<template>
    <Header />
    <div class="gloval_site" v-if="dataContentSite" :class="this.typeLayout==1? 'ligth' : 'dark'" >
        <div class="loading-site" v-show="!hasReady && ( this.typeLayout!= 1 || this.typeLayout!= 1)">
            <div class="loader">
            </div>
        </div>
        <section class="primary-content" v-if="dataContenWebsite[0]? dataContenWebsite[0].status:  listStatus[0].status==1" v-show="hasReady">
            <div class="background-wrapper" :style="{ backgroundImage: 'url(' + bannerBloco1 + ')' }">
                <div class="box-primary-content">
                    <div class="primary-text ">
                        <h3>{{titleBloco1}}</h3>
                    </div>
                    <div class="secondary-text">
                        <p>{{descriptionBloco1}}</p>
                    </div>
                    <div class="button-text">
                        <a :href="linkBloco1">Ver Cursos</a>
                    </div>
                </div>
                <div class="filter"></div>
            </div>
        </section>
        
        <section class="list-course" v-show="hasReady" v-if="dataContenWebsite[1]? dataContenWebsite[1].status: listStatus[1].status==1">
            <div class="title-list-course">
                <h3>{{this.titleBloco2}}</h3>
            </div>
            <div class="box-list-course">
                <div class="item-box-list-course animate__animated animate__backInLeft" v-for="course,index in dataCourses" :key="index">
                    <a href="#" class="link-item-box-list-course" @click="setCouseUrl(course)">
                        <div class="bg-course">
                            <img :src="course.imageFinalPath2 ? course.imageFinalPath2 : require('@/assets/Weflix/default-video-thumbnail.jpg')"/>
                        </div>
                        <div class="content-courser">
                            <div class="title-couse">
                                <h5>{{course.comercialName}}</h5>
                            </div>
                            <div class="pries">
                                <p class="full-price" v-show="course.discount > 0">R${{formatDecimals(course.price, 2)}}</p>
                                <p class="discount-price">R${{formatDecimals(course.price - course.discount, 2)}}</p>
                            </div>
                            
                        </div>
                    </a>
                </div>
            </div>
        </section>
     
        <section class="primary-content section2" v-if="dataContenWebsite[2] ? dataContenWebsite[2].status: listStatus[2].status==1">
            <div class="content-wrapper" style="display: flex; width: 100%;">
                <div class="image-block animate__animated" ref="observedElement1" style="width: 50%;">
                    <img :src="bannerBloco3" style="width: 100%; height: 100%; object-fit: cover;"/>
                </div>
                <div class="text-block animate__animated" ref="observedElement2" style="width: 50%; padding: 2em;">
                    <div class="primary-text">
                        <h3>{{titleBloco3}}</h3>
                    </div>
                    <div class="secondary-text">
                        <p>{{descriptionBloco3}}</p>
                    </div>
                    <div class="button-text">
                        <a :href="linkBloco3">Ver Cursos</a>
                    </div>
                </div>
             
            </div>
        </section>
        <section class="testimonials" v-show="hasReady" v-if="dataContenWebsite[3]? dataContenWebsite[3].status: listStatus[3].status==1">
            <div class="title-testimonials animate__animated" ref="observedElement3">
                <h3>Depoimentos</h3>
            </div>
            <div class="testimonials-data">
                <div class="info-testimonials" v-for="(testimony,index) in dataTestimonies" :key="index" :class="{'mobile-hidden': isMobile && currentIndex !== index}">
                    <div class="box-testimonials">
                        <div class="user-image">
                            <img class="avatar" :src="testimony.photoFinal"/>
                        </div>
                        <div class="user-testimony">
                            <p>"{{testimony.description}}"</p>
                        </div>
                        <div class="user-info">
                            <small>{{testimony.name}}</small>
                        </div>
                    </div>                
                </div>
            </div>
        </section>
        <!-- <section class="primary-course" v-show="dataCourses.length==1 ">
            <div class="info-primary-course">
                <h2>Comece hoje a sua jornada e garanta</h2>
                <div class="list-checks-primary-course">
                    <ul>
                        <li>
                            <i class="fas fa-check"></i> 80 horas de aulas
                        </li>
                        <li>
                            <i class="fas fa-check"></i> +12h bônus
                        </li>
                        <li>
                            <i class="fas fa-check"></i> Acesso vitalício
                        </li>
                        <li>
                            <i class="fas fa-check"></i> Certificado de conclusão
                        </li>
                        <li>
                            <i class="fas fa-check"></i> Garantia de 7 dias
                        </li>

                    </ul>
                </div>
            </div>
            <div class="price-primary-course" >
                <div class="box-primary-course">
                    <p class="price-old-primary-course">De R$ <span>99,99</span></p>
                    <p class="instalment-primary-course">por apenas 12x de</p>
                    <p class="price-course">R$ 19,99</p>
                    <p class="price-promo-primary-course">ou à vista R$199,99</p>
                    <button>Quero começar agora</button>
                    <div class="images-primary-course">
                        <img src="../../../assets/call-to-action-primary-content-image-1.jpg"/>
                    </div>
                </div>
            </div>
        </section> -->
        <!-- <section class="prices" v-show="false">
            <div class="box-prices">
                <div class="item-box-prices">
                    <div class="header-item-box-prices">
                        <div class="title-header-item-box-prices">
                            <h2>ENEM VIP 2024</h2>
                        </div>
                    </div>
                    <div class="prices-item-box-prices">
                        <p class="old-price-item-box-prices">De 12x R$24,99 por</p>
                        <p class="price-item-box-prices">12x <span>R$ 18,99</span></p>
                    </div>
                    <button class="button-item-box-prices">Aproveiter oferta</button>
                    <div class="list-item-box-prices">
                        <ul>
                            <li>
                                <span class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.12 5.84445C15.3113 6.03276 15.3198 6.33853 15.1392 6.53715L7.88032 14.5215C7.68223 14.7394 7.33968 14.7398 7.14112 14.5223L2.70392 9.66267C2.52292 9.46443 2.53075 9.15865 2.72166 8.96993L4.18487 7.52354C4.38678 7.32395 4.71417 7.33233 4.90561 7.542L7.13642 9.98519C7.33498 10.2027 7.67753 10.2023 7.87562 9.98439L12.9331 4.42142C13.1241 4.21134 13.4515 4.20226 13.6538 4.40141L15.12 5.84445Z" fill="#82DE85"></path>
                                    </svg>
                                </span>
                                Aulas ilimitadas
                            </li>
                            <li>
                                <span class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.12 5.84445C15.3113 6.03276 15.3198 6.33853 15.1392 6.53715L7.88032 14.5215C7.68223 14.7394 7.33968 14.7398 7.14112 14.5223L2.70392 9.66267C2.52292 9.46443 2.53075 9.15865 2.72166 8.96993L4.18487 7.52354C4.38678 7.32395 4.71417 7.33233 4.90561 7.542L7.13642 9.98519C7.33498 10.2027 7.67753 10.2023 7.87562 9.98439L12.9331 4.42142C13.1241 4.21134 13.4515 4.20226 13.6538 4.40141L15.12 5.84445Z" fill="#82DE85"></path>
                                    </svg>
                                </span>
                                5 Simulados por mês
                            </li>
                            <li>
                                <span class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.12 5.84445C15.3113 6.03276 15.3198 6.33853 15.1392 6.53715L7.88032 14.5215C7.68223 14.7394 7.33968 14.7398 7.14112 14.5223L2.70392 9.66267C2.52292 9.46443 2.53075 9.15865 2.72166 8.96993L4.18487 7.52354C4.38678 7.32395 4.71417 7.33233 4.90561 7.542L7.13642 9.98519C7.33498 10.2027 7.67753 10.2023 7.87562 9.98439L12.9331 4.42142C13.1241 4.21134 13.4515 4.20226 13.6538 4.40141L15.12 5.84445Z" fill="#82DE85"></path>
                                    </svg>
                                </span>
                                2 correções de redação por mês
                            </li>
                            <li>
                                <span class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.12 5.84445C15.3113 6.03276 15.3198 6.33853 15.1392 6.53715L7.88032 14.5215C7.68223 14.7394 7.33968 14.7398 7.14112 14.5223L2.70392 9.66267C2.52292 9.46443 2.53075 9.15865 2.72166 8.96993L4.18487 7.52354C4.38678 7.32395 4.71417 7.33233 4.90561 7.542L7.13642 9.98519C7.33498 10.2027 7.67753 10.2023 7.87562 9.98439L12.9331 4.42142C13.1241 4.21134 13.4515 4.20226 13.6538 4.40141L15.12 5.84445Z" fill="#82DE85"></path>
                                    </svg>
                                </span>
                                Comunidade no Telegram
                            </li>
                            <li>
                                <span class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.12 5.84445C15.3113 6.03276 15.3198 6.33853 15.1392 6.53715L7.88032 14.5215C7.68223 14.7394 7.33968 14.7398 7.14112 14.5223L2.70392 9.66267C2.52292 9.46443 2.53075 9.15865 2.72166 8.96993L4.18487 7.52354C4.38678 7.32395 4.71417 7.33233 4.90561 7.542L7.13642 9.98519C7.33498 10.2027 7.67753 10.2023 7.87562 9.98439L12.9331 4.42142C13.1241 4.21134 13.4515 4.20226 13.6538 4.40141L15.12 5.84445Z" fill="#82DE85"></path>
                                    </svg>
                                </span>
                                Resposta de dúvidas em até 3 dias úteis
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="box-prices primary">
                <div class="item-box-prices primary">
                    <div class="header-item-box-prices">
                        <div class="title-header-item-box-prices">
                            <h2>ENEM VIP 2024</h2>
                        </div>
                    </div>
                    <div class="prices-item-box-prices">
                        <p class="old-price-item-box-prices">De 12x R$24,99 por</p>
                        <p class="price-item-box-prices">12x <span>R$ 18,99</span></p>
                    </div>
                    <button class="button-item-box-prices">Aproveiter oferta</button>
                    <div class="list-item-box-prices">
                        <ul>
                            <li>
                                <span class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.12 5.84445C15.3113 6.03276 15.3198 6.33853 15.1392 6.53715L7.88032 14.5215C7.68223 14.7394 7.33968 14.7398 7.14112 14.5223L2.70392 9.66267C2.52292 9.46443 2.53075 9.15865 2.72166 8.96993L4.18487 7.52354C4.38678 7.32395 4.71417 7.33233 4.90561 7.542L7.13642 9.98519C7.33498 10.2027 7.67753 10.2023 7.87562 9.98439L12.9331 4.42142C13.1241 4.21134 13.4515 4.20226 13.6538 4.40141L15.12 5.84445Z" fill="#82DE85"></path>
                                    </svg>
                                </span>
                                Aulas ilimitadas
                            </li>
                            <li>
                                <span class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.12 5.84445C15.3113 6.03276 15.3198 6.33853 15.1392 6.53715L7.88032 14.5215C7.68223 14.7394 7.33968 14.7398 7.14112 14.5223L2.70392 9.66267C2.52292 9.46443 2.53075 9.15865 2.72166 8.96993L4.18487 7.52354C4.38678 7.32395 4.71417 7.33233 4.90561 7.542L7.13642 9.98519C7.33498 10.2027 7.67753 10.2023 7.87562 9.98439L12.9331 4.42142C13.1241 4.21134 13.4515 4.20226 13.6538 4.40141L15.12 5.84445Z" fill="#82DE85"></path>
                                    </svg>
                                </span>
                                5 Simulados por mês
                            </li>
                            <li>
                                <span class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.12 5.84445C15.3113 6.03276 15.3198 6.33853 15.1392 6.53715L7.88032 14.5215C7.68223 14.7394 7.33968 14.7398 7.14112 14.5223L2.70392 9.66267C2.52292 9.46443 2.53075 9.15865 2.72166 8.96993L4.18487 7.52354C4.38678 7.32395 4.71417 7.33233 4.90561 7.542L7.13642 9.98519C7.33498 10.2027 7.67753 10.2023 7.87562 9.98439L12.9331 4.42142C13.1241 4.21134 13.4515 4.20226 13.6538 4.40141L15.12 5.84445Z" fill="#82DE85"></path>
                                    </svg>
                                </span>
                                2 correções de redação por mês
                            </li>
                            <li>
                                <span class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.12 5.84445C15.3113 6.03276 15.3198 6.33853 15.1392 6.53715L7.88032 14.5215C7.68223 14.7394 7.33968 14.7398 7.14112 14.5223L2.70392 9.66267C2.52292 9.46443 2.53075 9.15865 2.72166 8.96993L4.18487 7.52354C4.38678 7.32395 4.71417 7.33233 4.90561 7.542L7.13642 9.98519C7.33498 10.2027 7.67753 10.2023 7.87562 9.98439L12.9331 4.42142C13.1241 4.21134 13.4515 4.20226 13.6538 4.40141L15.12 5.84445Z" fill="#82DE85"></path>
                                    </svg>
                                </span>
                                Comunidade no Telegram
                            </li>
                            <li>
                                <span class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.12 5.84445C15.3113 6.03276 15.3198 6.33853 15.1392 6.53715L7.88032 14.5215C7.68223 14.7394 7.33968 14.7398 7.14112 14.5223L2.70392 9.66267C2.52292 9.46443 2.53075 9.15865 2.72166 8.96993L4.18487 7.52354C4.38678 7.32395 4.71417 7.33233 4.90561 7.542L7.13642 9.98519C7.33498 10.2027 7.67753 10.2023 7.87562 9.98439L12.9331 4.42142C13.1241 4.21134 13.4515 4.20226 13.6538 4.40141L15.12 5.84445Z" fill="#82DE85"></path>
                                    </svg>
                                </span>
                                Resposta de dúvidas em até 3 dias úteis
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="box-prices">
                <div class="item-box-prices ">
                    <div class="header-item-box-prices">
                        <div class="title-header-item-box-prices">
                            <h2>ENEM VIP 2024</h2>
                        </div>
                    </div>
                    <div class="prices-item-box-prices">
                        <p class="old-price-item-box-prices">De 12x R$24,99 por</p>
                        <p class="price-item-box-prices">12x <span>R$ 18,99</span></p>
                    </div>
                    <button class="button-item-box-prices">Aproveiter oferta</button>
                    <div class="list-item-box-prices">
                        <ul>
                            <li>
                                <span class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.12 5.84445C15.3113 6.03276 15.3198 6.33853 15.1392 6.53715L7.88032 14.5215C7.68223 14.7394 7.33968 14.7398 7.14112 14.5223L2.70392 9.66267C2.52292 9.46443 2.53075 9.15865 2.72166 8.96993L4.18487 7.52354C4.38678 7.32395 4.71417 7.33233 4.90561 7.542L7.13642 9.98519C7.33498 10.2027 7.67753 10.2023 7.87562 9.98439L12.9331 4.42142C13.1241 4.21134 13.4515 4.20226 13.6538 4.40141L15.12 5.84445Z" fill="#82DE85"></path>
                                    </svg>
                                </span>
                                Aulas ilimitadas
                            </li>
                            <li>
                                <span class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.12 5.84445C15.3113 6.03276 15.3198 6.33853 15.1392 6.53715L7.88032 14.5215C7.68223 14.7394 7.33968 14.7398 7.14112 14.5223L2.70392 9.66267C2.52292 9.46443 2.53075 9.15865 2.72166 8.96993L4.18487 7.52354C4.38678 7.32395 4.71417 7.33233 4.90561 7.542L7.13642 9.98519C7.33498 10.2027 7.67753 10.2023 7.87562 9.98439L12.9331 4.42142C13.1241 4.21134 13.4515 4.20226 13.6538 4.40141L15.12 5.84445Z" fill="#82DE85"></path>
                                    </svg>
                                </span>
                                5 Simulados por mês
                            </li>
                            <li>
                                <span class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.12 5.84445C15.3113 6.03276 15.3198 6.33853 15.1392 6.53715L7.88032 14.5215C7.68223 14.7394 7.33968 14.7398 7.14112 14.5223L2.70392 9.66267C2.52292 9.46443 2.53075 9.15865 2.72166 8.96993L4.18487 7.52354C4.38678 7.32395 4.71417 7.33233 4.90561 7.542L7.13642 9.98519C7.33498 10.2027 7.67753 10.2023 7.87562 9.98439L12.9331 4.42142C13.1241 4.21134 13.4515 4.20226 13.6538 4.40141L15.12 5.84445Z" fill="#82DE85"></path>
                                    </svg>
                                </span>
                                2 correções de redação por mês
                            </li>
                            <li>
                                <span class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.12 5.84445C15.3113 6.03276 15.3198 6.33853 15.1392 6.53715L7.88032 14.5215C7.68223 14.7394 7.33968 14.7398 7.14112 14.5223L2.70392 9.66267C2.52292 9.46443 2.53075 9.15865 2.72166 8.96993L4.18487 7.52354C4.38678 7.32395 4.71417 7.33233 4.90561 7.542L7.13642 9.98519C7.33498 10.2027 7.67753 10.2023 7.87562 9.98439L12.9331 4.42142C13.1241 4.21134 13.4515 4.20226 13.6538 4.40141L15.12 5.84445Z" fill="#82DE85"></path>
                                    </svg>
                                </span>
                                Comunidade no Telegram
                            </li>
                            <li>
                                <span class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.12 5.84445C15.3113 6.03276 15.3198 6.33853 15.1392 6.53715L7.88032 14.5215C7.68223 14.7394 7.33968 14.7398 7.14112 14.5223L2.70392 9.66267C2.52292 9.46443 2.53075 9.15865 2.72166 8.96993L4.18487 7.52354C4.38678 7.32395 4.71417 7.33233 4.90561 7.542L7.13642 9.98519C7.33498 10.2027 7.67753 10.2023 7.87562 9.98439L12.9331 4.42142C13.1241 4.21134 13.4515 4.20226 13.6538 4.40141L15.12 5.84445Z" fill="#82DE85"></path>
                                    </svg>
                                </span>
                                Resposta de dúvidas em até 3 dias úteis
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section> -->
        <section class="footer">
            <div class="logo-footer">
                <img src="../../../assets/logo.png" v-show='this.dataConfigSite.typeLayout==1' />
                <img src="../../../assets/logo_white.png" v-show='this.dataConfigSite.typeLayout==2' />
            </div>
            <p class="copyright-footer">@Copyright - Weeeduc | 2024  Todos os direitos reservados</p>
            <div class="social-media" v-if="this.dataSocialMedias">
                <ul>
                    <li class="facebook" v-show="this.facebook.status==1" v-if="this.dataSocialMedias[0]">
                        <a :href="this.facebook.url">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path d="M512 256C512 114.6 397.4 0 256 0S0 114.6 0 256C0 376 82.7 476.8 194.2 504.5V334.2H141.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H287V510.1C413.8 494.8 512 386.9 512 256h0z"/></svg>
                        </a>
                    </li>
                    <li class="instagram" v-show="this.instagram.status==1"  v-if="this.dataSocialMedias[1]">
                        <a :href="this.instagram.url" >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"> 
                                <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg>
                        </a>
                    </li>
                    <li class="youtube" v-show="this.youtube.status==1" v-if="this.dataSocialMedias[2]">
                        <a :href="this.youtube.url">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"> 
                                <path d="M549.7 124.1c-6.3-23.7-24.8-42.3-48.3-48.6C458.8 64 288 64 288 64S117.2 64 74.6 75.5c-23.5 6.3-42 24.9-48.3 48.6-11.4 42.9-11.4 132.3-11.4 132.3s0 89.4 11.4 132.3c6.3 23.7 24.8 41.5 48.3 47.8C117.2 448 288 448 288 448s170.8 0 213.4-11.5c23.5-6.3 42-24.2 48.3-47.8 11.4-42.9 11.4-132.3 11.4-132.3s0-89.4-11.4-132.3zm-317.5 213.5V175.2l142.7 81.2-142.7 81.2z"/></svg>
                        </a>
                    </li>
                    <li class="linkedin" v-show="this.linkedin.status==1" v-if="this.dataSocialMedias[3]">
                        <a :href="this.linkedin.url">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"> 
                                <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/></svg>
                        </a>
                    </li>
                    <li class="whastapp" v-show="this.whastapp.status==1" v-if="this.dataSocialMedias[4]">
                        <a :href="this.whastapp.url" v-if="this.dataSocialMedias">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">                                 
                                <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"/></svg></a> 
                    </li>
                    <li class="tiktok" v-show="this.tiktok.status==1" v-if="this.dataSocialMedias[5]">
                        <a :href="this.tiktok.url" v-if="this.dataSocialMedias">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                <path d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z"/></svg>
                        </a>
                    </li>
                </ul>
            </div>
        </section>
    </div>
</template>

<style scoped>
@import './site.css';
</style>


<script>
import api from '@/utils/website';
import { mapState, mapActions } from 'vuex';
import router from '@/router';
import Header from './Header.vue';

export default{
    data(){
        return{
            dataConfigSite:[],
            dataContentSite:[],
            dataCourses:[],
            dataTestimonies:[],
            dataSocialMedias:[],
            listStatus:[{'status':0,},{'status':0,},{'status':0,},{'status':0,}],
            hasReady:false,
            typeLayout:1,

            //bloco 1
            titleBloco1:'',
            descriptionBloco1:'',
            linkBloco1:'',
            bannerBloco1:'',

            //bloco 2
            titleBloco2:'',

            //bloco 3
            titleBloco3:'',
            descriptionBloco3:'',
            linkBloco3:'',
            bannerBloco3:'',
            //bloco 4
            titleBloco4:'',
          
            //LinkSocialMedia
            facebook:[],
            instagram:[],
            youtube:[],
            linkedin:[],
            whastapp:[],
            tiktok:[],

           baseUrl: process.env.NODE_ENV === 'development' ? process.env.VUE_APP_BASE_URL_HOMOLOGATION  : window.location.origin,

           currentIndex: 0,
           isMobile: false,

            
        }   
    },
    computed:{
        ...mapState(['token','cleanCache','cacheDate','dataWebsite','dataContenWebsite','dataCoursesWebsite','dataTestimoniesWebsite','courseWebsite','dataSocialMedia']),
        totalTestimonials() {
          return this.dataTestimonies.length;
        },

    },
    components: {
        Header
    },
    methods:{

        setupIntersectionObserver() {
            const element1 = this.$refs.observedElement1;
            const element2 = this.$refs.observedElement2;
            const element3 = this.$refs.observedElement3;
            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                if (entry.isIntersecting) {
                    // Adiciona a classe quando o elemento entra na tela
                    element1.classList.add('animate__backInRight');
                    element2.classList.add('animate__backInLeft');
                    element3.classList.add('animate__backInUp');

                    
                    // Remove a classe depois de 2 segundos
                    setTimeout(() => {
                        element1.classList.remove('animate__backInRight');
                        element2.classList.remove('animate__backInUp');
                        element3.classList.remove('animate__backInUp');

                    }, 2000);
                }
                });
            }, { threshold: 0 });

        observer.observe(element1);
        observer.observe(element2);
        observer.observe(element3);

        },
        ...mapActions(['storeCourseWebsite','storeCleanCache','storeDataWebsite','storeDataContentWebsite','storeDataCoursesWebsite','storeDataTestimoniesWebsite', 'storeDataSocialMedia','storeCacheDate','toggleDarkMode']),
        
       
        async getDataSite(){
            const aux = this.baseUrl.replace(/\/$/, '');
            const obj = { url: aux }; 

            try {
                const response = await api.getDataWebsite(obj);

                console.log(response.typeLayoutPortal == 0 ? true : false);
                this.toggleDarkMode({dark:response.typeLayout == 0 ? true : false});


                this.storeDataWebsite({dataWebsite:response});
                const styleElement = document.createElement('style');
                styleElement.type = 'text/css';
                styleElement.appendChild(document.createTextNode(response.css));
                document.head.appendChild(styleElement);
        
                const scriptElement = document.createElement('script');
                scriptElement.type = 'text/javascript';
                scriptElement.appendChild(document.createTextNode(response.javascript));
                document.body.appendChild(scriptElement);

                this.typeLayout = response.typeLayout;
                    
            } catch (error) {
                console.error('Erro ao gerar dados do site');
                throw error;
            }
        },
        async getDataContent(){
              const aux = this.baseUrl.replace(/\/$/, '');
            const obj = { url: aux }; 

            try {
                const response = await api.getDataContent( obj);
                this.storeDataContentWebsite({dataContenWebsite:response});               
                this.dataConfigSite = response;  
                this.sortItemsByType(response);                
                if(this.dataContenWebsite){
                    this.loadDataContent();
                }
                this.listStatus = response.map(item => ({
                    status: item.status
                }));
            } catch (error) {
                console.error('Erro ao gerar conteúdo do site');
                throw error;

            }
        },
        async getDataCourses(){
              const aux = this.baseUrl.replace(/\/$/, '');
            const obj = { url: aux }; 

            try {
                const response = await api.getDataCourse( obj);
                this.storeDataCoursesWebsite({dataCoursesWebsite:response});
                this.dataCourses = response;                             
            } catch (error) {
                console.error('Erro ao gerar conteúdo do site');
                throw error;

            }
        },
        async getDataTestimonies(){
              const aux = this.baseUrl.replace(/\/$/, '');
            const obj = { url: aux }; 

            try {
                const response = await api.getDataTestimonies(obj);
                this.dataTestimonies = response;   
                this.storeDataTestimoniesWebsite({dataTestimoniesWebsite:response});                          
            } catch (error) {
                console.error('Erro ao gerar conteúdo do site');
                throw error;

            }
        },
        async getDataSocialMedia(){
            const aux = this.baseUrl.replace(/\/$/, '');
            const obj = { url: aux }; 

            try {
                const response = await api.getDataSocialMedia(obj);
                this.storeDataSocialMedia({dataSocialMedia:response});      
                this.dataSocialMedias = response;                 
                this.loadDataSocialMedia();

            } catch (error) {
                console.error('Erro ao gerar conteúdo do site');
                throw error;

            }
        },
        sortItemsByType(data) {
           this.dataContentSite = data.sort((a, b) => a.type - b.type);
        },
        formatDecimals(value, decimals) {
        if (isNaN(value)) {
            return "Invalid number";
        }
            return Number(value).toLocaleString('pt-BR', { minimumFractionDigits: decimals, maximumFractionDigits: decimals });
        },
        setCouseUrl(course){
           this.storeCourseWebsite({courseWebsite:course});
           this.$router.push({ path: '/produto/' + course.url }) 
        },
        loadDataContent(){
            //bloco 1
            this.titleBloco1 = this.dataContenWebsite[0].title;
            this.descriptionBloco1 = this.dataContenWebsite[0].description;
            this.linkBloco1 = this.dataContenWebsite[0].url;
            this.bannerBloco1 = this.dataContenWebsite[0].pathImageFinal;

            // //bloco 2
            this.titleBloco2 = this.dataContenWebsite[1] ? this.dataContenWebsite[1].title : 'Título não disponível';

            // //bloco 3
            this.titleBloco3 = this.dataContenWebsite[3].title;
            this.descriptionBloco3 = this.dataContenWebsite[3].description;
            this.linkBloco3 = this.dataContenWebsite[3].url;
            this.bannerBloco3 = this.dataContenWebsite[3].pathImageFinal;

            // //bloco 4
            this.titleBloco4 = this.dataContenWebsite[2].title;

            this.dataContentSite = this.dataContenWebsite;

            this.listStatus = this.dataContenWebsite.map(item => ({
                    status: item.status
                }));
        },
        loadDataConfig(){

            const styleElement = document.createElement('style');
            styleElement.type = 'text/css';
            styleElement.appendChild(document.createTextNode(this.dataConfigSite.css));
            document.head.appendChild(styleElement);

            const scirptTagManager = document.createElement('script');
            scirptTagManager.type = 'text/javascript';

            const scriptElement = document.createElement('script');
            scriptElement.type = 'text/javascript';
            scriptElement.appendChild(document.createTextNode(this.dataConfigSite.javascript));
            document.body.appendChild(scriptElement);

            this.typeLayout = this.dataConfigSite.typeLayout;
        },
        loadDataSocialMedia(){
            this.facebook = this.dataSocialMedias[0];
            this.instagram = this.dataSocialMedias[1];
            this.youtube = this.dataSocialMedias[2];
            this.linkedin = this.dataSocialMedias[3];
            this.whastapp = this.dataSocialMedias[4];
            this.tiktok = this.dataSocialMedias[5];
        },
        //Tag Manager
        loadDataTagManager(){
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer',this.dataConfigSite.tagManagerCode);
        },
        //Google Analytics
        addGtagScript() {
            const script = document.createElement('script');
            script.async = true;
            script.src = "https://www.googletagmanager.com/gtag/js?id="+this.dataConfigSite.googleAnalyticsCode;
            document.head.appendChild(script);
        },
        initGtag() {
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', this.dataConfigSite.googleAnalyticsCode);
        },
        //Facebook Pixel
        loadDataFacebbokPixel(){
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window,document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', this.dataConfigSite.facebookPixelCode); 
            fbq('track', 'PageView');
        },
        //Google Ads Pixel
        addGadsScript() {
            const script = document.createElement('script');
            script.async = true;
            script.src = "https://www.googletagmanager.com/gtag/js?id="+this.dataConfigSite.googleAdsCode;
            document.head.appendChild(script);
        },
        initGads() {
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', this.dataConfigSite.googleAdsCode);
        },
        async getLastUpdate(){
            const aux = this.baseUrl.replace(/\/$/, '');
            const obj = { url: aux }; 
            try {
                const response = await api.getLastupdate(obj);
                if(this.cacheDate != response){
                    this.storeCacheDate({cacheDate:response});
                    this.storeCleanCache({cleanCache:true});
                } else {
                    this.storeCleanCache({cleanCache:false});
                }
              
            } catch (error) {
                console.error('Erro ao gerar dados do cache');
            }
        },
        startSlideShow() {
            setInterval(() => {
                this.currentIndex = (this.currentIndex + 1) % this.totalTestimonials;
            }, 3000);
         },

    },
    mounted(){
        this.loadDataTagManager();
        this.addGtagScript();
        this.initGtag();
        this.loadDataFacebbokPixel();
        this.addGadsScript();
        this.initGads();
        this.startSlideShow();
        this.isMobile = window.innerWidth <= 768;

    },
    created(){

        this.getLastUpdate();

        const currentUrl = window.location.href;
        // Social Media
        this.getDataSocialMedia();

        // Config Site
        this.getDataSite();

        // Content Site
        this.getDataContent();

        // Courses Site
        this.getDataCourses();

        // Testimonies Site
        this.getDataTestimonies();
        setTimeout(() => {
            this.hasReady = true;
        }, 100);
    }
}
</script>

<style>
section.list-course > div {
    max-width: 1500px;
    margin: 0 auto;
}
.mobile-hidden {
    display: none;
  }
.testimonials-data {
    position: relative;
    overflow: hidden;
  }
  
  .info-testimonials {
    position: absolute;
    width: 100%;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
  
  .info-testimonials:nth-child(n) {
    opacity: 1;
    position: static;
  }
.content-wrapper {
    max-width: 1500px;
    margin: 0 auto;
}
section.primary-content {
    background-size: cover;
    position: relative;
    background-color: rgb(255, 255, 255);
}
.dark > section.primary-content {
    background-color: black;
}
.background-wrapper {
    min-height: 550px;
    max-width: 1500px;
    margin: auto;
    background-size: cover;
}
.item-box-list-course{
    max-width: 25%;
}
.gloval_site.dark section.list-course,.gloval_site.dark section.testimonials, .gloval_site.dark section.testimonial, .gloval_site.dark .item-box-list-course, .gloval_site.dark .title-couse h5,.gloval_site.dark p.full-price,.gloval_site.dark p.discount-price {
    background-color: black;
    color: white;
}
.gloval_site.dark .title-list-course h3, .gloval_site.dark .title-testimonials h3,  .gloval_site.dark  .primary-text h3, .gloval_site.dark .secondary-text p{
    color: white;
}
.gloval_site.dark .item-box-list-course {
    border: 1px solid #363636;
}
.gloval_site.dark section.footer {
    background-color: black;
    border-top: 1px solid #686161 !important;
    color: white;
    border: none;
}
.gloval_site.dark p.copyright-footer {
    color: white;
}
.gloval_site.dark .social-media path {
    fill: white;
}
.logo-footer img {
    width: 200px;
}
.info-testimonials {
    width: 600px;
    background-color: #f7f7f7;
    border-radius: 10px;
    padding: 10px;
}
.user-image {
    text-align: left;
}
.user-testimony {
    text-align: justify;
    margin-top: 25px;
    font-size: 14px;
}
.user-info {
    text-align: left;
}
.title-testimonials {
    margin-bottom: 30px;
}
.gloval_site.dark .info-testimonials {
    background-color: #181923;
}
@media only screen and (max-width: 600px) {
    .text-block.animate__animated {
        width: 100% !important;
    }
    .image-block.animate__animated {
        width: 100% !important;
    }
    section.primary-content.section2 .content-wrapper {
        display: block !important;
        max-width: 100%;
        width: 100%;
    }
    .background-wrapper {
        min-height: auto;
        max-width: 100%;
        margin: auto;
        background-size: cover;
    }
    .item-box-list-course.animate__animated.animate__backInLeft {
        width: 50%;
        max-width: 47%;
    }
    section.primary-content.section2 img {
        width: 100%;
    }
    section.primary-content.section2 .box-primary-content {
        max-width: 100%!important;
    }
    .gloval_site section.primary-content {
        display: block;
    }
    .dark section.primary-content.section2{
        display: block!important;
    }
    .gloval_site.dark section.primary-content {
        background-image: none!important;
        background-color: black;
        background-size: cover;
        min-height: 400px;
        padding: 4em 1em;
        position: relative;
    }
    .gloval_site section.primary-content {
        background-image: none!important;
        background-color: #f9f9f9;
        background-size: cover;
        min-height: 400px;
        padding: 4em 1em;
        position: relative;
    }
    .box-primary-content {
        text-align: center;
    }
    section.list-course {
        padding: 0.5rem 1em;
        text-align: center;
    }
    .box-list-course {
        justify-content: center;
    }
    section.testimonials {
        background-color: white;
        padding: 3em 1em;
    }
    .info-testimonials {
        width: 100%;
    }
    .info-testimonials {
        margin-bottom: 15px;
    }
    section.footer {
        display: block;
    }
    .social-media ul {
        display: flex;
        gap: 10px;
        justify-content: center;
        margin: 10px;
    }
    .gloval_site.dark  section.header {
        padding: 1.5em 5em;
        display: flex;
        justify-content: center;
        position: initial;
        width: 100%;
        background-color: black;
    }
        section.header {
        padding: 1.5em 5em;
        display: flex;
        justify-content: center;
        position: initial;
        width: 100%;
        background-color: white;
    }
    .testimonials-data {
        display: block;       
    }
}
.button-text a {
    display: inline-block;
    width: auto !important;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
    padding: 0.5rem 2rem;
    color: #fff;
    background-color: #ffa519;
    border-radius: 0.35em;
}
.gloval_site.ligth .filter {
    background-color: #ffffff45;
    position: absolute;
    width: 100%;
    height: 100px;
    top: 0;
    left: 0;
    height: 100%;
    background: rgb(255, 255, 255);
    background: linear-gradient(277deg, rgba(255, 255, 255, 1) 0%, rgba(50, 220, 255, 0) 0%, rgba(255, 255, 255, 0) 46%, rgba(255, 255, 255, 1) 100%);
}
.gloval_site.dark .filter {
    background-color: #ffffff45;
    position: absolute;
    width: 50%;
    height: 100px;
    top: 0;
    left: 0;
    height: 100%;
    background: rgb(255, 255, 255);
    background: linear-gradient(96deg, rgba(255, 255, 255, 1) 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 123%, rgb(255 255 255 / 0%) 100%);
}
@media (min-width: 768px) {
    .item-box-list-course {
        flex: 1 1 calc(22% - 20px);
    }
}
 
  
  @media (max-width: 768px) {
    .item-box-list-course {
        flex: 1 1 calc(49% - 20px);
    } 
  }
  
  @media (max-width: 480px) {
    .item-box-list-course {
        flex: 1 1 calc(100% - 20px);
    } 
  }
   section.primary-content.section2 {
    background-color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
}
.dark > section.primary-content.section2 {
    background-color: black;
    display: flex;
    align-items: center;
}
section.primary-content.section2 .box-primary-content {
    max-width: 50%;
}
</style>
