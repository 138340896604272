import api from '../api';

export default {
    async getCollections(payload, page) {
        try {
            const headers = {
                'Content-Type': 'application/json', 
            };
            const response = await api.post(`/ecommerce/collection`, payload, {
                method: 'POST',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados das coleções:', error);
                throw error;
        }
    },
    async getCollectionsLimit(payload, page) {
        try {
            const headers = {
                'Content-Type': 'application/json', 
            };
            const response = await api.post(`/ecommerce/collection/courses/limit?page=${page}&size=12`, payload, {
                method: 'POST',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados das coleções:', error);
                throw error;
        }
    },
    async getSocialMedia(payload) {
        try {
            const headers = {
                'Content-Type': 'application/json', 
            };
            const response = await api.post(`/ecommerce/social-media`, payload, {
                method: 'POST',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados das redes sociais:', error);
                throw error;
        }
    },
    async getWebsite(payload) {
        try {
            const headers = {
                'Content-Type': 'application/json', 
            };
            const response = await api.post(`/ecommerce/website`, payload, {
                method: 'POST',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados do website:', error);
                throw error;
        }
    },
    async Login(payload) {
        try {
            const headers = {
                'Content-Type': 'application/json', 
            };
            const response = await api.post(`/auth/login`, payload, {
                method: 'POST',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados do login:', error);
                throw error;
        }
    },
    async serachCollectionVideo(payload) {
        try {
            const headers = {
                'Content-Type': 'application/json', 
            };
            const response = await api.post(`/ecommerce/collection/search`, payload, {
                method: 'POST',
                headers,
            });
            return response.data;
        } catch (error) {
         
        }
    },
    async getVideosByCollection(id, payload, page) {  
        try {
            const headers = {
                'Content-Type': 'application/json', 
            };
            const response = await api.post(`/ecommerce/collection/course/${id}?page=${page}&size=100`, payload, {
                method: 'POST',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados da collection:', error);
                throw error;
        }
    },
    async getVideosByCollectionLogged(id, token) {  
        try {
            const headers = {
                'Content-Type': 'application/json', 
                'Authorization': `Bearer ${token}`
            };
            const response = await api.get(`/collection/${id}`, {
                method: 'GET',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados da collection:', error);
                throw error;
        }
    },
    async createAccount(payload) {
        try {
            const headers = {
                'Content-Type': 'application/json', 
            };
            const response = await api.post(`/ecommerce/insert`, payload, {
                method: 'POST',
                headers,
            });
            return response;
        } catch (error) {
            console.error('Erro ao criar usuário:', error);
                throw error;
        }
    },
    async getBanner(payload) {
        try {
            const headers = {
                'Content-Type': 'application/json', 
            };
            const response = await api.post(`/ecommerce/content`, payload, {
                method: 'POST',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados do banner:', error);
                throw error;
        }
    },
    async confirmationAccount(payload) {
        try {
            const headers = {
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/ecommerce/ativacao?token=${payload}`, {
                method: 'GET',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao confirmar a conta', error);
                throw error;
        }
    },
    async generateNewToken(payload) {
        try {
            const headers = {
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/ecommerce/renewToken?token=${payload}`, {
                method: 'GET',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao confirmar a conta', error);
                throw error;
        }
    },
    async getStats(payload) {
        try {
            const headers = {
                'Content-Type': 'application/json', 
            };
            const response = await api.post(`ecommerce/collection/stats`, payload,{
                method: 'POST',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados das estatísticas', error);
                throw error;
        }
    },
    async getInfoVideo(payload, id) {
        try {
            const headers = {
                'Content-Type': 'application/json', 
            };
            const response = await api.post(`ecommerce/lesson/${id}`, payload,{
                method: 'POST',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados do video', error);
                throw error;
        }
    },
    async getTrial(payload){
        try {
            const headers = {
                'Content-Type': 'application/json', 
            };
            const response = await api.post(`ecommerce/trial`, payload,{
                method: 'POST',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados do video', error);
                throw error;
        }
    },
    async forgotPassword(payload){
        try {
            const headers = {
                'Content-Type': 'application/json', 
            };
            const response = await api.post(`/password/forgot-password`, payload,{
                method: 'POST',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao solicitar recuperação de senha', error);
                throw error;
        }
    },
    async resetPassword(payload){
        try {
            const headers = {
                'Content-Type': 'application/json', 
            };
            const response = await api.post(`/password/change-password`, payload,{
                method: 'POST',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao solicitar recuperação de senha', error);
                throw error;
        }
    }

    
}