import api from '../api';

export default {
    async getSignatureImage(accessToken,nameFile) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/file/image?fileName=${nameFile}`,  {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao criar URL assinada AWS:', error);
                throw error;
        }
    },
    async getSignatureImageCompany(accessToken,nameFile,domain,idUser) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/file/company/image?fileName=${nameFile}&domain=${domain}&idUser=${idUser}`,  {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao criar URL assinada AWS:', error);
                throw error;
        }
    },
    async getSignaturePdf(accessToken, nameFile) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/file/pdf?fileName=${nameFile}`,  {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao criar URL assinada AWS:', error);
                throw error;
        }
    },
    async getSignatureVideo(accessToken,part,type) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/file/video?partes=${part}&extensao=${type}`,  {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao criar URL assinada AWS video:', error);
                throw error;
        }
    },
    async putFileAWS(url, payload) {
        try {
            const headers = {
                'Content-Type': payload.type 
              };
            const response = await api.put(`${url}`, payload,  {
                headers,
            });
            return response;
        } catch (error) {
            console.error('Erro ao enviar arquivo AWS:', error);
                throw error;
        }
    },
    // async putVideoAWS(url, chunk) {
    //     try {
    //         const headers = {
    //             'Content-Type': 'application/octet-stream'
    //         };

    //         const response = await api.put(url, chunk, {
    //             headers,
    //             onUploadProgress: (progressEvent) => {
    //                 if (progressEvent.lengthComputable) {
    //                     const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    //                     console.log(`Upload progress: ${percentCompleted}%`);
    //                 }
    //             }
    //         });

    //         return response;

    //     } catch (error) {
    //         console.error('Erro no upload do chunk:', error);
    //         throw error;
    //     }
    // },
    async putVideoAWS(url, chunk, onProgress) {
        try {
            const headers = {
                'Content-Type': 'application/octet-stream'
            };
    
            const response = await api.put(url, chunk, {
                headers,
                onUploadProgress: (progressEvent) => {
                    if (progressEvent.lengthComputable) {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        onProgress(percentCompleted);
                    }
                }
            });
    
            return response;
    
        } catch (error) {
            console.error('Erro no upload do chunk:', error);
            throw error;
        }
    },    
    async createVideoVideoteca(accessToken,payload) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
              const response = await api.post(`/file/videoteca/upload`, payload,  {
                headers,
            });
            return response;
        } catch (error) {
            console.error('Erro ao salvar video:', error);
                throw error;
        }
    },
    async editVideoVideoteca(accessToken,payload, id) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
              const response = await api.put(`/file/videoteca/update/${id}`, payload,  {
                headers,
            });
            return response;
        } catch (error) {
            console.error('Erro ao editar video:', error);
                throw error;
        }
    },
}

