<template>
    <div class="globalForum"  :class="{ 'dark': this.dark }">
        <Loader :isActive="!isReady"></Loader>
        <div class="title">
            <h3>Fóruns</h3>
            <!-- <button class="newForum" @click="newForum()">Novo Fórum</button> -->
        </div>
       <div class="content_forum desktop" v-if="data">
            <div class="sidebar_forum">
                <div class="item_sidebar" v-for="(item, index) in data" :key="index" :class="{ 'active': index === activeIndex }" @click="changeIdex(index, item.id, item.title, item.description, item.commentsQty, item.dataInsert)">
                    <h4>{{ textoLimitado(item.title, 40) }}</h4>
                    <div class="content_item_sidebar">
                        <span>{{ textoLimitado(item.description, 85) }}</span>
                    </div>
                    <div class="info_item_sidebar">
                        <fa icon="fa fa-comment"></fa> Respostas ({{ item.commentsQty }})
                    </div>
                    <div id="triangulo-para-direita"></div>
                </div>
            </div>
            <div class="forum_primary">
                <Loader :isActive="!isReadyFoum"></Loader>
                <div class="autor">
                    <span class="date">{{formatarData(dataForum)}}</span>
                </div>
                <div class="content_forum_primary">
                    <h3>{{this.title }}</h3>
                    <div class="description_content_forum_primary">
                       <span v-html="this.description">
                       </span>
                    </div>
                    <div class="answer_content_forum_primary">
                        <span>Publique seu comentário</span>
                        <QuillEditor theme="snow" toolbar="essential" 
                            @input="updateContentLongDescription($event.srcElement.innerHTML)"
                            v-model:content="comment"
                            contentType="html"
                        />
                        <div class="action_content_item_sidebar">
                            <button @click="newComment()">Enviar</button>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="anwer_users">
                    <div class="count_coment">
                        <span>{{ qtfForum }} respostas</span>
                    </div>
                    <div class="coment_anwer_users" v-for="comment in dataCommnet" :key="comment">
                        <div class="autor">
                            <span class="logo">{{obterIniciais(comment.userName)}}</span>
                        </div>
                        <div class="content_anwer_users">
                            <div class="autor">                            
                                <span class="name">{{comment.userName}}</span>
                                <span class="date">{{formatarData(comment.dateInsert)}}</span>
                            </div>
                            <div class="info_content_anwer_users">
                                <span v-html="comment.comment"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       </div>
       <div class="content_forum mobile" v-if="data">
        <div class="sidebar_forum">
            <div class="item_sidebar" v-for="(item, index) in data" :key="index" :class="{ 'active': index === activeIndex }" @click="changeIdex(index, item.id, item.title, item.description, item.commentsQty, item.dataInsert)">
                <h4>{{ textoLimitado(item.title, 40) }}</h4>
                <div class="content_item_sidebar">
                    <span>{{ textoLimitado(item.description, 85) }}</span>
                </div>
                <div class="info_item_sidebar">
                    <i class="pi pi-comment"></i> Respostas ({{ item.commentsQty }})
                </div>
                <div class="forum_primary" v-show="activeIndex == index">
                    <Loader :isActive="!isReadyFoum"></Loader>
                    <div class="autor">
                        <span class="date">{{formatarData(dataForum)}}</span>
                    </div>
                    <div class="content_forum_primary">
                        <h3>{{this.title }}</h3>
                        <div class="description_content_forum_primary">
                           <span v-html="this.description">
                           </span>
                        </div>
                        <div class="answer_content_forum_primary">
                            <span>Publique seu comentário</span>
                            <QuillEditor theme="snow" toolbar="essential" 
                                @input="updateContentLongDescription($event.srcElement.innerHTML)"
                                v-model:content="comment"
                                contentType="html"
                            />
                            <div class="action_content_item_sidebar">
                                <button @click="newComment()">Enviar</button>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="anwer_users">
                        <div class="count_coment">
                            <span>{{ qtfForum }} respostas</span>
                        </div>
                        <div class="coment_anwer_users" v-for="comment in dataCommnet" :key="comment">
                            <div class="autor">
                                <span class="logo">{{obterIniciais(comment.userName)}}</span>
                            </div>
                            <div class="content_anwer_users">
                                <div class="autor">                            
                                    <span class="name">{{comment.userName}}</span>
                                    <span class="date">{{formatarData(comment.dateInsert)}}</span>
                                </div>
                                <div class="info_content_anwer_users">
                                    <span v-html="comment.comment"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          
        </div>
       
   </div>
       <div class="noContent" v-if="!data">
        <h4>Nenhum fórum encontrado!</h4>
        <h5>Solicite a criação por seu administrador.</h5>
       </div>
    </div>
    <NewForum :showNewForum="openDialogNewForum" @handleOpenModalForum="handleOpenModalForum"></NewForum>

</template>

<script>
import api from '@/utils/portal/forum.js'
import { mapState } from 'vuex';
import Loader from '@/components/Loader/loader.vue';
import NewForum from '@/components/Student/Dashboard_Unique/Forum/New_Forum.vue'


export default {
    data(){
        return{
            active: 0,
            data:[],
            dataCommnet:[],
            openDialogNewInquiry:false,
            text: 'Quais são suas metas para explorar o fantastica',
            activeIndex:0,
            comment: null,
            idForum:null,
            dataForum:null,
            data:[],
            title:null,
            qtfForum:0,
            description:null,
            isReady:false,
            isReadyFoum:true,
            openDialogNewForum:false,
            indexForum:0,
            
        }
    },
    props:{

    },
    computed:{
        ...mapState(['token']),
        ...mapState(['dark']),
        textoLimitado() {
            return (text, value) => {
                if (text.length > value) {
                    return text.substring(0, value) + "...";
                } else {
                    return text;
                }
            };
        }
    },
    components:{
        Loader,
        NewForum
    },
    methods:{
        newForum(){
            this.openDialogNewForum=true;
        },
        handleOpenModalForum(){
            this.openDialogNewForum = false;
        },
        async newComment(){
            const obj = {
                    "comment": this.comment,
                    "idForum": this.idForum,
                 } 
            try {
                const response =  await api.newComment(this.token, obj);
                console.debug(response);
                this.getDataForumById()
            } catch (error) {
                console.error(error);
            } 
        
        },
        changeIdex(index, idForum, title, descrpition, qtd, data){
            this.activeIndex = index;
            this.idForum = idForum;
            this.title = title;
            this.description =  descrpition;
            this.qtfForum = qtd;
            this.isReadyFoum = false;
            this.dataForum = this.data[index].dateInsert;;
            this.getDataForumById()
        },
        async getDataForum(){
            try {
                const response = await api.getDataForum(this.token);
                this.data = response;
                this.isReady = true;
                this.idForum = this.data[0].id;
                this.title = this.data[0].title;
                this.description =  this.data[0].descrpition;
                this.qtfForum =  this.data[0].commentsQty
                this.dataForum =  this.data[0].dateInsert;
                this.getDataForumById();

            } catch (error) {
                console.error('Erro na listagem da notificações')
            }   
        },
        async getDataForumById(){
            try {
                const response = await api.getDataForumById(this.token, this.idForum);
                this.dataCommnet =  response;
                this.isReadyFoum =  true;
            } catch (error) {
                console.error('Erro na listagem da notificações')
            }   
        },
        updateContentLongDescription(value){
            this.comment=value;
        },
        formatarData(data) {
            const dataObj = new Date(data);
            const dia = dataObj.getDate();
            const mes = dataObj.getMonth() + 1;
            const ano = dataObj.getFullYear() % 100; // Pegando os dois últimos dígitos do ano

            // Formatando para dd/mm/aa
            const diaFormatado = dia < 10 ? "0" + dia : dia;
            const mesFormatado = mes < 10 ? "0" + mes : mes;
            const anoFormatado = ano < 10 ? "0" + ano : ano;

            return `${diaFormatado}/${mesFormatado}/${anoFormatado}`;
            },
            obterIniciais(nome) {
                const palavras = nome.split(" "); 
                let iniciais = "";
                let contador = 0;
                for (let i = 0; i < palavras.length && contador < 2; i++) {
                    const palavra = palavras[i];
                    if (palavra.length > 0) {
                    iniciais += palavra[0].toUpperCase();
                    contador++;
                    }
                }

                return iniciais;
            }
    },
    created() {
        this.getDataForum();
    },
}
</script>

<style >
.globalForum{
    background-color: #f9f9f9fc;
    height: calc(100% - 120px);
    min-height: calc(100vh - 120px);
    padding: 50px 100px;
    overflow-y: auto;
}
button.newForum {
    background-color: #19c7d0;
    padding: 8px 16px;
    border-radius: 5px;
    font-size: 14px;
    color: white;
    font-weight: 600;
}
.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.content_forum {
    display: flex;
    gap: 15px;
    margin-top: 20px;
}
.sidebar_forum {
    width: 25%;
}
.forum_primary {
    width: 75%;
    background-color: white;
    border: 1px solid #eaeaea;
    border-radius: 5px;
    text-align: left;
    padding: 20px;
    position: relative;
}
.item_sidebar {
    background-color: white;
    margin-bottom: 10px;
    padding: 15px 10px;
    border-radius: 5px;
    position: relative;
    text-align: left;
    transition: all 250ms ease-out;
    cursor: pointer;
    border: 2px solid white;
}
#triangulo-para-direita {
    display: none;
}
.item_sidebar.active  #triangulo-para-direita {
    display: block;
    width: 0;
    height: 0;
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
    border-left: 25px solid #56c9cb;
    position: absolute;
    right: -19px;
    top: 50%;
    transform: translateY(-50%) scale(0.5); 
}
.item_sidebar.active {
    border: 2px solid #56c9cb;
    box-shadow: 0px 0px 10px 1px #d6d6d6;
}
.item_sidebar h4 {
    font-size: 14px;
    font-weight: 700;
}
.content_item_sidebar {
    font-size: 13px;
    text-align: left;
}
.info_item_sidebar {
    margin: 10px 0px;
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 13px;
    color: #18c0c9;
}
.info_item_sidebar i {
    color: #56c9cb;
    font-size: 14px;
}
.autor {
    margin-top: 10px;
    display: flex;
    align-items: baseline;
}
.content_forum_primary h3 {
    font-size: 17px;
    font-weight: 700;
    margin-top: 20px;
}
.description_content_forum_primary {
    font-size: 15px;
    margin: 15px 0px;
}
.answer_content_forum_primary > span {
    font-size: 14px;
    font-weight: 700;
    margin: 10px 0px;
    display: block;
}
.ql-editor {
    min-height: 100px;
}
@media (min-height: 900px) {
    .ql-editor {
        min-height: 150px;
    }
}
.action_content_item_sidebar button {
    color: white;
    padding: 5px 30px;
    background-color: #19c7d0;
    margin: 10px 0px;
    border-radius: 5px;
}
.action_content_item_sidebar {
    text-align: right;
}
.coment_anwer_users {
    display: flex;
    align-items: flex-start;
    padding: 5px;
    margin-bottom: 5px;
    border-radius: 10px
}
.coment_anwer_users:nth-child(2n) {
    background-color: #f6f6f6;
    border-bottom: solid 2px #e3e2e3;
}
.dark .coment_anwer_users:nth-child(2n) {
    background-color: #696969;
    border-bottom: solid 2px #696969;
}
.count_coment {
    font-weight: 100;
    font-size: 13px;
    color: gray;
}
.globalForum.dark {
    background-color: black;
}
.globalForum.dark .title {
    color: white;
}
.globalForum.dark .item_sidebar {
    background-color: #353535;
    border: 2px solid black;
}
.globalForum.dark .item_sidebar.active {
    border: 2px solid #ffffff;
    box-shadow: 0px 0px 10px 1px #d6d6d6;
}
.globalForum.dark .item_sidebar h4,.globalForum.dark .content_item_sidebar{
    color: white;
}
.globalForum.dark .forum_primary {
    background-color: #363636;
    border: 1px solid #5d5d5d;
}
.globalForum.dark .forum_primary span{
    color: white;
}
.globalForum.dark .ql-snow .ql-stroke{
    color: white;
    stroke: white;
}
.globalForum.dark .ql-editor {
    color: white;
}
.globalForum.dark .content_forum_primary {
    color: white;
}
.dark .noContent h4 , .dark .noContent h{
    color: white !important;
}
.info_item_sidebar path{
    fill: none;
    stroke: #18c0c9;
    stroke-width: 20px;
    transform: scale(0.7);
}
@media (max-width:600px){
    .desktop{
        display: none;
    }
    .globalForum {
        padding: 10px;
    }
    .forum_primary {
        width: 100%;
    }
    .sidebar_forum {
        width: 100%;
    }
}
@media (min-width:600px){
    .mobile{
        display: none;
    }
}
</style>
