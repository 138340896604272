<template>
  <div class="login" :class="{ 'weflix': typeSystem == 2, 'weeduc': typeSystem == 1 }">
    <div class="content">
      <div class="info">
        <div class="logo">
          <img alt="Vue logo" src="@/assets/logo.png" style="width: 200px" v-show="typeSystem==1" />
          <img alt="Weeduc" src="@/assets/Weflix/Weflix-Logo-Total-Branco.png" style="width: 200px" v-show="typeSystem==2" />
        </div>
        <div class="form form-forgot-password">
          <form @submit.prevent="handleSubmit" class="form">
            <div class="mb-3">
              <label for="exampleInputEmail1" class="form-label">Digite o email</label>
              <input
                type="text"
                v-model="email"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
              />
              <p class="successEmail" v-show="sentEmail">E-mail enviado com sucesso.</p>
              <small>
                Um email será enviado com um link para redefinir sua senha, verifique sua caixa de entrada ou span.
              </small>
            </div>
           
            <div class="mb-5 text-center">
              <button type="submit" class="btn btn-success" :class="{'weflix': typeSystem==2, 'weeduc': typeSystem==1}">Solicitar Nova Senha</button>
              <button type="submit" class="btn btn-success backLogin" @click="backLogin">Voltar para Login</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import  { mapState, mapActions } from 'vuex';
import apiPassword from '@/utils/password.js';
import loginApi from '@/utils/login.js';


export default {
  data() {
    return {
      email: '',
      password: '',
      token: 'MTcxODc0NDIxNTE1NTo2NWIyNzFmMWMzYzAwNGE4ZDQwYmRhYzQ4NWM4ZTIyMjMwMzViMDFmMDViN2JhMTMzODcyNmNlYzRmM2U2MGJiOnNhbXVlbC5saW5rb25AdHV0b3IuZG86OGMzN2E4YjE4NWE0MmEzNjVhN2JmODU1YmM3NWVmODQyM2I1ZmE1MTc4ODc3MjgzY2NmNDMyM2Q1MzM4M2IxMmQ1ODMxYzA0YWNkOTVmMDA4NTA5MTA3NTJmZTgzOTVlOTNlNzllZDIyOGIwYmQwZjI1YWZlMTdlYmVlNWE5Zjg=',
      changePassword:false,
      sentEmail:false,
      typeSystemLocal:1,
     baseUrl: process.env.NODE_ENV === 'development' ? process.env.VUE_APP_BASE_URL_HOMOLOGATION  : window.location.origin,

    };
  },
  components: {
  },
  computed: {
    ...mapState(['typeSystem'])
  },
  methods: {
    ...mapActions(['storeTypeId','login','storeToken','storeWebsites','storeRefreshToken','storeUser','toggleDarkMode','storeNumberIntensTable','storeTypeSystem']),

  changeViewPassword(){
    if(this.showPassword=='password'){
      this.showPassword='text';
    } else {
      this.showPassword='password';
    }
  },
  async getLogo(){
    const aux = this.baseUrl.replace(/\/$/, '');
    const url = aux.split('/login')[0];
    const obj = { url: url }; 

    try {
      const response = await loginApi.logo(obj); 
      this.logo = 'https://image.weeduc.com/'+response.logo;
      this.storeTypeSystem({typeSystem:response.typeSystem});
      this.typeSystemLocal = response.typeSystem; 
    } catch (error) {
      console.error('Error fetching API:', error);
    } 
  },
  handleSubmit() {
    const aux = this.baseUrl.replace(/\/$/, '');
    const obj = {
      "url": aux.split('/forgotpassword')[0],
      'email': this.email,
      'typeSystem': this.typeSystemLocal
    }
      try {
        const response = apiPassword.resetPassowrdEmail(obj);
        console.debug(response);
        this.sentEmail = true;

      } catch (error) {
        console.error(error);
      }
    },
    backLogin(){
      this.$router.push('/login');
    }
  
  },
  mounted(){
    this.getLogo();
    this.typeSystemLocal = this.typeSystem;
  }
  
}
</script>

<style scoped>
.action {
  display: flex;
  justify-content: end;
  padding: 5px;
}
.mb-3 small {
  margin-top: 10px;
  display: block;
  color: gray;
}
.form.form-forgot-password{
  height: 240px!important;
}
.form {
  max-width: 100%;
  margin: 0 30px;
  text-align: left;
  height: 240px!important;
  position: relative;
}
.login.weeduc {
  height: 100vh;
  display: flex;
  background-image: url("../../assets/bg-login.png");
  background-size: cover;
}
.login.weflix {
  height: 100vh;
  display: flex;
  background-image: url("../../assets/Weflix/bg-login-weflix.png");
  background-size: cover;
}
button.btn.btn-success.weflix {
  background-color: #0d1575 !important;
  color: white !important;
}
.mb-5.text-center.btn {
  width: 100%;
  position: absolute;
  bottom: 0;
  background-color: #280494 !important
}
.logo {
  display: flex;
  justify-content: center;
  margin: 20px;
  display: flex;
  justify-content: center;
  margin: 20px;
  margin-top: -110px;
  margin-bottom: 50px;
}
@media (max-width:600px) {
  .info {
    width: 87%;
    padding: 0;
    
}
.logo {
  margin-top: -90px;
  margin-bottom: 50px;
}
.info {
  height: 280px;
}
}
.content {
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 10px;
}
.info {
  width: 450px;
  padding: 30px 10px;
  background-color: white;
  border-radius: 10px;
}
button.btn.btn-success {
  width: 100%;
}
.mb-3 {
  margin: 20px 0px;
}
.mb-3.forgot_password {
  color: red;
  margin: 0 !important;
  font-size: 11px;
  text-decoration: none;
  display: flex;
  justify-content: end;
}
.mb-3.forgot_password a {
  color: #19c7d0;
}
.mb-3.password {
  position: relative;
}
.mb-3.password i {
  position: absolute;
  top: 45px;
  right: 10px;
  color: #b4b4b4;
}
.company {
  max-width: 100%;
  margin: 0 30px;
  text-align: left;
  position: relative;
}
.company button.btn.btn-success {
  margin-top: 10px;
}
.option-company {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
}
p.successEmail {
  font-size: 12px;
  color: green;
  margin-top: 5px;
}
@media (max-width:600px) {
  .info {
    width: 87%;
    padding: 0;   
}
}
button.btn.btn-success.backLogin {
  background-color: #c5c5c5 !important;
  margin: 10px 0px;
}
.login {
  height: 100vh;
  display: flex;
  background-image: url("../../assets/Weflix/bg-login-weflix.png");
  background-size: cover;
}
button.btn.btn-success.backLogin:hover {
  color: black;
}
</style>
