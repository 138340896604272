<template>
    <div class="container">
        <div class="titleContainer">
            <h3>Informações de Perfil</h3>
            <small>Altere dados e informações do seu perfil.
            </small>
            
        </div>
        <div class="contetContainerEditVideo">
            <div class="col-form-inner col-12 col-md-7 col-lg-7 freeVideo">
                
              <div class="col-form-inner col-12 col-md-12 col-lg-12">
                  <div class="form-group">
                    <div class="form-label">
                      <label>Nome<v-tooltip text="Campo obrigatório" location="top">
                        <template v-slot:activator="{ props }">
                          <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                        </template>
                      </v-tooltip></label>
                    </div>
                    <div class="form-input">
                      <input
                        type="text" 
                        required
                        class="form-control"
                        v-model="comercialName"
                        placeholder="Seu nome"
                      />
                      <!-- <small class="alertText">Campo obrigatório e deve ser preenchido corretamente.</small> -->
                      <small>Nome comercial do seu curso, uso externo.</small>
                    </div>
                  </div>
            </div>
            <div class="col-form-inner col-12 col-md-12 col-lg-12">
                <div class="form-group">
                  <div class="form-label">
                    <label>Sobrenome<v-tooltip text="Campo obrigatório" location="top">
                      <template v-slot:activator="{ props }">
                        <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                      </template>
                    </v-tooltip></label>
                  </div>
                  <div class="form-input">
                    <input
                      type="text" 
                      required
                      class="form-control"
                      v-model="comercialName"
                      placeholder="Seu sobrenome"
                    />
                    <!-- <small class="alertText">Campo obrigatório e deve ser preenchido corretamente.</small> -->
                    <small>Nome comercial do seu curso, uso externo.</small>
                  </div>
                </div>
          </div>
          <div class="col-form-inner col-12 col-md-12 col-lg-12">
            <div class="form-group">
              <div class="form-label">
                <label>E-mail<v-tooltip text="Campo obrigatório" location="top">
                  <template v-slot:activator="{ props }">
                    <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                  </template>
                </v-tooltip></label>
              </div>
              <div class="form-input">
                <input
                  type="text" 
                  required
                  class="form-control"
                  v-model="comercialName"
                  placeholder="eamil@weflix.com"
                />
                <!-- <small class="alertText">Campo obrigatório e deve ser preenchido corretamente.</small> -->
                <small>Nome comercial do seu curso, uso externo.</small>
              </div>
            </div>
      </div>
      <hr/>
      <div class="col-form-inner col-12 col-md-12 col-lg-12">
        <div class="form-group">
          <div class="form-label">
            <label>Senha atual<v-tooltip text="Campo obrigatório" location="top">
              <template v-slot:activator="{ props }">
                <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
              </template>
            </v-tooltip></label>
          </div>
          <div class="form-input">
            <input
              type="text" 
              required
              class="form-control"
              v-model="comercialName"
              placeholder="*********"
            />
            <!-- <small class="alertText">Campo obrigatório e deve ser preenchido corretamente.</small> -->
            <small>Nome comercial do seu curso, uso externo.</small>
          </div>
        </div>
  </div>
  <div class="col-form-inner col-12 col-md-12 col-lg-12">
    <div class="form-group">
      <div class="form-label">
        <label>Nova senha<v-tooltip text="Campo obrigatório" location="top">
          <template v-slot:activator="{ props }">
            <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
          </template>
        </v-tooltip></label>
      </div>
      <div class="form-input">
        <input
          type="text" 
          required
          class="form-control"
          v-model="comercialName"
          placeholder="*********"
          />
        <!-- <small class="alertText">Campo obrigatório e deve ser preenchido corretamente.</small> -->
        <small>Nome comercial do seu curso, uso externo.</small>
      </div>
    </div>
</div>
<div class="col-form-inner col-12 col-md-12 col-lg-12">
    <div class="form-group">
      <div class="form-label">
        <label>Confirmar senha<v-tooltip text="Campo obrigatório" location="top">
          <template v-slot:activator="{ props }">
            <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
          </template>
        </v-tooltip></label>
      </div>
      <div class="form-input">
        <input
          type="text" 
          required
          class="form-control"
          v-model="comercialName"
          placeholder="*********"
          />
        <!-- <small class="alertText">Campo obrigatório e deve ser preenchido corretamente.</small> -->
        <small>Nome comercial do seu curso, uso externo.</small>
      </div>
    </div>
</div>
         
            </div>
          
            <div class="col-form-inner col-12 col-md-5 col-lg-5">
                <div class="col-form-inner col-12 col-md-12 col-lg-12">
                    <div class="form-group imageCoverPreview" v-if="imagePreviewCover">
                      <img class="coverVideo" :src="imagePreviewCover"  />
                        <div class="info">
                            <fa icon="fa-circle-play"/>
                        </div>
                    </div>
                  </div>
                <div class="col-form-inner col-12 col-md-12 col-lg-12 input-video">
                    <div class="form-group">
                        <div class="form-label">
                          <label>Foto de Perfil </label>
                        </div>
                        <div class="form-file">
                          <div class="file-wrapper primary">
                            <span class="thumbnail thumb-img">
                              <span class="icon">
                                <svg
                                  width="46"
                                  height="39"
                                  viewBox="0 0 46 39"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M45 19.9308L36.7274 12.3475C36.1595 11.827 35.2948 11.8047 34.7009 12.2953L18.824 25.411C18.3196 25.8277 17.6074 25.8812 17.0464 25.5446L11.1009 21.9773C10.5893 21.6703 9.94666 21.6856 9.45024 22.0166L1 27.6501"
                                    stroke="#9D9FA3"
                                    stroke-width="1.25714"
                                  ></path>
                                  <rect
                                    x="1.00049"
                                    y="1.40454"
                                    width="43.9461"
                                    height="36.1909"
                                    rx="2.58507"
                                    stroke="#9D9FA3"
                                    stroke-width="1.25714"
                                  ></rect>
                                  <circle
                                    cx="14.8948"
                                    cy="10.6676"
                                    r="3.71629"
                                    stroke="#9D9FA3"
                                    stroke-width="1.25714"
                                  ></circle>
                                </svg>
                              </span>
                              <span class="text">
                                Clique aqui para selecionar a imagem
                              </span>
                            </span>
                            <input class="form-control" type="file" @change="updateFileCover" accept=".png, .jpg, .jpeg, .webp"/>
                          </div>
                          <div class="form-caption">
                            <div class="caption">
                              <small> Tamanho: 400x400px, apenas formato PNG e JPG.</small>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
            </div>
        </div>
        <div class="actions">
            <button class="btn" @click="(()=>this.$router.push('/admin/colecao/1'))">Cancelar</button>
            <button class="btn">Salvar</button>
        </div>
      </div>
    <div class="pa-4 text-center">
        <v-dialog
        v-model="deleteItem"
        max-width="320"
        persistent
        >
        <v-card>
            <v-card-title class="headline">Atenção</v-card-title>
    
            <v-card-text>
            Deseja realmente excluir o vídeo {{ nameItem }}?
            </v-card-text>
    
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="cancel" text @click="closeDialog">Não</v-btn>
            <v-btn class="accept" text @click="closeDialog">Sim</v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
    </div>

</template>

<script>

export default {
    data(){
        return{
            deleteItem: false,
            nameItem: '',
            imagePreviewCover:'',
            season:'',
        }
    },
    components: {
    },
    methods: {
        deleteVideo(id, name){
            this.deleteItem = true;
            this.nameItem = name;
        },
        closeDialog(){
            this.deleteItem = false;
        },
        async updateFileCover(event){
            const file = event.target.files[0];
            this.imagePreviewCover = URL.createObjectURL(file);
            // try {
            //     const response =  await apiAWS.getSignatureImage(this.token, file.name);
            //     this.putFileAws(response.url, file);
            //     this.imageBanner = response.token;
            //     console.debug(response);
            // } catch (error) {
            //     console.error('Erro na geração do TOKEN AWS')
            // }
        },

    },  
}
</script>

<style scoped>
.form-group .form-label label {
    font-size: 15px;
}
.container {
    max-width: 100%;
 }
.titleContainer {
    display: block;
    text-align: left;
    padding: 0px 10px;
    border-bottom: 1px solid #e8e8e8;
    margin: 10px;
    
}
.container {
    background-color: white;
    padding: 10px 0px;
    padding-bottom: 50px;
}
.CollectionVideos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    padding: 10px;
}
.itemCollectionVideo {
    max-width: 30%;
    height: 248px;
    background-size: cover;
    min-width: 300px;
    width: 27%;
    cursor: pointer;
    position: relative;
    text-align: left;
    padding: 10px;
    border-radius: 5px;

}
.thumb-img path {
    fill: #ffffff!important;
    stroke: #aaaaaa!important;
    stroke-width: 1px!important;
}
img, svg {
    vertical-align: middle;
    width: 35px;
}
.UploadVideoContainer {
    /* max-width: 800px; */
    /* margin: 50px auto; */
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 20px;
    margin: 30px 15px;
}
.col-form-inner.col-12.col-md-12.col-lg-12.input-video {
    max-width: 800px;
    margin: 10px auto;
}
.uploadVideoIcon {
    vertical-align: middle;
    width: 46px;
    padding: 8px;
    border: 1px solid #e4e4e4;
    border-radius: 10px;
}
.infoCollectionVideos {
    text-align: left;
    padding: 0px 20px;
}
svg.svg-inline--fa {
    color: silver;
}
.infoCollectionVideos p {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 14px;
}
button.editCollections {
    position: absolute;
    top: 25px;
    right: 25px;
}
button.editCollections svg {
    transition: all 500ms ease-in-out;
}
button.editCollections:hover svg {
    color: #250891;
}
.itemCollectionVideo .actions {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    text-align: right;
    padding: 10px;
    background-color: transparent;
    transition: all 500ms ease-in-out;
}
button.hoverWhite svg{
    transition: all 500ms ease-in-out;
    font-size: 15px;
}
button.hoverWhite:hover svg{
    color: white;
}
.itemCollectionVideo p {
    color: #c8c8c8;
    margin: 0;
}
</style>
<style>
.v-card.v-theme--light.v-card--density-default.v-card--variant-elevated {
    padding: 35px 0px;
    /* text-align: left; */
}
.v-card-title.headline {
    text-align: left !important;
    color: red;
    padding: 0 40px;
}
button.v-btn.v-btn--slim.v-theme--light.text-green.darken-1.v-btn--density-default.v-btn--size-default.v-btn--variant-text {
    width: 150px;
    background-color: red;
    /* color: white; */
}
.v-card-actions button {
    width: 50%;
    background-color: gray;
    padding: 0 16px;
}
.v-overlay__content {
    width: 400px !important;
    max-width: 400px !important;
}
.v-card-actions {
    align-items: center;
    display: flex;
    flex: none;
    min-height: 52px;
    padding: 0 40px!important;
}
.cancel {
    background-color: white !important;
    border: 1px solid gray;
}
.accept {
    background-color: red !important;
    border: 1px solid red;
    color: white!important;
}
.contetContainerEditVideo {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}
.col-form-inner.col-12.col-md-7.col-lg-7.freeVideo .v-input__details {
    display: none;
}
.actions > button.btn {
    background-color: #21212100 !important;
    color: #250891 !important;
    border: 1px solid #250891 !important;
    font-size: 18px !important;
    font-weight: 100 !important;
    width: 146px;
    text-align: center;
    transition: all 500ms ease-in-out;

}
.actions {
    display: flex;
    justify-content: end;
    gap: 10px;
    padding: 0px 15px;
}
.actions > button.btn:hover {
    color: white !important;
    background-color: #250891 !important;
}
.exludeVideo {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
}
.exludeVideo svg {
    color: #250891 !important;
}
input.form-check-input {
    width: 3em !important;
}
.form-check-input:checked {
    background-color: #250891!important;
    border-color: #250891!important;
}
.col-form-inner.col-12.col-md-7.col-lg-7.freeVideo {
    display: flex;
    flex-wrap: wrap;
}
.itemAtionCollections.form-check.form-switch {
    padding: 16px 40px;
}
img.coverVideo {
    width: 300px;
    height: auto;
    margin: 0 auto;
    filter: brightness(0.5);
}
.form-group.imageCoverPreview {
    text-align: center;
    position: relative;
}
.info {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}
.info svg {
    font-size: 50px !important;
    color: white !important;
}
/* @media (max-width: 600px) {
  .modal-body.double {
    display: block;
  }
  .modal-body.double .form-group {
    width: 90%;
  }
} */
</style>