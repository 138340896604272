<template>
    <div class="container users-container reportEnrolsWeeduc">
        
        <!-- Cards -->
        <div class="cards-container">
            <div class="card">
                <h4>Total de Vendas</h4>
                <p>{{ numberSales }}</p>
            </div>
            <div class="card">
                <h4>{{ isMobile ? 'Venda Mensal' : 'Total de Vendas Mensais' }}</h4>
                <p>R$ {{ salesMonth }}</p>
            </div>
            <div class="card">
                <h4>{{ isMobile ? 'Venda Semestral' : 'Total de Vendas Semestrais' }}</h4>
                <p>R$ {{ salesSemester }}</p>
            </div>
            <div class="card">
                <h4>{{ isMobile ? 'Venda Anual' : 'Total de Vendas Anuais' }}</h4>
                <p>R$ {{ salesYear }}</p>
            </div>
        </div>

        <Loader v-if="!hasReady" />
        <div v-else class="table-responsive users-table-responsive">
            <table class="table users-table">
                <thead>
                    <tr>
                        <th style="width: 20%; text-align: left; border-right: none !important;">Nome do Cliente</th>
                        <th style="width: 20%; text-align: left; border-right: none !important; border-left: none !important;">Nome do Curso</th>
                        <th style="width: 15%; text-align: left; border-right: none !important; border-left: none !important;">Data</th>
                        <th style="width: 15%; text-align: left; border-right: none !important; border-left: none !important;">Total</th>
                        <th style="width: 5%; text-align: left; border-right: none !important; border-left: none !important;">Desconto</th>
                        <th style="width: 10%; text-align: left; border-left: none !important;">Preço</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="course in paginatedCourses" :key="course.id">
                        <td style="width: 20%; text-align: left; border-right: none !important;">{{ course.userName }}</td>
                        <td style="width: 20%; text-align: left; border-right: none !important; border-left: none !important;">{{ course.courseName }}</td>
                        <td style="width: 15%; text-align: left; border-right: none !important; border-left: none !important;">{{ formatarData(course.dateApproved) }}</td>
                        <td style="width: 15%; text-align: left; border-right: none !important; border-left: none !important;">R$ {{ course.orderTotal ? course.orderTotal.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : course.orderTotal }}</td>
                        <td style="width: 5%; text-align: left; border-right: none !important; border-left: none !important;">R$ {{ course.courseDiscount ? course.courseDiscount.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : course.courseDiscount }}</td>
                        <td style="width: 10%; text-align: left; border-left: none !important;">R$ {{ course.coursePrice ? course.coursePrice.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : course.coursePrice }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="pagination users-pagination">
            <button 
                :disabled="currentPage === 1" 
                @click="previousPage"
                class="page-btn users-page-btn"
            >
                Anterior
            </button>
            <span class="page-info users-page-info">Página {{currentPage}} de {{totalPages}}</span>
            <button 
                :disabled="currentPage === totalPages" 
                @click="nextPage"
                class="page-btn users-page-btn"
            >
                Próxima
            </button>
        </div>
    </div>
</template>

<script>
import Loader from '@/components/Loader/loader.vue';
import api from "@/utils/reports.js";
import { mapState } from "vuex";

export default {
    data() {
        return {
            label: this.labels,
            userFiltered: [],
            searchTerm: '',
            status: 'all',
            currentPage: 1,
            itemsPerPage: 5,
            alertOpenCourse: false,
            removedcoursename: '',
            afterWidth: 40,
            usersInfo: [],
            openModalCreateWarning: false,
            openModalEditWarning: false,
            hasReady: false,
            indiceItem: [false, false, false, true],
            isMobileIcon: false,
            isMobile: false,
            numberSales: 0,
            salesMonth: 0,
            salesSemester: 0,
            salesYear: 0,
        };
    },
    props: {
        labels: Array,
        searchTerm: String,
    },
    computed: {
        ...mapState(['token', 'numberIntensTable']),
        paginatedCourses() {
            if (!Array.isArray(this.userFiltered)) {
                return [];
            }
            const startIndex = (this.currentPage - 1) * this.numberIntensTable;
            const endIndex = startIndex + this.numberIntensTable;
            return Array.prototype.slice.call(this.userFiltered, startIndex, endIndex);
        },
        totalPages() {
            if (!Array.isArray(this.userFiltered)) {
                return 0;
            }
            return Math.ceil(this.userFiltered.length / this.numberIntensTable);
        },
    },
    watch: {
        searchTerm(newTerm) {
            this.filteredUsers(newTerm);
        }
    },
    methods: {
        checkIfMobile() {
            this.isMobile = window.matchMedia('(max-width: 600px)').matches;
        },
        exportToCSV() {
            const columnHeaders = ["ID", "Nome do Usuário", "Nome do Curso", "Data de Aprovação", "Total do Pedido", "Desconto do Cupom", "Desconto do Curso", "Preço do Curso"];
            let csvContent = "data:text/csv;charset=utf-8,";
            const headers = columnHeaders.join(","); 
            csvContent += headers + "\n";

            csvContent += this.userFiltered.map(user => {
                return [
                    user.id,                                  // ID
                    user.userName,                            // Nome do Usuário
                    user.courseName,                          // Nome do Curso
                    user.dateApproved ? new Date(user.dateApproved).toLocaleDateString() : "Data não disponível", // Data de Aprovação formatada
                    user.orderTotal ? user.orderTotal.toFixed(2): user.orderTotal,               // Total do Pedido formatado
                    user.cupomDiscount ? user.cupomDiscount.toFixed(2) : user.cupomDiscount,            // Desconto do Cupom formatado
                    user.courseDiscount ? user.courseDiscount.toFixed(2) : user.courseDiscount,           // Desconto do Curso formatado
                    user.coursePrice ? user.coursePrice.toFixed(2)  : user.coursePrice              // Preço do Curso formatado
                ].join(",");
            }).join("\n");

            const encodedUri = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "relatorio_vendas.csv");
            document.body.appendChild(link); 
            link.click();
        },
        filteredUsers(searchTerm) {
            if (searchTerm.trim() === '') {
                this.userFiltered = this.usersInfo;
            } else {
                this.userFiltered = this.usersInfo.filter((user) =>
                    user.userName.toLowerCase().includes(searchTerm.toLowerCase())
                );
            }
            this.currentPage = 1;
        },
        async getDataReport() {
            try {
                const response = await api.getDataEnrols(this.token);
                this.usersInfo = response.content;
                this.userFiltered = response.content;
                this.numberSales = response.totalElements;
                this.hasReady = true;

                // Get current date info
                const now = new Date();
                const currentYear = now.getFullYear();
                const currentMonth = now.getMonth();
                const currentSemester = currentMonth < 6 ? 1 : 2;

                // Calculate sales totals
                const totals = this.usersInfo.reduce((acc, sale) => {
                    const saleDate = new Date(sale.dateApproved);
                    const saleYear = saleDate.getFullYear();
                    const saleMonth = saleDate.getMonth();
                    const saleSemester = saleMonth < 6 ? 1 : 2;
                    const amount = sale.orderTotal || 0;

                    // Current year sales
                    if (saleYear === currentYear) {
                        acc.yearTotal += amount;

                        // Current semester sales
                        if (saleSemester === currentSemester) {
                            acc.semesterTotal += amount;

                            // Current month sales
                            if (saleMonth === currentMonth) {
                                acc.monthTotal += amount;
                            }
                        }
                    }

                    return acc;
                }, { monthTotal: 0, semesterTotal: 0, yearTotal: 0 });

                // Format and assign the values
                this.salesMonth = this.formatPrice(totals.monthTotal);
                this.salesSemester = this.formatPrice(totals.semesterTotal);
                this.salesYear = this.formatPrice(totals.yearTotal);

            } catch (error) {
                console.error('Erro Report Users', error);
                this.hasReady = true;
            }
        },
        formatPrice(value) {
            return value.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
        },
        filteredUsers() {
            if (this.searchTerm.trim() === '') {
                this.userFiltered = this.usersInfo;
            } else {
                this.userFiltered = this.usersInfo.filter((user) =>
                    user.userName.toLowerCase().includes(this.searchTerm.toLowerCase())
                );
            }
            return this.userFiltered;
        },
        previousPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },
        formatarData(date) {
            return new Date(date).toLocaleDateString();
        }
    },
    components: {
        Loader
    },
    created() {
        this.getDataReport();
        this.checkIfMobile();
        window.addEventListener('resize', this.checkIfMobile);
    },
};
</script>

<style scoped>
.users-container .cards-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.users-container .card {
    background-color: #e4eef5;
    border-radius: 8px;
    padding: 20px;
    width: 23%;
    text-align: left;
    margin-top: 0;
    border: none;
}

.users-container .card h4 {
    margin: 0 0 10px;
    font-size: 16px;
    color: #333;
}

.users-container .card p {
    margin: 0;
    font-size: 24px;
    font-weight: 600;
    color: #23225f;
}
.container {
    max-width: 100%;
    background-color: #ffffff;
    padding: 0 !important;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}
.search-form-input {
    position: relative;
    width: 100%;
}
.title-container {
    position: relative;
    margin-bottom: 10px;
    padding-bottom: 0;
    border-bottom: 1px solid #f0f0f0;
    text-align: left;
}

.title-container .title {
    font-size: 1.5rem;
    font-weight: 700;
    color: #000000;
    margin-bottom: 0.5rem;
}

.title-container .subtitle {
    color: #6b7280;
    font-size: 0.875rem;
    line-height: 1.4;
    margin-bottom: 1rem;
}

.search-row {
    display: flex;
    gap: 0.75rem;
    margin-bottom: 1.5rem;
}

.search-row .form-group {
    flex: 1;
}

.btn-weflix.absolute {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #2f2e8b;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: 600;
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(47, 46, 139, 0.2);
}

.btn-weflix.absolute:hover {
    background-color: #23225f;
    transform: translateY(-2px);
}

.users-table-responsive {
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 1rem;
}

.users-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
}

.users-table th {
    background-color: #f8fafc;
    padding: 0.75rem;
    font-weight: 600;
    color: #4a5568;
    text-align: left;
    border-bottom: 2px solid #e2e8f0;
}

.users-table td {
    padding: 0.75rem;
    vertical-align: middle;
    border-bottom: 1px solid #f0f0f0;
    color: #4a5568;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}

.users-table tr:hover {
    background-color: #f8fafc;
}

.badge-active, .badge-inactive {
    padding: 0.25rem 0.75rem;
    border-radius: 5px;
    font-size: 0.75rem;
    font-weight: 500;
    display: inline-block;
    text-align: center;
    transition: all 0.2s ease;
    width: 70%;
}

.badge-active {
    background-color: #7bcc8180;
    color: #4b614e;
}

.badge-inactive {
    background-color: #d7d7ee80;
    color: #2f2e8b;
}

.user-btn-edit {
    padding: 0.4rem;
    margin: 0 0.25rem;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    color: #2f2e8b;
}

.search-form-input {
    position: relative;
}

.search-form-control {
    width: 100%;
    padding: 0.5rem 0.75rem;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    font-size: 0.875rem;
    transition: all 0.2s ease;
}

.search-form-control:focus {
    outline: none;
    border-color: #2f2e8b;
    box-shadow: 0 0 0 3px rgba(47, 46, 139, 0.1);
}

.search-input-icon {
    position: absolute;
    right: 0.75rem;
    top: 45%;
    transform: translateY(-50%);
    color: #9ca3af;
    cursor: pointer;
}

.users-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
    padding-bottom: 10px;
}

.users-page-btn {
    padding: 0.5rem 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    background: white;
    color: #4a5568;
    cursor: pointer;
    transition: all 0.2s;
}

.users-page-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.users-page-btn:not(:disabled):hover {
    background: #f8fafc;
    border-color: #cbd5e0;
}

.users-page-info {
    color: #4a5568;
    font-size: 0.875rem;
}

@media (max-width: 768px) {
    .container {
        padding: 1rem;
    }

    .title-container {
        margin-bottom: 1.5rem;
    }

    .search-row {
        flex-direction: column;
    }

    .users-table th, .users-table td {
        padding: 0.5rem;
    }
}

@media (max-width: 600px) {
    .btn-weflix.absolute {
        padding: 0.5rem;
    }
    
    .btn-weflix.absolute span {
        display: none;
    }

    .users-table-responsive {
        overflow-x: auto;
    }
    .reportEnrolsWeeduc .cards-container {
        display: flex;
        gap: 4px;
    }
    .reportEnrolsWeeduc .card {
        width: 24%;
        padding: 5px;
        min-height: 70px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .reportEnrolsWeeduc .card h4 {
        font-size: 11px;
        margin-bottom: 4px;
        line-height: 1.2;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .reportEnrolsWeeduc .card p {
        font-size: 14px;
        line-height: 1.2;
    }
}
.content.dashboard > .container {
    background-color: white;
    padding: 20px;
    padding-bottom: 35px;
    box-shadow: 0px 6px 10px 0px #dfdfdf;
    min-height: 89vh;
    padding-bottom: 0;
}
select.form-control.filter-form-control {
    width: 60%;
}
.form-group.filter-form-group {
    justify-content: space-around;
    align-items: center;
    margin: 0 !important;
}
.form-group.search-form-group {
    margin: 0 !important;
}
table.table.users-table {
    border: none !important;
    padding: 0 !important;
}
button.btn-edit.user-btn-edit svg {
    color: #2f2e8b;
}
.form-group.filter-form-group {
    display: flex;
}
.btn-weflix.absolute span.flex {
    font-size: 16px;
}
.btn-weflix.absolute svg {
    color: white;
    font-size: 15px;
}
/* DARK */
.dashboard.dark .weeducAdminReports .card,
.dashboard.dark .weeducAdminReports .users-container .card {
    background-color: #575757 !important;
}
.dashboard.dark .weeducAdminReports .users-container .card h4,
.dashboard.dark .weeducAdminReports .users-container .card p,
.dashboard.dark .weeducAdminReports .page-info.users-page-info {
    color: white;
}
.dashboard.dark .weeducAdminReports .users-table-responsive {
    background-color: #000;
}
.dashboard.dark .users-table-responsive {
    background-color: #000 !important;
}
.dashboard.dark .weeducAdminReports .users-table th {
    border-bottom: 2px solid #575757 !important;
}
.dashboard.dark .weeducAdminReports .users-table td {
    border-bottom: 2px solid #575757 !important;
}
</style>
