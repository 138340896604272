<template>
    <section class="plans">
    <div class="user" v-if="hasReady">
      <div class="modal-content">
        <div class="info-user myAccountFormUserLight">
          <div class="modal-body info-perfil-image">
            <div class="form-group">
              <div class="form-label" style="text-align: center;">
                <img class="temporary" src="@/assets/user.png" v-show="!userimagePreview && !userimage"/>
                <img :src="userimagePreview? userimagePreview : userimage" alt="Logo" v-show="userimagePreview || userimage" />
              </div>
              <div class="form-label">
                <div class="form-label">
                  <label>Imagem de Perfil</label>
                </div>
                <div class="form-file">
                  <div class="file-wrapper primary">
                    <span class="thumbnail">
                      <span class="icon" v-if="loaderImage">
                        <svg
                          width="46"
                          height="39"
                          viewBox="0 0 46 39"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M45 19.9308L36.7274 12.3475C36.1595 11.827 35.2948 11.8047 34.7009 12.2953L18.824 25.411C18.3196 25.8277 17.6074 25.8812 17.0464 25.5446L11.1009 21.9773C10.5893 21.6703 9.94666 21.6856 9.45024 22.0166L1 27.6501"
                            stroke="#9D9FA3"
                            stroke-width="1.25714"
                          ></path>
                          <rect
                            x="1.00049"
                            y="1.40454"
                            width="43.9461"
                            height="36.1909"
                            rx="2.58507"
                            stroke="#9D9FA3"
                            stroke-width="1.25714"
                          ></rect>
                          <circle
                            cx="14.8948"
                            cy="10.6676"
                            r="3.71629"
                            stroke="#9D9FA3"
                            stroke-width="1.25714"
                          ></circle>
                        </svg>
                      </span>
                      <span class="text" v-if="loaderImage">
                        Clique aqui para selecionar a imagem
                      </span>
                      <LoaderImage :hasReady="loaderImage"/>
                    </span>
                    <input class="form-control" type="file"  @change="updateFilePathLogo"/>
                  </div>
                </div>
              </div>
            
            </div>
          </div>
          <div class="modal-body double edit_user">
            <div class="form-group">
              <div class="form-label">
                <label>
                  Nome  <v-tooltip text="Campo obrigatório" location="top">
                    <template v-slot:activator="{ props }">
                      <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                    </template>
                  </v-tooltip>
                </label>
              </div>
              <div class="form-input">
                <input v-model="name" type="text" class="form-control" placeholder="Nome">
              </div>
            </div>
  
            <div class="form-group">
              <div class="form-label">
                <label>
                  Eamil  <v-tooltip text="Campo obrigatório" location="top">
                    <template v-slot:activator="{ props }">
                      <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                    </template>
                  </v-tooltip>
                </label>
              </div>
              <div class="form-input">
                <input v-model="email" type="text" class="form-control" placeholder="E-mail">
              </div>
            </div>
          </div>
          <div class="modal-body double">
            <div class="form-group">
              <div class="form-label">
                <label>
                  CPF  <v-tooltip text="Campo obrigatório" location="top">
                    <template v-slot:activator="{ props }">
                      <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                    </template>
                  </v-tooltip>
                </label>
              </div>
              <div class="form-input">
                <input v-model="cpf" type="text" class="form-control" placeholder="CPF"  v-mask="'###.###.###-##'">
              </div>
            </div>
  
            <div class="form-group">
              <div class="form-label">
                <label>Identidade<v-tooltip text="Campo obrigatório" location="top">
                  <template v-slot:activator="{ props }">
                    <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                  </template>
                </v-tooltip></label>
              </div>
              <div class="form-input cep">
                <input
                  v-model="idNumber"
                  type="text"
                  class="form-control"
                  placeholder="x.xxx-xxx"
                />
              </div>
            </div>
  
            <div class="form-group">
              <div class="form-label">
                <label>
                  Telefone  <v-tooltip text="Campo obrigatório" location="top">
                    <template v-slot:activator="{ props }">
                      <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                    </template>
                  </v-tooltip>
                </label>
              </div>
              <div class="form-input">
                <input v-model="phone" type="text" class="form-control" placeholder="Telefone" v-mask="'(##) #.####-####'">
              </div>
            </div>
  
          </div>
          <div class="modal-body double">
            <div class="form-group">
              <div class="form-label">
                <label> CEP<v-tooltip text="Campo obrigatório" location="top">
                  <template v-slot:activator="{ props }">
                    <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                  </template>
                </v-tooltip></label>
              </div>
              <div class="form-input cep">
                <input
                  v-model="cep"
                  type="text"
                  class="form-control"
                  placeholder="xxxxx-xxx"
                  @change="getCEP()"
                  v-mask="'#####-###'"
  
                />
              </div>
            </div>
            <div class="form-group">
              <div class="form-label">
                <label>
                  Endereço  <v-tooltip text="Campo obrigatório" location="top">
                    <template v-slot:activator="{ props }">
                      <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                    </template>
                  </v-tooltip>
                </label>
              </div>
              <div class="form-input">
                <input v-model="address" type="text" class="form-control" placeholder="Endereço">
              </div>
            </div>
            <div class="form-group">
              <div class="form-label">
                <label>
                  Complemento  <v-tooltip text="Campo obrigatório" location="top">
                    <template v-slot:activator="{ props }">
                      <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                    </template>
                  </v-tooltip>
                </label>
              </div>
              <div class="form-input">
                <input v-model="addressComplement" type="text" class="form-control" placeholder="Endereço">
              </div>
            </div>
            
            <div class="form-group">
              <div class="form-label">
                <label>
                  Número  <v-tooltip text="Campo obrigatório" location="top">
                    <template v-slot:activator="{ props }">
                      <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                    </template>
                  </v-tooltip>
                </label>
              </div>
              <div class="form-input">
                <input v-model="addressNumber" type="text" class="form-control" placeholder="Número">
              </div>
            </div>
  
          </div>
  
          <div class="modal-body double">
            
            <div class="form-group">
              <div class="form-label">
                <label>
                  Cidade  <v-tooltip text="Campo obrigatório" location="top">
                    <template v-slot:activator="{ props }">
                      <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                    </template>
                  </v-tooltip>
                </label>
              </div>
              <div class="form-input">
                <input v-model="city" type="text" class="form-control" placeholder="Cidade">
              </div>
            </div>
  
            <div class="form-group">
              <div class="form-label">
                <label>
                  Estado  <v-tooltip text="Campo obrigatório" location="top">
                    <template v-slot:activator="{ props }">
                      <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                    </template>
                  </v-tooltip>
                </label>
              </div>
              <select class="form-select" aria-label="Default select example" v-model="state">
                <option value="state" selected>Selecione o estado</option>
                <option :value="item" v-for="item, index in states" :key="index">
                  {{item}} 
                </option>
              </select>
              
            </div>
  
            <div class="form-group">
              <div class="form-label">
                <label>
                  Pais  <v-tooltip text="Campo obrigatório" location="top">
                    <template v-slot:activator="{ props }">
                      <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                    </template>
                  </v-tooltip>
                </label>
              </div>
              <select class="form-select" aria-label="Default select example" v-model="country">
                <option value="br" selected>Brasil</option>
                <option value="outro">Outro</option>
              </select>
            </div>
          </div>
      </div>
      </div>     
    </div>
  
  </section>
  <div class="actionsSave"><button class="btnSave" @click="editUser">Salvar</button></div>
  <Loader :hasReady="hasReady" />
  </template>
  
  <script>
  import { mapState } from 'vuex';
  import Loader from '@/components/Weflix/Loader';
  import apiAWS from '@/utils/storage/aws';
  import Cep from '@/utils/cep';
  import User from '@/utils/Weflix/user';
  import LoaderImage from '@/components/Weflix/LoaderImage';
  
  export default {
    data() {
        return {
            name: '',
            email: '',
            phone: '',
            cpf: '',
            birthdate: '',
            gender: '',
            address: '',
            number: '',
            complement: '',
            neighborhood: '',
            addressComplement: '',
            city: '',
            addressNumber: '',
            state: '',
            country: 'br',
            cep: '',
            states: [
              "Acre",
              "Alagoas",
              "Amapá",
              "Amazonas",
              "Bahia",
              "Ceará",
              "Distrito Federal",
              "Espírito Santo",
              "Goiás",
              "Maranhão",
              "Mato Grosso",
              "Mato Grosso do Sul",
              "Minas Gerais",
              "Pará",
              "Paraíba",
              "Paraná",
              "Pernambuco",
              "Piauí",
              "Rio de Janeiro",
              "Rio Grande do Norte",
              "Rio Grande do Sul",
              "Rondônia",
              "Roraima",
              "Santa Catarina",
              "São Paulo",
              "Sergipe",
              "Tocantins",
            ],
            userimagePreview: '',
            userimage: '',
            hasReady: false,
            loaderImage: true,
        }
    },
    components: {
        Loader,
        LoaderImage
    },
    computed: {
        ...mapState(['token','userPortalWeflix'])
    },
    methods: {
      async getUser(){
        try {
          const response = await User.getDataUser(this.userPortalWeflix.idUser, this.userPortalWeflix.acessToken);
          this.name = response.name;
          this.email = response.email;
          this.address = response.address || '';
          this.complement = response.addressComplement || '';
          this.addressNumber = response.addressNumber || '';
          this.state = response.county || '';
          this.city = response.city || '';
          this.country = response.country || 'br';
          this.phone = response.phone || '';
          this.cep = response.postcode || '';
          this.idNumber = response.idNumber || '';
          this.cpf = response.socialNumber || '';
          this.userimage = response.photo || '';
          this.hasReady = true;
          this.userimagePreview = response.photoFinal;
        } catch (error) {
          console.error('Erro ao obter dados do usuário:', error);
        }
      },
      async editUser(){
        this.hasReady = false;
        const payload = {
          "name": this.name,
          "email": this.email,
          "phone": this.phone,
          "cpf": this.cpf,
          "birthdate": this.birthdate,
          "gender": this.gender,
          "address": this.address,  
          "addressComplement": this.addressComplement,
          "addressNumber": this.addressNumber,
          "neighborhood": this.neighborhood,
          "city": this.city,
          "county": this.state,
          "country": this.country,
          "postcode": this.cep,
          "idNumber": this.idNumber,
          "socialNumber": this.cpf,
          "status": 1,
          "type": 3,
          "photo": this.userimage
        }
        try {
          const response = await User.EditUser(payload, this.token, this.user.id );
          this.hasReady = true;
        } catch (error) {
          console.error('Erro ao editar dados do usuário:', error);
        }
      },
      async getCEP(){
        try {
          const response = await Cep.getDataCep(this.cep);
          this.address = response.logradouro;
          this.neighborhood = response.bairro;
          this.city = response.localidade;
          this.state = response.uf;
        } catch (error) {
          console.error('Erro ao obter dados do usuário:', error);
        }
      },
      async putFileAws(url, file){
        try {
            const response = await apiAWS.putFileAWS(url, file);
        } catch (error) {
            console.error('Erro ao enviar arquivo para AWS');
        }
      },
      async updateFilePathLogo(event){
        this.loaderImage = false;
          const file = event.target.files[0];
          this.userimagePreview = URL.createObjectURL(file);
          try {
              const response =  await apiAWS.getSignatureImage(this.token, file.name);
              this.putFileAws(response.url, file);
              this.userimage = response.token;
              console.debug(response);
              this.loaderImage = true;
          } catch (error) {
              console.error('Erro na geração do TOKEN AWS')
          }
      },
    },
    created(){
      this.getUser();
    }
  }
  </script>
  
  <style scoped>
  .plans{
    position: relative;
  }
  button.btnNew {
    color: #250891;
    width: 150px;
    background-color: #ffffff;
    padding: 6px 10px;
    border-radius: 5px;
    transition: all 500ms ease-in-out;
    font-size: 18px;
    border: 1px solid #250891;
    position: absolute;
    top: 0;
    right: 0;
  }
  button.btnNew:hover {
    background-color: #160266;
    color: white;
  }
  .titlelayoutLayout {
    text-align: left;
    padding: 10px;
    border-bottom: 1px solid #e3e3e3;
    width: 100%;
    margin-bottom: 10px;
  }
  .item {
    background-color: #eef0f5;
    padding: 9px 20px;
    border-radius: 5px;
    flex-direction: row;
    width: 100%;
  }
  .title {
    display: flex;
    justify-content: left;
    gap: 10px;
    align-items: center;
  }
  .description {
    display: flex;
    gap: 5px;
    align-items: center;
  }
  
  .description p{
   margin: 0;
  }
  .itens {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  .title h3 {
    margin: 0;
    font-weight: 600;
  }
  .actionsItem {
    display: flex;
    align-items: center;
    gap: 35px;
  }
  .title span {
    font-size: 13px;
    font-weight: 200;
    color: gray;
    line-height: 15px;
  }
  .description p {
    margin: 0;
    font-size: 15px;
    color: #707173;
  }
  .description span svg {
    font-size: 15px;
    color: black !important;
  }
  .description span svg path {
    fill: black !important;
  }
  .titlelayoutLayout.seondary {
    margin-top: 20px;
    position: relative;
  }
  .myAccountFormUserLight .itemAtionCollections.form-check.form-switch {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    gap: 50px;
    align-items: center;
  }
  </style>
  <style >
  .description span svg path {
    fill: #535353 !important;
  }
  .itemAtionCollections .form-check-input {
    margin-right: 1rem !important;
    cursor: pointer;
    flex-shrink: 0;
    width: 3rem;
    height: 1.rem;
    background-color: #d7d7d7;
    border: 1px solid #8d8d8d;
    box-shadow: none;
  }
  .v-slider-track__fill {
    background-color: rgb(37 8 145)!important;
  }
  .v-slider-thumb__surface.elevation-2 {
    background-color: #160266!important;
  }
  .v-slider-thumb__label {
    background-color: #250891 !important;
  }
  .v-card.v-theme--light.v-card--density-default.v-card--variant-elevated {
    padding: 15px 30px!important;
    box-shadow: none !important;
  }
  button.btnIcon.active svg path {
    fill: #e5c519 !important;
  }
  .trial p {
    color: gray;
    margin: 0;
  }
  .warning {
    text-align: left;
    padding: 0px 15px;
    background-color: #f6f6f6;
    width: 555px;
    padding: 6px;
    border-radius: 5px;
    border: 1px solid #e7e7e7;
  }
  </style>