<template>
    <div class="global_content" v-bind:class="this.dark? 'dark':''">
        <div class="bacnground ">
            <div class="title">
                <p>Meus Cursos</p>
               
            </div>
            <div class="courses" v-show="hasReady"> 
                <CourseCard v-for="course in data" 
                    :key="course.course" 
                    :image="course.course.imageFinalPath2" 
                    :title="course.course.name" 
                    :progress="progressPercentage != null ? (progressPercentage/100) : 0" 
                    :id="user.type == 3?course.id:course.course.id" 
                    :status="course.course.status"
                    :data="course.course"
                    ></CourseCard>
                    
            </div>
            <span class="loader home" v-show="!hasReady"></span>

    </div>
</div>
</template>


<script>
import Loader from '@/components/Loader/loader.vue';
import CourseCard from './CourseCard.vue';
import { mapState } from 'vuex';

export default{
    data(){
        return{
    
        }
    }, 
    components:{
        CourseCard,
        Loader
    },
    computed:{
        ...mapState(['dark','user'])
    },
    props:{
        view:Boolean,
        data: Array,
        hasReady: Boolean
    },
}
</script>
<style>
.courses {
    display: flex;
    gap: 32px 20px;
    flex-wrap: wrap;
}
.course {
    height: 263px;
    flex: 1 1 calc(33.33% - 20px);
    background-repeat: no-repeat;
    position: relative;
    border-radius: 10px;
    cursor: pointer;
    background-size: cover;
    box-sizing: border-box;
    max-width: 33%;

} 
  
  @media (max-width: 768px) {
    .course {
        flex: 1 1 calc(50% - 20px); /* Para 2 cursos por linha em telas menores */
        max-width: 50%;
    }
  }
  
  @media (max-width: 480px) {
    .course {
        flex: 1 1 100%; /* Para 1 curso por linha em telas ainda menores */
        max-width: 100%;
    }
  }
.global_content {
    background-color: white;
}
.global_content.dark {
    background-color: rgb(0, 0, 0);
}

.title{
    margin: 0;
}
.bacnground {
    padding: 30px 100px;
    margin: 0px auto;
}
.title p {
    color: #242424;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 103.6%;
    letter-spacing: 0.24px;
}
.infos {
    position: absolute;
    width: 100%;
    bottom: 15%;
    text-align: left;
    
}
.progress_course {
    align-items: center;
    display: flex;
    padding: 0 10px;
    color: #b4b1ad;
    font-size: 12px;
}
.infos p {
    padding: 0 25px;
    overflow: hidden;
    color: #F3F3F3;
    text-overflow: ellipsis;
    /* white-space: nowrap; */
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.4px;
}
.progress_course {
    display: flex;
}
.dark .title p {
    color: white;
}
.loader.home {
    z-index: 999999;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 48px;
    height: 48px;
    border: 5px solid #1AC7D0;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }
    @media only screen and (max-width:600px){
        .course {
            width: 100%;
            height: 263px;
            background-repeat: no-repeat;
            position: relative;
            border-radius: 10px;
            cursor: pointer;
            background-size: cover;
        }
        .bacnground {
            padding: 30px 20px;
            margin: 0px auto;
        }
    }    
</style>