import api from './api';

export default {
    async getDataQuiz(accessToken) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get('/quiz', {
                method: 'GET',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter listagem do Quiz:', error);
            throw error;
        }
    },

    async getDataQuizById(id,accessToken) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/quiz/${id}`, {
                method: 'GET',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error(`Erro ao obter dados da quiz: ${id}`, error);
            throw error;
        }
    },

    async createNewQuiz(payload, accessToken){
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.post('/quiz', payload, {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados da criação do quiz:', error);
            throw error;
        }
    },
    async editQuiz(id, payload, accessToken){
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.put(`/quiz/${id}`, payload, {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados da edição do quiz:', error);
            throw error;
        }
    },
    async moveUp(accessToken, id) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/question/up/${id}`,  {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao alterar ordem:', error);
                throw error;
        }
    },
    async moveDown(accessToken, id) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/question/down/${id}`,  {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao alterar ordem:', error);
                throw error;
        }
    },
}
