<template>
    <div>
        <div v-if="loading" class="loading-overlay">
            <div class="loading-spinner"></div>
        </div>
        <nav class="navbar primary navbar-expand-lg" >
            <div class="container" style="background-color: transparent!important;">
                <div class="navbar-wrapper primary">
                    <div class="wrapper-inner">
                        <div class="navbar-breadcrumb">
                            <ul class="breadcrumb">
                                <li v-for="(item, index) in items" :key="index"
                                    :class="{ 'breadcrumb-item': true, 'active': item.active }">
                                    <router-link v-if="!item.active" class="link-breadcrumb" :to="item.link">{{ item.label
                                    }}</router-link>
                                    <span v-else>{{ item.label }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="wrapper-inner">
                        <ul class="navbar-nav primary weflixMobile">
                            <li @click="changeSideBarState()" class="mobileClickMenu"> 
                                <a href="#">
                                    <fa icon="fa-bars"/>
                                </a>
                            </li>
                            <li class="select-container mobile-toolbar-weflix">
                              <select 
                                v-model="selectedCompany"
                                class="company-select"
                                @change="handleCompanySelect"
                                :disabled="loading"
                                v-if="companys.length > 1"
                              >
                                <option value="" disabled selected>Selecione a empresa atual</option>
                                <option 
                                  v-for="company in companys" 
                                  :key="company.id"
                                  :value="company.url"
                                >
                                  {{ company.nome.length > 20 ? company.nome.substring(0,20) + '...' : company.nome }}
                                </option>
                                <option v-if="companys.length === 0" disabled>
                                  Nenhuma empresa encontrada
                                </option>
                              </select>
                            </li>
                            <li>
                                <a @click="subscribeView" target="_blank" class="link tolbar" style="text-decoration: none;">
                                  <fa icon="fa-graduation-cap" class="icone-graduate" /><span>Visão do Assinante </span>
                                </a>
                            </li>
                            <li class="nav-item dropdown" @click="dropdownMenu = !dropdownMenu">
                                <span class="logout">
                                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z" stroke="#ffffff" stroke-width="0.552" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M12 14C8.13401 14 5 17.134 5 21H19C19 17.134 15.866 14 12 14Z" stroke="#ffffff" stroke-width="0.552" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                                </span>
                                <div class="dropdownMenu" :class="{'active': dropdownMenu}">
                                    <router-link to="/admin/perfil"><p>Perfil</p></router-link>
                                    <router-link to="" @click="logout"><p>Sair</p></router-link>
                                </div>
                            </li>
                            <fa icon="fa-chevron-down" :class="{'active': dropdownMenu}" @click="dropdownMenu = !dropdownMenu"/>                       
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</template>


<script>
import store from '@/store';
import { mapState, mapActions } from 'vuex';
import ApiStudentView from '@/utils/Weflix/StudentView';
import ApiCompany from '@/utils/Weflix/company';
export default {
    props: {
        items: {
            required: true,
            type: Object, 
        },
    },
    data() {
        return {
            typeUser: 0, 
            openmodaledituser:false,
            dropdownMenu:false,
            selectedCompany: "",
            filterText: '',
            filteredCompanies: [],
            loading: false
        };
    },
    computed:{
        ...mapState(['dark','user', 'websites', 'token', 'companys'])
    },
    components: {
    },
    methods: {
        ...mapActions(['storeTypeId','storeDark','toggleDarkMode','storeSideBarState','storeUserPortalWeflix','logoutWeflix','storeToken','storeRefreshToken']),
        logout(){
            localStorage.removeItem('activeIndex');
            localStorage.removeItem('logado');
            this.logoutWeflix();
            window.location.href = "/login";
           
        },
        toggleDarkModeFunction() {
            this.toggleDarkMode({dark:this.dark==true ? false : true});
        },
        atualizarValorNoFilhoEdit(novovalor){
            this.openmodaledituser = novovalor;
        },
        changeSideBarState(){
            this.storeSideBarState({sideBarState:!this.sideBarState})
        },
        async subscribeView(){

            const pyload = {
                "url": this.websites.url,
            }
            try {
                const response = await ApiStudentView.getToken(this.token, pyload);
                window.open(this.websites.url + '?tokenStudentView=' + response.token);

            } catch (error) {
            }
           
        },
        async handleCompanySelect() {
            await this.changeCompany(this.selectedCompany);
        },
        async changeCompany(url){
            this.loading = true;
            const payload = {
                "domain": url,
            }
            try {
                const response = await ApiCompany.changeCompany(this.token, payload);
                this.storeToken({token:response.acessToken});
                this.storeRefreshToken({refreshToken:response.refreshToken});
                window.location.href = '/admin/painel';
            } catch (error) {
            } finally {
                this.loading = false;
            }
        }
    },
    created() {
        this.typeUser = store.state.typeId;
    },
};
</script>

<style scoped>
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.loading-spinner {
    width: 50px;
    height: 50px;
    border: 5px solid #f3f3f3;
    border-top: 5px solid #250991;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@media (min-width: 600px) {
    .mobileClickMenu {
        display: none;
    }
}
@media (max-width: 600px) {
    .link.tolbar span {
        display: none;
    }
    .link.tolbar svg {
        height: 30px;
    }
    .mobileClickMenu svg{
        color: #250991 !important;
        height: 25px;
    }
    .navbar-nav.primary.weflixMobile {
        display: flex;
        flex-direction: row-reverse;
    }
    .navbar-nav.primary.weflixMobile .select-container {
        min-width: 100px;
        order: 1;
    }
    .select-container.mobile-toolbar-weflix {
        display: none;
    }
    .mobileClickMenu {
        position: absolute !important;
        left: 0;
    }
}

.select-container {
    min-width: 250px;
}

.company-select {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: white;
    color: #333;
    font-size: 14px;
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23333' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 12px center;
    background-size: 16px;
}

.company-select:focus {
    outline: none;
    border-color: #250991;
    box-shadow: 0 0 0 2px rgba(37, 9, 145, 0.1);
}

.company-select option {
    padding: 8px;
    font-size: 14px;
}

.company-select option:disabled {
    color: #999;
}

.company-select option:hover {
    background-color: #f5f5f5;
}

.navbar.primary {
    background-color: #fff;
    border-bottom: 1px solid #f6f6f6;
    width: 100%;
}
.container {
    max-width: 100%;
}
.navbar.primary>.container {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    max-width: 100%;
}
.navbar>.container{
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between;
}
.navbar.primary .navbar-wrapper.primary {
    display: flex;
    align-items: center;
    width: 100% !important;
    justify-content: space-between;
}
.navbar.primary .navbar-wrapper.primary>.wrapper-inner {
    display: flex;
    align-items: center;
}
.navbar.primary .navbar-nav.primary {
    display: flex;
    align-items: center;
}
.navbar.primary .navbar-nav.primary .nav-item:first-child {
    margin-right: 1rem !important;
}
.navbar.primary .navbar-breadcrumb .breadcrumb {
    padding: 0 !important;
    margin: 0 !important;
}
.navbar.primary .navbar-breadcrumb .breadcrumb .breadcrumb-item {
    padding: 0 !important;
}
.navbar.primary .navbar-breadcrumb .breadcrumb .breadcrumb-item:first-child .link-breadcrumb {
    padding-left: 0 !important;
}
.navbar.primary .navbar-breadcrumb .breadcrumb .breadcrumb-item .link-breadcrumb {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    text-decoration: none;
    color: #9d9fa3;
}
.navbar.primary .navbar-breadcrumb .breadcrumb .breadcrumb-item .link-breadcrumb.active {
    color: #18181c;
}
.dark .navbar-nav.primary svg {
    color: white;
}
.navbar-nav.primary svg {
    color: #767676;
}
li.logout {
    margin: 0 10px;
}
.dashboard.dark .logout i {
    color: white;
}
.logout i {
    color: black;
}
.image-user{
    width: 25px;
}
ul.dropdown-menu.show {
    left: -65px;
    box-shadow: 0px 0px 10px 1px #bfbfbf;
}
li.nav-item {
    cursor: pointer !important;
}
li.nav-item.icon-toolbar i {
    color: black;
    font-size: 20px;
}
.dashboard.dark li.nav-item.icon-toolbar i {
    color: white;
    font-size: 20px;
}
ul.dropdown-menu.show {
    padding: 0;
}
nav.navbar.primary.navbar-expand-lg {
    padding: 10px 0px;
}
ul.navbar-nav.primary li {
    margin: 0px 10px;
    cursor: pointer;
    position: relative;
}
.dark ul.navbar-nav.primary li a {
    color: #fff;
}
ul.navbar-nav.primary li a {
    text-decoration: none;
    color: black;
    position: relative;
}
ul.navbar-nav.primary li a.link::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #bebebe;
    top: 110%;
    left: 0;
    opacity: 0;
    transition: all 500ms linear;
}
ul.navbar-nav.primary li a.link:hover::after {
    opacity: 1;
}
nav.navbar.primary.navbar-expand-lg {
    background-color: #eef0fc;
}
li.nav-item.dropdown {
    background-color: #250891;
    border-radius: 50%;
    padding: 1px;
}
li.nav-item.dropdown path {
    stroke-width: 1.5px;
}
li.nav-item.dropdown svg {
    width: 30px;
    padding: 5px;
}
.dropdownMenu {
    position: absolute;
    top: 36px;
    right: -24px;
    width: 200px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 1px #d8d8d8;
    z-index: -9;
    opacity: 0;
    transition: all 500ms ease-in-out;
}
.dropdownMenu.active {
    position: absolute;
    top: 36px;
    right: -24px;
    width: 200px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 1px #d8d8d8;
    z-index: 99;
    opacity: 1;
}
svg.svg-inline--fa.fa-chevron-down.active {
    transform: rotate(-180deg) !important;
}
svg.svg-inline--fa.fa-chevron-down{
    transition: all 500ms ease-in-out;
    cursor: pointer;
}
.dropdownMenu a p {
    margin: 0;
    padding: 12px;
    color: #333;
    font-size: 14px;
    font-weight: 500;
    transition: all 0.3s ease;
}
.dropdownMenu a p:hover {
    background-color: #f5f5f5;
    color: #250991;
}
.dropdownMenu a p:last-child {
   border-top: 1px solid #e3e3e3;
}
svg.svg-inline--fa.fa-graduation-cap.icone-graduate {
    color: #250991;
}

a.link.tolbar:hover::after {
    display: none;
}
.vs__actions > button.vs__clear, .vs__actions > svg.vs__open-indicator {
    width: 17px;
}
</style>