<template>
    <div class="container reports-container weeducAdminReports">
        <div class="tabs">
            <button @click="activeTab = 'vendas'" :class="{ active: activeTab === 'vendas' }">Vendas</button>
            <button @click="activeTab = 'matriculas'" :class="{ active: activeTab === 'matriculas' }">Matrículas</button>
            <button @click="activeTab = 'duvidas'" :class="{ active: activeTab === 'duvidas' }">Dúvidas</button>
            <button @click="activeTab = 'forum'" :class="{ active: activeTab === 'forum' }">Fórum</button>
            <button @click="activeTab = 'avisos'" :class="{ active: activeTab === 'avisos' }">Avisos</button>
            <button @click="activeTab = 'usuarios'" :class="{ active: activeTab === 'usuarios' }">Usuários</button>
        </div>

        <div class="title-container">
            <h3 class="title">Relatórios de {{ activeTab.charAt(0).toUpperCase() + activeTab.slice(1).normalize('NFD').replace(/[\u0300-\u036f]/g, '') }}</h3>
            <div class="search-filter-wrapper weeduc-admin-reports" :class="{ 'justify-end': activeTab === 'forum' || activeTab === 'avisos' }">
                <!-- Campo de Pesquisa -->
                <div class="form-input" v-if="activeTab === 'vendas' || activeTab === 'matriculas' || activeTab === 'duvidas' || activeTab === 'usuarios'">
                    <input 
                        type="search" 
                        class="form-control"
                        v-model.trim="searchTerm" 
                        placeholder="Pesquisar..."
                        @keyup.enter="onClickSearchReport"
                    />
                </div>
                <div class="btn-weflix" @click="onClickSearchReport" v-if="activeTab === 'vendas' || activeTab === 'matriculas' || activeTab === 'duvidas' || activeTab === 'usuarios'">
                    <span  class="flex"><fa icon="fa-solid fa-magnifying-glass"></fa></span>
                </div>
                <div class="btn-filter-container">
                    <!-- Botões -->
                    <div class="btn-container">
                        <div class="btn-weflix" @click="exportToCSV">
                            <fa icon="fa fa-file-export"></fa>
                            <span v-show="!isMobileIcon" class="flex">Exportar</span>
                        </div>
                    </div>
                    <!-- Filtro de Status -->
                    <div class="filter-wrapper" v-if="['duvidas', 'forum', 'avisos', 'usuarios'].includes(activeTab)">
                        <label for="status-filter">Filtrar por:</label>
                        <select 
                            id="status-filter" 
                            class="select-status" 
                            v-model="status" 
                            @change="filteredCoursesStatus"
                        >
                            <option value="all" selected>Todos</option>
                            <option value="0">Inativo</option>
                            <option value="1">Ativo</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="activeTab === 'vendas'" class="tab-content">
            <ReportSales ref="reportSales" :searchTerm="searchTerm" />
        </div>
        <div v-if="activeTab === 'matriculas'" class="tab-content">
            <ReportCourses ref="reportCourses" :searchTerm="searchTerm"/>
        </div>
        <div v-if="activeTab === 'duvidas'" class="tab-content">
            <ReportDoubts ref="reportDoubts" :filterStatus="status" />
        </div>
        <div v-if="activeTab === 'forum'" class="tab-content">
            <ReportForum ref="reportForum" />
        </div>
        <div v-if="activeTab === 'avisos'" class="tab-content">
            <ReportWarnings ref="reportWarnings"/>
        </div>
        <div v-if="activeTab === 'usuarios'" class="tab-content">
            <ReportUsers ref="reportUsers"/>
        </div>
    </div>
</template>

<script>

import Loader from '@/components/Loader/loader.vue';
// import ApiReport from '@/utils/report';
import { mapState } from 'vuex';
import ReportEnrols from '@/components/Admin/Reports/courses.vue';
import ReportForum from '@/components/Admin/Reports/forum.vue';
import ReportUsers from '@/components/Admin/Reports/users.vue';
import ReportSales from '@/components/Admin/Reports/enrols.vue';
import ReportWarnings from '@/components/Admin/Reports/warnings.vue';
import ReportDoubts from '@/components/Admin/Reports/doubts.vue';
import ReportCourses from '@/components/Admin/Reports/courses.vue';


export default {
    data() {
        return {
            label: this.labels,
            reportsFiltered: [],
            searchTerm: '',
            currentPage: 1,
            itemsPerPage: 5,
            alertOpenReport:false,
            removedReportName:'',
            afterWidth:40,
            reportsData:[],
            openModalCreateReport:false,
            openModalEditReport:false,
            id:'',
            message:'',
            title:'',
            hasReady: false,
            status: '1',
            isMobileIcon: false,
            statusFilter: 'todos',
            searchText: '',
            activeTab: 'vendas',
        };
    },
    mounted() {
        this.checkScreenSize();
        window.addEventListener('resize', this.checkScreenSize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.checkScreenSize);
    },
    computed:{
        ...mapState(["token","numberIntensTable"]),

        paginatedReports() {
            const startIndex = (this.currentPage - 1) * this.numberIntensTable;
            const endIndex = startIndex + this.numberIntensTable;
            return this.filteredReports.slice(startIndex, endIndex);
        },
        totalPages() {
            return Math.ceil(this.filteredReports.length / this.numberIntensTable);
        },
        filteredReports() {
            let filtered = this.reportsData;

            if (this.searchText && this.searchText.trim() !== '') {
                filtered = filtered.filter((report) =>
                    report.title.toLowerCase().includes(this.searchText.toLowerCase())
                );
            }
            if (this.statusFilter !== 'todos') {
                filtered = filtered.filter((report) =>
                    report.status && (report.status === (this.statusFilter === 'ativo' ? 1 : 0))
                );
            }

            return filtered;
        }
    },
    methods: {
        filtrarReports() {
            this.currentPage = 1;
        },
        previousPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },
        removeReport(id, name){
            this.removedReportName = name;
            this.alertOpenReport = !this.alertOpenReport;
            setTimeout(() => {
                this.alertOpenReport = !this.alertOpenReport;
                this.removedReportName = '';
            }, 4000);
        },
        editReport(id, title, description, status){
            this.openModalEditReport = !this.openModalEditReport;
            this.id = id;
            this.title = title;
            this.message = description;
            this.status = status;
        },
        createReport(){
            this.openModalCreateReport = !this.openModalCreateReport;
        },
        updateValueCreate(newValue){
            this.openModalCreateReport = newValue;
            this.getDataReport();
        },
        updateValueEdit(newValue){
            this.getDataReport();

            this.openModalEditReport = newValue;
        },
        checkScreenSize() {
            this.isMobileIcon = window.innerWidth <= 600;
        },
        // exportoToCSV () {
        //     this.$refs.reportSales.exportToCSV();
        // },
        // testCSV() {
        //     this.$refs.reportSales.testSonCSV();
        // },
        onSearchInput() {
            console.log('Search term updated:', this.searchTerm);
        },
        onClickSearchReport() {
            console.log('Search term in parent:', this.searchTerm);
            if (this.activeTab === 'matriculas' && this.$refs.reportCourses) {
                console.log('Calling child component with search term:', this.searchTerm);
                this.$refs.reportCourses.getDataReport(this.searchTerm);
            }
        },
        exportToCSV() {
            const exportMap = {
                "vendas": "reportSales",
                "matriculas": "reportEnrols",
                "duvidas": "reportDoubts",
                "forum" : "reportForum",
                "avisos": "reportWarnings",
                "usuarios": "reportUsers",
            };

            const refName = exportMap[this.activeTab];
            
            if (refName && this.$refs[refName]) {
                this.$refs[refName].exportToCSV();
            }
        },
        async getReportData(){
            try{
                const response = await ApiReport.getDataReports(this.token);
                this.reportsData = response.content;
                this.hasReady = true;
            }
            catch(err){
                this.hasReady = false;
                console.error('Erro ao carregar dados do Relatório')
            }
        },
    },
    components: {
        Loader,
        ReportEnrols,
        ReportForum,
        ReportUsers,
        ReportSales,
        ReportWarnings,
        ReportDoubts,
        ReportCourses
    },
    created(){
        this.getReportData();
    },
    
};
</script>

<style scoped>
.container.reports-container {
    padding: 1.5rem;
    width: 100%;
}

.search-filter-wrapper.weeduc-admin-reports {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
}

.weeduc-admin-reports .form-input {
    display: flex;
    align-items: center;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    width: 60%;
    max-width: 830px;
    height: 42px;
}

.weeduc-admin-reports .form-input input {
    flex: 1; 
    border: none; 
    outline: none;
    font-size: 14px; 
    padding: 0.5rem; 
    color: #333; 
}

.weeduc-admin-reports .form-input .btn-search {
    background: transparent; 
    border: none; 
    cursor: pointer; 
    padding: 0.5rem; 
    display: flex; 
    align-items: center; 
    justify-content: center; 
    color: #555; 
}

.weeduc-admin-reports .form-input .btn-search:hover {
    color: #000; 
}

.weeduc-admin-reports .btn-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.weeduc-admin-reports .btn-weflix {
    background-color: #2f2e8b;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: 600;
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(47, 46, 139, 0.2);
}

.weeduc-admin-reports .btn-weflix:hover {
    background-color: #23225f;
    transform: translateY(-2px);
}

.weeduc-admin-reports .btn-weflix svg {
    color: white;
}

.weeduc-admin-reports .form-input .btn-search:hover {
    color: #000; 
}

.weeduc-admin-reports .filter-wrapper {
    display: flex; 
    align-items: center; 
    gap: 0.5rem; 
}

.weeduc-admin-reports .filter-wrapper label {
    font-size: 14px; 
    color: #555; 
}

.weeduc-admin-reports .select-status {
    border: 1px solid #ccc; 
    border-radius: 8px; 
    padding: 0.5rem; 
    font-size: 14px; 
    color: #333; 
    background-color: #fff; 
}
.weeduc-admin-reports .form-control:focus {
    box-shadow: none;
}

.weeduc-admin-reports .btn-container svg {
    color: white;
}

.title-container {
    position: relative;
    margin-bottom: 10px;
    padding-bottom: 0;
    text-align: left;
}

.title-container .title {
    font-size: 1.5rem;
    font-weight: 700;
    color: #000000;
    margin-bottom: 0.5rem;
}

.title-container .subtitle {
    color: #6b7280;
    font-size: 0.875rem;
    line-height: 1.4;
    margin-bottom: 1rem;
}

.search-row {
    display: flex;
    gap: 0.75rem;
    margin-bottom: 1.5rem;
}

.search-row .form-group {
    flex: 1;
}

.btn-weflix.absolute {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #2f2e8b;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: 600;
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(47, 46, 139, 0.2);
}

.btn-weflix.absolute:hover {
    background-color: #23225f;
    transform: translateY(-2px);
}

.cupons-table-responsive {
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 1rem;
}

.cupons-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
}

.cupons-table th {
    background-color: #f8fafc;
    padding: 0.75rem;
    font-weight: 600;
    color: #4a5568;
    text-align: left;
    border-bottom: 2px solid #e2e8f0;
}

.cupons-table td {
    padding: 0.75rem;
    vertical-align: middle;
    border-bottom: 1px solid #f0f0f0;
    color: #4a5568;
}

.cupons-table tr:hover {
    background-color: #f8fafc;
}

.badge-active, .badge-inactive {
    padding: 0.25rem 0.75rem;
    border-radius: 5px;
    font-size: 0.75rem;
    font-weight: 500;
    display: inline-block;
    text-align: center;
    transition: all 0.2s ease;
    width: 70%;
}

.badge-active {
    background-color: #7bcc8180;
    color: #4b614e;
}

.badge-inactive {
  background-color: #d7d7ee80;
    color: #2f2e8b;
    }

.cupom-btn-edit {
    padding: 0.4rem;
    margin: 0 0.25rem;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
   color: #2f2e8b;
}


.search-form-input {
    position: relative;
}

.search-form-control {
    width: 100%;
    padding: 0.5rem 0.75rem;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    font-size: 0.875rem;
    transition: all 0.2s ease;
}

.search-form-control:focus {
    outline: none;
    border-color: #2f2e8b;
    box-shadow: 0 0 0 3px rgba(47, 46, 139, 0.1);
}

.search-input-icon {
    position: absolute;
    right: 0.75rem;
    top: 45%;
    transform: translateY(-50%);
    color: #9ca3af;
    cursor: pointer;
}

.cupons-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
}

.cupons-page-btn {
    padding: 0.5rem 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    background: white;
    color: #4a5568;
    cursor: pointer;
    transition: all 0.2s;
}

.cupons-page-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.cupons-page-btn:not(:disabled):hover {
    background: #f8fafc;
    border-color: #cbd5e0;
}

.cupons-page-info {
    color: #4a5568;
    font-size: 0.875rem;
}

@media (max-width: 768px) {
    .container {
        padding: 1rem;
    }

    .title-container {
        margin-bottom: 1.5rem;
    }

    .search-row {
        flex-direction: column;
    }

    .cupons-table th, .cupons-table td {
        padding: 0.5rem;
    }
}

@media (max-width: 600px) {
    .container.reports-container {
        padding: 1rem;
    }

    .search-filter-wrapper.weeduc-admin-reports {
        flex-wrap: wrap;
        gap: 10px;
    }

    .weeduc-admin-reports .form-input {
        width: calc(100% - 130px);
        margin-right: 0;
    }

    .weeduc-admin-reports .btn-filter-container {
        display: flex;
        width: auto;
        margin-left: 0;
    }

    .weeduc-admin-reports .btn-weflix:not(.btn-container .btn-weflix) {
        position: absolute;
        right: 75px;
        top: 3px;
        height: 35px;
        width: 50px;
        display: flex;
        align-items: center;
    }
    .weeduc-admin-reports .btn-weflix {
        width: 50px;
    }

    .weeduc-admin-reports .btn-container {
        position: absolute;
        right: 17px;
        top: 0;
        height: 42px;
        display: flex;
        align-items: center;
    }

    .weeduc-admin-reports .filter-wrapper {
        width: 100%;
        position: relative;
        right: 140px;
        margin-top: 10px;
    }

    .search-filter-wrapper {
        position: relative;
    }
    .weeduc-admin-reports .btn-weflix .flex {
        display: flex;
        position: relative;
        right: 7px;
    }
    .badge-active, .badge-inactive {
        padding: 5px 5px !important;
        border-radius: 5px;
        font-size: 0.75rem;
        font-weight: 500;
        display: inline-block;
        text-align: center;
        transition: all 0.2s ease;
        width: 100% !important;
    }
}
.content.dashboard > .container {
    background-color: white;
    padding: 20px;
    padding-bottom: 35px;
    box-shadow: 0px 6px 10px 0px #dfdfdf;
    min-height: 89vh;
    padding-bottom: 0;
}
select.form-control.filter-form-control {
    width: 60%;
}
.form-group.filter-form-group {
    justify-content: space-around;
    align-items: center;
    margin: 0 !important;
}
.form-group.search-form-group {
    margin: 0 !important;
}
table.table.cupons-table {
    border: none !important;
    padding: 0 !important;
}
button.btn-edit.cupom-btn-edit svg {
    color: #2f2e8b;
}
.form-group.filter-form-group {
    display: flex;
}
.btn-weflix.absolute span.flex {
    font-size: 16px;
}
.btn-weflix.absolute svg {
    color: white;
    font-size: 15px;
}
.reports-container .tabs {
    display: flex;
    gap: 1rem;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid #eeeeee;
    overflow-x: auto;
    overflow-y: hidden;
}

.tabs button {
    padding: 0.5rem 1rem;
    background: white;
    color: #4a5568;
    cursor: pointer;
    transition: all 0.2s;
    position: relative;
    font-weight: 800;
}

.tabs button.active {
    color: #2f2e8b;
}

.tabs button::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background: #2f2e8b;
    position: absolute;
    bottom: -2px;
    left: 0;
    transform: scaleX(0);
    transition: transform 0.2s ease;
}

.tabs button.active::after {
    transform: scaleX(1);
}

.tab-content {
    background: #f8fafc;
    padding: 0rem;
    border-radius: 6px;
}
.container.reports-container .btn-weflix.absolute {
    top: -100px;
    right: -15px;
}

.reports-container .btn-filter-container {
    display: flex;
    gap: 10px;
    margin-left: auto;
}
/* DARK */
.dashboard.dark .content.dashboard > .container.reports-container.weeducAdminReports {
   background-color: #000; 
}
.dashboard.dark .container.reports-container.weeducAdminReports .tabs button {
    background-color: #000;
}
.dashboard.dark .container.reports-container.weeducAdminReports .tabs button.active {
    color: white;
    background-color: #444;
}
.dashboard.dark .container.reports-container.weeducAdminReports .tabs button {
    color: #c9c9c9;
}
.dashboard.dark .container.reports-container.weeducAdminReports h3,
.dashboard.dark .weeduc-admin-reports .filter-wrapper label {
    color: white;
}
.dashboard.dark .container.reports-container.weeducAdminReports input.form-control,
.dashboard.dark .container.reports-container.weeducAdminReports .users-container .card {
    background-color: #575757 !important;
}
.dashboard.dark .container.reports-container.weeducAdminReports .weeduc-admin-reports .form-input {
    background-color: #575757;
    color: white;
    border: 1px solid #444;
}
.dashboard.dark .container.reports-container.weeducAdminReports .users-container .cards-container,
.dashboard.dark .container.reports-container.weeducAdminReports .container {
    background-color: #000;
}
.dashboard.dark .users-table-responsive {
    background-color: #000 !important;
}
.dashboard.dark .weeduc-admin-reports .select-status {
    background-color: #575757;
    color: white;
    border: 1px solid #444;
}
.dashboard.dark .weeducAdminReports .reports-container .tabs {
    border-bottom: 1px solid #444 !important;
}
.dashboard.dark .weeducAdminReports .tab-content {
    background: #000 !important;
}
</style>