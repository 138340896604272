<template>
    <section class="section link-list primary weflix-admin-settings">
      <div class="link-list-content">
        <div class="content-link create-course">
          <div class="link">
            <a
              class="link-content"
              :class="[isActiveTab(1)? 'active': '']"
              @click="setActiveTab(1)"
            >
              Geral
            </a>
          </div>
          <!-- <div class="link">
            <a
              class="link-content"
              :class="[isActiveTab(2)? 'active': '']"
              @click="setActiveTab(2)"
            >
            Perfil
            </a>
          </div> -->
          <div class="link">
            <a
              class="link-content"
              :class="[isActiveTab(3)? 'active': '']"
              @click="setActiveTab(3)"
            >
              Domínios
            </a>
          </div>

        </div>
      </div>
    </section>

     <section
      class="section config-form primary"
      :class="[isActiveTab(1)? 'active': '']"
    >
      <General />
    </section>
  
   <section
    class="section config-form primary"
    :class="[isActiveTab(2)? 'active': '']"
  >
    <Perfil />
  </section>

    <section
    class="section config-form primary"
    :class="[isActiveTab(3)? 'active': '']"
  >
    <Domain />
  </section>


  </template>
  
  <script>
  import General from '@/components/Weflix/Config/genereal.vue';
  import Perfil from '@/components/Weflix/Config/perfil.vue';
  import Domain from '@/components/Weflix/Config/domain.vue';
  import { mapActions } from 'vuex';

  export default {
    data() {
      return {
        activeTab: 1,
        hassaved:false,
        openmodalmodule:false,
      }
    },
    props: {
  
    },
    components: {
      General,
      Perfil,
      Domain
    },
    methods: {
      ...mapActions(['storeToken']),
      setActiveTab(tabNumber) {
        this.activeTab = tabNumber;
      },
      isActiveTab(tabNumber) {
        return this.activeTab === tabNumber;
      },
      settoken(token){
        this.storeToken({token:token});
      }
    },
    created() {
  
    }
  }
  </script>
  
  <style scoped>
  @import url(./global.css);

  .container {
    max-width: 100%;
 }
  .link a {
    display: inline-block;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: rgb(24, 24, 28);
    background-color: rgb(255, 255, 255);
    text-decoration: none;
    padding: 0.5rem 2rem;
    border-bottom: 1px solid rgb(246, 246, 246);
    border-right: 1px solid rgb(246, 246, 246);
    cursor: pointer;
}
.link-list-content .content-link .link .link-content.active {
    color: rgb(255, 255, 255);
    background-color: #250891;
    border-right: 1px solid #250891;
}
section.section.config-form.primary.active {
    display: block;
    height: 83vh;
    overflow-y: auto;
}
section.section.config-form.primary {
  display: none;
}
.content-link.create-course {
  display: flex;
}
.content-link.create-course .link:first-child a {
  border-radius: 10px 0px 0px 0px;
}
@media (max-width: 600px) {
  .weflix-admin-settings .content-link.create-course {
    overflow: hidden;
    overflow-x: auto;
    height: 45px;
  }
  .weflix-admin-settings .content-link.create-course > .link {
      flex-grow: 1;
      width: fit-content !important;
  }
  .weflix-admin-settings .content-link.create-course .link a {
    width: -webkit-fill-available;
  }
  .weflix-admin-settings .content-link.create-course .link:last-child a {
    border-radius: 0px 10px 0px 0px;
  }
}
section.section.config-form.primary {
  background-color: white;
}
  </style>
  