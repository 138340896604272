<template>
    <section class="plans">
    <div class="titlelayoutLayout" v-if="hasReady">
        <h3>Planos</h3>
        <small>Crie e personalize um plano mensal, trimestral, semestral ou anual e defina descontos no pagamento à vista.</small>
        <button class="btnNew" @click="(()=>this.$router.push('/admin/novo-plano'))">
            <span v-if="!isMobileIcon">Novo Plano</span>
            <fa v-else icon="fa fa-plus"></fa>
        </button>
    </div>
    <div class="plan" v-if="hasReady">
        <div class="itens">
            <div class="item myPlanWeflixStyle" v-for="plan in plans" :key="plan.id">
                <div class="infoItemPlan">
                    <div class="title">
                        <h3>{{plan.name}}</h3>
                        <span>R$ {{formatarNumero(plan.planValue)}}</span>
                    </div>
                    <div class="description">
                        <p>Cobrança 
                            <span v-if="plan.planType == 1">Mensal</span>
                            <span v-if="plan.planType == 2">Semestral</span> 
                            <span v-if="plan.planType == 3">Trimestral</span>
                            <span v-if="plan.planType == 4">Anual</span>
                        </p>
                        <span><fa icon="fa-credit-card" /></span>
                        <!-- <span v-if="plan.billet == 1"><fa icon="fa-barcode" /></span>
                        <span v-if="plan.pix == 1">
                            <svg style="width: 15px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path d="M242.4 292.5C247.8 287.1 257.1 287.1 262.5 292.5L339.5 369.5C353.7 383.7 372.6 391.5 392.6 391.5H407.7L310.6 488.6C280.3 518.1 231.1 518.1 200.8 488.6L103.3 391.2H112.6C132.6 391.2 151.5 383.4 165.7 369.2L242.4 292.5zM262.5 218.9C256.1 224.4 247.9 224.5 242.4 218.9L165.7 142.2C151.5 127.1 132.6 120.2 112.6 120.2H103.3L200.7 22.8C231.1-7.6 280.3-7.6 310.6 22.8L407.8 119.9H392.6C372.6 119.9 353.7 127.7 339.5 141.9L262.5 218.9zM112.6 142.7C126.4 142.7 139.1 148.3 149.7 158.1L226.4 234.8C233.6 241.1 243 245.6 252.5 245.6C261.9 245.6 271.3 241.1 278.5 234.8L355.5 157.8C365.3 148.1 378.8 142.5 392.6 142.5H430.3L488.6 200.8C518.9 231.1 518.9 280.3 488.6 310.6L430.3 368.9H392.6C378.8 368.9 365.3 363.3 355.5 353.5L278.5 276.5C264.6 262.6 240.3 262.6 226.4 276.6L149.7 353.2C139.1 363 126.4 368.6 112.6 368.6H80.8L22.8 310.6C-7.6 280.3-7.6 231.1 22.8 200.8L80.8 142.7H112.6z"/>
                            </svg>
                        </span>  -->
                    </div>
                </div>
                <div class="actionsItem">
                    <!-- <button class="btnIcon"><fa icon="fa-trash" /></button> -->
                    <button class="btnIcon" @click="(()=>this.$router.push(`/admin/editar-plano/${plan.id}`))"><fa icon="fa-pencil" /></button>
                    <!-- <button class="btnIcon" :class="{ active: i === 1 }"><fa icon="fa-star" /></button> -->

                </div>
            </div>
        </div>
    </div>
    <div class="titlelayoutLayout seondary" v-if="hasReady">
        <h3>Trial</h3>
        <small>Defina um período de teste grátis para seus novos assinantes.</small>
        <div class="itemAtionCollections form-check form-switch paymentPlan">
            <span>Período {{statusTrial}}: </span>
            <input
            class="form-check-input"
            type="checkbox"
            role="switch"
            v-model="isTrialActive"
            @change="changeStatusTrial"
          />
        </div>
    </div>
    <div class="trial" v-if="isTrialActive && hasReady">
        <p>Seus assinantes poderão experimentar seu conteúdo por <strong style="color: #250891;">{{trialRange[1]}} dias</strong> gratuitamente..</p>
        <v-card>
            <v-card-text>
              <v-range-slider
                v-model="trialRange"
                :min="3"
                :max="30"
                :step="1"
                thumb-label="always"
                strict
              >
                <template v-slot:thumb-label="{ modelValue }">
                  {{ modelValue }} 
                </template>
              </v-range-slider>
            </v-card-text>
          </v-card>
          <div class="warning">
            <p>
               <strong>Atenção:</strong> Alterar o período de teste afetará apenas novos assinantes.
            </p>
          </div>
    </div>
</section>
<div data-v-41e9f2f5="" class="actionsSave planWeflix admin-weflix-payment"><button data-v-41e9f2f5="" @click="editTrial" class="btnSave">Salvar</button></div>
<Loader :hasReady="hasReady" />
</template>
<script>
import Plan from '@/utils/Weflix/plan';
import { mapState } from 'vuex';
import Loader from '@/components/Weflix/Loader';
export default {
    data() {
        return {
            statusTrial: 'Desativado',
            isTrialActive: false,
            trialRange: [3, 3],
            modelValue:0,
            plans: [],
            hasReady: false,
            windowWidth: window.innerWidth,
            isMobileIcon: window.innerWidth < 600,
        }
    },
    mounted() {
        window.addEventListener("resize", this.handleResize);
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.handleResize);
    },
    components: {
        Loader
    },
    computed: {
        ...mapState(['token'])
    },
    methods: {
        changeStatusTrial() {
            this.statusTrial = this.isTrialActive ? 'Ativado' : 'Desativado'
        },
        async getPlans(){
            try {
                const response = await Plan.getPlans(this.token);
                this.hasReady = true;
                this.plans = response;
            } catch (error) {
                console.error(error);
            }
        },
        formatarNumero(numero) {
            let number = parseFloat(numero).toFixed(2);
            return number.replace('.', ',')
        },
        async editTrial(){
            this.hasReady = false;
            const payload = {
                testPlan: this.trialRange[1]
            }
            try {
                const response = await Plan.editTrial( payload, this.token)
                this.hasReady = true;
            } catch (error) {
                console.error(error);
            }
        },
        async getTrial(){
            try {
                const response = await Plan.getTrial(this.token);
                this.trialRange[1] = response;
                if( this.trialRange[1]<=3){
                   this.isTrialActive = false;
                }else{
                    this.isTrialActive = true;
                    this.changeStatusTrial();
                }
            } catch (error) {
                console.error(error);
            }
        }
    },
    created(){
        this.getPlans();
        this.getTrial();
    },
    handleResize() {
        this.windowWidth = window.innerWidth;
    },
}
</script>

<style scoped>
.plans{
    position: relative;
}
button.btnNew {
    color: #250891;
    width: 150px;
    background-color: #ffffff;
    padding: 6px 10px;
    border-radius: 5px;
    transition: all 500ms ease-in-out;
    font-size: 18px;
    border: 1px solid #250891;
    position: absolute;
    top:10px;
    right: 10px;
}
button.btnNew:hover {
    background-color: #160266;
    color: white;
}
.titlelayoutLayout {
    text-align: left;
    padding: 10px;
    border-bottom: 1px solid #e3e3e3;
    width: 100%;
    margin-bottom: 10px;
}
.item {
    background-color: #eef0f5;
    padding: 9px 20px;
    border-radius: 5px;
    flex-direction: row;
    width: 100%;
}
.item.myPlanWeflixStyle {
    display: flex;
    justify-content: space-between;
}
.title {
    display: flex;
    justify-content: left;
    gap: 10px;
    align-items: center;
}
.description {
    display: flex;
    gap: 5px;
    align-items: center;
}

.description p{
   margin: 0;
}
.itens {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}
.title h3 {
    margin: 0;
    font-weight: 600;
}
.actionsItem {
    display: flex;
    align-items: center;
    gap: 35px;
}
.title span {
    font-size: 13px;
    font-weight: 200;
    color: gray;
    line-height: 15px;
}
.description p {
    margin: 0;
    font-size: 15px;
    color: #707173;
}
.description span svg {
    font-size: 15px;
    color: black !important;
}
.description span svg path {
    fill: black !important;
}
.titlelayoutLayout.seondary {
    margin-top: 20px;
    position: relative;
}
.itemAtionCollections.form-check.form-switch.paymentPlan {
    display: flex;
    gap: 50px;
    align-items: center;
    justify-content: flex-start;
    padding-left: 0;
}
</style>
<style scoped>
.description span svg path {
    fill: #535353 !important;
}
.itemAtionCollections .form-check-input {
    margin-right: 1rem !important;
    cursor: pointer;
    flex-shrink: 0;
    width: 3rem;
    height: 1.rem;
    background-color: #d7d7d7;
    border: 1px solid #8d8d8d;
    box-shadow: none;
}
.v-slider-track__fill {
    background-color: rgb(37 8 145)!important;
}
.v-slider-thumb__surface.elevation-2 {
    background-color: #160266!important;
}
.v-slider-thumb__label {
    background-color: #250891 !important;
}
.v-card.v-theme--light.v-card--density-default.v-card--variant-elevated {
    padding: 15px 30px!important;
    box-shadow: none !important;
}
button.btnIcon.active svg path {
    fill: #e5c519 !important;
}
.trial p {
    color: gray;
    margin: 0;
}
.warning {
    text-align: left;
    padding: 0px 15px;
    background-color: #f6f6f6;
    width: 555px;
    padding: 6px;
    border-radius: 5px;
    border: 1px solid #e7e7e7;
}
@media (max-width: 600px) {
    button.btnNew {
        padding: 1px 1px !important;
        max-width: 40px;
        width: auto;
    }
    button.btnNew svg {
        color: #250891;
    }
    button.btnNew:hover svg {
        color: white;
    }
    .warning {
        width: 100%;
    }
    .v-card.v-theme--light.v-card--density-default.v-card--variant-elevated {
        padding: 15px 0px !important;
    }
    .itemAtionCollections.form-check.form-switch.paymentPlan {
        position: absolute;
        top: -10px;
        right: -50px;
        display: flex;
        gap: 50px;
        align-items: center;
    }
    .actionsSave.planWeflix {
        position: inherit!important;
        bottom: 0px;
        right: 5px;
        margin: 0;
        padding-left: 0;
    }
    .planWeflix button.btnSave {
        width: 72vw;
    }
    .actionsSave.planWeflix.admin-weflix-payment {
        padding: 10px 0px 10px 0px;
    }
}
.actionsSave.planWeflix {
    position: inherit!important;
    bottom: 0px;
    right: 5px;
    margin: 0;
    padding-left: 0;
}

.plan {
    padding: 10px;
  }
</style>