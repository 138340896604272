<template>
    <div class="container users-container adminWeeducUsers">
        <div class="title-container">
            <h3 class="title">Usuários</h3>
            <p class="subtitle">Gerencie os usuários do sistema.</p>
            <div class="btn-weflix absolute" @click="(()=>this.$router.push('/dashboard/novo-usuario'))">
                <fa icon="fa fa-plus"></fa>
                <span v-show="!isMobileIcon" class="flex">Novo Usuário</span>
            </div>
        </div>

        <div class="search-row">
            <div class="form-group search-form-group" style="flex: 8;">
                <div class="form-input search-form-input">
                    <input
                        type="text" 
                        class="form-control search-form-control"
                        placeholder="Pesquisar usuário..."
                        v-model="searchText"
                    />
                    <fa icon="fa-search" class="input-icon search-input-icon" @click="buscarUser" />
                </div>
            </div>

            <div class="form-group filter-form-group" style="flex: 2; display: flex; align-items: center;">
                <label style="margin-right: 0.5rem;">Filtrar por:</label>
                <select v-model="statusFilter" class="form-control filter-form-control" @change="filtrarUsers">
                    <option value="todos">Todos</option>
                    <option value="1">Ativo</option>
                    <option value="0">Inativo</option>
                </select>
            </div>
        </div>
        <div  class="table-responsive users-table-responsive">
            <table class="table users-table">
                <thead>
                    <tr>
                        <th style="width: 3%;">ID</th>
                        <th style="width: 20%; text-align: left;">Nome</th>
                        <th style="width: 3%;">Status</th>
                        <th style="width: 7%;">Telefone</th>
                        <th style="width: 2%;">Email</th>
                        <th style="width: 1%;"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="user in paginatedUsers" :key="user.id">
                        <td style="width: 3%;">#{{user.id}}</td>
                        <td style="width: 20%; text-align: left;">{{user.name}}</td>
                        <td style="width: 3%;">
                            <span :class="user.status && user.status == 1 ? 'badge-active' : 'badge-inactive'">
                                {{user.status && user.status == 1 ? 'Ativo' : 'Inativo'}}
                            </span>
                        </td>
                        <td style="width: 3%;">{{user.phone ? user.phone : 'Não informado'}}</td>
                        <td style="width: 2%;">{{user.email}}</td>
                        <td style="width: 1%;">
                            <button class="btn-edit user-btn-edit" @click="editUser(user.id)">
                                <fa icon="fa-edit"/>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="pagination users-pagination">
            <button 
                :disabled="currentPage === 1" 
                @click="currentPage--"
                class="page-btn users-page-btn"
            >
                Anterior
            </button>
            <span class="page-info users-page-info">Página {{currentPage}} de {{totalPages}}</span>
            <button 
                :disabled="currentPage === totalPages" 
                @click="currentPage++"
                class="page-btn users-page-btn"
            >
                Próxima
            </button>
        </div>

        <Loader :isActive="hasReady" />
    </div>
</template>

<script>

import Loader from '@/components/Loader/loader.vue';
import ApiUser from '@/utils/user';


import { mapState } from 'vuex';


export default {
    data() {
        return {
            label: this.labels,
            usersFiltered: [],
            searchTerm: '',
            currentPage: 1,
            itemsPerPage: 5,
            alertOpenUser:false,
            removedUserName:'',
            afterWidth:40,
            usersData:[],
            openModalCreateUser:false,
            openModalEditUser:false,
            id:'',
            message:'',
            title:'',
            hasReady: false,
            status: '1',
            isMobileIcon: false,
            statusFilter: 'todos',
            searchText: '',
        };
    },
    mounted() {
        this.checkScreenSize();
        window.addEventListener('resize', this.checkScreenSize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.checkScreenSize);
    },
    computed:{
        ...mapState(["token","numberIntensTable"]),

        paginatedUsers() {
            const startIndex = (this.currentPage - 1) * this.numberIntensTable;
            const endIndex = startIndex + this.numberIntensTable;
            return this.filteredUsers.slice(startIndex, endIndex);
        },
        totalPages() {
            return Math.ceil(this.filteredUsers.length / this.numberIntensTable);
        },
        filteredUsers() {
            let filtered = this.usersData;

            if (this.searchText && this.searchText.trim() !== '') {
                filtered = filtered.filter((user) =>
                    user.name.toLowerCase().includes(this.searchText.toLowerCase())
                );
            }
            if (this.statusFilter !== 'todos') {
                filtered = filtered.filter((user) =>
                    user.status !== undefined && (user.status === parseInt(this.statusFilter))
                );
            }

            return filtered;
        }
    },
    methods: {
        filtrarUsers() {
            this.currentPage = 1;
        },
        previousPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },
        removeUser(id, name){
            this.removedUserName = name;
            this.alertOpenUser = !this.alertOpenUser;
            setTimeout(() => {
                this.alertOpenUser = !this.alertOpenUser;
                this.removedUserName = '';
            }, 4000);
        },
        editUser(id){
            this.$router.push({ path: '/dashboard/editar-usuario/' + id });
        },
        createUser(){
            this.openModalCreateUser = !this.openModalCreateUser;
        },
        updateValueCreate(newValue){
            this.openModalCreateUser = newValue;
            this.getDataUser();
        },
        updateValueEdit(newValue){
            this.getDataUser();

            this.openModalEditUser = newValue;
        },
        checkScreenSize() {
            this.isMobileIcon = window.innerWidth <= 600;
        },
        async getUserData(){
            try{
                const response = await ApiUser.getAllUser(this.token);
                this.usersData = response.content;
                this.hasReady = true;
            }
            catch(err){
                this.hasReady = false;
                console.error('Erro ao carregar dados do Usuário')
            }
        },
    },
    components: {
        Loader
    },
    created(){
        this.getUserData();
    },
    
};
</script>

<style scoped>
.container {
    max-width: 100%;
    background-color: #ffffff;
    padding: 1.5rem;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}
.search-form-input{
    position: relative;
    width: 100%;
}
.title-container {
      position: relative;
    margin-bottom: 10px;
    padding-bottom: 0;
    border-bottom: 1px solid #f0f0f0;
    text-align: left;
}

.title-container .title {
    font-size: 1.5rem;
    font-weight: 700;
    color: #000000;
    margin-bottom: 0.5rem;
}

.title-container .subtitle {
    color: #6b7280;
    font-size: 0.875rem;
    line-height: 1.4;
    margin-bottom: 1rem;
}

.search-row {
    display: flex;
    gap: 0.75rem;
    margin-bottom: 1.5rem;
}

.search-row .form-group {
    flex: 1;
}

.btn-weflix.absolute {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #2f2e8b;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: 600;
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(47, 46, 139, 0.2);
}

.btn-weflix.absolute:hover {
    background-color: #23225f;
    transform: translateY(-2px);
}

.users-table-responsive {
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 1rem;
}

.users-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
}

.users-table th {
    background-color: #f8fafc;
    padding: 0.75rem;
    font-weight: 600;
    color: #4a5568;
    text-align: left;
    border-bottom: 2px solid #e2e8f0;
}

.users-table td {
    padding: 0.75rem;
    vertical-align: middle;
    border-bottom: 1px solid #f0f0f0;
    color: #4a5568;
}

.users-table tr:hover {
    background-color: #f8fafc;
}

.badge-active, .badge-inactive {
    padding: 0.25rem 0.75rem;
    border-radius: 5px;
    font-size: 0.75rem;
    font-weight: 500;
    display: inline-block;
    text-align: center;
    transition: all 0.2s ease;
    width: 70%;
}

.badge-active {
    background-color: #7bcc8180;
    color: #4b614e;
}

.badge-inactive {
  background-color: #d7d7ee80;
    color: #2f2e8b;
    }

.user-btn-edit {
    padding: 0.4rem;
    margin: 0 0.25rem;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
   color: #2f2e8b;
}


.search-form-input {
    position: relative;
}

.search-form-control {
    width: 100%;
    padding: 0.5rem 0.75rem;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    font-size: 0.875rem;
    transition: all 0.2s ease;
}

.search-form-control:focus {
    outline: none;
    border-color: #2f2e8b;
    box-shadow: 0 0 0 3px rgba(47, 46, 139, 0.1);
}

.search-input-icon {
    position: absolute;
    right: 0.75rem;
    top: 45%;
    transform: translateY(-50%);
    color: #9ca3af;
    cursor: pointer;
}

.users-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
}

.users-page-btn {
    padding: 0.5rem 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    background: white;
    color: #4a5568;
    cursor: pointer;
    transition: all 0.2s;
}

.users-page-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.users-page-btn:not(:disabled):hover {
    background: #f8fafc;
    border-color: #cbd5e0;
}

.users-page-info {
    color: #4a5568;
    font-size: 0.875rem;
}

@media (max-width: 768px) {
    .container {
        padding: 1rem;
    }

    .title-container {
        margin-bottom: 1.5rem;
    }

    .search-row {
        flex-direction: column;
    }

    .users-table th, .users-table td {
        padding: 0.5rem;
    }
}

@media (max-width: 600px) {
    .btn-weflix.absolute {
        padding: 0.5rem;
    }
    
    .btn-weflix.absolute span {
        display: none;
    }

    .users-table-responsive {
        overflow-x: auto;
    }
}
.content.dashboard > .container {
    background-color: white;
    padding: 20px;
    padding-bottom: 35px;
    box-shadow: 0px 6px 10px 0px #dfdfdf;
    min-height: 89vh;
    padding-bottom: 0;
}
select.form-control.filter-form-control {
    width: 60%;
}
.form-group.filter-form-group {
    justify-content: space-around;
    align-items: center;
    margin: 0 !important;
}
.form-group.search-form-group {
    margin: 0 !important;
}
table.table.users-table {
    border: none !important;
    padding: 0 !important;
}
button.btn-edit.user-btn-edit svg {
    color: #2f2e8b;
}
.form-group.filter-form-group {
    display: flex;
}
.btn-weflix.absolute span.flex {
    font-size: 16px;
}
.btn-weflix.absolute svg {
    color: white;
    font-size: 15px;
}
/* DARK */
.dashboard.dark .content.dashboard > .container.users-container.adminWeeducUsers {
    background-color: #000;
}
.dashboard.dark .container.users-container.adminWeeducUsers .form-group label,
.dashboard.dark .container.users-container.adminWeeducUsers .title, 
.dashboard.dark .container.users-container.adminWeeducUsers .subtitle,
.dashboard.dark .container.users-container.adminWeeducUsers svg,
.dashboard.dark .container.users-container.adminWeeducUsers .users-page-info {
  color: white !important;
}
.dashboard.dark .container.users-container.adminWeeducUsers .form-group .form-input .form-control {
    background-color: #575757 !important;
    border: 1px solid #444 !important;
}
.dashboard.dark .container.users-container.adminWeeducUsers .form-control {
    background-color: #575757;
    color: white;
    border: 1px solid #444;
}
.dashboard.dark .container.users-container.adminWeeducUsers .users-table th {
    border-bottom: 2px solid #575757;
}
.dashboard.dark .container.users-container.adminWeeducUsers .users-table td {
    border-bottom: 1px solid #575757;
}
.dashboard.dark .container.users-container.adminWeeducUsers .users-table-responsive {
    background-color: #000;
}
.dashboard.dark .container.users-container.adminWeeducUsers .title-container {
    border-bottom: 1px solid #575757 !important;
}
</style>