import api from '../api';

export default {
    async getDataUser(accessToken) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/portal/user`,  {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao carregar dado do aluno:', error);
                throw error;
        }
    },
    async EditUser(accessToken, payload) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.put(`/portal/user`, payload, {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao carregar dado do aluno:', error);
                throw error;
        }
    },
    async changePassword(accessToken, payload) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.post(`/portal/change-password`, payload, {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao atualizar senha', error);
                throw error;
        }
    },
    

}

