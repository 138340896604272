<template>
  <!--Content Section-->
  <div class="config-form-content testimonials">
    <div class="content-wrapper primary">
      <div class="wrapper-inner">
        <div class="content-form">
          <div class="row">
            <div :class="responsiveTestimonialsOne">
              <div class="row">
                <div class="col-form-inner col-12 col-md-12 col-lg-12" style="display:flex;">

                  <div class="avatar" v-show="!hasImage[0]" style="width:20%;">
                    <img src="../../../assets/user.png" />                    
                  </div>
                  <div class="avatar" v-show="hasImage[0]"  style="width:20%;">
                    <img :src="imageUrl1Preview || imageUrl1" alt="User Avatar" />
                  </div>
                  <div class="form-group" style="width:80%;">
                    <div class="form-label">
                      <label>Selecione a Imagem:</label>
                    </div>
                    <div class="form-file">
                      <div class="file-wrapper primary">
                        <span class="thumbnail">
                          <span class="icon">
                            <svg
                            width="46"
                            height="39"
                            viewBox="0 0 46 39"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M45 19.9308L36.7274 12.3475C36.1595 11.827 35.2948 11.8047 34.7009 12.2953L18.824 25.411C18.3196 25.8277 17.6074 25.8812 17.0464 25.5446L11.1009 21.9773C10.5893 21.6703 9.94666 21.6856 9.45024 22.0166L1 27.6501"
                              stroke="#9D9FA3"
                              stroke-width="1.25714"
                            ></path>
                            <rect
                              x="1.00049"
                              y="1.40454"
                              width="43.9461"
                              height="36.1909"
                              rx="2.58507"
                              stroke="#9D9FA3"
                              stroke-width="1.25714"
                            ></rect>
                            <circle
                              cx="14.8948"
                              cy="10.6676"
                              r="3.71629"
                              stroke="#9D9FA3"
                              stroke-width="1.25714"
                            ></circle>
                          </svg>
                          </span>
                          <span class="text">
                            Clique aqui para selecionar a imagem
                          </span>
                          <span class="text">
                           1920x1080
                          </span>
                        </span>
                        <input class="form-control" type="file" @change="updateFilePathBanner1"  accept=".png, .jpg, .jpeg, .webp"/>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-form-inner col-12 col-md-12 col-lg-12">
                  <div class="form-group">
                    <div class="form-label">
                      <label>Nome:</label>
                    </div>
                    <div class="form-input">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Nome do Curso"
                        v-model="name[0]"
                      />
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="form-label">
                      <label>Descrição</label>
                    </div>
                    <div class="form-input">
                      <textarea v-model="description[0]"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div :class="responsiveTestimonialsTwo">
              <div class="row">
                <div class="col-form-inner col-12 col-md-12 col-lg-12" style="display:flex;">
                  <div class="avatar" v-show="!hasImage[1]" style="width:20%;">
                    <img src="../../../assets/user.png" />
                  </div>
                  <div class="avatar" style="width:20%;">
                    <img
                      :src="imageUrl2Preview || imageUrl2 "
                      alt="User Avatar"
                      v-show="hasImage[1]"
                    />
                  </div>
                  <div class="form-group" style="width:80%;">
                    <div class="form-label">
                      <label>Selecione a Imagem:</label>
                    </div>
                    <div class="form-file">
                      <div class="file-wrapper primary">
                        <span class="thumbnail">
                          <span class="icon">
                            <svg
                            width="46"
                            height="39"
                            viewBox="0 0 46 39"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M45 19.9308L36.7274 12.3475C36.1595 11.827 35.2948 11.8047 34.7009 12.2953L18.824 25.411C18.3196 25.8277 17.6074 25.8812 17.0464 25.5446L11.1009 21.9773C10.5893 21.6703 9.94666 21.6856 9.45024 22.0166L1 27.6501"
                              stroke="#9D9FA3"
                              stroke-width="1.25714"
                            ></path>
                            <rect
                              x="1.00049"
                              y="1.40454"
                              width="43.9461"
                              height="36.1909"
                              rx="2.58507"
                              stroke="#9D9FA3"
                              stroke-width="1.25714"
                            ></rect>
                            <circle
                              cx="14.8948"
                              cy="10.6676"
                              r="3.71629"
                              stroke="#9D9FA3"
                              stroke-width="1.25714"
                            ></circle>
                          </svg>
                          </span>
                          <span class="text">
                            Clique aqui para selecionar a imagem
                          </span>
                          <span class="text">
                           1920x1080
                          </span>
                        </span>
                        <input class="form-control" type="file" @change="updateFilePathBanner2" accept=".png, .jpg, .jpeg, .webp" />
                      </div>
                    </div>
                  </div>
                
                </div>
                <div class="col-form-inner col-12 col-md-12 col-lg-12">
                  <div class="form-group">
                    <div class="form-label">
                      <label>Nome:</label>
                    </div>
                    <div class="form-input">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Nome do Curso"
                        v-model="name[1]"
                      />
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="form-label">
                      <label>Descrição</label>
                    </div>
                    <div class="form-input">
                      <textarea v-model="description[1]"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-button site">
        <div class="button">
          <button type="button" class="btn btn-block btn-content" @click="updateinfo()">
            Salvar e Publicar
          </button>
          <button type="button" class="btn btn-block btn-content">
            Voltar
          </button>
          <button type="button" class="btn btn-block btn-content">
            Limpar Cache
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import api from '@/utils/site';
import apiSiteLayout from '@/utils/Site/testimony.js';

import { mapState } from 'vuex';
import apiAWS from '@/utils/storage/aws';

export default {
  data() {
    return {
      hasSaved: false,
      hasImage:[false, false],
      imageUrl1:null,
      imageUrl1Preview:null,
      imageUrl2:null,
      imageUrl2Preview:null,
      name:[],
      description:[],
      data:[],
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    ...mapState(['token']),
    ...mapState(['websites']),
      responsiveTestimonialsOne() {
          return this.windowWidth <= 500
            ? 'col-form-outer col-12 col-md-6 col-lg-6'
            : 'col-form-outer col-6 col-md-6 col-lg-6';
        },
  },
  methods: {
    async putFileAws(url, file){
      try {
          const response = await apiAWS.putFileAWS(url, file);
      } catch (error) {
          console.error('Erro ao enviar arquivo para AWS');
      }
    },
    async updateFilePathBanner1(event){
        const file = event.target.files[0];
        this.imageUrl1Preview = URL.createObjectURL(file)

        try {
            const response =  await apiAWS.getSignatureImage(this.token, file.name);
            this.putFileAws(response.url, file)
            this.imageUrl1 = response.token;
            console.debug(response);
        } catch (error) {
            console.error('Erro na geração do TOKEN AWS')
        }
    },
    async updateFilePathBanner2(event){
        const file = event.target.files[0];
        this.imageUrl2Preview = URL.createObjectURL(file)

        try {
            const response =  await apiAWS.getSignatureImage(this.token, file.name);
            this.putFileAws(response.url, file)
            this.imageUrl2 = response.token;
            console.debug(response);
        } catch (error) {
            console.error('Erro na geração do TOKEN AWS')
        }
    },
    async getDataTestimonials() {
      try {
        const response = await apiSiteLayout.getTestomonytData(this.token);
        this.data = response;        
        this.hasImage=[true, true],
        this.data.forEach((item, index) => {
          this.name[index] = item.name;
          this.description[index] = item.description;
            if (index === 0) {
            this.imageUrl1 = item.photoFinal;
          } else if (index === 1) {
            this.imageUrl2 = item.photoFinal;
          }
        });

      } catch (error) {
        console.error(error);
      }
    },
    async edit() {
      const objNew = [];
      const objEdit = [];

      this.name.forEach((item, index) => {
   
        const id = this.data[index] ? this.data[index].id : null;
        const name = this.name[index];
        const photo = index === 0 ? this.imageUrl1 : this.imageUrl2;
        const description = this.description[index];

        if (id) {
          objEdit.push({ id, name, photo, description });
        } else {
          objNew.push({ name, photo, description });
        }
      });
      
      try {   
        if (objEdit.length > 0) {
          await apiSiteLayout.putTestomonytData(this.token, objEdit);
        }
        if (objNew.length > 0) {
          await apiSiteLayout.postTestomonytData(this.token, objNew);
        }

      } catch (error) {
        console.error(error);
      }
      this.hasSaved = true;
      this.$emit('save', this.hasSaved);
    },
    updateinfo(){
        this.edit();
    },
  },
  props: {
    saved: Boolean
  },
  created() {
    this.getDataTestimonials();
  },
};
</script>
<style scoped>
.config-form-content.testimonials textarea {
  padding: 10px;
}
.form-file .file-wrapper > span.thumbnail {
  height: 6.5rem;
}
.form-group .form-input textarea {
  width: 100%;
  min-height: 7em;
  border-color: #dee2e6;
  border-radius: 5px;
  border: 1px solid #dee2e6;
}
.form-group {
  text-align: left;
  margin: 0.5em 1em;
}
.form-group .form-input textarea:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.col-form-inner.col-12.col-md-12.col-lg-12.select {
  margin: 1.9em 0em;
}
.content-button.site > .button {
  display: flex;
  gap: 17px;
  margin: 0em 1em;
}
.avatar img {
  width: 100px;
}
.avatar {
  text-align: left;
  padding: 20px;
}
.dashboard.dark span.thumbnail {
  color: white;
}
@media (max-width: 500px) {
  .content-button.site > .button {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin: 0em 1em;
  }
  .content-form .row {
    display: block; 
  }
  .row .col-form-inner {
    flex-wrap: wrap;
  }
}
</style>
