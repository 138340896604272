import api from '../api';

export default {

    // getDataTesstimonySection
    async getTestomonytData( accessToken) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/manager/testimony`,  {
                method: 'GET',
                headers,
            });
            return response;
        } catch (error) {
            console.error('Erro ao gerar informações dos depoimentos:', error);
                throw error;
        }
    },
    async putTestomonytData( accessToken, payload) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.put(`/manager/testimony`, payload , {
                method: 'PUT',
                headers,
            });
            return response;
        } catch (error) {
            console.error('Erro ao editar informações dos depoimentos:', error);
                throw error;
        }
    },
    async postTestomonytData( accessToken, payload) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.post(`/manager/testimony`, payload , {
                method: 'POST',
                headers,
            });
            return response;
        } catch (error) {
            console.error('Erro ao criar informações dos depoimentos:', error);
                throw error;
        }
    },
 }

