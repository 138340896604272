import api from './api';

export default {
    async getSesson(accessToken) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get('/module?linesPerPage=100', {
                method: 'GET',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados do usuário:', error);
            throw error;
        }
    },

    async getSessonByCollection(accessToken, id) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/module/course/token/${id}`, {
                method: 'GET',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados do usuário:', error);
            throw error;
        }
    },
    
    async createSesson(accessToken, payload){
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.post('/module', payload, {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao criar temporada:', error);
            throw error;
        }
    },
    async editSesson(payload, accessToken, id){
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.put(`/module/${id}`, payload, {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao atualizar modulo:', error);
            throw error;
        }
    },
}
