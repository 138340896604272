import api from '../api';

export default {

    // getDataSocialMediaSection
    async getSocialMidiastData( accessToken) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/manager/social`,  {
                method: 'GET',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao gerar informações das redes sociais:', error);
                throw error;
        }
    },
    async putSocialMidiastData( accessToken, payload) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.put(`/manager/social`, payload , {
                method: 'PUT',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao editar informações das redes sociais:', error);
                throw error;
        }
    },
    async postSocialMidiastData( accessToken, payload) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.post(`/manager/social`, payload , {
                method: 'POST',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao editar informações das redes sociais:', error);
                throw error;
        }
    },
 }

