import axios from 'axios';
import store from '../store/';

let isRefreshing = false;
let refreshPromise = null;

const config = require ('../config/environment.json');

const api = axios.create({
  baseURL: config.api.baseURL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

async function refreshTokenAndRetry(errorConfig) {
  try {
    if (!isRefreshing) {
      isRefreshing = true;
      let retryCount = 0;
      const maxRetries = 2;

      while (retryCount < maxRetries) {
        try {
          refreshPromise = api.post('/auth/refresh', null, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': !store.state.userPortalWeflix ?
                `Bearer ${store.state.refreshToken}` :
                `Bearer ${store.state.userPortalWeflix.refreshToken}`
            }
          });

          const response = await refreshPromise;
          store.commit('SET_TOKEN', response.data);

          if(store.state.userPortalWeflix){
          store.commit('SET_USER_PORTAL_WEFLIX', {
              ...store.state.userPortalWeflix,
              acessToken: response.data
          });
          }
          window.location.reload();
          isRefreshing = false;
          return api(errorConfig);
        } catch (error) {
          retryCount++;
          if (retryCount === maxRetries) {
            throw error;
          }
          await new Promise(resolve => setTimeout(resolve, 1000));
        }
      }
    } else {
      await refreshPromise;
      return api(errorConfig);
    }
  } catch (error) {
    console.error('Erro ao tentar renovar token:', error);
    logoutUser();
    return Promise.reject(error);
  }
}

api.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    const originalRequest = error.config;
    const status = error.response ? error.response.status : null;
    const currentUrl = window.location.pathname;

    const ignoredUrls = [
      'checkout',
      'auth/login',
      'ecommerce/finduser/',
      'checkoutweflix'
    ];

    const ignoredApiEndpoints = [
      '/auth/refresh',
      '/auth/validate',
      '/public/',
      '/ecommerce/insert'
    ];

    const shouldIgnoreUrl = ignoredUrls.some(url =>
      currentUrl.toLowerCase().includes(url.toLowerCase())
    );


    const shouldIgnoreEndpoint = ignoredApiEndpoints.some(endpoint =>
      originalRequest.url.toLowerCase().includes(endpoint.toLowerCase())
    );


    const shouldIgnore = shouldIgnoreUrl || shouldIgnoreEndpoint;


    const isAdminUrl = currentUrl.includes('/admin');

    try {
      switch(status) {
        case 401:
          if (isAdminUrl || !shouldIgnore) {
            return await refreshTokenAndRetry(originalRequest);
          }
          break;
        case 400:
          if (!shouldIgnore) {
            logoutUser();
          }
          break;
        case 403:
          logoutUserSite();
          break;
        case 409:
          return;
      }

      return Promise.reject(error);
    } catch (err) {
      console.error('Erro no interceptor:', err);
      return Promise.reject(err);
    }
  }
);

function logoutUser() {
  window.location.href = "/login";
  localStorage.removeItem('vuex');
  localStorage.removeItem('activeIndex');
}

function logoutUserSite() {
  window.location.href = "/";
  localStorage.removeItem('vuex');
  localStorage.removeItem('activeIndex');
}

export default api;
