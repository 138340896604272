import api from './api';

export default {
    async getDataCourse( accessToken) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get('/course?linesPerPage=100', {
                method: 'GET',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados do usuário:', error);
            throw error;
        }
    },
    
    async createNewCourse(userData, accessToken){
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.post('/course', userData, {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados da criação do curso:', error);
            throw error;
        }
    },

    async getCourse(id, accessToken){
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/course/${id}`, {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados da criação do curso:', error);
            throw error;
        }
    },

    async editCourse(id, payload, accessToken){
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.put(`/course/${id}`, payload ,  {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter editar o curso:', error);
            throw error;
        }
    },
    async getCourseTypeCount(accessToken, id){
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`course/${id}/type-count` ,  {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter contadores do curso:', error);
            throw error;
        }
    },
    
}

