<template>
    <Header @toggleModal="toggleOpenDialog" @toggleModalSubscribe="toggleOpenSubscribe"/> 
    <section class="about lightTheme">
        <div class="contentAbout">
            <div class="text">
                <div class="content-flex">
                    <img :src="logoAbout"/>
                    <div class="text-content">
                        <h1>{{ titleAbout }}</h1>
                        <p>{{ descriptionAbout }}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <Footer/>

    <Login :show="openDialogLogin" @toggleModal="toggleOpenDialog"/>
    <Subscribe :show="openDialogSubscribe" @toggleModal="toggleOpenSubscribe"/>
</template>

<script>
import Header from '@/components/Weflix/LigthTheme/Header.vue';
import Footer from '@/components/Weflix/LigthTheme/Footer.vue';
import Login from '@/components/Weflix/Login.vue'
import Subscribe from '@/components/Weflix/Subscribe.vue'
import { mapState } from 'vuex';
export default {
    data(){
        return{
            openDialogLogin:false,
            openDialogSubscribe:false,
            descriptionAbout:'',
            titleAbout:'',
            logoAbout:''
        }
    },
    computed:{
        ...mapState(['finalWebsite']),
    },
    components:{
        Header,
        Footer,
        Login,
        Subscribe
    },
    methods:{
        toggleOpenDialog(){
            this.openDialogLogin = !this.openDialogLogin;
        },
        toggleOpenSubscribe(){
            this.openDialogSubscribe = !this.openDialogSubscribe;
        },
    }, 
    created(){
        this.titleAbout = this.finalWebsite.title;
        this.descriptionAbout = this.finalWebsite.about;
        this.logoAbout = this.finalWebsite.logoFinal;
    }
}
</script>

<style scoped>
section.about {
    background-color: rgb(255, 255, 255);
    padding-top: 60px;
    min-height: calc(100vh - 260px); /* 260px é a soma do header (60px) + footer (200px) */
}
.contentAbout {
    max-width: 1500px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
}
.contentAbout .text p, .contentAbout .text h1{
    margin: 0;
    color:#bdbdbd;
    margin: 10px 0px;
}
.contentAbout .text h1{
    font-size: 35px;
    font-weight: 700;
}
.text {
    text-align: left;
    padding: 20px 0px;
    width: 100%;
}
.text img {
   width: 200px;
   margin-bottom: 25px;
}
.content-flex {
    display: flex;
    align-items: center;
    gap: 50px;
}
.text-content {
    flex: 1;
}
@media (max-width: 768px) {
    .content-flex {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
    }
    .text img[data-v-35a2026a] {
        width: 100%;
        margin-bottom: 25px;
    }
}
</style>
