import api from './api';

export default {
    async token(userData) {
        try {
          const headers = {
            'Content-Type': 'application/json', 
        };
          const response = await api.post('/auth/login', userData);
          return response
        } catch (error) {
          console.error('Erro ao solicitar token');
        }
       
      },
      async domain(accessToken, payload) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.post('/auth/domain', payload ,{
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao acesso token:', error);
                // throw error;
        }
    },
    async logo(payload) {
      try {
          const headers = {
              'Content-Type': 'application/json', 
          };
          const response = await api.post('/ecommerce/logo', payload ,{
              headers,
          });
          return response.data;
      } catch (error) {
          console.error('Erro ao gerar logo:', error);
              throw error;
      }
  },
}