<template>
    <footer>    
        <div class="footer">
            <div class="logoFooter">
                <img :src="finalWebsite?.logoFinal" alt="Logo Weflix" style="width: 120px;height: 60px;    padding: 10px;" />
            </div>
            <div class="menuFooter">
                <router-link to="/">Cursos</router-link>
                <router-link to="/sobre">Sobre</router-link>
                <router-link to="/termos-de-uso">Termos de uso</router-link>
            </div>
            <div class="socialFooter">
                <a href="https://www.facebook.com/weflixbrasil" target="_blank">
                    <svg data-v-580341a4="" class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path data-v-580341a4="" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path></svg>
                </a>
            </div>
        </div>
        <div class="copyright">
            <p>Conteúdo 2024 {{ finalWebsite?.title }}. Todos os direitos reservados</p>
            <p>Plataforma 2024 Weflix. Todos os direitos reservados</p>
        </div>
    </footer>
</template>

<script>
import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState(['finalWebsite']),
    },
    data() {
        return {
            
        }
    }
}
</script>

<style scoped>
footer {
    margin-top: 0px !important;
}
.socialFooter svg {
    border: 1px dashed black;
    padding: 5px;
    border-radius: 20px;
}
footer {
    background-color: rgb(246, 247, 249);
    height: 200px;
    padding: 20px 0px;
}
.footer, .copyright {
    max-width: 1500px;
    margin: 0 auto;
}
.logoFooter img {
    width: 200px;
}
.menuFooter {
    display: flex;
    gap: 70px;
    margin-left: -50%;
}
.menuFooter a {
    color: rgb(117, 126, 138);
    text-decoration: none;
}
.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.copyright {
    color: #757e8a;
    display: flex;
    gap: 100px;
    padding: 40px 0px;
}
@media (max-width: 600px) {
    .footer {
        flex-direction: column;
        gap: 15px;
    }
    .menuFooter {
        flex-wrap: wrap;
        display: flex;
        gap: 20px;
        margin-left: 0;
    }
    .menuFooter a {
        width: 100%;
    }
    footer {
        background-color: rgb(246, 247, 249);
        height: auto;
        padding: 20px 0px;
        margin-top: 0px !important;
        padding-bottom: 60px;
    }
    .copyright {
        color: #757e8a;
        display: flex;
        gap: 15px;
        padding: 40px 0px;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }
    .copyright p {
        font-size: 10px;
        margin: 0;
    }
}
</style>