import api from '../api';

export default {
    async getDataLesson(accessToken, id) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/portal/lesson/${id}`,  {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao carregar cursos da lesson:', error);
                throw error;
        }
    },
    async getDataLastLesson(accessToken, id) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/portal/course/lastlesson/${id}`,  {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao carregar ultima lesson:', error);
                throw error;
        }
    },
}

