<template>
    <div class="container">
        <div class="titleContainer">
            <h3>Titulo da Pagina</h3>
            <small>Descrição da Pagina</small>
        </div>
        <div class="contetContaine">
            <div class="col-form-inner col-6 col-md-6 col-lg-6 ">
     
              <div class="col-form-inner col-12 col-md-12 col-lg-12">
                  <div class="form-group">
                    <div class="form-label">
                      <label>Nome da Plano<v-tooltip text="Campo obrigatório" location="top">
                        <template v-slot:activator="{ props }">
                          <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                        </template>
                      </v-tooltip></label>
                    </div>
                    <div class="form-input">
                      <input
                        type="text" 
                        required
                        class="form-control"
                        v-model="nomePlano"
                        placeholder="Plano Mensal - 2º Semestre"
                      />
                      <!-- <small class="alertText">Campo obrigatório e deve ser preenchido corretamente.</small> -->
                      <small>Nome comercial de sua coleção, uso externo.</small>
                    </div>
                  </div>
            </div>

            <div class="col-form-inner col-12 col-md-12 col-lg-12">
                <div class="form-group">
                  <div class="form-label">
                    <label>Valor<v-tooltip text="Campo obrigatório" location="top">
                      <template v-slot:activator="{ props }">
                        <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                      </template>
                    </v-tooltip></label>
                  </div>
                  <div class="form-input">
                    <input
                      type="text" 
                      required
                      class="form-control"
                      v-model="valorPlano"
                      placeholder="R$ 99,99"
                    />
                    <!-- <small class="alertText">Campo obrigatório e deve ser preenchido corretamente.</small> -->
                    <small>Nome comercial de sua coleção, uso externo.</small>
                  </div>
                </div>
           </div>
         
            </div>
          
            <div class="col-form-inner col-6 col-md-6 col-lg-6">
                <div class="col-form-inner col-12 col-md-12 col-lg-12">
                    <div class="form-group">
                      <div class="form-label">
                        <label>Frequência de Renovação<v-tooltip text="Campo obrigatório" location="top">
                          <template v-slot:activator="{ props }">
                            <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                          </template>
                        </v-tooltip></label>
                      </div>
                      <div class="form-input">
                        <select
                          required
                          class="form-control"
                          v-model="frequenciaRenovacao"
                        >
                          <option value="" disabled selected>Selecione a frequência</option>
                          <option value="1">Mensal</option>
                          <option value="2">Trimestral</option>
                          <option value="3">Semestral</option>
                          <option value="4">Anual</option>
                        </select>
                        <small>Escolha a frequência de renovação do plano.</small>
                      </div>
                    </div>
              </div>
              <div class="col-form-inner col-12 col-md-12 col-lg-12">
                <div class="form-group">
                  <div class="form-label">
                    <label>Definir como recomendado<v-tooltip text="Campo obrigatório" location="top">
                      <template v-slot:activator="{ props }">
                        <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                      </template>
                    </v-tooltip></label>
                  </div>
                  <div class="form-input">
                    <input data-v-508f1204="" class="form-check-input" type="checkbox" role="switch" v-model="recomendado">
                    <small>Um plano recomendado é tratado como principal na tela de pagamento. Ative como recomendado e o Plano terá mais destaque.</small>
                  </div>
                </div>
          </div>

              
            </div>
            <hr/>
           
            
        </div>
        <div class="actions">
            <button class="btn" @click="(()=>this.$router.push('/admin/pagamentos'))">Voltar</button>
            <button class="btn" @click="editPlan">Salvar</button>
        </div>
      </div>
      <!-- PopupConfirm -->
    <div class="pa-4 text-center">
        <v-dialog
        v-model="deleteItem"
        max-width="320"
        persistent
        >
        <v-card>
            <v-card-title class="headline">Atenção</v-card-title>
    
            <v-card-text>
            Deseja realmente excluir o vídeo {{ nameItem }}?
            </v-card-text>
    
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="cancel" text @click="closeDialog">Não</v-btn>
            <v-btn class="accept" text @click="closeDialog">Sim</v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
    </div>
    <Loader :hasReady="hasReady" />
</template>

<script>
// import Plans from '@/utils/Weflix/plan';
import { mapState } from 'vuex';
import Loader from '@/components/Weflix/Loader.vue';

export default {
    data(){
        return{
            deleteItem: false,
            name: '',
            imagePreviewCover:'',
            nomePlano: '',
            valorPlano: '',
            frequenciaRenovacao: '',
            recomendado: false,
            habilitarBoleto: false,
            habilitarPix: false,
            hasReady: false
        }
    },
    computed: {
        ...mapState(['token'])
    },
    components: {
        Loader
    },
    methods: {
        deleteVideo(id, name){
            this.deleteItem = true;
            this.name = name;
        },
        closeDialog(){
            this.deleteItem = false;
        },
        async editPlan(){
          const id = this.$route.params.slug;

            const payload = {
              "name": this.nomePlano,
              "planValue": parseFloat(this.valorPlano),
              "planType": this.frequenciaRenovacao,
              "recommended": this.recomendado ? 1 : 0,
              "card": 1,
              "billet": this.habilitarBoleto ? 1 : 0,
              "pix": this.habilitarPix ? 1 : 0,
              "discount": '',
              "status": 1
            }
            try {
                const response = await Plans.editPlan(payload, this.token, id);
                this.$router.push('/admin/pagamentos');
            } catch (error) {
                console.error('Erro ao criar plano:', error);

            }
        },
        async getPlan(){
            const id = this.$route.params.slug;
            try {
                const response = await Plans.getPlanById(this.token, id);
                this.nomePlano = response.name;
                this.valorPlano = response.planValue;
                this.frequenciaRenovacao = response.planType;
                this.recomendado = response.recommended  == 1 ? true : false;
                this.habilitarBoleto = response.billet == 1 ? true : false;
                this.habilitarPix = response.pix == 1 ? true : false;
                this.hasReady = true;
            } catch (error) {
                console.error('Erro ao criar plano:', error);
            }
        },
        formatarNumero(numero) {
            let number = parseFloat(numero).toFixed(2);
            return number.replace('.', ',')
        },
    },  
    created(){
        this.getPlan();
    }
}
</script>

<style scoped>
.container {
    max-width: 100%;
 }
.titleContainer {
    display: block;
    text-align: left;
    padding: 0px 10px;
    border-bottom: 1px solid #e8e8e8;
    margin: 10px;
    padding-bottom: 10px;
    
}
.container {
    background-color: white;
    padding: 10px 0px;
    padding-bottom: 50px;
}
 .form-check-input:checked {
    background-color: #1AC7D0;
    border: 1px solid #1AC7D0;
}
.form-check-input {
    --bs-form-check-bg: var(--bs-body-bg);
    width: 1em;
    height: 1.3em!important;
    margin-top: 0.25em;
    vertical-align: top;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: var(--bs-form-check-bg);
    background-image: var(--bs-form-check-bg-image);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: var(--bs-border-width) solid var(--bs-border-color);
    -webkit-print-color-adjust: exact;
}
    
.form-check-input {
    --bs-form-switch-bg: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e);
    width: 2em;
    background-image: var(--bs-form-switch-bg);
    background-position: left center;
    border-radius: 2em!important;
    transition: background-position 0.15s ease-in-out;
}
.form-check-input:checked {
    background-position: right center;
    --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e");
}
.contetContainerNewPlan{
    display: flex;
    flex-wrap: wrap;    
}
hr {
    border-color: #b1b1b1;
    height: 1px;
    width: 100%;
    margin: 10px 10px;
}
.creditCard {
    display: flex;
    flex-wrap: wrap;
    padding: 5px 20px;
    align-items: center;
    text-align: left;
    gap: 21px;
}
.boleto {
    display: flex;
    flex-wrap: wrap;
    padding: 5px 20px;
    align-items: center;
    text-align: left;
    gap: 21px;
}
.boleto img {
    width: 22px;
    height: 30px;
}
.infoCreditCard h4 {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
}
.infoCreditCard {
    width: 80%;
}
.paymentMethods {
    padding-bottom: 50px;
}
.infoCreditCard p {
    margin: 0;
    color: gray;
}
.contetContaine {
    display: flex;
    flex-wrap: wrap;
}
</style>