<template>
  <div class="config-form-content adminWeeducDomain">
    <div class="content-wrapper primary">
      <div class="wrapper-inner">
        <div class="content-form">
          <div class="row">
            <div class="col-12">
              
              <!-- Domínio Weeduc -->
              <div class="domain-section">
                <h5>Domínio Weeduc</h5>
                <small>O Weeduc cria, automaticamente, um dominio baseado no nome da sua loja. ATENÇÃO: Ao atualizar o domínio, você será redirecionado e será necessário fazer login novamente. O tempo de propagação de suas alterações pode ser de até 20 minutos.</small>
                
                <div class="form-group mt-3">
                  <div class="row">
                    <div class="col-md-12">
                      <label>Nome do Domínio Weeduc</label>
                      <div class="input-group domain">
                        <input
                          type="text"
                          class="form-control domain"
                          placeholder="Meu Domínio" 
                          v-model="domainTutor"
                          @keyup="createFullDomain()"
                        />
                        <input
                        type="text"
                        class="form-control domainComplement"
                        placeholder="Meu Domínio" 
                        disabled
                        value="weeduc.com.br"
                        />
                        <div class="input-group-append">
                          <i class="pi pi-check text-success" v-show="hasValidDomain"></i>
                          <i class="pi pi-times text-danger" v-show="hasValidDomain === false"></i>
                          <button class="btn btn-primary ml-2" @click="validateDomain">Verificar</button>
                        </div>
                      </div>
                      <small class="text-muted">Apenas texto, sem espaços ou caracteres especiais. Limite de 30 caracteres.</small>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Domínio Próprio -->
              <div class="domain-section mt-4">
                <h5>Domínio Próprio</h5>
                <small>O Weeduc permite que você use um domínio próprio para sua loja. Para isso, é necessário configurar um apontamento CNAME no seu provedor de domínio. ATENÇÃO: Após atualizar o domínio, você será redirecionado e precisará fazer login novamente. O tempo de propagação das suas alterações pode levar até 24 horas.</small>

                <div class="row mt-3">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Domínio Primário</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="https://www.seudominio.com.br"
                        v-model="domainOwn"
                      />
                      <small class="text-muted">Domínio primário para acesso.</small>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Domínio Adicional</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="https://www.seudominio.com.br"
                        v-model="domainSecondary"
                      />
                      <small class="text-muted">Domínio secundário para acesso.</small>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Instruções de Apontamento -->
              <div class="domain-section mt-4">
                <div class="row">
                  <div class="col-md-6 border-right">
                    <h6>Registro CNAME</h6>
                    <small>No seu provedor, selecione o registro CNAME e aponte para o endereço:</small>
                    <div class="input-group mt-2">
                      <input
                        type="text"
                        class="form-control bg-light"
                        value="sni.weeduc.tv"
                        readonly
                      />
                      <div class="input-group-append">
                        <span class="input-group-text pointer">
                          <i class="pi pi-copy"></i>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <h6>Registro A</h6>
                    <small>Você pode também apontar seu domínio root, Selecione o Registro A em seu provedor e aponte para os IPs:</small>
                    <div class="input-group mt-2">
                      <input
                        type="text"
                        class="form-control bg-light"
                        v-model="title"
                        readonly
                      />
                      <div class="input-group-append">
                        <span class="input-group-text pointer">
                          <i class="pi pi-copy"></i>
                        </span>
                      </div>
                    </div>
                    <div class="input-group mt-2">
                      <input
                        type="text"
                        class="form-control bg-light"
                        v-model="ipAddress"
                        readonly
                      />
                      <div class="input-group-append">
                        <span class="input-group-text pointer">
                          <i class="pi pi-copy"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-3 text-muted">
                <small>Caso esteja com difuculdade em realizar os apontamentos, siga nosso tutorial <a href="">clicando aqui</a>.</small>
              </div>

            </div>
          </div>
        </div>

        <div class="content-button site">
          <div class="button">
            <button
              type="button"
              @click="save()"
              class="btn btn-block btn-content"
            >
              Salvar Edição e Publicar
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import apiSiteDomain from '@/utils/Site/domain';
import Loader from '@/components/Loader/loader.vue';

export default {
  data() {
    return {
      domainTutor: null,
      fullDomainTutor: null,
      domainOwn: null,
      domainSecondary: null,
      hasValidDomain: null,
      ipAddress: '192.168.0.1',
      title: '192.168.0.1',
    };
  },
  computed: {
    ...mapState(['token']),
  },
  methods: {
    createFullDomain(){
      this.domainTutor = this.domainTutor.replace(/[^\w\s]/gi, '').replace(/\s+/g, '').toLowerCase();
      this.fullDomainTutor = 'https://'+this.domainTutor+'.weeduc.com.br';
    },
    async save() {
      const obj = {
        "url": this.fullDomainTutor,
        "url1": this.domainOwn,
        "url2": this.domainSecondary
      }
      try {
        const response = await apiSiteDomain.putDomaintData(this.token, obj);
        this.getDataDomain();
      } catch (error) {
        console.error(error);
      }
      this.hasReady = false;       
      this.hasSaved = true;
      this.$emit('save', this.hasSaved);
    },
    async getDataDomain(){
      try {
        const response = await apiSiteDomain.getDomainData(this.token);
        this.domainTutor = response.url.split('.')[0].replace('https://', '');
        this.fullDomainTutor = response.url;
        this.domainOwn = response.url1;
        this.domainSecondary = response.url2;
      } catch (error) {
        console.error(error);
      }
    },
    async validateDomain(){
      const obj = {
        url: this.fullDomainTutor
      }
      try {
        const response = await apiSiteDomain.postVerifyDomaintData(this.token, obj);
        this.hasValidDomain = response;
      } catch (error) {
        console.error(error);
      }
    }
  },
  created() {
    this.getDataDomain();
  },
  components: {
    Loader,
  },
};
</script>

<style scoped>
.domain-section {
  background: #fff;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  text-align: left;
}

.domain-section h5 {
  color: #333;
  margin-bottom: 1rem;
}

.domain-section small {
  color: #666;
}

.input-group-text {
  background: transparent;
  border: none;
}

.pointer {
  cursor: pointer;
}

.border-right {
  border-right: 1px solid #dee2e6;
}

.bg-light {
  background-color: #f8f9fa;
}

.btn-primary {
  background-color: #160266;
  border-color: #160266;
}

.btn-primary:hover {
  background-color: #0d0149;
  border-color: #0d0149;
}

.text-success {
  color: #28a745;
}

.text-danger {
  color: #dc3545;
}

@media (max-width: 768px) {
  .border-right {
    border-right: none;
    border-bottom: 1px solid #dee2e6;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
  }
}
input.form-control.domain {
  width: 47%;
}
.input-group.domain {
  border: none;
}
.input-group-append i {
  font-size: 19px !important;
  text-align: center;
  padding: 0px 10px;
}
.mt-3.text-muted {
  text-align: left;
}
.content-button.site {
  position: absolute;
  bottom: 10px;
  right: 25px;
}
input.form-control.domainComplement {
  min-width: 142px;
  max-width: 14%;
}
/* DARK */
.dashboard.dark .adminWeeducDomain .domain-section {
  background: #444;
}
.dashboard.dark .adminWeeducDomain .domain-section h5,
.dashboard.dark .adminWeeducDomain .domain-section h6,
.dashboard.dark .adminWeeducDomain .domain-section small,
.dashboard.dark .adminWeeducDomain .domain-section label,
.dashboard.dark .adminWeeducDomain .domain-section small.text-muted,
.dashboard.dark .adminWeeducDomain .input-group-append i,
.dashboard.dark .adminWeeducDomain .mt-3.text-muted {
  color: white !important;
}
.dashboard.dark .adminWeeducDomain input.form-control {
  background-color: #575757 !important;
  border: 1px solid #444;
}
</style>