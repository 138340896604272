<template>
  <div class="global_view_content" :key="currentLesson">  
    <Loader :is-active="hasReady"></Loader>
    <div class="content">
      <div class="video youtube" v-show="typeLessonPortal === 2">
        <iframe
          width="100%"
          height="800"
          :key="videoLink"
          :src="`https://www.youtube.com/embed/${videoLink}`"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen>
        </iframe>
      </div>
      
      <div class="video vimeo" v-show="typeLessonPortal === 3">
        <iframe
          width="1200"
          height="600"
          :key="videoLink"
          :src="`https://player.vimeo.com/video/${videoLink}`"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen>
        </iframe>
      </div>

      <div class="quiz" v-if="typeLessonPortal === 5" :key="typeLessonPortal">
        <div class="result" v-show="hasReadyQuizScore">
          <div class="info_percentage">
            <p>Sua nota</p>
            <h3>{{ scoreData.grade * 10 }}%</h3>
          </div>
          <div class="indo_hits">
            <p>Acertos</p>
            <h3><i class="pi pi-check"></i>{{ scoreData.acertos }}</h3>
          </div>
          <div class="indo_errors">
            <p>Erros</p>
            <h3><i class="pi pi-times"></i>{{ scoreData.erros }}</h3>
          </div>
        </div>
        <div class="quiz-content">
          <Question
            v-for="(q, index) in dataQuiz"
            :key="index"
            :question="q.description"
            :options="q.answers"
            :correctAnswer="correctAnswerIndices[index]"
            :numberQuestion="index"
            @answer="storeAnswer($event, index)"
            :review="review"
            :currentLesson="idLessonPortal"
            :typeLessonPortal="typeLessonPortal">
          </Question>
          <button class="end_quiz" @click="startReview">Finalizar</button>
        </div>
      </div>

      <div class="pdf" v-show="typeLessonPortal === 4" :key="typeLessonPortal">
        <embed style="width: 100%; height: 100vh" :src="pdfSource" v-if="pdfSource"/>
      </div>
      <div class="titleMobileContent">
        <fa icon="fa-chalkboard-user"/>
        <p>Aula atual: {{ nameCurrentLesson }}</p>
    </div>
    </div>
  </div>
</template>

<script>
import PDFViewer from 'pdf-viewer-vue';
import Question from './utils/questions.vue';
import Loader from '@/components/Loader/loader.vue';
import api from '@/utils/portal/lesson.js';
import apiQuiz from '@/utils/portal/quiz';
import { mapState } from 'vuex';

export default {
  components: {
    PDFViewer,
    Question,
    Loader
  },
  props: {
    currentLesson: String,
  },
  data() {
    return {
      data: [],
      dataQuiz: [],
      pdfSource: null,
      answers: [],
      review: false,
      hasReadyQuiz: false,
      hasReadyQuizScore: false,
      correctAnswerIndices: [],
      idQuiz: null,
      scoreData: [],
      videoLink: null,
      typeVideo: null,
      hasReady: false,
    };
  },
  computed: {
    ...mapState(['token', 'currentModuleOpen', 'idLessonPortal', 'nameCurrentLesson', 'typeLessonPortal']),
  },
  methods: {
    async findCorrectAnswerIndex(data) {
      const indices = [];
      data.forEach((item) => {
        item.answers.forEach((answer, index) => {
          if (answer.correct === 1) {
            indices.push(index + 1);
          }
        });
      });
      this.correctAnswerIndices = indices;
    },
    transformToObject(array) {
      return { answers: array };
    },
    async getDataQuiz(idQuiz) {
      try {
        const response = await apiQuiz.getDataQuizQuestion(this.token, idQuiz);
        this.dataQuiz = response;
        this.hasReady = true;
        this.findCorrectAnswerIndex(response);
      } catch (error) {
        console.error('Erro ao carregar o quiz:', error);
      }
    },
    async getDataLesson() {
      try {
        const response = await api.getDataLesson(this.token, this.idLessonPortal);
        this.data = response;
        
        if (response.type === 4) {
          this.pdfSource = response.finalPath;
          this.hasReady = true;

        } else if (response.type === 5 && response.idQuiz) {
          this.idQuiz = response.idQuiz;
          this.getDataQuiz(response.idQuiz);
        } else if (response.type === 2 || response.type === 3) {
          if (response.type === 2) {
            this.typeVideo = 'youtube';
            this.videoLink = this.getEmbeddedLink(response.path);
            this.hasReady = true;

          } else if (response.type === 3) {
            this.typeVideo = 'vimeo';
            this.videoLink = this.getVimeoVideoId(response.path);
            this.hasReady = true;

          }
        }
      } catch (error) {
        console.error('Erro ao carregar a lesson:', error);
      }
    },
    async submitAnswer() {
      const answer = this.transformToObject(this.answers);
      try {
        const response = await apiQuiz.submitAnswers(this.token, answer, this.idLessonPortal);
        this.hasReadyQuizScore = true;
        this.scoreData = response;
      } catch (error) {
        console.error('Erro ao enviar as respostas:', error);
      }
    },
    startReview() {
      this.review = true;
      const targetElement = document.querySelector('.global_view_content');
      if (targetElement) {
        targetElement.scrollTo({ top: 0, behavior: 'smooth' });
      }
      setTimeout(() => {
        this.submitAnswer();
      }, 500); 
    },
    storeAnswer(answer, questionIndex) {
      this.answers[questionIndex] = answer;
    },
    getEmbeddedLink(link) {
      if (link.includes('youtu.be')) {
        return link.split('/').pop().split('?')[0];
      } else if (link.includes('youtube.com/watch')) {
        const params = new URLSearchParams(new URL(link).search);
        return params.get('v');
      } else if (link.includes('youtube.com/embed/')) {
        return link.split('/embed/')[1].split('?')[0];
      } else {
        alert('Por favor, insira um link válido do YouTube.');
        return null;
      }
    },
    getVimeoVideoId(link) {
      const videoIdMatch = link.match(/vimeo\.com\/(\d+)/);
      return videoIdMatch ? videoIdMatch[1] : null;
    }
  },
  created() {
    // this.getDataLesson();
  },
  watch: {
    idLessonPortal(newValue) {
      this.hasReady = false;
      this.review = false;
      this.getDataLesson();
    },
    typeLessonPortal(newValue) {
      this.videoLink = null;
      this.typeVideo = null;
    }
  },
};
</script>

<style scoped>
.global_content_course.dark .titleMobileContent {
  background-color: gray;
  color: white;
}
.global_content_course.dark .titleMobile {
  color: white;
}
.titleMobileContent{
  gap: 10px;
  padding: 10px;
  /* text-decoration: underline; */
  margin: 10px 0px;
  display: flex;
  background-color: white;
  align-items: center;
}
.titleMobileContent p {
  margin: 0 !important;
}
.global_view_content {
  min-height: 93vh;
  overflow-y: auto;
  position: relative;
}
button.end_quiz {
  background: #3b2d70;
  padding: 5px 40px;
  color: white;
  border-radius: 5px;
}
.result {
  background-color: white;
  border-radius: 10px;
  width: 600px;
  margin: 30px auto;
  display: flex;
  padding: 20px;
  gap: 50px;
}
.info_percentage {
  width: 300px;
  background-color: #f4fcfc;
  padding: 10px;
}
.info_percentage h3 {
  font-weight: 800;
}
.indo_hits {
  width: 150px;
  background-color: #f1fce3;
  padding: 10px;
  color: #84ba41;
  border-radius: 10px;
}
.indo_hits h3 {
  color: black;
  font-weight: 800;
}
.indo_errors {
  width: 150px;
  background-color: #fcdfdf;
  padding: 10px;
  color: red;
  border-radius: 10px;
}
.indo_errors h3 {
  color: black;
  font-weight: 800;
}
.result i {
  font-size: 20px;
  margin-right: 5px;
}
.indo_errors i {
  color: red;
}
.indo_hits i {
  color: green;
}
.quiz {
  position: relative;
  min-height: 92vh;
}
.option_question_quiz p {
  margin: 0;
}
.video.youtube iframe {
  width: 100%;
  height: 800px; /* Altura padrão para telas maiores */
}
@media (max-width: 768px) {
  .global_view_content {
    padding: 0px 10px;
  }
}
@media (max-width: 768px) {
  .video.youtube iframe {
    height: 400px; /* Altura menor para telas médias */
  }
}

@media (max-width: 480px) {
  .video.youtube iframe {
    height: 250px; /* Altura ainda menor para telas pequenas */
  }
}

/* Ajuste para vídeos do Vimeo */
.video.vimeo iframe {
  width: 100%;
  height: 600px; /* Altura padrão para telas maiores */
}

@media (max-width: 768px) {
  .video.vimeo iframe {
    height: 350px; /* Altura menor para telas médias */
  }
}

@media (max-width: 480px) {
  .video.vimeo iframe {
    height: 200px; /* Altura ainda menor para telas pequenas */
  }
}

/* Ajuste para PDFs */
.pdf embed {
  width: 100%;
  height: 100vh; /* Altura padrão para telas maiores */
}

@media (max-width: 768px) {
  .pdf embed {
    height: 70vh; /* Altura menor para telas médias */
  }
}

@media (max-width: 480px) {
  .pdf embed {
    height: 50vh; /* Altura ainda menor para telas pequenas */
  }
}
</style>
