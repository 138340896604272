<template>
    <Loader :isActive="!hasReady"></Loader>
    <div class="config-form-content adminWeeducLayout">
      <div class="content-wrapper primary">
        <div class="wrapper-inner">
          <div class="content-form">
            <div class="row">
                <div class="col-form-outer col-12 col-md-12 col-lg-12 " >
                 
                    <div class="col-form-inner col-12 col-md-12 col-lg-12 select">
                      <div class="form-group">
                        <label class="label-form">Tipo Layout do Site</label>
                        <select v-model="layoutSite" class="select-mode">
                          <option value="1" selected>Clean</option>
                          <option value="2">Dark</option>
                        </select>
                        <small class="small">Escolha o Layout do Site de Vendas Clean (Claro) ou Dark (Escuro).</small>
  
                      </div>
                    </div>
                    <div class="col-form-inner col-12 col-md-12 col-lg-12 select">
                      <div class="form-group">
                        <label class="label-form">Tipo de Layout Portal do Aluno</label>
                        <select v-model="layoutPortal" class="select-mode">
                          <option value="1" selected>Clean</option>
                          <option value="2">Dark</option>
                        </select>
                        <small class="small">Escolha o Layout do Portal do Aluno Clean (Claro) ou Dark (Escuro).</small>
  
                      </div>
                    </div>
                    
                </div>
                <div class="col-form-outer col-12 col-md-12 col-lg-12 col-1">
                <div class="row">

                   
               
                  <div class="col-form-inner col-12 col-md-12 col-lg-12">
                    <div class="form-group">
                      <div class="form-label">
                        <label>CSS</label>
                      </div>
                      <div class="form-input">
                       <textarea v-model="css"></textarea>
                       <small class="small">Códigos avançados de estilização (CSS) de laoyout do site, recomendamos o uso apenas para desenvolderoes ou que possuem conehcimento de HTML.</small>
                      </div>
                    </div>
                  </div>
                  <div class="col-form-outer col-12 col-md-12 col-lg-12">
                    <div class="row">
                        <div class="col-form-inner col-12 col-md-12 col-lg-12">
                            <div class="form-group">
                              <div class="form-label">
                                <label>Javascript</label>
                              </div>
                              <div class="form-input">
                                <textarea  v-model="js"></textarea>
                                <small class="small">Códigos avançados de controle de funções (Js) do site, recomendamos o uso apenas para desenvolderoes ou que possuem conehcimento de JavaScript.</small>

                              </div>
                            </div>
                          </div>
                    </div>
                  </div>
                 
                </div>
              </div>

             
            </div>
          </div>
        </div>
        <div class="content-button site">
          <div class="button">
            <button type="button" @click="save()" class="btn btn-block btn-content">
              Salvar
            </button>
            
          </div>
        </div>
      </div>
    </div>
    
  </template>
  
  <script>
  import api from '@/utils/site'
  import apiSiteLayout from '@/utils/Site/layout'
  import apiAWS from '@/utils/storage/aws';
  import { mapState, mapActions } from 'vuex'
  import Loader from '@/components/Loader/loader.vue'
  

  export default{
      data(){
          return{
            css: '',
            layoutSite: 1,
            layoutPortal: 1,
            js: '',
            hasSaved:false,
            hasReady:false,
          }
      },
      computed:{
        ...mapState(['token'])
      },
      methods:{
   
        async getLayoutSite(){
          try {
            const response = await apiSiteLayout.getLayoutData(this.token);

            this.hasSite = true;
            this.css  = response.css;
            this.layoutSite = response.typeLayout;
            this.layoutPortal = response.typeLayoutPortal;
            this.js = response.javascript;
            this.hasReady = true;

          } catch (error) {
            console.error('Erro ao carregar as configurações de layout do Site', error)
          }
        },
        async createSite(){
          const obj = {
            "nome": this.title,
            "url": this.url,
            "title": this.title,
            "description": this.description,
            "keywords": this.keyWords,
            "logo": null,
            "favicon": null,
            "status": this.status,
            "tagManagerCode": this.tagManager,
            "typeLayout": this.layoutSite,
            "javascript": this.js,
            "css": this.css,
            "googleAnalyticsCode":this.ganalytic,
            "facebookPixelCode": this.facebook,
            "googleAdsCode":this.gads,
            "rdsecretClient": this.rdSecret,
            "rdclienteId": this.rdClient,
            "rdcode": this.rdcode,
            "rdrefreshToken": this.rdrefreshToken,
            "typeLayoutPortal": this.layoutPortal  
            
          }
          try {
            const response = api.CreateSite(this.token, obj);
          } catch (error) {
            console.error('Erro ao criar Site', error)
          }
        },
        async editSite(){
          const obj = {
            
            "typeLayout": this.layoutSite,
            "typeLayoutPortal": this.layoutPortal, 
            "javascript": this.js,
            "css": this.css,
            
          }
          try {
            const response = await apiSiteLayout.putLayoutData( this.token, obj);
            this.getLayoutSite();
            this.hasReady = true;
          } catch (error) {
            console.error('Erro ao Editar Site', error)
          }
        },
          save(){
              this.hasReady = false;
              this.editSite();
              this.hasSaved = true
              this.$emit('save', this.hasSaved)
          }
      },
      props:{
          saved: Boolean
      },
      created(){
        this.getLayoutSite();
      },
      components:{
        Loader
      }
  }
  </script>
  
  <style scoped>
  .form-file .file-wrapper>span.thumbnail {
   height: 6.5rem;   
}
.form-group .form-input textarea {
    width: 100%;
    min-height: 7em;
    border-color: #dee2e6;
    border-radius: 5px;
    border: 1px solid #dee2e6;
}
.form-group {
    text-align: left;
    margin: 0.5em 1em;
}
.form-group .form-input textarea:focus {
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.col-form-inner.col-12.col-md-12.col-lg-12.select {
    margin: 1.9em 0em;
}
.content-button.site > .button {
    display: flex;
    gap: 17px;
    margin: 0em 1em;
}
textarea {
  height: auto !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  resize: none;
  outline: none;
  padding: 0.5rem 0.75rem;
  color: #5f5f5f;
  background-color: #fff;
  border: 1px solid #dbdcde;
  box-shadow: none;
  padding: 13px;
}
.col-form-outer.col-6.col-md-6.col-lg-6.col-1 .col-form-inner.col-12.col-md-12.col-lg-12.select {
  margin: 10px 0px;
}
.form-group img {
  width: auto;
  height: auto;
}
/* DARK */
</style>
  