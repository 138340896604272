<template>
    <section class="collections">
        <Header @toggleModal="toggleOpenDialog" @toggleModalSubscribe="toggleOpenSubscribe"/> 
      
        <div class="titleCollectionsPage" :style="{backgroundImage: `url(${collection.imageFinalPath})`}">
            <div class="header-content">
                <button class="btn-voltar" @click="$router.go(-1)">
                    <fa icon="fa-arrow-left"/> Voltar
                </button>
                <div class="collection-info collection-info-videos">
                    <p>{{collection.lessonQty ? collection.lessonQty : 0}} vídeos | {{collection.totalTime ? formatarTempo(collection.totalTime) : '00:00'}}</p>

                    <h3>{{nameCollection}}
                        <span style="display: block;font-size: 15px;margin: 20px 0px;">{{stripHtml(collection.shortDescription)}}</span>
                    </h3>
                    <div class="cta-single-collection" v-if="collection.price > 0 && collection.owned !== 4">
                        <div class="price-info">
                            <span class="price-label">{{collection.installment}}x de</span>
                            <span class="price-value">R$ {{ (collection.price && collection.price > 0 ? collection.price/collection.installment : 25).toFixed(2) }}</span>
                            <span class="price-details">sem juros</span>
                        </div>
                        <a class="button-single-collection" href="#!" @click="setCollectionCheckout(collection)" v-if="finalWebsite.company.cartStatus && finalWebsite.company.cartStatus === 1">
                            COMPRE AGORA
                        </a>
                    </div>
                </div>
                
            </div>
            
            
        </div>
        <!-- <div class="layout-options">
            <button @click="setLayout('cards')" :class="{ active: layout === 'cards' }" class="cards-button">
                <fa icon="fa-th" /> 
            </button>
            <button @click="setLayout('list')" :class="{ active: layout === 'list' }" class="list-button">
                <fa icon="fa-list" /> 
            </button>
        </div> -->
        <div class="itemVideoCollections" ref="videoContainer" :class="layout">  
            <div class="videosCollections" v-for="video in allLessons" :key="video.id" @click="goToPlayer(video.id, video.sample, collection)">
                <div class="videoThumbnail" :class="finalWebsite?.typeImageCoverCourse !== 2 ? 'videoThumbnail-horizontal' : 'videoThumbnail-vertical'" 
                :style="{height: finalWebsite?.typeImageCoverCourse !== 2 ? '190px' : '580px', backgroundImage: `url(${video.finalVideoThumbnail || '@/assets/Weflix/default-video-thumbnail.jpg'})`}">
                   
                   
                    <fa icon="fa-play" class="playIcon" />
                    <div v-if="video.sample === 1" class="sampleFlag_collectionVideos">
                        Degustação
                    </div>
                    <div class="time-video">
                        {{video.videoTime ? formatarTempo(video.videoTime) : '00:00'}}
                    </div>
                </div>
                <div class="videoDetails"> 
                    <h3 v-if="isMobile">{{(video.title ? video.title : 'Sem título').length > 27 ? video.title.substring(0,27) + '...' : (video.title ? video.title : 'Sem título')}}</h3>
                    <h3 v-else>{{(video.title ? video.title : 'Sem título').length > 40 ? video.title.substring(0,40) + '...' : (video.title ? video.title : 'Sem título')}}</h3>

                    <p v-if="layout === 'list'">{{stripHtml(video.description)}}</p>
                </div>
                <div class="lockBlock" v-if="!userPortalWeflix">
                    <fa icon="lock" class="lockIcon" />
                    <span class="textPrivateVideo">Conteúdo Exclusivo</span>
                </div>
                <div class="degradeCarousel__item__collection"></div>

            </div>
        </div>

        <div v-if="showPurchaseModal" class="purchase-modal">
            <div class="modal-content">
                <h3>Conteúdo Exclusivo</h3>
                <p>Para acessar este conteúdo, você precisa adquirir esta coleção.</p>
                <small v-if="!userPortalWeflix">Realize o login ou cadastre-se para finalizar a compra.</small>
                <div class="modal-buttons">
                    <button @click="closePurchaseModal" class="cancel-button">Fechar</button>
                    <button @click="redirectToCheckout" class="buy-button">Comprar Agora</button>
                </div>
            </div>
        </div>

        <div v-if="loading" class="loader-container">
            <div class="loader"></div>
        </div>

        <Footer/>
    </section>
    <Subscribe :show="openDialogSubscribe" @toggleModal="toggleOpenSubscribe"/>
    <Login :show="openDialogLogin" @toggleModal="toggleOpenDialog"/>

</template>
<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Navigation, Slide } from 'vue3-carousel'
import { mapState } from 'vuex';
import Header from "@/components/Weflix/Hedaer.vue"
import Footer from '@/components/Weflix/Footer.vue'
import Subscribe from '@/components/Weflix/Subscribe.vue'
import Login from '@/components/Weflix/Login.vue'
import ApiWebsite from '@/utils/Weflix/Website/index';

export default {
    data(){
        return{
            titleCollection:['Primeire Coleção', 'Segunda Coleção', 'Terceira Coleção'],
            openDialogLogin:false,
            openDialogSubscribe:false,
            collection: [],
            allLessons: [],
            nameCollection:'',
            baseUrl: process.env.NODE_ENV === 'development' ? process.env.VUE_APP_BASE_URL_HOMOLOGATION  : window.location.origin,
            isMobile: false,
            currentPage: 0,
            loading: false,
            hasMorePages: true,
            observer: null,
            showPurchaseModal: false,
            layout: 'cards'
        }
    },
    computed:{
        ...mapState(['userPortalWeflix', 'finalWebsite'])
    },
    components: {
        Carousel,
        Slide,
        Navigation,
        Header,
        Footer,
        Subscribe,
        Login
    },
    mounted() {
        this.setupInfiniteScroll();
        this.isMobile = window.innerWidth < 768;
    },
    beforeUnmount() {
        if (this.observer) {
            this.observer.disconnect();
        }
    },
    methods:{
        setupInfiniteScroll() {
            const options = {
                root: null,
                rootMargin: '0px',
                threshold: 0.5
            };

            this.observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting && this.hasMorePages && !this.loading) {
                        this.currentPage++;
                        this.getVideosByCollections(this.currentPage);
                    }
                });
            }, options);

            const target = document.createElement('div');
            target.id = 'scroll-trigger';
            this.$refs.videoContainer.appendChild(target);
            this.observer.observe(target);
        },
        toggleOpenDialog(){
            this.openDialogLogin = !this.openDialogLogin;
        },
        toggleOpenSubscribe(){
            this.openDialogSubscribe = !this.openDialogSubscribe;
        },
        goToPlayer(id, sample, collection){
            if(this.userPortalWeflix){
                if(sample == 1 || collection.owned == 4){
                    this.$router.push('/player/'+id)
                } else if(this.userPortalWeflix.user.orderStatus == 1){
                    this.$router.push('/player/'+id)
                } else if(this.userPortalWeflix.user.orderStatus != 1){
                    this.toggleOpenSubscribe();
                }
                else{
                    this.toggleOpenDialog();
                }
            } else {
                this.toggleOpenDialog();
            }
        },
        stripHtml(html) {
            if (!html) return '';
            return html.replace(/<[^>]*>?/g, '');
        },
        async getVideosByCollections(page = 0){
            this.loading = true;
            const id = this.$route.params.slug;
            const aux1 = this.baseUrl.replace(/\/$/, '');
            const aux = aux1.split('/videos-colecao')[0];
          
            const payload = {
                "url": aux
            }
            try {
                let response;
                if(this.userPortalWeflix){  
                    response = await ApiWebsite.getVideosByCollectionLogged(id, this.userPortalWeflix.acessToken);
                } else {
                    response = await ApiWebsite.getVideosByCollection(id, payload, page);
                }
                
                this.collection = response;
                this.nameCollection = response.comercialName;

                if (page === 0) {
                    this.allLessons = response.lessons;
                } else {
                    this.allLessons = [...this.allLessons, ...response.lessons];
                }

                // Verifica se há mais páginas para carregar
                if (response.lessons.length < 100) {
                    this.hasMorePages = false;
                }

            } catch (error) {
                console.error(error);
            } finally {
                this.loading = false;
            }
        },
        formatarTempo(tempo){
            const horas = Math.floor(tempo / 3600);
            const minutos = Math.floor((tempo % 3600) / 60);
            return `${horas}:${minutos.toString().padStart(2, '0')} min`;
        },
        setLayout(layout) {
            this.layout = layout;
        },
        setCollectionCheckout(collection) {
            if(this.userPortalWeflix){
                this.$store.commit('setCheckoutInfo', {
                    id: collection.id,
                    name: collection.comercialName,
                    url: collection.url,
                    price: collection.price,
                    image: collection.imageFinalPath,
                    totalVideos: collection.totalVideos
                });
                this.$router.push('/checkout-collection-weflix');  
            } else {
                this.toggleOpenDialog();
            }
        }
    },
    watch: {
        userPortalWeflix: {
            handler(newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.getVideosByCollections();
                }
            },
            deep: true
        }
    },
    created(){
        this.getVideosByCollections();
    }   
}
</script>
<style scoped>
.lockBlock {
    width: 100%;
    position: absolute;
    top: 0;
    right: -10px;
    opacity: 0;
}
.videosCollections:hover .lockBlock,
.videosCollections:hover span.textPrivateVideo{
    opacity: 1;
}
span.textPrivateVideo {
    color: #fff;
    font-size: 11px;
    position: absolute;
    right: 46px;
    top: 10px;
    opacity: 0;
    transition: all .5s linear;
    z-index: 2;
}
.titleCollections {
    margin: 10px 10px;
}
.titleCollections h3{
    font-size: 15px;
    color: white;
}
section.collections .titleCollectionsPage {
    max-width: 1500px;
    margin: 0 auto;
    text-align: left;
    height: 400px;
    padding: 20px;
    border-radius: 5px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-size: cover;
}

.header-content {
    max-width: 100%;
    width: 100%;
    text-align: center;
}

.collection-info {
    margin-top: 20px;
}

.collection-info h3 {
    color: white;
    font-size: 50px;
    margin-bottom: 10px;
}

.collection-info p {
    color: white;
    font-size: 16px;
    margin: 20px 0px;
}

.itemVideoCollections {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    max-width: 1500px;
    margin: 30px auto;
    gap: 40px 30px;
    justify-content: center;
}
@media (max-width: 600px) {
    .itemVideoCollections {
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
        padding: 0 10px;
    }

    .itemVideoCollections.list {
        grid-template-columns: 1fr;
    }
}

.videosCollections {
    display: flex;
    cursor: pointer;
    position: relative;
    flex-direction: column;
    width: 100%; /* 1 card por linha na lista */
    gap: 10px;
    transition: all 0.3s ease;
}
.videosCollections:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    filter: brightness(1.3);
}
.itemVideoCollections.cards .videosCollections {
    width: 100%;
}
.itemVideoCollections.list .videosCollections {
    flex-direction: row; /* Imagem e texto lado a lado */
    align-items: center;
    border-bottom: 1px solid #444444;
    padding-bottom: 15px;
}
.videoThumbnail {
    width: 100%;
    border-radius: 10px;
    background-size: cover;
    background-position: center;
    position: relative;
    text-align: left;
    transition: all 0.3s ease;
}

.sampleFlag_collectionVideos {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: v-bind('finalWebsite?.color');
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 12px;
    z-index: 2;
}

.itemVideoCollections.list .videoThumbnail {
    width: 100%;
    max-width: 300px; /* Ajusta a largura da imagem na lista */
    height: 100%;
}
.videoThumbnail .playIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 25px;
    color: #ffffff96;
}
.videoDetails {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    position: absolute;
    bottom: 6px;
    left: 0;
    z-index: 2;
    background-color: #00000073;
    width: 100%;
    text-align: left !important;
    padding-bottom: 10px;
}
.videoDetails h3 {
    font-size: 13px;
    margin: 0px 0;
    color: white;
    font-weight: 600;
}
.videoDetails p {
    display: none; /* Ocultar a descrição por padrão */
    color: white; /* Deixar a cor da descrição branca */
}
.itemVideoCollections.list .videoDetails p {
    display: block; /* Mostrar a descrição na lista */
}
.videosCollections.list:hover .degradeCarousel__item {
    opacity: 1;
}
section.collections hr {
    color: white;
}
.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}
.loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
.layout-options {
    display: flex;
    justify-content: end;
    max-width: 1500px;
    margin: 20px auto;
}
.layout-options button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
    margin: 0 10px;
    color: white;
    cursor: pointer;
}
.layout-options button svg {
    color: #6d6d6d;
}
.layout-options button.active svg {
    color: white;
}
.itemVideoCollections.cards .videosCollections {
    flex-direction: column;
    align-items: center;
}
.itemVideoCollections.cards .videoThumbnail {
    max-width: 100%;
}
.itemVideoCollections.cards .videoDetails {
    text-align: center;
}

.itemVideoCollections.cards .videoDetails p {
    margin: 0;
}

.cta-single-collection {
    padding: 15px;
    text-align: center;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: 10px 0px;
    width: 250px;
    margin: 0 auto;
}

.price-info {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin-bottom: 0px!important;
}

.price-label {
    color: #a4a4a4;
    font-size: 14px;
}

.price-value {
    color: white;
    font-size: 18px;
    font-weight: bold;
}

.price-details {
    color: #a4a4a4;
    font-size: 14px;
}

.button-single-collection {
    background-color: v-bind('finalWebsite?.color');
    color: white;
    border: none;
    border-radius: 6px;
    padding: 12px 17px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    transition: all 0.3s ease;
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
}

.button-single-collection:hover {
    filter: brightness(1.1);
    transform: translateY(-1px);
}

</style>
<style >

section.collections .titleCollectionsPage {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}
.titleCollectionsPage p{
    color: white !important;
}
.collections .videoCollections.list img {
    width: 100%;
    height: auto;
    border-radius: 10px;
}
@media (max-width: 600px) {
    section.collections .titleCollectionsPage {
        display: block;
        max-width: 100%;
    }
    .itemVideoCollections {
        padding: 10px 20px;
    }
    .videosCollections {
        width: 100%!important;
        cursor: pointer;
        flex-direction: column;
    }
    .videoCollections img {
        width: 100%;
        height: 250px;
    }
    
}
.videoCollections img {
    width: 100%;
    height: 250px;
    border-radius: 0px;
}
.videosCollections {
    transition: all 0.3s ease;
}
.videoThumbnail:hover {
    filter: brightness(1.1);
}
.videoThumbnail:hover .playIcon {
    color: white !important;
}
.collection-info.collection-info-videos {
    display: block;
}
@media (max-width: 600px) {
    .videoDetails h3[data-v-35666d98] {
        font-size: 10px;
    }
    .videoThumbnail {
        height: 120px!important;
    }
    .videoThumbnail.videoThumbnail-vertical {
        height: 350px !important;
    }
}
.time-video {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #272727;
    padding: 5px;
    border-radius: 5px;
    color: white;
    font-size: 10px;
}
</style>
