import api from '../api';

export default {
    async getDataNotifications(accessToken) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/portal/company/notification`,  {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao carregar as notificações:', error);
                throw error;
        }
    },
    async getNumberNotifications(accessToken) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/portal/notification/total-unread`,  {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao carregar quatidade de notificações:', error);
                throw error;
        }
    },

}

