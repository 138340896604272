import api from './api';

export default {
    async getDataWebSite(accessToken) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`website`, {
                method: 'GET',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados do Site:', error);
            throw error;
        }
    },

    async CreateSite(accessToken, payload) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.post(`/website`, payload, {
                method: 'POST',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao criar Site:', error);
            throw error;
        }
    },

    async editSite(payload, accessToken, id){
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.put(`/website/${id}`, payload, {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao editar site:', error);
        }
    },
    async addSocialMedia( accessToken, payload){
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.post(`/socialmedia`, payload, {
                headers,
            });
            return response;
        } catch (error) {
            console.error('Erro adicionar redes sociais', error);
            throw error;

        }
    },
    async editSocialMedia(accessToken, payload){
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.put(`/socialmedia`, payload, {
                headers,
            });
            return response;
        } catch (error) {
            console.error('Erro ao editar site:', error);
        }
    },
    async getSocialmedia(accessToken){
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/socialmedia`, {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao editar site:', error);
        }
    },
    async getTestimonials(accessToken){
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/testimony`, {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao editar site:', error);
        }
    },
    async newTestimonials(accessToken, payload){
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.post('/testimony', payload, {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados da Resposta:', error);
            throw error;
        }
    },
    async editTestimonials(accessToken, payload, id){
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.put(`/testimony/${id}`, payload, {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados da Resposta:', error);
            throw error;
        }
    },
    async content(accessToken, payload){
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.post('/content', payload, {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao criar conteúdo do Site:', error);
            throw error;
        }
    },
    async getContent(accessToken){
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get('/content', {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao carregar conteúdo do Site:', error);
            throw error;
        }
    },
    async editContent(accessToken, payload){
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.put(`/content`, payload, {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao editar conteúdo do Site:', error);
            throw error;
        }
    }
}
