<template>
    <header class="mobile-header" v-if="isMobile">
        <div class="mobile-header-content">
            <router-link to="/" class="mobile-logo" @click="toggleMobileMenu">
                <img :src="finalWebsite && finalWebsite.logoFinal ? finalWebsite.logoFinal : '/assets/Weflix/logo_weflix.png'" alt="Logo Weflix" />
            </router-link>

            <div class="mobile-search">
                <input type="text" v-model="searchQuery" @input="getSearchCollection" placeholder="Buscar...">
                <button v-if="searchQuery" class="close-search-btn" @click="toggleSearch">
                    <fa icon="fa-xmark"/>
                </button>
                <button class="search-btn" @click="toggleSearch">
                    <fa icon="fa-magnifying-glass"/>
                </button>
            </div>

            <button class="menu-toggle" @click="toggleMobileMenu">
                <fa icon="fa-bars"/>
            </button>
        </div>

        <div class="mobile-sidebar" :class="{'active': showMobileMenu}">
            <div class="sidebar-header">
                <button class="close-menu" @click="toggleMobileMenu">
                    <fa icon="fa-xmark"/>
                </button>
            </div>

            <nav class="sidebar-nav">
                <router-link to="/" @click="toggleMobileMenu">
                    <fa icon="fa-home"/> Home
                </router-link>
                <router-link to="/colecoes" @click="toggleMobileMenu">
                    <fa icon="fa-folder"/> Coleções
                </router-link>
                <div v-if="!userPortalWeflix" @click="handleLoginClick" class="mobile-login">
                    <fa icon="fa-user"/>
                    <span>Entrar</span>
                </div>

                <div v-if="userPortalWeflix && finalWebsite && finalWebsite.company && finalWebsite.company.cartStatus == 1 && !hasSinglePurchase">
                    <button class="subscribe-btn" @click="handleSubscribeClick" v-if="userPortalWeflix.user.status !=  1">
                        Assine agora
                    </button>
                </div>
               

                <div v-if="userPortalWeflix" class="user-menu">
                    <router-link to="/minha-conta" @click="toggleMobileMenu">
                        <fa icon="fa-user"/>
                        Minha conta
                    </router-link>
                    <button @click="handleLogoutClick">
                        <fa icon="fa-power-off"/>
                        Sair
                    </button>
                </div>
            </nav>
        </div>
    </header>

    <header v-else>
        <div class="header">
            <div class="contentHeaderLeft">
                <router-link to="/" class="logo" :class="[activeSearch ? 'active':'']">
                    <img :src="finalWebsite && finalWebsite.logoFinal ? finalWebsite.logoFinal : '/assets/Weflix/logo_weflix.png'" alt="Logo Weflix" style="width: 120px;height: 60px;    padding: 10px;" />
                </router-link>

                <p class="collectionsMenu">
                    <router-link to="/">Home</router-link>
                </p>
                <p class="collectionsMenu">
                    <router-link to="/colecoes">Coleções</router-link>
                </p>
            </div>
            <div class="contentHeaderMiddle">
                <div class="search" :class="[activeSearch ? 'active':'']">
                    <input type="text" v-model="searchQuery" @input="getSearchCollection">
                    <button class="btnCloseSearch" @click="toggleSearch" v-show="activeSearch"><fa icon="fa-xmark"/></button>
                    <button class="btnSearch" @click="toggleSearch"><fa icon="fa-magnifying-glass"/></button>
                </div>
                <p @click="toggleModal" class="login" v-if="!userPortalWeflix">
                    <fa icon="fa-user"/>
                    Entrar</p>

                <div v-if="userPortalWeflix && finalWebsite && finalWebsite.company && finalWebsite.company.cartStatus == 1 && !hasSinglePurchase" style="display: contents;">
                    <button class="btnHedaer" @click="toggleModalSubscribe" v-if="userPortalWeflix.user.orderStatus !==  1">Assine agora</button>
                </div>
                
                <div v-if="!userPortalWeflix && finalWebsite && finalWebsite.company && finalWebsite.company.cartStatus === 1 && !hasSinglePurchase" class="InfoOutLogin">
                    <button class="btnHedaer" @click="toggleModalSubscribe">Assine agora</button>
                </div>
                <div class="menuMobile" @click="showMenuMobile = !showMenuMobile" :class="[activeSearch ? 'active':'']" v-if="userPortalWeflix">
                    <fa icon="fa-user"/>
                    <fa icon="fa-chevron-down" :class="[showMenuMobile ? 'rotate':'']"/>
                </div>
            </div>
        </div>
        
        <div class="loggedInfo" v-if="userPortalWeflix ">
            <div class="itensMenu" :class="[showMenuMobile ? 'active':'']">
                <router-link to="/minha-conta" class="linkMenu" @click="showMenuMobile = false"><fa icon="fa-user"/>Minha conta</router-link>
                <p class="linkMenu" @click="logout"><fa icon="fa-power-off"/>Sair</p>
            </div>
        </div>
    </header>

    <div class="resultSearch" :class="[hasReadyResult ? 'active':'']">
        <div class="resultSearchItens">
            <div class="tabs">
                <button :class="['tab-btn', activeTab === 'collections' ? 'active' : '']" 
                        @click="activeTab = 'collections'">
                    Coleções
                </button>
                <button :class="['tab-btn', activeTab === 'videos' ? 'active' : '']" 
                        @click="activeTab = 'videos'">
                    Vídeos
                </button>
            </div>

            <div v-if="isLoading" class="loader-container">
                <div class="loader"></div>
            </div>

            <div class="tab-content" v-else-if="resultSearch">
                <div v-show="activeTab === 'collections'" class="grid-container">
                    <div class="grid-item" v-for="item in collectionsResult" :key="item.id" v-show="collectionsResult">
                        <router-link :to="`/colecoes-videos/themeLight/${item.id}`" @click="removeOverflow">
                            <div class="item-content">
                                <img v-if="item.imageFinalPath == null" src="@/assets/Weflix/default-video-thumbnail.jpg" alt="Imagem padrão">
                                <img v-else :src="item.imageFinalPath" alt="Capa do vídeo">
                                <p>{{ item.comercialName }}</p>
                            </div>
                        </router-link>
                    </div>
                    <div class="notFound" v-show="!collectionsResult">
                        <p>Nenhum resultado encontrado</p>
                    </div>
                </div>

                <div v-show="activeTab === 'videos'" class="grid-container">
                    <div class="grid-item" v-show="videosResult.length > 0" v-for="item in videosResult" :key="item.id">
                        <div @click="validateUserAccess(item.id)">
                            <div class="item-content">
                                <img v-if="item.finalVideoThumbnail == null" src="@/assets/Weflix/default-video-thumbnail.jpg" alt="Imagem padrão">
                                <img v-else :src="item.finalVideoThumbnail" alt="Capa do vídeo">
                                <p>{{ item.title }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="notFound" v-show="videosResult.length == 0">
                        <p>Nenhum resultado encontrado</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ApiWebsite from '@/utils/Weflix/Website/index';
import ApiPayment from '@/utils/Weflix/Website/payment';

export default {
    data(){
        return{
            activeSearch:false,
            logo: null,
            description: null,
            showMenuMobile: false,
            searchQuery: '',
            hasReadyResult: false,
            resultSearch: null,
            collectionsResult: null,
            videosResult: null,
            activeTab: 'collections',
            isLoading: false,
            development: process.env.NODE_ENV === 'development' ? true : false,
            baseUrl: process.env.NODE_ENV === 'development' ? process.env.VUE_APP_BASE_URL_HOMOLOGATION  : window.location.origin,
            selectedCompany: null,
            showMobileMenu: false,
            isMobile: window.innerWidth <= 768,
        }
    },
    computed:{
        ...mapState(['finalWebsite','userPortalWeflix', 'trialWeflix', 'activeTrial','companys', 'hasSinglePurchase', 'noLogin']),
    },
    watch: {
        noLogin(newValue) {
            if(newValue === true && !this.userPortalWeflix) {
                this.$emit('toggleModalSubscribe');
                this.$emit('toggleModal');
            }
        }
    },
    methods:{
        ...mapActions(['logoutPortalWeflix', 'storeActiveTrial', 'storeFinalWebsite', 'storeNoLogin']),
        toggleMobileMenu() {
            this.showMobileMenu = !this.showMobileMenu;
            document.body.style.overflow = this.showMobileMenu ? 'hidden' : '';
        },
        handleLoginClick() {
            this.toggleModal();
            this.toggleMobileMenu();
        },
        handleSubscribeClick() {
            this.toggleModalSubscribe();
            this.toggleMobileMenu();
        },
        handleLogoutClick() {
            this.logout();
            this.toggleMobileMenu();
        },
        async changeCompany(company) {
            if (company) {
                try {
                    await this.storeFinalWebsite({finalWebsite: company});
                    this.getInfoSite();
                } catch (error) {
                    console.error('Erro ao mudar empresa:', error);
                }
            }
        },
        validateUserAccess(videoId) {
            if (!this.userPortalWeflix) {
                this.toggleModal();
                return;
            }
            
            if (!this.activeTrial && !this.userPortalWeflix.subscriber) {
                this.toggleModalSubscribe();
                return;
            }
            
            this.$router.push(`/player-curso/${videoId}`);
            this.removeOverflow();
        },
        toggleModal(){
           this.$emit('toggleModal');
        },
        toggleModalSubscribe(){
           this.$emit('toggleModalSubscribe');
        },
        async getSearchCollection() {
            if (this.searchQuery.length < 3) {
                this.hasReadyResult = false;
                this.resultSearch = null;
                this.collectionsResult = [];
                this.videosResult = [];
                return;
            }

            this.hasReadyResult = true;
            this.isLoading = true;
            const url = this.baseUrl.replace(/\/$/, '');
            const payload = {
                "url": url,
                "search": this.searchQuery
            }
           try {
                const response = await ApiWebsite.serachCollectionVideo(payload);
                this.hasReadyResult = true;
                this.resultSearch = response;
                this.collectionsResult = response.courses;
                this.videosResult = response.lessons;
                this.toggleBodyOverflow();
                this.lastSearch = this.searchQuery;
           } catch (error) {
                console.error('Erro ao obter dados da pesquisa:', error);
                this.hasReadyResult = false;
                this.resultSearch = null;
                this.collectionsResult = [];
                this.videosResult = [];
           } finally {
                this.isLoading = false;
           }
        },
        toggleSearch() {
            this.activeSearch = !this.activeSearch;
            this.hasReadyResult = false;
            this.searchQuery = '';
            this.collectionsResult = [];
            this.videosResult = [];
            this.toggleBodyOverflow();
        },
        toggleBodyOverflow() {
            if (this.hasReadyResult) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = '';
            }
        },
        logout() {
            this.logoutPortalWeflix();
            window.location.href = '/';
           
        },
        removeOverflow(){
            document.body.style.overflow = '';
        },
        async activeTrialUser(){
            try {
                const response = await ApiPayment.activeTrial(this.userPortalWeflix.acessToken);
                this.$store.dispatch('storeActiveTrial', {activeTrial: true});
                alert('Trial ativado com sucesso');
            } catch (error) {
                console.error('Erro ao ativar trial:', error);
            }
        },
        async getInfoSite(){
            if (!this.finalWebsite) {
                const aux = this.baseUrl.replace(/\/$/, '');
                const payload = {
                    "url": aux
                }
                try {
                    const response = await ApiWebsite.getWebsite(payload);
                    this.storeFinalWebsite({finalWebsite:response});
                    
                    document.title = response.title;
                    
                    const link = document.createElement('link');
                    link.rel = 'icon';
                    link.href = response.faviconFinal;
                    document.head.appendChild(link);
                    
                    if (response.css) {
                        const style = document.createElement('style');
                        style.textContent = response.css;
                        document.head.appendChild(style);
                    }
                    
                    if (response.javascript) {
                        const script = document.createElement('script');
                        script.textContent = response.javascript;
                        document.body.appendChild(script);
                    }

                    if (response.googleAnalyticsCode) {
                        const gaScript = document.createElement('script');
                        gaScript.async = true;
                        gaScript.src = "https://www.googletagmanager.com/gtag/js?id=" + response.googleAnalyticsCode;
                        document.head.appendChild(gaScript);

                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', response.googleAnalyticsCode);
                    }

                    if (response.facebookPixelCode) {
                        !function(f,b,e,v,n,t,s)
                        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                        n.queue=[];t=b.createElement(e);t.async=!0;
                        t.src=v;s=b.getElementsByTagName(e)[0];
                        s.parentNode.insertBefore(t,s)}(window,document,'script',
                        'https://connect.facebook.net/en_US/fbevents.js');
                        fbq('init', response.facebookPixelCode);
                        fbq('track', 'PageView');
                    }

                    if (response.googleAdsCode) {
                        const gadsScript = document.createElement('script');
                        gadsScript.async = true;
                        gadsScript.src = "https://www.googletagmanager.com/gtag/js?id=" + response.googleAdsCode;
                        document.head.appendChild(gadsScript);

                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', response.googleAdsCode);
                    }
                    
                    
                } catch (error) {
                    console.error(error);
                }
            } else {
                document.title = this.finalWebsite.title;
                
                const link = document.createElement('link');
                link.rel = 'icon';
                link.href = this.finalWebsite.faviconFinal;
                document.head.appendChild(link);
                
                if (this.finalWebsite.css) {
                    const style = document.createElement('style');
                    style.textContent = this.finalWebsite.css;
                    document.head.appendChild(style);
                }
                
                if (this.finalWebsite.javascript) {
                    const script = document.createElement('script');
                    script.textContent = this.finalWebsite.javascript;
                    document.body.appendChild(script);
                }
            }
        },
    },
    created(){
        this.getInfoSite();
        this.storeNoLogin({noLogin:false});
    },
    mounted() {
        window.addEventListener('resize', () => {
            this.isMobile = window.innerWidth <= 768;
        });
    },
    beforeDestroy() {
        window.removeEventListener('resize', () => {
            this.isMobile = window.innerWidth <= 768;
        });
    }
}
</script>

<style scoped>
body.no-scroll {
    overflow: hidden;
}
header{
    backdrop-filter: blur(10px);
    background-color: rgba(20, 20, 20, 0.64);
    z-index: 99;
    height: 60px;
}
.header {
    height: 64px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    max-width: 1500px;
    margin: 0 auto;
}
.contentHeaderLeft {
    display: flex;
    align-items: center;
    gap: 20px;
}
.contentHeaderLeft img{
    width: 120px;
}
.contentHeaderLeft p{
    margin: 0;
    color: white;
}
.contentHeaderLeft p a{
   text-decoration: none;
   color: white;
}

.company-selector {
    width: 200px;
}

.company-selector .v-select {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    color: white;
}

.company-selector .vs__dropdown-toggle {
    border: 1px solid rgba(255, 255, 255, 0.2);
    background: transparent;
}

.company-selector .vs__selected {
    color: white;
}

.company-selector .vs__search::placeholder {
    color: rgba(255, 255, 255, 0.5);
}

.company-selector .vs__dropdown-menu {
    background: rgba(20, 20, 20, 0.95);
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: white;
}

.company-selector .vs__dropdown-option {
    color: white;
}

.company-selector .vs__dropdown-option--highlight {
    background: rgba(255, 255, 255, 0.1);
}

.contentHeaderMiddle {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    align-items: center;
}
.contentHeaderMiddle p {
    margin: 0;
    color: white;
 }
.contentHeaderMiddle p a{
    text-decoration: none;
    color: white;
 }
header {
    position: absolute;
    top: 0;
    width: 100%;
}
.contentHeaderMiddle > div >button.btnHedaer {
    padding: 0px 16px;
    color: rgb(255, 255, 255);
    display: block;
    text-align: center;
    line-height: 27px;
    font-weight: 800;
    text-transform: uppercase;
    background-color: black;
    border-radius: 10px;
    background-color: #260791;
    padding: 7px 10px;
    border-radius: 4px;
    font-size: 13px;
}
.search {
    background-color: rgba(255, 255, 255, 0.15);
    border: 1px solid rgba(255, 255, 255, 0.05);
    border-radius: 5px;
}
.search input {
    height: 100%;
    color: white;
    width: 300px;
    font-size: 14px;
    width: 0;
    transition: all 0.3s ease 0s;
}
.search.active input {
   width: 300px;
   padding: 0 10px;

}
input:focus-visible {
    outline: none;
    box-shadow: none;
}
button.btnCloseSearch svg {
    font-size: 15px;
}
button.btnCloseSearch {
    margin-right: 15px;
}
.search.active button.btnSearch {
    background-color: #2c2c2f;
    height: 40px;
    width: 40px;
    border: none;
}
button.btnSearch {
    height: 40px;
    width: 40px;
}
.login{
    cursor: pointer;
}
.menuMobile {
    cursor: pointer;
}
.linkMenu {
    margin: 0;
    padding: 15px;
    color: #8e8e8e;
    transition: all 500ms ease-in-out;
    cursor: pointer;
    background-color: transparent;
    text-decoration: none;
    display: block;
}
.resultSearchItens {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    display: block;
}
.notFound p {
    color: #333;
    text-align: center;
    margin: 20px;
    font-size: 16px;
}
.resultSearch {
    z-index: -1!important;
    position: absolute!important;
    top: 63px!important;
    background-color: #000000e0!important;
    height: 100vh!important;
    width: 100%!important;
    opacity: 0!important;
}
.resultSearch.active {
    opacity: 1 !important;
    z-index: 9999!important;
}
.InfoOutLogin {
    display: contents;
}
.lgooContentCenter {
    position: absolute;
    top: 5px;
    left: 50%;
}
.search.active input[type="text"] {
    color: rgb(255, 255, 255);
}

.contentHeaderMiddle p a{
    text-decoration: none;
    color: #7f7f7f;
 }
 .resultSearch.mobile.active {
    z-index: 88!important;
    position: absolute!important;
    top: 0px!important;
    background-color: #000000e0!important;
    height: 80vh!important;
    width: 100%!important;
    opacity: 1!important;
    padding-top: 75px!important;
}
.siteTheme2 svg.svg-inline--fa {
    color: #8e8e8e;
}
.tab-content {
    height: 700px;
    overflow: scroll;
    -ms-overflow-style: none; 
    scrollbar-width: none;  
}
.tab-content::-webkit-scrollbar {
    display: none; 
}

.tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.tab-btn {
    padding: 10px 20px;
    margin: 0 10px;
    border: none;
    background: #a8a8a8;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
}

.tab-btn.active {
    background: v-bind("finalWebsite?.color || '#4CAF50'");
    color: white;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    padding: 20px;
}

.grid-item {
    background: white;
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.2s;
}

.grid-item:hover {
    transform: translateY(-5px);
}

.item-content {
    padding: 10px;
}

.item-content img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 4px;
}

.item-content p {
    margin: 10px 0;
    font-size: 14px;
    color: #333;
    text-align: center;
}

@media (max-width: 1200px) {
    .grid-container {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 900px) {
    .grid-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 600px) {
    .grid-container {
        grid-template-columns: 1fr;
        padding-bottom: 180px !important;
    }

    .grid-item {
        width: 100%;
    }
    .sidebar-nav {
        gap: 0px !important;
    }
    .user-menu {
        gap: 0px!important;
    }
}
p.login {
    background-color: #5b5b5b;
    padding: 7px 10px;
    border-radius: 4px;
}
p.login svg {
    font-size: 15px;
}
.mobile-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    background-color: rgba(20, 20, 20, 0.95);
    backdrop-filter: blur(10px);
}

.mobile-header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    height: 60px;
}

.mobile-logo img {
    height: 40px;
    width: auto;
}

.menu-toggle {
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
    padding: 5px;
}

.mobile-sidebar {
    position: fixed;
    top: 0;
    right: -100%;
    width: 60%;
    height: 100vh;
    background-color: rgba(20, 20, 20, 0.98);
    transition: right 0.3s ease;
    z-index: 1001;
    padding: 20px;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
}

.mobile-sidebar.active {
    right: 0;
}

.sidebar-header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
}

.close-menu {
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
    padding: 5px;
}

.sidebar-nav {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.sidebar-nav a {
    color: white;
    text-decoration: none;
    font-size: 15px;
    text-align: left;
    padding: 10px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.mobile-search {
    position: relative;
    margin: 20px 0;
}

.mobile-search input {
    width: 100%;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    color: white;
}

.search-btn {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: white;
    cursor: pointer;
}

.close-search-btn {
    position: absolute;
    right: 40px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: white;
    cursor: pointer;
}

.mobile-login {
    display: flex;
    align-items: center;
    gap: 10px;
    color: white;
    cursor: pointer;
    padding: 10px 0;
}

.user-menu {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.user-menu a,
.user-menu button {
    display: flex;
    align-items: center;
    gap: 10px;
    color: white;
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    padding: 10px 0;
    text-decoration: none;
}

.subscribe-btn {
    width: 100%;
    padding: 12px;
    background-color: #260791;
    color: white;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    margin: 10px 0;
}

body.no-scroll {
    overflow: hidden;
}

header{
    backdrop-filter: blur(10px);
    background-color: rgba(20, 20, 20, 0.64);
    z-index: 99;
    height: 60px;
}
.loggedInfo .itensMenu {
    background-color: #232323;
    padding: 6px;
    text-align: left;
}

.loggedInfo .itensMenu.active {
    width: 200px;
    position: absolute;
    right: 1%
}
.loggedInfo .itensMenu {
    width: 10%;
    position: absolute;
    right: -60%;
}
.loggedInfo {
    max-width: 1500px;
    margin: 0 auto;
    position: relative;
    display: block;
    width: 100%;
}
</style>
