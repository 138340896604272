<template>
    <section class="collections">
        <Header @toggleModal="toggleOpenDialog" @toggleModalSubscribe="toggleOpenSubscribe"/>
        <div class="titleCollectionsPage">
            <div class="title-header">
                <button class="btn-voltar" @click="$router.go(-1)">
                    <fa icon="fa-arrow-left"/> Voltar
                </button>
                <div class="title-content">
                    <h3>Coleções</h3>
                    <p>Acesse as coleções de vídeos disponíveis para você.</p>
                </div>
            </div>
           <!-- Aqui:  {{ !urlBand }}
           Aqui: {{ finalWebsite.typeImageCoverCourse == 2 }} -->
            <!-- Tabs de filtro -->
            <div class="collection-tabs" v-if="plansWeflix.length == 0 && userPortalWeflix">
                <button 
                    :class="['tab-button', { active: activeTab === 'all' }]"
                    @click="setActiveTab('all')"
                >
                    Todas as coleções
                </button>
                <button 
                    :class="['tab-button', { active: activeTab === 'owned' }]"
                    @click="setActiveTab('owned')"
                >
                    Minhas coleções
                </button>
                <button 
                    :class="['tab-button', { active: activeTab === 'available' }]"
                    @click="setActiveTab('available')"
                >
                    Coleções disponíveis
                </button>
            </div>
        </div>

        <!-- Loader -->
        <div v-if="loading" class="loader-container">
            <div class="loader"></div>
        </div>

        <div v-if="!loading && (!urlBand && finalWebsite.typeImageCoverCourse == 1) " class="containerCollections">
            <div 
                v-for="collection in filteredCollections" 
                :key="collection.id"
                class="itensCollections"
            >
                <span id="line1"></span>
                <span id="line2"></span>
                <div class="conteriner-flex-videos" @click="handleCollectionClick(collection)">
                    <div class="col-1-videos">
                        <img v-if="collection.imageFinalPath || collection.imageFinalPath2" 
                            :src="collection.imageFinalPath2 && collection.imageFinalPath2 != 'https://image.weeduc.com/' ? collection.imageFinalPath2 : collection.imageFinalPath" 
                            alt="Imagem padrão">
                        <img v-else src="@/assets/Weflix/default-video-thumbnail.jpg" alt="Imagem padrão"/>
                        <div class="title-row-3-videos">
                            <h3>{{collection.comercialName}}</h3>
                            <!-- <p>{{collection.lessonQty ? collection.lessonQty : 0}} vídeos | {{collection.totalTime ? Math.max(1, (collection.totalTime/3600).toFixed(0)) : 0}} horas</p> -->
                        </div>
                    </div>
                    <div class="degradeCarousel__item__collection"></div>
                    <div class="total-videos">
                        {{collection.lessonQty ?  collection.lessonQty : '0'}} vídeos
                    </div>
                </div>
                <!-- <div class="cta-single-collection" v-if="collection.price && collection.owned !== 4">
                    <div class="price-info">
                        <span class="price-label">{{collection.installment}}x de</span>
                        <span class="price-value">R$ {{ (collection.price && collection.price > 0 ? collection.price/collection.installment : 25).toFixed(2) }}</span>
                        <span class="price-details">sem juros</span>
                    </div>
                    <a class="button-single-collection" href="#!" @click="setCollectionCheckout(collection)" v-if="finalWebsite.company.cartStatus && finalWebsite.company.cartStatus === 1">
                        COMPRE AGORA
                    </a> 
                </div> -->
            </div>
        </div>
        <!-- Layout alternativo para localhost:8080/colecoes -->
         <div v-if="urlBand || finalWebsite.typeImageCoverCourse == 2">
            <div class="itensCollections" v-for="collection in filteredCollections" :key="collection.id">
                <div class="titleCollections pageCollections">
                    <div class="info_header_collections">
                        <h3>{{collection.comercialName}}</h3>
                        <p style="margin-bottom: 0px;">{{ collection.lessonQty || 0 }} vídeos | {{ formatarTempo(collection.lessonTime || 0) }}</p>  
                        <small class="description-collections">{{collection.shortDescription}}</small>           
                    </div>
                    <div class="button-header-collections">
                        <button class="btn-collection" @click="handleCollectionClick(collection)">Ver coleção</button>
                    </div>
                </div>
                <div class="videoCollections pageCollections">
                    <div class="itensVideoCollections">
                        <swiper
                            :modules="modules"
                            :slides-per-view="mobile ? 2 : 5"
                            :space-between="20"
                            navigation
                            @swiper="onSwiper"
                            @slideChange="onSlideChange"
                        >
                            <swiper-slide v-for="slide in collection.lessons" :key="slide.id">
                                
                                <div class="carousel__item" @click="GoToPlayer(slide)">

                                <img v-if="!slide.finalVideoThumbnail" src="@/assets/Weflix/default-video-thumbnail.jpg" alt="Imagem padrão">
                                <img v-else :src="slide.finalVideoThumbnail" :alt="slide.title"/>
                                    <div class="lockIcon" v-if="userPortalWeflix && slide.sample !== 1 && userPortalWeflix.user.orderStatus != 1 && collection.owned != 4">
                                        <fa icon="lock" class="lockIcon"/>
                                        <span class="textPrivateVideo">Conteúdo Exclusivo</span>
                                    </div>

                                    <div class="lockBlock" v-if="!userPortalWeflix && slide.sample !== 1 && userPortalWeflix && (userPortalWeflix.user.orderStatus != 1 || userPortalWeflix.user.orderStatus != 4)">
                                        <fa icon="lock" class="lockIcon" />
                                        <span class="textPrivateVideo">Conteúdo Exclusivo</span>
                                    </div>

                                    <div class="sampleFlag" v-if="slide.sample === 1">
                                        <span>DEGUSTAÇÃO</span>
                                    </div>

                                    <div class="degradeCarousel__item"></div>
                                    <div class="descriptionVideo" v-if="finalWebsite.visibleTitleCourse == 1">
                                        <h4>{{slide.title}}</h4>
                                        <div class="videoInfo">
                                            <small>{{formatarTempo(slide.videoTime)}}</small>
                                        </div>
                                    </div>
                                </div>
                            </swiper-slide>
                        </swiper>
                    </div>
                </div>
                </div>
        </div>

        <!-- <div class="videoCollections" v-if="urlBand">
            <div class="itensVideoCollections">
                <swiper
                    :modules="modules"
                    :slides-per-view="mobile ? 2 : 5"
                    :space-between="20"
                    navigation
                    @swiper="onSwiper"
                    @slideChange="onSlideChange"
                >
                    <swiper-slide v-for="slide in collection.lessons" :key="slide.id">
                        
                        <div class="carousel__item" @click="toggleModalSubscribe(slide.id, slide.sample, collection.owned, collection.price, collection)">

                        <img v-if="!slide.finalVideoThumbnail" src="@/assets/Weflix/img-long.png" alt="Imagem padrão">
                        <img v-else :src="slide.finalVideoThumbnail" :alt="slide.title"/>


                            <div class="lockIcon" v-if="userPortalWeflix && slide.sample !== 1">
                                <fa v-if="userPortalWeflix.status !== 1" icon="lock" class="lockIcon"/>
                                <span v-if="userPortalWeflix.user.status !== 1" class="textPrivateVideo">Conteúdo Exclusivo</span>
                            </div>

                            <div class="lockBlock" v-if="!userPortalWeflix && slide.sample !== 1">
                                <fa icon="lock" class="lockIcon" />
                                <span class="textPrivateVideo">Conteúdo Exclusivo</span>
                            </div>

                            <div class="sampleFlag" v-if="slide.sample === 1">
                                <span>DEGUSTAÇÃO</span>
                            </div>

                            <div class="degradeCarousel__item"></div>
                            <div class="descriptionVideo" v-if="finalWebsite.visibleTitleCourse == 1">
                                <h4>{{slide.title}}</h4>
                                <div class="videoInfo">
                                    <small>{{formatarTempo(slide.videoTime)}}</small>
                                </div>
                            </div>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
        </div> -->

      

        <!-- Paginação -->
        <div class="pagination">
            <button 
                :disabled="currentPage === 1" 
                @click="previousPage"
                class="pagination-button"
            >
                Anterior
            </button>
            
            <span class="page-info">Página {{currentPage}} de {{totalPages}}</span>
            
            <button 
                :disabled="currentPage === totalPages" 
                @click="nextPage"
                class="pagination-button"
            >
                Próximo
            </button>
        </div>

        <!-- Modal de Aviso -->
        <div v-if="showPurchaseModal" class="purchase-modal">
            <div class="modal-content">
                <h3>Conteúdo Exclusivo</h3>
                <p>Para acessar este conteúdo, você precisa adquirir esta coleção.</p>
                <small v-if="!userPortalWeflix">Realize o login ou cadastre-se para finalizar a compra.</small>
                <div class="modal-buttons">
                    <button @click="closePurchaseModal" class="cancel-button">Fechar</button>
                    <button @click="redirectToCheckout" class="buy-button">Comprar Agora</button>
                </div>
            </div>
        </div>

        <Footer/>
    </section>
    <Subscribe :show="openDialogSubscribe" @toggleModal="toggleOpenSubscribe"/>
    <Login :show="openDialogLogin" @toggleModal="toggleOpenDialog"/>
</template>

<script>
import { Carousel, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
import Header from "@/components/Weflix/Hedaer.vue"
import Footer from '@/components/Weflix/Footer.vue'
import Subscribe from '@/components/Weflix/Subscribe.vue'
import Login from '@/components/Weflix/Login.vue'
import ApiWebsite from '@/utils/Weflix/Website/index';
import ApiSite from '@/utils/Weflix/Website/index';
import ApiCollections from '@/utils/Weflix/collections';
import ApiPlans from '@/utils/Weflix/Website/plans';
import { mapState, mapActions } from 'vuex';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default {
    data(){
        return{
            titleCollection:['Primeire Coleção', 'Segunda Coleção', 'Terceira Coleção'],
            openDialogLogin:false,
            openDialogSubscribe:false,
            collections: [],
            allCollections: [], 
            baseUrl: process.env.NODE_ENV === 'development' ? process.env.VUE_APP_BASE_URL_HOMOLOGATION  : window.location.origin,
            currentPage: 1,
            totalPages: 1,
            loading: true,
            activeTab: 'all',
            showPurchaseModal: false,
            selectedCollection: null,
            urlBand: false,
            collection: {
                lessons: []
            },
            modules: [Navigation, Pagination, Scrollbar, A11y],
            mobile: false
        }
    },
    computed: {
        ...mapState(['finalWebsite', 'userPortalWeflix','plansWeflix','storeHasSinglePurchase']),
        filteredCollections() {
            if (this.activeTab === 'all') {
                return this.allCollections;
            } else if (this.activeTab === 'owned') {
                return this.allCollections.filter(collection => collection.owned === 4);
            } else {
                return this.allCollections.filter(collection => collection.owned === 0);
            }
        },
        urlBand(){
            const currentUrl = window.location.href;
            return currentUrl.includes('https://www.arte1play.com.br') || currentUrl.includes('https://bandarte1.weflix.com.br');
        }
    },
    components: {
        Carousel,
        Slide,
        Navigation,
        Header,
        Footer,
        Subscribe,
        Login,
        Swiper,
        SwiperSlide
    },
    methods:{
        ...mapActions(['storeFinalWebsite']),
        async getPlans() {
            const aux = this.baseUrl.replace(/\/$/, '');

            const payload = {
                "url": aux
            }
            try {
                const response = await ApiPlans.getPlans(payload);
               
                this.storeHasSinglePurchase({
                    hasSinglePurchase: response.length > 0 ? false : true
                });
            } catch (error) {
                console.error(error);
            }
        },
        GoToPlayer(lesson){
            if(this.userPortalWeflix && this.userPortalWeflix.user.orderStatus == 4){
                this.$router.push('/player/'+lesson.id);
            } else {
                this.$router.push('/checkoutweflix');
            }
        },
        async getInfoSite() {
            const aux = this.baseUrl.replace(/\/$/, '');

            const payload = {
                "url": aux
            }
            try {
                const response = await ApiSite.getWebsite(payload);
                this.storeFinalWebsite({
                    finalWebsite: response
                });
            } catch (error) {
                console.error(error);
            }
        },
        formatarTempo(tempo) {
            const horas = Math.floor(tempo / 3600);
            const minutos = Math.floor((tempo % 3600) / 60);
            return `${horas > 0 ? horas + 'h ' : ''} ${minutos > 0 ? minutos + 'm' : ''}`;
        },
        handleCollectionClick(collection) {
                this.goToCollection(collection.id);
        },
        closePurchaseModal() {
            this.showPurchaseModal = false;
            this.selectedCollection = null;
        },
        redirectToCheckout() {
           if(this.userPortalWeflix){
                this.showPurchaseModal = false;
                this.$router.push('/checkout-collection-weflix');   
           } else {
                this.showPurchaseModal = false;
                this.toggleOpenDialog();
                // this.$emit('toggleOpenDialog');
           }
        },
        setActiveTab(tab) {
            this.activeTab = tab;
            this.currentPage = 1;
        },
        toggleOpenDialog(){
            this.openDialogLogin = !this.openDialogLogin;
        },
        toggleOpenSubscribe(){
            this.openDialogSubscribe = !this.openDialogSubscribe;
        },
        goToVideo(){
            this.openDialogSubscribe = !this.openDialogSubscribe;
        },
        async getCollections(page = 0){
            this.loading = true;
            let aux;
            if(process.env.NODE_ENV === 'development'){
                aux = this.baseUrl;
            } else {
                aux = this.baseUrl.split('/colecoes')[0];
            }
            let payload = {
                "url": aux
            }
            try {
                const response = await ApiWebsite.getCollectionsLimit(payload, page);
                const sortedContent = response.content.sort((a, b) => {
                    // Garante que order seja um número e ordena de forma crescente
                    const orderA = Number(a.order) || 0;
                    const orderB = Number(b.order) || 0;
                    if (orderA > orderB) return 1;
                    if (orderA < orderB) return -1;
                    return 0;
                });
                
                if (page === 0) {
                    this.allCollections = sortedContent;
                } else {
                    this.allCollections = sortedContent;
                }
                
                this.totalPages = response.totalPages;
            } catch (error) {
                console.error(error);
            } finally {
                this.loading = false;
            }
        },
        async getCollectionByLogin(page = 0){
            this.loading = true;
            try {
                const response = await ApiCollections.getCollectionByLogin(this.userPortalWeflix.acessToken, page);
                const sortedContent = response.content.sort((a, b) => {
                    const orderA = Number(a.order) || 0;
                    const orderB = Number(b.order) || 0;
                    if (orderA > orderB) return 1;
                    if (orderA < orderB) return -1;
                    return 0;
                });

                this.allCollections = sortedContent;
                this.totalPages = response.totalPages;
                console.debug(this.allCollections);
            } catch (error) {
                console.error(error);
            } finally {
                this.loading = false;
            }
        },
        goToCollection(id){
            this.$router.push(`/videos-colecao/${id}`);
        },
        async previousPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
                if (this.userPortalWeflix && this.plansWeflix.length == 0) {
                    await this.getCollectionByLogin(this.currentPage - 1);
                } else {
                    await this.getCollections(this.currentPage - 1);
                }
            }
        },
        async nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
                if (this.userPortalWeflix && this.plansWeflix.length == 0) {
                    await this.getCollectionByLogin(this.currentPage - 1);
                } else {
                    await this.getCollections(this.currentPage - 1);
                }
            }
        },
        setCollectionCheckout(collection) {
            if(this.userPortalWeflix){
                this.showPurchaseModal = true;
                this.$store.commit('SET_COLLECTION_CHECKOUT', {
                id: collection.id,
                name: collection.comercialName,
                url: collection.url,
                price: collection.price,
                imageFinalPath: collection.imageFinalPath,
                totalVideos: collection.totalVideos,
                collection: collection
            });
            } else {
                this.showPurchaseModal = true;
            }
        }
    },
    created(){
        console.log('Planos',this.plansWeflix.length);
        this.mobile = window.innerWidth <= 768;

        if(this.userPortalWeflix && this.plansWeflix.length == 0){
            this.getCollectionByLogin();
        } else {
            this.getCollections();
        }
        this.getInfoSite();
    }   
}
</script>

<style scoped>
/* Estilos existentes mantidos... */

/* Estilos para o modal de compra */
.purchase-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: #282828;
    padding: 30px;
    border-radius: 10px;
    width: 90%;
    max-width: 400px;
    text-align: center;
}

.modal-content h3 {
    color: white;
    margin-bottom: 15px;
    font-size: 24px;
}

.modal-content p {
    color: #a4a4a4;
    margin-bottom: 25px;
    font-size: 16px;
}

.modal-buttons {
    display: flex;
    gap: 15px;
    justify-content: center;
}

.modal-buttons button {
    padding: 12px 25px;
    border: none;
    border-radius: 6px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
}

.cancel-button {
    background-color: #464646;
    color: white;
}

.buy-button {
    background-color: v-bind('finalWebsite?.color');
    color: white;
}

.cancel-button:hover,
.buy-button:hover {
    filter: brightness(1.1);
    transform: translateY(-1px);
}

/* Novos estilos para as tabs */
.collection-tabs {
    display: flex;
    gap: 20px;
    margin: 20px 0;
}

.tab-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #464646;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease;
    height: 50px;
}

.tab-button.active {
    background-color: v-bind('finalWebsite?.color');
}

.tab-button:hover {
    filter: brightness(1.1);
}

/* Resto dos estilos mantidos... */
section.collections .titleCollectionsPage{
    position: relative;
    padding: 0 20px;
}

.title-header {
    position: relative;
    margin-bottom: 30px;
}

.title-content {
    text-align: left;
    margin-top: 50px;
}

.title-content h3 {
    font-size: 32px;
    margin-bottom: 10px;
    color: white;
    font-weight: 700;
}

.title-content p {
    font-size: 16px;
    color: #a4a4a4;
    margin: 0;
}

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
}

.loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    gap: 20px;
}

.pagination-button {
    padding: 8px 16px;
    background-color: #464646;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.pagination-button:hover:not(:disabled) {
    background-color: #5e5e5e;
}

.pagination-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.page-info {
    color: white;
    font-size: 14px;
}
.loader {
    z-index: 999999;
    position: absolute;
    top: 31%;
}

.cta-single-collection {
    padding: 15px;
    text-align: center;
    background-color: #1e1e1e;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: 10px 0px;
}

.price-info {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.price-label {
    color: #a4a4a4;
    font-size: 14px;
}

.price-value {
    color: white;
    font-size: 18px;
    font-weight: bold;
}

.price-details {
    color: #a4a4a4;
    font-size: 14px;
}

.button-single-collection {
    background-color: v-bind('finalWebsite?.color');
    color: white;
    border: none;
    border-radius: 6px;
    padding: 12px 17px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    transition: all 0.3s ease;
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
    margin: 0px;
}

.button-single-collection:hover {
    filter: brightness(1.1);
    transform: translateY(-1px);
}

/* Novos estilos para o layout alternativo */
.containerCollectionsAlternative {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 20px;
    max-width: 1500px;
    margin: 0 auto;
}

.itemCollectionAlternative {
    background: #1e1e1e;
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s ease;
}

.itemCollectionAlternative:hover {
    transform: translateY(-5px);
}

.collection-grid {
    display: grid;
    grid-template-columns: 60% 40%;
    gap: 10px;
    padding: 10px;
}

.col-left {
    height: 200px;
}

.col-left .main-image {
    height: 100%;
}

.col-left img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
    cursor: pointer;
}

.col-right {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.col-right .secondary-image {
    height: calc(50% - 5px);
        position: relative;
}

.col-right img {
    width: 100%;
    height: 95px !important;
    object-fit: cover;
    border-radius: 8px;
    cursor: pointer;
}

.collectionInfo {
    padding: 15px;
    text-align: left;
}

.collectionInfo h4 {
    color: white;
    margin: 0 0 10px 0;
    font-size: 13px;
}

.collectionInfo .description {
    color: #a4a4a4;
    font-size: 14px;
    margin: 0 0 10px 0;
}

.collectionInfo .videoCount {
    color: white;
    font-size: 12px;
    background: #272727;
    display: inline-block;
    padding: 5px 10px;
    border-radius: 5px;
}

@media (max-width: 768px) {
    .containerCollectionsAlternative {
        grid-template-columns: 1fr;
    }
}

/* Restante dos estilos... */
.carousel__item {
    position: relative;
    cursor: pointer;
}
.carousel__item img {
    width: 98%;
}
.containerCollections .itensCollections {
    padding: 5px 5px 0 5px;
    cursor: pointer;
    width: 100%;
    border-radius: 10px;
    transition: all .5s ease-in-out;
    margin: 0;
    position: relative;
}

@media (max-width: 1024px) {
    .containerCollections .itensCollections {
        width: 32%;
    }
}

@media (max-width: 768px) {
    .containerCollections .itensCollections {
        width: 48%;
    }
}

@media (max-width: 480px) {
    .containerCollections .itensCollections {
        width: 100%;
    }
}
.titleCollections {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    color: white;
}
.titleCollectionsPage{
    max-width: 1500px;
    margin: 0 auto;
    margin-top: 100px;
}
.collections {
    background-color: black;
    padding: 20px 0px 50px 0px;
}
.titleCollections h3 {
    font-size: 20px;
    font-weight: 700;
}
.carousel__prev {
    left: -4%;
}
.carousel__next {
    right: -4%;
}
svg.carousel__icon {
    color: white;
}
svg.svg-inline--fa.fa-lock.lockIcon path {
    fill: transparent;
    stroke: white;
    stroke-width: 25px;
}
svg.svg-inline--fa.fa-lock.lockIcon {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 15px;
}
.degradeCarousel__item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #0000007d;
    height: 100%;
    opacity: 0;
    
}
.carousel__item:hover > .degradeCarousel__item {
    opacity: 1;
}
span.textPrivateVideo {
    color: white;
    font-size: 11px;
    position: absolute;
    right: 36px;
    top: 13px;
    opacity: 0;
    transition: all 500ms linear;
    z-index: 2;
}
.carousel__item:hover > span.textPrivateVideo {
    opacity: 1;
}
.descriptionVideo small, .descriptionVideo h4 {
    color: white;
}
.descriptionVideo h4 {
    font-size: 15px;
    margin-left: 5px;
    margin-top: 10px;
    margin-bottom: 0;
}
.descriptionVideo small {
    font-size: 12px;
    margin-left: 5px;
}
.titleCollectionsPage h3 {
    color: white !important;
    font-weight: 700;
}
footer {
    margin-top: 100px !important;
}
.videoCollections img {
    width: 100%;
    height: 300px;
    border-radius: 10px;
}
@media (max-width: 600px) {
    small.description-collections {
        display: none;
    }
    .itensCollections {
        padding: 20px;
    }
    .titleCollectionsPage {
        margin-left: 20px;
    }
    .containerCollections {
        display: block!important;
        gap: 18px;
        flex-wrap: wrap;
        padding: 20px;
        justify-content: start;
    }
    .containerCollections .itensCollections {
        width: 100%;
        margin: 10px 0px!important;
    }
    .videoCollections {
        transition: all 500ms linear;
    }
  
    .itensCollections {
        cursor: pointer;
        width: 100%;
        padding: 15px!important;
    }
    .title-row-3-videos {
        padding: 0px 0px;
    }
    .title-row-3-videos h3 {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
    }
}
.containerCollections {
    display: grid !important;
    grid-template-columns: repeat(4, 1fr) !important;
    gap: 60px 10px !important;
    max-width: 1500px !important;
    margin: 0 auto !important;
}
.videoCollections {
    transition: all 500ms linear;
}

.conteriner-flex-videos {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}
.col-1-videos {
    width: 100%!important;
}
.col-2-videos {
    width: 31%;
}
.col-1-videos img {
    width: 100%;
    border-radius: 10px;
    height: auto!important;
}
.col-2-videos img {
    width: 100%;
    border-radius: 10px;
    height: 95px;
}
.row-1-videos {
    margin-bottom: 10px;
}
.row-3-videos {
    width: 100%;
}
.title-row-3-videos {
    text-align: left;
    padding: 0px 8px;
}
.title-row-3-videos h3 {
    color: white !important;
    font-size: 13px;
    margin: 0;
    font-weight: 600;
}
.title-row-3-videos p {
    color: #a4a4a4;
    font-size: 11px;
    margin: 0;
}
.row-2-videos {
    position: relative;
}
.infoVideosBackground {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #393939b8;
    display: flex;
    align-items: center;
    justify-content: center;
}
.infoVideosBackground span {
    color: white;
}
button.btn-voltar {
    padding: 8px 15px;
    position: absolute;
    top: -45px;
    left: 0;
    color: white !important;
    background: none;
    border: none;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.3s ease;
}

button.btn-voltar:hover {
    transform: translateX(-5px);
}

button.btn-voltar svg {
    color: white !important;
}
.swiper-slide:hover .carousel__item {
    border: 3px solid white;
    border-radius: 10px;
}
.degradeCarousel__item__collection {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #ff8181;
    height: 100%;
    opacity: 1;
    background: rgb(3, 3, 3);
    background: linear-gradient(0deg, rgb(28 28 28 / 68%) 10%, rgb(50 50 50 / 13%) 40%, rgb(57 45 45 / 0%) 100%);
}
.title-row-3-videos {
    text-align: left;
    padding: 0px 8px;
    position: absolute;
    bottom: 0;
    z-index: 4;
    bottom: 10px;
}
#line1 {
    position: absolute;
    top: 14px;
    height: 5px;
    width: 90%;
    background-color: #ffffff;
    left: 0;
    z-index: 1!important;
    margin: 0 auto;
    left: 50%;
    transform: scale(1.5);
    position: absolute;
    top: -3px!important;
    height: 5px;
    width: 90%;
    background-color: #ffffffa6;
    margin: 0 auto;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px 10px 0px 0px;
    transition: all 500ms linear;
}
#line2 {
    position: absolute;
    top: 14px;
    height: 5px;
    width: 90%;
    background-color: #ffffff;
    left: 0;
    z-index: 1;
    margin: 0 auto;
    left: 50%;
    transform: scale(1.5);
    position: absolute;
    top: -11px!important;
    height: 5px;
    width: 84%;
    background-color: #ffffff69;
    margin: 0 auto;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px 10px 0px 0px;
    transition: all 500ms linear;
}
.total-videos {
    position: absolute;
    top: 12px;
    right: 10px;
    background-color: #272727;
    padding: 5px;
    border-radius: 5px;
    color: white;
    font-size: 10px;
}
.col-right img {
    height: 95px !important;
        filter: brightness(0.7);
}   
.videoCount {
    position: absolute;
    bottom: -9%;
    color: #fefefea8;
    font-size: 12px;
    left: 50%;
    transform: translate(-50%, -43%);
    width: 100%;
    background-color: #00000070;
}
.videoCollections.pageCollections img {
    height: auto !important;
}
.titleCollections.pageCollections{
    text-align: left;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center;
}
.sampleFlag {
    background-color: v-bind('finalWebsite?.color');
    padding: 5px;
    border-radius: 5px;
    color: white;
    font-size: 10px;
    top: 25px !important;
    right: 10px !important;
    position: absolute !important;
    bottom: auto !important;
}
.swiper-button-prev:after, .swiper-button-next:after {
    font-family: swiper-icons;
    font-size: var(--swiper-navigation-size);
    text-transform: none !important;
    letter-spacing: 0;
    font-variant: initial;
    line-height: 1;
    font-size: 24px;
    font-weight: bold;
    /* box-shadow: 0px 0px 10px 1px #ffffff; */
}
</style>

<style scoped>
/* Estilos existentes mantidos... */

/* Estilos para o modal de compra */
.purchase-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: #282828;
    padding: 30px;
    border-radius: 10px;
    width: 90%;
    max-width: 400px;
    text-align: center;
}

.modal-content h3 {
    color: white;
    margin-bottom: 15px;
    font-size: 24px;
}

.modal-content p {
    color: #a4a4a4;
    margin-bottom: 25px;
    font-size: 16px;
}

.modal-buttons {
    display: flex;
    gap: 15px;
    justify-content: center;
}

.modal-buttons button {
    padding: 12px 25px;
    border: none;
    border-radius: 6px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
}

.cancel-button {
    background-color: #464646;
    color: white;
}

.buy-button {
    background-color: v-bind('finalWebsite?.color');
    color: white;
}

.cancel-button:hover,
.buy-button:hover {
    filter: brightness(1.1);
    transform: translateY(-1px);
}

/* Novos estilos para as tabs */
.collection-tabs {
    display: flex;
    gap: 20px;
    margin: 20px 0;
}

.tab-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #464646;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease;
}

.tab-button.active {
    background-color: v-bind('finalWebsite?.color');
}

.tab-button:hover {
    filter: brightness(1.1);
}

/* Resto dos estilos mantidos... */
section.collections .titleCollectionsPage{
    position: relative;
    padding: 0 20px;
}

.title-header {
    position: relative;
    margin-bottom: 30px;
}

.title-content {
    text-align: left;
    margin-top: 50px;
}

.title-content h3 {
    font-size: 32px;
    margin-bottom: 10px;
    color: white;
    font-weight: 700;
}

.title-content p {
    font-size: 16px;
    color: #a4a4a4;
    margin: 0;
}

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
}

.loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    gap: 20px;
}

.pagination-button {
    padding: 8px 16px;
    background-color: #464646;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.pagination-button:hover:not(:disabled) {
    background-color: #5e5e5e;
}

.pagination-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.page-info {
    color: white;
    font-size: 14px;
}
.loader {
    z-index: 999999;
    position: absolute;
    top: 31%;
}

.cta-single-collection {
    padding: 15px;
    text-align: center;
    background-color: #1e1e1e;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: 10px 0px;
}

.price-info {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.price-label {
    color: #a4a4a4;
    font-size: 14px;
}

.price-value {
    color: white;
    font-size: 18px;
    font-weight: bold;
}

.price-details {
    color: #a4a4a4;
    font-size: 14px;
}

.button-single-collection {
    background-color: v-bind('finalWebsite?.color');
    color: white;
    border: none;
    border-radius: 6px;
    padding: 12px 17px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    transition: all 0.3s ease;
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
    margin: 0px;
}

.button-single-collection:hover {
    filter: brightness(1.1);
    transform: translateY(-1px);
}

/* Novos estilos para o layout alternativo */
.containerCollectionsAlternative {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 20px;
    max-width: 1500px;
    margin: 0 auto;
}

.itemCollectionAlternative {
    background: #1e1e1e;
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s ease;
}

.itemCollectionAlternative:hover {
    transform: translateY(-5px);
}

.collection-grid {
    display: grid;
    grid-template-columns: 60% 40%;
    gap: 10px;
    padding: 10px;
}

.col-left {
    height: 200px;
}

.col-left .main-image {
    height: 100%;
}

.col-left img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
    cursor: pointer;
}

.col-right {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.col-right .secondary-image {
    height: calc(50% - 5px);
        position: relative;
}

.col-right img {
    width: 100%;
    height: 95px !important;
    object-fit: cover;
    border-radius: 8px;
    cursor: pointer;
}

.collectionInfo {
    padding: 15px;
    text-align: left;
}

.collectionInfo h4 {
    color: white;
    margin: 0 0 10px 0;
    font-size: 13px;
}

.collectionInfo .description {
    color: #a4a4a4;
    font-size: 14px;
    margin: 0 0 10px 0;
}

.collectionInfo .videoCount {
    color: white;
    font-size: 12px;
    background: #272727;
    display: inline-block;
    padding: 5px 10px;
    border-radius: 5px;
}

@media (max-width: 768px) {
    .containerCollectionsAlternative {
        grid-template-columns: 1fr;
    }
    .tab-button {
        padding: 10px!important;
        height: auto!important;
        font-size: 12px!important;
    }
    button.btn-collection {
        min-width: 110px;
        padding: 7px;
        font-size: 11px;
    }
}

/* Restante dos estilos... */
.carousel__item {
    position: relative;
    cursor: pointer;
}
.carousel__item img {
    width: 98%;
}
.containerCollections .itensCollections {
    padding: 5px 5px 0 5px;
    cursor: pointer;
    width: 100%;
    border-radius: 10px;
    transition: all .5s ease-in-out;
    margin: 0;
    position: relative;
}

@media (max-width: 1024px) {
    .containerCollections .itensCollections {
        width: 32%;
    }
}

@media (max-width: 768px) {
    .containerCollections .itensCollections {
        width: 48%;
    }
}

@media (max-width: 480px) {
    .containerCollections .itensCollections {
        width: 100%;
    }
}
.titleCollections {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    color: white;
}
.titleCollectionsPage{
    max-width: 1500px;
    margin: 0 auto;
    margin-top: 100px;
}
.collections {
    background-color: black;
    padding: 20px 0px 50px 0px;
}
.titleCollections h3 {
    font-size: 20px;
    font-weight: 700;
}
.carousel__prev {
    left: -4%;
}
.carousel__next {
    right: -4%;
}
svg.carousel__icon {
    color: white;
}
svg.svg-inline--fa.fa-lock.lockIcon path {
    fill: transparent;
    stroke: white;
    stroke-width: 25px;
}
svg.svg-inline--fa.fa-lock.lockIcon {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 15px;
}
.degradeCarousel__item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #0000007d;
    height: 100%;
    opacity: 0;
    
}
.carousel__item:hover > .degradeCarousel__item {
    opacity: 1;
}
span.textPrivateVideo {
    color: white;
    font-size: 11px;
    position: absolute;
    right: 36px;
    top: 13px;
    opacity: 0;
    transition: all 500ms linear;
    z-index: 2;
}
.carousel__item:hover > span.textPrivateVideo {
    opacity: 1;
}
.descriptionVideo small, .descriptionVideo h4 {
    color: white;
}
.descriptionVideo h4 {
    font-size: 15px;
    margin-left: 5px;
    margin-top: 10px;
    margin-bottom: 0;
}
.descriptionVideo small {
    font-size: 12px;
    margin-left: 5px;
}
.titleCollectionsPage h3 {
    color: white !important;
    font-weight: 700;
}
footer {
    margin-top: 100px !important;
}
.videoCollections img {
    width: 100%;
    height: 300px;
    border-radius: 10px;
}
@media (max-width: 600px) {
    small.description-collections {
        display: none;
    }
    .itensCollections {
        padding: 20px;
    }
    .titleCollectionsPage {
        margin-left: 20px;
    }
    .containerCollections {
        display: block!important;
        gap: 18px;
        flex-wrap: wrap;
        padding: 20px;
        justify-content: start;
    }
    .containerCollections .itensCollections {
        width: 100%;
        margin: 10px 0px!important;
    }
    .videoCollections {
        transition: all 500ms linear;
    }
  
    .itensCollections {
        cursor: pointer;
        width: 100%;
        padding: 15px!important;
    }
}
.containerCollections {
    display: grid !important;
    grid-template-columns: repeat(4, 1fr) !important;
    gap: 60px 10px !important;
    max-width: 1500px !important;
    margin: 0 auto !important;
}
.videoCollections {
    transition: all 500ms linear;
}

.conteriner-flex-videos {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}
.col-1-videos {
    width: 100%!important;
}
.col-2-videos {
    width: 31%;
}
.col-1-videos img {
    width: 100%;
    border-radius: 10px;
    height: auto!important;
}
.col-2-videos img {
    width: 100%;
    border-radius: 10px;
    height: 95px;
}
.row-1-videos {
    margin-bottom: 10px;
}
.row-3-videos {
    width: 100%;
}
.title-row-3-videos {
    text-align: left;
    padding: 0px 8px;
}
.title-row-3-videos h3 {
    color: white !important;
    font-size: 13px;
    margin: 0;
    font-weight: 600;
}
.title-row-3-videos p {
    color: #a4a4a4;
    font-size: 11px;
    margin: 0;
}
.row-2-videos {
    position: relative;
}
.infoVideosBackground {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #393939b8;
    display: flex;
    align-items: center;
    justify-content: center;
}
.infoVideosBackground span {
    color: white;
}
button.btn-voltar {
    padding: 8px 15px;
    position: absolute;
    top: -45px;
    left: 0;
    color: white !important;
    background: none;
    border: none;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.3s ease;
}

button.btn-voltar:hover {
    transform: translateX(-5px);
}

button.btn-voltar svg {
    color: white !important;
}
.swiper-slide:hover .carousel__item {
    border: 3px solid white;
    border-radius: 10px;
}
.degradeCarousel__item__collection {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #ff8181;
    height: 100%;
    opacity: 1;
    background: rgb(3, 3, 3);
    background: linear-gradient(0deg, rgb(28 28 28 / 68%) 10%, rgb(50 50 50 / 13%) 40%, rgb(57 45 45 / 0%) 100%);
}
.title-row-3-videos {
    text-align: left;
    padding: 0px 8px;
    position: absolute;
    bottom: 0;
    z-index: 4;
    bottom: 10px;
}
#line1 {
    position: absolute;
    top: 14px;
    height: 5px;
    width: 90%;
    background-color: #ffffff;
    left: 0;
    z-index: 1!important;
    margin: 0 auto;
    left: 50%;
    transform: scale(1.5);
    position: absolute;
    top: -3px!important;
    height: 5px;
    width: 90%;
    background-color: #ffffffa6;
    margin: 0 auto;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px 10px 0px 0px;
    transition: all 500ms linear;
}
#line2 {
    position: absolute;
    top: 14px;
    height: 5px;
    width: 90%;
    background-color: #ffffff;
    left: 0;
    z-index: 1;
    margin: 0 auto;
    left: 50%;
    transform: scale(1.5);
    position: absolute;
    top: -11px!important;
    height: 5px;
    width: 84%;
    background-color: #ffffff69;
    margin: 0 auto;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px 10px 0px 0px;
    transition: all 500ms linear;
}
.total-videos {
    position: absolute;
    top: 12px;
    right: 10px;
    background-color: #272727;
    padding: 5px;
    border-radius: 5px;
    color: white;
    font-size: 10px;
}
.col-right img {
    height: 95px !important;
        filter: brightness(0.7);
}   
.videoCount {
    position: absolute;
    bottom: -9%;
    color: #fefefea8;
    font-size: 12px;
    left: 50%;
    transform: translate(-50%, -43%);
    width: 100%;
    background-color: #00000070;
}
.videoCollections.pageCollections img {
    height: auto !important;
}
.titleCollections.pageCollections{
    text-align: left;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center;
}
.sampleFlag {
    background-color: v-bind('finalWebsite?.color');
    padding: 5px;
    border-radius: 5px;
    color: white;
    font-size: 10px;
    top: 25px !important;
    right: 10px !important;
    position: absolute !important;
    bottom: auto !important;
}
.swiper-button-prev:after, .swiper-button-next:after {
    font-family: swiper-icons;
    font-size: var(--swiper-navigation-size);
    text-transform: none !important;
    letter-spacing: 0;
    font-variant: initial;
    line-height: 1;
    font-size: 24px;
    font-weight: bold;
    /* box-shadow: 0px 0px 10px 1px #ffffff; */
}
</style>