<template>
    <div class="container forum-container adminWeeducForum">
        <div class="title-container">
            <h3 class="title">Fórum</h3>
            <p class="subtitle">Gerencie os fóruns do sistema.</p>
            <button class="btn-weflix absolute" @click="newForumDialog = true">
                <fa icon="fa-plus" />
                <span>Novo Fórum</span>
            </button>
        </div>

        <div class="search-row">
            <div class="form-group search-form-group" style="flex: 8;">
                <div class="form-input search-form-input">
                    <input
                        type="text" 
                        class="form-control search-form-control"
                        placeholder="Pesquisar fórum..."
                        v-model="searchText"
                    />
                    <fa icon="fa-search" class="input-icon search-input-icon" @click="buscarForum" />
                </div>
            </div>

            <div class="form-group filter-form-group" style="flex: 2; display: flex; align-items: center;">
                <label style="margin-right: 0.5rem;">Filtrar por:</label>
                <select v-model="statusFilter" class="form-control filter-form-control" @change="filtrarForums">
                    <option value="todos">Todos</option>
                    <option value="ativo">Ativo</option>
                    <option value="inativo">Inativo</option>
                </select>
            </div>
        </div>

        <Loader v-if="!hasReady" />
        <div v-else class="table-responsive forums-table-responsive">
            <table class="table forums-table">
                <thead>
                    <tr>
                        <th style="width: 15%; text-align: left !important; border-left: none !important; border-right: none !important;">ID</th>
                        <th style="width: 60%; text-align: left;  border-left: none !important; border-right: none !important;">Título</th>
                        <th style="width: 20%; text-align: center !important; border-left: none !important; border-right: none !important;">Status</th>
                        <th style="width: 5%; text-align: center !important; border-left: none !important; border-right: none !important;">Ver</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="forum in paginatedForums" :key="forum.id">
                        <td style="width: 15%; text-align: left;">#{{forum.id}}</td>
                        <td style="width: 60%; text-align: left;">{{forum.title}}</td>
                        <td style="width: 20%; text-align: center;">
                            <span :class="forum.status && forum.status == 1 ? 'badge-active' : 'badge-inactive'">
                                {{forum.status && forum.status == 1 ? 'Aberto' : 'Fechado'}}
                            </span>
                        </td>
                        <td style="width: 5%; text-align: center;">
                            <button class="btn-edit forum-btn-edit" @click="editForum(forum.id, forum)">
                                <fa icon="fa-file-alt"/>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="pagination forums-pagination">
            <button 
                :disabled="currentPage === 1" 
                @click="currentPage--"
                class="page-btn forums-page-btn"
            >
                Anterior
            </button>
            <span class="page-info forums-page-info">Página {{currentPage}} de {{totalPages}}</span>
            <button 
                :disabled="currentPage === totalPages" 
                @click="currentPage++"
                class="page-btn forums-page-btn"
            >
                Próxima
            </button>
        </div>

        <Loader :isActive="hasReady" />

        <v-dialog v-model="newForumDialog" max-width="600">
            <v-card class="new-forum-dialog">
                <div class="modal-header">
                    <h5 class="modal-title">Criar Novo Fórum</h5>
                    <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
                  </div>
                <v-card-text>
                    <v-text-field v-model="newForumTitle" label="Título do Fórum" required></v-text-field>
                    <v-text-field v-model="newForumDescription" label="Descrição do Fórum" required></v-text-field>
                </v-card-text>
                <v-card-actions class="new-forum-actions">
                    <v-spacer></v-spacer>
                    <v-btn class="new-forum-btn cancel" text @click="newForumDialog = false">Cancelar</v-btn>
                    <v-btn class="new-forum-btn confirm" text @click="createNewForum" :disabled="!newForumTitle.length">Criar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

import Loader from '@/components/Loader/loader.vue';
import ApiForum from '@/utils/forum'

import { mapState, mapActions } from 'vuex';


export default {
    data() {
        return {
            label: this.labels,
            forumsFiltered: [],
            searchTerm: '',
            currentPage: 1,
            itemsPerPage: 5,
            alertOpenForum:false,
            removedForumName:'',
            afterWidth:40,
            forumsData:[],
            openModalCreateForum:false,
            openModalEditForum:false,
            id:'',
            message:'',
            title:'',
            hasReady: false,
            status: '1',
            isMobileIcon: false,
            statusFilter: 'todos',
            searchText: '',
            newForumDialog: false,
            newForumTitle: '',
            newForumDescription: ''
        };
    },
    mounted() {
        this.checkScreenSize();
        window.addEventListener('resize', this.checkScreenSize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.checkScreenSize);
    },
    computed:{
        ...mapState(["token","numberIntensTable","forum"]),

        paginatedForums() {
            const startIndex = (this.currentPage - 1) * this.numberIntensTable;
            const endIndex = startIndex + this.numberIntensTable;
            return this.filteredForums.slice(startIndex, endIndex);
        },
        totalPages() {
            return Math.ceil(this.filteredForums.length / this.numberIntensTable);
        },
        filteredForums() {
            let filtered = this.forumsData;

            if (this.searchText && this.searchText.trim() !== '') {
                filtered = filtered.filter((forum) =>
                    forum.title.toLowerCase().includes(this.searchText.toLowerCase())
                );
            }
            if (this.statusFilter !== 'todos') {
                filtered = filtered.filter((forum) =>
                    forum.status && (forum.status === (this.statusFilter === 'ativo' ? 1 : 0))
                );
            }

            return filtered;
        }
    },
    methods: {
        ...mapActions(["storeForum"]),
        filtrarForums() {
            this.currentPage = 1;
        },
        previousPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },
        removeForum(id, name){
            this.removedForumName = name;
            this.alertOpenForum = !this.alertOpenForum;
            setTimeout(() => {
                this.alertOpenForum = !this.alertOpenForum;
                this.removedForumName = '';
            }, 4000);
        },
        editForum(id, forum){
            this.storeForum({forum: forum});
            this.$router.push('/dashboard/forum/'+id);
           
        },
        createForum(){
            this.openModalCreateForum = !this.openModalCreateForum;
        },
        updateValueCreate(newValue){
            this.openModalCreateForum = newValue;
            this.getDataForum();
        },
        updateValueEdit(newValue){
            this.getDataForum();

            this.openModalEditForum = newValue;
        },
        checkScreenSize() {
            this.isMobileIcon = window.innerWidth <= 600;
        },
        async getForumData(){
            try{
                const response = await ApiForum.getDataForum(this.token);
                this.forumsData = response;
                this.hasReady = true;
            }
            catch(err){
                this.hasReady = false;
                console.error('Erro ao carregar dados do Forum')
            }
        },
        async createNewForum() {
            const payload = {
                title: this.newForumTitle,
                description: this.newForumDescription,
                status: 1
            }
            if (this.newForumTitle.trim()) {
                try {
                    const response = await ApiForum.createNewForum(payload, this.token);  
                    this.newForumDialog = false;
                    this.newForumTitle = '';
                    this.newForumDescription = '';
                    this.getForumData();
                } catch (err) {
                    console.error('Erro ao criar novo fórum:', err);
                }
            }
        }
    },
    components: {
        Loader
    },
    created(){
        this.getForumData();
    },
    
};
</script>

<style scoped>
.container {
    max-width: 100%;
    background-color: #ffffff;
    padding: 1.5rem;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}
.search-form-input{
    position: relative;
    width: 100%;
}
.title-container {
      position: relative;
    margin-bottom: 10px;
    padding-bottom: 0;
    border-bottom: 1px solid #f0f0f0;
    text-align: left;
}

.title-container .title {
    font-size: 1.5rem;
    font-weight: 700;
    color: #000000;
    margin-bottom: 0.5rem;
}

.title-container .subtitle {
    color: #6b7280;
    font-size: 0.875rem;
    line-height: 1.4;
    margin-bottom: 1rem;
}

.search-row {
    display: flex;
    gap: 0.75rem;
    margin-bottom: 1.5rem;
}

.search-row .form-group {
    flex: 1;
}

.btn-weflix.absolute {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #2f2e8b;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: 600;
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(47, 46, 139, 0.2);
}

.btn-weflix.absolute:hover {
    background-color: #23225f;
    transform: translateY(-2px);
}

.forums-table-responsive {
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 1rem;
}

.forums-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
}

.forums-table th {
    background-color: #f8fafc;
    padding: 0.75rem;
    font-weight: 600;
    color: #4a5568;
    text-align: left;
    border-bottom: 2px solid #e2e8f0;
}

.forums-table td {
    padding: 0.75rem;
    vertical-align: middle;
    border-bottom: 1px solid #f0f0f0;
    color: #4a5568;
}

.forums-table tr:hover {
    background-color: #f8fafc;
}

.badge-active, .badge-inactive {
    padding: 0.25rem 0.75rem;
    border-radius: 5px;
    font-size: 0.75rem;
    font-weight: 500;
    display: inline-block;
    text-align: center;
    transition: all 0.2s ease;
    width: 70%;
}

.badge-active {
    background-color: #7bcc8180;
    color: #4b614e;
}

.badge-inactive {
  background-color: #d7d7ee80;
    color: #2f2e8b;
    }

.forum-btn-edit {
    padding: 0.4rem;
    margin: 0 0.25rem;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
   color: #2f2e8b;
}


.search-form-input {
    position: relative;
}

.search-form-control {
    width: 100%;
    padding: 0.5rem 0.75rem;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    font-size: 0.875rem;
    transition: all 0.2s ease;
}

.search-form-control:focus {
    outline: none;
    border-color: #2f2e8b;
    box-shadow: 0 0 0 3px rgba(47, 46, 139, 0.1);
}

.search-input-icon {
    position: absolute;
    right: 0.75rem;
    top: 45%;
    transform: translateY(-50%);
    color: #9ca3af;
    cursor: pointer;
}

.forums-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
}

.forums-page-btn {
    padding: 0.5rem 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    background: white;
    color: #4a5568;
    cursor: pointer;
    transition: all 0.2s;
}

.forums-page-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.forums-page-btn:not(:disabled):hover {
    background: #f8fafc;
    border-color: #cbd5e0;
}

.forums-page-info {
    color: #4a5568;
    font-size: 0.875rem;
}

@media (max-width: 768px) {
    .container {
        padding: 1rem;
    }

    .title-container {
        margin-bottom: 1.5rem;
    }

    .search-row {
        flex-direction: column;
    }

    .forums-table th, .forums-table td {
        padding: 0.5rem;
    }
}

@media (max-width: 600px) {
    .btn-weflix.absolute {
        padding: 0.5rem;
    }
    
    .btn-weflix.absolute span {
        display: none;
    }

    .forums-table-responsive {
        overflow-x: auto;
    }
}
.content.dashboard > .container {
    background-color: white;
    padding: 20px;
    padding-bottom: 35px;
    box-shadow: 0px 6px 10px 0px #dfdfdf;
    min-height: 89vh;
    padding-bottom: 0;
}
select.form-control.filter-form-control {
    width: 60%;
}
.form-group.filter-form-group {
    justify-content: space-around;
    align-items: center;
    margin: 0 !important;
}
.form-group.search-form-group {
    margin: 0 !important;
}
table.table.forums-table {
    border: none !important;
    padding: 0 !important;
}
button.btn-edit.forum-btn-edit svg {
    color: #2f2e8b;
}
.form-group.filter-form-group {
    display: flex;
}
.btn-weflix.absolute span.flex {
    font-size: 16px;
}
.btn-weflix.absolute svg {
    color: white;
    font-size: 15px;
}

.new-forum-dialog {
    padding: 1rem;
}

.new-forum-title {
    color: #2f2e8b;
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.new-forum-actions {
    padding: 1rem;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
}

.new-forum-btn {
    text-transform: none !important;
}

.new-forum-btn.cancel {
    color: #6b7280;
}

.new-forum-btn.confirm {
    background-color: #2f2e8b;
    color: white;
}

.new-forum-btn.confirm:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
/* DARK */
.dashboard.dark .content.dashboard > .container.forum-container.adminWeeducForum {
    background-color: #000;
}
.dashboard.dark .container.forum-container.adminWeeducForum .title-container .title {
    color: white !important;
}
.dashboard.dark .container.forum-container.adminWeeducForum .subtitle {
    color: white !important;
}
.dashboard.dark .container.forum-container.adminWeeducForum .form-group label, 
.dashboard.dark .container.forum-container.adminWeeducForum .title,
.dashboard.dark .container.forum-container.adminWeeducForum .forums-page-info,
.dashboard.dark .container.forum-container.adminWeeducForum svg {
    color: white !important;
}
.dashboard.dark .container.forum-container.adminWeeducForum .form-group .form-input .form-control {
    background-color: #575757 !important;
    border: 1px solid #444 !important;
}
.dashboard.dark .container.forum-container.adminWeeducForum .forums-table th {
    border-bottom: 2px solid #575757;
}
.dashboard.dark .container.forum-container.adminWeeducForum .forums-table td {
    border-bottom: 1px solid #575757;
}
.dashboard.dark .container.forum-container.adminWeeducForum .form-control {
    background-color: #575757;
    color: white;
    border: 1px solid #444;
}
.dashboard.dark .container.forum-container.adminWeeducForum .forums-table-responsive {
    background-color: #000;
}
.dashboard.dark .container.forum-container.adminWeeducForum .title-container {
    border-bottom: 1px solid #575757 !important;
}
.modal-header {
    padding: 16px 24px 10px;
}
</style>