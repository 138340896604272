<template>
  <div class="site_content" :class="{ 'fixed-site-preview': positionFixedSite }">
    <div class="row site demo" :class="{ 'fixed-site-left': positionFixedSite, 'dark': websites.typeLayout == 0, 'light': websites.typeLayout == 1 }">
      <section class="config-form primary col-12">
        <div class="title site toggle">
          <h4>
            <span class="toggle-icon" @click="toggleSection('bloco1')">
              <fa class="fas" :icon="sections.bloco1 ? 'fa-chevron-down' : 'fa-chevron-up'"/>
            </span>
            Banner
          </h4>
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              v-model="bloco1_active"
            />
          </div>
        </div>
        <div class="content-text" v-show="sections.bloco1">
          <div class="form-group">
            <div class="form-label">
              <label>Imagem</label>
            </div>
            <div class="form-file">
              <div class="file-wrapper primary">
                <span class="thumbnail">
                  <span class="icon">
                    <svg
                      width="46"
                      height="39"
                      viewBox="0 0 46 39"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M45 19.9308L36.7274 12.3475C36.1595 11.827 35.2948 11.8047 34.7009 12.2953L18.824 25.411C18.3196 25.8277 17.6074 25.8812 17.0464 25.5446L11.1009 21.9773C10.5893 21.6703 9.94666 21.6856 9.45024 22.0166L1 27.6501"
                        stroke="#9D9FA3"
                        stroke-width="1.25714"
                      ></path>
                      <rect
                        x="1.00049"
                        y="1.40454"
                        width="43.9461"
                        height="36.1909"
                        rx="2.58507"
                        stroke="#9D9FA3"
                        stroke-width="1.25714"
                      ></rect>
                      <circle
                        cx="14.8948"
                        cy="10.6676"
                        r="3.71629"
                        stroke="#9D9FA3"
                        stroke-width="1.25714"
                      ></circle>
                    </svg>
                  </span>
                  <span class="text">
                    Clique aqui para selecionar a imagem
                  </span>
                  <span class="text">
                    1920x1080
                  </span>
                </span>
                <input class="form-control" type="file" @change="updateFilePathBanner1" accept=".png, .jpg, .jpeg, .webp"/>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="form-label">
              <label>Título:</label>
            </div>
            <div class="form-input">
              <input
                type="text"
                class="form-control"
                placeholder="Curso X - O melhor curso de matemática do mercado"
                v-model="bloco1_title"
              />
            </div>
          </div>
          <div class="form-group">
            <div class="form-label">
              <label>Endereço da URL:</label>
            </div>
            <div class="form-input">
              <input
                type="text"
                class="form-control"
                placeholder="https://seucurso.com.br"
                v-model="bloco1_link"
              />
            </div>
          </div>
          <div class="form-group">
            <div class="form-label">
              <label>Descrição</label>
            </div>
            <div class="form-input">
              <textarea
                placeholder="Somos o maior curso de matemática..."
                v-model="bloco1_description"
              ></textarea>
            </div>
          </div>
        </div>
      </section>

      <section class="config-form primary col-12">
        <div class="title site toggle">
          <h4>
            <span class="toggle-icon" @click="toggleSection('bloco2')">
              <fa class="fas" :icon="sections.bloco2 ? 'fa-chevron-down' : 'fa-chevron-up'"/>
            </span>
            Listagem de Cursos
          </h4>
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              v-model="bloco2_active"
            />
          </div>
        </div>
        <div class="content-text" v-show="sections.bloco2">
          <div class="form-group">
            <div class="form-label">
              <label>Título:</label>
            </div>
            <div class="form-input">
              <input
                type="text"
                class="form-control"
                placeholder="Veja o que os alunos estão dizendo"
                v-model="bloco2_title"
              />
            </div>
          </div>
        </div>
      </section>

      <section class="config-form primary col-12">
        <div class="title site toggle">
          <h4>
            <span class="toggle-icon" @click="toggleSection('bloco3')">
              <fa class="fas" :icon="sections.bloco3 ? 'fa-chevron-down' : 'fa-chevron-up'"/>
            </span>
            Conteúdo Institucional
          </h4>
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              v-model="bloco3_active"
            />
          </div>
        </div>
        <div class="content-text" v-show="sections.bloco3">
          <div class="form-group">
            <div class="form-label">
              <label>Imagem</label>
            </div>
            <div class="form-file">
              <div class="file-wrapper primary">
                <span class="thumbnail">
                  <span class="icon">
                    <svg
                      width="46"
                      height="39"
                      viewBox="0 0 46 39"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M45 19.9308L36.7274 12.3475C36.1595 11.827 35.2948 11.8047 34.7009 12.2953L18.824 25.411C18.3196 25.8277 17.6074 25.8812 17.0464 25.5446L11.1009 21.9773C10.5893 21.6703 9.94666 21.6856 9.45024 22.0166L1 27.6501"
                        stroke="#9D9FA3"
                        stroke-width="1.25714"
                      ></path>
                      <rect
                        x="1.00049"
                        y="1.40454"
                        width="43.9461"
                        height="36.1909"
                        rx="2.58507"
                        stroke="#9D9FA3"
                        stroke-width="1.25714"
                      ></rect>
                      <circle
                        cx="14.8948"
                        cy="10.6676"
                        r="3.71629"
                        stroke="#9D9FA3"
                        stroke-width="1.25714"
                      ></circle>
                    </svg>
                  </span>
                  <span class="text">
                    Clique aqui para selecionar a imagem
                  </span>
                  <span class="text">
                    1920x1080
                  </span>
                </span>
                <input class="form-control" type="file" @change="updateFilePathBanner3" accept=".png, .jpg, .jpeg, .webp"/>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="form-label">
              <label>Título:</label>
            </div>
            <div class="form-input">
              <input
                type="text"
                class="form-control"
                placeholder="Curso X - O melhor curso de matemática do mercado"
                v-model="bloco3_title"
              />
            </div>
          </div>
          <div class="form-group">
            <div class="form-label">
              <label>Endereço da URL:</label>
            </div>
            <div class="form-input">
              <input
                type="text"
                class="form-control"
                placeholder="https://seucurso.com.br"
                v-model="bloco3_link"
              />
            </div>
          </div>
          <div class="form-group">
            <div class="form-label">
              <label>Descrição</label>
            </div>
            <div class="form-input">
              <textarea
                placeholder="Somos o maior curso de matemática..."
                v-model="bloco3_description"
              ></textarea>
            </div>
          </div>
        </div>
      </section>

      <section class="config-form primary col-12">
        <div class="title site toggle">
          <h4>
            <span class="toggle-icon" @click="toggleSection('bloco4')">
              <fa class="fas" :icon="sections.bloco4 ? 'fa-chevron-down' : 'fa-chevron-up'"/>
            </span>
            Depoimentos
          </h4>
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              v-model="bloco4_active"
            />
          </div>
        </div>
        <div class="content-text" v-show="sections.bloco4">
          <div class="form-group">
            <div class="form-label">
              <label>Título:</label>
            </div>
            <div class="form-input">
              <input
                type="text"
                class="form-control"
                placeholder="Curso X - O melhor curso de matemática do mercado"
                v-model="titleDepoimentos"
              />
            </div>
          </div>
          <hr/>
          <div class="form-group">
            <div class="form-label">
              <label>Imagem</label>
            </div>
            <div class="form-file">
              <div class="file-wrapper primary">
                <span class="thumbnail">
                  <span class="icon">
                    <svg
                      width="46"
                      height="39"
                      viewBox="0 0 46 39"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M45 19.9308L36.7274 12.3475C36.1595 11.827 35.2948 11.8047 34.7009 12.2953L18.824 25.411C18.3196 25.8277 17.6074 25.8812 17.0464 25.5446L11.1009 21.9773C10.5893 21.6703 9.94666 21.6856 9.45024 22.0166L1 27.6501"
                        stroke="#9D9FA3"
                        stroke-width="1.25714"
                      ></path>
                      <rect
                        x="1.00049"
                        y="1.40454"
                        width="43.9461"
                        height="36.1909"
                        rx="2.58507"
                        stroke="#9D9FA3"
                        stroke-width="1.25714"
                      ></rect>
                      <circle
                        cx="14.8948"
                        cy="10.6676"
                        r="3.71629"
                        stroke="#9D9FA3"
                        stroke-width="1.25714"
                      ></circle>
                    </svg>
                  </span>
                  <span class="text">
                    Clique aqui para selecionar a imagem
                  </span>
                  <span class="text">
                    1920x1080
                  </span>
                </span>
                <input class="form-control" type="file" @change="updateFilePathDepoimento1" accept=".png, .jpg, .jpeg, .webp"/>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="form-label">
              <label>Nome</label>
            </div>
            <div class="form-input">
              <input
                type="text"
                class="form-control"
                placeholder="Nome do aluno"
                v-model="depoimentos[0].name"
              />
            </div>
          </div>
          <div class="form-group">
            <div class="form-label">
              <label>Descrição</label>
            </div>
            <div class="form-input">
              <textarea
                placeholder="Depoimento do aluno..."
                v-model="depoimentos[0].description"
              ></textarea>
            </div>
          </div>
          <hr/>
          <div class="form-group">
            <div class="form-label">
              <label>Imagem</label>
            </div>
            <div class="form-file">
              <div class="file-wrapper primary">
                <span class="thumbnail">
                  <span class="icon">
                    <svg
                      width="46"
                      height="39"
                      viewBox="0 0 46 39"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M45 19.9308L36.7274 12.3475C36.1595 11.827 35.2948 11.8047 34.7009 12.2953L18.824 25.411C18.3196 25.8277 17.6074 25.8812 17.0464 25.5446L11.1009 21.9773C10.5893 21.6703 9.94666 21.6856 9.45024 22.0166L1 27.6501"
                        stroke="#9D9FA3"
                        stroke-width="1.25714"
                      ></path>
                      <rect
                        x="1.00049"
                        y="1.40454"
                        width="43.9461"
                        height="36.1909"
                        rx="2.58507"
                        stroke="#9D9FA3"
                        stroke-width="1.25714"
                      ></rect>
                      <circle
                        cx="14.8948"
                        cy="10.6676"
                        r="3.71629"
                        stroke="#9D9FA3"
                        stroke-width="1.25714"
                      ></circle>
                    </svg>
                  </span>
                  <span class="text">
                    Clique aqui para selecionar a imagem
                  </span>
                  <span class="text">
                    1920x1080
                  </span>
                </span>
                <input class="form-control" type="file" @change="updateFilePathDepoimento2" accept=".png, .jpg, .jpeg, .webp"/>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="form-label">
              <label>Nome</label>
            </div>
            <div class="form-input">
              <input
                type="text"
                class="form-control"
                placeholder="Nome do aluno"
                v-model="depoimentos[1].name"
              />
            </div>
          </div>
          <div class="form-group">
            <div class="form-label">
              <label>Descrição</label>
            </div>
            <div class="form-input">
              <textarea
                placeholder="Depoimento do aluno..."
                v-model="depoimentos[1].description"
              ></textarea>
            </div>
          </div>
        </div>
      </section>

      <section class="config-form primary col-12">
        <div class="title site toggle">
          <h4>
            <span class="toggle-icon" @click="toggleSection('bloco5')">
              <fa class="fas" :icon="sections.bloco5 ? 'fa-chevron-down' : 'fa-chevron-up'"/>
            </span>
            Redes Sociais
          </h4>
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              v-model="bloco5_active"
            />
          </div>
        </div>
        <div class="content-text" v-show="sections.bloco5">
          <div class="list">
            <div class="col-form-inner col-12 col-md-12 col-lg-12 social" v-for="(item, index) in names" :key="index">
              <div class="form-group socialMedia">
                <div class="description">
                  <span>{{ item }}</span>
                </div>
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    role="switch"
                    v-model="activeSocial[index]"
                    :id="'flexSwitchCheck' + index"
                  />
                </div>
              </div>
              <div class="form-group full">
                <div class="form-input">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="www.facebook.com.br"
                    v-model="linkSocial[index]"
                    :disabled="!activeSocial[index]"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div class="row site demo" :class="{ 'fixed-site': positionFixedSite, 'dark': websites.typeLayout == 0, 'light': websites.typeLayout == 1 }">
      <section class="config-form primary col-12" :class="!bloco1_active?'blur':''">
        <div class="box_1">
          <div class="banner">
            <div class="banner-content">
              <img src='../../../assets//1920x1080.png' alt="" v-show="!bloco1_banner"/>
              <img :src="bloco1_bannerPreview" alt="" v-show="bloco1_banner"/>
              <div class="banner-text">
                <h2>{{bloco1_title}}</h2>
                <p>{{bloco1_description}}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="config-form primary col-12 itens-courses" :class="!bloco2_active?'blur':''">
        <p style="margin: 0px;">{{bloco2_title}}</p>
        <div class="box_2" v-for="item, i in 8" :key="i">
          <div class="cursos">
            <div class="banner-content cursos">
              <img src='../../../assets/190x112.png' alt="" />
              <div class="banner-text">
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="config-form primary col-12 itens-courses" :class="!bloco3_active?'blur':''">
        <div class="box_3">
          <div class="cursos">
            <div class="banner-content cursos d-flex align-items-center">
              <div class="banner-image" style="flex: 1;">
                <img src='../../../assets/1920x1080.png' alt="" v-show="!bloco3_banner"/>
                <img :src="bloco3_bannerPreview" alt="" v-show="bloco3_banner"/>
              </div>
              <div class="banner-text" style="flex: 1; position: static;">
                <h2>{{bloco3_title}}</h2>
                <p>{{bloco3_description}}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="config-form primary col-12" :class="!bloco4_active?'blur':''">
        <div class="box_4">
          <div class="banner depoimentos">
            <h2 class="section-title">{{titleDepoimentos}}</h2>
            <div class="depoimentos-slider">
                <div v-for="(depoimento, index) in depoimentos" 
                     :key="index"
                     class="depoimento-slide">
                  <div class="depoimento-content">
                    <div class="depoimento-image">
                      <img :src="depoimento.preview ? depoimento.preview : require('@/assets/user.png')" alt="Foto depoimento" />
                    </div>
                    <div class="depoimento-text">
                      <h3 class="depoimento-nome">{{depoimento?.name ? depoimento.name : 'Nome...'}}</h3>
                      <p class="depoimento-descricao">{{depoimento?.description ? depoimento.description : 'Depoimento...'}}</p>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </section>

      <section class="config-form primary col-12" :class="!bloco5_active?'blur':''">
        <div class="box_5">
          <div class="logoPreview">
            <img :src="websites.logoFinal" alt="" />
          </div>
          <div class="redes-sociais">
         
            <div class="rede-social" v-for="(item, index) in names" :key="index" v-show="activeSocial[index]">
              <a :href="linkSocial[index]" target="_blank">
                <fa :icon="['fa-brands', getSocialIcon(item)]"/>
              </a>
            </div>
          </div>
        </div>
      </section>
      
    </div>
  </div>
  <div class="content-button site">
    <div class="button">
      <button type="button" @click="saveGeral" class="btn btn-block btn-content">
        Salvar e Publicar
      </button>
      <button type="button" class="btn btn-block btn-content">Voltar</button>
      <button type="button" class="btn btn-block btn-content">
        Limpar Cache
      </button>
    </div>
  </div>
</template>



<script>
import api from '@/utils/site';
import websiteApi from '@/utils/Weflix/website';
import apiSiteContent from '@/utils/Site/content';
import apiSiteSocialMidia from '@/utils/Site/socialMidia';
import apiTestimony from '@/utils/Site/testimony.js';

import apiAWS from '@/utils/storage/aws';
import { mapState } from 'vuex';

export default {
  data() {
    return {
      sections: {
        bloco1: true,
        bloco2: true,
        bloco3: true,
        bloco4: true,
        bloco5: true
      },
      listContents:[],
      currentSlide: 0,
      slideInterval: null,

      bloco1_active: false,
      bloco2_active: false,
      bloco3_active: false,
      bloco4_active: false,
      bloco5_active: false,

      bloco1_banner: '', 
      bloco1_bannerPreview: null, 
      bloco1_title: '',
      bloco1_link: '',
      bloco1_description: '',

      bloco2_title: '',

      bloco3_banner: '', 
      bloco3_bannerPreview: null, 
      bloco3_title: '',
      bloco3_link: '',
      bloco3_description: '',
      
      bloco4_title: '',

      positionFixedSite: false,

      idSite:null,  

      activeSocial: Array(6).fill(false),
      linkSocial: Array(6).fill(''),
      names: ['Facebook', 'Instagram', 'Youtube', 'Linkedin', 'Whatsapp', 'TikTok'],

      titleDepoimentos: '',
      depoimentos: [
        {
          id: null,
          name: '',
          photo: '',
          photoFinal: '',
          description: '',
        },
        {
          id: null,
          name: '',
          photo: '',
          photoFinal: '',
          description: '',
        }
      ]
    };
  },
  computed: {
    ...mapState(['token','websites'])
  },
  methods: {
    async saveGeral(){
      try {
        await this.save();
        await this.saveDepoimentos();
        await this.editSocialMedia();
        alert('Dados atualizados com sucesso!');
      } catch (error) {
        console.error('Erro ao atualizar os dados:', error);
      }
    },
    toggleSection(section) {
      this.sections[section] = !this.sections[section];
    },
    getSocialIcon(rede) {
      const icons = {
        Facebook: 'fa-facebook',
        Instagram: 'fa-instagram', 
        Youtube: 'fa-youtube',
        Linkedin: 'fa-linkedin',
        Whatsapp: 'fa-whatsapp',
        TikTok: 'fa-tiktok'
      };
      return icons[rede];
    },
    async putFileAws(url, file){
      try {
        const response = await apiAWS.putFileAWS(url, file);
      } catch (error) {
        console.error('Erro ao enviar arquivo para AWS');
      }
    },
    async updateFilePathBanner1(event){
      const file = event.target.files[0];
      this.bloco1_bannerPreview = URL.createObjectURL(file)

      try {
        const response =  await apiAWS.getSignatureImage(this.token, file.name);
        this.putFileAws(response.url, file)
        this.bloco1_banner = response.token;
        console.debug(response);
      } catch (error) {
        console.error('Erro na geração do TOKEN AWS')
      }
    },
    async updateFilePathBanner3(event){
      const file = event.target.files[0];
      this.bloco3_bannerPreview = URL.createObjectURL(file)

      try {
        const response =  await apiAWS.getSignatureImage(this.token, file.name);
        this.putFileAws(response.url, file)
        this.bloco3_banner = response.token;
        console.debug(response);
      } catch (error) {
        console.error('Erro na geração do TOKEN AWS')
      }
    },
    async updateFilePathDepoimento1(event){
      const file = event.target.files[0];
      this.depoimentos[0].preview = URL.createObjectURL(file)

      try {
        const response =  await apiAWS.getSignatureImage(this.token, file.name);
        this.putFileAws(response.url, file)
        this.depoimentos[0].imagem = response.token;
        console.debug(response);
      } catch (error) {
        console.error('Erro na geração do TOKEN AWS')
      }
    },
    async updateFilePathDepoimento2(event){
      const file = event.target.files[0];
      this.depoimentos[1].preview = URL.createObjectURL(file)

      try {
        const response =  await apiAWS.getSignatureImage(this.token, file.name);
        this.putFileAws(response.url, file)
        this.depoimentos[1].imagem = response.token;
        console.debug(response);
      } catch (error) {
        console.error('Erro na geração do TOKEN AWS')
      }
    },
    async save() {
      const content_1 = {
        "type": 1,
        "title": this.bloco1_title,
        "description": this.bloco1_description,
        "pathImage": this.bloco1_banner,
        "url": this.bloco1_link,
        "order": 1,
        "status": this.bloco1_active == true ? 1 : 0,
      }

      const content_2 = {
        "type": 2,
        "title": this.bloco2_title,
        "description": '',
        "pathImage": '',
        "url": '',
        "order": 2,
        "status": this.bloco2_active == true ? 1 : 0,
      }

      const content_3 = {
        "type": 3,
        "title": this.bloco3_title,
        "description": this.bloco3_description,
        "pathImage": this.bloco3_banner,
        "url": this.bloco3_link,
        "order": 3,
        "status": this.bloco3_active == true ? 1 : 0,
      }

      const content_4 = {
        "type": 2,
        "title": this.titleDepoimentos,
        "description": '',
        "pathImage": '',
        "url": '',
        "order": 4,
        "status": this.bloco4_active == true ? 1 : 0,
      }
      const putData = [];
      const postData = [];

      if (this.listContents?.length > 0 && this.listContents[0]?.id) {
        content_1.id = this.listContents[0].id;
        putData.push(content_1);
      } else {
        postData.push(content_1);
      }

      if (this.listContents?.length > 0 && this.listContents[1]?.id) {
        content_2.id = this.listContents[1].id;
        putData.push(content_2);
      } else {
        postData.push(content_2);
      }

      if (this.listContents?.length > 0 && this.listContents[2]?.id) {
        content_3.id = this.listContents[2].id;
        putData.push(content_3);
      } else {
        postData.push(content_3);
      }

      if (this.listContents?.length > 0 && this.listContents[3]?.id) {
        content_4.id = this.listContents[3].id;
        putData.push(content_4);
      } else {
        postData.push(content_4);
      }

      try {
        if (putData.length > 0) {
          await apiSiteContent.putContentData(this.token, putData);
        }
        if (postData.length > 0) {
          await apiSiteContent.postContentData(this.token, postData);
        }
        this.clearCache();
        this.getContetData();
      } catch (error) {
        console.error(error);
      }
    },
    async clearCache(){
      try {
        const response = await websiteApi.clearCache(this.token);
        this.messageStatusCache = true;
      } catch (error) {
        console.error('Erro ao limpar cache', error);
      }
    },
    async saveDepoimentos() {
      const putData = [];
      const postData = [];

      this.depoimentos.forEach((depoimento, index) => {
        const item = {
          name: depoimento.name,
          description: depoimento.description,
          photo: depoimento.photo,
        };

        if (depoimento.id) {
          item.id = depoimento.id;
          putData.push(item);
        } else {
          postData.push(item);
        }
      });

      try {
        if (putData.length > 0) {
          await apiTestimony.putTestomonytData(this.token, putData);
        }
        if (postData.length > 0) {
          await apiTestimony.postTestomonytData(this.token, postData);
        }
        this.getDataTestimony();
      } catch (error) {
        console.error('Erro ao salvar depoimentos:', error);
      }
    },
    async getContetData(){
      try {
        const response = await apiSiteContent.getContentData(this.token);
        this.listContents = response;
        this.bloco1_active =  response[0].status == 1 ? true : false,
        this.bloco2_active =  response[1].status == 1 ? true : false,
        this.bloco3_active =  response[2].status == 1 ? true : false,
        this.bloco4_active =  response[3].status == 1 ? true : false,

        this.bloco1_banner = response[0].pathImage;
        this.bloco1_bannerPreview = response[0].pathImageFinal;
        this.bloco1_title = response[0].title;
        this.bloco1_link = response[0].url;
        this.bloco1_description = response[0].description;

        this.bloco2_title = response[1].title;

        this.bloco3_banner =  response[2].pathImage;
        this.bloco3_bannerPreview = response[2].pathImageFinal;

        this.bloco3_title = response[2].title;
        this.bloco3_link = response[2].url;
        this.bloco3_description = response[2].description;

        this.bloco4_title = response[3].title;


      } catch (error) {
        console.error(error);
      }
    },
    async editSocialMedia() {
      const socialData = [];
      const newItems = [];

      this.linkSocial.forEach((link, index) => {

        if(link!==''){
          const id = this.data[index] ? this.data[index].id : null;
          const status = this.activeSocial[index] ? 1 : 0;
          const url = link ? link : null;
          const type = index;
          const name = this.names[index];

          if (id) {
            // Itens existentes para atualização
            socialData.push({ id, name, type, url, status });
          } else if (this.activeSocial[index] == 1) {
            // Novos itens para criação
            newItems.push({ name, type, url, status });
          }
      }
      });
      try {
        if (socialData.length > 0) {
          // Atualiza os itens existentes
          await apiSiteSocialMidia.putSocialMidiastData(this.token, socialData);
        }

        if (newItems.length > 0) {
          // Cria novos itens
          await apiSiteSocialMidia.postSocialMidiastData(this.token, newItems);
        }

        // Recarrega os dados após a atualização/criação
        this.getDataSocialMedia();
      } catch (error) {
        console.error(error);
      }
},
    async getDataSocialMedia(){

      try {
        const response = await apiSiteSocialMidia.getSocialMidiastData(this.token);
        this.data = response;
        this.bloco5_active = response.length > 0 ? true : false;
        this.data.forEach((item) => {
          this.activeSocial[item.type] = item.status === 1;
          this.linkSocial[item.type] = item.url;
        });
      } catch (error) {
        console.error(error);
      }
    },
    async getDataTestimony(){
      try {
        const response = await apiTestimony.getTestomonytData(this.token);
        console.debug(response.data)
        if(response.data.length > 0){
          this.depoimentos = response.data;
        } else {
          // this.depoimentos = [];
        }
      } catch (error) {
        console.error(error);
      }
    }
  },
  components: {
  },
  created() {
    this.getContetData();
    this.getDataSocialMedia();
    this.getDataTestimony();
  },
 
};
</script>
<style scoped>
img {
  border-radius: 5px;
}
.site_content {
  display: flex;
  justify-content: space-around;
}
.content-text {
  border: 1px solid #d7d7d7;
  border-radius: 0px 0px 10px 10px;
}
.form-group .form-input textarea {
  width: 100%;
  min-height: 7em;
  border-color: #dee2e6;
  border: 1px solid #dee2e6;
  border-radius: 5px;
}
.form-group {
  text-align: left;
  margin: 0.5em 1em;
}
.form-group .form-input textarea:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
input.correct.form-control {
  font-weight: 700;
}
.answer_quiz {
  margin: 5px 0px;
}

.row.site {
  display: block;
  width: 49%;
  padding: 10px;
}
.button-order {
  display: flex;
  width: auto;
}
.title.site {
    display: flex;
    gap: 10px;
    background-color: #F8F9FB;
    color: #262735;
    padding: 10px;
    align-items: center;
    border-radius: 10px 10px 0px 0px;
    justify-content: space-between;
}
.title.site.toggle {
  cursor: pointer;
  background-color: #e9ecef;
}
.title.site.toggle:hover {
  background-color: #dee2e6;
}
.toggle-icon {
  font-size: 16px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
}
.toggle-icon i {
  color: #666;
}
.title.site h4 {
  margin: 0;
  font-weight: 500;
  font-size: 17px;
  display: flex;
  gap: 10px;
}
.form-check-input:checked {
  background-color: #2b9ea4;
  border-color: #2b9ea4;
}
.banner-content {
  width: 100%;
  height: auto !important;
  border-radius: 0px;
}
.banner-content img {
  width: 100%;
}
.blur {
  filter: blur(10px);
}
section.config-form.primary.col-12 {
  transition: all 500ms ease-out;
  margin-bottom: 1rem;
}
.banner-text {
  position: absolute;
  top: 50px;
  left: 8px;
  color: black;
  width: 50%;
}

.banner-content {
  position: relative;
}
.site.demo section.config-form.primary.col-12 {
  margin: 10px 0px;
  width: 100%;
  padding: 0px;
}
.site.demo section.config-form.primary.col-12:nth-child(1) {
  margin: 0px 0px 10px 0px;
  width: 100%;
  padding: 0px;
}
section.config-form.primary.col-12.itens-courses {
  display: flex;
  gap: 7px;
  flex-wrap: wrap;
  justify-content: center;
}
.box_2 {
  width: 24%;
}
section.config-form.primary.col-12.itens-courses .banner-text {

  color: black;
  width: 50%;
}

section.config-form.primary.col-12.itens-courses .banner-text p  {
  font-size: 12px;
}
section.config-form.primary.col-12.itens-courses > p {
  display: block;
  width: 100%;
}
.site_content textarea {
  padding: 10px;
}
@media (max-width: 500px) {
  .site_content {
    display: block;
  }
  .row.site {
    display: block;
    width: auto;
  }
}
.form-check-input {
  --bs-form-check-bg: var(--bs-body-bg);
  width: 1em;
  height: 25px!important;
}
.content-text .form-group {
  padding: 15px;
}
.row.site:nth-child(1) {
  display: block;
  padding: 10px;
  box-shadow: 7px 1px 10px 1px #dadada;
}
.form-group.socialMedia {
  width: 26%;
  display: flex;
  flex-direction: row-reverse;
  align-items: anchor-center;
  gap: 20px;
  justify-content: flex-end;
}
.form-group.full {
  width: 70%;
}
.col-form-inner.col-12.col-md-12.col-lg-12.social {
  display: flex
;
}
.depoimentos-slider {
  display: flex
;
}
.depoimento-slide {
  width: 50%;
}
p.depoimento-descricao {
  width: 100%;
  overflow: hidden;
  word-wrap: break-word;
  word-break: break-word;
  font-size: 12px;
}
.rede-social svg {
  color: #250891;
}
.redes-sociais {
  display: flex;
  justify-content: end;
}
.row.site.demo section.config-form.primary.col-12 {
  margin: 20px 0px !important;
}
.banner.depoimentos h2.section-title {
  font-size: 20px;
  margin: 20px;
}
h3.depoimento-nome {
  font-size: 17px;
  margin: 20px 0px;
}
.depoimento-image img {
  width: 100px;
}
.depoimento-content {
  display: flex
;
  gap: 10px;
}
.depoimentos-slider[data-v-31710641] {
  display: flex
;
  gap: 20px;
}
.depoimento-text {
  text-align: left;
}
/* DARK */
.dashboard.dark .global-site-weeduc.adminWeeducSite .row.site {
  box-shadow: none !important;
}
.dashboard.dark .global-site-weeduc.adminWeeducSite .title.site.toggle {
  background-color: #575757;
}
.dashboard.dark .global-site-weeduc.adminWeeducSite .title.site h4,
.dashboard.dark .global-site-weeduc.adminWeeducSite .file-wrapper.primary span,
.dashboard.dark .global-site-weeduc.adminWeeducSite section.config-form.primary.col-12.itens-courses > p,
.dashboard.dark .global-site-weeduc.adminWeeducSite h3.depoimento-nome,
.dashboard.dark .global-site-weeduc.adminWeeducSite p.depoimento-descricao {
  color: white;
}
.dashboard.dark .global-site-weeduc.adminWeeducSite input.form-control {
  background-color: #444;
  border: 1px solid #444;
}
.dashboard.dark .global-site-weeduc.adminWeeducSite textarea {
  background-color: #444 !important;
  border: 1px solid #444 !important;
  color: white !important;
}
.dashboard.dark .global-site-weeduc.adminWeeducSite .content-text {
  border: 1px solid #444;
  background-color: #575757;
}
.row.site.demo.light .box_1 .banner-text h2 {
  color: white;
  font-size: 25px;
  margin-bottom: 30px;
}
.row.site.demo.light .box_1 .banner-text p {
  color: white;
  font-size: 15px;
}
.row.site.demo.light {
  background-color: white;
}
.row.site.demo.dark {
  background-color: rgb(0, 0, 0)57;
}
.row.site.demo.light .box_5 {
  background-color: #eef0fc;
  padding: 10px;
}
.row.site.demo.dark .box_5 {
  background-color: #000000;
  padding: 10px;
}
.row.site.demo:nth-child(2) section.config-form.primary.col-12 {
  margin: 56px 0px !important;
}
.row.site.demo.light .banner-text h2 {
  font-size: 25px;
  margin-bottom: 30px;
}
.row.site.demo.dark .banner-text h2 {
  font-size: 25px;
  margin-bottom: 30px;
  color: white;
}
.row.site.demo.light .banner-text p {
  font-size: 15px;
}
.row.site.demo.dark .banner-text p {
  font-size: 15px;
  color: white;
}
</style>


