<template>
  <div class="container weflix-admin-editCupon">
      <div class="titleContainer">
          <h3>Editrar Cupom</h3>
          <small>Edite as informações do cupom</small>
      </div>
      <div class="contetContainerNewPlan" v-if="hasReady">
          <div class="col-form-inner col-6 col-md-6 col-lg-6 ">
   
            <div class="col-form-inner col-12 col-md-12 col-lg-12">
                <div class="form-group">
                  <div class="form-label">
                    <label>Código do cupom<v-tooltip text="Campo obrigatório" location="top">
                      <template v-slot:activator="{ props }">
                        <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                      </template>
                    </v-tooltip></label>
                  </div>
                  <div class="form-input">
                    <input
                      type="text" 
                      required
                      class="form-control"
                      v-model="code"
                      placeholder="Off10"
                    />
                    <!-- <small class="alertText">Campo obrigatório e deve ser preenchido corretamente.</small> -->
                    <small>Nome comercial de sua coleção, uso externo.</small>
                  </div>
                </div>
          </div>
          

          <div class="col-form-inner col-12 col-md-12 col-lg-12">
              <div class="form-group">
                <div class="form-label">
                  <label>Desconto do cupom %<v-tooltip text="Campo obrigatório" location="top">
                    <template v-slot:activator="{ props }">
                      <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                    </template>
                  </v-tooltip></label>
                </div>
                <div class="form-input">
                  <input
                    type="text" 
                    required
                    class="form-control"
                    v-model="discount"
                    placeholder="10"
                  />
                  <!-- <small class="alertText">Campo obrigatório e deve ser preenchido corretamente.</small> -->
                  <small>Nome comercial de sua coleção, uso externo.</small>
                </div>
              </div>
        </div>
       
          </div>
        
          <div class="col-form-inner col-6 col-md-6 col-lg-6">
              <!-- <div class="col-form-inner col-12 col-md-12 col-lg-12">
                  <div class="form-group">
                    <div class="form-label">
                      <label>Plano que receberá o desconto<v-tooltip text="Campo obrigatório" location="top">
                        <template v-slot:activator="{ props }">
                          <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                        </template>
                      </v-tooltip></label>
                    </div>
                    <div class="form-input">
                      <select
                        required
                        class="form-control"
                        v-model="selectedPlan"
                      >
                        <option value="" disabled selected>Planos</option>
                        <option :value="plan.id" v-for="plan in plans" :key="plan.id">{{ plan.name }}</option>
                      </select>
                      <small>Escolha a frequência de renovação do plano.</small>
                    </div>
                  </div>
            </div> -->
            <div class="col-form-inner col-12 col-md-12 col-lg-12" style="    margin-bottom: 20px;">
              <div class="form-group">
                <div class="form-label">
                  <label>Status<v-tooltip text="Campo obrigatório" location="top">
                    <template v-slot:activator="{ props }">
                      <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                    </template>
                  </v-tooltip></label>
                </div>
                <div class="form-input web-mobile-form">
                  <input data-v-508f1204="" class="form-check-input" type="checkbox" role="switch" v-model="status">
                  <small>Ative para permitir que o cupom seja utilizado.</small>
                </div>
              </div>
        </div>
            <div class="col-form-inner col-12 col-md-12 col-lg-12">
              <div class="form-group">
                <div class="form-label">
                  <label>Data de Expiração<v-tooltip text="Campo obrigatório" location="top">
                    <template v-slot:activator="{ props }">
                      <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                    </template>
                  </v-tooltip></label>
                </div>
                <div class="form-input">
                  <input
                    type="date" 
                    required
                    class="form-control"
                    v-model="expired"
                  />
                </div>
              </div>
        </div>

            
          </div>
          <hr/>
          <div class="col-form-inner col-12 col-md-12 col-lg-12">
              
          </div>
          
      </div>
      <div class="actions">
          <button class="btn" @click="(()=>this.$router.push('/admin/pagamentos'))">Voltar</button>
          <button class="btn" @click="editCupom()">Salvar</button>
      </div>
    </div>
    <!-- PopupConfirm -->
  <div class="pa-4 text-center">
      <v-dialog
      v-model="deleteItem"
      max-width="320"
      persistent
      >
      <v-card>
          <v-card-title class="headline">Atenção</v-card-title>
  
          <v-card-text>
          Deseja realmente excluir o vídeo {{ nameItem }}?
          </v-card-text>
  
          <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="cancel" text @click="closeDialog">Não</v-btn>
          <v-btn class="accept" text @click="closeDialog">Sim</v-btn>
          </v-card-actions>
      </v-card>
      </v-dialog>
  </div>
  <Loader :hasReady="hasReady" />
</template>

<script>
import Cupom from '@/utils/Weflix/cupom';
import Plan from '@/utils/Weflix/plan';
import { mapState } from 'vuex';
import Loader from '@/components/Weflix/Loader';

export default {
  data(){
      return{
          deleteItem: false,
          code: '',
          frequenciaRenovacao: '',
          plans: [],
          selectedPlan: '',
          discount: '',
          expired: '',
          hasReady: false,
          id: this.$route.params.slug,
          status: false
      }
  },
  computed: {
      ...mapState(['token'])
  },
  components: {
    Loader
  },
  methods: {
      deleteVideo(id, name){
          this.deleteItem = true;
          this.nameItem = name;
      },
      closeDialog(){
          this.deleteItem = false;
      },
      formatDateToISO(date) {
        const d = new Date(date);
        return d.toISOString();
      },
      formatDateToISO(date) {
        const d = new Date(date);
        return d.toISOString();
      },
      formatDate(isoDate) {
          const date = new Date(isoDate);
          const year = date.getUTCFullYear();
          const month = String(date.getUTCMonth() + 1).padStart(2, '0');
          const day = String(date.getUTCDate()).padStart(2, '0');
          
          return `${year}-${month}-${day}`;
      },
      async editCupom(){
        this.hasReady = false;
        const payload = {
          "title": this.code,
          "code": this.code,
          "defaul": 0,
          "status": this.status == true ? 1 : 0,
          "expired": this.formatDateToISO(this.expired),
          "discount": this.discount,
          "planId": this.selectedPlan
        }
          try {
              const response = await Cupom.editCupom(this.token, this.id, payload);
              this.hasReady = true;
          } catch (error) {
              console.error(error);
          }
      },
      async getPlans(){
          try {
              const response = await Plan.getPlans(this.token);
              this.plans = response;
          } catch (error) {
              console.error(error);
          }
      },
      async getCupomById(id){
        const params = this.$route.params.slug;
        try {
          const response = await Cupom.getCupomById(this.token, params);
          this.code =  response.code;
          this.discount =  response.discount;
          this.expired = this.formatDate(response.expired);
          this.status = response.status == 1 ? true : false;
          this.hasReady = true;
        } catch (error) {
          console.error(error);
        }
      }
  },  
  created () {
      this.getPlans();
      this.getCupomById();
  }
}
</script>

<style scoped>
@import url(./global.css);
.container {
  max-width: 100%;
}
.titleContainer {
  display: block;
  text-align: left;
  padding: 0px 10px;
  border-bottom: 1px solid #e8e8e8;
  margin: 10px;
  padding-bottom: 10px;
  
}
.container {
  background-color: white;
  padding: 10px 0px;
  padding-bottom: 50px;
}
.form-check-input:checked {
  background-color: #1AC7D0;
  border: 1px solid #1AC7D0;
}
.form-check-input {
  --bs-form-check-bg: var(--bs-body-bg);
  width: 1em;
  height: 1.3em!important;
  margin-top: 0.25em;
  vertical-align: top;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  -webkit-print-color-adjust: exact;
}
  
.form-check-input {
  --bs-form-switch-bg: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e);
  width: 2em;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 2em!important;
  transition: background-position 0.15s ease-in-out;
}
.form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e");
}
.contetContainerNewPlan{
  display: flex;
  flex-wrap: wrap;    
}
hr {
  border-color: #b1b1b1;
  height: 1px;
  width: 100%;
  margin: 10px 10px;
}
.creditCard {
  display: flex;
  flex-wrap: wrap;
  padding: 5px 20px;
  align-items: center;
  text-align: left;
  gap: 21px;
}
.boleto {
  display: flex;
  flex-wrap: wrap;
  padding: 5px 20px;
  align-items: center;
  text-align: left;
  gap: 21px;
}
.boleto img {
  width: 22px;
  height: 30px;
}
.infoCreditCard h4 {
  margin: 0;
  font-size: 16px;
  font-weight: 700;
}
.infoCreditCard {
  width: 80%;
}
.paymentMethods {
  padding-bottom: 50px;
}
.infoCreditCard p {
  margin: 0;
  color: gray;
}
@media (max-width: 600px) {
  .weflix-admin-editCupon h3 {
    font-size: 20px;
    margin-bottom: 5px;
  }
  .weflix-admin-editCupon small {
    font-size: 12px;
    color: #666;
  }
  .weflix-admin-editCupon .contetContainerNewPlan {
    padding: 0px 10px;
  }
  .weflix-admin-editCupon .actions{
    padding: 0px 20px;
  }
  .weflix-admin-editCupon .actions .btn {
    width: 100%;
  }
  .weflix-admin-editCupon .form-input.web-mobile-form {
    display: flex;
    gap: 5px;
    align-items: self-end;
  }
}
</style>