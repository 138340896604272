<template>
    <div class="container generalConfig weflix-admin-general">
        <div class="titleContainer">
            <h3>Configurações Gerais</h3>
            <small>Personalize o sistema Weflix.</small>
        </div>
        <div class="contetContainerEditVideo" v-if="hasReady">
            <!-- <div class="col-form-inner col-6 col-md-6 col-lg-6">
                <div class="col-form-inner col-12 col-md-12 col-lg-12">
                    <div class="form-group">
                      <div class="form-label">
                        <label>Antecipação de Recebíveis<v-tooltip text="Campo obrigatório" location="top">
                        </v-tooltip></label>
                      </div>
                      <div class="itemAtionCollections form-check form-switch">
                        <span>Ativado</span>
                        <input
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                        v-model="receivablesAdvance"
                      />
                    </div>
                    <small>Ative para que até 85% dos valores a receber sejam antecipados automaticamente todos os meses</small>
                    </div>
              </div>
            </div> -->
            <!-- <div class="col-form-inner col-6 col-md-6 col-lg-6">
                <div class="col-form-inner col-12 col-md-12 col-lg-12">
                    <div class="form-group">
                      <div class="form-label">
                        <label>Comentários nas mídias<v-tooltip text="Campo obrigatório" location="top">
                        </v-tooltip></label>
                      </div>
                      <div class="itemAtionCollections form-check form-switch">
                        <span>Ativado</span>
                        <input
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                        v-model="mediaComments"
                      />
                    </div>
                    <small>Ative para que seus usuários possam comentar na página de suas mídias.</small>
                    </div>
              </div>
            </div> -->
            <div class="col-form-inner col-6 col-md-6 col-lg-6">
                <div class="col-form-inner col-12 col-md-12 col-lg-12">
                    <div class="form-group">
                      <div class="form-label">
                        <label>Fechamento de carrinho
                            <v-tooltip text="Campo obrigatório" location="top">
                        </v-tooltip></label>
                      </div>
                      <div class="itemAtionCollections form-check form-switch">
                        <span>{{ cartClosure ? 'Ativado' : 'Inativo' }}</span>
                        <input
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                        v-model="cartClosure"
                      />
                    </div>
                    <small>Ative para que seus usuários não possam realizar compras em seu site.</small>
                    </div>
              </div>
            </div>
            <!-- <div class="col-form-inner col-6 col-md-6 col-lg-6">
                <div class="col-form-inner col-12 col-md-12 col-lg-12">
                    <div class="form-group">
                      <div class="form-label">
                        <label>Cadastro/Login com Google
                            <v-tooltip text="Campo obrigatório" location="top">
                        </v-tooltip></label>
                      </div>
                      <div class="itemAtionCollections form-check form-switch">
                        <span>Ativado</span>
                        <input
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                        v-model="googleSignIn"
                      />
                    </div>
                    <small>Ative para que seus usuários possam se cadastrar/logar com Google.</small>
                    </div>
              </div>
            </div> -->
            <div class="col-form-inner col-6 col-md-6 col-lg-6">
                <div class="col-form-inner col-12 col-md-12 col-lg-12">
                    <div class="form-group">
                      <div class="form-label">
                        <label>Exibir o valor parcelado no checkout
                            <v-tooltip text="Campo obrigatório" location="top">
                        </v-tooltip></label>
                      </div>
                      <div class="itemAtionCollections form-check form-switch">
                        <span>{{ showInstallmentValue ? 'Ativado' : 'Inativo' }}</span>
                        <input
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                        v-model="showInstallmentValue"
                      />
                    </div>
                    <small>Ative para que o valor exibido no checkout seja referente à menor parcela do plano escolhido</small>
                    </div>
              </div>
            </div>
        </div>
        <div class="actions configGeneralWeflix">
            <!-- <button class="btn" @click="(()=>this.$router.push('/admin/colecao/1'))">Cancelar</button> -->
            <button class="btn" @click="updateCompany">Salvar</button>
        </div>
      </div>
    <Loader :hasReady="hasReady" />
</template>

<script>
import apiCompany from '@/utils/Weflix/company';
import { mapState } from 'vuex';
import Loader from '@/components/Weflix/Loader';

export default {
    data(){
        return{
            company: [],
            receivablesAdvance: false,
            mediaComments: false,
            cartClosure: false,
            googleSignIn: false,
            showInstallmentValue: false,
            id: '',
            hasReady: false,
        }
    },
    components: {
        Loader
    },
    computed: {
        ...mapState(['token'])
    },
    methods: {
        async getCompany(){
            try {
                const response = await apiCompany.getCompany(this.token);
                this.company = response;
                this.receivablesAdvance = response.anticipation === 1 ? true : false;
                this.mediaComments = response.videoComments === 1 ? true : false;
                this.cartClosure = response.cartStatus === 1 ? true : false;
                this.showInstallmentValue = response.intallmentCheckout === 1 ? true : false;
                this.id = response.id;
                this.hasReady = true;
            } catch (error) {
                console.error(error);
            }
        },
        async updateCompany(){
            this.hasReady = false;
            const payload = {
                "anticipation": this.receivablesAdvance ? 1 : 0,
                "videoComments": this.mediaComments ? 1 : 0,
                "cartStatus": this.cartClosure ? 1 : 0,
                "intallmentCheckout": this.showInstallmentValue ? 1 : 0,
                "name": this.company.name,
            }
            try {
                const response = await apiCompany.editCompany(this.token, payload);
                this.hasReady = true;
            } catch (error) {
                console.error(error);
            }
        }
    }, 
    created(){
        this.getCompany();
    }
}
</script>

<style scoped>
 .container {
    max-width: 100%;
 }
.titleContainer {
    display: block;
    text-align: left;
    padding: 0px 10px;
    border-bottom: 1px solid #e8e8e8;
    margin: 10px;
    
}
.container {
    background-color: white;
    padding: 10px 0px;
    padding-bottom: 50px;
}
.CollectionVideos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    padding: 10px;
}
.itemCollectionVideo {
    max-width: 30%;
    height: 248px;
    background-size: cover;
    min-width: 300px;
    width: 27%;
    cursor: pointer;
    position: relative;
    text-align: left;
    padding: 10px;
    border-radius: 5px;

}
.thumb-img path {
    fill: #ffffff!important;
    stroke: #aaaaaa!important;
    stroke-width: 1px!important;
}
img, svg {
    vertical-align: middle;
    width: 35px;
}
.UploadVideoContainer {
    /* max-width: 800px; */
    /* margin: 50px auto; */
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 20px;
    margin: 30px 15px;
}
.col-form-inner.col-12.col-md-12.col-lg-12.input-video {
    max-width: 800px;
    margin: 10px auto;
}
.uploadVideoIcon {
    vertical-align: middle;
    width: 46px;
    padding: 8px;
    border: 1px solid #e4e4e4;
    border-radius: 10px;
}
.infoCollectionVideos {
    text-align: left;
    padding: 0px 20px;
}
svg.svg-inline--fa {
    color: silver;
}
.infoCollectionVideos p {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 14px;
}
button.editCollections {
    position: absolute;
    top: 25px;
    right: 25px;
}
button.editCollections svg {
    transition: all 500ms ease-in-out;
}
button.editCollections:hover svg {
    color: #250891;
}
button.hoverWhite svg{
    transition: all 500ms ease-in-out;
    font-size: 15px;
}
button.hoverWhite:hover svg{
    color: white;
}
.itemCollectionVideo p {
    color: #c8c8c8;
    margin: 0;
}
</style>
<style>
.v-card.v-theme--light.v-card--density-default.v-card--variant-elevated {
    padding: 35px 0px;
    /* text-align: left; */
}
.v-card-title.headline {
    text-align: left !important;
    color: red;
    padding: 0 40px;
}
button.v-btn.v-btn--slim.v-theme--light.text-green.darken-1.v-btn--density-default.v-btn--size-default.v-btn--variant-text {
    width: 150px;
    background-color: red;
    /* color: white; */
}
.v-card-actions button {
    width: 50%;
    background-color: gray;
    padding: 0 16px;
}
.v-overlay__content {
    width: 400px !important;
    max-width: 400px !important;
}
.v-card-actions {
    align-items: center;
    display: flex;
    flex: none;
    min-height: 52px;
    padding: 0 40px!important;
}
.cancel {
    background-color: white !important;
    border: 1px solid gray;
}
.accept {
    background-color: red !important;
    border: 1px solid red;
    color: white!important;
}
.contetContainerEditVideo {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}
.col-form-inner.col-12.col-md-7.col-lg-7.freeVideo .v-input__details {
    display: none;
}
.actions > button.btn {
    background-color: #21212100 !important;
    color: #250891 !important;
    border: 1px solid #250891 !important;
    font-size: 18px !important;
    font-weight: 100 !important;
    width: 146px;
    text-align: center;
    transition: all 500ms ease-in-out;

}
.actions {
    display: flex;
    justify-content: end;
    gap: 10px;
    padding: 0px 15px;
}
.actions > button.btn:hover {
    color: white !important;
    background-color: #250891 !important;
}
.exludeVideo {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
}
.exludeVideo svg {
    color: #250891 !important;
}
input.form-check-input {
    width: 3em !important;
}
.form-check-input:checked {
    background-color: #250891!important;
    border-color: #250891!important;
}
.col-form-inner.col-12.col-md-7.col-lg-7.freeVideo {
    display: flex;
    flex-wrap: wrap;
}
.itemAtionCollections.form-check.form-switch {
    padding: 16px 40px;
    position: initial!important;
    padding-left: 0 !important;
}
@media (max-width: 600px) {
    .itemAtionCollections.form-check.form-switch {
        padding: 10px 0px!important;
        position: initial!important;
    }
    .actions.configGeneralWeflix {
        position: absolute !important;
        bottom: 50px;
    }
    .actions.configGeneralWeflix > button.btn {
        width: 72vw;
    }
    .weflix-admin-general h3 {
        font-size: 20px;
        margin-bottom: 5px;
    }
    .weflix-admin-general small {
        font-size: 12px;
        color: #666;
    }
    .weflix-admin-general .titleContainer {
        padding: 0px 10px 10px 10px;
        margin: 0;
    }
    .weflix-admin-general .actions.configGeneralWeflix {
        padding: 0px 10px;
    } 
}
img.coverVideo {
    width: 300px;
    height: auto;
    margin: 0 auto;
    filter: brightness(0.5);
}
.form-group.imageCoverPreview {
    text-align: center;
    position: relative;
}
.info {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}
.info svg {
    font-size: 50px !important;
    color: white !important;
}
.form-group .form-label label {
    font-size: 17px;
}
</style>