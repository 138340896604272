import api from './api';

export default {
    async getDomain( accessToken) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get('/manager/domain', {
                method: 'GET',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados do dominio:', error);
            throw error;
        }
    },
    
    async editDomain(accessToken, payload){
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.put('/manager/domain', payload, {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao editar dominios:', error);
            throw error;
        }
    },

    async getCupomById(accessToken, id){
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/cupom/${id}`, {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados da criação do curso:', error);
            throw error;
        }
    },

    async editCupom(accessToken, id, payload){
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.put(`/cupom/${id}`, payload ,  {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter editar o curso:', error);
            throw error;
        }
    }
}

