import api from './api';

export default {
    async editQuestionAnswer(id, payload, accessToken) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.put(`/question/token/${id}`, payload,  {
                method: 'PUT',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao criar questões:', error);
                throw error;
        }
    },
    async createQuestionAnswer( payload, accessToken) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.post(`/question`, payload,  {
                method: 'POST',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao criar questões:', error);
            throw error;
        }
    },
    async getAnswerByQuiz(id, accessToken) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/question/quiz/${id}`, {
                method: 'GET',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados das respostas:', error);
            throw error;
        }
    },
    async getAnswerByQuestion(id, accessToken) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/answer/question/${id}?linesPerPage=100`, {
                method: 'GET',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados das respostas:', error);
            throw error;
        }
    },
    async getQuestionAndAnswer(accessToken){
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            };
            const response = await api.get('/question?' ,
            {
                method: 'GET',
                headers
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados das questões:', error);
            throw error;
        }
    }
}

