<template>
    <div class="success-page">
        <div class="container">
            <div class="success-content" ref="content">
                <div class="logo">
                    <!-- <img :src="finalWebsite.logoFinal"/> -->
                </div>

                <div class="success-icon">
                    <fa icon="fa-circle-check" class="check-icon"/>
                </div>
                
                <div class="success-message">
                    <h1>Pedido realizado com sucesso!</h1>
                    <p class="info-email" v-if="dataPaymentWeflix?.transaction?.transaction_type === 'pix'">
                        Realize o pagamento via PIX para liberar seu acesso ao conteúdo.
                    </p>
                    <p class="info-email" v-else>
                        Seu pagamento foi aprovado! O acesso ao conteúdo já está liberado.
                    </p>
                </div>

                <div class="order-details" v-if="dataPaymentWeflix">
                    <div class="order-number">
                        <span>Pedido: #{{ dataPaymentWeflix.idOrder }}</span>
                    </div>
                    <div class="order-status">
                        <span :class="['status', dataPaymentWeflix.transaction && dataPaymentWeflix.transaction.transaction_type === 'pix' ? 'pending' : 'approved']">
                            {{ dataPaymentWeflix.transaction && dataPaymentWeflix.transaction.transaction_type === 'pix' ? 'Aguardando Pagamento' : 'Pagamento Aprovado' }}
                        </span>
                    </div>
                </div>

                <!-- Seção PIX -->
                <div class="pix-details" v-if="dataPaymentWeflix?.transaction">
                    <h3>Pagamento via PIX</h3>
                    <div class="pix-container">
                        <div class="qr-code">
                            <img :src="dataPaymentWeflix.transaction.qr_code_url" alt="QR Code PIX"/>
                        </div>
                        <div class="pix-instructions">
                            <h4>Como pagar com PIX:</h4>
                            <ol>
                                <li>Abra o app do seu banco</li>
                                <li>Escolha pagar via PIX</li>
                                <li>Escaneie o QR Code ao lado</li>
                                <li>Confirme as informações e pague</li>
                            </ol>
                            <p class="pix-amount">Valor a pagar: <strong>R$ {{ collectionCheckout?.collection?.price.toFixed(2) }}</strong></p>
                        </div>
                    </div>
                    <div class="pix-copy">
                        <p>Ou copie o código PIX abaixo:</p>
                        <div class="copy-input">
                            <input type="text" readonly :value="dataPaymentWeflix.transaction.qr_code"/>
                            <button @click="copyPixKey" class="btn-copy">
                                <fa icon="fa-copy"/> Copiar
                            </button>
                        </div>
                    </div>
                </div>

                <!-- Resumo da Compra -->
                <div class="purchase-details" v-if="dataPaymentWeflix?.transaction">
                    <h3>Resumo da Compra</h3>
                    <div class="purchase-info">
                        <div class="purchase-item">
                            <p><strong>Conteúdo:</strong> {{ collectionCheckout?.collection?.name }}</p>
                            <p><strong>Valor:</strong> R$ {{ collectionCheckout?.collection?.price.toFixed(2) }}</p>
                            <p><strong>Validade:</strong> {{ collectionCheckout?.collection?.dayExpired }} dias</p>
                            <p><strong>Forma de Pagamento:</strong> {{ dataPaymentWeflix.transaction.transaction_type === 'pix' ? 'PIX' : 'Cartão de Crédito' }}</p>
                            <p><strong>Data da Compra:</strong> {{ new Date().toLocaleDateString('pt-BR') }}</p>
                        </div>
                    </div>
                </div>
                <div class="purchase-details" v-if="!dataPaymentWeflix?.transaction">
                    <h3>Resumo da Compra</h3>
                    <div class="purchase-info">
                        <div class="purchase-item">
                            <p><strong>Conteúdo:</strong> {{ collectionCheckout?.collection?.name }}</p>
                            <p><strong>Valor:</strong> R$ {{ collectionCheckout?.collection?.price.toFixed(2) }}</p>
                            <p><strong>Validade:</strong> {{ collectionCheckout?.collection?.dayExpired }} dias</p>
                            <p><strong>Forma de Pagamento:</strong> Cartão de Crédito</p>
                            <p><strong>Data da Compra:</strong> {{ new Date().toLocaleDateString('pt-BR') }}</p>
                        </div>
                    </div>
                </div>

                <div class="action-buttons">
                    <button @click="redirect" class="btn-primary">
                        <fa style="color: white;" icon="fa-right-to-bracket" /> Voltar para o site
                    </button>
                    <button @click="exportarPDF" class="btn-export">
                        <fa style="color: white;" icon="fa-file-pdf" /> Exportar Comprovante
                    </button>
                </div>
            </div>
        </div>

        <footer class="footer">
            <div class="footer-content">
                <div class="logo-footer">
                    <img src="../../assets/Weflix/logo_weflix-black.png" alt="Weflix Logo"/>
                </div>
                <p class="copyright">© 2024 Weflix - Todos os direitos reservados</p>
            </div>
        </footer>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    name: 'SuccessPage',
    data() {
        return {
        }
    },
    computed: {
        ...mapState(['finalWebsite','dataPaymentWeflix', 'collectionCheckout'])
    },
    methods: {
        ...mapActions(['logout','storeDataPaymentWeflix','storeCollectionCheckout']),
        redirect() {
            this.storeDataPaymentWeflix({ dataPaymentWeflix: null });
            this.storeCollectionCheckout({ collectionCheckout: null });
            this.$router.push('/');
        },
        copyPixKey() {
            if (this.dataPaymentWeflix?.transaction) {
                navigator.clipboard.writeText(this.dataPaymentWeflix.transaction.qr_code);
                alert('Chave PIX copiada!');
            }
        },
        exportarPDF() {
            const html2pdf = require('html2pdf.js');
            const element = this.$refs.content;
            const opt = {
                margin: [10, 10],
                filename: `pedido-${this.dataPaymentWeflix?.idOrder || 'sem-numero'}.pdf`,
                image: { type: 'jpeg', quality: 1 },
                html2canvas: { scale: 2, useCORS: true },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
            };
            html2pdf().set(opt).from(element).save();
        }
    },
    mounted() {
        const link = document.querySelector("link[rel~='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'icon';
        link.href = this.finalWebsite.faviconFinal;
        document.getElementsByTagName('head')[0].appendChild(link);
    },
    created() {
        if(this.dataPaymentWeflix.length === 0) {
            this.$router.push('/');
        }
    }
}
</script>

<style scoped>
.success-page {
    min-height: 100vh;
    background: linear-gradient(135deg, #f5f7fa 0%, #ffffff 100%);
    display: flex;
    flex-direction: column;
    font-family: 'Poppins', sans-serif;
}

.success-content {
    max-width: 800px;
    margin: 40px auto;
    padding: 40px;
    background: white;
    border-radius: 20px;
    box-shadow: 0 15px 35px rgba(0,0,0,0.1);
    transition: all 0.3s ease;
}

.success-content:hover {
    transform: translateY(-5px);
    box-shadow: 0 20px 40px rgba(0,0,0,0.15);
}

.logo {
    text-align: center;
}

.logo img {
    max-width: 220px;
    margin-bottom: 30px;
    transition: transform 0.3s ease;
}

.logo img:hover {
    transform: scale(1.05);
}

.success-icon {
    margin: 30px 0;
    text-align: center;
}

.success-icon .check-icon {
    font-size: 90px;
    color: #4CAF50;
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.1); }
    100% { transform: scale(1); }
}

.success-message {
    text-align: center;
    margin-bottom: 40px;
}

.success-message h1 {
    color: #2C3E50;
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 20px;
    letter-spacing: -0.5px;
}

.info-email {
    color: #666;
    font-size: 18px;
    line-height: 1.6;
}

.order-details {
    background: #f8f9fa;
    padding: 25px;
    border-radius: 15px;
    margin: 30px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 5px 15px rgba(0,0,0,0.05);
}

.status {
    padding: 10px 20px;
    border-radius: 25px;
    font-size: 14px;
    font-weight: 600;
    color: white;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.status.pending {
    background: linear-gradient(45deg, #FFC107, #FF9800);
}

.status.approved {
    background: linear-gradient(45deg, #4CAF50, #45a049);
}

.pix-details {
    background: #f8f9fa;
    padding: 35px;
    border-radius: 15px;
    margin: 30px 0;
    text-align: left;
    box-shadow: 0 5px 15px rgba(0,0,0,0.05);
}

.pix-details h3 {
    color: #2C3E50;
    font-size: 24px;
    margin-bottom: 25px;
    text-align: center;
}

.pix-container {
    display: flex;
    gap: 30px;
    align-items: flex-start;
    margin-bottom: 30px;
}

.qr-code {
    background: white;
    padding: 15px;
    border-radius: 10px;
    width: 220px;
    flex-shrink: 0;
}

.qr-code img {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

.pix-instructions {
    flex-grow: 1;
}

.pix-instructions h4 {
    color: #2C3E50;
    margin-bottom: 15px;
    font-size: 18px;
}

.pix-instructions ol {
    padding-left: 20px;
    margin-bottom: 20px;
}

.pix-instructions li {
    margin-bottom: 10px;
    color: #666;
}

.pix-amount {
    font-size: 18px;
    color: #2C3E50;
    margin-top: 20px;
}

.pix-copy {
    text-align: center;
    margin-top: 20px;
}

.copy-input {
    display: flex;
    gap: 12px;
    justify-content: center;
    margin-top: 15px;
}

.copy-input input {
    padding: 15px;
    border: 2px solid #e0e0e0;
    border-radius: 8px;
    width: 320px;
    font-size: 16px;
    transition: all 0.3s ease;
}

.copy-input input:focus {
    border-color: #2196F3;
    outline: none;
}

.btn-copy {
    background: linear-gradient(45deg, #2196F3, #1976D2);
    color: white;
    border: none;
    padding: 0 25px;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 600;
    transition: all 0.3s ease;
}

.btn-copy:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(33,150,243,0.3);
}

.purchase-details {
    background: white;
    padding: 35px;
    border-radius: 15px;
    margin: 30px 0;
    border: 2px solid #f0f0f0;
    box-shadow: 0 5px 15px rgba(0,0,0,0.05);
}

.purchase-details h3 {
    color: #2C3E50;
    font-size: 24px;
    margin-bottom: 25px;
    text-align: center;
}

.purchase-info {
    display: grid;
    gap: 20px;
}

.purchase-item p {
    margin: 12px 0;
    color: #666;
    font-size: 16px;
    line-height: 1.6;
}

.purchase-item strong {
    color: #2C3E50;
}

.action-buttons {
    display: flex;
    gap: 20px;
    justify-content: center;
    margin-top: 40px;
}

.btn-primary, .btn-export {
    padding: 16px 32px;
    border-radius: 30px;
    border: none;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    transition: all 0.3s ease;
}

.btn-primary {
    background: linear-gradient(45deg, #2196F3, #1976D2);
    color: white;
}

.btn-export {
    background: linear-gradient(45deg, #FF5722, #F4511E);
    color: white;
}

.btn-primary:hover, .btn-export:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 20px rgba(0,0,0,0.2);
}

.footer {
    margin-top: auto;
    padding: 30px;
    background: white;
    text-align: center;
    box-shadow: 0 -5px 15px rgba(0,0,0,0.05);
}

.logo-footer img {
    width: 130px;
    margin-bottom: 15px;
    opacity: 0.8;
    transition: opacity 0.3s ease;
}

.logo-footer img:hover {
    opacity: 1;
}

.copyright {
    color: #95a5a6;
    font-size: 14px;
}

@media (max-width: 768px) {
    .success-content {
        margin: 5px;
        padding: 8px;
    }
    
    .success-message h1 {
        font-size: 28px;
    }
    
    .pix-container {
        flex-direction: column;
        align-items: center;
    }

    .pix-instructions {
        text-align: center;
    }

    .pix-instructions ol {
        text-align: left;
    }
    
    .copy-input {
        flex-direction: column;
    }
    
    .copy-input input {
        width: 100%;
    }
    
    .action-buttons {
        flex-direction: column;
    }
    .pix-details {
        margin: 30px 5px;
    }
    .order-details {
        flex-direction: column;
        gap: 15px;
        text-align: center;
        margin: 30px 5px;
        padding: 20px 10px;
    }
    .qr-code {
        width: 260px;
    }
    .btn-primary, .btn-export {
        width: 100%;
        justify-content: center;
    }
    button.btn-copy {
        padding: 10px !important;
    }
    footer {
        margin-top: 10px !important;
    }
}
.purchase-item {
    text-align: left;
}
</style>