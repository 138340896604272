<template>
    <div class="dashboard">       
        <Sidebar/>
        <div class="page">
            <Toolbar />
            <div class="content dashboard">
                <RouterView></RouterView>
            </div>
        </div>
    </div>
</template>

<script>

import Sidebar from '@/components/Weflix/Sidebar.vue'
import Footer from '@/components/Admin/Footer/footer.vue'
import Toolbar from '@/components/Weflix//Tolbar.vue'

export default {
    data() {
        return {
        }
    },
    components: {
       Sidebar,
       Footer,
       Toolbar
    },
    computed: {
  },

}
</script>

<style>
@import url(./global.css);

.dashboard {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #eef0fc;
    margin: 0 auto;
    position: relative;
}   
.content.dashboard {
    background-color: transparent;
    margin: 0;
    width: 100%;
    padding: 10px 10px;
    height: 100%;
}
</style>