<template>
  <div class="bg-dark-create" :class="[showmodal ? 'open' : '']"></div>
  <div class="form-create-module edit_user portal" :class="[showmodal ? 'open' : '', { dark: this.dark }]">
    <div class="modal" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <Loader :isActive="!hasReady"></Loader>
          <div class="info-user" v-show="!updatePassword">
            <div class="modal-header">
              <h5 class="modal-title">Editar Usuário</h5>
            </div>
            <div class="modal-body info-perfil-image">
              <div class="form-group">
                <div class="form-label">
                  <img class="temporary" src="../../../../assets/user.png" v-show="!userimagePreview && !userimage"/>
                  <img :src="userimagePreview? userimagePreview : userimage" alt="Logo" v-show="userimagePreview || userimage" />
                </div>
                <div class="form-label">
                  <div class="form-label">
                    <label>Imagem de Perfil</label>
                  </div>
                  <div class="form-file">
                    <div class="file-wrapper primary">
                      <span class="thumbnail">
                        <span class="icon">
                          <svg
                            width="46"
                            height="39"
                            viewBox="0 0 46 39"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M45 19.9308L36.7274 12.3475C36.1595 11.827 35.2948 11.8047 34.7009 12.2953L18.824 25.411C18.3196 25.8277 17.6074 25.8812 17.0464 25.5446L11.1009 21.9773C10.5893 21.6703 9.94666 21.6856 9.45024 22.0166L1 27.6501"
                              stroke="#9D9FA3"
                              stroke-width="1.25714"
                            ></path>
                            <rect
                              x="1.00049"
                              y="1.40454"
                              width="43.9461"
                              height="36.1909"
                              rx="2.58507"
                              stroke="#9D9FA3"
                              stroke-width="1.25714"
                            ></rect>
                            <circle
                              cx="14.8948"
                              cy="10.6676"
                              r="3.71629"
                              stroke="#9D9FA3"
                              stroke-width="1.25714"
                            ></circle>
                          </svg>
                        </span>
                        <span class="text">
                          Clique aqui para selecionar a imagem
                        </span>
                      </span>
                      <input class="form-control" type="file"  @change="updateFilePath" accept=".png, .jpg, .jpeg, .webp"/>
                    </div>
                  </div>
                </div>
              
              </div>
            </div>
            <div class="modal-body double edit_user">
              <div class="form-group">
                <div class="form-label">
                  <label>
                    Nome  <v-tooltip text="Campo obrigatório" location="top">
                      <template v-slot:activator="{ props }">
                        <i v-bind="props" class="pi pi-exclamation-circle"></i>
                      </template>
                    </v-tooltip>
                  </label>
                </div>
                <div class="form-input">
                  <input v-model="name" type="text" class="form-control" placeholder="Nome">
                </div>
              </div>

              <div class="form-group">
                <div class="form-label">
                  <label>
                    Eamil  <v-tooltip text="Campo obrigatório" location="top">
                      <template v-slot:activator="{ props }">
                        <i v-bind="props" class="pi pi-exclamation-circle"></i>
                      </template>
                    </v-tooltip>
                  </label>
                </div>
                <div class="form-input">
                  <input v-model="email" type="text" class="form-control" placeholder="E-mail" disabled>
                </div>
              </div>
            </div>
            <div class="modal-body double">
              <div class="form-group">
                <div class="form-label">
                  <label>
                    CPF  <v-tooltip text="Campo obrigatório" location="top">
                      <template v-slot:activator="{ props }">
                        <i v-bind="props" class="pi pi-exclamation-circle"></i>
                      </template>
                    </v-tooltip>
                  </label>
                </div>
                <div class="form-input">
                  <input v-model="cpf" type="text" class="form-control" placeholder="CPF" disabled>
                </div>
              </div>

              <div class="form-group">
                <div class="form-label">
                  <label>
                    Telefone  <v-tooltip text="Campo obrigatório" location="top">
                      <template v-slot:activator="{ props }">
                        <i v-bind="props" class="pi pi-exclamation-circle"></i>
                      </template>
                    </v-tooltip>
                  </label>
                </div>
                <div class="form-input">
                  <input v-model="phone" type="text" class="form-control" placeholder="Telefone">
                </div>
              </div>

            </div>
            <div class="modal-body double">

              <div class="form-group">
                <div class="form-label">
                  <label>Identidade<v-tooltip text="Campo obrigatório" location="top">
                    <template v-slot:activator="{ props }">
                      <i v-bind="props" class="pi pi-exclamation-circle"></i>
                    </template>
                  </v-tooltip></label>
                </div>
                <div class="form-input cep">
                  <input
                    v-model="idNumber"
                    type="text"
                    class="form-control"
                    placeholder="x.xxx-xxx"
                    disabled
                    
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="form-label">
                  <label> CEP <v-tooltip text="Campo obrigatório" location="top">
                    <template v-slot:activator="{ props }">
                      <i v-bind="props" class="pi pi-exclamation-circle"></i>
                    </template>
                  </v-tooltip></label>
                </div>
                <div class="form-input cep">
                  <input
                    v-model="cep"
                    type="text"
                    class="form-control"
                    placeholder="xxxxx-xxx"
                    @change="loadCep()"
                  />
                </div>
              </div>

            </div>
            <div class="modal-body double less">
              <div class="form-group">
                <div class="form-label">
                  <label>
                    Endereço  <v-tooltip text="Campo obrigatório" location="top">
                      <template v-slot:activator="{ props }">
                        <i v-bind="props" class="pi pi-exclamation-circle"></i>
                      </template>
                    </v-tooltip>
                  </label>
                </div>
                <div class="form-input">
                  <input v-model="address" type="text" class="form-control" placeholder="Endereço">
                </div>
              </div>

              <div class="form-group">
                <div class="form-label">
                  <label>
                    Complemento  <v-tooltip text="Campo obrigatório" location="top">
                      <template v-slot:activator="{ props }">
                        <i v-bind="props" class="pi pi-exclamation-circle"></i>
                      </template>
                    </v-tooltip>
                  </label>
                </div>
                <div class="form-input">
                  <input v-model="addressComplement" type="text" class="form-control" placeholder="Casa, Apt...">
                </div>
              </div>

              <div class="form-group">
                <div class="form-label">
                  <label>
                    Número  <v-tooltip text="Campo obrigatório" location="top">
                      <template v-slot:activator="{ props }">
                        <i v-bind="props" class="pi pi-exclamation-circle"></i>
                      </template>
                    </v-tooltip>
                  </label>
                </div>
                <div class="form-input">
                  <input v-model="number" type="text" class="form-control" placeholder="Número">
                </div>
              </div>
            </div>
            <div class="modal-body double">
              <div class="form-group">
                <div class="form-label">
                  <label>
                    Cidade  <v-tooltip text="Campo obrigatório" location="top">
                      <template v-slot:activator="{ props }">
                        <i v-bind="props" class="pi pi-exclamation-circle"></i>
                      </template>
                    </v-tooltip>
                  </label>
                </div>
                <div class="form-input">
                  <input v-model="city" type="text" class="form-control" placeholder="Cidade">
                </div>
              </div>

              <div class="form-group">
                <div class="form-label">
                  <label>
                    Estado  <v-tooltip text="Campo obrigatório" location="top">
                      <template v-slot:activator="{ props }">
                        <i v-bind="props" class="pi pi-exclamation-circle"></i>
                      </template>
                    </v-tooltip>
                  </label>
                </div>
                <select class="form-select" aria-label="Default select example" v-model="county">
                  <option value="state" selected>Selecione o estado</option>
                  <option :value="item" v-for="item, index in states" :key="index">
                    {{item}} 
                  </option>
                </select>
                
              </div>

              <div class="form-group">
                <div class="form-label">
                  <label>
                    Pais  <v-tooltip text="Campo obrigatório" location="top">
                      <template v-slot:activator="{ props }">
                        <i v-bind="props" class="pi pi-exclamation-circle"></i>
                      </template>
                    </v-tooltip>
                  </label>
                </div>
                <select class="form-select" aria-label="Default select example" v-model="country">
                  <option value="br" selected>Brasil</option>
                  <option value="outro">Outro</option>
                </select>
              </div>
            </div>
        </div>
        <div class="info-user-paswword" v-show="updatePassword">
          <div class="modal-header">
            <h5 class="modal-title">Alterar Senha</h5>
          </div>
          <div class="modal-body double">
            <div class="form-group">
              <div class="form-label">
                <label>
                  Senha Atual
                  <v-tooltip text="Campo obrigatório" location="top">
                    <template v-slot:activator="{ props }">
                      <i v-bind="props" class="pi pi-exclamation-circle"></i>
                    </template>
                  </v-tooltip>
                </label>
              </div>
              <div class="form-input">
                <input v-model="oldPassword" type="password" class="form-control" placeholder="Senha Atual">
              </div>
            </div>
          </div>
          <div class="modal-body double">
            <div class="form-group">
              <div class="form-label">
                <label>
                  Nova senha  <v-tooltip text="Campo obrigatório" location="top">
                    <template v-slot:activator="{ props }">
                      <i v-bind="props" class="pi pi-exclamation-circle"></i>
                    </template>
                  </v-tooltip>
                </label>
              </div>
              <div class="form-input">
                <input v-model="password" type="password" class="form-control" placeholder="Nova senha">
              </div>
            </div>
            <div class="form-group">
              <div class="form-label">
                <label>
                  Confirme a nova senha  <v-tooltip text="Campo obrigatório" location="top">
                    <template v-slot:activator="{ props }">
                      <i v-bind="props" class="pi pi-exclamation-circle"></i>
                    </template>
                  </v-tooltip>
                </label>
              </div>
              <div class="form-input">
                <input v-model="rePassword" type="password" class="form-control" placeholder="Confirmar nova senha">
              </div>
            </div>
          </div>
        </div>
          <div class="modal-footer">
            <button type="button" @click="closetab()" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
            <button type="button" @click="(()=>this.updatePassword=!this.updatePassword)" class="btn btn-secondary" data-bs-dismiss="modal">{{updatePassword==true? 'Alterar Dados':'Alterar Senha'}}</button>
            
            <button type="button" @click="editUser()" class="btn btn-primary" v-show="!updatePassword">Salvar Dados</button>
            <button type="button" @click="changePassword()" class="btn btn-primary" v-show="updatePassword">Atualizar Senha</button>
          </div>
        </div>
      </div>
    </div>
    <v-dialog
    v-model="dialog"
    width="auto"
  >
    <v-card
      max-width="400"
      prepend-icon="mdi-update"
      text="Um ou mais campo não foram preenchidos corretamenta, por favore revise-os."
      title="Ops, há algum erro!"
    >
      <template v-slot:actions>
        <v-btn
          class="ms-auto"
          text="Ok"
          @click="dialog = false"
        ></v-btn>
      </template>
    </v-card>
  </v-dialog>
  </div>
  </template>
  <script>
  import { mapState } from "vuex";
  import Loader from "@/components/Loader/loader.vue"
  import api from "@/utils/portal/user.js"
  import apiCep from "@/utils/cep.js" 

  export default {
  data(){
    return{
        showmodal: '',
        name: '',
        email: '',
        cpf: '',
        phone: '',
        idNumber:'',


        country:'',
        county:'',
        cep: '',
        address: '',
        number: '',
        city: '',
        addressComplement:'',
        
        password: '',
        rePassword: '',
        oldPassword: '',
        updatePassword:false,

        
        
        dialog:false,
        hasReady:false,
        states: [
          "Acre",
          "Alagoas",
          "Amapá",
          "Amazonas",
          "Bahia",
          "Ceará",
          "Distrito Federal",
          "Espírito Santo",
          "Goiás",
          "Maranhão",
          "Mato Grosso",
          "Mato Grosso do Sul",
          "Minas Gerais",
          "Pará",
          "Paraíba",
          "Paraná",
          "Pernambuco",
          "Piauí",
          "Rio de Janeiro",
          "Rio Grande do Norte",
          "Rio Grande do Sul",
          "Rondônia",
          "Roraima",
          "Santa Catarina",
          "São Paulo",
          "Sergipe",
          "Tocantins"
        ],
    }
  },
  props: {
    showEditInfo: Boolean,
  },
  computed:{
    ...mapState(['token', 'dark'])
  },
  methods: {
    async loadDataUser(){
        try {
            const response =  await api.getDataUser(this.token);
            this.name = response.name;
            this.email = response.email;
            this.cpf = response.socialNumber;
            this.phone = response.phone;
            this.cep = response.postcode;
            this.type = response.type;
            this.address = response.address;
            this.number = response.addressNumber;
            this.city = response.city;
            this.state = response.couty;
            this.country = response.country;
            this.county = response.county;
            this.hasReady = true;
            this.addressComplement = response.addressComplement;
            
            console.debug(response);
        } catch (error) {
            console.error('Erro ao exibir dados do usuário');
        }
    },
    async editUser(){
        const obj = {
          "name": this.name,
          "email": this.email,
          "password": this.password,
          "phone": this.phone,
          "socialNumber": this.cpf,
          "idNumber": this.idNumber,
          "county": this.county,
          "country": this.country,
          "city": this.city,
          "address": this.address,
          "addressNumber": this.number,
          "addressComplement": this.addressComplement,
          "postcode": this.cep,
          "username": this.email,
          "type": 3,
          "status": 1,
      }
      try {
        const response = await api.EditUser(this.token, obj);
      } catch (error) {
        console.error('Erro ao eidtar o usuário', error)
      }
    },
    async changePassword(){
      if(this.password!=this.rePassword){
        alert('As senhas devem ser iguais');
        return false;
      }
      const obj = {
        "oldPassword": this.oldPassword,
        "newPassword": this.password
      }
      try {
        const response = api.changePassword(this.token, obj);
        this.showmodal = false;
        this.$emit('open-tab', this.showmodal);
      } catch (error) {
        console.error(error)
      }
    },
    closetab() {
      this.alterarValorEmit();
    },
    alterarValorEmit() {
      this.$emit("showModalEditDataUser", false);
    },
    async loadCep(){
      try {
        const response = await apiCep.getDataCep(this.cep);
         this.country = 'br';
         this.city = response.city;
         this.address = response.street;
          this.county = response.state;
      } catch (error) {
        console.error('Erro ao buscar cep', error)
      }
    }
  },
  components:{
    Loader
  },
  created(){
    // this.loadDataUser();
},
  watch: {
    showEditInfo(novoValor) {
      this.showmodal = this.showEditInfo;
      this.loadDataUser(this.token);
    },
    }
  };
  </script>
  <style scoped>
  .modal-footer {
    border: none;
  }
  .edit_user label {
    justify-content: flex-start !important;
    gap: 5px;
  }
  .modal-body.double {
    display: flex;
  }
  .modal-body.double .form-group {
    width: 100%;
  }
  .modal-body.double.less .form-group:first-child {
    width: 300%;
  }
  .form-group.nao_sei_cep {
    align-items: end;
    display: flex;
  }
  .form-input.cep input {
    padding: 4px 10px !important;
  }
  input.form-control[data-v-932bf986]:disabled {
        background-color: #e7e7e7;
    }
    .form-create-module.edit_user.open {
        box-shadow: -1px -8px 13px #e5e5e5;
        height: 100%;
    }
    .modal-body.info-perfil-image .form-group {
      display: flex;
      gap: 20px;
      align-items: center;
    }
    .modal-body.info-perfil-image .form-group  > .form-label:nth-child(1){
      width: 20%;
    }
    .modal-body.info-perfil-image .form-group  > .form-label:nth-child(2){
      width: 80%;
    }
    .modal-body.info-perfil-image .form-group img {
     width: 100px;
     height: auto;
    }
    img.temporary {
      filter: grayscale(1);
    }
    .form-create-module.edit_user.portal.open .modal-content {
      height: 100%;
    }
    .form-create-module.edit_user.portal.open.dark .modal-content {
      background-color: #1f1f1f;
  }
  .form-create-module.edit_user.portal.open.dark .form-input input.form-control {
    background-color: black;
    color: white;
    border-color: gray;
}
.form-create-module.edit_user.portal.open.dark .form-label label{
  color: white;
}
.form-create-module.edit_user.portal.open.dark select.form-select {
  background-color: black;
  color: white;
}
.form-create-module.edit_user.portal.open.dark h5.modal-title{
  color: white;
}
.form-create-module.edit_user.portal.open.dark {
  box-shadow: -1px -8px 13px #000000;
}
  </style>
  