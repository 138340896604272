<template>
    <section class="global-site-weeduc adminWeeducSite">
        <section class="section link-list primary">
            <div class="link-list-content">
                <div class="content-link create-course">
                    <div class="link">
                        <a
                            class="link-content"
                            :class="[isActiveTab(1)? 'active': '']"
                            @click="setActiveTab(1)"
                        >
                            Básico
                        </a>
                    </div>
                    <div class="link">
                        <a
                            class="link-content"
                            :class="[isActiveTab(2)? 'active': '']"
                            @click="setActiveTab(2)"
                        >
                            Layout
                        </a>
                    </div>
                    <div class="link">
                        <a
                            class="link-content"
                            :class="[isActiveTab(3)? 'active': '']"
                            @click="setActiveTab(3)"
                        >
                            Domínio
                        </a>
                    </div>
                    <div class="link">
                        <a
                            class="link-content"
                            :class="[isActiveTab(4)? 'active': '']"
                            @click="setActiveTab(4)"
                        >
                            Integrações
                        </a>
                    </div>
                    <div class="link">
                        <a
                            class="link-content"
                            :class="[isActiveTab(5)? 'active': '']"
                            @click="setActiveTab(5)"
                        >
                            Avançado
                        </a>
                    </div>
                </div>
            </div>
        </section>

        <section
            class="section config-form primary"
            :class="[isActiveTab(1)? 'active': '']"
        >
            <Config />
        </section>
    
        <section
            class="section config-form primary"
            :class="[isActiveTab(2)? 'active': '']"
        >
            <!-- <Layout /> -->
            <Content />
        </section>

        <section
            class="section config-form primary"
            :class="[isActiveTab(3)? 'active': '']"
        >
            <Domain />
        </section>


        <section
            class="section config-form primary"
            :class="[isActiveTab(4)? 'active': '']"
        >
            <Integration />
        </section>

        <section
        class="section config-form primary"
        :class="[isActiveTab(5)? 'active': '']"
    >
        <Advanced  />
    </section>

    </section>
</template>

<script>
    import Config from '@/components/Admin/Site/config'
    import Content from '@/components/Admin/Site/content'
    import Social from '@/components/Admin/Site/social'
    import Testimonials from '@/components/Admin/Site/testimonials'
    import Domain from '@/components/Admin/Site/domain'
    import Layout from '@/components/Admin/Site/layout'
    import Integration from '@/components/Admin/Site/integrations'
    import Advanced from '@/components/Admin/Site/advanced'

    export default {
        data() {
            return {
                activeTab: 1,
                hassaved: false,
                openmodalmodule: false,
            }
        },
        props: {
    
        },
        components: {
            Config,
            Content,
            Social,
            Testimonials,
            Domain,
            Layout,
            Integration,
            Advanced
        },
        methods: {
            updatevalue(novoValor) {
                this.hassaved = novoValor;
            },
    
            setActiveTab(tabNumber) {
                this.activeTab = tabNumber;
            },
            isActiveTab(tabNumber) {
                return this.activeTab === tabNumber;
            },
            openmodal() {
                this.openmodalmodule = !this.openmodalmodule;
            },
            atualizarValorNoFilho(novoValor) {
                this.openmodalmodule = novoValor;
            }
        },
        created() {
    
        }
    }
</script>

<style scoped>
    .link a {
        display: inline-block;
        font-family: Inter;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 28px;
        color: rgb(24, 24, 28);
        background-color: rgb(255, 255, 255);
        text-decoration: none;
        padding: 0.5rem 2rem;
        cursor: pointer;
    }
    @media (max-width: 500px) {
        .redes-sociais-mobile {
            min-width: fit-content !important;
        }
    }
    .content-link.create-course {
        display: flex;
        background-color: white;
        border-radius: 10px;
    }
    section.section.config-form.primary {
        display: none;
    }
    section.section.config-form.primary.active {
        display: block;
    }
    section.section.link-list.primary {
        border-bottom: 1px solid #e4e4e4;
    }
    a.link-content.active {
        position: relative;
        transition: all 500ms linear;
    }
    section.section.link-list.primary a.link-content.active[data-v-01d14931]::after {
        height: 3px;
        width: 95%;
        position: absolute;
        display: block;
        background-color: #160266;
        bottom: 0;
        content: '';
        left: 5%;
        border-radius: 10px;
        transition: all 500ms linear;
    }
    .content.dashboard .global-site-weeduc.adminWeeducSite {
        background: white;
    }
    /* DARK */
    .dashboard.dark .global-site-weeduc.adminWeeducSite .content-link.create-course,
    .dashboard.dark .global-site-weeduc.adminWeeducSite .link-list-content .content-link .link .link-content {
        background-color: #575757;
        border: none;
    }
    .dashboard.dark .global-site-weeduc.adminWeeducSite section.section.link-list.primary a.link-content.active::after {
        background-color: #444;
    }
    .dashboard.dark .global-site-weeduc.adminWeeducSite section.section.link-list.primary {
        border-bottom: 1px solid #444;
    }
</style>