import api from './api';

export default {
    async getDataLesson(accessToken) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get('/lesson', {
                method: 'GET',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados da lesson:', error);
            throw error;
        }
    },

    async deleteLesson(id, accessToken){
        try {
              const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.delete(`/lesson/${id}`, {
                method: 'DELETE',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error(`Erro ao deletar a lesson: ${id}`, error);
            throw error;
        }
    },
    
    async getDataLessonById(id,accessToken) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/lesson/${id}`, {
                method: 'GET',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error(`Erro ao obter dados da lesson: ${id}`, error);
            throw error;
        }
    },

    async getDataLessonByModule(id,accessToken) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/lesson/module/${id}`, {
                method: 'GET',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados da lesson:', error);
            throw error;
        }
    },
    
    async createNewLesson(payload, accessToken){
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.post('/lesson', payload, {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados da criação da Lesson:', error);
            throw error;
        }
    },

    async editLesson(accessToken,payload,id){
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.put(`/lesson/${id}`, payload, {
                headers,
            });
            return response;
        } catch (error) {
            console.error('Erro ao atualizar lesson', error);
            throw error;
        }
    },
    async moveUp(accessToken, id) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/lesson/up/${id}`,  {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao alterar ordem:', error);
                throw error;
        }
    },
    async moveDown(accessToken, id) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/lesson/down/${id}`,  {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao alterar ordem:', error);
                throw error;
        }
    },
}
