import api from './api';

export default {
    async getDataCupons( accessToken) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get('/cupom', {
                method: 'GET',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados do usuário:', error);
            throw error;
        }
    },
    
    async createNewCupom(accessToken, payload){
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.post('/cupom', payload, {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados da criação do cupom:', error);
            throw error;
        }
    },

    async getCupom(accessToken, id){
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/cupom/${id}`, {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados da criação do curso:', error);
            throw error;
        }
    },

    async editCupom(accessToken, id, payload){
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.put(`/cupom/${id}`, payload ,  {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter editar o curso:', error);
            throw error;
        }
    }
}

