<template>
    <div class="container">
        <!-- Loader -->
        <LoaderImage v-if="loading" />

        <!-- Mensagem quando não há dados -->
        <div v-if="!hasSubscription && !loading" class="no-data-container">
            <h3>Nenhuma assinatura encontrada</h3>
            <p>Este usuário ainda não possui movimentações ou assinatura ativa.</p>
        </div>

        <!-- Conteúdo existente - só exibe se houver dados -->
        <div v-else-if="!loading">
            <div class="titleContainer">
                <h3>Pagamentos e Assinaturas</h3>
                <p>Gerencie os pagamentos e assinaturas do usuário</p>
            </div>

            <div class="userInfoContainer">
                <div class="userInfoCard">
                    <div class="userInfoDetails">
                        <div class="user-line">
                            <span>Nome: <strong class="namePayment">{{userName}}</strong></span>
                            <span>Email: <strong>{{userEmail}}</strong></span>
                            <span>CPF: <strong>{{userCPF ? userCPF.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4') : ''}}</strong></span>
                            <span>Plano: <strong>{{planType}}</strong></span>
                            <span>Status: <strong>{{subscriptionStatus}}</strong></span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mainContent">
                <div class="cardsContainer">
                    <div class="row">
                        <div class="card-md">
                            <div class="contentCard" style="position: relative; height: 100%;">
                                <h3 v-if="hasReadyStats">{{totalPaid.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}}</h3>
                                <LoaderImage v-if="!hasReadyStats" />
                                <p v-if="hasReadyStats">Total Pago</p>
                            </div>
                        </div>

                        <div class="card-md">
                            <div class="contentCard" style="position: relative; height: 100%;">
                                <h3 v-if="hasReadyStats">{{nextPayment ? new Date(nextPayment).toLocaleDateString('pt-BR') : 'Não há pagamento agendado'}}</h3>
                                <LoaderImage v-if="!hasReadyStats" />
                                <p v-if="hasReadyStats">Próximo Pagamento</p>
                            </div>
                        </div>

                        <div class="card-md">
                            <div class="contentCard" style="position: relative; height: 100%;">
                                <div v-if="hasReadyStats">
                                    <h3>{{subscriptionValue ? subscriptionValue.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}) : 'R$ 0,00'}}</h3>
                                    <p>Valor da Assinatura</p>
                                </div>
                                <LoaderImage v-if="!hasReadyStats" />
                            </div>
                        </div>

                        <div class="card-md">
                            <div class="contentCard" style="position: relative; height: 100%;">
                                <div class="card-details">
                                    <div class="detail-item">
                                        <span class="label">Número do Cartão</span>
                                        <span class="value">{{cardLastDigits || '****'}}</span>
                                    </div>
                                    <div class="detail-item">
                                        <span class="label">Validade</span>
                                        <span class="value">{{cardExpiration || '--/--'}}</span>
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="actionsContainer">
                <div class="actionsHeader">
                    <!-- <div class="form-group" id="searchUserPage">
                        <div class="form-input">
                            <input
                                type="date"
                                class="form-control"
                                v-model="startDate"
                            />
                        </div>
                        <div class="form-input">
                            <input
                                type="date" 
                                class="form-control"
                                v-model="endDate"
                            />
                        </div>
                        <button class="btnSearch" @click="filterPayments" :disabled="!startDate || !endDate">
                            <fa icon="fa-search" :style="{color: (!startDate || !endDate) ? '#ccc' : '#250891'}" />
                            <span>Filtrar</span>
                        </button>
                    </div> -->
                    <div class="buttonsContainerUsers">
                        <button class="btnExport" @click="openChangeCardModal" :disabled="!cardLastDigits">
                            <fa icon="fa-credit-card" :style="{color: !cardLastDigits ? '#ccc' : '#150065'}" />
                            <span>Trocar Cartão</span>
                        </button>
                        <button class="btnExport" @click="cancelSubscription" :disabled="subscriptionStatus !== 'Ativo'">
                            <fa icon="fa-times" :style="{color: subscriptionStatus !== 'Ativo' ? '#ccc' : '#150065'}" />
                            <span>Cancelar Assinatura</span>
                        </button>
                    </div>
                </div>
            </div>

            <div class="tableContainer" style="height: 380px; overflow-y: auto;" v-if="hasReadySearch">
                <table>
                    <thead>
                        <tr>
                            <th>Data</th>
                            <th>Valor</th>
                            <th>Status</th>
                            <th>Método</th>
                            <th>ID Transação</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="payment in payments" :key="payment.id">
                            <td>{{formatDate(payment.dateInsert)}}</td>
                            <td>{{formatCurrency(payment.total)}}</td>
                            <td>{{payment.status === 4 ? 'Aprovado' : 'Pendente'}}</td>
                            <td>
                                <span v-if="payment.typePayment === 1">PIX</span>
                                <span v-else-if="payment.typePayment === 2">Cartão de Crédito</span>
                            </td>
                            <td>{{payment.codePayment}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <LoaderImage v-if="!hasReadySearch" />

            <!-- Modal Trocar Cartão -->
            <div class="modal-overlay" v-if="showChangeCardModal">
                <div class="modal-wrapper">
                    <div class="modal-header">
                        <h3>Trocar Cartão</h3>
                        <button class="modal-close" @click="closeChangeCardModal">&times;</button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Número do Cartão</label>
                            <input type="text" v-model="newCardNumber" placeholder="0000 0000 0000 0000" v-mask="'#### #### #### ####'" maxlength="19" @input="formatCardNumber">
                        </div>
                        <div class="form-row">
                            <div class="form-group half">
                                <label>Validade</label>
                                <input type="text" v-model="newCardExpiry" placeholder="MM/AA" maxlength="5" @input="formatCardExpiry">
                            </div>
                            <div class="form-group half">
                                <label>CVV</label>
                                <input type="text" v-model="newCardCvv" placeholder="123" maxlength="3">
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Nome no Cartão</label>
                            <input type="text" v-model="newCardName" placeholder="Nome como está no cartão">
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="modal-btn cancel" @click="closeChangeCardModal">Cancelar</button>
                        <button class="modal-btn save" @click="changeCardByUser" :disabled="!isFormValid">Salvar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import LoaderImage from '@/components/Weflix/LoaderImage.vue';
import Subscriptions from '@/utils/Weflix/subscriptions';

export default {
    data() {
        return {
            loading: false,
            hasReadyStats: true,
            hasReadySearch: true,
            hasSubscription: false,
            userName: 'samuel linkon',
            userEmail: 'samuel.linkon+10@tutor.do',
            userCPF: '09735058421',
            totalPaid: 99.99,
            nextPayment: '2025-02-14T16:37:09Z',
            planType: 'Premium',
            subscriptionStatus: 'Inativo',
            subscriptionValue: 99.99,
            cardLastDigits: '400000*****0069',
            cardExpiration: '10/2025',
            startDate: '',
            endDate: '',
            showChangeCardModal: false,
            newCardNumber: '',
            newCardExpiry: '',
            newCardCvv: '',
            newCardName: '',
            payments: [
                {
                    "id": "oFDc_CwNMdw",
                    "idBuyer": "oIl3BmP-wyg",
                    "nameBuyer": "samuel linkon",
                    "idWebsite": "L9ANE7tJzDo", 
                    "nameWebsite": "O CANAL DE TODAS AS ARTES",
                    "idCupom": null,
                    "titleCupom": null,
                    "total": 99.99,
                    "status": 1,
                    "typePayment": 1,
                    "codePayment": "sub_Aov57bxf9fqeVO3J",
                    "dateInsert": "2025-01-14T16:37:09Z",
                    "dateApproved": "2025-01-14T16:37:09Z",
                    "planType": 1,
                    "dateExpired": "2025-02-14T16:37:09Z",
                    "dateCancelled": null
                }
            ]
        }
    },
    computed: {
        ...mapState(['token']),
        isFormValid() {
            return this.newCardNumber.length === 19 && 
                   this.newCardExpiry.length === 5 && 
                   this.newCardCvv.length === 3 && 
                   this.newCardName.length > 0;
        }
    },
    components: {
        LoaderImage
    },
    methods: {
        formatDate(date) {
            return new Date(date).toLocaleDateString('pt-BR')
        },
        formatCurrency(value) {
            return value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})
        },
        async filterPayments() {
            console.log('Filtrando pagamentos...')
        },
        openChangeCardModal() {
            this.showChangeCardModal = true;
        },
        closeChangeCardModal() {
            this.showChangeCardModal = false;
            this.newCardNumber = '';
            this.newCardExpiry = '';
            this.newCardCvv = '';
            this.newCardName = '';
        },
        formatCardNumber(e) {
            let value = e.target.value.replace(/\D/g, '');
            if (value.length > 0) {
                value = value.match(new RegExp('.{1,4}', 'g')).join(' ');
            }
            this.newCardNumber = value;
        },
        formatCardExpiry(e) {
            let value = e.target.value.replace(/\D/g, '');
            if (value.length >= 2) {
                value = value.slice(0,2) + '/' + value.slice(2);
            }
            this.newCardExpiry = value;
        },
        async saveNewCard() {
            console.log('Salvando novo cartão...');
            this.closeChangeCardModal();
        },
        async changeCard() {
            console.log('Trocando cartão...')
        },
        async cancelSubscription() {
            console.log('Cancelando assinatura...')
        },
        getCardBrandImage(brand) {
            return `/images/card-brands/${brand.toLowerCase()}.png`
        },
        async getSubscription() {
            const id = this.$route.params.slug
            this.loading = true
            try {
                const response = await Subscriptions.getSubscriptionById(this.token, id)
                if(response && response.orders.length > 0) {
                    this.hasSubscription = true
                    this.userName = response.nome
                    this.userEmail = response.email 
                    this.userCPF = response.cpf
                    this.totalPaid = response.totalpayed
                    this.nextPayment = response.nextPayment
                    this.cardLastDigits = response.card
                    this.cardExpiration = response.cardValidity
                    this.payments = response.orders
                    this.subscriptionStatus = 'Ativo'
                } else {
                    this.hasSubscription = false
                }
            } catch (error) {
                console.error('Erro ao obter assinatura:', error)
                this.hasSubscription = false
            } finally {
                this.loading = false
            }
        },
        async cancelSubscription() {
            const id = this.$route.params.slug
            try {
                const response = await Subscriptions.cancelSubscription(this.token, id)
                if(response) {
                    this.subscriptionStatus = 'Cancelado'
                    alert('Assinatura cancelada com sucesso!')
                }
            } catch (error) {
                console.error('Erro ao cancelar assinatura:', error)
            }
        },
        async changeCardByUser() {
            const id = this.$route.params.slug
            const payload = {
                number: this.newCardNumber.replace(/\s/g, ''),
                holder_name: this.newCardName,
                exp_month: parseInt(this.newCardExpiry.split('/')[0]),
                exp_year: parseInt(this.newCardExpiry.split('/')[1]),
                cvv: this.newCardCvv
            }
            try {
                const response = await Subscriptions.changeCardByUser(this.token, payload, id)
                if(response) {
                    alert('Cartão trocado com sucesso!')
                }
            } catch (error) {
                console.error('Erro ao trocar cartão:', error)
            }
            console.log('Trocando cartão...')
        }
    }, 
    created() {
        this.getSubscription()
    }
}
</script>

<style scoped>
@import url(./global.css);
.container {
    max-width: 100%;
    background-color: white;
    padding: 10px 0px;
    padding-bottom: 50px;
}

.no-data-container {
    text-align: center;
    padding: 50px 20px;
}

.no-data-container h3 {
    color: #666;
    margin-bottom: 10px;
}

.no-data-container p {
    color: #999;
}

.titleContainer {
    display: block;
    text-align: left;
    padding: 0px 10px;
    border-bottom: 1px solid #e8e8e8;
    margin: 10px;
}

.userInfoContainer {
    padding: 20px;
    margin: 0 10px;
    border-bottom: 1px solid #e8e8e8;
}

.userInfoCard {
    background-color: #f8f9fa;
    border-radius: 5px;
    padding: 20px;
}

.userInfoDetails {
    display: flex;
    justify-content: space-between;
}

.user-line {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    font-size: 1.1em;
    color: #333;
    justify-content: space-between;
    width: 100%;
}

.mainContent {
    display: flex;
    justify-content: center;
    padding: 20px;
}

.cardsContainer {
    flex: 1;
    max-width: 100%;
}

.row {
    margin: 0;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.card-md {
    padding: 20px;
    margin: 0;
    box-shadow: -1px 4px 10px 1px #dfdfdf;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    height: 120px;
    flex: 1;
}

.actionsContainer {
    padding: 0 20px;
    margin-bottom: 20px;
}

.actionsHeader {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.btnSearch {
    width: auto;
    padding: 6px 12px;
    background-color: white;
    border: 1px solid #250891;
    color: #250891;
    border-radius: 5px;
    cursor: pointer;
    transition: all 500ms ease-in-out;
    margin-left: 10px;
}

.btnSearch:hover {
    background-color: #250891;
    color: white;
}

.btnExport {
    width: auto;
    padding: 6px 12px;
    background-color: white;
    border:1px solid #250891;
    color: #250891;
    border-radius: 5px;
    cursor: pointer;
    transition: all 500ms ease-in-out;
    display: flex;
    align-items: center;
    gap: 8px;
}

.btnExport:hover:not(:disabled) {
    background-color: #250891;
    color: white;
}

.tableContainer {
    padding: 10px;
    overflow-x: auto;
}

table {
    width: 100%;
    border-collapse: collapse;
    min-width: 600px;
}

th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

th {
    background-color: #f2f2f2;
    font-weight: bold;
}

tr:nth-child(even) {
    background-color: #f9f9f9;
}

#searchUserPage {
    position: relative;
    width: 600px;
    display: flex;
    justify-content: end;
    gap: 10px
}

.buttonsContainerUsers {
    display: flex;
    gap: 10px;
}

.card-details {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.detail-item {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.label {
    font-size: 0.9em;
    color: #666;
}

.value {
    font-size: 1.1em;
    font-weight: 500;
    color: #333;
}

/* Novo estilo do Modal */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-wrapper {
    background: #fff;
    width: 95%;
    max-width: 520px;
    border-radius: 12px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    overflow: hidden;
}

.modal-header {
    padding: 20px;
    background: #f8f9fa;
    border-bottom: 1px solid #e9ecef;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.modal-header h3 {
    margin: 0;
    color: #1a1a1a;
    font-size: 1.25rem;
}

.modal-close {
    background: none;
    border: none;
    font-size: 24px;
    color: #6c757d;
    cursor: pointer;
    padding: 0;
    line-height: 1;
    transition: color 0.2s;
}

.modal-close:hover {
    color: #1a1a1a;
}

.modal-body {
    padding: 24px;
}

.modal-body .form-group {
    margin-bottom: 20px;
}

.modal-body label {
    display: block;
    margin-bottom: 8px;
    color: #1a1a1a;
    font-weight: 500;
}

.modal-body input {
    width: 100%;
    padding: 12px;
    border: 1px solid #ced4da;
    border-radius: 8px;
    font-size: 1rem;
    transition: border-color 0.2s;
}

.modal-body input:focus {
    outline: none;
    border-color: #250891;
    box-shadow: 0 0 0 2px rgba(37, 8, 145, 0.1);
}

.form-row {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.form-row .form-group {
    flex: 1;
    margin-bottom: 0;
}

.modal-footer {
    padding: 20px;
    background: #f8f9fa;
    border-top: 1px solid #e9ecef;
    display: flex;
    justify-content: flex-end;
    gap: 12px;
}

.modal-btn {
    padding: 12px 24px;
    border-radius: 8px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
}

.modal-btn.cancel {
    background: #fff;
    border: 1px solid #ced4da;
    color: #6c757d;
}

.modal-btn.cancel:hover {
    background: #f8f9fa;
    border-color: #6c757d;
}

.modal-btn.save {
    background: #250891;
    border: none;
    color: #fff;
}

.modal-btn.save:hover:not(:disabled) {
    background: #1b0668;
}

.modal-btn.save:disabled {
    background: #a8a8a8;
    cursor: not-allowed;
}

@media (max-width: 768px) {
    .mainContent {
        padding: 10px;
    }
    
    .row {
        flex-direction: column;
        gap: 15px;
    }
    
    .card-md {
        width: 100%;
        height: auto;
        min-height: 100px;
    }
    
    .user-line {
        flex-direction: column;
        gap: 10px;
    }
    
    .actionsContainer {
        padding: 0 10px;
    }
    
    .buttonsContainerUsers {
        width: 100%;
        justify-content: flex-end;
    }
    
    .btnExport {
        padding: 8px;
    }
    
    .btnExport span {
        display: none;
    }
    
    .tableContainer {
        margin: 0;
        padding: 10px;
    }
    
    .form-row {
        flex-direction: column;
        gap: 15px;
    }
    
    .modal-wrapper {
        margin: 15px;
        width: auto;
    }
    
    .modal-body {
        padding: 16px;
    }
    
    .modal-footer {
        padding: 16px;
        flex-direction: column;
    }
    
    .modal-btn {
        width: 100%;
        text-align: center;
    }
}

strong.namePayment {
    text-transform: capitalize;
}
</style>
