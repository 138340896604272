import api from './api';

export default {
    async getVideos(accessToken) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get('/lesson', {
                method: 'GET',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados dos Videos:', error);
            throw error;
        }
    },

    async getVideosBySesson(accessToken, id) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/lesson/module/token/${id}`, {
                method: 'GET',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados da lesson:', error);
            throw error;
        }
    },
      async NewVideo(accessToken, payload){
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.post('/lesson', payload, {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao criar video', error);
            throw error;
        }
    },
    async editLesson(accessToken , payload , id){
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.put(`/lesson/${id}`, payload, {
                headers,
            });
            return response;
        } catch (error) {
            console.error('Erro ao atualizar o video', error);
            throw error;
        }
    },
    async getVideosById(accessToken, id) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/lesson/${id}`, {
                method: 'GET',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados do video:', error);
            throw error;
        }
    },
    async orderVideos(accessToken, payload) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.post(`/lesson/order`, payload,  {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao alterar ordem:', error);
                throw error;
        }
    },
    async listCollections(accessToken) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/course/mini`, {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao listar coleções:', error);
            throw error;
        }
    },
    async moveVideo(accessToken, idLesson, idCourse) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            };
            const response = await api.get(`/lesson/change/${idLesson}/course/${idCourse}`, {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao mover video:', error);
            throw error;
        }
    }

 
  
}
