<template>
<section class="globalSiteWeflix" v-if="finalWebsite">
    <Header @toggleModal="toggleOpenDialog" @toggleModalSubscribe="toggleOpenSubscribe" />
    <Banner />
    <section v-if="!userPortalWeflix && lowPricePlan && lowPricePlan.planValue ">
        <div class="subscription">
            <div class="text">
                <h1>Assines e tenha acesso ilimitado a partir de R$ {{ lowPricePlan.planValue > 0 ? lowPricePlan.planValue.toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0,00' }} /mês</h1>
                <button v-if="finalWebsite.company.cartStatus && finalWebsite.company.cartStatus === 1" class="btnHedaer" @click="toggleOpenSubscribe">ASSINE AGORA</button>
            </div>
        </div>
    </section>
    <Collections @toggleOpenDialog="toggleOpenDialog" @toggleOpenSubscribe="toggleOpenSubscribe"  v-if="plansWeflix.length > 0 && finalWebsite.typeImageCoverCourse === 2" />
    <TopColllections v-if="plansWeflix.length > 0 && finalWebsite.typeImageCoverCourse === 2" />
    <VideoCollections @toggleOpenDialog="toggleOpenDialog" @toggleOpenSubscribe="toggleOpenSubscribe"  />
    <section>
        <div class="infoWatch">
            <img class="imageResponsive" src="../../assets/Weflix/icon-responsive.svg" />
            <h3>Assista em qualquer lugar</h3>
            <h6>Nossos vídeos estão disponíveis para computadores, tablets e celulares.</h6>
        </div>
    </section>
    <CardsInfo />
    <section v-if="userPortalWeflix && userPortalWeflix.user.orderStatus !== 1 && lowPricePlan && lowPricePlan.planValue">
        <div class="subscription secundary">
            <div class="text">
                <h1>Assines e tenha acesso ilimitado a partir de R$ {{ lowPricePlan.planValue > 0 ? lowPricePlan.planValue.toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0,00' }} /mês</h1>
                <button  v-if="finalWebsite.company.cartStatus && finalWebsite.company.cartStatus === 1" class="btnHedaer" @click="toggleOpenDialog">ASSINE AGORA</button>
            </div>
        </div>
    </section>
    <Footer v-if="finalWebsite" />
    <Login :show="openDialogLogin" @toggleModal="toggleOpenDialog" />
    <Subscribe :show="openDialogSubscribe" @toggleModal="toggleOpenSubscribe" />
</section>
</template>

<script>
import Header from "@/components/Weflix/Hedaer.vue"
import Banner from "@/components/Weflix/Banner.vue"
import Collections from "@/components/Weflix/Collections.vue"
import CardsInfo from '@/components/Weflix/CardsInfo.vue'
import Footer from '@/components/Weflix/Footer.vue'
import Login from '@/components/Weflix/Login.vue'
import Subscribe from '@/components/Weflix/Subscribe.vue'
import ApiSite from '@/utils/Weflix/Website/index';
import VideoCollections from '@/components/Weflix/VideoCollections.vue'
import TopColllections from '@/components/Weflix/TopColllections.vue'
import {
    mapState,
    mapActions
} from 'vuex';
import ApiPlans from '@/utils/Weflix/Website/plans';
import ApiStudentView from '@/utils/Weflix/StudentView';

export default {
    data() {
        return {
            openDialogLogin: false,
            openDialogSubscribe: false,
            baseUrl: process.env.NODE_ENV === 'development' ? process.env.VUE_APP_BASE_URL_HOMOLOGATION : window.location.origin,
            lowPricePlan: '',
        }
    },
    components: {
        Header,
        Banner,
        Collections,
        VideoCollections,
        TopColllections,
        CardsInfo,
        Footer,
        Login,
        Subscribe
    },
    computed: {
        ...mapState(['userPortalWeflix', 'contentSiteWeflix', 'finalWebsite','plansWeflix']),
    },
    methods: {
        ...mapActions(['storeFinalWebsite', 'storeContentSiteWeflix', 'storePlansWeflix', 'storeTrialWeflix', 'storeUserPortalWeflix', 'storeHasSinglePurchase']),
        toggleOpenDialog() {
            this.openDialogLogin = !this.openDialogLogin;
        },
        toggleOpenSubscribe() {
            if (this.finalWebsite.company.cartStatus && this.finalWebsite.company.cartStatus === 1) {
                this.openDialogSubscribe = !this.openDialogSubscribe;
            }
        },
        async getInfoSite() {
            const aux = this.baseUrl.replace(/\/$/, '');

            const payload = {
                "url": aux
            }
            try {
                const response = await ApiSite.getWebsite(payload);
                this.storeFinalWebsite({
                    finalWebsite: response
                });
            } catch (error) {
                console.error(error);
            }
        },
        async getBanner() {
            const aux = this.baseUrl.replace(/\/$/, '');

            const payload = {
                "url": aux
            }
            try {
                const response = await ApiSite.getBanner(payload);
                this.storeContentSiteWeflix({
                    contentSiteWeflix: response[0]
                });
            } catch (error) {
                console.error(error);
            }
        },
        async getPlans() {
            const aux = this.baseUrl.replace(/\/$/, '');

            const payload = {
                "url": aux
            }
            try {
                const response = await ApiPlans.getPlans(payload);
                this.lowPricePlan = response.reduce((min, plan) => Number(plan.planValue) > 0 && Number(plan.planValue) < Number(min.planValue) ? plan : min, response.find(p => Number(p.planValue) > 0) || response[0]);
                this.storePlansWeflix({
                    plansWeflix: response
                });
                this.storeHasSinglePurchase({
                    hasSinglePurchase: response.length > 0 ? false : true
                });
            } catch (error) {
                console.error(error);
            }
        },
        async getTrial() {
            const aux = this.baseUrl.replace(/\/$/, '');

            const payload = {
                "url": aux
            }
            try {
                const response = await ApiSite.getTrial(payload);
                this.storeTrialWeflix({
                    trialWeflix: response
                });
            } catch (error) {
                console.error(error);
            }
        },
        async setUserViewAdmin() {
            try {
                const urlParams = new URLSearchParams(window.location.search);
                const userParam = urlParams.get('tokenStudentView');

                if (userParam) {
                    const response = await ApiStudentView.validateToken(userParam);
                    this.storeUserPortalWeflix({
                        userPortalWeflix: response
                    });
                }
            } catch (error) {
                console.error('Erro ao decodificar usuário:', error);
                return null;
            }
        }
    },
    created() {
        this.getInfoSite();
        this.getBanner();
        this.getPlans();
        this.getTrial();
    },
    mounted() {
        this.setUserViewAdmin();
    }
}
</script>

<style scoped>
.globalSiteWeflix {
    background-color: black;
}

.subscription {
    background-color: black;
    min-height: 300px;
}

.text {
    max-width: 850px;
    margin: 0 auto;
    padding-top: 50px;
}

.subscription h1 {
    color: white;
    font-size: 30px;
}

button.btnHedaer {
    color: white;
    background-color: black;
    margin: 10px 0px;
    font-size: 30px;
    font-weight: 700;
}

img.imageResponsive {
    width: 150px;
}

.infoWatch {
    margin-top: 50px;
    margin-bottom: 50px;
}

.infoWatch h3,
.infoWatch h6 {
    color: white;
}

.infoWatch h3 {
    margin-top: 40px;
    font-weight: 700;
}

.infoWatch h6 {
    font-size: 20px;
}

.subscription.secundary {
    margin-top: 65px;
}
</style>
