import api from './api';

export default {
    async getDataModules(accessToken) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get('/module?linesPerPage=100', {
                method: 'GET',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados do usuário:', error);
            throw error;
        }
    },

    async getDataModulesByCourse(id,accessToken) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/module/course/${id}`, {
                method: 'GET',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados dos modulos:', error);
            throw error;
        }
    },
    async getDataModulesAndLessonsByCourse(id,accessToken) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/module/course/token/${id}`, {
                method: 'GET',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados dos modulos e aulas:', error);
            throw error;
        }
    },
    
    async createNewModule(userData, accessToken){
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.post('/module', userData, {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados da criação do curso:', error);
            throw error;
        }
    },
    async editModule(payload, accessToken, id){
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.put(`/module/${id}`, payload, {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao atualizar modulo:', error);
            throw error;
        }
    },
    async orderModules(accessToken, payload) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.post(`/module/order`, payload,  {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao alterar ordem:', error);
                throw error;
        }
    },
    async orderLessons(accessToken, payload) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.post(`/lesson/order`, payload,  {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao alterar ordem:', error);
                throw error;
        }
    },
    async getStatusCourse(accessToken, id) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/course/${id}/type-count`,  {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao pegar status do curso:', error);
                throw error;
        }
    },
   
}
