<template>
  <section class="layoutLayout weflix-admin-integrationsLayout">
      <div class="titlelayoutLayout">
          <h3>Integrações</h3>
          <small>Personalize as integrações do seu site.
          </small>
      </div>
      <div class="row" v-if="hasReady">
        <div :class="responsiveClassOne">
          <div class="row">

            <div class="col-form-inner col-12 col-md-12 col-lg-12 select">
              <div class="form-group">
                <label class="label-form">Facebook Pixel: </label>
                <div class="form-input">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="GTM-28CJ0"
                    v-model="facebook"
                  />
                  <small class="small">Insira o código do seu Facebook Pixel para rastrear e analisar o comportamento dos visitantes do seu site.</small>

                </div>
              </div>
            </div>
            <div class="col-form-inner col-12 col-md-12 col-lg-12 select">
              <div class="form-group">
                <label class="label-form">Google Ads Pixel: </label>
                <div class="form-input">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="GTM-28CJ0"
                    v-model="gads"
                  />
                  <small class="small">Digite o código do seu Google Ads Pixel para acompanhar as conversões e otimizar suas campanhas de publicidade.</small>

                </div>
              </div>
            </div>
            <div class="col-form-inner col-12 col-md-12 col-lg-12 select">
              <div class="form-group">
                <label class="label-form">Google Analytics Code: </label>
                <div class="form-input">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="GTM-28CJ0"
                    v-model="ganalytic"
                  />
                  <small class="small">Digite o código de acompanhamento do Google Analytics para monitorar o tráfego e o comportamento dos usuários no seu site.</small>

                </div>
              </div>
            </div>
            <div class="col-form-inner col-12 col-md-12 col-lg-12 select">
              <div class="form-group">
                <label class="label-form">Tag Manager Code: </label>
                <div class="form-input">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="GTM-28CJ0"
                    v-model="tagManager"
                  />
                  <small class="small">Insira o código do Google Tag Manager para gerenciar e implantar tags no seu site de forma centralizada.</small>

                </div>
              </div>
            </div>
           
          </div>
        </div>

        <div :class="responsiveClassTwo" >
         

          <div class="col-form-inner col-12 col-md-12 col-lg-12 select">
            <div class="form-group">
              <label class="label-form">RDRefresh Code: </label>
              <div class="form-input">
                <input
                  type="text"
                  class="form-control"
                  placeholder="GTM-28CJ0"
                  v-model="rdrefreshToken"
                />
                <small class="small">Informe o código do RDStation para integrar suas estratégias de marketing digital e automação de marketing.</small>

              </div>
            </div>

          </div>
          
            <div class="col-form-inner col-12 col-md-12 col-lg-12 select">
              <div class="form-group">
                <label class="label-form">RDStation Code: </label>
                <div class="form-input">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="GTM-28CJ0"
                    v-model="rdcode"
                  />
                  <small class="small">Informe o código do RDStation para integrar suas estratégias de marketing digital e automação de marketing.</small>

                </div>
              </div>

            </div>
            <div class="col-form-inner col-12 col-md-12 col-lg-12 select">
              <div class="form-group">
                <label class="label-form">RDStation Secret Client: </label>
                <div class="form-input">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="GTM-28CJ0"
                    v-model="rdSecret"
                  />
                  <small class="small">Informe o código secreto do cliente RDStation para integrar com serviços externos de forma segura e automatizada.</small>

                </div>
              </div>

            </div>
            <div class="col-form-inner col-12 col-md-12 col-lg-12 select">
              <div class="form-group">
                <label class="label-form">RDStation Client ID: </label>
                <div class="form-input">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="GTM-28CJ0"
                    v-model="rdClient"
                  />
                  <small class="small">Digite o ID do cliente RDStation para configurar a integração e automatização de suas estratégias de marketing digital.</small>

                </div>
              </div>
            </div>
            
        </div>
      </div>
      <div class="actionsSave layoutIntegrationsWeflix">
        <button class="btnSave" @click="updateIntegrations">Salvar</button>
      </div>
      
  </section>
 
<Loader :hasReady="hasReady" />
</template>
<script>
import websiteApi from '@/utils/Weflix/website';
import { mapState } from 'vuex';
import Loader from '@/components/Weflix/Loader.vue';

export default {
  data(){
      return{
          facebook: '',
          gads: '',
          ganalytic: '',
          tagManager: '',
          rdrefreshToken: '',
          rdcode: '',
          rdSecret: '',
          rdClient: '',
          hasReady: false,
          descriptionAbout:'',
          windowWidth: window.innerWidth,
      }
  },
  computed: {
    ...mapState(['token']),
    responsiveClassOne() {
      if (this.windowWidth <= 600) {
        return "col-form-outer col-12 col-md-6 col-lg-6 col-1";
      } else {
        return "col-form-outer col-6 col-md-6 col-lg-6 col-1";
      }
    },
    responsiveClassTwo() {
      if (this.windowWidth <= 600) {
        return "col-form-outer col-12 col-md-6 col-lg-6 col-2";
      } else {
        return "col-form-outer col-6 col-md-6 col-lg-6 col-2";
      }
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  components: {
      Loader
  },
  methods:{
    async getIntegrations() {
      const response = await websiteApi.getWebsite(this.token);
      this.id = response.id;
      this.name = response.nome;
      this.description = response.description;
      this.logo = response.logo;
      this.favicon = response.favicon;
      this.logoPreview = response.logoFinal;
      this.faviconPreview = response.faviconFinal;
      this.url = response.url;
      this.status = response.status;
      this.hasReady = true;
      this.activeTheme = response.typeLayout;
      this.color = response.color;

      this.facebook = response.facebookPixelCode;
      this.gads = response.googleAdsCode;
      this.ganalytic = response.googleAnalyticsCode;
      this.tagManager = response.tagManagerCode;
      this.rdrefreshToken = response.rdrefreshToken;
      this.rdcode = response.rdcode;
      this.rdSecret = response.rdsecretClient;
      this.rdClient = response.rdclienteId;
      this.descriptionAbout = response.about;
    },
    async updateIntegrations() {
      this.hasReady = false;
      const payload = {
          "description": this.description,
          "logo": this.logo,
          "favicon": this.favicon,
          "nome": this.name,
          "status": this.status,
          "url": this.url,
          "typeLayout": this.activeTheme,
          "color": this.color,

          "facebookPixelCode": this.facebook,
          "googleAdsCode": this.gads,
          "googleAnalyticsCode": this.ganalytic,
          "tagManagerCode": this.tagManager,
          "rdrefreshToken": this.rdrefreshToken,
          "rdcode": this.rdcode,
          "rdsecretClient": this.rdSecret,
          "rdclienteId": this.rdClient,
          "about":this.descriptionAbout
      }
        try {
          const response = await websiteApi.editWebsite(this.token, this.id, payload);
          this.getIntegrations();
          this.hasReady = true;
        } catch (error) {
          console.error('Erro ao atualizar dados do website:', error);
      }
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  created() {
    this.getIntegrations()
  }
}
</script>
<style>
.titlelayoutLayout {
  text-align: left;
  padding: 10px;
  border-bottom: 1px solid #e3e3e3;
}
.actionsSave {
  position: absolute;
  bottom: 30px;
  right: 35px;
}
button.btnSave {
  color: #250891;
  width: 150px;
  background-color: #ffffff;
  padding: 6px 10px;
  border-radius: 5px;
  transition: all 500ms ease-in-out;
  font-size: 18px;
  border: 2px solid #250891;
}
button.btnSave:hover {
  background-color: #160266;
  color: white;
}
@media (max-width: 600px) {
  .actionsSave.layoutIntegrationsWeflix {
    position: inherit !important;
    margin: 0;
  }
  .weflix-admin-integrationsLayout h3 {
    font-size: 20px;
    margin-bottom: 5px;
    color: #333;
  }
  .weflix-admin-integrationsLayout .form-group label, .title {
    font-size: 16px;
    margin-bottom: 5px;
    color: #333;
  }
  .weflix-admin-integrationsLayout small {
    font-size: 12px;
    color: #666;
  }
  .weflix-admin-integrationsLayout .col-form-inner.col-12.col-md-12.col-lg-12.select {
    margin: 5px 0px 5px 0px;
  }
  .weflix-admin-integrationsLayout .actionsSave {
    padding: 10px 10px 10px 10px;
  }
}
</style>