<template>
    <div class="container myWeflixEditVideo">
        <div class="titleContainer">
            <h3>Editar Video</h3>
            <small>Personalize informações do seu video.</small>
           
            <div class="moveVideo" @click="showMoveDialog = true">
                <fa icon="fa-list"/>
                <span>Mover</span>
            </div>
            <div class="reuseVideo" @click="showCopyDialog = true">
                <fa icon="fa-copy"/>
                <span>Reutilizar</span>
            </div>
            <div class="exludeVideo edit" @click="showConfirmDialog = true">
                <fa icon="fa-trash"/>
                <span>Excluir</span>
            </div>
        </div>
        <div class="contetContainerEditVideo" v-if="hasReady">
            <div class="col-form-inner col-12 col-md-7 col-lg-7 freeVideo">
                <div class="col-form-inner col-6 col-md-6 col-lg-6">
                    <div class="form-group">
                        <div class="form-label">
                            <label>Status<v-tooltip text="Campo obrigatório" location="top">
                            </v-tooltip></label>
                        </div>
                        <div class="itemAtionCollections form-check form-switch">
                            <span>Ativado</span>
                            <input
                                class="form-check-input"
                                type="checkbox"
                                role="switch"
                                v-model="status"
                            />
                        </div>
                        <small>Ao ativar este vídeo, ele será disponibilizado ao público. Desative para ocultá-lo da visualização pública.</small>
                    </div>
                </div>
                <div class="col-form-inner col-6 col-md-6 col-lg-6">
                    <div class="form-group">
                        <div class="form-label">
                            <label>Visibilidade<v-tooltip text="Campo obrigatório" location="top">
                            </v-tooltip></label>
                        </div>
                        <v-checkbox label="Vídeo Gratuito" v-model="sample" @click="(()=>this.sample = !this.sample)"></v-checkbox>
                        <small>Apenas um vídeo em cada coleção pode ser gratuito. Ao habilitar este vídeo como gratuito, todos os outros na coleção se tornam automaticamente privados.</small>
                    </div>
                </div>
                <div class="col-form-inner col-12 col-md-12 col-lg-12">
                    <div class="form-group">
                        <div class="form-label">
                            <label>Título do Video<v-tooltip text="Campo obrigatório" location="top">
                                <template v-slot:activator="{ props }">
                                    <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                                </template>
                            </v-tooltip></label>
                        </div>
                        <div class="form-input">
                            <input
                                type="text" 
                                required
                                class="form-control"
                                v-model="name"
                                placeholder="video.mp4"
                            />
                            <!-- <small class="alertText">Campo obrigatório e deve ser preenchido corretamente.</small> -->
                            <small>Nome comercial do seu curso, uso externo.</small>
                        </div>
                    </div>
                </div>
                <div class="col-form-inner col-12 col-md-12 col-lg-12" v-if="!noSeason">
                    <div class="form-group">
                        <div class="form-label">
                            <label>Temporada: </label>
                        </div>
                        <div class="form-input">
                            <select
                                required
                                class="form-control"
                                v-model="seasonSelected">
                                <option :value="seasonItem.id" :selected="index == 0" v-for="(seasonItem, index) in season" :key="seasonItem.id">{{ seasonItem.title }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-form-inner col-12 col-md-12 col-lg-12">
                    <div class="form-group">
                        <div class="form-label">
                            <label> Descrição do video<v-tooltip text="Campo obrigatório" location="top">
                                <template v-slot:activator="{ props }">
                                    <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                                </template>
                            </v-tooltip></label>
                        </div>
                        <div class="form-textarea full-descroption">
                            <QuillEditor theme="snow" toolbar="essential" 
                                @input="updateContentLongDescription($event.srcElement.innerHTML)"
                                v-model:content="longDescription"
                                :options="{ placeholder: 'Descrição completa do video (detalhes, objetivos, público-alvo etc.)' }"
                                contentType="html"/>
                            <!-- <small class="alertText" >Campo obrigatório e deve ser preenchido corretamente.</small> -->                    
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="col-form-inner col-12 col-md-5 col-lg-5">
                <div class="col-form-inner col-12 col-md-12 col-lg-12">
                    <div class="form-group imageCoverPreview" v-if="imagePreviewCover" @click="openModalVideo">
                        <img class="coverVideo" :src="imagePreviewCover"  />
                        <div class="info">
                            <fa icon="fa-circle-play"/>
                        </div>
                    </div>
                </div>
                <div class="col-form-inner col-12 col-md-12 col-lg-12 input-video">
                    <div class="form-group">
                        <div class="form-label">
                            <label>Capa do vídeo </label>
                        </div>
                        
                        <div class="form-file">
                            <div class="file-wrapper primary" >
                                <span class="thumbnail thumb-img">
                                    <span class="icon"  v-if="loaderImage">
                                        <svg
                                            width="46"
                                            height="39"
                                            viewBox="0 0 46 39"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M45 19.9308L36.7274 12.3475C36.1595 11.827 35.2948 11.8047 34.7009 12.2953L18.824 25.411C18.3196 25.8277 17.6074 25.8812 17.0464 25.5446L11.1009 21.9773C10.5893 21.6703 9.94666 21.6856 9.45024 22.0166L1 27.6501"
                                                stroke="#9D9FA3"
                                                stroke-width="1.25714"
                                            ></path>
                                            <rect
                                                x="1.00049"
                                                y="1.40454"
                                                width="43.9461"
                                                height="36.1909"
                                                rx="2.58507"
                                                stroke="#9D9FA3"
                                                stroke-width="1.25714"
                                            ></rect>
                                            <circle
                                                cx="14.8948"
                                                cy="10.6676"
                                                r="3.71629"
                                                stroke="#9D9FA3"
                                                stroke-width="1.25714"
                                            ></circle>
                                        </svg>
                                    </span>
                                    <span class="text"  v-if="loaderImage">
                                        Clique aqui para selecionar a imagem
                                    </span>
                                </span>
                                <LoaderImage :hasReady="loaderImage"/>
                                <input class="form-control" type="file" @change="updateFileCover" accept=".png, .jpg, .jpeg, .webp"/>
                            </div>
                            <div class="form-caption">
                                <div class="caption">
                                    <small> Tamanho: 400x400px, apenas formato PNG e JPG.</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-form-inner col-12 col-md-12 col-lg-12 input-video">
                    <div class="form-group">
                        <div class="form-label">
                            <label>Carregue novos videos para usa coleção</label>                  
                        </div>
                        <div class="form-file">
                            <div class="file-wrapper primary">
                                <span class="thumbnail thumb-img">
                                    <span class="icon">
                                        <svg class="uploadVideoIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M0 128C0 92.7 28.7 64 64 64l256 0c35.3 0 64 28.7 64 64l0 256c0 35.3-28.7 64-64 64L64 448c-35.3 0-64-28.7-64-64L0 128zM559.1 99.8c10.4 5.6 16.9 16.4 16.9 28.2l0 256c0 11.8-6.5 22.6-16.9 28.2s-23 5-32.9-1.6l-96-64L416 337.1l0-17.1 0-128 0-17.1 14.2-9.5 96-64c9.8-6.5 22.4-7.2 32.9-1.6z"/></svg>                    </span>
                                    <span class="text">
                                        Clique aqui para adicionar novos vídeos
                                    </span>
                                </span>
                                <input class="form-control" multiple type="file" @change="uploadVideoAws"  accept=".mp4, .avi"/>
                            </div>
                            <div class="form-caption">
                                <div class="caption">
                                    <small>Formatos aceitos: .mp4, .avi. </small>
                                </div>
                            </div>
                        </div>
                        <div class="upload-status" v-if="isUploading || isUploaded">
                            <div class="loading-spinner" v-if="isUploading && !isUploaded">
                                <fa icon="fa-sync fa-spin" />
                                <span>Enviando vídeo...</span>
                            </div>
                            <div class="upload-complete" v-else-if="isUploaded">
                                <fa icon="fa-check-circle" />
                                <span>Upload concluído com sucesso!</span>
                            </div>
                            <div class="progress-bar" v-if="isUploading || isUploaded">
                                <div class="progress-fill" :style="{ width: progress + '%' }">
                                    <span class="progress-text">{{ Math.round(progress) }}%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="actions">
            <button class="btn" @click="(()=>this.$router.push('/admin/colecao/' + this.currentCollections.id))">Voltar</button>
            <button class="btn" @click="editVideo()" :disabled="!loaderImage" :class="{ 'disabled': !loaderImage }">Salvar</button>
        </div>
    </div>
    <div class="pa-4 text-center">
        <v-dialog
            v-model="showConfirmDialog"
            max-width="320"
            persistent
        >
            <v-card>
                <v-card-title class="headline">Atenção</v-card-title>
        
                <v-card-text>
                    Deseja realmente desativar este vídeo?
                </v-card-text>
        
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="cancel" text @click="showConfirmDialog = false">Sair</v-btn>
                    <v-btn class="accept" text @click="confirmDisable">Continuar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>

       <!-- Modal de Mover Video -->
       <!-- <v-dialog v-model="showMoveDialog" max-width="600">
        <v-card>
            <v-card-title>Mover Vídeo</v-card-title>
            <v-card-text>
                <div class="video-info">
                    <div class="video-thumbnail">
                        <img :src="imagePreviewCover" :alt="name">
                    </div>
                    <div class="video-details">
                        <h3>{{ name }}</h3>
                        <p class="duration">Duração: {{ videoTime ? converterTempo(videoTime) : '00:00' }}</p>
                    </div>
                </div>

                <div class="move-controls">
                    <p>Para qual coleção deseja mover este vídeo?</p>
                    <input 
                    type="text"
                    v-model="filterText"
                    placeholder="Filtrar coleções..."
                    class="form-control mt-2"
                />
                {{ filterText }}
                    <select v-model="selectedCollection" class="form-select">
                        <option value="" v-if="collections.length == 0">Selecione uma coleção</option>
                        <option v-for="collection in collections.filter(c => 
                            c.name.toLowerCase().includes((filterText || '').toLowerCase()))" 
                            :key="collection.id"
                            :value="collection.id">
                            {{ collection.name }}
                        </option>
                    </select>
                   
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="showMoveDialog = false">Cancelar</v-btn>
                <v-btn color="primary" text @click="moveVideo">Mover</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog> -->
    <v-dialog v-model="showMoveDialog" max-width="600">
        <v-card>
            <v-card-title>Mover Vídeo</v-card-title>
            <v-card-text>
                <div class="video-info">
                    <div class="video-thumbnail">
                        <img :src="imagePreviewCover" :alt="name">
                    </div>
                    <div class="video-details">
                        <h3>{{ name }}</h3>
                        <p class="duration">Duração: {{ videoTime ? converterTempo(videoTime) : '00:00' }}</p>
                    </div>
                </div>
    
                <div class="move-controls">
                    <p>Para qual coleção deseja mover este vídeo?</p>
                    <select v-model="selectedCollection" class="form-select">
                        <option value="">Selecione uma coleção</option>
                        <option v-for="collection in collections" 
                                :key="collection.id"
                                :value="collection.id">
                            {{ collection.name }}
                        </option>
                    </select>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="showMoveDialog = false">Cancelar</v-btn>
                <v-btn color="primary" text :disabled="!selectedCollection" @click="moveVideo">Mover</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    

    <v-dialog v-model="showCopyDialog" max-width="600">
        <v-card>
            <v-card-title>Copiar Vídeo</v-card-title>
            <v-card-text>
                <div class="move-controls">
                    <p>Selecione a coleção de origem</p>
                    <select v-model="selectedCollection" class="form-select">
                        <option value="">Selecione uma coleção</option>
                        <option v-for="collection in collections" 
                                :key="collection.id"
                                :value="collection.id">
                            {{ collection.name }}
                        </option>
                    </select>
                </div>
            </v-card-text>
            <v-card-text>
                <div class="move-controls">
                    <p>Selecione o vídeo</p>
                    <select v-model="selectedLesson" class="form-select">
                        <option value="">Selecione um vídeo</option>
                        <option v-for="videos in lessonByCollection" 
                                :key="videos.id"
                                :value="videos.id">
                            {{ videos.name }}
                        </option>
                    </select>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="showCopyDialog = false">Cancelar</v-btn>
                <v-btn color="primary" text @click="copyVideo">Copiar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <Loader :hasReady="hasReady"/>
    <Player :showModalVideo="showModalVideo" @closeModalVideo="closeModalVideo"/>
</template>

<script>
    import Sesson from '@/utils/Weflix/sesson';
    import Videos from '@/utils/Weflix/videos';
    import { mapState } from 'vuex';
    import Loader from '@/components/Weflix/Loader';
    import apiAWS from '@/utils/storage/aws';
    import LoaderImage from '@/components/Weflix/LoaderImage';
    import Player from '@/components/Weflix/Player/player';


    export default {
        data(){
            return{
                deleteItem: false,
                showConfirmDialog: false,
                season:'',
                seasonSelected: null,
                noSeason: true,
                status: false,
                sample: false,
                name: '',
                shortDescription: '',
                videoFile: null,
                coverVideo: '',
                imagePreviewCover:'',
                linkVideo: '',
                hasReady: false,
                loaderImage: true,
                showModalVideo: false,
                tokenVideo: '',
                isUploading: false,
                uploadProgress: 0,
                isUploaded: false,
                longDescription: '',
                progress: 0,
                showMoveDialog: false,
                selectedVideo: null,
                selectedCollection: '',
                collections: [],
                showCopyDialog: false,
                lessonByCollection: [{id: 1, name: 'Video 1'}, {id: 2, name: 'Video 2'}, {id: 3, name: 'Video 3'}],
                selectedLesson: null,
                filterText: '',
                filteredCollections: []

            }
        },
        computed: {
            ...mapState(['token', 'currentCollections']),
            filteredCollections() {
                if (!this.filterText) return this.collections;
                return this.collections.filter(item => 
                    item.name.toLowerCase().includes(this.filterText.toLowerCase())
                );
            }
        },
        components: {
            Loader,
            LoaderImage,
            Player
        },
        methods: {            confirmDisable() {
                this.showConfirmDialog = false;
                this.disableVideo();
            },
            closeDialog(){
                this.deleteItem = false;
            },
            updateFileCover(event) {
                const file = event.target.files[0];
                if (file) {
                    this.imagePreviewCover = URL.createObjectURL(file);
                }
            },
            updateFilePathThumbnail(event) {
                this.videoFile = event.target.files[0];
            },
            updateContentLongDescription(content) {
                this.longDescription = content;
            },
            async getSesson(){
                try {
                    const response = await Sesson.getSessonByCollection(this.token, this.currentCollections.id);
                    this.hasReady = true;
                    if(response.content.length > 0) {
                        if (Array.isArray(response)) {
                            this.season = response.content.sort((a, b) => a.order - b.order);
                            this.seasonSelected = this.season[0].id;
                            this.noSeason = false;
                        } else {
                            this.season = response.content;
                            this.seasonSelected = this.season[0].id;
                            this.noSeason = false;
                        }
                    }
                    else {
                        this.noSeason = true;
                        this.newSeason();
                    }
                } catch (error) {
                    console.error('Erro ao obter dados da coleção:', error);
                }
            },
            async getSessonBy(){
                try {
                    const response = await Sesson.getSessonByCollection(this.token, this.currentCollections.id);
                    this.hasReady = true;
                    if(response.content.length > 0) {
                        if (Array.isArray(response)) {
                            this.season = response.content.sort((a, b) => a.order - b.order);
                            this.seasonSelected = this.season[0].id;
                            this.noSeason = false;
                        } else {
                            this.season = response.content;
                            this.seasonSelected = this.season[0].id;
                            this.noSeason = false;
                        }
                    }
                    else {
                        this.noSeason = true;
                        this.newSeason();
                    }
                } catch (error) {
                    console.error('Erro ao obter dados da coleção:', error);
                }
            },
            async newSeason(){
                const payload = {
                    "course": {
                        "id": this.currentCollections.id
                    },
                    "title": "Temporada 1",
                    "sample": 0
                }
                try {
                    const response = await Sesson.createSesson(this.token, payload);
                } catch (error) {
                    console.error('Erro ao criar temporada:', error);
                }
            },
            async getVideo(){
                const param = this.$route.params.slug;
                try {
                    const response = await Videos.getVideosById(this.token, param);
                    this.status = response.status == 1? true : false,
                    this.sample = response.sample == 1? true : false,
                    this.name = response.title,
                    this.longDescription = response.description,
                    this.videoFile = response.path,
                    this.coverVideo = response.finalVideoThumbnail.split('.com/')[1],
                    this.seasonSelected = response.idModule,
                    this.imagePreviewCover = response.finalVideoThumbnail
                    this.hasReady = true;
                    this.status = response.status == 1? true : false;
                    
                } catch (error) {
                    console.error('Erro ao obter dados do video:', error);
                }
            },
            async putFileAws(url, file){
                try {
                    const response = await apiAWS.putFileAWS(url, file);
                } catch (error) {
                    console.error('Erro ao enviar arquivo para AWS');
                }
            },
            async updateFileCover(event){
                this.loaderImage = false;
                const file = event.target.files[0];
                this.imagePreviewCover = URL.createObjectURL(file);
                try {
                    const response =  await apiAWS.getSignatureImage(this.token, file.name);
                    this.putFileAws(response.url, file);
                    this.coverVideo = response.token;
                    this.loaderImage = true;
                } catch (error) {
                    console.error('Erro na geração do TOKEN AWS')
                }
            },
            async uploadVideoAws(event) {
    try {
        const file = event.target.files[0];
        const fileExtension = file.type.split('/').pop();
        const fileSize = Math.round(file.size / (1024 * 1024));


        const CHUNK_SIZE = 4 * 1024 * 1024 * 1024;
        const totalChunks = Math.ceil(file.size / CHUNK_SIZE);

        const response = await apiAWS.getSignatureVideo(this.token, totalChunks, fileExtension, CHUNK_SIZE);

        if (!response) {
            throw new Error('URLs de upload inválidas');
        }

        this.tokenVideo = response;

        await this.putVideoAws(response.urls, file, totalChunks, CHUNK_SIZE);

    } catch (error) {
        console.error('Erro ao enviar arquivo para AWS', error);
    }
},

async putVideoAws(urlArray, file, totalChunks, chunkSize) {
    this.isUploading = true;
    this.isUploaded = false;
    let successfulUploads = 0;
    let totalProgress = 0;
    let progressArray = new Array(totalChunks).fill(0);

    for (let i = 0; i < totalChunks; i++) {
        const start = i * chunkSize;
        const end = Math.min(start + chunkSize, file.size);
        const chunk = file.slice(start, end);

        try {
            await apiAWS.putVideoAWS(urlArray[i].url, chunk, (progress) => {
                progressArray[i] = progress;
                totalProgress = progressArray.reduce((a, b) => a + b, 0) / totalChunks;
                this.progress = totalProgress;
            });

            successfulUploads++;
            if (successfulUploads === totalChunks) {
                this.isUploaded = true;
                this.isUploading = false;
            }
        } catch (error) {
            console.error(`Erro no upload da parte ${i + 1}:`, error);
            throw error;
        }
    }
},
           
            async editVideo(){
                this.hasReady = false;
                const id = this.$route.params.slug;
                const payload = {
                    "idModule":this.seasonSelected,
                    "title": this.name,
                    // "status": this.status == true? 1 : 0,
                    "description": this.longDescription,
                    //path imagem cover
                    "videoThumbnail": this.coverVideo,
                    "sample": this.sample == true? 1 : 0,
                    "type":6,
                    "token": this.tokenVideo ?  this.tokenVideo.urls.map(item => item.token) : null,
                    "status": this.status == true? 1 : 0,
                    "uploadId": this.tokenVideo.uploadId

                }
                try {
                    const response = await apiAWS.editVideoVideoteca(this.token, payload, id);
                    this.hasReady = true;
                } catch (error) {
                    console.error('Erro ao enviar arquivo para AWS');
                }
            },
            async disableVideo(){
                this.hasReady = false;
                const id = this.$route.params.slug;
                const payload = {
                    "idModule":this.seasonSelected,
                    "title": this.name,
                    "description": this.longDescription,
                    "videoThumbnail": this.coverVideo,
                    "sample": this.sample == true? 1 : 0,
                    "type":6,
                    "token": this.tokenVideo? this.tokenVideo : null,
                    "status": 3

                }
                try {
                    const response = await apiAWS.editVideoVideoteca(this.token, payload, id);
                    this.hasReady = true;
                } catch (error) {
                    console.error('Erro ao enviar arquivo para AWS');
                } finally {
                    this.$router.push('/admin/colecao/' + this.currentCollections.id);
                }
            },
            closeModalVideo(){
                this.showModalVideo = false;
                document.body.style.overflow = '';

            },
            openModalVideo(){
                this.showModalVideo = true;
                document.body.style.overflow = 'hidden';

            },
            async moveVideo() {
           
               const id = this.$route.params.slug;
               try {
                   const response = await Videos.moveVideo(this.token, id, this.selectedCollection);
                   alert('Vídeo movido com sucesso');
                   this.showMoveDialog = false;
               } catch (error) {
                   console.error('Erro ao mover vídeo:', error);
               }
           
       },
       async getCollections(){
            try {
                const response = await Videos.listCollections(this.token);
                console.log(response);
                this.collections = response.map(item => ({
                    id: item.id,
                    name: item.name,
                    status: item.status
                }));
            } catch (error) {
                console.error('Erro ao obter coleções:', error);
            }
        },
        },  
        created(){
            this.getVideo();
            this.getSesson();
            this.getCollections();
        }
    }
</script>

<style scoped>
    @import url(./global.css);
    .container {
        max-width: 100%;
    }
    .titleContainer {
        display: block;
        text-align: left;
        padding: 0px 10px;
        border-bottom: 1px solid #e8e8e8;
        margin: 10px;
        
    }
    .container {
        background-color: white;
        padding: 10px 0px;
        padding-bottom: 50px;
    }
    .CollectionVideos {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px;
        padding: 10px;
    }
    .itemCollectionVideo {
        max-width: 30%;
        height: 248px;
        background-size: cover;
        min-width: 300px;
        width: 27%;
        cursor: pointer;
        position: relative;
        text-align: left;
        padding: 10px;
        border-radius: 5px;

    }
    .thumb-img path {
        fill: #d0d0d0 !important;
        stroke: #aaaaaa !important;
        stroke-width: 1px !important;
    }
    img, svg {
        vertical-align: middle;
        width: 35px;
    }
    .UploadVideoContainer {
        /* max-width: 800px; */
        /* margin: 50px auto; */
        border-bottom: 1px solid #e8e8e8;
        margin-bottom: 20px;
        margin: 30px 15px;
    }
    .col-form-inner.col-12.col-md-12.col-lg-12.input-video {
        max-width: 800px;
        margin: 10px auto;
        position: relative;
    }
    .uploadVideoIcon {
        vertical-align: middle;
        width: 46px;
        padding: 8px;
        border: 1px solid #e4e4e4;
        border-radius: 10px;
    }
    .infoCollectionVideos {
        text-align: left;
        padding: 0px 20px;
    }
    svg.svg-inline--fa {
        color: silver;
    }
    .infoCollectionVideos p {
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 14px;
    }
    button.editCollections {
        position: absolute;
        top: 25px;
        right: 25px;
    }
    button.editCollections svg {
        transition: all 500ms ease-in-out;
    }
    button.editCollections:hover svg {
        color: #250891;
    }
    .itemCollectionVideo .actions {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        text-align: right;
        padding: 10px;
        background-color: transparent;
        transition: all 500ms ease-in-out;
    }
    button.hoverWhite svg{
        transition: all 500ms ease-in-out;
        font-size: 15px;
    }
    button.hoverWhite:hover svg{
        color: white;
    }
    .itemCollectionVideo p {
        color: #c8c8c8;
        margin: 0;
    }
</style>
<style>
    .v-card.v-theme--light.v-card--density-default.v-card--variant-elevated {
        padding: 35px 0px;
        /* text-align: left; */
    }
    .v-card-title.headline {
        text-align: left !important;
        color: red;
        padding: 0 40px;
    }
    button.v-btn.v-btn--slim.v-theme--light.text-green.darken-1.v-btn--density-default.v-btn--size-default.v-btn--variant-text {
        width: 150px;
        background-color: red;
        /* color: white; */
    }
    .v-card-actions button {
        width: 50%;
        background-color: gray;
        padding: 0 16px;
    }
    .v-overlay__content {
        width: 400px !important;
        max-width: 400px !important;
    }
    .v-card-actions {
        align-items: center;
        display: flex;
        flex: none;
        min-height: 52px;
        padding: 0 40px!important;
    }
    .cancel {
        background-color: white !important;
        border: 1px solid gray;
    }
    .accept {
        background-color: red !important;
        border: 1px solid red;
        color: white!important;
    }
    .contetContainerEditVideo {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
    }
    .col-form-inner.col-12.col-md-7.col-lg-7.freeVideo .v-input__details {
        display: none;
    }
    .actions > button.btn {
        background-color: #21212100 !important;
        color: #250891 !important;
        border: 1px solid #250891 !important;
        font-size: 18px !important;
        font-weight: 100 !important;
        width: 146px;
        text-align: center;
        transition: all 500ms ease-in-out;
    }
    .actions {
        display: flex;
        justify-content: end;
        gap: 10px;
        padding: 0px 15px;
    }
    .actions > button.btn:hover {
        color: white !important;
        background-color: #250891 !important;
    }
    .exludeVideo {
        position: absolute;
        top: 25px!important;
        right: 250px!important;
        cursor: pointer;
        background-color: #ffffff;
        padding: 5px 10px;
        color: #ff0000;
        border-radius: 10px;
    }
    .exludeVideo.edit svg {
        color: red !important;
    }
    input.form-check-input {
        width: 3em !important;
    }
    .form-check-input:checked {
        background-color: #250891!important;
        border-color: #250891!important;
    }
    .col-form-inner.col-12.col-md-7.col-lg-7.freeVideo {
        display: flex;
        flex-wrap: wrap;
    }
    .container.myWeflixEditVideo .itemAtionCollections.form-check.form-switch {
        position: relative;
    }
    .itemAtionCollections.form-check.form-switch {
        padding: 16px 40px;
    }
    img.coverVideo {
        width: 96% !important;
        height: auto;
        margin: 0 auto;
        filter: brightness(0.5);
        border-radius: 10px;
        box-shadow: 0px 0px 10px 1px gray;
    }
    .form-group.imageCoverPreview {
        text-align: center;
        position: relative;
    }
    .info {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
    }
    .info svg {
        font-size: 50px !important;
        color: white !important;
    }
    .container {
        max-width: 100%;
    }
    .titleContainer {
        display: block;
        text-align: left;
        padding: 0px 10px;
        border-bottom: 1px solid #e8e8e8;
        margin: 10px;
        padding-bottom: 10px;
    }
    .form-group.flex {
        justify-content: end;
        align-items: end;
    }
    .container {
        padding: 10px 0px;
        padding-bottom: 50px;
    }
    .CollectionVideos {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px;
        padding: 10px;
    }
    .itemCollectionVideo {
        max-width: 30%;
        height: 248px;
        background-size: cover;
        min-width: 300px;
        width: 27%;
        cursor: pointer;
        position: relative;
        text-align: left;
        padding: 10px;
        border-radius: 5px;

    }
    .thumb-img path {
        fill: #ffffff;
        stroke: #aaaaaa;
        stroke-width: 10px;
    }
    img, svg {
        vertical-align: middle;
        width: 35px;
    }
    .UploadVideoContainer {
        /* max-width: 800px; */
        /* margin: 50px auto; */
        border-bottom: 1px solid #e8e8e8;
        margin-bottom: 20px;
        margin: 30px 15px;
    }
    .col-form-inner.col-12.col-md-12.col-lg-12.input-video {
        max-width: 800px;
        margin: 50px auto;
    }
    .uploadVideoIcon {
        vertical-align: middle;
        width: 46px;
        padding: 8px;
        border: 1px solid #e4e4e4;
        border-radius: 10px;
    }
    .infoCollectionVideos {
        text-align: left;
        padding: 0px 20px;
        display: flex;
    }
    svg.svg-inline--fa {
        color: silver;
    }
    .infoCollectionVideos p {
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 14px;
    }
    button.editCollections {
        position: absolute;
        top: 25px;
        right: 25px;
    }
    button.editCollections svg {
        transition: all 500ms ease-in-out;
    }
    button.editCollections:hover svg {
        color: #250891;
    }
    .itemCollectionVideo .actions {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        text-align: right;
        padding: 10px;
        background-color: transparent;
        transition: all 500ms ease-in-out;
    }
    button.hoverWhite svg{
        transition: all 500ms ease-in-out;
        font-size: 15px;
    }
    button.hoverWhite:hover svg{
        color: white;
    }
    .itemCollectionVideo p {
        color: #c8c8c8;
        margin: 0;
    }
    .UploadVideoContainer.flex {
        display: flex;
        flex-wrap: wrap;
        gap: 0;
    }
    .btn.disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
    .progress-status {
        display: flex;
        align-items: center;
        gap: 8px;
        font-weight: 500;
    }
    
    .progress-status svg {
        width: 16px;
        height: 16px;
    }
    
    .progress-status svg.fa-check-circle {
        color: #28a745;
    }
    
    .progress-status svg.fa-sync {
        color: #250891;
    }
</style>
<style scoped>
.progress-bar {
    width: 100%;
    height: 10px;
    background-color: #e7e7e7;
    border-radius: 10px;
    overflow: hidden;
    margin: 10px 0;
}

.progress-fill {
    height: 100%;
    background-color: #150065;
    transition: width 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.progress-text {
    color: white;
    font-size: 10px;
}
.upload-complete svg {
    color: #150065!important;
}
</style>

<style>
.v-card-title {
    padding: 10px 0px;
}
.video-details h3 {
    font-size: 18px;
}
.video-details p {
    font-size: 13px;
    margin-bottom: 11px;
}
button.v-btn.v-btn--slim.v-theme--light.text-primary.v-btn--density-default.v-btn--size-default.v-btn--variant-text {
    background-color: #21077a;
    color: white;
}
button.v-btn.v-btn--slim.v-theme--light.text-primary.v-btn--density-default.v-btn--size-default.v-btn--variant-text span.v-btn__content {
    color: white!important;
}
.video-thumbnail img {
    width: 100%;
    border-radius: 10px;
    margin-bottom: 10px;
}
.moveVideo {
    position: absolute;
    top: 25px;
    right: 131px;
    background-color: #eef0fc;
    padding: 5px 10px;
    color: #250891;
    border-radius: 5px;
    cursor: pointer;
}
.moveVideo svg.svg-inline--fa {
    color: #250891;
}
.reuseVideo {
    position: absolute;
    top: 25px;
    right: 20px;
    background-color: #eef0fc;
    padding: 5px 10px;
    color: #250891;
    border-radius: 5px;
    cursor: pointer;

}
.reuseVideo svg.svg-inline--fa {
    color: #250891!important;
}
</style>
