<template>
    <div class="container">
        <div class="titleContainer">
            <div class="primaryHeader">
                <h3>Meus anexos</h3>
                <p>Acompanhe e gerencie os arquivos do sistema.
                </p>
            </div>
            <div class="sencodHeader">
                <p>Filtro</p>
                <p>Busca</p>
            </div>
           
            <div class="btnNewFile">
                <button><fa icon="fa-cloud-arrow-up"/>Carregar arquivos</button>
            </div>
        </div>
        
        <div class="attchments">
            <div class="attchmentsItem" v-for="i in 10" :key="i" @click="goToCollection(i)">
                <div class="titleItemCollection">
                    <p>Segunda coleção</p>
                    <small>50kb</small>
                </div>
            </div>
        </div>
       
      </div>
</template>

<script>
export default {
    data() {
        return {
            collections: []
        }
    },
    methods: {
        goToCollection(id) {
            this.$router.push('/admin/colecao/' + id)
        }
    }
}
</script>


<style scoped>
@import url(./global.css);
.container {
    max-width: 100%;
 }
.titleContainer {
    display: flex;
    text-align: left;
    padding: 0px 10px;
    border-bottom: 1px solid #e8e8e8;
    margin: 10px;
    justify-content: space-between;
    align-items: end;
}
.container {
    background-color: white;
    padding: 10px 0px;
    padding-bottom: 50px;
}
.btnNewFile {
    position: absolute;
    top: 15px;
    right: 15px;
}
.btnNewFile button {
    background-color: #1b017c;
    color: white;
    padding: 10px 20px;
    border-radius: 25px;
    display: flex;
    gap: 10px;
}
.sencodHeader {
    display: flex;
    gap: 10px;
}
.attchments {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: space-around;
   
}
.attchmentsItem {
    width: 19%;
    min-height: 100px;
    background: linear-gradient(180deg, rgb(180 180 180) 0%, rgba(150, 150, 150, 0) 68%, rgba(255, 255, 255, 1) 100%);
    border-radius: 5px;
}
</style>