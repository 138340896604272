<template>
    <Content></Content>
</template>

<script>
import Content from '@/components/Student/Dashboard_Unique/Course/content.vue';

export default{
    data(){
        return{

        }
    },
    components:{
        Content
    }
}
</script>