<template>
    <section class="collections theme2 collWeflixLightTheme">
        <Header @toggleModal="toggleOpenDialog" @toggleModalSubscribe="toggleOpenSubscribe"/> 
        <div class="titleCollectionsPage">
            <button class="btn-voltar" @click="$router.go(-1)">
                <fa icon="fa-arrow-left"/> Voltar
            </button>

            <p class="collection-title collDescriptionCourse" style="width: 100%;">Curso</p>
            <h3><span class="collection-title" style="font-size: 60px;padding-right: 11px;">{{nameCollection}}</span>
                <span class="collection-description" style="display: block;font-size: 15px;margin: 10px 0px;">{{stripHtml(collection.shortDescription)}}</span>
            </h3>
            <p class="videosInfoNumHours collDescriptionCourse">{{collection.totalVideos ? collection.totalVideos : 0}} vídeos | {{collection.totalTime ? formatarTempo(collection.totalTime) : '00:00'}} h</p>
        </div>
        <hr>
        <div class="itemVideoCollections" ref="videoContainer">  
            <div class="videosCollections list" v-for="video in allLessons" :key="video.id" @click="goToPlayer(video.id, video.sample)">
                <div class="videoCollections">
                    <img v-if="video.finalVideoThumbnail == null" src="@/assets/Weflix/default-video-thumbnail.jpg" alt="Imagem padrão">
                    <img v-else :src="video.finalVideoThumbnail"/>
                </div>
                <div class="titleCollections"> 
                    <h3>{{video.title ? video.title : 'Sem título'}}</h3>
                </div>
                <div class="lockBlock" v-if="!userPortalWeflix">
                    <fa icon="lock" class="lockIcon" />
                    <span class="textPrivateVideo">Conteúdo Exclusivo</span>
                </div>
                <div class="degradeCarousel__item">
                    <fa icon="play" class="playIcon collection-play-icon"/>
                </div>
            </div>
        </div>
        <div v-if="loading" class="loader-container">
            <div class="loader"></div>
        </div>

        <Footer/>
    </section>
    <Subscribe :show="openDialogSubscribe" @toggleModal="toggleOpenSubscribe"/>
    <Login :show="openDialogLogin" @toggleModal="toggleOpenDialog"/>

</template>
<script>
import { Carousel, Navigation, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
import Header from '@/components/Weflix/LigthTheme/Header.vue';
import Footer from '@/components/Weflix/LigthTheme/Footer.vue';
import Subscribe from '@/components/Weflix/Subscribe.vue'
import Login from '@/components/Weflix/Login.vue'
import ApiWebsite from '@/utils/Weflix/Website/index';
import { mapState } from 'vuex';

export default {
    data(){
        return{
            titleCollection:['Primeire Coleção', 'Segunda Coleção', 'Terceira Coleção'],
            openDialogLogin:false,
            openDialogSubscribe:false,
            collection: [],
            allLessons: [],
            nameCollection:'',
           baseUrl: process.env.NODE_ENV === 'development' ? process.env.VUE_APP_BASE_URL_HOMOLOGATION  : window.location.origin,

            currentPage: 0,
            loading: false,
            hasMorePages: true,
            observer: null
        }
    },
    computed:{
        ...mapState(['userPortalWeflix', 'finalWebsite']),
        websiteColor() {
            return this.finalWebsite?.color || '#4CAF50' // Cor padrão caso finalWebsite.color seja null
        }
    },
    components: {
        Carousel,
        Slide,
        Navigation,
        Header,
        Footer,
        Subscribe,
        Login
    },
    mounted() {
        this.setupInfiniteScroll();
    },
    beforeUnmount() {
        if (this.observer) {
            this.observer.disconnect();
        }
    },
    methods:{
        setupInfiniteScroll() {
            const options = {
                root: null,
                rootMargin: '0px',
                threshold: 0.5
            };

            this.observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting && this.hasMorePages && !this.loading) {
                        this.currentPage++;
                        this.getVideosByCollections(this.currentPage);
                    }
                });
            }, options);

            const target = document.createElement('div');
            target.id = 'scroll-trigger';
            this.$refs.videoContainer.appendChild(target);
            this.observer.observe(target);
        },
        toggleOpenDialog(){
            this.openDialogLogin = !this.openDialogLogin;
        },
        toggleOpenSubscribe(){
            this.openDialogSubscribe = !this.openDialogSubscribe;
        },
        goToPlayer(id, sample){
            if(this.userPortalWeflix){
                if(sample == 1){
                    this.$router.push('/player-curso/'+id)
                } else {
                    if(this.userPortalWeflix.user.status == 1){
                        this.$router.push('/player-curso/'+id)
                    } else if(this.userPortalWeflix.user.status != 1){
                        this.toggleOpenSubscribe();
                    }
                }    
            } else {
                this.toggleOpenDialog();
            }
        },
        stripHtml(html) {
            if (!html) return '';
            return html.replace(/<[^>]*>?/g, '');
        },
        async getVideosByCollections(page = 0){
            this.loading = true;
            const id = this.$route.params.slug;
            const aux1 = this.baseUrl.replace(/\/$/, '');
            const aux = aux1.split('/videos-colecao')[0];
          
            const payload = {
                "url": aux
            }
            try {
                const response = await ApiWebsite.getVideosByCollection(id, payload, page);
                this.collection = response;
                this.nameCollection = response.comercialName;

                if (page === 0) {
                    this.allLessons = response.lessons;
                } else {
                    this.allLessons = [...this.allLessons, ...response.lessons];
                }

                // Verifica se há mais páginas para carregar
                if (response.lessons.length < 100) {
                    this.hasMorePages = false;
                }

            } catch (error) {
                console.error(error);
            } finally {
                this.loading = false;
            }
        },
        formatarTempo(tempo){
            const horas = Math.floor(tempo / 3600);
            return `${horas}`;
        }
    },
    created(){
        this.getVideosByCollections();
    }   
}
</script>
<style scoped>
.collWeflixLightTheme .videosInfoNumHours {
    color: white !important;
}
.theme2 .videosCollections {
    width: 24.5%;
    cursor: pointer;
    position: relative;
}
.titleCollectionsPage p.collection-title {
    color: white !important;
    font-size: 30px;
    margin: 0;
}
.titleCollectionsPage p {
    color: white;
    font-size: 15px;
    margin: 0;
}
.collections.theme2 {
    background-color: white;
    padding: 20px 0px 50px 0px;
}
.lockBlock {
    width: 100%;
    position: absolute;
    top: 0;
    right: -10px;
    opacity: 0;
}
.videosCollections:hover .lockBlock,
.videosCollections:hover span.textPrivateVideo{
    opacity: 1;
}
span.textPrivateVideo {
    color: #fff;
    font-size: 11px;
    position: absolute;
    right: 46px;
    top: 10px;
    opacity: 0;
    transition: all .5s linear;
    z-index: 2;
}
.titleCollections {
    margin: 10px 10px;
}
.titleCollections h3{
    font-size: 13px;
    text-align: left;
}
section.collections .titleCollectionsPage {
    max-width: 1500px;
    margin: 0 auto;
    margin-top: 105px;
    text-align: left;
    height: auto;
    padding: 20px;
    border-radius: 5px;
    position: relative;
    background-color: v-bind('websiteColor');
    flex-wrap: wrap;
}
.titleCollectionsPage h3 {
    color: rgb(255 255 255) !important;
    font-weight: 700;
}
.itemVideoCollections {
    display: flex;
    flex-wrap: wrap;
    max-width: 1500px;
    margin: 30px auto;
    gap: 20px 10px;
    justify-content: center;
}
.videosCollections {
    width: 32.5%;
    cursor: pointer;
    position: relative;
}
.videosCollections.list:hover .degradeCarousel__item {
    opacity: 1;
}
section.collections hr {
    color: white;
}
.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}
.loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
/* Mobile View Styles */
.mobile-view {
    display: none;
}
@media (min-width: 601px) {
    .videosInfoNumHours {
        position: absolute;
    }
    p.videosInfoNumHours {
        bottom: 30px;
        right: 30px;
    }
}
@media (max-width: 600px) {
    span.collection-title {
        font-size: 40px !important;
    }
    span.collection-description {
        font-size: 13px !important;
    }
    .desktop-view {
        display: none;
    }
    .titleCollectionsPage p {
        color: white !important;
        font-size: 15px;
        margin: 0;
    }
    .mobile-view {
        display: block;
        padding: 0 20px;
    }

    .mobile-view .videosCollections {
        width: 100%;
        margin-bottom: 20px;
    }

    .mobile-view .videoCollections img {
        width: 100%;
        height: 200px;
        object-fit: cover;
    }
}

</style>
<style >

section.collections .titleCollectionsPage {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}
.titleCollectionsPage p{
    color: white;
    margin: 0;
}
.collections .videoCollections.list img {
    width: 100%;
    height: auto;
    border-radius: 10px;
}
@media (max-width: 600px) {
    .titleCollectionsPage p.collection-title{
        color: white !important;
        font-size: 25px;
        margin: 0;
    }
    .pagination {
        margin-bottom: 30px;
    }
    .col-1-videos img {
        height: 160px;
    }
    .col-2-videos img {
        height: 76px;
    }
    .collWeflixLightTheme .titleCollectionsPage p{
        color: #7d7d7d!important;
    }
    .containerCollections .itensCollections {
        min-height: 200px;
    }
    section.collections .titleCollectionsPage {
        display: block;
        max-width: 100%;
    }
    .itemVideoCollections {
        padding: 10px 20px;
    }
    .videosCollections {
        width: 100%!important;
        cursor: pointer;
    }
    .videoCollections img {
        width: 100%;
        height: 250px;
    }
    
}
.videoCollections img {
    width: 100%;
    height: 200px;
    border-radius: 5px;
}
.videosCollections {
    transition: all 0.3s ease;
}
.theme2 .titleCollections{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    color: #858585;
}
.theme2 .degradeCarousel__item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fbfbfb7d;
    height: 100%;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.collection-play-icon {
    color: #4CAF50;
}
svg.svg-inline--fa.fa-play.playIcon.collection-play-icon path {
    fill: v-bind('websiteColor');
}
svg.svg-inline--fa.fa-play.playIcon.collection-play-icon {
    margin-top: -35px;
    border: 1px dashed v-bind('websiteColor');
    padding: 10px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
}
</style>