import { createRouter, createWebHistory } from "vue-router";
import store from '../store/';
import axios from 'axios';

const config = require('../config/environment.json');

const api = axios.create({
  baseURL: config.api.baseURL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Administrator
import Courses from '../Views/Weeduc/Admin/Courses.vue';
import Panel from '../Views/Weeduc/Admin/Panel';
import Faq from '../Views/Weeduc/Admin/Faq';
import Forum from '../Views/Weeduc/Admin/Forum';
import Login from '../Views/Weeduc/Login';
import ForgotPassword from '../Views/Weeduc/ForgotPassword.vue';
import ResetPassword from "../Views/Weeduc/ResetPassword.vue";
import UsersCompomnent from '../Views/Weeduc/Admin/Users';
import CreateCourse from '../Views/Weeduc/Admin/CreateCourse';
import CreateVideo from '../Views/Weeduc/Admin/CreateVideo';
import CreateQuiz from '../Views/Weeduc/Admin/CreateQuiz';
import CreateFile from '../Views/Weeduc/Admin/CreateFile.vue';
import EditQuiz from '../Views/Weeduc/Admin/EditQuiz';
import EditVideoWeeduc from '../Views/Weeduc/Admin/EditVideo';
import EditFile from '../Views/Weeduc/Admin/EditFile';
import Dashboard from '../Views/Weeduc/Admin/Dashboard';
import ViewCourse from '../Views/Weeduc/Admin/Course.vue';
import SiteConfig from '../Views/Weeduc/Admin/Site';
import Cupons from '../Views/Weeduc/Admin/Cupons';
import Warnings from '../Views/Weeduc/Admin/Warnings';
import NewCourse from '../Views/Weeduc/Admin/CreateCourse';
import EditDoubt from '../Views/Weeduc/Admin/EditDoubt';
import EditCourse from '../Views/Weeduc/Admin/EditCourse';
import NewUserWeeduc from '../Views/Weeduc/Admin/NewUser';
import EditUserWeeduc from '../Views/Weeduc/Admin/EditUser';
import ResetPasswordUser from '../Views/Weeduc/ResetPasswordUser';

//Novas Páginas weeduc
import Doubt from '../Views/Weeduc/Admin/Doubt';
import ForumView from '../Views/Weeduc/Admin/ForumView';

// Quiz
// import CreateQuiz from '../components/Admin/Quiz/createquiz';
// import EditQuiz from '../components/Admin/Quiz/editquiz';
// import ViewQuuestions from '../components/Admin/Quiz/viewquestions';

// Reports
import Reports from '../Views/Weeduc/Admin/Reports';

import ReportDoubts from '../components/Admin/Reports/doubts';
import ReportForum from '../components/Admin/Reports/forum';
import ReportUsers from '../components/Admin/Reports/users';
import ReportWarnings from '../components/Admin/Reports/warnings';
import ReportEnrols from '../components/Admin/Reports/enrols';
import ReportCourses from '../components/Admin/Reports/courses';

// Panel Flix Courses
import Panel_Unique from '../Views/Weeduc/Student/Panel_Unique.vue';
import CourseStudent from '../Views/Weeduc/Student/ViewCourse.vue';
import Notification from '../Views/Weeduc/Student/Notification.vue';
import Inquiry from '../Views/Weeduc/Student/Inquiry.vue';
import CoursesStudent from '../Views/Weeduc/Student/Courses.vue';
import ForumStudent from '../Views/Weeduc/Student/Forum.vue';
import UserPerfil from '../Views/Weeduc/Student/User.vue'

// Site
import Site from '@/Views/Weeduc/Site/Site.vue';
import Product from '@/Views/Weeduc/Site/Product';
import Checkout from '@/Views/Weeduc/Site/Checkout';
import Success from '@/Views/Weeduc/Site/Success';
import LoginUser from '@/Views/Weeduc/LoginUser.vue';

//Weflix

import SiteWeflix from '@/Views/Weflix/Site.vue';
import Site_Theme2 from '@/Views/Weflix/LigthTheme/Site.vue';
import Videos from '@/Views/Weflix/Videos.vue';
import Collections from "@/Views/Weflix/Collections.vue";
import Terms from "@/Views/Weflix/Terms.vue";
import Contact from "@/Views/Weflix/Contact.vue";
import About from "@/Views/Weflix/About.vue";
import SuccessWeflix from "@/Views/Weflix/Success.vue";
import CollectionsCourse from "@/Views/Weflix/LigthTheme/Collections.vue";
import CollectionVideosTheme2 from "@/Views/Weflix/LigthTheme/CollectionVideos.vue";
import VideosPlayerCourse from "@/Views/Weflix/LigthTheme/VideosPlayer.vue";
import AboutTheme2 from "@/Views/Weflix/LigthTheme/About.vue";
import ResetPasswordWeflix from "@/Views/Weflix/ResetPassword.vue";
import SuccessCollection from "@/Views/Weflix/SuccessCollection.vue";


//Weflix Admin

import DashboardWeflix from '@/Views/Weflix/Adm/Dashboard.vue';
import PanelWeflix from '@/Views/Weflix/Adm/Panel.vue';
import CollectionsWeflix from '@/Views/Weflix/Adm/Collections.vue';
import Layout from '@/Views/Weflix/Adm/Layout.vue';
import Settings from "@/Views/Weflix/Adm/Settings.vue";
import Payment from "@/Views/Weflix/Adm/Payment.vue";
import Account from "@/Views/Weflix/Adm/Account.vue";
import CollectionWeflix from "@/Views/Weflix/Adm/Collection.vue";
import EditVideo from "@/Views/Weflix/Adm/EditVideo.vue";
import NewCollection from "@/Views/Weflix/Adm/NewCollection.vue";
import EditCollection from "@/Views/Weflix/Adm/EditCollections.vue";
import Attachment from "@/Views/Weflix/Adm/Attachment.vue";
import NewPlan from "@/Views/Weflix/Adm/NewPlan.vue";
import NewCupon from "@/Views/Weflix/Adm/NewCupon.vue";
import NewVideo from "@/Views/Weflix/Adm/NewVideo.vue";
import EditCupon from "@/Views/Weflix/Adm/EditCupon.vue";
import AccountWeflix from "@/Views/Weflix/Adm/Account.vue";
import FinalcialReport from "@/Views/Weflix/Adm/FinalcialReport.vue";
import ClientReport from "@/Views/Weflix/Adm/ClientReport.vue";
import EditPlan from "@/Views/Weflix/Adm/EditPlan.vue";
import Users from "@/Views/Weflix/Adm/Users.vue";
import Profile from "@/Views/Weflix/Adm/Profile.vue";
import NewUser from "@/Views/Weflix/Adm/NewUser.vue";
import CheckoutWeflix from "@/Views/Weflix/Checkout.vue";
import CheckoutByCollection from "@/Views/Weflix/CheckoutByCollection.vue";
import NewSeason from "@/Views/Weflix/Adm/NewSeason.vue";
import MyAccount from "@/Views/Weflix/MyAccount.vue";
import CollectionVideos from "@/Views/Weflix/CollectionVideos.vue";
import EditUser from "@/Views/Weflix/Adm/EditUser.vue";
import ConfirmationAccount from "@/Views/Weflix/ConfirmationAccount.vue";
import MyAccountLight from "@/Views/Weflix/LigthTheme/MyAccount.vue";
import UserPayments from "@/Views/Weflix/Adm/UserPayments.vue";

//SuperADmin
import SuperAdmin from "@/Views/Weflix/SuperAdmin.vue";
import LoginSuperAdmin from "@/Views/Weflix/LoginSuperAdmin.vue";
import SuperAdminLayout from "@/Views/Weflix/SuperAdminLayout.vue";
import SuperAdminListCompany from "@/Views/Weflix/SuperAdminListCompany.vue";
import CompanySuperAdmin from "@/Views/Weflix/CompanySuperAdmin.vue";

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: { requiresAuth: false },
        beforeEnter: (to, from, next) => {
            if (window.location.origin.includes('localhost')) {
                next();
            // } else if (window.location.origin !== 'https://app.weflix.com.br') {
            //     window.location.href = 'https://app.weflix.com.br/login';
            // }
            } else {
                next();
            }
        }
    },
    {
        path: '/forgotpassword/',
        name: 'EsqueciSenha',
        component: ForgotPassword,
        meta: { requiresAuth: false },
    },
    {
        path: '/novasenha/:slug',
        name: 'NovaSenha',
        component: ResetPassword,
        meta: { requiresAuth: false },
    },
    {
        path: '/resetpassworduser',
        name: 'ResetPasswordUser',
        component: ResetPasswordUser,
        meta: { requiresAuth: false },
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: { requiresAuth: true, role: 'admin' },
        children: [
            { path: 'painel', component: Panel, meta: { role: 'admin' } },
            { path: 'cursos', component: Courses, meta: { role: 'admin' } },
            { path: 'curso', component: ViewCourse, meta: { role: 'admin' } },
            { path: 'usuarios', component: UsersCompomnent, meta: { role: 'admin' } },
            { path: 'curso/criar', component: CreateCourse, meta: { role: 'admin' } },
            { path: 'duvidas', component: Faq, meta: { role: 'admin' } },
            { path: 'forum', component: Forum, meta: { role: 'admin' } },
            { path: 'avisos', component: Warnings, meta: { role: 'admin' } },
            { path: 'site', component: SiteConfig, meta: { role: 'admin' } },
            { path: 'cupons', component: Cupons, meta: { role: 'admin' } },
            { path: 'relatorios', component: Reports, meta: { role: 'admin' } },
            { path: 'relatorios/duvidas', component: ReportDoubts, meta: { role: 'admin' } },
            { path: 'relatorios/foruns', component: ReportForum, meta: { role: 'admin' } },
            { path: 'relatorios/avisos', component: ReportWarnings, meta: { role: 'admin' } },
            { path: 'relatorios/usuarios', component: ReportUsers, meta: { role: 'admin' } },
            // { path: 'configuracoes', component: Configuration, meta: { role: 'admin' } },
            // { path: 'criar/quiz/', component: CreateQuiz, meta: { role: 'admin' } },
            // { path: 'editar/quiz/:slug', component: EditQuiz, meta: { role: 'admin' } },
            // { path: 'quiz/questoes/:slug', component: ViewQuuestions, meta: { role: 'admin' } },
            { path: 'relatorios/vendas', component: ReportEnrols, meta: { role: 'admin' } },
            { path: 'relatorios/cursos', component: ReportCourses, meta: { role: 'admin' } },
            // { path: 'duvida/:slug', component: EditDoubt, meta: { role: 'admin' } },
            { path: 'novo-curso', component: NewCourse, meta: { role: 'admin' } },
            { path: 'editar-curso/:slug', component: EditCourse, meta: { role: 'admin' } },
            { path: 'novo-video', component: CreateVideo, meta: { role: 'admin' } },
            { path: 'novo-quiz', component: CreateQuiz, meta: { role: 'admin' } },
            { path: 'novo-arquivo', component: CreateFile, meta: { role: 'admin' } },
            { path: 'editar-quiz/:slug', component: EditQuiz, meta: { role: 'admin' } },
            { path: 'editar-video/:slug', component: EditVideoWeeduc, meta: { role: 'admin' } },
            { path: 'editar-arquivo/:slug', component: EditFile, meta: { role: 'admin' } },
            { path: 'duvida/:slug', component: Doubt, meta: { role: 'admin' } },
            { path: 'forum/:slug', component: ForumView, meta: { role: 'admin' } },
            { path: 'novo-usuario', component: NewUserWeeduc, meta: { role: 'admin' } },
            { path: 'editar-usuario/:slug', component: EditUserWeeduc, meta: { role: 'admin' } },
        ]
    },
    {
        path: '/aluno',
        component: Panel_Unique,
        meta: { requiresAuth: true, role: ['student', 'admin'] },
        redirect: '/aluno/dashboard',
        children: [
            { path: 'dashboard', component: CoursesStudent, meta: { role: ['student', 'admin'] } },
            { path: 'perfil', component: UserPerfil, meta: { role: ['student', 'admin'] } },
            { path: 'notificacoes', component: Notification, meta:{ role: ['student', 'admin'] } },
            { path: 'duvidas', component: Inquiry, meta: { role: ['student', 'admin'] } },
            { path: 'forum', component: ForumStudent, meta: { role: ['student', 'admin'] } },
        ]
    },
    {
        path: '/aluno/curso/:slug',
        component: CourseStudent,
        meta: { requiresAuth: true, role: ['student', 'admin'] },
    },
    {
        path: '/:slug?',
        name: 'Site',
        meta: { requiresAuth: false },
        component: () => {
            return new Promise((resolve) => {
                api.post('/ecommerce/logo', {
                    url: window.location.origin === 'http://localhost:8080' ?  process.env.VUE_APP_BASE_URL_HOMOLOGATION : window.location.origin
                })
                .then(response => {
                    store.dispatch('storeTypeSystem', { typeSystem: response.data.typeSystem });
                    if(window.location.origin == 'http://localhost:8080'){
                        resolve(SiteWeflix)
                    } else if (response.data.typeSystem == 1) {
                        resolve(Site);
                    } else if (response.data.typeSystem == 2) {
                        if (response.data.typeLayout == 0) {
                            resolve(SiteWeflix);
                        } else {
                            resolve(Site_Theme2);
                        }
                    } else {
                        resolve(SiteWeflix);
                    }
                })
                .catch(() => {
                    resolve(Site);
                });
            });
        },
        props: true
    },
    {
        path: '/colecoes-cursos',
        name: 'CollectionsCourse',
        component: CollectionsCourse,
        meta: { requiresAuth: false },
    },
    {
        path: '/colecoes-videos/themeLight/:slug',
        name: 'CollectionVideosTheme2',
        component: CollectionVideosTheme2,
        meta: { requiresAuth: false },
    },
    {
        path: '/produto/:slug?',
        name: 'Product',
        component: Product,
        meta: { requiresAuth: false },
    },
    {
        path: '/checkout',
        name: 'Checkout',
        component: Checkout,
        meta: { requiresAuth: false },
    },
    {
        path: '/sucesso',
        name: 'Sucesso',
        component: Success,
        meta: { requiresAuth: false },

    },
    {
        path: '/minha-conta-light',
        name: 'MyAccountLight',
        component: MyAccountLight,
        meta: { requiresAuth: false },
    },
    {
        path: '/portal',
        name: 'LoginUser',
        component: LoginUser,
        meta: { requiresAuth: false },
    },
    // //Wflix Site
    // {
    //     path: '/weflix/:slug?',
    //     name: 'Weflix',
    //     component: SiteWeflix,
    //     meta: { requiresAuth: false },
    // },
    {
        path: '/player/:slug',
        name: 'Player',
        component: Videos,
        meta: { requiresAuth: false },
    },
      {
        path: '/colecoes',
        name: 'Collections',
        component: Collections,
        meta: { requiresAuth: false },
    },
    {
        path: '/player-curso/:slug',
        name: 'VideosPlayerCourse',
        component: VideosPlayerCourse,
        meta: { requiresAuth: false },
    },
    {
        path: '/sobre',
        name: 'AboutTheme2',
        component: AboutTheme2,
        meta: { requiresAuth: false },
    },
    {
        path: '/termos-de-uso',
        name: 'Terms',
        component: Terms,
        meta: { requiresAuth: false },
    },
    {
        path: '/contato',
        name: 'Contact',
        component: Contact,
        meta: { requiresAuth: false },
    },
    {
        path: '/quem-somos',
        name: 'About',
        component: About,
        meta: { requiresAuth: false },
    },
    {
        path: '/checkoutweflix',
        name: 'CheckoutWeflix',
        component: CheckoutWeflix,
        meta: { requiresAuth: false },
    },
    {
        path: '/checkout-collection-weflix',
        name: 'CheckoutByCollection',
        component: CheckoutByCollection,
        meta: { requiresAuth: false },
    },
    {
        path: '/sucesso-colecao',
        name: 'SuccessCollection',
        component: SuccessCollection,
        meta: { requiresAuth: false },
        beforeEnter: (to, from, next) => {
            const store = require('../store').default;
            if (!store.state.dataPaymentWeflix || store.state.dataPaymentWeflix.length === 0) {
                next('/');
            } else {
                next();
            }
        }
    },
    {
        path: '/minha-conta',
        name: 'MyAccount',
        component: MyAccount,
        meta: { requiresAuth: false },
    },
    {
        path: '/videos-colecao/:slug',
        name: 'CollectionVideos',
        component: CollectionVideos,
        meta: { requiresAuth: false },
    },
    {
        path: '/ecommerce/ativacao',
        name: 'ConfirmationAccount',
        component: ConfirmationAccount,
        meta: { requiresAuth: false },
    },
    {
        path: '/super-admin',
        name: 'SuperAdmin',
        component: SuperAdmin,
        meta: { requiresSuperAdmin: true },
    },
    {
        path: '/personalizar-empresa/:slug',
        name: 'SuperAdminLayout',
        component: SuperAdminLayout,
        meta: { requiresSuperAdmin: false },
    },
    {
        path: '/editar-empresa/:slug',
        name: 'CompanySuperAdmin',
        component: CompanySuperAdmin,
        meta: { requiresSuperAdmin: false },
    },
    {
        path: '/admin',
        name: 'LoginSuperAdmin',
        component: LoginSuperAdmin,
        meta: { requiresAuth: false },
    },
    {
        path: '/admin/empresas',
        name: 'SuperAdminListCompany',
        component: SuperAdminListCompany,
        meta: { requiresSuperAdmin: false },
    },
    {
        path: '/sucessoWeflix',
        name: 'SuccessWeflix',
        component: SuccessWeflix,
        meta: { requiresAuth: false },
        beforeEnter: (to, from, next) => {
            const store = require('../store').default;
            if (!store.state.itemCheckoutWeflix || store.state.itemCheckoutWeflix.length === 0) {
                next('/');
            } else {
                next();
            }
        }
    },
    {
        path: '/redefinir-senha/:slug',
        name: 'ResetPasswordWeflix',
        component: ResetPasswordWeflix,
        meta: { requiresAuth: false },
    },
    {
        path: '/admin',
        name: 'AdminWeflix',
        component: DashboardWeflix,
        meta: { requiresAuth: false },
        children: [
            {   path: 'painel',
                component: PanelWeflix,
            },
            {   path: 'colecoes',
                component: CollectionsWeflix,
            },
            {
                path: 'pagamentos-usuario/:slug',
                component: UserPayments,
            },
            {
                path: 'personalizar',
                component: Layout,
            },
            {
                path: 'configuracoes',
                component: Settings,
            },
            {
                path: 'pagamentos',
                component: Payment,
            },
            {
                path: 'conta',
                component: Account,
            },
            {
                path: 'colecao/:slug',
                component: CollectionWeflix,
            },
            {
                path: 'video/:slug',
                component: EditVideo,
            },
            {
                path: 'nova-colecao',
                component: NewCollection,
            },
            {
                path: 'editar-colecao/:slug',
                component: EditCollection,
            },
            {
                path: 'anexos',
                component: Attachment,

            },
            {
                path: 'novo-plano',
                component: NewPlan,
            },
            {
                path: 'novo-cupom',
                component: NewCupon,
            },
            {
                path: 'novo-video',
                component: NewVideo,
            },
            {
                path: 'editar-cupom/:slug',
                component: EditCupon,
            },
            {
                path: 'conta',
                component: AccountWeflix,
            },
            {
                path: 'relatorios/financas',
                component: FinalcialReport,
            },
            {
                path: 'relatorios/clientes',
                component: ClientReport,
            },
            {
                path: 'editar-plano/:slug',
                component: EditPlan,
            },
            {
                path: 'usuarios',
                component: Users,
            },
            {
                path: 'perfil',
                component: Profile,
            },
            {
                path: 'novo-usuario',
                component: NewUser,
            },
            {
                path: 'nova-temporada',
                component: NewSeason,
            },
            {
                path: 'editar-usuario/:slug',
                component: EditUser,
            },
        ]
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});
// Guarda de rota global que é executada antes de cada navegação
router.beforeEach((to, from, next) => {
    // Verifica se a rota requer autenticação checando a propriedade meta.requiresAuth
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        // Se não houver token no store, redireciona para o login
        if (store.state.token == null) {
            next('/login');
        } else {
            // Define o papel do usuário baseado no typeId
            // Se typeId for 1 ou 2, é admin, caso contrário é student
            const userRole = store.state.typeId == 2  || store.state.typeId == 1 ? 'admin' : 'student';
            
            // Verifica se a rota tem restrição de papel e se o usuário tem permissão
            if (to.matched.some((record) => record.meta.role && !record.meta.role.includes(userRole))) {
                next('/login'); // Redireciona para login se não tiver permissão
            } else {
                next(); // Permite a navegação se tiver permissão
            }
        }
    // Verifica se a rota requer super admin
    } else if (to.matched.some((record) => record.meta.requiresSuperAdmin)) {
        // Se não houver super admin no store, redireciona para /admin
        if (store.state.userSuperAdmin == null) {
            next('/admin');
        } else {
            next(); // Permite a navegação se for super admin
        }
    } else {
        next(); // Permite a navegação para rotas sem restrições
    }
});

export default router;
