<template>
    <header class="siteTheme2">
        <div class="header">
            <div class="lgooContentCenter">
                <router-link to="/" class="logo" :class="[activeSearch ? 'active':'']">
                    <img :src="finalWebsite?.logoFinal" alt="Logo Weflix" style="width: 120px;height: 60px;    padding: 10px;" />
                </router-link>
            </div>
            <div class="contentHeaderMiddle">
                <div class="search" :class="[activeSearch ? 'active':'']">
                    <input type="text" v-model="searchQuery" @input="getSearchCollection">
                    <button class="btnCloseSearch" @click="toggleSearch" v-show="activeSearch"><fa icon="fa-xmark"/></button>
                    <button class="btnSearch" @click="toggleSearch"><fa icon="fa-magnifying-glass"/></button>
                </div>
                <p @click="toggleModal" class="login" v-if="!userPortalWeflix">Entrar</p>

                <div class="menuMobile" @click="showMenuMobile = !showMenuMobile" :class="[activeSearch ? 'active':'']" v-if="userPortalWeflix">
                    <fa icon="fa-user"/>
                    <fa icon="fa-chevron-down" :class="[showMenuMobile ? 'rotate':'']"/>
                </div>
            </div>
        </div>
        <div class="loggedInfo" v-if="userPortalWeflix ">
            <div class="menuMobileItens" :class="[showMenuMobile ? 'active':'']">
                <router-link to="/minha-conta-light" class="linkMenu"><fa icon="fa-user"/>Minha conta</router-link>
                <p class="linkMenu" @click="logout"><fa icon="fa-power-off"/>Sair</p>
            </div>
        </div>
    </header>
    <div class="resultSearch" :class="[hasReadyResult ? 'active':'']">
        <div class="resultSearchItens">
            <div class="tabs">
                <button :class="['tab-btn', activeTab === 'collections' ? 'active' : '']" 
                        @click="activeTab = 'collections'">
                    Coleções
                </button>
                <button :class="['tab-btn', activeTab === 'videos' ? 'active' : '']" 
                        @click="activeTab = 'videos'">
                    Vídeos
                </button>
            </div>

            <div v-if="isLoading" class="loader-container">
                <div class="loader"></div>
            </div>

            <div class="tab-content" v-else-if="resultSearch">
                <div v-show="activeTab === 'collections'" class="grid-container">
                    <div class="grid-item" v-for="item in collectionsResult" :key="item.id" v-show="collectionsResult">
                        <router-link :to="`/colecoes-videos/themeLight/${item.id}`" @click="removeOverflow">
                            <div class="item-content">
                                <img v-if="item.imageFinalPath == null" src="@/assets/Weflix/default-video-thumbnail.jpg" alt="Imagem padrão">
                                <img v-else :src="item.imageFinalPath" alt="Capa do vídeo">
                                <p>{{ item.comercialName }}</p>
                            </div>
                        </router-link>
                    </div>
                    <div class="notFound" v-show="!collectionsResult">
                        <p>Nenhum resultado encontrado</p>
                    </div>
                </div>

                <div v-show="activeTab === 'videos'" class="grid-container">
                    <div class="grid-item" v-show="videosResult.length > 0" v-for="item in videosResult" :key="item.id">
                        <div @click="validateUserAccess(item.id)">
                            <div class="item-content">
                                <img v-if="item.finalVideoThumbnail == null" src="@/assets/Weflix/default-video-thumbnail.jpg" alt="Imagem padrão">
                                <img v-else :src="item.finalVideoThumbnail" alt="Capa do vídeo">
                                <p>{{ item.title }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="notFound" v-show="videosResult.length == 0">
                        <p>Nenhum resultado encontrado</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <section class="menu-mobile-bottom">
        <router-link to="/" class="menu-item">
            <fa icon="fa-home"/>
            <span>Início</span>
        </router-link>
        
        <router-link to="/colecoes-cursos" class="menu-item">
            <fa icon="fa-book"/>
            <span>Cursos</span>
        </router-link>

        <div class="menu-item" @click="toggleSearch">
            <fa icon="fa-search"/>
            <span>Buscar</span>
        </div>

        <div class="menu-item" @click="toggleModal" v-if="!userPortalWeflix">
            <fa icon="fa-user"/>
            <span>Entrar</span>
        </div>
    </section>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ApiWebsite from '@/utils/Weflix/Website/index';
import ApiPayment from '@/utils/Weflix/Website/payment';

export default {
    data(){
        return{
            activeSearch:false,
            logo: null,
            description: null,
            showMenuMobile: false,
            searchQuery: '',
            hasReadyResult: false,
            resultSearch: null,
            collectionsResult: null,
            videosResult: null,
            activeTab: 'collections',
            isLoading: false,
           baseUrl: process.env.NODE_ENV === 'development' ? process.env.VUE_APP_BASE_URL_HOMOLOGATION  : window.location.origin,

        }
    },
    computed:{
        ...mapState(['finalWebsite','userPortalWeflix', 'trialWeflix', 'activeTrial']),
    },
    methods:{
        ...mapActions(['logoutPortalWeflix', 'storeActiveTrial']),
        validateUserAccess(videoId) {
            if (!this.userPortalWeflix) {
                this.toggleModal();
                return;
            }
            
            if (!this.activeTrial && !this.userPortalWeflix.subscriber) {
                this.toggleModalSubscribe();
                return;
            }
            
            this.$router.push(`/player-curso/${videoId}`);
            this.removeOverflow();
        },
        toggleModal(){
           this.$emit('toggleModal');
        },
        toggleModalSubscribe(){
           this.$emit('toggleModalSubscribe');
        },
        async getSearchCollection() {
            this.hasReadyResult = true;
            this.isLoading = true;
            const url = this.baseUrl.replace(/\/$/, '');
            const payload = {
                "url": url,
                "search": this.searchQuery
            }
           try {
                const response = await ApiWebsite.serachCollectionVideo(payload);
                this.hasReadyResult = true;
                this.resultSearch = response;
                this.collectionsResult = response.courses;
                this.videosResult = response.lessons;
                this.toggleBodyOverflow();
                this.lastSearch = this.searchQuery; // Armazenando última pesquisa
           } catch (error) {
                console.error('Erro ao obter dados da pesquisa:', error);
                this.hasReadyResult = false;
                this.resultSearch = null;
                this.collectionsResult = [];
                this.videosResult = [];
           } finally {
                this.isLoading = false;
           }
        },
        toggleSearch() {
            this.activeSearch = !this.activeSearch;
            this.hasReadyResult = false;
            this.searchQuery = '';
            this.collectionsResult = [];
            this.videosResult = [];
            this.toggleBodyOverflow();
        },
        toggleBodyOverflow() {
            if (this.hasReadyResult) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = '';
            }
        },
        logout() {
            this.logoutPortalWeflix();
            this.$router.push('/');
        },
        removeOverflow(){
            document.body.style.overflow = '';
        },
        async activeTrialUser(){
            try {
                const response = await ApiPayment.activeTrial(this.userPortalWeflix.acessToken);
                this.$store.dispatch('storeActiveTrial', {activeTrial: true});
                alert('Trial ativado com sucesso');
            } catch (error) {
                console.error('Erro ao ativar trial:', error);
            }
        }
    },
}
</script>

<style scoped>
.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
}

.loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.grid-item > div {
    text-decoration: none;
    cursor: pointer;
}
body.no-scroll {
    overflow: hidden;
}
header{
    backdrop-filter: blur(10px);
    background-color: rgba(20, 20, 20, 0.64);
    z-index: 99;
    height: 60px;
}
.header {
    height: 64px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    max-width: 1500px;
    margin: 0 auto;
    justify-content: end;
}

/* Novo estilo para as tabs */
.tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.tab-btn {
    padding: 10px 20px;
    margin: 0 10px;
    border: none;
    background: #f0f0f0;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
}
.tab-btn.active {
    background: v-bind("finalWebsite?.color || '#000000'");
    color: white;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    padding: 20px;
}

.grid-item {
    background: white;
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.2s;
}

.grid-item:hover {
    transform: translateY(-5px);
}

.item-content {
    padding: 10px;
}

.item-content img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 4px;
}

.item-content p {
    margin: 10px 0;
    font-size: 14px;
    color: #333;
    text-align: center;
}

@media (max-width: 1200px) {
    .grid-container {
        grid-template-columns: repeat(3, 1fr);
        flex-wrap: wrap;
        display: flex;
    }
    .grid-container {
        grid-template-columns: 1fr;
        padding: 0!important;
        flex-wrap: wrap;
        display: flex;
        padding-bottom: 180px !important;
    }
}

@media (max-width: 900px) {
    .grid-container {
        grid-template-columns: repeat(2, 1fr);
        flex-wrap: wrap;
        display: flex;
    }
    .grid-container {
        grid-template-columns: 1fr;
        padding: 0!important;
        flex-wrap: wrap;
        display: flex;
        padding-bottom: 180px !important;
    }
}

@media (max-width: 600px) {
    .grid-item {
        background: white;
        border-radius: 8px;
        overflow: hidden;
        transition: transform 0.2s;
        width: 47%;
    }
    .grid-container {
        grid-template-columns: 1fr;
        padding: 0!important;
        flex-wrap: wrap;
        display: flex;
        padding-bottom: 180px !important;
    }
}

/* Resto dos estilos mantidos como estava... */
.contentHeaderLeft {
    display: flex;
    align-items: center;
    gap: 20px;
}
.contentHeaderLeft img{
    width: 120px;
}
.contentHeaderLeft p{
    margin: 0;
    color: white;
}
.contentHeaderLeft p a{
   text-decoration: none;
   color: white;
}
.contentHeaderMiddle {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    align-items: center;
}
header {
    position: absolute;
    top: 0;
    width: 100%;
}
.contentHeaderMiddle > div >button.btnHedaer {
    padding: 0px 16px;
    color: rgb(255, 255, 255);
    display: block;
    text-align: center;
    line-height: 40px;
    font-weight: 800;
    text-transform: uppercase;
    background-color: black;
    border-radius: 10px;
}
.search {
    background-color: rgba(255, 255, 255, 0.15);
    border: 1px solid rgba(255, 255, 255, 0.05);
    border-radius: 5px;
}
.search input {
    height: 100%;
    color: white;
    width: 300px;
    font-size: 14px;
    width: 0;
    transition: all 0.3s ease 0s;
}
.search.active input {
   width: 300px;
   padding: 0 10px;

}
input:focus-visible {
    outline: none;
    box-shadow: none;
}
button.btnCloseSearch svg {
    font-size: 15px;
}
button.btnCloseSearch {
    margin-right: 15px;
}
.search.active button.btnSearch {
    background-color: #2c2c2f;
    height: 40px;
    width: 40px;
    border: none;
}
button.btnSearch {
    height: 40px;
    width: 40px;
}
.login{
    cursor: pointer;
}
.menu-mobile-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: white;
    display: flex;
    justify-content: space-around;
    padding: 10px 0;
    box-shadow: 0 -2px 10px rgba(0,0,0,0.1);
    z-index: 1000;
}

.menu-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: #666;
    font-size: 12px;
    cursor: pointer;
}

.menu-item svg {
    font-size: 24px;
    margin-bottom: 4px;
}

.menu-item span {
    font-size: 12px;
}

.menu-item:hover {
    color: #000;
}

@media (min-width: 768px) {
    .menu-mobile-bottom {
        display: none;
    }
}

@media (max-width: 600px) {
    .lgooContentCenter {
        left: 0%!important;
        top: 0px!important;
    }
    .siteTheme2 {
        background-color: white !important;
    }
}
@media (max-width: 600px) {
    .resultSearchItens {
        max-width: 800px !important;
        margin: 0 auto !important;
        display: block !important;
        gap: 10px !important;
        margin: 17px !important;
        padding: 0 !important;
    }
     button.btnHedaer {
        display: none!important;
    }
    .search.active input {
        width: calc(100% - 91px)!important;
        padding: 0 10px!important;
    }
    .resultSearch.mobile.active {
        z-index: 88!important;
        position: absolute!important;
        top: 0px!important;
        background-color: #000000e0!important;
        height: 80vh!important;
        width: 100%!important;
        opacity: 1!important;
        padding-top: 75px!important;
    }
    .resultSearch {
        z-index: -1!important;
        position: absolute!important;
        top: 63px!important;
        background-color: #000000e0!important;
        height: 80vh!important;
        width: 100%!important;
        opacity: 0!important;
    }
    .contentHeaderMiddle{
        z-index: 9999999!important;
    }
    .resultSearch.mobile.active p {
        color: white!important;
    }
    .menuMobileItens svg {
        width: 13px!important;
        padding: 0px 10px!important;
        color: white!important;
    }
    .menuMobileItens p {
        text-align: center!important;
        color: #8e8e8e!important;
        font-size: 15px!important;
        padding: 18px!important;
        margin: 0!important;
        display: flex!important;
        justify-content: center!important;
        align-items: center!important;
    }
    .menuMobileItens {
        top: -175px!important;
        position: absolute!important;
        width: 100%!important;
        transition: all 500ms ease-in-out!important;
        display: flex;
    }
    .menuMobileItens.active {
        top: 62px!important;
        margin-top: 0;
        background-color: rgba(204 204 204 / 1)!important;
    }
    .siteTheme2 svg.svg-inline--fa {
        color: #8e8e8e !important;
    }
    svg.svg-inline--fa.fa-chevron-down {
        width: 12px!important;
        transform: rotate(0)!important;
        transition: all 500ms ease-in-out!important;
    }
    svg.svg-inline--fa.fa-chevron-down.rotate {
        transform: rotate(-180deg)!important;
    }
    header {
        background-color: #000000e6!important;
    }
    .primaryBanner[data-v-331e0a98] {
        height: 450px!important;
        padding-bottom: -10px!important;
    }
    .subscription h1 {
        color: white!important;
        font-size: 18px!important;
        padding: 0px 40px!important;
    }
    .text {
        max-width: 90%!important;
        margin: 0 auto!important;
        padding-top: 10px!important;
    }
    .subscription {
        background-color: black!important;
        min-height: 80px!important;
    }    
    a.router-link-active.router-link-exact-active.logo.active {
        display: none;
    }
    .menuMobile.active {
        display: none;
    }
}
@media screen and (min-width: 600px) {
    svg.svg-inline--fa.fa-chevron-down {
        width: 12px!important;
        transform: rotate(0)!important;
        transition: all 500ms ease-in-out!important;
    }
    svg.svg-inline--fa.fa-chevron-down.rotate {
        transform: rotate(-180deg)!important;
    }
    .menuMobileItens {
        top: 70px;
        background-color: #00000000;
        right: -400px;
        transition: all 500ms ease-in-out;
        max-width: 1500px;
        margin: 0 auto;
        display: flex;
        align-items: stretch;
        justify-content: right;
        margin-top: -200px;
    }
    .menuMobileItens.active {
        margin-top: 0;
    }
    .menuMobileItens p {
        margin: 0;
        padding: 15px;
        color: #8e8e8e;
        transition: all 500ms ease-in-out;
        cursor: pointer;
    }
    .menuMobileItens p:hover , .menuMobileItens a:hover {
        transform: scale(1.05);
    }    
}
.menuMobile {
    cursor: pointer;
}
.linkMenu {
        margin: 0;
        padding: 15px;
        color: #8e8e8e;
        transition: all 500ms ease-in-out;
        cursor: pointer;
        background-color: transparent;
        text-decoration: none;
    }
    .resultSearch.active {
        z-index: 88 !important;
        position: absolute !important;
        top: 0px !important;
        background-color: #ebebeb !important;
        height: 100vh !important;
        width: 100% !important;
        opacity: 1 !important;
        padding-top: 75px !important;
    }
    .resultSearchItens {
        max-width: 1200px;
        margin: 0 auto;
        padding: 20px;
        display: block;
    }
    .notFound p {
        color: #333;
        text-align: center;
        margin: 20px;
        font-size: 16px;
    }
    .resultSearch {
        z-index: -1!important;
        position: absolute!important;
        top: 63px!important;
        background-color: #000000e0!important;
        height: 100vh!important;
        width: 100%!important;
        opacity: 0!important;
    }
    .loggedInfo {
        background-color: rgb(240 242 247);
        margin-top: -4px;
    }
    .InfoOutLogin {
        display: contents;
    }
    .lgooContentCenter {
        position: absolute;
        top: 5px;
        left: 50%;
    }
    header {
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        background-color: rgb(255 255 255);
        z-index: 99;
        height: 60px;
    }
    .search.active {
        background-color: #ebebeb;
    }
    .search.active input[type="text"] {
        color: black;
    }
    .search.active button.btnSearch {
        background-color: #f8f8f8;
    }
    .contentHeaderMiddle p {
        margin: 0;
        color: #7f7f7f;
    }

.contentHeaderMiddle p a{
    text-decoration: none;
    color: #7f7f7f;
 }
 .resultSearch.mobile.active {
    z-index: 88!important;
    position: absolute!important;
    top: 0px!important;
    background-color: #000000e0!important;
    height: 80vh!important;
    width: 100%!important;
    opacity: 1!important;
    padding-top: 75px!important;
}
.siteTheme2 svg.svg-inline--fa {
    color: #8e8e8e;
}
.tab-content {
    height: 700px;
    overflow: scroll;
    -ms-overflow-style: none; 
    scrollbar-width: none;  
}
.tab-content::-webkit-scrollbar {
    display: none; 
}
</style>
