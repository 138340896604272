<template>
    <div class="container">
        <div class="titleContainer">
            <h3>Usuários</h3>
            <p>Gerencie os usuários do sistema</p>
        </div>
        <div class="actionsContainer">
            <div class="actionsHeader">
                <div id="searchUserPage">
                    <select v-model="orderFilter" class="form-select">
                      <option value="">Status</option>
                      <option value="1">Assinante</option>
                      <option value="0">Não Assinante</option>
                    </select>
                    <select v-model="typeFilter" class="form-select">
                      <option value="">Tipo</option>
                      <option value="2">Gestor</option>
                      <option value="3">Usuário</option>
                    </select>
                    <div class="form-input search-input-user">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Pesquisar..."
                        v-model="userSearch"
                      />
                      <fa icon="fa-magnifying-glass" />
                    </div>
                    <button class="btnSearch" id="btnSearchUserPage" @click="getUserByName">
                      <span>Buscar</span>
                    </button>
                    <button class="btnClear" @click="clearSearch" v-if="userSearch.length > 0">
                      <span>Limpar</span>
                    </button>
                  </div>
                  <div class="buttonsContainerUsers">
                    <button class="btnExport usersExport" @click="exportarUsuariosParaCSV" >
                        <div>
                            <fa icon="fa-download"/>
                            <span class="mobile-text-none">Baixar relatório</span>
                        </div>
                        <LoaderImage v-show="isExporting" />

                    </button>
                    <button class="btnExport" @click="(()=>this.$router.push('/admin/novo-usuario'))">
                        <fa icon="fa fa-plus"></fa>
                        <span v-if="!isMobileIcon">Novo usuário</span>
                    </button>
                  </div>
            </div>
        </div>
        <div class="cardsContainer">
            <div class="row ">
                <div class="card-md">
                    <div class="contentCard" style="position: relative;">
                        <h3 v-if="hasReadyStats">{{totalUsers.toLocaleString('pt-BR')}}</h3>
                        <LoaderImage v-if="!hasReadyStats" />
                        <p v-if="hasReadyStats">Usuários Cadastrados</p>
                    </div>
                </div>

                <div class="card-md">
                    <div class="contentCard mult" style="position: relative;">
                        <div class="contentCardMult">
                            <div class="follower">
                                <div class="follower-content">
                                    <strong v-if="hasReadyStats">{{percentageSubscribers.toFixed(2)}}%</strong>
                                    <LoaderImage v-if="!hasReadyStats" />
                                    <small v-if="hasReadyStats">Assinantes ({{totalSubscribers}})</small>

                                </div>
                                <div class="follower-progress" v-if="hasReadyStats">
                                    <div class="progress-bar">
                                        <div class="progress animate-progress" :style="{width: percentageSubscribers + '%!important', animation: 'progressAnimation-57a9c231 2s ease-out forwards'}"></div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    <div class="card-md">
                        <div class="contentCard mult" style="position: relative;">
                            <div class="contentCardMult">
                                <div class="follower">
                                    <div class="follower-content">
                                        <strong v-if="hasReadyStats">{{Math.floor(percentageAdmins)}}%</strong>
                                        <LoaderImage v-if="!hasReadyStats" />
                                        <small v-if="hasReadyStats">Gestores ({{totalAdmins}})</small>

                                    </div>
                                    <div class="follower-progress" v-if="hasReadyStats">
                                        <div class="progress-bar">
                                        <div class="progress animate-progress" :style="{width: percentageAdmins + '%!important', animation: 'progressAnimation-57a9c231 2s ease-out forwards'}"></div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-md">
                        <div class="contentCard mult" style="position: relative;">
                            <div class="contentCardMult">
                                <div class="follower">
                                    <div class="follower-content">
                                        <strong v-if="hasReadyStats">{{Math.floor(percentageActives)}}%</strong>
                                        <LoaderImage v-if="!hasReadyStats" />
                                        <small v-if="hasReadyStats">Ativos ({{totalActives.toLocaleString('pt-BR')}})</small>
                                    </div>
                                    <div class="follower-progress" v-if="hasReadyStats">
                                        <div class="progress-bar">
                                        <div class="progress animate-progress" :style="{width: percentageActives + '%!important', animation: 'progressAnimation-57a9c231 2s ease-out forwards'}"></div>
                                        </div>

                                    </div>
                                </div>
                            </div>                           
                        </div>
                    </div>
            </div>
         
        </div>
        <div class="tableContainer" style="height: 380px; overflow-y: auto;" @scroll="handleScroll" v-if="hasReadySearch">
            <table>
                <thead>
                    <tr>
                        <th @click="ordenar('name')">
                            Nome
                            <i :class="['fas', ordemAtual === 'name' ? (ordemAscendente ? 'fa-sort-up' : 'fa-sort-down') : 'fa-sort']"></i>
                        </th>
                        <th @click="ordenar('speed')">
                            E-mail
                            <i :class="['fas', ordemAtual === 'speed' ? (ordemAscendente ? 'fa-sort-up' : 'fa-sort-down') : 'fa-sort']"></i>
                        </th>
                        <th class="tableUserStatus" @click="ordenar('type')">
                            Assinante
                            <i :class="['fas', ordemAtual === 'type' ? (ordemAscendente ? 'fa-sort-up' : 'fa-sort-down') : 'fa-sort']"></i>
                        </th>
                        <th @click="ordenar('length')" v-if="!isMobile">
                            Tipo
                            <i :class="['fas', ordemAtual === 'length' ? (ordemAscendente ? 'fa-sort-up' : 'fa-sort-down') : 'fa-sort']"></i>
                        </th>
                        <th style="width: 80px;">
                            Pagamentos
                        </th>
                        <th style="width: 80px;">
                            Editar
                        </th>
                        
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(user, index) in usuariosFiltrados" :key="index">
                        <td class="tableUserName">{{ user.name }}</td>
                        <td>{{ user.email }}</td>                        
                        <td class="tableUserStatus">{{ user.orderStatus == 1 ? 'Assinante' : user.orderStatus == 4 ? 'Compra Avulsa' : 'Não Assinante' }}</td>
                        <td v-if="!isMobile">{{ user.type == 1 ? 'Administrador' : user.type == 2 ? 'Gestor' : 'Usuário' }}</td>
                        <td style="text-align: center;">
                            <button class="btnEdit" @click="(()=>this.$router.push(`pagamentos-usuario/${user.id}`))">
                                <fa icon="fa-dollar-sign"></fa>
                            </button>
                        </td>
                        <td>
                            <button class="btnEdit" @click="(()=>this.$router.push(`editar-usuario/${user.id}`))">
                                <fa icon="fa-pencil"></fa>
                            </button>
                        </td>
                       
                    </tr>
                </tbody>
            </table>
            <div v-if="carregando" class="loading">
                Carregando mais usuários...
            </div>
        </div>
      <LoaderImage v-if="!hasReadySearch" />
      </div>
</template>

<script>
import User from '@/utils/Weflix/user';
import { mapState } from 'vuex';
import Loader from '@/components/Weflix/Loader';
import LoaderImage from '@/components/Weflix/LoaderImage.vue';

export default {
    data() {
        return {
        users: [],
        usuariosFiltrados: [],
        ordemAtual: 'name', 
        ordemAscendente: true,
        hasReady: false,
        userSearch: '',
        orderFilter: '',
        typeFilter: '',
        isMobileIcon: false,
        totalUsers: 0,
        totalSubscribers: 0,
        percentageSubscribers: 0,
        totalAdmins: 0,
        percentageAdmins: 0,
        totalActives: 0,
        percentageActives: 0,
        paginaAtual: 0,
        carregando: false,
        temMaisUsuarios: true,
        hasReadyStats: false,
        hasReadySearch: true,
        exportUsers: [],
        isExporting: false,
        isMobile: false
        }
    },
    mounted() {
        this.checkScreenSize();
        window.addEventListener('resize', this.checkScreenSize);
        this.isMobile = window.innerWidth <= 600;
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.checkScreenSize);
    },
    computed: {
        ...mapState(['token'])
    },
    components: {
        Loader,
        LoaderImage
    },
    methods: {
        ordenar(coluna) {
            // Se clicar na mesma coluna, inverte a ordem
            if (this.ordemAtual === coluna) {
                this.ordemAscendente = !this.ordemAscendente;
            } else {
                // Se clicar em uma nova coluna, ordena ascendente
                this.ordemAtual = coluna;
                this.ordemAscendente = true;
            }

            // Ordena os usuários, excluindo type 1
            this.usuariosFiltrados = this.usuariosFiltrados.filter(user => user.type !== 1);
            this.usuariosFiltrados.sort((a, b) => {
                let comparacao = 0;
                if (a[coluna] < b[coluna]) comparacao = -1;
                if (a[coluna] > b[coluna]) comparacao = 1;
                return this.ordemAscendente ? comparacao : -comparacao;
            });
        },
        async getUsers() {
            const payload = {
                "type": this.typeFilter || null,
                "order": this.orderFilter || null
            }
            try {
                const response = await User.getAllUserWithPurchase(this.token, this.paginaAtual, payload);
                if (response && response.content) {
                    if (response.content.length === 0) {
                        this.temMaisUsuarios = false;
                        return;
                    }
                    const filteredContent = response.content.filter(user => user.type !== 1);
                    this.users = [...this.users, ...filteredContent];
                    this.usuariosFiltrados = [...this.usuariosFiltrados, ...filteredContent];
                    this.hasReady = true;
                }
            } catch (error) {
                console.error('Erro ao obter usuários:', error);
            }
        },
        async getAllUserExport() {
            try {
                const response = await User.getAllUserExport(this.token);
                this.exportUsers = response.content;
                this.isExporting = false;
            } catch (error) {
                console.error('Erro ao obter usuários:', error);
            }
        },
        
        async handleScroll(e) {
            const element = e.target;
            if (element.scrollHeight - element.scrollTop === element.clientHeight) {
                if (!this.carregando && this.temMaisUsuarios) {
                    this.carregando = true;
                    this.paginaAtual++;
                    await this.getUsers();
                    this.carregando = false;
                }
            }
        },
        async getUserStats() {
            this.hasReadyStats = false;

            try {
                const response = await User.getUserStats(this.token);
                this.totalUsers = response.totalUsers;
                this.totalSubscribers = response.totalSubscribers;
                this.percentageSubscribers = response.percentageSubscribers;
                this.totalAdmins = response.totalAdmins;
                this.percentageAdmins = response.percentageAdmins;
                this.totalActives = response.totalActives;
                this.percentageActives = response.percentageActives;
                this.hasReadyStats = true;
            } catch (error) {
                console.error('Erro ao obter dados das estatísticas do usuário:', error);
            }
        },
        async getUserByName() {
            if(this.userSearch.length < 3 && !this.typeFilter && !this.orderFilter){
                this.getUsers();
                return;
            }
            this.hasReadySearch = false;
            const payload = {
                "name": this.userSearch || null,
                "type": this.typeFilter || null,
                "order": this.orderFilter || null
            }
           try {
                const response = await User.getUserByName(this.token, payload);
                if (response.content && Array.isArray(response.content )) {
                    this.usuariosFiltrados = response.content;
                    this.users = response.content;
                    this.hasReadySearch = true;
                } else {
                    this.usuariosFiltrados = [];
                    this.users = [];
                    this.hasReadySearch = true;
                }
           } catch (error) {
                console.error('Erro ao obter usuário pelo nome:', error);
                this.usuariosFiltrados = [];
                this.users = [];
                this.hasReadySearch = true;
           }
        },
        clearSearch() {
            this.userSearch = '';
            this.orderFilter = '';
            this.typeFilter = '';
            this.users = [];
            this.usuariosFiltrados = [];
            this.paginaAtual = 0;
            this.temMaisUsuarios = true;
            this.getUsers();
        },
        checkScreenSize() {
            this.isMobileIcon = window.innerWidth <= 600;
        },
        async exportarUsuariosParaCSV() {

            if (this.isExporting) return;
           
            try {
                this.isExporting = true;

                await this.getAllUserExport();


                if (!this.exportUsers || this.exportUsers.length === 0) {
                    console.error('Nenhum usuário para exportar');
                    return;
                }

                const cabecalho = [
                    'ID', 'Nome', 'E-mail', 'Telefone', 'Tipo', 'Status',
                    'CPF', 'RG', 'País', 'Estado', 'Cidade', 'Endereço', 
                    'Bairro', 'Número', 'Complemento', 'CEP', 'Data de Inserção',
                    'Primeiro Login', 'Ativo'
                ];
                const linhasCSV = Array.isArray(this.exportUsers) ? this.exportUsers.map(user => [
                    user.id,
                    user.name,
                    user.email,
                    user.phone,
                    user.type,
                    user.statusDescription,
                    user.socialNumber,
                    user.idNumber,
                    user.country,
                    user.county,
                    user.city,
                    user.address,
                    user.neighborhood,
                    user.addressNumber,
                    user.addressComplement,
                    user.postcode,
                    user.dateInsert,
                    user.firstLogin ? 'Sim' : 'Não',
                    user.enabled ? 'Sim' : 'Não',
                ]) : [];

                const conteudoCSV = [
                    cabecalho.join(','),
                    ...linhasCSV.map(linha => linha.join(','))
                ].join('\n');

                const blob = new Blob(["\ufeff" + conteudoCSV], { type: 'text/csv;charset=utf-8;' });
                const link = document.createElement('a');
                const url = URL.createObjectURL(blob);
                
                link.setAttribute('href', url);
                link.setAttribute('download', `usuarios_weflix_${new Date().toLocaleDateString()}.csv`);
                link.style.visibility = 'hidden';
                
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(url);

            } catch (error) {
                console.error('Erro ao exportar usuários:', error);
            } finally {
                this.isExporting = false;
            }
        }
    },
    created() {
        this.getUsers();
        this.getUserStats();
    },
   
}
</script>


<style scoped>
@import url(./global.css);
.container {
    max-width: 100%;
 }
 .usersExport svg.svg-inline--fa.fa-download {
    color: #250891;
    padding: 0px;
    transition: all 500ms ease-in-out;
}
.usersExport{
    margin-right: 10px;
}
button.btnExport svg.svg-inline--fa.fa-plus {
    color: #250991;
}
.titleContainer {
    display: block;
    text-align: left;
    padding: 0px 10px;
    border-bottom: 1px solid #e8e8e8;
    margin: 10px;
}
.container {
    background-color: white;
    padding: 10px 0px;
    padding-bottom: 50px;
}
.btnExport {
    width: auto;
    padding: 6px 12px;
    background-color: white;
    border:1px solid #250891;
    color: #250891;
    border-radius: 5px;
    top: 20px;
    right: 20px;
    cursor: pointer;
    transition: all 500ms ease-in-out;
}
.btnExport:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}
.btnExport:hover:not(:disabled) {
    background-color: #250891;
    color: white;
}
.btnSearch, .btnClear {
    width: auto;
    padding: 6px 12px;
    background-color: white;
    border: 1px solid #250891;
    color: #250891;
    border-radius: 5px;
    cursor: pointer;
    transition: all 500ms ease-in-out;
    margin-left: 10px;
}
.btnSearch:hover, .btnClear:hover {
    background-color: #250891;
    color: white;
}
.btnSearch svg, .btnClear svg {
    margin-right: 5px;
}
.row {
    margin: 0;
    padding: 10px;
    gap: 10px;
    justify-content: space-evenly;

}
.card-md {
    width: 24%;
    padding: 0;
    margin: 0;
    padding: 10px;
    box-shadow: -1px 4px 10px 1px #dfdfdf;
    border-radius: 5px;
    align-items: center;
    display: flex;
    justify-content: center;
}
#searchUserPage svg.svg-inline--fa.fa-magnifying-glass {
    color: #bcbcbc;
    position: absolute;
    top: 9px;
    right: 9px;
}
.form-input {
    position: relative;
}
input.form-control {
    padding-right: 33px !important;
}
.actionsHeader {
    display: flex;
    align-items: center;
    justify-content: end;
}
.actionsContainer {
    position: absolute;
    top: 50px;
    right: 20px;
    width: 70%;
}
svg.svg-inline--fa.fa-download {
    color: #250891;
    padding: 0px 10px;
    transition: all 500ms ease-in-out;
}
.btnExport:hover:not(:disabled) svg.svg-inline--fa.fa-download {
    color: white;
}
table {
    width: 100%;
    border-collapse: collapse;
}
th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}
th {
    background-color: #f2f2f2;
    font-weight: bold;
}
tr:nth-child(even) {
    background-color: #f9f9f9;
}
.tableContainer {
    padding: 10px;
}
.progress-bar {
    width: 100%;
    background-color: #e0e0e0;
    border-radius: 10px;
    overflow: hidden;
    height: 5px;
    position: relative;
}

.progress {
    height: 10px;
    background-color: #250891;
    position: absolute;
    height: 10px;
    width: 0;
}

.animate-progress {
    animation: progressAnimation 2s ease-out forwards;
}

@keyframes progressAnimation {
    0% {
        width: 0;
    }
    100% {
        width: 90%;
    }
}
.contentCard.mult {
    width: 70%;
}
.follower-content {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin: 3px 0px;
}
.contentCardMult {
    margin: 25px 0px;
}
.follower-progress{
    position: relative;
}
small.progress-value {
    position: absolute;
    top: 7px;
    color: gray;
    font-size: 11px;
}
small.progress-value {
    display: none;
}
.loading {
    text-align: center;
    padding: 10px;
    color: #666;
}
#searchUserPage {
    position: relative;
    width: 600px;
    display: flex;
    justify-content: end;
    gap: 10px
}
.form-select {
    padding: 6px;
    border: 1px solid #ccc;
    border-radius: 5px;
    color: #333;
    background-color: white;
}
@media (max-width: 600px) {
    .cardsContainer .row {
        display: flex;
    }
    .card-md {
        width: 45%;
    }
    .follower-content {
        flex-wrap: wrap;
    }
    .btnExport {
        padding: 7px 7px;
    }
    .btnExport svg {
        color: #250891;
    }
    .btnExport:hover:not(:disabled) svg {
        color: white;
    }
    .titleContainer {
        display: block;
        text-align: left;
        padding: 0px 10px;
        margin: 10px;
        border-bottom: none;
    }
    .actionsContainer {
        position: relative;
        top: 0;
        right: 0;
        width: 100%;
        padding: 10px;
    }
    .actionsHeader {
        flex-direction: column;
        align-items: stretch;
    }
    #searchUserPage {
        width: 100%;
        flex-direction: column;
        gap: 10px;
    }
    .form-input.search-input-user {
        width: 100%;
    }
    .form-input.search-input-user input.form-control {
        width: 100%!important;
    }
    .buttonsContainerUsers {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
        gap: 10px;
        position: absolute;
        top: -100px;
        right: 10px;
    }
    .mobile-text-none {
        display: none;
    }
    .tableUserName {
        min-width: 150px;
    }
    .tableUserStatus {
        display: none;
    }
    #btnSearchUserPage {
        width: 100%;
        position: relative;
        margin: 0;
    }
    #btnSearchUserPage svg {
        color: #250891;
        margin-right: 5px;
    }
    .contentCard h3 {
        font-size: 17px;
        font-weight: 700;
    }
    .contentCard p {
        font-size: 11px;
    }
    .btnClear {
        width: 100%;
    }
}
@media (min-width: 601px) {
    .is-not-mobile {
        display: none;
    }
     #btnSearchUserPage {
        margin: 0px 10px;
    }
   
}
button.btnExport.usersExport .loader2{
    width: 19px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 2px solid #250891;
    animation: l20-1-79f4614c 0.8s infinite linear alternate, l20-2-79f4614c 1.6s infinite linear;
    margin: 0px 10px;
    position: initial;
}
button.btnExport.usersExport {
    display: inline-flex;
}
.form-input.search-input-user input.form-control {
    width: 150px;
}
</style>
