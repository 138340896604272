<template>
    <div class="login">
      <div class="content">
        <div class="info">
          <div class="logo">
            <img alt="Vue logo" src="@/assets/Weflix/logo_weflix-black.png" style="width: 200px" />
          </div>      
          <div class="form" v-if="status==1">
            <h4>Sua conta foi ativada com sucesso!</h4>
            <small>Agora você pode acessar coleções, videos e muito mais</small>
            <button class="btn btn-success weflix" @click="this.$router.push('/')">Ir para o site</button>
          </div>
          <div class="form" v-else>
            <h4>Sua conta não poder ser ativada!</h4>
            <small>Seu token de solicitação de ativação é inválido ou expirado, por geentilize socliite um novo token.</small>
            <button class="btn btn-success weflix" @click="generateToken">Solicitar novo token</button>
            <div class="message-generate-token">
              <h4 v-if="successGenerateToken">Sucesso! Um novo token foi enviado para seu e-mail</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import  { mapState } from 'vuex';
  import apiPassword from '@/utils/password.js';
  import apiSite from '@/utils/Weflix/Website/index';
  
  
  export default {
    data() {
      return {
        email: '',
        password: '',
        changePassword:false,
        token: '',
        status: 2,
        successGenerateToken: false,
      };
    },
    components: {
    },
    computed: {
    },
    methods: {
    changeViewPassword(){
      if(this.showPassword=='password'){
        this.showPassword='text';
      } else {
        this.showPassword='password';
      }
    },
      async handleSubmitChangePassword() {
      const obj = {
        'password': this.email,
        'token': this.token,
      }
        try {
          const response = await apiPassword.resetPassowrdEmail(obj);
          this.$router.push('/login');
        } catch (error) {
          console.error(error);
        }
      },
      async confirmationAccount() {
        try {
          const response = await apiSite.confirmationAccount(this.token);
          this.status = response;
        } catch (error) {
          console.error(error);
        }
      },
      async generateToken() {
        try {
          const response = await apiSite.generateNewToken(this.token);
          this.successGenerateToken = true;
        } catch (error) {
          console.error(error);
        }
      }
  
    },
    created() {
      this.token =  this.$route.query.token;
      this.confirmationAccount();
    }
  }
  </script>
  
  <style scoped>
  .action {
    display: flex;
    justify-content: end;
    padding: 5px;
  }
  .mb-3 small {
    margin-top: 10px;
    display: block;
    color: gray;
  }
  .form {
    max-width: 100%;
    margin: 0 30px;
    text-align: left;
    height: 250px;
    position: relative;
  }
  .login {
    height: 100vh;
    display: flex;
    background-image: url("../../assets/Weflix/bg-login-weflix.png");
    background-size: cover;
  }
  .mb-5.text-center.btn {
    width: 100%;
    position: absolute;
    bottom: 0;
  }
  .logo {
    display: flex;
    justify-content: center;
    margin: 20px;
  }
  .content {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  .info {
    width: 450px;
    padding: 30px 10px;
    background-color: white;
    border-radius: 10px;
  }
  button.btn.btn-success {
    width: 100%;
  }
  .mb-3 {
    margin: 20px 0px;
  }
  .mb-3.forgot_password {
    color: red;
    margin: 0 !important;
    font-size: 11px;
    text-decoration: none;
    display: flex;
    justify-content: end;
  }
  .mb-3.forgot_password a {
    color: #19c7d0;
  }
  .mb-3.password {
    position: relative;
  }
  .mb-3.password i {
    position: absolute;
    top: 45px;
    right: 10px;
    color: #b4b4b4;
  }
  .company {
    max-width: 100%;
    margin: 0 30px;
    text-align: left;
    position: relative;
  }
  .company button.btn.btn-success {
    margin-top: 10px;
  }
  .option-company {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
  }
  @media (max-width:600px) {
    .info {
      width: 87%;
      padding: 0;
      
  }
  }
  button.btn.btn-success.weflix {
    background-color: #0d1a7e !important;
    margin: 20px 0px;
}
.form h4 {
    font-size: 22px;
}
.message-generate-token {
  text-align: center;
  color: green;
}
  </style>
  