<template>
    <div class="box_question_quiz" :class="{ 'review': true }">
      <div class="number_question_quiz">
        <span>Questão {{ numberQuestion + 1}}</span>
      </div>
      <div class="title_question_quiz">
        <span v-html="question"></span>
      </div>      
      <div v-for="(option, index) in options" :key="index" class="option_question_quiz">

        <input type="hidden" :id="'option'+index" v-model="selectedOption">

        <i class="pi pi-check" :class="{ 'correct': option.correct == 1}" v-show="review && option.correct == 1" ></i>

        <i class="pi pi-times" v-show="review && option.correct != 1 && index == selectedOption" ></i>

        <label :for="'option'+index" @click="selectOption(index, option.id)" style="display:flex;align-items: center;" >
            <span :class="{ 
              'selected': index == selectedOption , 
              'correct': option.correct == 1 && review==true, 
              'incorect': option.correct != 1 && index == selectedOption && review==true}" 
              class="option_input">
              {{ optionQuestion[index] }}
            </span>
          <span :class="{ 'selected': option === selectedOption }"><span v-html="option.description"></span></span>
        </label>
      </div>
    </div>
    <!-- <button @click="(()=>this.review=!this.review)">Revisar</button> -->
  </template>
  
  <script>
import api from '@/utils/portal/quiz';
import { mapState } from 'vuex';

  export default {
    props: {
      question: String,
      options: Array,
      correctAnswer: String,
      numberQuestion: Number,
      review:Boolean,
      currentLesson: String,
      typeCurrentLesson:Number
    },
    computed: {
    ...mapState(['token', 'currentModuleOpen', 'idLessonPortal', 'nameCurrentLesson', 'typeLessonPortal']),
  },
    data() {
      return {
        // review:false,
        selectedOption: null,
        selectedOptionIds:null,

        optionQuestion:['A','B', 'C', 'D', 'E']
      };
    },
    methods: {

      async getDataQuiz(){
        try {
          const response = api.getDataQuizQuestion(this.token, this.idLessonPortal);
          console.debug(response);
        } catch (error) {
          console.error(error)
        }
      },
      selectOption(option, id) {
        if(this.review==false){
          this.selectedOptionIds =  id
            this.selectedOption = option;
        }
          
      },
    },
    created(){
      // this.findCorrectAnswerIndex();
    },
    watch:{
      currentLesson(newValue){
        if(this.typeCurrentLesson==5){
          this.getDataQuiz();
        }
      },
      review(newValue){
        if(this.review){
               this.$emit('answer', this.selectedOptionIds);
          }
      }
    },
  };
  </script>
  
  <style scoped>
  .box_question_quiz {
    background-color: white;
    margin: 20px 30px;
    text-align: left;
    border-radius: 10px;
    display: block;
    padding-bottom: 20px;
}
.number_question_quiz {
    padding: 15px;
    border-bottom: 1px solid #ededed;
    font-weight: 700;
    margin-bottom: 10px;
    font-size: 13px;
}
.title_question_quiz{  
    padding: 5px 15px;
    margin-bottom: 10px;
}  
.option_question_quiz{
  display: flex;
  align-items: center;
    padding: 15px 30px;
}
span.option_input {
    border: 2px solid #cecece;
    margin: 0 10px;
    padding: 5px 10px;
    border-radius: 5px;
    transition: all 200ms linear;
    outline: 1px solid white;
}
span.selected.option_input {
    background-color: #26bdbe;
    border: 2px solid white;
    color: white;
    outline: 1px solid #26bdbe;
}
.box_question_quiz.review span.correct.option_input {
    background-color: green;
    color: white;
    border-color: green;
    outline-color: green;
}
i.pi.pi-times {
    color: red;
}
span.selected.option_input.incorect {
    background-color: red;
    outline: red;
    border-color: red;
}
.option_question_quiz I {
    margin-right: -18px;
}
.option_question_quiz label {
    margin-left: 18px;
}
i.pi.pi-check.correct {
    color: #018001;
}
.indo_hits i.pi.pi-check {
  color: green;
  font-size: 20px;
}
.option_question_quiz p {
  margin: 0;
}
</style>
<style>
.option_question_quiz p {
  margin: 0;
}
@media only screen and (max-width:600px){
  .review {
     width: 89%; 
}
}
</style>
  