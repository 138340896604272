<template>
    <div class="global_content_course" :class="[this.dark ? 'dark' : '']">
        <div class="content">
            <div class="sidebar" :class="showSidebar? 'show':'hide'">
                <SideBar :activeModule="activeModule" :nameCourse="nameCourse" :data="dataModulesxLesson" :currentLesson="currentLesson" @updateLessonName="updateCurrentLessonName"  @lessonChanged="updateCurrentLesson" @typeChanged="updateCurrentTypeLesson"></SideBar>
            </div>
            <div class="viewcontent" :class="!showSidebar? 'full':'less'">
                <div class="title">
                    <div class="infoLeft" style="cursor:pointer;">
                        <fa icon="bars"  @click="toggleSidebar()"/>
                    </div>
                    <div class="infoCenter">
                        <img v-if="!this.dark"  src="../../../../assets/logo.png" >
                        <img v-if="this.dark" src="../../../../assets/logo_white.png">
                    </div>
                    <div class="infoRigth" >
                        <router-link to="/aluno/dashboard"> <fa icon="house" /></router-link>
                        <span @click="toggleDarkModeLocal" v-if="this.dark"><fa icon="sun"/></span>
                        <span @click="toggleDarkModeLocal" v-if="!this.dark"><fa icon="moon"/></span>
                    </div>
                </div>
                <div class="titleMobile">
                    <fa icon="fa-graduation-cap"/>
                    <p>{{currentCoursePortal.name}}</p>
                </div>
                <ViewCourse></ViewCourse>
            </div>
        </div>
        
    </div>
</template>

<script>

import SideBar from './sidebar.vue';
import ViewCourse from './viewContent.vue';

import api from '@/utils/portal/modulesLesson.js';
import apiLesson from '@/utils/portal/lesson.js';
import { mapState, mapActions } from 'vuex';

export default {
    data() {
        return {
           dataModulesxLesson:null,
           currentLesson:null,
           currentLessonName:null,
           typeCurrentLesson:null,
           nameCourse:null,
           activeModule:11,
           listLesson:null,
           dataCourse:null,
           hasLastLesson:false,
        };
    },
    components: {
        SideBar,
        ViewCourse
    },
    computed:{
        ...mapState(['token']),
        ...mapState(['showSidebar']),
        ...mapState(['dark']),
        ...mapState(['currentLesson','currentModuleOpen','idLessonPortal','nameCurrentLesson',"currentCoursePortal"]),

    },
    methods:{
        ...mapActions(['toggleDarkMode','storeTypeLessonPortal', 'storeIdLessonPortal','storeCurrentModuleOpen','storeCurrentLesson','storeNameCurrentLesson','storeShowSidebar']),

        nextLesson() {
            if (!this.listLesson || !this.currentLesson) {
                console.error('Nenhuma lista de lições ou lição atual encontrada.');
                return;
            }

            const currentLessonIndex = this.listLesson.findIndex(lesson => lesson.id === this.currentLesson);
            if (currentLessonIndex === -1) {
                console.error('A lição atual não foi encontrada na lista de lições.');
                return;
            }

            const nextLessonIndex = currentLessonIndex + 1;
            if (nextLessonIndex < this.listLesson.length) {

                const nextLesson = this.listLesson[nextLessonIndex];

                this.storeIdLessonPortal({ idLessonPortal: nextLesson.id });
                this.storeTypeLessonPortal({ typeLessonPortal: nextLesson.type });
                this.storeNameCurrentLesson({ nameCurrentLesson: nextLesson.title});

            } else {
                console.error('Não há próxima lição.');
            }
        },
        getLessonsArray(data) {
            const idCourse = this.$route.params.slug;

            const lessonsArray = [];
            data.forEach(item => {
                if (item.lessons) {
                    lessonsArray.push(...item.lessons);
                }
            });
            this.listLesson = lessonsArray;
            
            if(this.hasLastLesson==false){
                this.storeIdLessonPortal({ idLessonPortal: this.listLesson[0].id });
                this.storeCurrentLesson({currentLesson:0});
                this.storeTypeLessonPortal({ typeLessonPortal: this.listLesson[0].type  });
                this.storeCurrentModuleOpen({ currentModuleOpen: 0 });
                this.storeNameCurrentLesson({ nameCurrentLesson:this.listLesson[0].title });
                }
            },
        async getDataLessonAndModules(id){
            try {
                const response = await api.getDataModulesAndLesson(this.token, id);
                this.dataModulesxLesson = response;
                this.getLessonsArray(response);
            } catch (error) {
                console.error("Erro ao gerar a lista de modulos e lesson");
            }
        },
        async getLastLesson(id){
            try {
                const response = await apiLesson.getDataLastLesson(this.token, id);
                this.storeIdLessonPortal({ idLessonPortal: response.id});
                this.storeCurrentLesson({currentLesson:0});
                this.storeTypeLessonPortal({ typeLessonPortal: response.type  });
                this.storeCurrentModuleOpen({ currentModuleOpen: 0 });
                this.storeNameCurrentLesson({ nameCurrentLesson: response.id.title });
            } catch (error) {
                console.error("Erro ao gerar dados da ultima lesson");
            }
        },
        updateCurrentLesson(lessonId, typeLesson) {
            this.currentLesson = lessonId;
            this.typeCurrentLesson = typeLesson;
        },
        updateCurrentTypeLesson(typeLesson) {
            this.typeCurrentLesson = typeLesson;
        },
        updateCurrentLessonName(nameLesson) {
            this.currentLessonName = nameLesson;
        },
        updateReviewMode(review) {
            this.currentLessonName = nameLesson;
        },
        toggleSidebar() {
            this.storeShowSidebar({showSidebar:!this.showSidebar});
        },
        toggleDarkModeLocal() {
            this.toggleDarkMode({dark:!this.dark});
        },
    },
    created() {
        const idCourse = this.$route.params.slug;
        console.debug(idCourse)
        this.getDataLessonAndModules(idCourse);
        if(this.storeIdLessonPortal){
            this.getLastLesson(idCourse);
        }
        
        // this.getDataCourse(idCourse);
  },
}

</script>

<style scoped>
.global_content_course.dark .titleMobileContent {
    background-color: gray;
    color: white;
}
.global_content_course.dark .titleMobile {
    color: white;
}
.global_content_course{
    height: 100vh;
}
.content {
    display: flex;
}
.sidebar {
    width: calc(100% - 300px);
    width: 300px;
}
.dark .sidebar {
    border: 1px solid #646464;
}

.viewcontent{
    width: calc(100% - 300px);
    background-color: #f7f7f7;
}
.viewcontent .title {
    background-color: white;
    padding: 15px 20px;
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: space-between;
}
.viewcontent .title p{
    margin: 0;
    font-size: 17px;
    font-weight: 500;
}
.infoLeft {
    display: flex;
    gap: 10px;
    align-items: center;
}
.infoLeft i {
    color: #26bdbe;
}
.infoRigth {
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
}
.infoRigth a {
    text-decoration: none;
    color: #26bdbe;
}
.infoRigth i {
    font-size: 11px;
    color: #26bdbe;
}
</style>

<style>
.global_content_course.dark .hedaer {
    background-color: black;
    color: white;
}
.global_content_course.dark .v-expansion-panel {
    background-color: black;
    color: white;
    border-bottom: 1px solid #555555;
}
.global_content_course.dark .viewcontent {
    background-color: black;
}
.global_content_course.dark .title {
    background-color: black;
}
.global_content_course.dark .infoRigth a {
    color: white;
}
.global_content_course.dark .global_sidebar {
    background-color: black;
}
.global_content_course.dark .box_question_quiz.review {
    background-color: #727272;
    color: white;
}
.global_content_course.dark .number_question_quiz{ 
    border-bottom: 1px solid #000000;
}
.sidebar.hide {
    opacity: 0;
    display: none;
    transition: all 500ms linear;
}
.viewcontent.full {
    width: 100%;
}
.titleMobile p {
    margin: 0;
}
.titleMobile  {
    text-align: left;
    padding: 10px 20px;
    display: flex;
    gap: 10px;
}
.infoCenter img {
    width: 110px;
}
svg.svg-inline--fa {
    color: white;
}
.dark svg.svg-inline--fa {
    color: white;
}
</style>