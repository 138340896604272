import api from './api';

export default {
    async getToken(accessToken, payload) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.post('/manager/student/vision', payload, {
                method: 'POST',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter token do usuário:', error);
            throw error;
        }
    },
    async validateToken(accessToken) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get('/manager/student/vision/check', {
                method: 'GET',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados do usuário:', error);
            throw error;
        }
    },
}
