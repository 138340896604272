<template>
  <section class="layoutLayout advanced weflix-admin-advancedLayout">
      <div class="titlelayoutLayout">
          <h3>Avançado</h3>
          <small>Personalize as informações avançadas do seu site.
          </small>
      </div>
      <div class="col-form-inner col-12 col-md-12 col-lg-12 flex" v-if="hasReady">
        <!-- JavaScript -->
        <div class="col-form-inner col-12 col-md-12 col-lg-12">
          <div class="form-group">
            <div class="form-label">
              <label>JavaScript Personalizado:</label>
            </div>
            <div class="form-input">
              <textarea
                class="form-control code-editor"
                placeholder="console.log('JavaScript Personalizado');"
                v-model="javascript"
                rows="5"
              ></textarea>
              <small class="small">
                Adicione código JavaScript personalizado que será executado no header da página do seu site.
                <br/>
                <span class="text-danger">ATENÇÃO: Adicionar códigos JavaScript incorretos pode afetar o funcionamento do seu site.</span>
              </small>
            </div>
          </div>
        </div>

        <!-- CSS -->
        <div class="col-form-inner col-12 col-md-12 col-lg-12">
          <div class="form-group">
            <div class="form-label">
              <label>CSS Personalizado:</label>
            </div>
            <div class="form-input">
              <textarea
                class="form-control code-editor"
                placeholder="body { background-color: #f0f0f0; }"
                v-model="css"
                rows="5"
              ></textarea>
              <small class="small">
                Adicione estilos CSS personalizados para customizar a aparência do seu site. Estes estilos serão aplicados em todas as páginas do seu site.
                <br/>
                <span class="text-danger">ATENÇÃO: Adicionar códigos CSS incorretos pode afetar o layout do seu site.</span>
              </small>
            </div>
          </div>
        </div>

    </div>
    <div class="actionsSave" v-if="hasReady">
      <button class="btnSave" @click="updateAdvanced">Salvar</button>
    </div>
  </section>
  <Loader :hasReady="hasReady"/>
</template>

<script>
import websiteApi from '@/utils/Weflix/website';
import { mapState } from 'vuex';
import Loader from '@/components/Weflix/Loader.vue';


export default {
  data(){
      return{
          javascript: '',
          css: '',
          id: '',
          name: '',
          description: '',
          logo: '',
          favicon: '',
          logoPreview: '',
          faviconPreview: '',
          url: '',
          status: '',
          hasReady: false,
          activeTheme: '',
          color: '',
          facebook: '',
          gads: '',
          ganalytic: '',
          tagManager: '',
          rdrefreshToken: '',
          rdcode: '',
          rdSecret: '',
          rdClient: '',
          descriptionAbout: ''

      }
  },
  components: {
      Loader
  },
  computed: {
    ...mapState(['token']),
  },
  methods:{
    async getAdvanced() {
      const response = await websiteApi.getWebsite(this.token);
      this.id = response.id;
      this.name = response.nome;
      this.description = response.description;
      this.logo = response.logo;
      this.favicon = response.favicon;
      this.logoPreview = response.logoFinal;
      this.faviconPreview = response.faviconFinal;
      this.url = response.url;
      this.status = response.status;
      this.hasReady = true;
      this.activeTheme = response.typeLayout;
      this.color = response.color;
      this.javascript = response.javascript;
      this.css = response.css;
      this.facebook = response.facebookPixelCode;
      this.gads = response.googleAdsCode;
      this.ganalytic = response.googleAnalyticsCode;
      this.tagManager = response.tagManagerCode;
      this.rdrefreshToken = response.rdrefreshToken;
      this.rdcode = response.rdcode;
      this.rdSecret = response.rdsecretClient;
      this.rdClient = response.rdclienteId;
      this.descriptionAbout = response.about;
    },
    async updateAdvanced() {
      this.hasReady = false;
      const payload = {
          "description": this.description,
          "logo": this.logo,
          "favicon": this.favicon,
          "nome": this.name,
          "status": this.status,
          "url": this.url,
          "typeLayout": this.activeTheme,
          "color": this.color,
          "javascript": this.javascript,
          "css": this.css,
          "facebookPixelCode": this.facebook,
          "googleAdsCode": this.gads,
          "googleAnalyticsCode": this.ganalytic,
          "tagManagerCode": this.tagManager,
          "rdrefreshToken": this.rdrefreshToken,
          "rdcode": this.rdcode,
          "rdsecretClient": this.rdSecret,
          "rdclienteId": this.rdClient,
          "about": this.descriptionAbout
      }
        try {
          const response = await websiteApi.editWebsite(this.token, this.id, payload);
          this.getAdvanced();
          this.hasReady = true;
        } catch (error) {
          console.error('Erro ao atualizar dados do website:', error);
      }
    },
  },
  created(){
    this.getAdvanced();
  }   
}
</script>
<style scoped>
@media (max-width: 600px) {
  .weflix-admin-advancedLayout h3 {
    font-size: 20px;
    margin-bottom: 5px;
  }
  .weflix-admin-advancedLayout small {
    font-size: 12px;
    color: #666;
  }
  .weflix-admin-advancedLayout  .actionsSave {
    position: relative;
    top: 0;
    right: 0;
    padding: 10px;
  }
}
</style>
<style>
.titlelayoutLayout {
  text-align: left;
  padding: 10px;
  border-bottom: 1px solid #e3e3e3;
}

.col-form-inner.col-12.col-md-12.col-lg-12.flex {
  flex-wrap: wrap;
  gap: 20px;
}

section.layoutLayout.advanced .form-control {
  min-height: 100px;
  font-family: monospace;
}

.code-editor {
  min-height: 300px !important;
}

.warning .text-danger {
  color: #dc3545;
  font-weight: bold;
}
.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
  font-size: 11px;
}
.actionsSave {
  position: absolute;
  bottom: 30px;
  right: 35px;
}

button.btnSave {
  color: #250891;
  width: 150px;
  background-color: #ffffff;
  padding: 6px 10px;
  border-radius: 5px;
  transition: all 500ms ease-in-out;
  font-size: 18px;
  border: 2px solid #250891;
}

button.btnSave:hover {
  background-color: #160266;
  color: white;
}
section.layoutLayout.advanced {
  margin-bottom: 80px;
}
</style>