import api from '../api';

export default {
    async getPlayerVideo(accessToken, id) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.post(`/file/videoteca/player/${id}`, {} , {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter o player:', error);
            throw error;
        }
    },
    
}
