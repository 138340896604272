<template>
    <div class="container doubts-container">
        <div class="titleContainer myCoursesWeeduc">
            <h3>Editar Atividade Questionário</h3>
            <p style="font-size: 14px;margin-bottom: 0;">Visualize as informações da sua atividade questionário.</p>
        </div>

        <div class="form-container" v-if="!isLoading && !isSaving">
            <div class="form-row">
                <div class="form-group col-3 new-quiz-options">
                    <p class="form-label-title">Título do Atividade</p>
                    <div class="form-input">
                        <input 
                            type="text"
                            class="form-control"
                            v-model="dataLesson.title"
                            
                        />
                    </div>
                </div>

                <div class="form-group col-2"> 
                    <p class="form-label-title">Nota máxima</p>
                    <div class="form-input">
                        <input 
                            type="number"
                            class="form-control"
                            v-model="quizData.maxGrade"
                            
                        />
                    </div>
                </div>

                <div class="form-group col-2">
                    <p class="form-label-title">Exige nota mínima</p>
                    <div class="form-input" style="display: flex; align-items: center; gap: 10px;">
                        <label class="switch">
                            <input type="checkbox" v-model="quizData.requiresMinGrade">
                            <span class="slider round"></span>
                        </label>
                        <div class="status-text">
                            {{ quizData.requiresMinGrade ? 'Sim' : 'Não' }}
                        </div>
                    </div>
                </div>

                <div class="form-group col-2"> 
                    <p class="form-label-title">Nota mínima</p>
                    <div class="form-input">
                        <input 
                            type="number"
                            class="form-control"
                            v-model="quizData.minGrade"
                            
                        />
                    </div>
                </div>


                <div class="form-group col-2"> 
                    <p class="form-label-title">Status</p>
                    <div class="form-input" style="display: flex; align-items: center; gap: 10px;">
                        <label class="switch">
                            <input type="checkbox" v-model="status">
                            <span class="slider round"></span>
                        </label>
                        <div class="status-text">
                            {{ dataLesson.status == 1 ? 'Ativo' : 'Inativo' }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="questions-preview">
                <h3 class="preview-title">Pré-visualização do Questionário</h3>
                <div class="preview-container">
                    <div v-for="(question, index) in selectedQuizData" :key="index" class="preview-question">
                        <!-- <div class="preview-question-header">
                            <h3 class="preview-question-title">Questão {{question.order}}</h3>
                        </div> -->
                        <div class="preview-question-text" v-html="question.description"></div>
                        <div class="preview-answers">
                            <div v-for="(answer, answerIndex) in question.answers" :key="answerIndex" 
                                class="preview-answer" :class="{'correct-answer': answer.correct === 1}">
                                <div class="answer-content">
                                    <span class="answer-letter">{{alternatives[answerIndex]}} ) </span>
                                    <span class="answer-text" v-html="answer.description"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="info-container">
                <small>As opções de edição do questionário estão disponíveis apenas na criação da atividade.</small>
            </div>

     
            
            <div class="button-container">
                <button class="btn-weflix primary" @click="save">Salvar</button>
                <button class="btn-weflix" @click="$router.go(-1)">Voltar</button>
            </div>
        </div>
        <Loader v-if="isSaving || isLoading" />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import apiQuiz from '@/utils/quiz';
import apiAnswer from '@/utils/answer';
import apiLesson from '@/utils/lesson';
import Loader from '@/components/Loader/loader.vue';

export default {
    components: {
        QuillEditor,
        Loader
    },
    data() {
        return {
            isSaving: false,
            isLoading: true,
            dataLesson: null,
            quizData: {
                id: '',
                title: '',
                requiresMinGrade: true,
                minGrade: 0,
                maxGrade: 0,
                status: 1
            },
            status: false,
            lessonDatatitle: '',
            selectedQuizData: null,
            alternatives: ['A','B','C','D','E'],
            expandedQuestions: {},
            activeAnswerTabs: {}
        }
    },
    computed: {
        ...mapState(['token'])
    },
    methods: {
        toggleQuestion(index) {
            this.expandedQuestions[index] = !this.expandedQuestions[index];
        },

        setActiveAnswerTab(questionIndex, answerIndex) {
            this.activeAnswerTabs[questionIndex] = answerIndex;
        },
        async loadLessonData() {
            const id = this.$route.params.slug;
            const response = await apiLesson.getDataLessonById(id, this.token);
            this.dataLesson = response;
            this.status = this.dataLesson.status == 1 ? true : false;
            this.loadQuizData();
        },
        async loadQuizData() {
            const id = this.dataLesson.idQuiz
            try {
                const response = await apiQuiz.getDataQuizById(id, this.token);
                this.quizData = {
                    id: response.id,
                    title: response.title,
                    requiresMinGrade: response.minimum > 0,
                    minGrade: response.minimum,
                    maxGrade: response.maximum,
                    status: response.status
                };
                
                const questionsResponse = await apiAnswer.getAnswerByQuiz(id, this.token);
                this.selectedQuizData = questionsResponse.content;
                
                this.selectedQuizData.forEach((_, index) => {
                    this.expandedQuestions[index] = false;
                    this.activeAnswerTabs[index] = 0;
                });
            } catch (error) {
                console.error('Erro ao carregar dados do quiz:', error);
                alert('Ocorreu um erro ao carregar os dados do questionário');
            } finally {
                this.isLoading = false;
            }
        },
        async editQuiz() {
            this.isSaving = true;
            const payload = {
                "type": 5,
                "title": this.quizData.title,
                "minimum": this.quizData.minGrade,
                "maximum": this.quizData.maxGrade,
                "status": this.quizData.status
            }
            try {
                const response = await apiQuiz.editQuiz(this.quizData.id, payload, this.token);
            } catch (error) {
                console.error('Erro ao atualizar questionário:', error);
                alert('Ocorreu um erro ao atualizar o questionário');
            } finally {
                this.isSaving = false;
            }
        },
        async editLesson() {
            const payload = {
                "type": 5,
                "title": this.dataLesson.title,
                "path": this.dataLesson.path,
                "finalPath": this.dataLesson.finalPath,
                "description": this.dataLesson.description,
                "idQuiz": this.dataLesson.idQuiz,
                "order": this.dataLesson.order,
                "idModule": this.dataLesson.idModule,
                "idCourse": this.dataLesson.idCourse,
                "progress": this.dataLesson.progress,
                "status": this.status ? 1 : 0,
                "finalVideoThumbnail": this.dataLesson.finalVideoThumbnail,
                "videoTime": this.dataLesson.videoTime,
                "times": this.dataLesson.times,
                "token": this.dataLesson.token,
                "idLesson": this.dataLesson.idLesson,
                "videoThumbnail": this.dataLesson.videoThumbnail,
                "sample": this.dataLesson.sample,
                "favourite": this.dataLesson.favourite || 0
            }
            try {
                await apiLesson.editLesson(this.token, payload, this.dataLesson.id);
            } catch (error) {
                console.error('Erro ao atualizar aula:', error);
                alert('Ocorreu um erro ao atualizar aula');
            }
        },
        async save(){
            await this.editLesson();
            await this.editQuiz();
        }

    },
    created() {
        this.loadLessonData();
    }
}
</script>

<style scoped>
.container {
    max-width: 100%;
    background-color: #ffffff;
    padding: 1.5rem;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.form-container {
    padding: 0px 20px;
}

.form-group {
    margin-bottom: 20px;
}

.form-row {
    display: flex;
    gap: 10px;
}

.form-control {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    font-size: 0.875rem;
    transition: all 0.2s ease;
}

.form-control:focus {
    outline: none;
    border-color: #2f2e8b;
    box-shadow: 0 0 0 3px rgba(47, 46, 139, 0.1);
}

.button-container {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    margin-top: 30px;
    position: absolute;
    bottom: 29px;
    right: 30px;
}

.btn-weflix {
    padding: 0.5rem 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    background: white;
    color: #4a5568;
    cursor: pointer;
    transition: all 0.2s;
}

.btn-weflix.primary {
    background: #2f2e8b;
    color: white;
    border: none;
}

.btn-weflix:hover {
    background: #f8fafc;
    border-color: #cbd5e0;
}

.btn-weflix.primary:hover {
    background: #23225f;
}

p.form-label-title {
    margin-bottom: 10px;
    font-weight: 600;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 25px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 17px;
    width: 17px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
}

input:checked + .slider {
    background-color: #2f2e8b;
}

input:checked + .slider:before {
    transform: translateX(36px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.radio-options {
    display: flex;
    gap: 20px;
    justify-content: initial;
}

.radio-option-card {
    flex: 1;
    max-width: 400px;
    position: relative;
}

.radio-option-card input[type="radio"] {
    display: none;
}

.radio-option-card label {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;
    border: 2px solid #e2e8f0;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    gap: 10px;
}

.radio-option-card label i {
    font-size: 2rem;
    color: #2f2e8b;
}

.radio-option-card label span {
    text-align: center;
    font-weight: 500;
}

.radio-option-card input[type="radio"]:checked + label {
    border-color: #2f2e8b;
    background-color: rgba(47, 46, 139, 0.05);
}

.quantity-control {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
}

.quantity-btn {
    padding: 5px 10px;
    border: 1px solid #e2e8f0;
    background: white;
    border-radius: 4px;
    cursor: pointer;
}

.quantity-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.quantity-display {
    min-width: 30px;
    text-align: center;
}

.question-card {
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    margin-bottom: 20px;
    background: white;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.question-header {
    padding: 15px 20px;
    cursor: pointer;
    background-color: #f8fafc;
    border-radius: 8px 8px 0 0;
    border-bottom: 1px solid #e2e8f0;
}

.question-content {
    padding: 20px;
    text-align: left;
}

.question-title-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.question-title {
    font-size: 1.1rem;
    color: #2d3748;
    margin: 0;
}

.question-actions {
    display: flex;
    align-items: center;
    gap: 15px;
}

.question-label {
    font-weight: 500;
    color: #4a5568;
    margin-bottom: 10px;
}

.tabs-answer {
    display: flex;
    gap: 10px;
    margin: 20px 0;
}

.tab {
    padding: 8px 15px;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.active-tab {
    background-color: #2f2e8b;
    color: white;
    border-color: #2f2e8b;
}

.answer-editor-container {
    background: #f8fafc;
    border-radius: 8px;
    padding: 15px;
    width: 100%;
}

.correct-answer-toggle {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #e2e8f0;
}

.correct-answer-label {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.correct-answer-label input[type="radio"] {
    width: 18px !important;
    height: 18px !important;
    margin: 0;
}

.label-text {
    color: #4a5568;
    font-weight: 500;
}

@media (max-width: 768px) {
    .container {
        padding: 1rem;
    }
    
    .form-row {
        flex-direction: column;
    }
    
    .radio-options {
        flex-direction: column;
    }
}

input.form-control:disabled {
    background-color: #f0f0f0;
    cursor: not-allowed;
}

.radio-option-card:hover label {
    border: 2px dashed #150065;
}

.form-group.col-12.new-quiz-options {
    margin: 15px 10px !important;
}

.config-row {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.config-item {
    flex: 1;
}

.form-input.import-create-quiz {
    display: flex;
    gap: 20px;
}

.form-input.import-create-quiz > div {
    width: 50%;
}

.questions-section {
    text-align: left;
}

.form-input.import-create-quiz.import {
    gap: 0px;
}

.questions-preview {
    margin-top: 30px;
    padding: 20px;
    background: #f8fafc;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.preview-title {
    color: #2d3748;
    font-size: 1.5rem;
}
.preview-question {
    margin-bottom: 30px;
    padding: 20px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.preview-answers {
    margin-top: 15px;
}

.preview-answer {
    padding: 10px;
    margin: 8px 0;
    border-radius: 4px;
    background: #f8f9fa;
    display: flex;
    align-items: center;
}

.preview-answer.correct-answer {
    background: #250891;
    border: 1px solid #250991;
    color: white;
}

.preview-question-header {
    text-align: left;
}
.preview-question-text {
    text-align: left;
}
.answer-content {
    display: flex;
    gap: 10px;
}
.form-group.col-12.new-quiz-options {
    max-width: 100%;
    display: inline;
    padding: 10px !important;
    margin: 10px !important;
}
button.btn-weflix.primary:disabled {
    background-color: #2f2e8b;
    opacity: 0.5;
    cursor: not-allowed;
}
.questions-section {
    margin: 20px 5px;
}
</style>

<style>
span.answer-text p {
    margin: 0;
}
.info-container {
    text-align: left;
    padding: 10px;
}
</style>
