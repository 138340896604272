<template>
  <div class="container weeducAdminCourse">
    <div class="titleContainer">
      <div class="title-banner-course">
        <img :src="currentCollections.imageFinalPath" alt="Weflix" />
        <span class="status-flag" :class="currentCollections.status == 1 ? 'active' : 'inactive'">
          {{ currentCollections.status == 1 ? 'Ativo' : 'Inativo' }}
        </span>
      </div>
      <div class="title-content">
        <small>Id: {{currentCollections.id}}</small>
        <h3>{{currentCollections.comercialName}}</h3>
        <span v-html="currentCollections.shortDescription"></span>
      </div>
      <div class="title-actions">
        <button class="editCollections" @click="(()=>this.$router.push(`/dashboard/editar-curso/${currentCollections.id}`))">
          <fa icon="fa-pen-to-square"/> <span class="edit-text">Editar</span>
        </button>
      </div>
      <div class="infoCollectionStats">
        <div class="statsRow">
          <fa icon="fa-users" />
          <span>Total de Matrículas:</span>
          <strong>{{infoCount.totalEnrols}}</strong>
        </div>
        <div class="statsRow">
          <fa icon="fa-dollar-sign" />
          <span>Faturamento:</span>
          <strong>{{ infoCount.invoicing ? infoCount.invoicing.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'N/A' }}</strong>
        </div>
      </div>
      
    </div>
     
      <div class="infoCollectionVideos">
          <div class="videosInfoCollection">
             <p>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#250891" d="M480 256A224 224 0 1 1 32 256a224 224 0 1 1 448 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM240 112l0 144c0 5.3 2.7 10.3 7.1 13.3l96 64c7.4 4.9 17.3 2.9 22.2-4.4s2.9-17.3-4.4-22.2L272 247.4 272 112c0-8.8-7.2-16-16-16s-16 7.2-16 16z"/></svg>
                <span class="info-text">{{infoCount.timeTotal}} <span class="info-text-min">min</span></span></p>
              <p>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#250891" d="M256 32a224 224 0 1 1 0 448 224 224 0 1 1 0-448zm0 480A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm72.7-256L208 329.7l0-147.5L328.7 256zM212.5 147.5c-7.4-4.5-16.7-4.7-24.3-.5s-12.3 12.3-12.3 20.9l0 176c0 8.7 4.7 16.7 12.3 20.9s16.8 4.1 24.3-.5l144-88c7.1-4.4 11.5-12.1 11.5-20.5s-4.4-16.1-11.5-20.5l-144-88z"/></svg>
                <span class="info-text">{{ infoCount.totalVideos }} <span class="info-text-min">videos</span></span>
              </p>
              <p>
                <svg xmlns="http://www.w3.org/2000/svg" style="    width: 11px;" viewBox="0 0 384 512"><path fill="#24385c" d="M320 480L64 480c-17.7 0-32-14.3-32-32L32 64c0-17.7 14.3-32 32-32l128 0 0 112c0 26.5 21.5 48 48 48l112 0 0 256c0 17.7-14.3 32-32 32zM240 160c-8.8 0-16-7.2-16-16l0-111.5c2.8 .7 5.4 2.1 7.4 4.2L347.3 152.6c2.1 2.1 3.5 4.6 4.2 7.4L240 160zM64 0C28.7 0 0 28.7 0 64L0 448c0 35.3 28.7 64 64 64l256 0c35.3 0 64-28.7 64-64l0-284.1c0-12.7-5.1-24.9-14.1-33.9L254.1 14.1c-9-9-21.2-14.1-33.9-14.1L64 0zm48 256c-8.8 0-16 7.2-16 16s7.2 16 16 16l160 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-160 0zm0 64c-8.8 0-16 7.2-16 16s7.2 16 16 16l160 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-160 0zm0 64c-8.8 0-16 7.2-16 16s7.2 16 16 16l160 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-160 0z"/></svg>
                <span class="info-text">{{ infoCount.totalDocuments }}  <span class="info-text-min">materiais</span></span>
              </p>
              <p>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#160266" d="M156.3 58.2c5.7-6.8 4.7-16.9-2-22.5s-16.9-4.7-22.5 2L62.9 120.3 27.3 84.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l48 48c3.2 3.2 7.5 4.9 12 4.7s8.7-2.3 11.6-5.7l80-96zm0 160c5.7-6.8 4.7-16.9-2-22.5s-16.9-4.7-22.5 2L62.9 280.3 27.3 244.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l48 48c3.2 3.2 7.5 4.9 12 4.7s8.7-2.3 11.6-5.7l80-96zM192 96c0 8.8 7.2 16 16 16l288 0c8.8 0 16-7.2 16-16s-7.2-16-16-16L208 80c-8.8 0-16 7.2-16 16zm0 160c0 8.8 7.2 16 16 16l288 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-288 0c-8.8 0-16 7.2-16 16zM160 416c0 8.8 7.2 16 16 16l320 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-320 0c-8.8 0-16 7.2-16 16zm-64 0a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/></svg>
                <span class="info-text">{{ infoCount.totalQuizes }}  <span class="info-text-min">questionários</span></span>
              </p>
          </div>
          <div class="col-form-inner col-6 col-md-6 col-lg-6 flex end" id="changeSeasonAddVideo">
              <div class="form-group flex" id="addNewVideo">
                <div class="search-container">
                  <input 
                    type="text" 
                    v-model="searchTerm"
                    placeholder="Pesquisar módulos..."
                    class="search-input"
                  >
                  <fa icon="fa-times" v-if="searchTerm" @click="searchTerm = ''" class="clear-icon"/>
                  <fa icon="fa-search" v-else class="search-icon"/>
                </div>
                <button class="btnWeflix" @click="openNewModuleDialog">
                  <fa icon="fa-solid fa-plus"/><span class="info-text-min">Novo Módulo</span>
                </button>
              </div>
          </div>
      </div>

      <draggable
      :list="filteredModules"
      :disabled="false"
      class="list-group"
      ghost-class="ghost"
      @start="dragging = true"
      @end="onDragEnd"
      handle=".drag-handle"
    >
      <template #item="{ element }">
        <div class="table-modules">
          <div class="title-table" @click="element.isOpen = !element.isOpen">
            <div class="module-header">
              <div class="drag-handle">
                <fa icon="fa-bars"/>
              </div>
              {{ element.title }}
              <span class="lesson-count">({{ element.lessons.length }} atividades)</span>
            </div>
            <div class="module-actions">
              <div @click.stop="openModalEditModule(element.id, element.title)" class="btn-action">
                <fa icon="fa fa-pencil"/>
              </div>
              <div class="dropdown" @click.stop>
                <button class="btn-action dropdown-toggle" @click="element.showDropdown = !element.showDropdown">
                  <fa icon="fa-solid fa-plus"/> <span class="info-text-min" @click="storeCurrentModuleLocal(element)">Nova Atividade</span>
                </button>
                <div class="dropdown-content" v-show="element.showDropdown">
                  <div class="dropdown-item">
                    <router-link to="/dashboard/novo-video" @click="storeCurrentModuleLocal(element)">
                      <fa :icon="getLessonIcon(3)"/> Vídeo
                    </router-link>
                  </div>
                  <div class="dropdown-item">
                    <router-link to="/dashboard/novo-arquivo" @click="storeCurrentModuleLocal(element)">
                      <fa :icon="getLessonIcon(2)"/> Arquivo
                    </router-link>
                  </div>
                  <div class="dropdown-item">
                    <router-link to="/dashboard/novo-quiz" @click="storeCurrentModuleLocal(element)">
                      <fa :icon="getLessonIcon(4)"/> Questionário
                    </router-link>
                  </div>
                </div>
              </div>
              <div class="btn-action">
                <fa :icon="element.isOpen ? 'fa-chevron-up' : 'fa-chevron-down'"/>
              </div>
            </div>
          </div>

          <draggable
            v-show="element.isOpen"
            :list="element.lessons"
            :disabled="false"
            ghost-class="ghost"
            @start="draggingLesson = true"
            @end="onDragEndLesson(element.id)"
            handle=".drag-handle"
            class="list-lessons"
          >
            <template #item="{ element: lesson}" >
              <div class="lesson-row">
                <div class="lesson-content">
                  <div class="drag-handle">
                    <fa icon="fa-bars"/>
                  </div>
                  <span style="color: #d4d4d4;">|</span>
                  <span class="lesson-icon">
                    <fa :icon="getLessonIcon(lesson.type)" />
                  </span>
                  {{ lesson.title }}
                </div>
                <div @click="openModalEdit(lesson)"
                  class="btn-action">
                  <fa icon="fa fa-pencil"/>
                </div>
                <div 
                class="btn-action" @click="openModalDelete(lesson)">
                <fa icon="fa fa-trash"/>
              </div>
              </div>
            </template>
          </draggable>

        </div>
      </template>
    </draggable>

  
      <div class="noVideos" v-if="videos?.length === 0 && hasReady">
          <p>Envie novos videos para esta coleção clicando aqui <fa icon="fa-plus" @click="(()=>this.$router.push(`/admin/novo-video`))"/></p>
      </div>

    </div>
      <div class="pa-4 text-center">
        <v-dialog
          v-model="deleteItem"
          max-width="320"
          persistent
        >
          <v-card>
            <v-card-title class="headline">Atenção</v-card-title>
    
            <v-card-text>
              Deseja realmente excluir o vídeo {{ nameItem }}?
            </v-card-text>
    
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="cancel" text @click="closeDialog">Não</v-btn>
              <v-btn class="accept" text @click="closeDialog">Sim</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <Loader :hasReady="hasReady"/>
      <CreateModule :show="true" />
      <CreateLesson :show="true" />

      <v-dialog v-model="newModuleDialog" max-width="600">
        <v-card class="new-module-dialog">
          <v-card-title class="new-module-title">Criar Novo Módulo</v-card-title>
          <v-card-text>
            <v-text-field v-model="newModuleTitle" label="Título do Módulo" required></v-text-field>
          </v-card-text>
          <v-card-actions class="new-module-actions">
            <v-spacer></v-spacer>
            <v-btn class="new-module-btn cancel" text @click="newModuleDialog = false">Cancelar</v-btn>
            <v-btn class="new-module-btn confirm" text @click="createNewModule" :disabled="newModuleTitle.length === 0">Criar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="editModuleDialog" max-width="600">
        <v-card class="edit-module-dialog">
          <v-card-title class="edit-module-title">Editar Módulo</v-card-title>
          <v-card-text>
            <v-text-field v-model="editModuleTitle" label="Título do Módulo" required></v-text-field>
          </v-card-text>
          <v-card-actions class="edit-module-actions">
            <v-spacer></v-spacer>
            <v-btn class="new-module-btncancel" text @click="editModuleDialog = false">Cancelar</v-btn>
            <v-btn class="new-module-btn confirm" text @click="updateModule" :disabled="editModuleTitle.length === 0">Salvar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="deleteModuleDialog" max-width="600">
        <v-card class="edit-module-dialog">
          <v-card-title class="edit-module-title">Excluir Módulo</v-card-title>
          <v-card-text>
            Tem certeza que deseja excluir este módulo? Esta ação não pode ser desfeita.
          </v-card-text>
          <v-card-actions class="edit-module-actions">
            <v-spacer></v-spacer>
            <v-btn class="new-module-btncancel" text @click="deleteModuleDialog = false">Cancelar</v-btn>
            <v-btn class="new-module-btn confirm" text @click="deleteLesson">Deletar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>


   
      <!-- <v-dialog v-model="deleteModuleDialog" max-width="400">
        <v-card>
          <v-card-title class="headline">Confirmar Exclusão</v-card-title>
          <v-card-text>
            Tem certeza que deseja excluir este módulo? Esta ação não pode ser desfeita.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="cancel" text @click="deleteModuleDialog = false">Cancelar</v-btn>
            <v-btn class="delete" text @click="deleteModule">Excluir</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog> -->
</template>

<script>
import Sesson from '@/utils/Weflix/sesson';
import Loader from '@/components/Weflix/Loader.vue'
import ApiModules from '@/utils/modules'
import ApiLesson from '@/utils/lesson'
import Courses from '@/utils/courses'
import { mapState, mapActions } from 'vuex';
import draggable from 'vuedraggable'

export default {
  data(){
      return{
          seasson: [],
          valueProgressTemporary:0, 
          deleteItem: false,
          nameItem: '',
          season:'',
          seasonSelected: null,
          modules: [],
          hasReady: false,
          descriptionCollection: '',
          nameCollection: '',
          totalVideos: 0,
          totalMinutes: 0,
          totalViews: 0,
          dataViewVideos: [],
          manySeason: false,
          dragging: false,
          draggingLesson: false,
          allModulesOpen: true,
          searchTerm: '',
          infoCount: [],
          newModuleDialog: false,
          newModuleTitle: '',
          newModuleDescription: '',
          editModuleDialog: false,
          editModuleTitle: '',
          currentModuleId: null,
          deleteModuleDialog: false,
          selectedLessonDelete:[{
            id: null,
            title: ''
          }]
          
      }
  },
  components: {
      Loader,
      draggable,
  },
  computed: {
      ...mapState(['token', 'currentCollections', 'currentModule']),
      filteredModules() {
        if (!this.searchTerm) return this.modules;
        
        const searchLower = this.searchTerm.toLowerCase();
        return this.modules.filter(module => 
          module.title.toLowerCase().includes(searchLower)
        );
      }
  },
  methods: {
    ...mapActions(['storeCurrentModule']),

    storeCurrentModuleLocal(item){
      this.storeCurrentModule({currentModule: item});
    },
    openModalDelete(module){
      this.deleteModuleDialog = true;
      this.selectedLessonDelete = module;
    },
      openNewModuleDialog() {
          this.newModuleDialog = true;
      },
      async createNewModule() {
          const newModule = {
              title: this.newModuleTitle,
          };
          const payload = {
            "idCourse": this.currentCollections.id,
            "title": this.newModuleTitle,
            "sample": 0
          }
          try {
            const response = await ApiModules.createNewModule(payload, this.token);
            this.getModules();
            this.newModuleDialog = false;
            this.newModuleTitle = '';
            this.newModuleDescription = '';
          } catch (error) {
            console.error('Erro ao criar novo módulo:', error);
          }
         
      },
      toggleAllModules() {
        this.allModulesOpen = !this.allModulesOpen;
        this.modules.forEach(module => {
          module.isOpen = this.allModulesOpen;
        });
      },
      getLessonIcon(type) {
      
        const icons = {
          1: 'fa-solid fa-video',
          2: 'fa-solid fa-video',
          3: 'fa-solid fa-video',
          4: 'fa-solid fa-file-pdf',
          5: 'fa-solid fa-list-check'
        }
        return icons[type]
      },
      deleteVideo(id, name){
          this.deleteItem = true;
          this.nameItem = name;
      },
      closeDialog(){
          this.deleteItem = false;
      },
      editVideo(id){
          this.$router.push('/admin/video/' + id)
      },
      async getSesson(){
          const param = this.$route.params.slug;
          try {
              const response = await Sesson.getSessonByCollection(this.token, param);
              
              if(response.length > 0) {
                  this.season = response;
                  this.seasonSelected = this.season[0].id;
                  this.manySeason = this.season.length > 1;
                  this.getVideos(this.seasonSelected);
              } else {
                  await this.newSeason();
                  const newResponse = await Sesson.getSessonByCollection(this.token, param);
                  this.season = newResponse;
                  this.seasonSelected = this.season[0].id;
                  this.manySeason = this.season.length > 1;
                  this.getVideos(this.seasonSelected);
              }
          } catch (error) {
              console.error('Erro ao obter dados da coleção:', error);
          }
      },
      async getModules(){
      try {
        const response = await ApiModules.getDataModulesAndLessonsByCourse(this.currentCollections.id, this.token);
        this.modules = response.sort((a, b) => a.order - b.order).map((module, index) => ({
          ...module,
          isOpen: index === 0,
          showDropdown: false
        }));
        this.hasReady = true;
      } catch (error) {
        console.error(error);
      }
    },
      changeSeason(){
          this.hasReady = false;
          this.getVideos(this.seasonSelected);
      }, 
      async getCourseTypeCount(){
        try {
          const response = await Courses.getCourseTypeCount(this.token, this.currentCollections.id);
          this.infoCount = response;
        } catch (error) {
          console.error('Erro ao obter contadores do curso:', error);
        }
      },
      async onDragEnd() {
        const payload = this.modules.map((module, index) => ({
          id: module.id,
          order: index + 1
        }));
        
        try {
          await ApiModules.orderModules(this.token, payload);
        } catch (error) {
          console.error('Erro ao alterar ordem dos módulos:', error);
        }
      },
      async onDragEndLesson(moduleId) {
        const module = this.modules.find(m => m.id === moduleId);
        if (!module) return;

        const payload = module.lessons.map((lesson, index) => ({
          id: lesson.id,
          order: index + 1
        }));
        
        try {
          await ApiModules.orderLessons(this.token, payload);
        } catch (error) {
          console.error('Erro ao alterar ordem das lições:', error);
        }
      },
      async getStatusCourse(){
        try {
          const response = await ApiModules.getStatusCourse(this.token, this.currentCollections.id);
          this.infoCount = response;
        } catch (error) {
          console.error('Erro ao pegar status do curso:', error);
        }
      },
      openModalEdit(lesson) {
        if(lesson.type == 5){
          this.$router.push(`/dashboard/editar-quiz/${lesson.id}`);
        }else if(lesson.type == 2 || lesson.type == 3 || lesson.type == 1){
          this.$router.push(`/dashboard/editar-video/${lesson.id}`);
        }else if(lesson.type == 4){
          this.$router.push(`/dashboard/editar-arquivo/${lesson.id}`);
        }
      },
      openModalEditModule(moduleId, moduleTitle) {
          this.currentModuleId = moduleId;
          this.editModuleTitle = moduleTitle;
          this.editModuleDialog = true;
      },
      async updateModule() {
          try {
              const payload = {
                "idCourse": this.currentCollections.id,
                "title": this.editModuleTitle,
                "sample": 0

              };
              await ApiModules.editModule(payload, this.token, this.currentModuleId);
              this.getModules();
              this.editModuleDialog = false;
          } catch (error) {
              console.error('Erro ao atualizar módulo:', error);
          }
      },
      confirmDeleteModule() {
          this.deleteModuleDialog = true;
      },
      async deleteModule() {
          try {
              await ApiModules.deleteModule(this.token, this.currentModuleId);
              this.getModules();
              this.deleteModuleDialog = false;
              this.editModuleDialog = false;
          } catch (error) {
              console.error('Erro ao excluir módulo:', error);
          }
      },
      async deleteLesson() {
        try {
          await ApiLesson.deleteLesson(this.selectedLessonDelete.id, this.token);
          this.getModules();
          this.deleteModuleDialog = false;
          this.editModuleDialog = false;
        } catch (error) {
          console.error('Erro ao excluir lição:', error);
        }
      }
  },
  created(){
    this.getModules();
    this.getStatusCourse();
 
  }
  
}
</script>

<style scoped>
@import url(./global.css);

.container {
  max-width: 100%;
  background-color: white;
  padding: 10px 0 50px;
}

.titleContainer {
  display: block;
  text-align: left;
  padding: 0 10px;
  border-bottom: 1px solid #e8e8e8;
  margin: 10px;
}

.infoCollectionVideos {
  text-align: left;
  padding: 5px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.videosInfoCollection {
  display: flex;
  gap: 30px;
}

.videosInfoCollection p {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  margin: 0;
}

.toggle-all-modules {
  padding: 20px;
  text-align: right;
}

.table-modules {
  background: #fff;
  border-radius: 8px;
  margin: 20px;
}

.title-table {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
}

.drag-handle svg {
  color: #9c9c9c;
}

.module-header {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 1.1em;
  font-weight: 500;
}

.lesson-count {
  font-size: 0.9em;
  color: #6c757d;
  font-weight: normal;
}

.module-actions {
  display: flex;
  gap: 15px;
}

.drag-handle {
  cursor: move;
  color: #6c757d;
  padding: 5px;
  border-radius: 4px;
}

.drag-handle:hover {
  background: #e9ecef;
}
.dark .drag-handle:hover {
  background: #000000;
}

.lesson-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px 15px 40px;
  border-bottom: 1px solid #e9ecef;
  transition: background-color 0.2s;
}

.lesson-row:hover {
  background-color: #f8f9fa;
}

.lesson-content {
  display: flex;
  align-items: center;
  gap: 12px;
  flex: 1;
}

.lesson-icon {
  color: #6c757d;
  width: 20px;
  text-align: center;
}

.btn-action {
  padding: 6px;
  cursor: pointer;
  color: #6c757d;
  border-radius: 4px;
  transition: all 0.2s;
}

.btn-action:hover {
transform: scale(1.05);
}

.ghost {
  opacity: 0.5;
  background: #e3f2fd !important;
  border: 2px dashed #90caf9;
}

.btnWeflix {
  padding: 8px 16px;
  border-radius: 4px;
  background: #250891!important;
  border: 1px solid #250891!important;
  color: #ffffff!important;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.3s;
}

.btnWeflix:hover {
  background: #250891;
  color: #fff;
}

.search-container {
  position: relative;
  margin-right: 10px;
  width: 65%;
}

.search-input {
  padding: 8px 16px;
  padding-right: 35px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  width: 100%;
  font-size: 14px;
  transition: all 500ms ease-in-out;
}

.search-input:focus,
.search-input:not(:placeholder-shown) {
  border-color: #250891;
}

.search-icon, .clear-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #dddddd;
  cursor: pointer;
}

.clear-icon:hover {
  color: #dc3545;
}

@media (max-width: 600px) {
  .infoCollectionVideos {
    flex-direction: column;
    gap: 20px;
    padding: 20px 10px;
  }
  
  .videosInfoCollection {
    flex-wrap: wrap;
  }
  
  #changeSeasonAddVideo {
    width: 100%;
  }
  
  .btnWeflix {
    width: 100%;
    justify-content: center;
  }

  .search-input {
    width: 100%;
  }
}

.infoCollectionStats:before {
  content: '';
  width: 1px;
  height: 110%;
  background-color: #eef0fc;
  display: block;
  position: absolute;
  top: -6px;
  /* width: 20%; */
  margin-left: -10px;
}
.infoCollectionStats{
  width: 23%;
  display: flex;
  flex-wrap: wrap;
  padding: 10px 10px;
  position: relative;
  gap: 15px;
}
.titleContainer {
  display: flex;
  text-align: left;
  padding: 10px;
  border-bottom: 1px solid #e8e8e8;
  margin: 0;
  gap: 10px;
}
.title-banner-course {
  width: 15%;
  position: relative;
}
.title-banner-course img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.title-content {
  width: 60%;
}
button.editCollections {
  position: relative;
  top: 25px;
  right: 25px;
  display: flex;
  border: 2px solid #160266;
  padding: 5px;
  border-radius: 5px;
  color: #160266;
}
button.editCollections svg {
  color: #160266;
}
.status-flag {
  position: absolute;
  bottom: 5px;
  right: 7px;
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
}
.active {
  background: #e2fdde;
  color: #009688;
}
.inactive {
  background: #dc3545;
  color: white;
}
.statsRow > svg {
  background-color: #eef0fc;
  padding: 5px;
  border-radius: 5px;
  margin-right: 10px;
  color: #160266;
}
.statsRow {
  display: flex;
  align-items: center;
}
.statsRow strong {
  padding-left: 10px;
  font-weight: 800;
  color: #250a91;
  font-size: 14px;
}
.statsRow span {
  font-size: 13px;
  color: #250991;
}
.title-content h3 {
  font-size: 20px;
  font-weight: 700;
  margin: 0px;
  margin-bottom: 5px;
}
svg.svg-inline--fa.fa-xmark.clear-icon {
    color: #cccccc;
}
div#changeSeasonAddVideo {
    width: 56%;
}
div#addNewVideo {
    width: 100%;
}

.videosInfoCollection p svg {
  width: 15px;
}
.list-lessons {
  width: 97%;
  margin: 0 auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #dfdfdf;
  border-radius: 0px 0px 10px 10px;
  border-top: none;
}

/* Estilos adicionados para o dropdown */
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  position: absolute;
  right: 0;
  top: 100%;
  background-color: #fff;
  min-width: 195px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  border-radius: 4px;
  z-index: 1;
}

.dropdown-item {
  padding: 10px 15px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.dropdown-item:hover {
  background-color: #f8f9fa;
}

.dropdown-toggle {
  display: flex;
      align-items: center;
      gap: 8px;
      background: none;
      border: none;
      font-size: inherit;
      background-color: #250991;
      color: white;
      padding: 8px 10px; 
}
.lesson-content span.lesson-icon svg {
  color: #250891;
}
.module-actions > .btn-action svg {
  color: #250a91;
}
button.btn-action.dropdown-toggle svg {
  color: white;
}
.dropdown-content svg {
  color: #250a91;
}
div#addNewVideo button.btnWeflix svg {
  color: white;
}
@media screen and (max-width: 768px) {
  .titleContainer {
    display: block;
    /* text-align: left; */
    padding: 10px;
    border-bottom: 1px solid #e8e8e8;
    margin: 0;
    gap: 10px;
}
.title-banner-course {
  display: none;
}
  .title-content {
    width: 100%;
  }
  button.editCollections {
    position: absolute;
    top: 20px;
    right: 15px;
    display: flex;
    border: none;
    padding: 5px;
    border-radius: 5px;
    color: #160266;
} 
span.edit-text {
  display: none;
}
.statsRow span {
  display: none;
}
.infoCollectionStats[data-v-d82f58f6] {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 10px 10px;
  position: relative;
  gap: 15px;
}
span.info-text-min{
  display: none;
}
div#changeSeasonAddVideo[data-v-d82f58f6] {
  width: 100%;
}
.btnWeflix[data-v-d82f58f6] {
  width: 15%;
  justify-content: center;
}
.search-container {
  position: relative;
  margin-right: 10px;
  width: 80%;
}
.table-modules {
  background: #fff;
  border-radius: 8px;
  margin: 0px 11px;
  margin-bottom: 20px;
  overflow: hidden;
}
span.lesson-count{
  display: none;
}
.title-table {
  padding: 10px !important;
}
.module-header {
  font-size: 13px;
}
.dropdown-toggle {
  padding: 8px 0px;
}
.dropdown-toggle::after{
  display: none;
}
.module-actions svg {
  font-size: 16px;
}
.module-actions {
  display: flex;
  gap: 5px;
}
.btn-action {
  padding: 6px 0px;
  cursor: pointer;
  color: #6c757d;
  border-radius: 4px;
  transition: all 0.2s;
}
.lesson-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 5px 15px 5px;
  border-bottom: 1px solid #e9ecef;
  transition: background-color 0.2s;
} 
.lesson-content {
  display: flex;
  align-items: center;
  gap: 12px;
  flex: 1;
  font-size: 14px;
}
}
.dropdown-item a {
  color: #250891;
  text-decoration: none;
}
button.v-btn.v-btn--slim.v-theme--light.v-btn--density-default.v-btn--size-default.v-btn--variant-text.new-module-btn.cancel {
  color: white !important;
  background-color: gray !important;
  border: gray !important;
}
button.v-btn.v-btn--slim.v-theme--light.v-btn--density-default.v-btn--size-default.v-btn--variant-text.new-module-btn.confirm{
  background: #250891 !important;
  border: 1px solid #250891 !important;
  color: white !important;
}
.btn-action:hover svg {
  color: #250891;
}
.dark .btn-action:hover svg {
  color: #000000;
}
/* DARK */
.dashboard.dark .content.dashboard > .container.weeducAdminCourse {
    background-color: #000;
}
.dashboard.dark .container.weeducAdminCourse .title-content h3,
.dashboard.dark .container.weeducAdminCourse .title-content small,
.dashboard.dark .container.weeducAdminCourse .title-actions button.editCollections svg,
.dashboard.dark .container.weeducAdminCourse .title-content span {
  color: white !important;
}
.dashboard.dark .container.weeducAdminCourse .module-actions .btn-action:hover {
  color: white !important;
}
.dashboard.dark .container.weeducAdminCourse .title-actions button.editCollections {
  color: white;
  background-color: #160565;
}
.dashboard.dark .container.weeducAdminCourse .title-actions button.editCollections:hover {
  color: white;
  background-color: #250991;
}
.dashboard.dark .container.weeducAdminCourse .statsRow > svg {
  background-color: transparent;
}
.dashboard.dark .container.weeducAdminCourse .lesson-content span.lesson-icon svg {
  color: white !important;
}
.dashboard.dark .container.weeducAdminCourse .statsRow span,
.dashboard.dark .container.weeducAdminCourse .statsRow strong {
  color: white !important;
}
.dashboard.dark .container.weeducAdminCourse .videosInfoCollection p svg path {
  fill: white;
}
.dashboard.dark .container.weeducAdminCourse .videosInfoCollection p span {
  color: white;
}
.dashboard.dark .container.weeducAdminCourse .search-input {
  border: 1px solid #444;
  background-color: #575757;
  color: white;
}
.dashboard.dark .container.weeducAdminCourse .search-input::placeholder {
  color: white;
}
.dashboard.dark .container.weeducAdminCourse .table-modules {
  background: #000;
}
.dashboard.dark .container.weeducAdminCourse .module-header {
  color: white;
}
.dashboard.dark .container.weeducAdminCourse .module-header span {
  color: white;
}
.dashboard.dark .container.weeducAdminCourse .title-table {
  background-color: #575757;
  border: 1px solid #444;
}
.dashboard.dark .container.weeducAdminCourse .module-actions > .btn-action svg {
    color: white;
}
.dashboard.dark .container.weeducAdminCourse .module-actions > .btn-action:hover svg {
  color: #000000;
}
.dashboard.dark .container.weeducAdminCourse .list-lessons {
  background-color: #575757;
}
.dashboard.dark .container.weeducAdminCourse .list-lessons .lesson-row:hover {
  background-color: #444;
}
.dashboard.dark .container.weeducAdminCourse .list-lessons .lesson-row,
.dashboard.dark .container.weeducAdminCourse .titleContainer {
  border-bottom: 1px solid #444;
}
.dashboard.dark .container.weeducAdminCourse .list-lessons {
  border: 1px solid #444;
}
.dashboard.dark .container.weeducAdminCourse .lesson-content,
.dashboard.dark .container.weeducAdminCourse .dropdown-item a {
  color: white !important;
}
.dashboard.dark .container.weeducAdminCourse .dropdown-content {
  background-color: #575757 !important;
}
.dashboard.dark .container.weeducAdminCourse .dropdown-item:hover {
  background-color: #444 !important;
}
.btn-action:hover svg.svg-inline--fa.fa-trash {
  color: #ff0000;
}
</style>


