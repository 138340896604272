import api from './api';

export default {
    async getWebsite(accessToken) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get('/website', {
                method: 'GET',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados do website:', error);
            throw error;
        }
    },

    async editWebsite(accessToken, id, payload) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.put(`/website/${id}`, payload ,{
                method: 'PUT',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao atualizar dados do website:', error);
            throw error;
        }
    },
    
    async createWebsite(accessToken, payload){
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.post('/website', payload, {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao criar website:', error);
            throw error;
        }
    },
    async verifyDomaintData( accessToken, payload) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.post(`/manager/checkdomain`, payload , {
                method: 'POST',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao verificar url de dominio', error);
                throw error;
        }
    },
    async clearCache( accessToken) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.delete(`/manager/cache/clear`, {
                method: 'DELETE',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao limpar cache', error);
                throw error;
        }
    },
}
