<template>
    <div class="container weflix-admin-newCollection">
        <div class="titleContainer">
            <h3>Nova Coleção</h3>
            <small>Crie nova coleção de videos</small>
        </div>
        <div class="contetContainerEditVideo">
            <div class="col-form-inner col-12 col-md-7 col-lg-7 freeVideo">
     
              <div class="col-form-inner col-12 col-md-12 col-lg-12">
                  <div class="form-group">
                    <div class="form-label">
                      <label>Nome da coleção<v-tooltip text="Campo obrigatório" location="top">
                        <template v-slot:activator="{ props }">
                          <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                        </template>
                      </v-tooltip></label>
                    </div>
                    <div class="form-input">
                      <input
                        type="text" 
                        required
                        class="form-control"
                        v-model="name"
                        placeholder="Título da sua coleção"
                      />
                      <small>Nome da coleção, use um nome descritivo para facilitar a identificação.</small>
                    </div>
                  </div>
            </div>

            <div class="col-form-inner col-12 col-md-12 col-lg-12">
                <div class="form-group">
                  <div class="form-label">
                    <label>Descrição da coleção<v-tooltip text="Campo obrigatório" location="top">
                      <template v-slot:activator="{ props }">
                        <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                      </template>
                    </v-tooltip></label>
                  </div>
                  <div class="form-textarea full-descroption">
                    <QuillEditor theme="snow" toolbar="essential" 
                    @input="updateContentLongDescription($event.srcElement.innerHTML)"
                    v-model:content="longDescription"
                    :options="{ placeholder: 'Descrição completa do video (detalhes, objetivos, público-alvo etc.)' }"
                    contentType="html"/>
                  </div>
                </div>
            </div>

            <!-- Status da Coleção -->
            <div class="col-form-inner col-12 col-md-12 col-lg-12">
                <div class="form-group">
                    <div class="form-label">
                        <label>Status da Coleção</label>
                        <small>Define se a coleção está ativa ou inativa</small>
                    </div>
                    <div class="form-select">
                        <select v-model="status" class="form-control">
                            <option value="1">Ativo</option>
                            <option value="0">Inativo</option>
                        </select>
                    </div>
                </div>
            </div>

            <!-- Tipo de Venda -->
            <div class="col-form-inner col-12 col-md-12 col-lg-12">
                <div class="form-group venda-type">
                    <div class="form-label">
                        <label>Tipo de Venda</label>
                        <small>Escolha o tipo de venda para sua coleção</small>
                    </div>
                    <div class="venda-options">
                        <div class="venda-options">
                            <div class="venda-option" :class="{ active: !vendaAvulsa }" @click="vendaAvulsa = false">
                                <input type="radio" v-model="vendaAvulsa" :value="false" id="vendaNormal">
                                <label for="vendaNormal">
                                    <fa icon="fas fa-user-check"></fa>
                                    <span>Assinatura</span>
                                    <small>Acesso através de plano mensal, semestral ou anual ou seu plano personalizado</small>
                                </label>
                            </div>
                            <div class="venda-option" :class="{ active: vendaAvulsa }" @click="vendaAvulsa = true">
                                <input type="radio" v-model="vendaAvulsa" :value="true" id="vendaAvulsa">
                                <label for="vendaAvulsa">
                                    <fa icon="fas fa-shopping-cart"></fa>
                                    <span>Venda Avulsa</span>
                                    <small>Compra única com acesso temporário</small>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Mensagem informativa para modelo de assinatura -->
            <div v-if="!vendaAvulsa" class="col-form-inner col-12 col-md-12 col-lg-12">
                <div class="info-box">
                    <div class="info-box-header">
                        <h4>Modelo de Assinatura</h4>
                    </div>
                    <div class="info-box-content">
                        <p>No modelo de assinatura, o conteúdo do curso será disponibilizado junto aos demais em um modelo de assinatura.</p>
                    </div>
                </div>
            </div>

            <!-- Campos que aparecem quando venda avulsa está ativa -->
            <div v-if="vendaAvulsa" class="col-form-inner col-12 col-md-12 col-lg-12 info-box">
                <div class="form-group">
                    <div class="form-label">
                        <label>Preço (R$)</label>
                    </div>
                    <div class="form-input price-input">
                        <span class="currency-symbol">R$</span>
                        <input
                            type="number"
                            class="form-control price-info"
                            v-model="preco"
                            placeholder="0.00"
                            step="0.01"
                        />
                    </div>
                </div>

                <div class="form-group">
                    <div class="form-label">
                        <label>Número de Parcelas</label>
                    </div>
                    <div class="form-select">
                        <select v-model="numeroParcelas" class="form-control">
                            <option value="1">1x</option>
                            <option value="2">2x</option>
                            <option value="3">3x</option>
                            <option value="4">4x</option>
                            <option value="5">5x</option>
                            <option value="6">6x</option>
                            <option value="7">7x</option>
                            <option value="8">8x</option>
                            <option value="9">9x</option>
                            <option value="10">10x</option>
                            <option value="11">11x</option>
                            <option value="12">12x</option>
                        </select>
                    </div>
                </div>

                <div class="form-group">
                    <div class="form-label">
                        <label>Tempo de Disponibilidade</label>
                    </div>
                    <div class="form-select">
                        <select v-model="tempoDisponibilidade" class="form-control">
                            <option value="30">30 dias</option>
                            <option value="60">60 dias</option>
                            <option value="90">90 dias</option>
                            <option value="180">180 dias</option>
                            <option value="365">365 dias</option>
                        </select>
                    </div>
                </div>
            </div>

            </div>
          
            <div class="col-form-inner col-12 col-md-5 col-lg-5">
                <div style="position: relative;">
                    <div class="col-form-inner col-12 col-md-12 col-lg-12">
                    <div class="form-group imageCoverPreview" v-if="imagePreviewCover">
                      <img class="coverVideo" :src="imagePreviewCover" />
                    </div>
                     </div>
                    <div class="col-form-inner col-12 col-md-12 col-lg-12 input-video">
                    <div class="form-group">
                        <div class="form-label">
                          <label>Capa da Coleção - Horizontal </label>
                        </div>
                        <div class="form-file">
                          <div class="file-wrapper primary">
                            <span class="thumbnail thumb-img" :style="imagePreviewCover ? {height: '2.5rem'} : {height: '6.5rem'}">
                              <span class="icon" v-if="loaderImage">
                                <svg
                                  width="46"
                                  height="39"
                                  viewBox="0 0 46 39"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  v-if="!imagePreviewCover"
                                >
                                  <path
                                    d="M45 19.9308L36.7274 12.3475C36.1595 11.827 35.2948 11.8047 34.7009 12.2953L18.824 25.411C18.3196 25.8277 17.6074 25.8812 17.0464 25.5446L11.1009 21.9773C10.5893 21.6703 9.94666 21.6856 9.45024 22.0166L1 27.6501"
                                    stroke="#9D9FA3"
                                    stroke-width="1.25714"
                                  ></path>
                                  <rect
                                    x="1.00049"
                                    y="1.40454"
                                    width="43.9461"
                                    height="36.1909"
                                    rx="2.58507"
                                    stroke="#9D9FA3"
                                    stroke-width="1.25714"
                                  ></rect>
                                  <circle
                                    cx="14.8948"
                                    cy="10.6676"
                                    r="3.71629"
                                    stroke="#9D9FA3"
                                    stroke-width="1.25714"
                                  ></circle>
                                </svg>
                              </span>
                              <span class="text" v-if="loaderImage">
                                Clique aqui para selecionar a imagem
                              </span>
                              <LoaderImage :hasReady="loaderImage"/>

                            </span>
                            <input class="form-control" type="file" @change="updateFileCover" accept=".png, .jpg, .jpeg, .webp"/>
                          </div>
                          <div class="form-caption">
                            <div class="caption">
                              <small> Tamanho: 1920x200px, apenas formato PNG e JPG.</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>

                <hr>

                <div style="position: relative;">
                    <div class="col-form-inner col-12 col-md-12 col-lg-12">
                        <div class="form-group imageCoverPreview" v-if="imagePreviewCover2">
                        <img class="coverVideo" :src="imagePreviewCover2" style="width: 240px !important; height: 440px !important;" />
                        </div>
                      </div>
                    <div class="col-form-inner col-12 col-md-12 col-lg-12 input-video">
                        <div class="form-group">
                            <div class="form-label">
                            <label>Capa da Coleção - Vertical </label>
                            </div>
                            <div class="form-file">
                            <div class="file-wrapper primary">
                                <span class="thumbnail thumb-img" :style="imagePreviewCover2 ? {height: '2.5rem'} : {height: '6.5rem'}">
                                    <span class="icon" v-if="loaderImage2">
                                    <svg
                                    width="46"
                                    height="39"
                                    viewBox="0 0 46 39"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    v-if="!imagePreviewCover2"
                                    >
                                    <path
                                        d="M45 19.9308L36.7274 12.3475C36.1595 11.827 35.2948 11.8047 34.7009 12.2953L18.824 25.411C18.3196 25.8277 17.6074 25.8812 17.0464 25.5446L11.1009 21.9773C10.5893 21.6703 9.94666 21.6856 9.45024 22.0166L1 27.6501"
                                        stroke="#9D9FA3"
                                        stroke-width="1.25714"
                                    ></path>
                                    <rect
                                        x="1.00049"
                                        y="1.40454"
                                        width="43.9461"
                                        height="36.1909"
                                        rx="2.58507"
                                        stroke="#9D9FA3"
                                        stroke-width="1.25714"
                                    ></rect>
                                    <circle
                                        cx="14.8948"
                                        cy="10.6676"
                                        r="3.71629"
                                        stroke="#9D9FA3"
                                        stroke-width="1.25714"
                                    ></circle>
                                    </svg>
                                </span>
                                <span class="text" v-if="loaderImage">
                                    Clique aqui para selecionar a imagem
                                </span>
                                <LoaderImage :hasReady="loaderImage2"/>

                                </span>
                                <input class="form-control" type="file" @change="updateFileCover2" accept=".png, .jpg, .jpeg, .webp"/>
                            </div>
                            <div class="form-caption">
                                <div class="caption">
                                <small> Tamanho: 240x440px, apenas formato PNG e JPG.</small>
                                </div>
                            </div>
                            </div>
                        </div>
                      </div>
                  </div>
            </div>
        </div>
        <div class="actions newCollectionWeflix">
            <button class="btn" @click="(()=>this.$router.push('/admin/colecoes'))">Cancelar</button>
            <button class="btn" @click="newCollection" :disabled="!loaderImage" :class="{'disabled': !loaderImage}">Salvar</button>
        </div>
      </div>

</template>

<script>
import { mapState, mapActions } from 'vuex';
import apiCollections from '@/utils/Weflix/collections';
import LoaderImage from '@/components/Weflix/LoaderImage';
import apiAWS from '@/utils/storage/aws';

export default {
    data(){
        return{
            name: '',
            description: '',
            coverVideo: '',
            imagePreviewCover: '',
            loaderImage: true,
            vendaAvulsa: false,
            preco: '',
            numeroParcelas: '1',
            tempoDisponibilidade: '1',
            imageCover2: '',
            imagePreviewCover2: '',
            loaderImage2: true,
            status: 1
        }
    },
    computed: {
        ...mapState(['token'])
    },
    components: {
        LoaderImage
    },
    methods: {
        ...mapActions(['storeCurrentCollections']),

        deleteVideo(id, name){
            this.deleteItem = true;
            this.nameItem = name;
        },
        closeDialog(){
            this.deleteItem = false;
        },
        async newCollection() {
            const payload = {
                    "comercialName": this.name,
                    "name": this.name,
                    "status": this.status,
                    "shortDescription": this.description,
                    "imagePath": this.coverVideo,
                    "vendaAvulsa": this.vendaAvulsa,
                    "price": this.vendaAvulsa ? this.preco : null,
                    "installment": this.vendaAvulsa ? this.numeroParcelas : null,
                    "dayExpired": this.vendaAvulsa ? this.tempoDisponibilidade : null,
                    "url": 'www.link.com',
                    "imagePath2": this.imageCover2,
            }
            console.log(payload);
            try {
                const response = await apiCollections.newCollection(this.token, payload);
                this.storeCurrentCollections({currentCollections: response});
                const id = response.id;
                this.$router.push('/admin/colecao/'+id)
            } catch (error) {
                console.error('Erro ao criar Coleção:', error);
            }
        },
        updateContentLongDescription(value){
           this.description = value;    
        },
        async putFileAws(url, file){
          try {
              const response = await apiAWS.putFileAWS(url, file);
          } catch (error) {
              console.error('Erro ao enviar arquivo para AWS');
          }
        },
        async updateFileCover(event){
            this.loaderImage = false;
            const file = event.target.files[0];
            this.imagePreviewCover = URL.createObjectURL(file);
            try {
                const response =  await apiAWS.getSignatureImage(this.token, file.name);
                this.putFileAws(response.url, file);
                this.coverVideo = response.token;
                this.loaderImage = true;
            } catch (error) {
                console.error('Erro na geração do TOKEN AWS')
            }
        },
        async updateFileCover2(event){
            this.loaderImage2 = false;
            const file = event.target.files[0];
            this.imagePreviewCover2 = URL.createObjectURL(file);
            try {
                const response =  await apiAWS.getSignatureImage(this.token, file.name);
                this.putFileAws(response.url, file);
                this.imageCover2 = response.token;
                this.loaderImage2 = true;
            } catch (error) {
                console.error('Erro na geração do TOKEN AWS')
            }
        },

    },  
}
</script>

<style scoped>
@import url(./global.css);
.container {
    max-width: 100%;
 }
.titleContainer {
    display: block;
    text-align: left;
    padding: 0px 10px;
    border-bottom: 1px solid #e8e8e8;
    margin: 10px;
    
}
.container {
    background-color: white;
    padding: 10px 0px;
    padding-bottom: 50px;
}
.CollectionVideos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    padding: 10px;
}
.itemCollectionVideo {
    max-width: 30%;
    height: 248px;
    background-size: cover;
    min-width: 300px;
    width: 27%;
    cursor: pointer;
    position: relative;
    text-align: left;
    padding: 10px;
    border-radius: 5px;

}
.thumb-img path {
    fill: #ffffff!important;
    stroke: #aaaaaa!important;
    stroke-width: 1px!important;
}
img, svg {
    vertical-align: middle;
    width: 35px;
}
.UploadVideoContainer {
    /* max-width: 800px; */
    /* margin: 50px auto; */
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 20px;
    margin: 30px 15px;
}
.col-form-inner.col-12.col-md-12.col-lg-12.input-video {
    max-width: 800px;
    margin: 10px auto;
}
.uploadVideoIcon {
    vertical-align: middle;
    width: 46px;
    padding: 8px;
    border: 1px solid #e4e4e4;
    border-radius: 10px;
}
.infoCollectionVideos {
    text-align: left;
    padding: 0px 20px;
}
svg.svg-inline--fa {
    color: silver;
}
.infoCollectionVideos p {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 14px;
}
button.editCollections {
    position: absolute;
    top: 25px;
    right: 25px;
}
button.editCollections svg {
    transition: all 500ms ease-in-out;
}
button.editCollections:hover svg {
    color: #250891;
}
.itemCollectionVideo .actions {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    text-align: right;
    padding: 10px;
    background-color: transparent;
    transition: all 500ms ease-in-out;
}
button.hoverWhite svg{
    transition: all 500ms ease-in-out;
    font-size: 15px;
}
button.hoverWhite:hover svg{
    color: white;
}
.itemCollectionVideo p {
    color: #c8c8c8;
    margin: 0;
}
/* Estilos melhorados para o tipo de venda */
.venda-type {
    margin: 30px 0;
    background: #ffffff;
    border-radius: 12px;
    padding: 25px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.05);
}
.venda-options {
    display: flex;
    gap: 25px;
    margin-top: 20px;
}
.venda-option {
    flex: 1;
    background: #ffffff;
    border: 2px solid #e9ecef;
    border-radius: 12px;
    padding: 25px;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
}
.venda-option.active {
    border-color: #250891;
    background: #f8f9ff;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(37,8,145,0.1);
}
.venda-option input[type="radio"] {
    display: none;
}
.option-icon {
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    background: #f0f0ff;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.venda-option i {
    font-size: 24px;
    color: #250891;
    transition: all 0.3s ease;
}
.option-content {
    flex-grow: 1;
}
.option-title {
    display: block;
    font-size: 18px;
    font-weight: 600;
    color: #333;
    margin-bottom: 8px;
}
.option-description {
    display: block;
    font-size: 14px;
    color: #666;
    line-height: 1.4;
}
.venda-option:hover {
    border-color: #250891;
    background: #f8f9ff;
}
.venda-option.active .option-icon {
    background: #250891;
}
.venda-option.active i {
    color: #ffffff;
}
.form-label {
    margin-bottom: 15px;
}
.form-label label {
    font-size: 16px;
    font-weight: 600;
    color: #333;
    margin-bottom: 5px;
}
.form-label small {
    display: block;
    color: #666;
    font-size: 14px;
}
/* Estilos para o campo de preço */
.price-input {
    position: relative;
}
.currency-symbol {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #6c757d;
}
.price-input input {
    padding-left: 30px;
}
.form-select select {
    width: 100%;
    padding: 8px;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    background-color: white;
    cursor: pointer;
}
.form-input input[type="number"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
}
/* Estilo melhorado para a caixa de informação */
.info-box {
    background-color: #f8f9ff;
    border: 1px solid #250891;
    border-radius: 12px;
    padding: 20px;
    margin: 20px 10px;
    box-shadow: 0 2px 4px rgba(37, 8, 145, 0.1);
    width: 97%;
}
.info-box-header {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
    border-bottom: 1px solid rgba(37, 8, 145, 0.2);
    padding-bottom: 10px;
}
.info-box-header i {
    color: #250891;
    font-size: 24px;
}
.info-box-header h4 {
    color: #250891;
    margin: 0;
    font-size: 18px;
    font-weight: 600;
}
.info-box-content {
    color: #333;
}
.info-box-content p {
    margin-bottom: 15px;
    line-height: 1.5;
    font-size: 14px;
}
.info-box-content ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
}
.info-box-content ul li {
    position: relative;
    padding-left: 25px;
    margin-bottom: 10px;
    font-size: 14px;
    color: #555;
}
.info-box-content ul li:before {
    content: "✓";
    position: absolute;
    left: 0;
    color: #250891;
    font-weight: bold;
}
.info-box.form-group {
    background-color: #fff;
    padding: 25px;
}
select.form-control {
    border: none !important;
}
@media (max-width: 600px) {
    .weflix-admin-newCollection .titleContainer {
        padding: 0px 10px 10px 10px;
    }
    .weflix-admin-newCollection .contetContainerEditVideo {
        padding: 0px 10px 0px 10px !important;
    }
    .weflix-admin-newCollection .titleContainer h3 {
        font-size: 20px;
        margin-bottom: 5px !important;
    }
    .weflix-admin-newCollection .titleContainer small {
        font-size: 12px;
        color: #666;
    }
    .weflix-admin-newCollection .form-input input {
        margin: 0px 0px 5px;
    }
    .weflix-admin-newCollection .form-input small {
        color: #666;
    }
    .weflix-admin-newCollection .form-label small {
        font-size: 12px;
        margin: 0px 0px 5px 0px;
    }
    .weflix-admin-newCollection .form-group.venda-type {
        padding: 0px;
    }
    .weflix-admin-newCollection .venda-options {
        margin-top: 10px;
        gap: 10px;
    }
    .weflix-admin-newCollection .info-box-header h4 {
        font-size: 16px;
        color: #333333;
    }
    .weflix-admin-newCollection .info-box-content p {
        color: #666666;
    }
    .weflix-admin-newCollection .actions.newCollectionWeflix {
        display: flex;
    }
    .weflix-admin-newCollection .actions.newCollectionWeflix button {
        width: 100%
    }
    .weflix-admin-newCollection .actions {
        padding: 10px 22px;
        align-items: center;
    }
}
</style>
<style>
.v-card.v-theme--light.v-card--density-default.v-card--variant-elevated {
    padding: 35px 0px;
}
.v-card-title.headline {
    text-align: left !important;
    color: red;
    padding: 0 40px;
}
button.v-btn.v-btn--slim.v-theme--light.text-green.darken-1.v-btn--density-default.v-btn--size-default.v-btn--variant-text {
    width: 150px;
    background-color: red;
}
.v-card-actions button {
    width: 50%;
    background-color: gray;
    padding: 0 16px;
}
.v-overlay__content {
    width: 400px !important;
    max-width: 400px !important;
}
.v-card-actions {
    align-items: center;
    display: flex;
    flex: none;
    min-height: 52px;
    padding: 0 40px!important;
}
.cancel {
    background-color: white !important;
    border: 1px solid gray;
}
.accept {
    background-color: red !important;
    border: 1px solid red;
    color: white!important;
}
.contetContainerEditVideo {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}
.col-form-inner.col-12.col-md-7.col-lg-7.freeVideo .v-input__details {
    display: none;
}
.actions > button.btn {
    background-color: #21212100 !important;
    color: #250891 !important;
    border: 1px solid #250891 !important;
    font-size: 18px !important;
    font-weight: 100 !important;
    width: 146px;
    text-align: center;
    transition: all 500ms ease-in-out;

}
.actions {
    display: flex;
    justify-content: end;
    gap: 10px;
    padding: 0px 15px;
}
.actions > button.btn:hover {
    color: white !important;
    background-color: #250891 !important;
}
.exludeVideo {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
}
.exludeVideo svg {
    color: #250891 !important;
}
input.form-check-input {
    width: 3em !important;
}
.form-check-input:checked {
    background-color: #250891!important;
    border-color: #250891!important;
}
.col-form-inner.col-12.col-md-7.col-lg-7.freeVideo {
    display: flex;
    flex-wrap: wrap;
}
.itemAtionCollections.form-check.form-switch {
    padding: 16px 40px;
}
img.coverVideo {
    width: 300px;
    height: auto;
    margin: 0 auto;
    filter: brightness(0.5);
}
.form-group.imageCoverPreview {
    text-align: center;
    position: relative;
}
.info {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}
.info svg {
    font-size: 50px !important;
    color: white !important;
}
input.form-control.price-info {
    padding-left: 35px !important;
}
.venda-option i {
    font-size: 24px;
    color: #250891;
}
.venda-option span {
    font-size: 16px;
    font-weight: 600;
    color: #333;
}
.venda-option small {
    font-size: 12px;
    color: #6c757d;
    text-align: center;
}
.venda-option label {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}
</style>