<template>
    <div class="global_sidebar">
        <div class="hedaer">
            <div class="top_header">
                <span @click="backPage()" style="display: flex;align-items: center;"><fa icon="arrow-left"/>Voltar</span>
                <span @click="storeShowSidebar({showSidebar:!this.showSidebar});" style="display: flex;align-items: center;" v-show="largura<768"><fa icon="fa-xmark"/></span>

            </div>
            <div class="info-course">
                <span>{{this.currentCoursePortal.comercialName}}</span>
                <div class="progress_item">
                    <div class="progress-container">
                        <div class="progress-bar" :style="{ width: (this.currentCoursePortal.progressPercentage ? this.currentCoursePortal.progressPercentage : 0)+ '%' }">
                        </div>
                    </div>
                    {{formatNumber(this.currentCoursePortal.progressPercentage ? this.currentCoursePortal.progressPercentage : 0)}}%
                </div>
            </div>
        </div>
        <div class="modules">
            <v-expansion-panels variant="accordion" v-model="currentModuleOpenLocal">
                <v-expansion-panel v-for="(item, index) in data" :key="index">
                  <v-expansion-panel-title expand-icon="mdi-menu-down" >
                    <div class="title_accordion">
                      <span>{{item.title}}</span>
                      <span class="count">{{item.lessonQtyRead}} / {{item.lessons.length}}</span>
                    </div>
                  </v-expansion-panel-title>
            
                  <v-expansion-panel-text class="content" :class="{'active' : lesson.id == idLessonPortal}" v-for="(lesson, i) in item.lessons" :key="i">
                    <div class="lesson" @click="setCurrentLesson(lesson.id, i, lesson.type, index, lesson.title)">
                      <i class="pi pi-check-circle" v-show="lesson.status == 1"></i>  
                      <span>{{lesson.title}}</span>
                    </div>
                  </v-expansion-panel-text>
        
                </v-expansion-panel>
              </v-expansion-panels>
        </div>

    </div>
</template>

<script>


import { mapState, mapActions } from 'vuex';
export default{
    data(){
        return{
            progress: 40,
            activePanel:0,
            currentIndex:0,
            nameCourse: 'Curso de Teste',
            currentModuleOpenLocal: this.currentModuleOpen? this.currentModuleOpen : 0,
            largura:window.innerWidth,
        }
    },
    computed:{
    ...mapState(['token','currentLesson','currentModuleOpen','idLessonPortal','nameCurrentLesson','currentCoursePortal']),
    ...mapState(['typeLessonPortal']),
    ...mapState(['showSidebar']),
  },
    props:{
        data:Array,
        currentLesson: String,
        activeModule:Number
    },
    methods:{
        ...mapActions(['storeTypeLessonPortal', 'storeIdLessonPortal','storeCurrentModuleOpen','storeCurrentLesson','storeNameCurrentLesson','storeShowSidebar']),
        
        setCurrentLesson(id, indexLesson, type, index, name){

            console.debug( id, indexLesson, type, index);

            this.storeIdLessonPortal({ idLessonPortal: id });
            this.storeCurrentLesson({currentLesson:indexLesson});
            this.storeTypeLessonPortal({ typeLessonPortal: type });
            this.storeCurrentModuleOpen({ currentModuleOpen: index });
            this.storeNameCurrentLesson({ nameCurrentLesson:name });
            const largura = window.innerWidth;
            if(largura < 768){
                this.storeShowSidebar({showSidebar:false});
            }

            // window.location.reload();


            // this.$emit('lessonChanged', id);
            // this.$emit('typeChanged', type);
            // this.$emit('updateLessonName',name)
            // this.currentIndex = index;
        },
        backPage() {
            this.$router.go(-1);
        }, 
        formatNumber(value) {
            if (typeof value === 'number') {
                return value.toFixed(0);
            } else {
                let parsed = parseFloat(value);
                return isNaN(parsed) ? 'Invalid Number' : parsed.toFixed(0);
            }
        },
    },
    created(){
        if(this.activeModule){
            this.activePanel = this.activeModule;
        } else {
            this.activePanel = 0;
        }
       
    },
    watch: {
    activeModule(newValue, oldValue) {
        this.activePanel = this.activeModule;
    },
  }
}

</script>
<style>
.v-expansion-panel-text__wrapper {
    padding: 16px 10px 16px;
    flex: 1 1 auto;
    max-width: 100%;
}
.dark .v-expansion-panel-text.content {
    background-color: #2a2a2a;
}
</style>
<style scoped>

.global_sidebar{
    background-color: white;
    height: 100%;
}
.top_header {
    cursor: pointer;
    padding: 10px 20px;
    text-align: left;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
}
.top_header i{
    font-size: 12px;
}
.progress-container {
    width: 85%;
    height: 8px;
    background-color: #f0f0f08f;
    margin-bottom: 20px;
    position: relative;
    border-radius: 10px;
    margin: 0 auto;
}
.progress-bar {
    background-color: #84ba41;
    height: 8px;
    border-radius: 10px;
}
.info-course {
    text-align: left;
    padding: 20px;
    font-weight: 700;
}
.progress_item {
    display: flex;
    align-items: center;
    font-size: 12px;
    gap: 15px;
    font-weight: 500;
    margin-top: 5px;
}
i.pi.pi-check-circle {
    color: #84ba41;
    
}
.title_accordion {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.lesson{
    text-align: left;
    cursor: pointer;
    display: flex;
    gap: 10px;
    align-items: center;
}
span.count {
    font-size: 12px;
    color: gray;
}
 button.v-expansion-panel-title.v-expansion-panel-title--active {
    background-color: #e3e3e3;
}
.dark button.v-expansion-panel-title.v-expansion-panel-title--active {
    background-color: #666666;
}
.v-expansion-panel-text__wrapper {
    padding: 16px 10px 16px;
    flex: 1 1 auto;
    max-width: 100%;
}
.v-expansion-panel-text.content {
    border-left: 5px solid transparent;
}

.v-expansion-panel-text.content.active {
    border-left: 5px solid #84ba41;
    background-color: #f2f2f2;
}
.global_content_course.dark .v-expansion-panel-text.content.active {
    border-left: 5px solid #84ba41;
    background-color: #000000;
}
.v-expansion-panel-title {
    padding: 24px 10px;
}
.modules {
    overflow-y: auto;
    height: 86vh;
}
.global_content_course.dark .lesson {
    color: white;
}
svg.svg-inline--fa.fa-arrow-left {
    margin-right: 10px;
}
.dark button.v-expansion-panel-title.v-expansion-panel-title--active span.count {
    color: white;
}
</style>