<template>
    <div class="subscribe" :class="openModalLogin? 'open':''">
        <div class="headerSubscribe">
            <span class="close" @click="toggleModal"><fa icon="fa-xmark"/>Fechar</span>
        </div>
        <div class="titleItemSubscribe">
            <h4>Escolha o plano que mais combina com você!</h4>
        </div>
        <div class="itensSubscribe">
            <div class="itemSubscribe" v-for="plan in filteredPlans" :key="plan.id" :class="plan.recommended === 1 ? 'primary' : ''" @click="goToCheckout(plan.name, plan.planValue, plan.planType, plan.id)">
                <div class="contentItem" :class="plan.planValue === 0 ? 'trial' : ''">
                    <div class="title">{{plan.name == "Trial" ? `Teste Grátis`  : plan.name}}</div>
                    <small class="freqeuncy" v-if="plan.planValue !== 0">
                        {{
                            plan.planType === 1 ? 'Mensal' :
                            plan.planType === 2 ? 'Trimestral' :
                            plan.planType === 3 ? 'Bimestral' :
                            plan.planType === 4 ? 'Anual' :
                            "Desconhecido"
                        }}
                    </small>
                    <small class="freqeuncy" v-if="trialWeflix && plan.planValue === 0  ">Experimente por {{trialWeflix.days}} dias</small>
                    <div class="price" v-if="plan.planValue !== 0">
                        <span class="moeda">R$</span><span class="priceValue">{{formatarParaReal(plan.planValue)}}</span>
                        <small class="freqeuncy" v-if="plan.planValue !== 0"> POR {{
                            plan.planType === 1 ? 'MÊS' :
                            plan.planType === 2 ? 'TRIMESTRE' :
                            plan.planType === 3 ? 'BIMESTRE' :
                            plan.planType === 4 ? 'ANO' :
                            "Desconhecido"
                        }}</small>
                    </div>
                </div>
                <div v-if="plan.planValue !== 0">
                    <small class="message">Este plano é automaticamente renovado a cada <strong>{{
                        plan.planType === 1 ? 'mês' :
                        plan.planType === 2 ? '3 meses' :
                        plan.planType === 3 ? '6 meses' :
                        plan.planType === 4 ? '12 meses' :
                        "Desconhecido"
                    }}</strong>.</small>
                </div>
                <!-- <div class="badge" v-show="plan.planValue === 0">TRIAL</div> -->
            </div>
        </div>
    </div>
    <div class="backgroundLoginRegister"></div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
    data(){
        return{
            openModalLogin:false,
            typeLogin:1,
            hasReadyToLogin:false,
            typeInput:'password'
        }
    },
    props:{
       show:Boolean
    },
    computed:{
        ...mapState(['plansWeflix', 'trialWeflix', 'activeTrial', 'userPortalWeflix']),
        filteredPlans() {
            return this.plansWeflix
                .filter(plan => !(plan.planValue === 0 && this.userPortalWeflix && this.userPortalWeflix.hasTrial === 0))
                .sort((a, b) => a.planValue - b.planValue);
        }
    },
    methods:{
        ...mapActions(['storeItemCheckoutWeflix', 'storeNoLogin']),
        toggleModal(){
           this.$emit('toggleModal');
        },
        formatarParaReal(valor) {
            const valorNumerico = parseFloat(valor);
            if (isNaN(valorNumerico)) {
                return "0,00";
            }
            return valorNumerico.toFixed(2).replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
        },
        goToCheckout(name, price, type, id){
            if(!this.userPortalWeflix){
              this.storeNoLogin({noLogin:true});
              return;
            }
            const plan = {
                "name":name,
                "price":price,
                "type":type,
                "id":id
            }
            document.body.style.overflow = '';
            this.storeItemCheckoutWeflix({itemCheckoutWeflix:plan});
            this.$router.push('/checkoutweflix');
        }
    },
    watch:{
        openModalLogin(novoValor){
            document.body.style.overflow = novoValor ? 'hidden' : '';
        },
        show(novoValor){
            this.openModalLogin = novoValor;
        }
    },    
}
</script>

<style scoped>
.subscribe {
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    opacity: 0;
    z-index: -1;
    transition: all 200ms ease-in-out;
}
.subscribe.open {
    z-index: 103;
    opacity: 1;
}
span.close svg.svg-inline--fa.fa-xmark {
    color: gray;
}
.headerSubscribe {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    color: gray;
}
span.close {
    display: flex;
    gap: 15px;
    align-items: center;
    color: gray;
    cursor: pointer;
}
.itensSubscribe {
    max-width: 850px;
    margin: 0 auto;
    display: flex;
    gap: 20px;
    overflow-x: auto;
}
.itemSubscribe.primary .contentItem {
    border: 3px solid;
    background-color: #eef0f5;
    border-radius: 5px;
    
}
 .contentItem {
    border: 3px solid #eef0f5;
    background-color: #eef0f5;
    border-radius: 5px;
    padding: 10px;
    min-height: 130px;
    text-align: center;
    position: relative;
    transition: all 0.5s ease-in-out;
    cursor: pointer;
}
@media (max-width: 600px) {
    .contentItem {
        min-height: 100px;
    }
    .price {
        margin-top: 10px!important;
    }
}
.itemSubscribe {
    width: 33%;
    position: relative;
}
.contentItem .title {
    text-align: center;
    font-weight: 900;
    display: block;
}
small.freqeuncy {
    font-size: 9px;
    color: gray;
    display: block;
}

.discount {
    font-size: 11px;
    color: gray;
    position: absolute;
    bottom: 5px;
    width: 175px;
}
small.message {
    font-size: 11px;
    color: gray;
    margin-top: 10px;
    display: block;
}
.badge {
    position: absolute;
    top: -8px;
    background-color: black;
    border-radius: 5px;
    left: 29%;
    font-size: 10px;
}
.itemSubscribe:hover .contentItem {
    background-color: white;
}
.titleItemSubscribe {
    max-width: 600px;
    margin: 0 auto;
    margin-top: 300px;
    margin-bottom: 50px;
}
.titleItemSubscribe h4 {
    font-size: 29px;
    font-weight: 800;
}
span.moeda {
    font-size: 12px;
    margin-right: 5px;
}
span.priceValue {
    color: black;
    font-weight: 700;
    font-size: 20px;
}
@media (max-width: 600px) {
    .titleItemSubscribe {
        margin-top: 5vw;
    }
    .itensSubscribe {
        margin: 0 10px;
        flex-wrap: wrap;
        height: 70vh;
        overflow-y: scroll;
    }
    .itemSubscribe.primary {
        width: 45vw;
    }
    .itemSubscribe.trial {
        width: 95vw;
    }
}
.contentItem.trial {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    align-items: center;
}
</style>