<template>
    <Header @toggleModal="toggleOpenDialog" @toggleModalSubscribe="toggleOpenSubscribe"/> 
    <section class="terms">
        <div class="titleTerm"><h1>Termos de Uso</h1></div>
        <div class="contentTerm">
            <p>
                <b>Samba Play teste-prod</b>, brasileiro, inscrito no CPF sob o nº 27481473919, residente e domiciliado no endereço Rua Turim, nº 99, bairro Santa Lúcia, na cidade de Belo Horizonte, Estado MG, CEP 30360552, doravante
                denominado PUBLISHER, SAMBA MOBILE MULTIMÍDIA S/A, sociedade inscrita no CNPJ sob o nº 06.165.667/0001-20, com sede na Rua Turim, nº 99, sala 02, bairro Santa Lúcia, na cidade de Belo Horizonte, Estado de Minas Gerais, CEP
                30.360-552, neste ato representada na forma de seu Estatuto Social, (“INTERVENIENTE ANUENTE”), e o USUÁRIO, celebram o presente TERMO DE USO (“TERMO”), que define as condições pelas quais o USUÁRIO poderá acessar e utilizar
                as funcionalidades do sistema denominado SAMBA PLAY (a “Plataforma”), disponibilizado em ambiente web com seu uso limitado às presentes condições de licenciamento.
            </p>
            <h5>1. Aceitação do termo de uso</h5>
            <p>
                1.1. Para a realização do cadastro, utilização das funcionalidades da Plataforma, realização da Assinatura e visualização do Conteúdo do Canal, é obrigatória a leitura, compreensão e “Aceite” do presente TERMO. Ao manifestar
                o “Aceite”, o USUÁRIO declara expressamente que leu, entendeu e compreendeu todos os direitos e obrigações determinados no TERMO, e ainda, concorda e se sujeita a todas as disposições do presente instrumento.
            </p>
            <p>1.2. A utilização da Plataforma, mesmo que parcial ou a título de teste, indica que o USUÁRIO concorda com o presente TERMO.</p>
            <p>
                1.3. O presente TERMO poderá sofrer alterações periódicas, seja por questões legais ou estratégicas do PUBLISHER e da INTERVENIENTE ANUENTE. O USUÁRIO desde já concorda e reconhece que é de sua única e inteira
                responsabilidade a verificação periódica deste TERMO. O PUBLISHER, assim como a INTERVENIENTE ANUENTE poderão, por mera liberalidade, informar ao USUÁRIO sobre alterações significativas neste TERMO, por meio da divulgação de
                avisos ou informativos ao USUÁRIO, enviados por meio do e-mail cadastrado pelo USUÁRIO na Plataforma.
            </p>
            <h5>2. Definições</h5>
            <p>2.1. Para os efeitos deste instrumento, os vocábulos e expressões abaixo têm as seguintes definições, podendo ser utilizados no singular ou plural:</p>
            <blockquote>
                2.1.1. “Samba Play”: consiste em uma plataforma (“Plataforma”), desenvolvida e de propriedade exclusiva da INTERVENIENTE ANUENTE, a qual o PUBLISHER, mediante assinatura de Contrato de Licenciamento com a INTERVENIENTE
                ANUENTE, possui os direitos de uso para realizar a distribuição de vídeos de Conteúdo diversificado produzidos pelo PUBLISHER, sendo um canal de informações variadas, que pode ser utilizado para diversos fins pelo USUÁRIO;
            </blockquote>
            <blockquote>2.1.2. “Canal”: espaço exclusivo do PUBLISHER, para disponibilização do Conteúdo para o USUÁRIO na Plataforma;</blockquote>
            <blockquote>
                2.1.3. “USUÁRIO(S)”: designa a pessoa física, ou jurídica, com plena capacidade de contratar, que acessa a Plataforma e realiza o seu cadastro pessoal de modo a usufruir das funcionalidades oferecidas por esta, aderindo
                desta forma automaticamente ao presente TERMO;
            </blockquote>
            <blockquote>
                2.1.4. “Dados Financeiros”: dados relativos ao cartão de crédito, utilizado pelo USUÁRIO para realização da Assinatura do Canal, tais como, número do cartão de crédito, nome impresso no cartão, data de validade, código de
                segurança e bandeira;
            </blockquote>
            <blockquote>
                2.1.5. “Conteúdo”: conjunto de criações de titularidade do PUBLISHER, e de responsabilidade exclusiva deste, podendo incluir, sem limitações, vídeos, fotografias, ilustrações e outras imagens, sons, animações, obras
                tridimensionais e outras obras que possam ser distribuídas (com ou sem conversão de formatos) na Plataforma pelo PUBLISHER ao USUÁRIO, por meio do Canal;
            </blockquote>
            <blockquote>2.1.6. “Coleções”: ambiente do Canal, no qual fica alocado o Conteúdo do PUBLISHER, para acesso e realização da Assinatura pelo USUÁRIO, separados por temas, a critério do PUBLISHER;</blockquote>
            <blockquote>2.1.7. “Informações Pessoais”: ambiente da Plataforma no qual o USUÁRIO insere seus dados pessoais, solicitados pelo PUBLISHER ou pela INTERVENIENTE ANUENTE;</blockquote>
            <blockquote>
                2.1.8. “Informações Financeiras”: dados relativos ao cartão de crédito, utilizado pelo USUÁRIO para realização da Assinatura do Canal, tais como, número do cartão de crédito, nome impresso no cartão, data de validade, código
                de segurança e bandeira;
            </blockquote>
            <blockquote>2.1.9. “Assinatura”: ato por meio do qual o USUÁRIO, adquire o direito de assistir ao Conteúdo publicado em uma Coleção, pelo prazo, preço e condições pré-determinadas pelo PUBLISHER em seu Canal.</blockquote>
            <h5>3. Concessão de acesso ao Conteúdo</h5>
            <p>
                3.1. O PUBLISHER, proprietário dos direitos autorais do Conteúdo, neste ato e pela melhor forma de direito, autoriza o USUÁRIO, mediante a realização da Assinatura, a acessar o Conteúdo publicado por ele no Canal, por meio
                da Plataforma, pelo prazo e condições determinadas pelo PUBLISHER no momento da realização da Assinatura pelo USUÁRIO.
            </p>
            <p>
                3.2. O USUÁRIO declara-se integralmente responsável pelas Informações Pessoais e Informações Financeiras inseridas na Plataforma, sendo que, compete a este, manter o seu cadastro sempre atualizado, sob pena de o USUÁRIO não
                receber todas e quaisquer informações acerca da Plataforma, em virtude de estar com seus dados desatualizados.
            </p>
            <p>3.3. O USUÁRIO não está autorizado a praticar atos que visem difamar, perseguir, praticar bullying, abusar, assediar, ameaçar ou intimidar o PUBLISHER, em virtude de informações obtidas por meio de acesso ao Conteúdo.</p>
            <h5>4. Funcionamento</h5>
            <p>
                4.1 Funcionamento geral. A Plataforma consiste em uma ferramenta para disponibilização de Conteúdo digital pelo PUBLISHER, no Canal, o qual poderá ser visualizado pelo USUÁRIO, mediante a realização da Assinatura das
                Coleções.
            </p>
            <p>
                4.2 Ao acessar o Canal, mais precisamente a ferramenta “Coleções”, o USUÁRIO poderá visualizar (i) o Conteúdo disponibilizado pelo PUBLISHER, (ii) a quantidade de vídeos disponíveis em cada Coleção, (iii) o tempo total de
                Contéudo publicado na respectiva Coleção e (iv) as condições estabelecidas para a realização da Assinatura.
            </p>
            <blockquote>4.2.1 O PUBLISHER, no momento de disponibilização de cada Coleção, irá definir, a seu único e exclusivo critério, a quantidade de vídeos disponibilizados em cada Coleção, bem como o valor da Assinatura.</blockquote>
            <p>
                4.3 Caso queira realizar a assinatura do Canal, de forma a ter acesso ao Conteúdo publicado pelo PUBLISHER, o USUÁRIO deverá efetuar o seu cadastro, por meio da ferramenta “Cadastra-se”, mediante o fornecimento das
                Informações Pessoais e Informações Financeiras, sendo que ele responderá pela veracidade e atualização dos dados informados no cadastro.
            </p>
            <blockquote>4.3.1 Preenchidos os dados pessoais solicitados nos termos do item 4.3 e aceito o presente TERMO, o USUÁRIO estará apto a realizar a Assinatura do Canal do PUBLISHER.</blockquote>
            <h5>5. Planos de Contratação</h5>
            <p>
                5.1 O USUÁRIO poderá realizar, a seu critério, a Assinatura mensal, trimestral, semestral ou anual do Canal, sendo que os valores, bem como o prazo de duração do acesso, em cada uma das modalidades, estarão discriminados no
                Canal e acessível ao USUÁRIO no momento da Assinatura.
            </p>
            <blockquote>
                5.1.1 Renovação Automática da vigência do Plano contratado. O USUÁRIO desde já se declara ciente de que a assinatura do plano contratado em qualquer das modalidades (mensal, trimestral, semestral ou anual) será renovada
                automaticamente quando do término da vigência do prazo do referido plano, sendo que, a nova cobrança relativa ao valor da Assinatura será realizada automaticamente em seu cartão de crédito até o término da vigência do plano
                renovado. Caso o USUÁRIO não tenha interesse na renovação do plano, deverá cancelá-lo na Plataforma, antes da data de renovação deste.
            </blockquote>
            <blockquote>
                5.1.2 Cancelamento do Plano contratado. O USUÁRIO, desde já, se declara ciente de que o cancelamento da assinatura do plano em qualquer das modalidades (mensal, trimestral, semestral ou anual), nos termos Lei 12.965/2014
                (Marco Civil da Internet), não resultará no reembolso e/ou devolução, pelo PUBLISHER, dos valores já adimplidos pelo USUÁRIO, com exceção da hipótese descrita no item 12.2.1.
            </blockquote>
            <blockquote>
                <blockquote>
                    5.1.2.1 O USUÁRIO declara, também, estar ciente de que o cancelamento e/ou desistência dos planos mensal, trimestral, semestral e/ou anual também não permitem o estorno, devolução ou invalidação do pagamento dos valores
                    futuros e que integram o plano contratado. Dessa forma, em caso de cancelamento do plano contratado durante o prazo de vigência deste, a cobrança relativa ao valor da Assinatura continuará a ser realizada automaticamente
                    em seu cartão de crédito até o término da vigência do plano que houver sido cancelado pelo USUÁRIO.
                </blockquote>
            </blockquote>
            <p>5.2 O PUBLISHER poderá alterar os valores de assinatura do Canal. Caso o PUBLISHER altere o valor da assinatura somente novos USUÁRIOS serão afetados.</p>
            <p>5.3 O pagamento relativo à Assinatura descrito no item 5.1, será realizado pelo USUÁRIO, na própria Plataforma, via cartão de crédito ou Boleto de Cobrança.</p>
            <blockquote>
                5.3.1 O USUÁRIO desde já se declara ciente de que o PUBLISHER e a INTERVENIENTE ANUENTE, não possuem qualquer tipo de responsabilidade, caso a operação por meio do cartão de crédito não seja concluída e/ou aprovada pela
                operadora do cartão.
            </blockquote>
            <h5>6. Suporte Técnico</h5>
            <p>
                6.1 O Suporte Técnico limita-se ao atendimento ao USUÁRIO pelo PUBLISHER, por meio de troca de informações, por e-mail, via Plataforma, na aba “Fale Conosco”, não estando incluído neste serviço, o atendimento in loco ou via
                telefone.
            </p>
            <p>6.2 O Suporte Técnico fornecido pelo PUBLISHER não abrange a conexão com a internet, rede interna e computadores/dispositivos móveis do USUÁRIO.</p>
            <h5>7. Melhorias/Correções/Novas Versões</h5>
            <p>
                7.1 A Plataforma poderá ser aperfeiçoada, conforme conveniência e necessidade, podendo inclusive ocorrer suspensão temporária dos serviços em virtude de aperfeiçoamento. Todas as modificações, melhorias e correções efetuadas
                na Plataforma, ficam incorporadas a esta e sujeitas a este TERMO.
            </p>
            <p>7.2 Faculta-se ao PUBLISHER acolher as sugestões de melhorias realizadas pelo USUÁRIO, não assistindo a ele, em nenhuma hipótese, qualquer direito de propriedade sobre tais inserções ou melhorias.</p>
            <h5>8. Política de Confidencialidade e Privacidade</h5>
            <p>8.1 Cada Parte tratará de forma estritamente confidencial as informações levadas a seu conhecimento pela outra Parte e somente as utilizará no âmbito dos serviços ora pactuados.</p>
            <p>
                8.2 Entende-se por Informações Confidenciais todas as informações exclusivas de uma das Partes (Parte Reveladora) revelados à outra Parte (Parte Receptora), de modo verbal ou por escrito, que sejam indicadas como
                confidenciais ou que pelo bom senso devam ser consideradas confidenciais em vista da natureza das informações, especialmente em relação às informações fornecidas pelo USUÁRIO no momento do cadastro, tais como: nome, idade,
                e-mail etc., que serão utilizadas para fins do presente TERMO.
            </p>
            <p>8.3 Cada uma das Partes concorda em proteger o sigilo das Informações Confidenciais da outra Parte, da mesma forma como atua na proteção do sigilo de suas próprias informações exclusivas e confidenciais do mesmo tipo.</p>
            <p>
                8.4 O acesso às informações coletadas é restrito a pessoas autorizadas para esta finalidade. Quaisquer pessoas autorizadas que se utilizarem indevidamente das Informações Pessoais e Informações Financeiras do USUÁRIO, de
                forma a não respeitar a política de confidencialidade e privacidade, estarão sujeitas às penalidades legais cabíveis.
            </p>
            <p>8.5 As informações do USUÁRIO serão fornecidas, quando solicitadas, por meio próprio, apenas por ordem legal ou judicial.</p>
            <h5>9. Restrições ao Uso da PLATAFORMA</h5>
            <p>
                9.1 O presente TERMO é regido pelas disposições da Lei 9.609/98 e Lei 9.610/98, ficando os infratores sujeitos às penas dos crimes previstos no art. 184 do Código Penal, sem prejuízo da responsabilidade civil pelos danos
                eventualmente causados pelo uso e distribuição de cópias não autorizadas do Conteúdo ou por qualquer outra violação aos direitos decorrentes da propriedade deste.
            </p>
            <p>
                9.2 É vedado qualquer procedimento que implique em (i) engenharia reversa, descompilação, desmontagem, tradução, adaptação e/ou modificação da Plataforma, ou qualquer outra conduta que possibilite o acesso ao código fonte
                desta; (ii) copiar, ceder, sublicenciar, vender, dar em locação ou em garantia, reproduzir, doar, alienar de qualquer forma, transferir total ou parcialmente, sob quaisquer modalidades, gratuita ou onerosamente, provisória
                ou permanentemente, o Conteúdo do Canal; e (iii) disponibilizar acesso a terceiros mediante o fornecimento de senha e usuário cadastrados.
            </p>
            <p>
                9.3 O Conteúdo é protegido por leis de direitos autorais e de propriedade intelectual. O direito de uso do Conteúdo é licenciado ao USUÁRIO única e exclusivamente para visualização no Canal, não existindo, portanto, qualquer
                cessão de direitos relativos à titularidade deste.
            </p>
            <h5>10. Limitação de responsabilidade</h5>
            <p>
                10.1 O PUBLISHER não será responsabilizado pelos (i) resultados produzidos pela Plataforma, caso esta seja afetada por algum tipo de programa externo, como aqueles conhecidos popularmente como vírus, ou por falha de operação
                ou corrupção de dados; (ii) qualquer defeito decorrente de culpa exclusiva do USUÁRIO; (iii) danos ou prejuízos decorrentes de decisões administrativas, gerenciais ou comerciais tomadas com base no Conteúdo; (iv) problemas
                na rede física e lógica do USUÁRIO; (v) toda e qualquer responsabilidade por eventuais perdas e danos, lucros cessantes ou emergentes, cujas causas possam ser atribuídas, de forma direta ou indireta, ao fornecimento,
                utilização ou desempenho da Plataforma; e (vi) eventos definidos na legislação civil como caso fortuito ou força maior.
            </p>
            <h5>11. Vigência</h5>
            <p>11.1 O presente TERMO tem vigência por prazo indeterminado, a contar do momento da realização da Assinatura do Canal e consequente aceite deste TERMO, nos termos do item 5.1.</p>
            <h5>12. Rescisão</h5>
            <p>12.1 Pelo PUBLISHER:</p>
            <blockquote>
                12.1.1 O presente TERMO poderá ser rescindido antecipadamente pelo PUBLISHER, a qualquer momento, mediante comunicação eletrônica prévia ao USUÁRIO, sem que assista ao USUÁRIO qualquer direito à reclamação.
            </blockquote>
            <blockquote>
                12.1.2 No caso descrito no item 12.1.1, o USUÁRIO será reembolsado integralmente do valor pago pela Assinatura, no respectivo mês, mediante realização de estorno no cartão de crédito, nos prazos e condições estabelecidos
                pela operadora do cartão.
            </blockquote>
            <blockquote>
                12.1.3 A realização do referido estorno será de responsabilidade e obrigação exclusiva do PUBLISHER, o qual deverá realizar o procedimento por meio da PLATAFORMA, responsabilizando-se diretamente perante o USUÁRIO acerca do
                cumprimento desta obrigação.
            </blockquote>
            <blockquote>
                12.1.4 Para compras realizadas via Boleto, o estorno só é completado quando os dados bancários do usuário para depósito (nome completo, CPF, Banco, Agência com dígito verificador e número da conta corrente com dígito
                verificador) forem informados pelo PUBLISHER na Plataforma.
            </blockquote>
            <p>12.2 Pelo USUÁRIO:</p>
            <blockquote>
                12.2.1 Caso o USUÁRIO desista da Assinatura, em até 07 (sete) dias a contar da data de confirmação da realização da Assinatura na Plataforma e desde que não tenha usufruído do serviço contratado, este deverá entrar em
                contato com o PUBLISHER e encaminhar email para o seguinte endereço eletrônico suporte@sambaplay.tv, solicitando a desistência da Assinatura e, caso a compra tenha sido realizada no boleto, enviar também nome completo, CPF,
                Banco, Agência com dígito verificador e número da conta corrente com dígito verificador. A integralidade dos valores pagos, será reembolsada ao USUÁRIO, mediante realização de estorno no cartão de crédito, nos prazos e
                condições estabelecidos pela operadora do cartão; ou, no caso do boleto, em depósito na conta corrente enviada por e-mail. A realização do referido estorno será de responsabilidade e obrigação exclusiva do PUBLISHER, o qual
                deverá realizar o procedimento por meio da PLATAFORMA, responsabilizando-se diretamente perante o USUÁRIO acerca do cumprimento desta obrigação.
            </blockquote>
            <blockquote>12.2.2 Caso não deseje renovar sua assinatura, o USUÁRIO deverá cancelar a mesma no painel de usuário.</blockquote>
            <p>
                12.3 O USUÁRIO declara-se ciente que, caso o presente TERMO seja rescindido, independentemente do motivo, este ficará automaticamente impossibilitado de acessar o Canal e utilizar a Plataforma, bem como de visualizar o
                Conteúdo.
            </p>
            <h5>13. Disposições finais</h5>
            <p>
                13.1 A tolerância de uma Parte com a outra quanto ao descumprimento de qualquer uma das obrigações assumidas neste TERMO não implicará em novação ou renúncia de direito. A Parte tolerante poderá, a qualquer tempo, exigir da
                outra Parte o fiel e cabal cumprimento deste TERMO.
            </p>
            <p>13.2 O presente TERMO vincula as Partes e seus sucessores a qualquer título.</p>
            <p>
                13.3 A inviabilidade da execução ou invalidade, ainda que em virtude de decisão judicial, de alguma cláusula, não afetará a validade das demais cláusulas deste TERMO, que permanecerá válido e executável conforme seus termos.
            </p>
            <p>
                13.4 As Partes elegem o foro da comarca da cidade de Belo Horizonte, Estado de Minas Gerais, para dirimir qualquer controvérsia oriunda do presente TERMO, renunciando expressamente a qualquer outro, por mais privilegiado que
                seja.
            </p>
            <b>Declaro que li e estou ciente das condições acima especificadas.</b>
        </div>
    </section>
    <Footer/>

    <Login :show="openDialogLogin" @toggleModal="toggleOpenDialog"/>
    <Subscribe :show="openDialogSubscribe" @toggleModal="toggleOpenSubscribe"/>
</template>

<script>
import Header from "@/components/Weflix/Hedaer.vue"
import Footer from '@/components/Weflix/Footer.vue'
import Login from '@/components/Weflix/Login.vue'
import Subscribe from '@/components/Weflix/Subscribe.vue'

export default {
    data(){
        return{
            openDialogLogin:false,
            openDialogSubscribe:false,
        }
    },
    components:{
        Header,
        Footer,
        Login,
        Subscribe
    },
    methods:{
        toggleOpenDialog(){
            this.openDialogLogin = !this.openDialogLogin;
        },
        toggleOpenSubscribe(){
            this.openDialogSubscribe = !this.openDialogSubscribe;
        }
    }
}
</script>

<style scoped>
header {
    background-color: black !important;
}
</style>
<style>
section.terms {
    background-color: #000000;
    padding-top: 150px;
}
.contentTerm p, .contentTerm blockquote,  .contentTerm h5, .contentTerm b {
    color: #ffffff;
    text-align: left;
}
.titleTerm h1 {
    color: #ffffff;
}
.contentTerm blockquote {
   padding-left: 30px;
   margin: 20px 0px;
   font-size: 15px;
}
.contentTerm p {
    font-size: 15px;
 }
.contentTerm h5 {
    font-size: 20px;
    font-weight: 700;
}
.contentTerm {
    max-width: 1280px;
    margin: 0 auto;
    padding-top: 20px;
    text-align: left;
    padding-bottom: 20px;
}
footer {
    margin-top: 0 !important;
}
section.terms {
    padding-top: 160px;
}
@media (max-width: 600px) {
    .contentTerm {
        padding: 20px 20px;
    }
}
</style>