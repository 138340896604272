<template>
    <div class="login" :class="{'weflix': typeSystemLocal==2}">
      <div class="content">
        <div class="logo">
            <img alt="Weeduc" src="@/assets/logo-tutor.png" style="width: 200px"/>
          </div>
        <div class="info">
  
          <div class="form">
            <form @submit.prevent="handleSubmit" class="form">
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">E-mail</label>
                <input
                  type="text"
                  v-model="email"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
              </div>
              <div class="mb-3 password">
                <label for="exampleInputPassword1" class="form-label"
                  >Senha</label
                >
                <input
                  v-model="password"
                  :type="showPassword"
                  class="form-control"
                  id="exampleInputPassword1"
                />
  
                <fa icon="eye"          
                  style="cursor: pointer"
                  v-show="showPassword=='text'"
                  @click="changeViewPassword()"
                  />
                  <fa icon="eye-slash"          
                  style="cursor: pointer"
                  v-show="showPassword=='password'"
                  @click="changeViewPassword()"
                  />
              </div>
              <div class="erro" v-show="erroLgin"> 
                <small>{{mensageErro}}</small>
              </div>
              <div class="mb-5 text-center">
                <button type="submit" class="btn-success weflix">Entrar</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import  { mapState, mapActions } from 'vuex';
  import loginApi from '@/utils/login.js';
  
  export default {
    data() {
      return {
        email: '',
        password: '',
        typeIdLocal:null,
        users:null,
        credentials:null,
        token: null,
        websites:null,
        refreshToken:null,
        idUser:null,
        showPassword:'password',
        lististes:null,
        numberSites:1,
        logo:null,
        seletectCompany:0,
        darkMode:null,
        userName:null,
        mensageErro:'Erro ao realizar login, usuário ou senha incorretos.',
        erroLgin: false,
        weflixPageLogin:false,
        typeSystemLocal:this.typeSystem,
      };
    },
    methods: {
    ...mapActions(['storeToken','storeUserSuperAdmin']),
    changeViewPassword(){
      if(this.showPassword=='password'){
        this.showPassword='text';
      } else {
        this.showPassword='password';
      }
    },
    async fetchUsers() {
      try {
        const response = await loginApi.token({
          "email": this.email,
          "password": this.password,
          "domain": "https://bandarte1.weflix.com.br"
        }, {
          withCredentials: false,
        });      
        this.storeToken({token: response.data.acessToken});
        this.storeUserSuperAdmin({userSuperAdmin: response.data.user});
        this.handleRedirect(response.data.user.type);
    } catch (error) {
      this.erroLgin = true;
      console.error('Error fetching API:', error);
    }
  },
    //     try {
    //       const response = await loginApi.token({
    //         "email": this.email,
    //         "password": this.password,
    //         "domain": this.lististes[index].url
  
    //       }, {
    //         withCredentials: false,
    //       });
  
    //       this.token = response.data.acessToken;
    //       this.typeIdLocal =  response.data.type;
    //       this.refreshToken =  response.data.refreshToken;
    //       this.idUser = response.data.idUser;
    //       this.websites = response.data.listWebsite[0];
    //       this.darkMode = this.websites.typeLayout == 2 ? true : false;
    //       this.toggleDarkMode({dark:this.darkMode});
    //       this.setItemStorage();
    //       this.storeToken({token: this.token})
    //       this.storeRefreshToken({refreshToken: this.refreshToken})
    //       this.storeTypeId({typeId: this.typeIdLocal})
    //       this.fetchUserData(this.idUser,  this.token);
    //       this.storeWebsites({websites:this.websites})
  
  
    //     } catch (error) {
    //       console.error('Error fetching API:', error);
    //     }
    // },
    handleSubmit() {
      this.fetchUsers();
    },
  
    handleRedirect(typeId){
      if(typeId==1){
       this.$router.push({ path: '/super-admin' });
      } else {
        alert('Usuário sem permissão');
      }
    },
    }, 
  }
  </script>
  
  <style scoped>
  .item{
    border:none;
  }
  .action {
    display: flex;
    justify-content: end;
    padding: 5px;
  }
  .form {
    max-width: 100%;
    margin: 0 30px;
    text-align: left;
    height: 250px;
    position: relative;
  }
  .login {
    height: 100vh;
    display: flex;
    background-image: url("../../assets/Weflix/bg-login-weflix.png");
    background-size: cover;
  }
  .login.weflix {
    height: 100vh;
    display: flex;
    background-image: url("../../assets/Weflix/bg-login-weflix.png");
    background-size: cover;
  }
  .mb-5.text-center.btn {
    width: 100%;
    position: absolute;
    bottom: 0;
  }
  .logo {
    display: flex;
    justify-content: center;
    margin: 20px;
    position: absolute;
    top: 17%;
  }
  .content {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  .info {
    width: 450px;
    padding: 30px 10px;
    background-color: white;
    border-radius: 10px;
  }
  button.btn.btn-success {
    width: 100%;
  }
  .mb-3 {
    margin: 20px 0px;
  }
  .mb-3.forgot_password {
    color: red;
    margin: 0 !important;
    font-size: 11px;
    text-decoration: none;
    display: flex;
    justify-content: end;
  }
  .mb-3.forgot_password a {
    color: #19c7d0;
  }
  .mb-3.password {
    position: relative;
  }
  .mb-3.password i {
    position: absolute;
    top: 45px;
    right: 10px;
    color: #b4b4b4;
  }
  .company {
    max-width: 100%;
    margin: 0 30px;
    text-align: left;
    position: relative;
  }
  .company button.btn.btn-success {
    margin-top: 10px;
  }
  .option-company {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
  }
  .erro small {
    color: red;
  }
  @media (max-width:600px) {
    .info {
      width: 87%;
      padding: 0;
      
  }
  }
  svg.svg-inline--fa.fa-eye, svg.svg-inline--fa.fa-eye-slash {
    position: absolute;
    top: 45px;
    right: 5px;
    font-size: 15px!important;
    color: #dee2e6!important;
  }
  button.btn-success.weflix {
    background-color: #0d1a7e !important;
    width: 100%;
    color: white;
    padding: 10px;
    border-radius: 5px;
  }
  a.weflix {
    color: #0d1a7e !important;
  }
  </style>
  