<template>
    <div class="sidebar primary" :class="{ 'show': sideBarState }">
        <fa v-if="sideBarState" icon="fa-xmark" class="icon-close" @click="changeSideBarState"/>
        <!--Header Section-->

        <div class="sidebar-header">
            <div class="header-logo">
                <div class="logo" id="desktopLogoWeflix">
                    <img class="logo_black" src="./../../assets/logo-tutor.png" >
                </div>
                <div class="logo-mobile">
                    <span><img src="./../../assets/Weflix/icons/Weflix-Icone-Branco.svg" alt=""></span>
                    <span class="mobile-icon-weflix-text"><img src="./../../assets/Weflix/icons/Weflix-Escrita-Branco.svg" alt=""></span>
                </div>
            </div>
        </div>

        <!--Content Section-->
       

        <div class="sidebar-content">
            <div class="content-link">
                <div class="link">
                    <router-link class="link-content primary" to="/super-admin" :class="{ active: activeIndex == 2 }" @click="setActiveIndex(2)">
                        <span class="link-icon">
                            <fa icon="fa-building-columns"  style="font-size: 1rem"/>
                        </span>
                        <span class="link-text">
                            <span class="text">
                            Nova Empresa
                            </span>
                        </span>
                    </router-link>
                </div>
                <div class="link">
                    <router-link class="link-content primary" to="/admin/empresas" :class="{ active: activeIndex == 1 }" @click="setActiveIndex(1)">
                        <span class="link-icon">
                            <fa icon="fa-building"  style="font-size: 1rem"/>
                        </span>
                        <span class="link-text">
                            <span class="text">
                             Lista de Empresas
                            </span>
                        </span>
                    </router-link>
                </div>
              
                <div class="link" >
                    <router-link class="link-content primary" to="/super-admin" @click="logout">
                        <span class="link-icon">
                            <fa icon="fa-sign-out-alt"  style="font-size: 1rem"/>
                        </span>
                        <span class="link-text">
                            <span class="text">
                           Sair
                            </span>
                        </span>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import store from '@/store';
import  { mapState,mapActions } from 'vuex';

export default {
    data() {
        return {
            menu: [],
            activeIndex: 2,
            secondoactiveindex:null,
            lenguage: "pt",
            label_lenguage: "Idioma",
            typeUser:'',
            typesReports: [ 'Clientes', 'Financeiro',],
            iconsReports: ['fa-chalkboard-user','fa-sack-dollar'],
            linksReports: ['/admin/relatorios/clientes','/admin/relatorios/financas'],
        }
    },
    computed: {
        ...mapState(['sideBarState'])
    },
    methods: {
        ...mapActions(['storeTypeId','storeSideBarState','logoutSuperAdmin']),

        setActiveIndex(index) {
            if (this.activeIndex === index) {
                // this.activeIndex = null;
            } else {
                if(this.setActiveIndex!=8){
                    this.secondoactiveindex=null;
                }
                this.activeIndex = index;
            }
            localStorage.setItem('activeIndex', this.activeIndex);
        },
        logout(){
            this.logoutSuperAdmin();
            this.$router.push('/admin');
        }
    },
    created() {

    }
}
</script>
<style scoped>
.logo img {
    width: 150px;
}
.link.subitem {
    margin-left: 13%;
    margin-top: 2%;
    margin-bottom: 2%;
}
.link.dad {
    height: 46px;
    overflow: hidden;
    transition: all 500ms linear;
    margin-left: 0.5rem;
}
.link.dad:hover {
    overflow: visible;
    height: 145px;
}
.link.dad > a.link-content.primary {
    cursor: default;
}
.dashboard.dark img.logo_black {
    display: none;
}
.dashboard img.logo_white {
    display: none;
}
.dashboard.dark img.logo_white {
    display: block;
}
</style>

<style scoped>
.link.dad .link-content.primary .link-icon {
    margin-right: 1rem !important; 
}
.sidebar.primary .sidebar-content .content-link .link .link-content {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    text-decoration: none;
    padding: 0.5rem 1rem;
    color: white;
    transition: .3s ease;
}
.sidebar.primary {
    position: fixed;
    height: 100%!important;
    z-index: 1040;
    width: 15%;
    left: 0;
    overflow: hidden;
    padding: 0.5rem;
    background-color: #260791;

}
@media only screen and (min-width:1900px){
    .sidebar.primary {
        position: fixed;
        height: 100%!important;
        z-index: 1040;
        width: 15%;
        left: 0;
        overflow: hidden;
        padding: 0.5rem;
        background-color: #260791;
        border-right: 1px solid #f6f6f6;
        transition: .5s ease;
        box-shadow: 2px -1px 22px -15px gray;
        margin-left: 0px;
    } 
}
.sidebar.primary .sidebar-header {
    padding: 1rem !important;
    /* padding-left: 2px!important; */
    padding-top: 5px!important;
}
.sidebar.primary .sidebar-content {
    overflow-y: auto;
    height: calc(100vh - 128px);
    margin-top: 50px;
}
.sidebar.primary .sidebar-content .content-link {
    display: flex;
    height: calc(100vh - 10rem);
    flex-direction: column;
    gap: 1px;
}
.sidebar.primary .sidebar-content .content-link .link {
    display: block;
}

.sidebar.primary .sidebar-content .content-link .link .link-content .link-icon {
    display: inline-block;
    margin-right: 0.5rem;
    transition: .2s ease;
}
.sidebar.primary .sidebar-content .content-link .link .link-content.active {
    background-color: #150065;
    transition: .3s ease;
}


.sidebar.primary .sidebar-content .content-link .link .link-content:hover {
    color: #fff;
    background-color: #150065;
    transition: .2s ease;
}

.sidebar.primary .sidebar-content .content-link .link .link-content:hover {
    color: #fff;
    background-color: #150065;
    transition: .2s ease;
}
.sidebar.primary .sidebar-content .content-link .link .link-content:hover .link-text>span.text {
    transform: translateX(1rem);
    transition: transform .4s ease,color .1s ease;
}
.sidebar.primary .sidebar-content .content-link .link .link-content .link-text {
    display: flex;
    align-items: center;
    width: 100% !important;
    justify-content: space-between;
}
.sidebar.primary .sidebar-content .content-link .link .link-content:hover .link-icon {
    transform: translateX(0.75rem);
    transition: .2s ease;
    transition-delay: .15s;
}
.sidebar.primary .sidebar-content .content-link .link .link-content:hover .link-icon svg path {
    fill: #fff;
    transition: .3s ease;
}

.sidebar.primary .sidebar-content .content-link .link .link-content.active .link-icon svg path {
    fill: #fff;
    transition: .3s ease;
}
.sidebar.primary .sidebar-content .content-link .link .link-content.active span.link-icon, .sidebar.primary .sidebar-content .content-link .link .link-content.active .text {
    color: white;
}
.link-icon svg.svg-inline--fa {
    color: #ffffff;
}
.sidebar.primary.show {
    width: 200px;
}
svg.svg-inline--fa.fa-xmark.icon-close {
    position: absolute;
    transform: translate(75px, 10px);
}
.logo-mobile {
    display: none;
}
@media (max-width: 500px) {
    .sidebar.primary {
        width: 60px;
        transition: .5s ease;
    }
    .sidebar.primary.show {
        width: 61%;
    }
    .sidebar.primary .sidebar-content .content-link .link .link-content .link-text {
        display: flex;
        align-items: center;
        width: 100% !important;
        justify-content: space-between;
        margin-left: 0.8em;
        max-height: 40px;
    }
    .sidebar.primary .sidebar-content .content-link .link .link-content:hover .link-icon {
        transform: translateX(0);
        transition: .2s ease;
        transition-delay: .15s;
    }
    .link .link-icon {
        display: inline-block;
        margin-left: -10px;
    }
    .link.dad .link-icon {
        margin-left: 0px;
    }
    .sidebar.primary .sidebar-content .content-link .link .link-content:hover .link-icon {
        transform: none !important;
        transition: .2s ease;
        transition-delay: .15s;
    }
    .sidebar.primary .sidebar-content .content-link .link .link-content.active {
        background-color: #150065;
        transition: .3s ease;
        border-radius: 10px;
    }
    .icon-close {
        color: #fff;
        padding: 5px;
    }
    .sidebar.primary .sidebar-header {
        padding: 1rem !important;
        padding-left: 3px!important;
        padding-top: 5px!important;
    }
    .link.subitem {
        margin-left: -10px;
        margin-top: 0;
        margin-bottom: 0;
    }
    .link.dad {
        height: 46px;
        overflow: visible;
        transition: none;
    }
    .logo img {
        width: 160px;
    }
    #desktopLogoWeflix {
        display: none;  
    }
    .logo-mobile {
        display: flex;
        align-items: center;
        gap: 50px;
    }
    .logo-mobile img {
        width: 40px;
    }
    .mobile-icon-weflix-text {
        width: 60px;
        transform: scale(2.3);
    }
}
</style>