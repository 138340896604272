<template>
  <RouterView/>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
import 'primeicons/primeicons.css';
</script>

<style>
#app {
  font-family: Inter, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
@font-face {
  font-family: "Inter";
  src: local("Inter"),
   url(../src/assets/font/Inter/Inter-Regular.ttf) format("truetype");
}
svg.svg-inline--fa {
  font-size: 20px;
}
section.section.page-body.primary {
  background-color: white;
  height: calc( 100vh - 200px);
  border-radius: 0px 0px 10px 10px;
}
button.btn.btn-secondary, button.btn.btn-primary {
  color: white;
}
@media only screen and (min-width: 1500px) {
  section.section.page-body.primary {
    height: calc(100vh - 250px);
}
}
</style>
