import axios from 'axios';


export default {
    async getDataCep(cep) {
        try {
            const headers = {
                'Content-Type': 'application/json', 
            };
            const response = await axios.get(`https://viacep.com.br/ws/${cep}/json`, {
                method: 'GET',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados do cep:', error);
            throw error;
        }
    },
    

}

