<template>
  <div class="container myCoursesWeeduc">
      <div class="titleContainer myCoursesWeeduc">
          <h3>Meus Cursos</h3>
          <p>Gerencie seus cursos ou crie novos. Arraste-os para mudar a ordem de visualizacao no portal do aluno.
          </p>
          <div class="searchContainer myCoursesWeeduc searchCollectionsMobile">
              <div class="form-group">
                  <div class="form-input">
                      <input
                          type="text" 
                          class="form-control"
                          placeholder="Pesquisar curso..."
                          v-model="searchText"
                          @input="buscarColecao"
                      />
                  </div>
              </div>
              <div class="btn-weflix" @click="buscarColecao">
                  <span class="flex"><fa icon="fa-solid fa-magnifying-glass"></fa></span>
              </div>
          </div>
          <div class="btnWeflix absolute myCoursesWeeduc" @click="(()=>this.$router.push('/dashboard/novo-curso'))">
              <fa icon="fa fa-plus"></fa>
              <span v-show="!isMobileIcon" class="flex">Novo Curso</span>
          </div>
        <div class="viewOptionsContainer myCoursesWeeduc">
            <button class="btnViewOption" :class="{'active': view === 1}" @click="changeView(1)" title="Visualização em blocos">
                <fa icon="fa-th-large"/>
            </button>
            <button class="btnViewOption" :class="{'active': view === 2}" @click="changeView(2)" title="Visualização em lista">
                <fa icon="fa-list"/>
            </button>
        </div>
      </div>
      <draggable
        :list="collections"
        :disabled="false"
        class="list-group"
        :class="{'grid': view === 1, 'list': view === 2}"
        ghost-class="ghost"
        :move="checkMove"
        @start="dragging = true"
        @end="onDragEnd"
      >
        <template #item="{ element }">
          <div class="list-group-item myCoursesWeeduc" :id="`collection-${element.id}`">
            <div class="item" @click="goToCollection(element.id, element)">
              <div class="drag-handle" v-if="dragging">
                  <fa icon="fa-grip-vertical"/>
              </div>
              <div class="backgroundItemCollection" :class="{'noImage': !element.imageFinalPath2}" :style="{ backgroundImage: element.imageFinalPath2 ? `url(${element.imageFinalPath2})` : `url(${require('@/assets/Weflix/default-video-thumbnail.jpg')})` }">
              </div>
              <div class="myCoursesWeeduc statusDescriptionFlag">
                  <span class="status-flag" :class="element.status == 1 ? 'active' : 'inactive'">
                      {{ element.status == 1 ? 'Ativo' : 'Inativo' }}
                  </span>
              </div> 
              <div class="itemContent" :class="{'noImage': !element.imageFinalPath}">
                  <div class="titleItemCollection">
                      {{element.comercialName}}
                  </div>
                  <div class="descriptionItemCollection">
                      <p><fa icon="fa-user-group"/> {{element.studentQty}} Alunos</p>
                  </div>  
              </div>
            </div>
          </div>
        </template>
      </draggable>
  </div>
    <Loader :hasReady="hasReady" />
</template>

<script>
import { mapState, mapActions } from 'vuex';
import apiCollections from '@/utils/Weflix/collections';
import Loader from '@/components/Weflix/Loader';
import draggable from 'vuedraggable';


let id = 1;

export default {    
  data() {
      return {
          collections: [],
          hasReady: false,
          dragging:false,
          showCollections:false,
          isMobileIcon: false,
          searchText: '',
          view: 1,
          numberCourses: 0,
          numberModules: 0,
          numberLessons: 0,
          allCollections: [],
          searchDebounceTimeout: null,
      }
  },
  mounted() {
      this.checkScreenSize();
      window.addEventListener('resize', this.checkScreenSize);
  },
  beforeDestroy() {
      if (this.searchDebounceTimeout) {
          clearTimeout(this.searchDebounceTimeout);
      }
      window.removeEventListener('resize', this.checkScreenSize);
  },
  computed: {
      ...mapState(['token', 'panelData', 'currentCollections']),
  },
  methods: {
      ...mapActions(['storeCurrentCollections']),

      goToCollection(id, collections) {
          this.storeCurrentCollections({currentCollections: collections});
          this.$router.push('/dashboard/curso')
      },
      async getCollections() {
          try {
              const response = await apiCollections.getCollection(this.token);
              const sortedCollections = response.sort((a, b) => {
                  const orderA = Number(a.order) || 0;
                  const orderB = Number(b.order) || 0;
                  return orderA - orderB;
              });
              
              this.allCollections = [...sortedCollections];
              this.collections = [...sortedCollections];
              this.numberCourses = response.length;
              this.numberModules = response.reduce((total, collection) => total + collection.moduleQty, 0);
              this.numberLessons = response.reduce((total, collection) => total + collection.lessonQty, 0);
              this.hasReady = true;
          } catch (error) {
              console.error('Erro ao obter dados das coleções:', error);
          }
      },
      checkMove: function(e) {
      },
      onDragEnd: function(e) {
          const movedElement = this.collections[e.newIndex];
            
          // Filtrar collections para obter apenas order e id
          const collectionOrderAndId = this.collections.map(item => ({
              id: item.id,
              nome: item.comercialName,
              order: this.collections.indexOf(item) + 1
          }));
          this.updateCollectionOrder(collectionOrderAndId);
          this.dragging = false;
      },
      async updateCollectionOrder(collectionOrderAndId) {
          try {
              const response = await apiCollections.updateCollectionOrder(this.token, collectionOrderAndId);
          } catch (error) {
              console.error('Erro ao atualizar ordem da coleção:', error);
          }
      },
      checkScreenSize() {
          this.isMobileIcon = window.innerWidth <= 600;
      },
      buscarColecao() {
          clearTimeout(this.searchDebounceTimeout);
          
          this.searchDebounceTimeout = setTimeout(() => {
              if (!this.searchText.trim()) {
                  this.collections = [...this.allCollections];
                  return;
              }
              
              const searchLower = this.searchText.toLowerCase().trim();
              
              this.collections = this.allCollections.filter(collection => {
                  const nameMatch = collection.comercialName.toLowerCase().includes(searchLower);
                  return nameMatch;
              });

              if (this.collections.length > 0) {
                  this.$nextTick(() => {
                      const firstMatch = this.collections[0];
                      const element = document.getElementById(`collection-${firstMatch.id}`);
                      if (element) {
                          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                      }
                  });
              }
          }, 300);
      },
      changeView(view) {
          this.view = view;
      },
      async getStatusCourse(){
        try {
          const response = await ApiModules.getStatusCourse(this.token, this.currentCollections.id);
          this.infoCount = response;
        } catch (error) {
          console.error('Erro ao pegar status do curso:', error);
        }
      }
     
  },
  components: {
      Loader,
      draggable
  },
  created() {
      this.getCollections();
      this.getStatusCourse();
  }
}
</script>
<style scoped>
.backgroundItemCollection.noImage {
  background-size: cover;
}
.container {
  max-width: 100%;
}
.btnWeflix.absolute {
  position: absolute;
  top: 25px;
  right: 25px;
}
/* Alterações no layout de titleContainer Meus Cursos Weeduc Adm */
.viewOptionsContainer.myCoursesWeeduc {
  display: none !important;
}
.titleContainer.myCoursesWeeduc {
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    padding: 0px 10px;
    border-bottom: none;
    margin: 10px;
    align-items: center;
    justify-content: space-between;
}
.titleContainer.myCoursesWeeduc h3 {
    flex: 1 1 100%;
    font-size: 1.5em;
    font-weight: bold;
    color: #000;
}
.titleContainer.myCoursesWeeduc p {
    flex: 1 1 100%;
    font-size: 0.9em;
    margin-bottom: 10px;
    color: #555;
}
.searchContainer.myCoursesWeeduc {
    flex: 1 1 70%; 
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
    margin-left: -10px;
}
.searchContainer.myCoursesWeeduc .form-input {
    display: flex;
    align-items: center;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 0.5rem;
    width: 100%;
    height: 42px;
}
.searchContainer.myCoursesWeeduc .form-input input {
    flex: 1;
    border: none;
    outline: none;
    font-size: 14px;
    padding: 0.5rem;
    color: #333;
}
.searchContainer.myCoursesWeeduc .btn-weflix {
    background-color: #2f2e8b;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    height: 42px;
    min-width: 42px;
    justify-content: center;
    transition: all 0.3s ease;
}
.searchContainer.myCoursesWeeduc .btn-weflix:hover {
    background-color: #23225f;
}
.searchContainer.myCoursesWeeduc .btn-weflix svg {
    color: white;
}
.btnWeflix.absolute.myCoursesWeeduc {
    background-color: #160266 !important;
    color: white !important;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 0.9em !important;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
}
.btnWeflix.absolute.myCoursesWeeduc:hover {
    background-color: #250991 !important;
}
.btnWeflix.absolute.myCoursesWeeduc svg {
  color: white;
}
.viewOptionsContainer.myCoursesWeeduc {
    display: flex;
    gap: 10px;
}
.viewOptionsContainer.myCoursesWeeduc .btnViewOption {
    background-color: transparent;
    border: none;
    font-size: 1.2em;
    color: #555;
    cursor: pointer;
}
.viewOptionsContainer.myCoursesWeeduc .btnViewOption.active {
    color: #2f2e8b;
}
.myCoursesWeeduc .form-group {
  width: 100%;
  max-width: 800px;
}
/* Fim Alterações no layout Meus Cursos Weeduc Adm */
/* Alterações no layout de list-group-grid Meus Cursos Weeduc Adm */
.myCoursesWeeduc .list-group.grid {
  display: grid;
  grid-template-columns: repeat(3, 33%);
  gap: 10px;
  padding: 10px 20px 10px 20px;
  margin: 0;
}

.list-group-item.myCoursesWeeduc {
  padding: 0;
  margin: 0;
  border: 2px solid #e7e7e7;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 235px;
  transition: all 500ms ease-in-out;;
}

.list-group-item.myCoursesWeeduc:hover {
  border: 2px solid #250991;
}
.myCoursesWeeduc .item {
  padding: 0;
  border: 0;
  gap: 0;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  position: relative;
}
.myCoursesWeeduc .backgroundItemCollection {
  width: 100%;
  height: 156px;
  background-size: cover;
  background-position: center;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  position: relative;
}
.myCoursesWeeduc .itemContent {
  width: 100%;
  padding: 0px 15px 0px 15px;
  margin-top: 10px;
}
.myCoursesWeeduc .itemContent .titleItemCollection {
  text-align: left;
  font-size: 14px;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/* Fim Alterações no layout de list-group-grid Meus Cursos Weeduc Adm */
/* New List view */
.myCoursesWeeduc .list-group.list {
  padding: 5px 10px;
  width: 100%;
  height: 40px;
  gap: 5px;
}
.myCoursesWeeduc .list-group.list .itemContent {
  display: flex;
  flex-direction: row;
  width: auto;
}
.myCoursesWeeduc .list-group.list .backgroundItemCollection {
  display: none;
}
.myCoursesWeeduc .list .list-group-item {
  padding: 0;
}
.myCoursesWeeduc .list .itemContent .descriptionItemCollection {
  position: relative;
  inset: 0;
}
/* List view */
.list-group.list .backgroundItemCollection {
  display: none;
}
.list .list-group-item {
  padding: 5px 10px;
}
.list-group.list .item {
  height: 40px;
}
.list-group.list .titleItemCollection{
  margin:10px;
}
.list-group.list .itemContent {
  display: flex;
}
.list-group.list .titleItemCollection {
  font-size: 14px;
}
.list-group.list .titleItemCollection {
  font-size: 14px;
}
.list-group.list .descriptionItemCollection p {
  font-size: 13px;
}
.list-group.list .descriptionItemCollection p svg {
  font-size: 13px;
}
.container {
  background-color: white;
  padding: 10px 0px;
  padding-bottom: 50px;
}
.btnNewCollection {
  position: absolute;
  width: auto;
  padding: 6px 12px;
  background-color: #250891;
  color: white;
  border-radius: 5px;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.drag-handle {
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
  color: #250891;
  font-size: 20px;
  z-index: 10;
  background: rgba(255, 255, 255, 0.9);
  padding: 5px;
  border-radius: 4px;
  cursor: move;
}
.collecttionsItens {
  padding: 10px;
}

.descriptionItemCollection  {
  margin-top: 10px;
  display: flex;
  gap: 10px;
}
.descriptionItemCollection p {
  color: gray;
  font-size: 13px;
  margin: 0;
}
.descriptionItemCollection svg path {
  fill: #ffffff;
  stroke-width: 0;
  stroke: white;
}
.descriptionItemCollection svg {
  font-size: 13px;
}
.buttons {
margin-top: 35px;
}
.ghost {
opacity: 0.5;
background: #c8ebfb;
}
@media (max-width: 600px) {
  .myCoursesWeeduc .list-group.grid {
    grid-template-columns: 100%;
  }

  .btnWeflix.absolute.myCoursesWeeduc {
    padding: 5px 5px !important;
  }
  .btnWeflix.absolute svg {
      color: #250891;
  }
  .btnWeflix:hover svg {
      color: white;
  }
  .backgroundItemCollection {
      width: 100%;
      background-size: 100%;
  }
  .item {
      gap: 0px;
      flex-wrap: wrap;
  }
  .itemContent {
      width: 100%;
      height: 1%;
      margin-left: 10px;
  }
  .titleItemCollection {
      margin-top: 10px;
  }
  .tolbarCollections .card-sm {
      padding: 13px !important;
  }
  .contentCardCollections h4{
      margin-bottom: .1rem !important;
  }
  .titleCardCollections h4 {
      display: none;
  }
}
.tolbarCollections {
  display: flex;
  gap: 10px;
  padding: 10px;
}
.tolbarCollections .card-sm {
  width: 33%;
  background-color: #eef0fc;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  align-items: center;
  border-radius: 5px;
}
.titleCardCollections {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
.titleCardCollections h4{
  margin: 0;
  font-size: 16px;
  font-weight: 700;
}
.titleCardCollections svg {
  color: #250991;
}
.form-group {
  width: 100%;
  max-width: 400px;
}
.form-input {
  position: relative;
  display: flex;
  align-items: center;
}
.form-input input {
  width: 100%;
  padding: 8px 35px 8px 15px;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  font-size: 14px;
}
.form-input svg {
  position: absolute;
  right: 10px;
  color: #250991;
}
.btnSearchCollections {
  background-color: #21212100;
  color: #250891;
  border: 1px solid #250891;
  padding: 8px 20px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  padding: 7px;
  transition: all 500ms ease-in-out;
}
.btnSearchCollections svg{
  color: #250991;
}
.btnSearchCollections:hover {
  color: white;
  background-color: #1b0668;
}
.btnSearchCollections:hover svg {
  color: white;
}
@media (max-width: 600px) {
  .searchContainer {
      flex-direction: column;
      align-items: stretch;
  }
  
  .form-group {
      max-width: 100%;
  }
  
  .btnSearch {
      justify-content: center;
  }

  .searchContainer.searchCollectionsMobile {
      display: flex;
      position: relative;
      top: 0;
      right: 10px;
      max-width: 100%;
      flex-direction: row;
  }
  .viewOptionsContainer {
      position: absolute;
      top: 165px !important;
      right: 25px !important;
      transform: scale(1.4);
      display: flex;
      flex-direction: row;
  }
  .btnSearchCollections.btnSearchCollectionMobile {
      transform: scale(0.77);
  }
}
button.btnViewOption.active svg {
  color: #250891;
}
.myCoursesWeeduc.statusDescriptionFlag {
  position: absolute;
  top: 120px;
  right: 10px;
}
.list-group.list .myCoursesWeeduc .statusDescriptionFlag {
  display: none;
}
.myCoursesWeeduc .status-flag {
    padding: 5px 7px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: normal;
}
.myCoursesWeeduc .active {
  background: rgb(153 253 153);
  color: rgb(13 65 13);
}
.myCoursesWeeduc .inactive {
  background: rgb(193 190 190);
  color: #1f1e1e;
}
/* DARK */
.dashboard.dark .content.dashboard > .container.myCoursesWeeduc {
    background-color: #000;
}
.dashboard.dark .titleContainer.myCoursesWeeduc h3 {
  color: white;
}
.dashboard.dark .titleContainer.myCoursesWeeduc p {
  color: white;
}
.dashboard.dark .searchContainer.myCoursesWeeduc .form-input {
  background-color: #575757;
}
.dashboard.dark .searchContainer.myCoursesWeeduc .form-input {
  border: 1px solid #444;
}
.dashboard.dark .searchContainer.myCoursesWeeduc .form-input input {
  background-color: #575757;
}
.dashboard.dark .myCoursesWeeduc .item {
  background-color: #575757;
}
.dashboard.dark .myCoursesWeeduc .itemContent .titleItemCollection {
  color: white;
}
.dashboard.dark .list-group-item.myCoursesWeeduc {
  background-color: #575757;
  border: 2px solid #444;
}
.dashboard.dark .myCoursesWeeduc .descriptionItemCollection p {
  color: white;
}
</style>
<style>
.itemAtionCollection .form-check-input:checked {
  background-color: #1AC7D0;
  border: 1px solid #1AC7D0;
}
.itemAtionCollections .form-check-input {
  margin-right: 1rem !important;
  cursor: pointer;
  flex-shrink: 0;
  width: 3rem;
  height: 1.375rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='white'/%3E%3C/svg%3E");
  background-color: #8d8d8d;
  border: 1px solid #8d8d8d;
  box-shadow: none;
}
.itemAtionCollections .form-check-input {
  margin-right: 1rem !important;
  cursor: pointer;
  flex-shrink: 0;
  width: 3rem;
  height: 1.375rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='white'/%3E%3C/svg%3E");
  background-color: #8d8d8d;
  border: 1px solid #8d8d8d;
  box-shadow: none;
}
.collecttionsItens .itemAtionCollections.form-check.form-switch {
  position: absolute;
  top: 10px;
  right: 0;
}
.itemContent {
  width: 70%;
}
.backgroundItemCollection {
  width: 60%;
  background-size: cover;
}
.itemContent.noImage {
  padding: 0px 10px;
}
</style>
