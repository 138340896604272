<template>
    <div class="layoutBasic weflix-admin-layoutBasic">
      <div class="titlelayoutBasic">
        <h3>Informações de Básicas</h3>
                <small>Personalize seu site com a sua cara.
        </small>
    </div>
        <div class="col-form-inner col-6 col-md-6 col-lg-6" v-if="hasReady">
           
            <!-- Nome Fantasia -->
            <div class="col-form-inner col-12 col-md-12 col-lg-12">
                <div class="form-group">
                  <div class="form-label">
                    <label>Título do Site<v-tooltip text="Campo obrigatório" location="top">
                      <template v-slot:activator="{ props }">
                        <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                      </template>
                    </v-tooltip></label>
                  </div>
                  <div class="form-input">
                    <input
                      type="text" 
                      required
                      class="form-control"
                      v-model="name"
                      placeholder="Título do seu site"
                    />
                    <small class="alertText" v-if="error[0]">Campo obrigatório e deve ser preenchido corretamente.</small>
                    <small>Título do seu site, uso externo.</small>
                  </div>
                </div>
            </div>
            <!-- Descrição -->
            <div class="col-form-inner col-12 col-md-12 col-lg-12 textarea">
                <div class="form-group">
                  <div class="form-label">
                    <label>Descrição<v-tooltip text="Campo obrigatório" location="top">
                      <template v-slot:activator="{ props }">
                        <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                      </template>
                    </v-tooltip></label>
                  </div>
                  <div class="form-input"> 
                    <textarea v-model="description"></textarea>
                    <small class="alertText" v-if="error[1]">Campo obrigatório e deve ser preenchido corretamente.</small>
                    <small>Nome comercial do seu curso, uso externo.</small>
                  </div>
                </div>
            </div>
            <!-- <hr/> -->
            <!-- <div class="col-form-inner col-12 col-md-12 col-lg-12">
                <div class="form-group">
                  <div class="form-label">
                    <label>Título Sobre<v-tooltip text="Campo obrigatório" location="top">
                      <template v-slot:activator="{ props }">
                        <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                      </template>
                    </v-tooltip></label>
                  </div>
                  <div class="form-input">
                    <input
                      type="text" 
                      required
                      class="form-control"
                      v-model="comercialName"
                      placeholder="Sua empresa"
                    />
                    <small class="alertText">Campo obrigatório e deve ser preenchido corretamente.</small>
                    <small>Nome comercial do seu curso, uso externo.</small>
                  </div>
                </div>
            </div> -->
            <!-- Descrição -->
            <div class="col-form-inner col-12 col-md-12 col-lg-12 textarea">
                <div class="form-group">
                  <div class="form-label">
                    <label>Descrição Sobre<v-tooltip text="Campo obrigatório" location="top">
                      <template v-slot:activator="{ props }">
                        <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                      </template>
                    </v-tooltip></label>
                  </div>
                  <div class="form-input"> 
                    <textarea v-model="descriptionAbout"></textarea>
                    <small>Descrição para a página Sobre</small>
                    <!-- <small class="alertText">Campo obrigatório e deve ser preenchido corretamente.</small> -->
                  </div>
                </div>
            </div>
        </div>
        <div class="col-form-inner col-6 col-md-6 col-lg-6" v-if="hasReady">
            <div class="col-form-inner col-12 col-md-12 col-lg-12 logo-form-inner">
                <div class="form-group">
                  <div class="form-label">
                    <label>Logo</label>
                    <small>Insira uma imagem maior que 120x60 pixels</small>
                  </div>
                  <div class="form-file">
                    <div class="file-wrapper primary">
                      <img
                      :src="logoPreview? logoPreview : logo"
                      alt="Logo"
                      v-show="logoPreview || logo"
                      style="width: 120px;height: 60px;margin: 10px 0px;"

                    />
                      <span class="thumbnail">
                        <span class="icon" v-if="loaderImage">
                          <svg
                            width="46"
                            height="39"
                            viewBox="0 0 46 39"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M45 19.9308L36.7274 12.3475C36.1595 11.827 35.2948 11.8047 34.7009 12.2953L18.824 25.411C18.3196 25.8277 17.6074 25.8812 17.0464 25.5446L11.1009 21.9773C10.5893 21.6703 9.94666 21.6856 9.45024 22.0166L1 27.6501"
                              stroke="#9D9FA3"
                              stroke-width="1.25714"
                            ></path>
                            <rect
                              x="1.00049"
                              y="1.40454"
                              width="43.9461"
                              height="36.1909"
                              rx="2.58507"
                              stroke="#9D9FA3"
                              stroke-width="1.25714"
                            ></rect>
                            <circle
                              cx="14.8948"
                              cy="10.6676"
                              r="3.71629"
                              stroke="#9D9FA3"
                              stroke-width="1.25714"
                            ></circle>
                          </svg>
                        </span>
                        <span class="text" v-if="loaderImage">
                          Clique aqui para selecionar a imagem
                        </span>
                        <LoaderImage :hasReady="loaderImage"/>
                      </span>
                      <input
                        class="form-control"
                        type="file"
                        @change="updateFilePathLogo"
                        accept=".png, .jpg, .jpeg, .webp"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-form-inner col-12 col-md-12 col-lg-12">
                <div class="form-group">
                  <div class="form-label">
                    <label>Favicon</label>
                    <small>Você pode adicionar uma imagem para ser o favicon do seu site.</small>

                  </div>
                  <div class="form-file">
                    <div class="file-wrapper primary">
                      <img
                        :src="faviconPreview ? faviconPreview : favicon "
                        alt="Logo"
                        v-show="faviconPreview || favicon"
                        style="width: 50px;margin: 10px 0px;"
                      />
                      <span
                        class="thumbnail"
                      >
                        <span class="icon" v-if="loaderImage">
                          <svg
                            width="46"
                            height="39"
                            viewBox="0 0 46 39"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M45 19.9308L36.7274 12.3475C36.1595 11.827 35.2948 11.8047 34.7009 12.2953L18.824 25.411C18.3196 25.8277 17.6074 25.8812 17.0464 25.5446L11.1009 21.9773C10.5893 21.6703 9.94666 21.6856 9.45024 22.0166L1 27.6501"
                              stroke="#9D9FA3"
                              stroke-width="1.25714"
                            ></path>
                            <rect
                              x="1.00049"
                              y="1.40454"
                              width="43.9461"
                              height="36.1909"
                              rx="2.58507"
                              stroke="#9D9FA3"
                              stroke-width="1.25714"
                            ></rect>
                            <circle
                              cx="14.8948"
                              cy="10.6676"
                              r="3.71629"
                              stroke="#9D9FA3"
                              stroke-width="1.25714"
                            ></circle>
                          </svg>
                        </span>
                        <span class="text" v-if="loaderImage">
                          Clique aqui para selecionar a imagem
                        </span>
                        <LoaderImage :hasReady="loaderImage"/>
                      </span>
                     
                      <input
                        class="form-control"
                        type="file"
                        @change="updateFilePathFavicon"
                       
                      />
                    </div>
                  </div>
                </div>
              </div>
        </div>
        <div class="actionsSave layoutBasicWeflix">
          <button class="btnSave" @click="updateWebsite" :disabled="!loaderImage" :class="{'disabled': !loaderImage}">Salvar</button>
        </div>
    </div>
  <Loader :hasReady="hasReady" />
</template>
<script>
import websiteApi from '@/utils/Weflix/website';
import apiAWS from '@/utils/storage/aws';
import { mapState } from 'vuex';
import Loader from '@/components/Weflix/Loader.vue';
import LoaderImage from '@/components/Weflix/LoaderImage';

export default {
  data() {
    return {
      description: '',
      logo: '',
      favicon: '',
      logoPreview: '',
      faviconPreview: '',
      id: '',
      error: [false, false],
      hasReady: false,
      facebook: '',
      gads: '',
      ganalytic: '',
      tagManager: '',
      rdrefreshToken: '',
      rdcode: '',
      rdSecret: '',
      rdClient: '',
      color: '',
      activeTheme: null,
      descriptionAbout: '',
      facebook: '',
      gads: '',
      ganalytic: '',
      tagManager: '',
      rdrefreshToken: '',
      rdcode: '',
      rdSecret: '',
      rdClient: '',
      loaderImage: true,
    };
    },
    computed: {
        ...mapState(['token'])
    },
    components: {
        Loader,
        LoaderImage
    },
    methods: {
        async getWebsite() {
            const response = await websiteApi.getWebsite(this.token);
            this.id = response.id;
            this.name = response.nome;
            this.description = response.description;
            this.logo = response.logo;
            this.favicon = response.favicon;
            this.logoPreview = response.logoFinal;
            this.faviconPreview = response.faviconFinal;
            this.url = response.url;
            this.status = response.status;
            this.url = response.url;
            this.hasReady = true;

            this.facebook = response.facebookPixelCode;
            this.gads = response.googleAdsCode;
            this.ganalytic = response.googleAnalyticsCode;
            this.tagManager = response.tagManagerCode;
            this.rdrefreshToken = response.rdrefreshToken;
            this.rdcode = response.rdcode;
            this.rdSecret = response.rdsecretClient;
            this.rdClient = response.rdclienteId;
            this.color = response.color;
            this.activeTheme = response.typeLayout;
            this.descriptionAbout = response.about;

            this.facebook = response.facebookPixelCode;
            this.gads = response.googleAdsCode;
            this.ganalytic = response.googleAnalyticsCode;
            this.tagManager = response.tagManagerCode;
            this.rdrefreshToken = response.rdrefreshToken;
            this.rdcode = response.rdcode;
            this.rdSecret = response.rdsecretClient;
            this.rdClient = response.rdclienteId;
            this.descriptionAbout = response.about;
        },
        async updateWebsite() {
          
            if(this.verifyFields()){
              this.hasReady = false;
                const payload = {
                "description": this.description,
                "logo": this.logo,
                "favicon": this.favicon,
                "nome": this.name,
                "status": this.status,
                "url": this.url,
                "facebookPixelCode": this.facebook,
                "googleAdsCode": this.gads,
                "googleAnalyticsCode": this.ganalytic,
                "tagManagerCode": this.tagManager,
                "rdrefreshToken": this.rdrefreshToken,
                "rdcode": this.rdcode,
                "rdsecretClient": this.rdSecret,
                "rdclienteId": this.rdClient,
                "color": this.color,
                "typeLayout": this.activeTheme,
                "about" : this.descriptionAbout,

                "facebookPixelCode": this.facebook,
                "googleAdsCode": this.gads,
                "googleAnalyticsCode": this.ganalytic,
                "tagManagerCode": this.tagManager,
                "rdrefreshToken": this.rdrefreshToken,
                "rdcode": this.rdcode,
                "rdsecretClient": this.rdSecret,
                "rdclienteId": this.rdClient,
                "about":this.descriptionAbout
            }
              try {
                const response = await websiteApi.editWebsite(this.token, this.id, payload);
                this.getWebsite();
                this.hasReady = true;
              } catch (error) {
                console.error('Erro ao atualizar dados do website:', error);
              }
        }
        },
        verifyFields() {
            if (!this.name) {
                this.error[0] = true; 
                return false;
            }
            if (!this.description) {
              this.error[1] = true;
              return false;
            }
            return true;
        },
        async putFileAws(url, file){
          try {
              const response = await apiAWS.putFileAWS(url, file);
          } catch (error) {
              console.error('Erro ao enviar arquivo para AWS');
          }
        },
        async updateFilePathLogo(event){
            this.loaderImage = false;
            const file = event.target.files[0];
            this.logoPreview = URL.createObjectURL(file);
            try {
                const response =  await apiAWS.getSignatureImage(this.token, file.name);
                this.putFileAws(response.url, file);
                this.logo = response.token;
                this.loaderImage = true;
            } catch (error) {
                console.error('Erro na geração do TOKEN AWS')
            }
        },
        async updateFilePathFavicon(event){
            this.loaderImage = false;
            const file = event.target.files[0];
            this.faviconPreview = URL.createObjectURL(file);
            try {
                const response =  await apiAWS.getSignatureImage(this.token, file.name);
                this.putFileAws(response.url, file);
                this.favicon = response.token;
                console.debug(response);
                this.loaderImage = true;
              } catch (error) {
                console.error('Erro na geração do TOKEN AWS')
            }
        },
    },
    created() {
        this.getWebsite();
    }
  }
</script>

<style scoped>
.actionsSave {
  position: absolute;
  bottom: 30px;
  right: 35px;
}
button.btnSave {
  color: #250891;
  width: 150px;
  background-color: #ffffff;
  padding: 6px 10px;
  border-radius: 5px;
  transition: all 500ms ease-in-out;
  font-size: 18px;
  border: 2px solid #250891;
}
button.btnSave:hover {
  background-color: #160266;
  color: white;
}
.titlelayoutBasic{
  text-align: left;
  padding: 10px;
  border-bottom: 1px solid #e3e3e3;
  width: 100%;
}
.layoutBasic {
    display: flex;
    flex-wrap: wrap;
}
.textarea textarea {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    resize: none;
    outline: none;
    color: #626262;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 0.5em;
    box-shadow: none;
    width: 100%;
    min-height: 95px;
}
.titleItemLayout {
    text-align: left;
    padding: 0px 15px;
}
@media (max-width: 600px) {
  .col-form-inner.col-6.col-md-6.col-lg-6 {
    width: 100%;
  }
  .weflix-admin-layoutBasic small {
    font-size: 12px;
    color: #666;
  }
  .weflix-admin-layoutBasic .form-group .form-label label {
    font-size: 16px;
  }
  .weflix-admin-layoutBasic .logo-form-inner {
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 10px;
  }
  .weflix-admin-layoutBasic .actionsSave {
    position: relative;
    bottom: 0;
    right: 0;
    padding: 10px 10px 10px 10px;
  }
}
</style>