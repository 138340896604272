<template>
    <div class="container">
        <div class="titleContainer">
            <h3>Visão Geral</h3>
            <p>Acompanhe aqui a saúde geral do seu negócio.
            </p>
        </div>
        <div class="cardsContainer" v-if="hasReady">
            <div class="row ">
                <div class="card-sm">
                    <div class="titleCard">
                        <h4>Assinantes</h4>
                    </div>
                    <div class="contentCard">
                        <h3>{{subscribers}}</h3>
                        <p>Total de assinantes</p>
                    </div>
                </div>
                <div class="card-md">
                    <div class="titleCard">
                        <h4>Armazenamento</h4>
                    </div>
                    <div class="contentCard">
                        <div class="contentCardMult mult">
                            <h3>{{returnStorage}}%</h3>
                            <small>Utilizado</small>
                        </div>
                        <div class="storageUsage">
                            <progress :value="storage" max="100"></progress>
                        </div>
                        <div class="storageUsageInfo">
                            <small>{{videoTimeUsed}}/{{videoTimeTotal }} horas restantes</small>
                        </div>
                    </div>
                </div>
                <div class="card-sm fastAccess">
                    <div class="titleCard">
                        <h4>Acesso Rápido</h4>
                    </div>
                    <button class="fastAccessBtn" @click="this.$router.push('/admin/nova-colecao')">
                        <fa icon="fa-film"/>
                        Nova coleção
                    </button>
                    <button class="fastAccessBtn" @click="this.$router.push('/admin/novo-usuario')">
                        <fa icon="fa-user-plus"/>
                        Adicionar Usuário
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="card-lg">
                    <div class="titleCard">
                        <h4>Visualizações</h4>
                        <small>Últimos 30 dias</small>
                    </div>
                    <Chart :data="dataViewVideos"/>
                </div>
                <div class="card-md-extra">
                    <div class="titleCard">
                        <h4>Principais vídeos</h4>
                        <small>Últimos 30 dias</small>
                    </div>
                    <div class="contentLastVideos">
                        <div class="subtitles">
                            <small>Conteúdo</small>
                            <small>Visualizações</small>
                        </div>
                        <div class="contentVideo" v-for="video in mostViewVideos" :key="video.id">
                            <div class="videoContent">
                                <img style="border: 1px solid #929292;width: 100px;border-radius: 5px;" :src="video.finalVideoThumbnail" alt="">
                                <h5>{{video.title}}</h5>
                            </div>
                            <h5>{{video.times}}</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <Loader :hasReady="hasReady"/>
</template>
<script>
import Chart from '@/components/Weflix/Chart.vue'
import Dashboard from '@/utils/Weflix/dashboar'
import { mapState, mapActions } from 'vuex'
import Loader from '@/components/Weflix/Loader.vue'

export default {
    data() {
        return {
            subscribers: 0,
            storage: 0,
            videoTimeTotal: 0,
            videoTimeUsed: 0,
            hasReady: false,
            dataViewVideos: [],
            mostViewVideos: [],
            returnStorage: 0,
        }
    },
    computed:{
        ...mapState(['token'])
    },
    watch: {
        storage(newValue) {
            this.returnStorage = Math.round(newValue);
        }
    },
    components: {
        Chart,
        Loader
    },
    methods:{
        ...mapActions(['storePanelData']),
        async getDataDashboard(){
            try {
                const response = await Dashboard.getDataDashboard(this.token);
                this.storePanelData({panelData: response});
                this.subscribers = response.totalSubscriber;
                this.storage = response.percentage;
                this.videoTimeTotal = response.videoTimeTotal;
                this.videoTimeUsed = response.videoTimeUsed;
                this.dataViewVideos = response.progressDataMap;
                this.mostViewVideos = response.topVideos;
                this.hasReady = true;
            } catch (error) {
                console.error('Erro ao obter dados do painel:', error);
            }
        }
    },
    created(){
        this.getDataDashboard();
    }
}
</script>

<style scoped>
@import url(./global.css);

.container {
   max-width: 100%;
}
.titleContainer {
    display: block;
    text-align: left;
    padding: 0px 10px;
    border-bottom: 1px solid #e8e8e8;
    margin: 10px;
}
.cardsContainer .row {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    gap: 20px;
    margin-bottom: 20px;
}
.cardsContainer {
    padding: 10px;
}
.card-sm {
    width: 26%;
    padding: 20px;
    border-radius: 10px;
    text-align: left;
    background-color: #f9f9f9;
}
.card-lg {
    width: 65%;
    padding: 20px;
    border-radius: 10px;
    text-align: left;
    background-color: #f9f9f9;
}
.card-md {
    width: 44% !important;
    padding: 20px;
    border-radius: 10px;
    text-align: left;
    background-color: #f9f9f9;
}
.container {
    background-color: white;
    padding: 10px 0px;
    padding-bottom: 50px;
}
.contentCard.mult {
    display: flex;
    justify-content: space-around;
}
.contentCard {
    padding: 0;
    text-align: left;
}
.titleCard {
    text-align: left;
    margin: 10px 0px;
    margin-bottom: 20px;
}
.card-md-extra {
    width: 32%;
    padding: 20px;
    border-radius: 10px;
    text-align: left;
    background-color: #f9f9f9;
}
.titleCard h4 {
    font-size: 15px;
    font-weight: 600;
}
.contentCard h3 {
    font-weight: 700;
}
.contentCardMult.mult {
    display: flex;
    align-items: baseline;
    gap: 10px;
}
.card-sm.fastAccess {
    background-color: white;
    padding: 0px 10px;
}
button.fastAccessBtn {
    width: 100%;
    background-color: #eef0fc;
    margin: 10px 0px;
    padding: 15px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-weight: 600;
    color: #250991;
}
button.fastAccessBtn svg {
    color: #250991;
}
.card-sm.fastAccess .titleCard {
    margin: 10px 0px !important;
}
.storageUsage progress {
    width: 100%;
}
.storageUsageInfo {
    text-align: right;
}
.storageUsageInfo small {
    color: gray;
}
.subtitles {
    display: flex;
    justify-content: space-between;
}
.contentVideo {
    display: flex;
    justify-content: space-between;
    margin: 10px 0px;
    align-items: center;
}
.videoContent {
    display: flex;
    gap: 10px;
    align-items: center;
}
.videoContent h5 {
    font-size: 15px;
    font-weight: 600;
}
.contentVideo h5 {
    font-size: 15px;
    font-weight: 600;
    color: gray;
}
@media (max-width: 500px) {
    .row .card-sm {
        width: 45%;
    }
    .card-sm.fastAccess {
        width: 100%;
    }
    .cardsContainer .row .card-lg {
        width: 100%;
    }
    .cardsContainer .row .card-md-extra {
        width: 100%;
    }
}
</style>