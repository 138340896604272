<template>
  <div class="dashboard">       
    <Sidebar/>
    <div class="page">
      <div class="content dashboard superAdmin">
        <div class="container">
          <div class="titleContainer">
            <div class="primaryHeader">
              <h3>Layout e Personalização</h3>
              <p>
                {{currentCompanyCreated.company.name}}
              </p>
            </div>
          </div>
          <div class="container" v-if="hasReady">
            <div class="row">
              <!-- <div class="col-form-inner col-6 col-md-6 col-lg-6">
                <div class="form-group">
                  <div class="form-input">
                    <div class="form-label">
                      <label>Cor da Empresa<v-tooltip text="Campo obrigatório" location="top">
                        <template v-slot:activator="{ props }">
                          <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                        </template>
                      </v-tooltip></label>
                    </div>
                    <div class="inputsColors">
                      <input type="color" id="body" name="body"  v-model="color" />
                      <input
                      type="text" 
                      required
                      class="form-control"
                      v-model="color"
                      placeholder="#ffffff"
                    />
                    </div>              
                    <small>Cores básica para botões e outros espaços do site.</small>
                  </div>   
                </div>
            </div> -->

            <div class="col-form-inner col-6 col-md-6 col-lg-6">
                <div class="form-group">
                  <div class="form-label">
                    <label>Logo</label>
                    <small>Insira uma imagem maior que 250x250 pixels</small>
                  </div>
                  <div class="form-file">
                    <div class="file-wrapper primary">
                      <img
                      :src="logoPreview? logoPreview : logo"
                      alt="Logo"
                      v-show="logoPreview || logo"
                      style="width: 250px;"
                    />
                      <span class="thumbnail">
                        <span class="icon">
                          <svg
                            width="46"
                            height="39"
                            viewBox="0 0 46 39"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M45 19.9308L36.7274 12.3475C36.1595 11.827 35.2948 11.8047 34.7009 12.2953L18.824 25.411C18.3196 25.8277 17.6074 25.8812 17.0464 25.5446L11.1009 21.9773C10.5893 21.6703 9.94666 21.6856 9.45024 22.0166L1 27.6501"
                              stroke="#9D9FA3"
                              stroke-width="1.25714"
                            ></path>
                            <rect
                              x="1.00049"
                              y="1.40454"
                              width="43.9461"
                              height="36.1909"
                              rx="2.58507"
                              stroke="#9D9FA3"
                              stroke-width="1.25714"
                            ></rect>
                            <circle
                              cx="14.8948"
                              cy="10.6676"
                              r="3.71629"
                              stroke="#9D9FA3"
                              stroke-width="1.25714"
                            ></circle>
                          </svg>
                        </span>
                        <span class="text">
                          Clique aqui para selecionar a imagem
                        </span>
                        <LoaderImage :hasReady="loaderImage"/>
                      </span>
                      <input
                        class="form-control"
                        type="file"
                        @change="updateFilePathLogo"
                        accept=".png, .jpg, .jpeg, .webp"
                      />
                    </div>
                  </div>
                </div>
            </div>

            <div class="col-form-inner col-6 col-md-6 col-lg-6">
                <div class="form-group">
                    <div class="form-label">
                    <label>Favicon</label>
                    <small>Insira uma imagem maior que 250x250 pixels</small>
                    </div>
                    <div class="form-file">
                    <div class="file-wrapper primary">
                        <img
                        :src="faviconPreview? faviconPreview : favicon"
                        alt="Favicon"
                        v-show="faviconPreview || favicon"
                        style="width: 100px;"
                    />
                        <span class="thumbnail">
                        <span class="icon">
                            <svg
                            width="46"
                            height="39"
                            viewBox="0 0 46 39"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            >
                            <path
                                d="M45 19.9308L36.7274 12.3475C36.1595 11.827 35.2948 11.8047 34.7009 12.2953L18.824 25.411C18.3196 25.8277 17.6074 25.8812 17.0464 25.5446L11.1009 21.9773C10.5893 21.6703 9.94666 21.6856 9.45024 22.0166L1 27.6501"
                                stroke="#9D9FA3"
                                stroke-width="1.25714"
                            ></path>
                            <rect
                                x="1.00049"
                                y="1.40454"
                                width="43.9461"
                                height="36.1909"
                                rx="2.58507"
                                stroke="#9D9FA3"
                                stroke-width="1.25714"
                            ></rect>
                            <circle
                                cx="14.8948"
                                cy="10.6676"
                                r="3.71629"
                                stroke="#9D9FA3"
                                stroke-width="1.25714"
                            ></circle>
                            </svg>
                        </span>
                        <span class="text">
                            Clique aqui para selecionar a imagem
                        </span>
                        <LoaderImage :hasReady="loaderImage"/>
                        </span>
                        <input
                        class="form-control"
                        type="file"
                        @change="updateFilePathFavicon"
                        accept=".png, .jpg, .jpeg, .webp, .ico"
                        />
                    </div>
                    </div>
                </div>
                </div>
                
                  <hr>
                  <div class="col-form-inner col-md-6 col-lg-6 col-6">
                    <div class="form-group">
                    <div class="form-label">
                      <label>Nome do Domínio</label>
                    </div>
                    <div class="form-input">
                      <div class="flex domain_input" style="position:relative;gap: 10px;align-items: center;">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Meu Domínio"
                        v-model="urlTemporary"
                        @keyup="createFullDomain()"
                        style="width: 70%;"
                      />
                      <fa icon="fa-check icon_check" v-if="hasValidDomain==true"/>
                      <fa icon="fa-times icon_check" v-if="hasValidDomain==false"/>
                      
                      <button class="btn_new" @click="validateDomain">Verificar</button>
                    </div>
                      <small class="small">Apenas texto, sem espaços ou caracteres especiais. Limite de 30 caracteres.</small>

                    </div>
                    </div>
                </div>

                <div class="domainPreview col-md-6 col-lg-6 col-6">
                    <div class="form-group">

                    <div class="form-label">
                      <label>Domínio Final</label>
                    </div>
                    <div class="form-input" style="position:relative;">
                      <input
                        type="text"
                        class="form-control disabled"
                        :placeholder="'https://www.seudominio.' + (currentCompanyCreated.company.typeSystem == 1 ? 'weeduc' : 'weflix') + '.com.br'"
                        v-model="url"
                        disabled
                      />
                      <small class="small">Domínio que será visto pelos usuários.</small>
  
                      <span class="iconCopy"><i class="pi pi-copy"></i></span>
                    </div>
                    </div>
                  </div>


                  <div class="col-form-inner col-6 col-md-6 col-lg-6 ">
                    <div class="form-group">
                      <div class="form-label">
                        <label>Domínio Primário</label>
                      </div>
                      <div class="form-input">
                        <input
                          type="text"
                          class="form-control"
                            placeholder="https://www.seudominio.com.br"
                          v-model="url1"
                        />
                        <small class="small">Domínio primário para acesso.</small>
    
                      </div>
                    </div>
                  </div>


                  <div class="col-form-inner col-6 col-md-6 col-lg-6">
                    <div class="form-group">
                      <div class="form-label">
                        <label>Domínio Adicional</label>
                      </div>
                      <div class="form-input">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="https://www.seudominio.com.br"
                          v-model="url2"
                        />
                        <small class="small">Domínio secundário para acesso.</small>
    
                      </div>
                    </div>
                  </div>

                  <hr>

    <div class="col-form-inner col-6 col-md-6 col-lg-6">
        <div class="col-form-inner col-12 col-md-12 col-lg-12">
            <div class="form-group">
                <div class="form-label">
                <label>Título<v-tooltip text="Campo obrigatório" location="top">
                    <template v-slot:activator="{ props }">
                    <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                    </template>
                </v-tooltip></label>
                </div>
                <div class="form-input">
                <input
                    type="text" 
                    required
                    class="form-control"
                    v-model="title"
                    placeholder="Meu Site"
                />
                <small>Digite o título do seu site.</small>
                </div>
            </div>
        </div>
        <div class="col-form-inner col-12 col-md-12 col-lg-12">
            <div class="form-group">
            <div class="form-label">
                <label>Tipo do Layout<v-tooltip text="Selecione o estado" location="top">
                <template v-slot:activator="{ props }">
                    <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                </template>
                </v-tooltip></label>
            </div>
            <div class="form-input">
                <select
                class="form-control"
                v-model="typeLayout"
                >
                <option value="" disabled selected>Selecione o tipo</option>
                <option value="0">Dark</option>
                <option value="1">Light</option>
                </select>
                <small class="small">
                Selecione o tipo de sistema 1 - Weeduc e 2 - Weflix.
                </small>
            </div>
            </div>
        </div>
        <div class="col-form-inner col-12 col-md-12 col-lg-12" v-if="currentCompanyCreated.company.typeSystem == 1">
            <div class="form-group">
            <div class="form-label">
                <label>Tipo do Layout Portal<v-tooltip text="Selecione o estado" location="top">
                <template v-slot:activator="{ props }">
                    <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                </template>
                </v-tooltip></label>
            </div>
            <div class="form-input">
                <select
                class="form-control"
                v-model="typeLayoutPortal"
                >
                <option value="" disabled selected>Selecione o tipo</option>
                <option value="1">Clean</option>
                <option value="2">Dark</option>
                </select>
                <small class="small">
                Selecione o tipo de sistema 1 - Weeduc e 2 - Weflix.
                </small>
            </div>
            </div>
        </div>
        <div class="col-form-inner col-12 col-md-12 col-lg-12">
            <div class="form-group">
                <div class="form-label">
                <label>E-mail<v-tooltip text="Campo obrigatório" location="top">
                    <template v-slot:activator="{ props }">
                    <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                    </template>
                </v-tooltip></label>
                </div>
                <div class="form-input">
                <input
                    type="text" 
                    required
                    class="form-control"
                    v-model="emailSite"
                    placeholder="exemplo@dominio.com"
                />
                <small>Digite o e-mail do seu site.</small>
                </div>
            </div>
        </div>
        <div class="col-form-inner col-12 col-md-12 col-lg-12">
            <div class="form-group">
                <div class="form-label">
                <label>Telefone<v-tooltip text="Campo obrigatório" location="top">
                    <template v-slot:activator="{ props }">
                    <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                    </template>
                </v-tooltip></label>
                </div>
                <div class="form-input">
                <input
                    type="text" 
                    required
                    class="form-control"
                    v-model="phoneSite"
                    placeholder="Digite o telefone"
                    v-mask="'(##) #####-####'"
                />
                <small>Digite o telefone do seu site.</small>
                </div>
            </div>
        </div>
        <div class="col-form-inner col-12 col-md-12 col-lg-12">
          <div class="form-group">
            <div class="form-input">
              <div class="form-label">
                <label>Cor da Empresa<v-tooltip text="Campo obrigatório" location="top">
                  <template v-slot:activator="{ props }">
                    <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                  </template>
                </v-tooltip></label>
              </div>
              <div class="inputsColors">
                <input type="color" id="body" name="body"  v-model="color" />
                <input
                type="text" 
                required
                class="form-control"
                v-model="color"
                placeholder="#ffffff"
              />
              </div>              
              <!-- <small class="alertText">Campo obrigatório e deve ser preenchido corretamente.</small> -->
              <small>Cores básica para botões e outros espaços do site.</small>
            </div>   
            <div class="form-label">
              <label>Sugestões de Cores<v-tooltip text="Campo obrigatório" location="top">
                <template v-slot:activator="{ props }">
                  <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                </template>
              </v-tooltip></label>
            </div>         
            <div class="wrap-suggested-colors" style="display: flex; gap: 10px;">
              <span @click="setColor('#1AB490')" style="background-color:#1AB490;"></span>
              <span @click="setColor('#28C565')" style="background-color:#28C565;"></span>
              <span @click="setColor('#2E8DD6')" style="background-color:#2E8DD6;"></span>
              <span @click="setColor('#904EAC')" style="background-color:#904EAC;"></span>
              <span @click="setColor('#B93126')" style="background-color:#B93126;"></span>
              <span @click="setColor('#E54236')" style="background-color:#E54236;"></span>
              <span @click="setColor('#E47220')" style="background-color:#E47220;"></span>
              <span @click="setColor('#EFBC11')" style="background-color:#EFBC11;"></span>
              <span @click="setColor('#250891')" style="background-color:#250891;"></span>
              <span @click="setColor('#FF69B4')" style="background-color:#FF69B4;"></span>
              <span @click="setColor('#4B0082')" style="background-color:#4B0082;"></span>
              <span @click="setColor('#006400')" style="background-color:#006400;"></span>
              <span @click="setColor('#8B4513')" style="background-color:#8B4513;"></span>
              <span @click="setColor('#00008B')" style="background-color:#00008B;"></span>
            </div>
          </div>
      </div>
    </div>
    <div class="col-form-inner col-6 col-md-6 col-lg-6">
        <div class="col-form-inner col-12 col-md-12 col-lg-12 textarea">
            <div class="form-group">
            <div class="form-label">
                <label>Descrição<v-tooltip text="Campo obrigatório" location="top">
                <template v-slot:activator="{ props }">
                    <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                </template>
                </v-tooltip></label>
            </div>
            <div class="form-input"> 
                <textarea class="textarea_input" v-model="description"></textarea>
                <small>Nome comercial do seu curso, uso externo.</small>
            </div>
            </div>
        </div>
        <div class="col-form-inner col-12 col-md-12 col-lg-12 textarea">
          <div class="form-group">
          <div class="form-label">
              <label>Sobre<v-tooltip text="Campo obrigatório" location="top">
              <template v-slot:activator="{ props }">
                  <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
              </template>
              </v-tooltip></label>
          </div>
          <div class="form-input"> 
              <textarea class="textarea_input" v-model="about"></textarea>
              <small>Nome comercial do seu curso, uso externo.</small>
          </div>
          </div>
      </div>
        <div class="col-form-inner col-12 col-md-12 col-lg-12 textarea">
            <div class="form-group">
            <div class="form-label">
                <label>Palavras Chaves<v-tooltip text="Campo obrigatório" location="top">
                <template v-slot:activator="{ props }">
                    <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                </template>
                </v-tooltip></label>
            </div>
            <div class="form-input"> 
                <textarea class="textarea_input" v-model="keywords"></textarea>
                <small>Nome comercial do seu curso, uso externo.</small>
            </div>
            </div>
        </div>
      
    </div>
    <hr>
    <div class="col-form-inner col-12 col-md-12 col-lg-12">
      <div class="form-group">
      <div class="form-label">
          <label>Banner Principal</label>
          <small>Insira uma imagem maior que 250x250 pixels</small>
      </div>
      <div class="form-file">
        <img
        :src="primaryBannerPreview? primaryBannerPreview : primaryBanner"
        alt="Logo"
        v-show="primaryBannerPreview || primaryBanner"
        style="width: 99%;margin: 10px;"
        />
          <div class="file-wrapper primary">
        
          <span class="thumbnail">
              <span class="icon">
              <svg
                  width="46"
                  height="39"
                  viewBox="0 0 46 39"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                  <path
                  d="M45 19.9308L36.7274 12.3475C36.1595 11.827 35.2948 11.8047 34.7009 12.2953L18.824 25.411C18.3196 25.8277 17.6074 25.8812 17.0464 25.5446L11.1009 21.9773C10.5893 21.6703 9.94666 21.6856 9.45024 22.0166L1 27.6501"
                  stroke="#9D9FA3"
                  stroke-width="1.25714"
                  ></path>
                  <rect
                  x="1.00049"
                  y="1.40454"
                  width="43.9461"
                  height="36.1909"
                  rx="2.58507"
                  stroke="#9D9FA3"
                  stroke-width="1.25714"
                  ></rect>
                  <circle
                  cx="14.8948"
                  cy="10.6676"
                  r="3.71629"
                  stroke="#9D9FA3"
                  stroke-width="1.25714"
                  ></circle>
              </svg>
              </span>
              <span class="text">
              Clique aqui para selecionar a imagem
              </span>
              <LoaderImage :hasReady="loaderImage"/>
          </span>
          <input
              class="form-control"
              type="file"
              @change="updateFilePathBanner"
              accept=".png, .jpg, .jpeg, .webp"
          />
          </div>
      </div>
      </div>
  </div>

            </div>
            <div class="actionsSave">
              <button class="btnSave" @click="editLayoutCompany">
              Salvar
              </button>
            </div>
          </div> 
        </div>
      </div>
      <Loader :hasReady="hasReady"/>
    </div>
    <Footer/>
  </div>
</template>

<script>
  import Sidebar from '@/components/Weflix/Sidebar-SuperAdmin.vue'
  import Footer from '@/components/Admin/Footer/footerSuperAdmin.vue'
  import { mapState } from 'vuex';
  import apiAWS from '@/utils/storage/aws';
  import apiSiteDomain from '@/utils/Site/domain';
  import superAdmin from '@/utils/superAdmin';
  import Loader from '@/components/Weflix/Loader.vue';


  export default {
    data() {
      return {
        idCompany: null,
        logo: null,
        favicon: null,
        logoPreview: null,
        faviconPreview: null,
        title: null,
        description: null,
        keywords: null,
        urlTemporary: null,
        about: null,
        url: null,
        url1: null,
        url2: null,
        typeLayout: null,
        typeLayoutPortal: null,
        emailSite: null,
        phoneSite: null,
        primaryBanner: null,
        primaryBannerPreview: null,
        color: null,
        hasValidDomain: null,
        hasReady: true
      }
    },
    components: {
      Sidebar,
      Footer,
      Loader
    },
    computed: {
      ...mapState(['token','currentCompanyCreated']),
    },
    methods: {
        
      setPageTitle() {
        document.title ='Tutor -Super Admin';
      },
      setFavicon() {
        const favicon = document.querySelector('link[rel="icon"]');
        if (!favicon) {
          const newFavicon = document.createElement('link'); 
          newFavicon.rel = 'icon';
          newFavicon.href = this.imagePath || '/favicon-tutor.ico';
          
          document.head.appendChild(newFavicon);
        } else {
          favicon.href = this.imagePath || '/favicon-tutor.ico';
        }
      },
      async putFileAws(url, file){
            try {
                const response = await apiAWS.putFileAWS(url, file);
            } catch (error) {
                console.error('Erro ao enviar arquivo para AWS');
            }
      },

      async updateFilePathLogo(event){
          const file = event.target.files[0];
          this.logoPreview = URL.createObjectURL(file)

          try {
            const response =  await apiAWS.getSignatureImageCompany(this.token, file.name, this.currentCompanyCreated.company.id, this.currentCompanyCreated.representative.id);
            this.putFileAws(response.url, file)
              this.logo = response.token;
          } catch (error) {
              console.error('Erro na geração do TOKEN AWS')
          }
      },
      async updateFilePathFavicon(event){
          const file = event.target.files[0];
          this.faviconPreview = URL.createObjectURL(file)

          try {
              const response =  await apiAWS.getSignatureImageCompany(this.token, file.name, this.currentCompanyCreated.company.id, this.currentCompanyCreated.representative.id);
              this.putFileAws(response.url, file)
              this.favicon = response.token;
          } catch (error) {
              console.error('Erro na geração do TOKEN AWS')
          }
      },
      async updateFilePathBanner(event){
          const file = event.target.files[0];
          this.primaryBannerPreview = URL.createObjectURL(file)

          try {
              const response =  await apiAWS.getSignatureImage(this.token, file.name);
              this.putFileAws(response.url, file)
              this.primaryBanner = response.token;
          } catch (error) {
              console.error('Erro na geração do TOKEN AWS')
          }
      },
      async editLayoutCompany() {
        this.hasReady = false;
        const payload = {
            "idCompany": this.currentCompanyCreated.company.id,
            "website": {
              "nome": this.title,
              "url": this.url,
              "url1": this.url1,
              "url2": this.url2,
              "title": this.title,
              "description": this.description,
              "keywords": this.keywords,
              "logo": this.logo,
              "about": this.about,
              "favicon": this.favicon,
              "status": 1,
              "typeLayout": this.typeLayout,
              "typeLayoutPortal": this.typeLayoutPortal,
              "email": this.emailSite,
              "phone": this.phoneSite,
              "color": this.color,
            },
            "contents": [
              {
                "type": 1,
                "typeEnum": "",
                "title": "",
                "description": "",
                "pathImage": this.primaryBanner,
                "url": "string",
                "order": 1,
                "status": 1,
                "statusEnum": "Ativo"
            }
          ]
        }
        try {
          const response = await superAdmin.editLayoutCompany(this.token, payload);
          this.hasReady = true;
          this.$router.push('/admin/empresas/');
        } catch (error) {
          console.error(error);
        }
      },
      createFullDomain(){
        this.urlTemporary = this.urlTemporary.replace(/[^\w\s]/gi, '').replace(/\s+/g, '').toLowerCase();
        this.url = 'https://' + this.urlTemporary + '.' + (this.currentCompanyCreated.company.typeSystem == 1 ? 'weeduc' : 'weflix') + '.com.br';
      },
      async validateDomain(){
        const obj = {
          url: this.url
        }
        try {
          const response = await apiSiteDomain.postVerifyDomaintData(this.token, obj);
          this.hasValidDomain = response;

        } catch (error) {
          console.error(error);
        }
      },
      //TODO: Copiar o link
      copyLink(){
        navigator.clipboard.writeText(this.fullDomainTutor);
      },
      setColor(color){
        this.color = color;
      }
    },
    mounted() {
      this.setPageTitle();
      this.setFavicon();
    }
  }
</script>

<style>
.content.dashboard > .container {
  padding-bottom: 70px;
}
  .flex {
    display: flex;
    align-items: flex-start;
    gap: 0px;
    flex-wrap: wrap;
  }
  .dashboard {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #eef0fc;
    margin: 0 auto;
    position: relative;
  }   
  .content.dashboard {
    background-color: transparent;
    margin: 0;
    width: 100%;
    padding: 10px 10px;
    height: 100%;
  }
  .col-form-inner {
    padding: 0px;
}
.form-group {
    margin: 5px 10px !important;
}
textarea.textarea_input {
    height: 150px !important;
    font-family: "Poppins" !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 28px !important;
    resize: none !important;
    outline: none !important;
    padding: 0.5rem 0.75rem !important;
    color: #5f5f5f !important;
    background-color: #fff !important;
    border: 1px solid #dbdcde !important;
    box-shadow: none !important;
    width: 100% !important;
    border-radius: 5px !important;
}
button.btn_new {
    color: #250891;
    width: 20%;
    background-color: #ffffff;
    padding: 6px 10px;
    border-radius: 5px;
    transition: all 500ms ease-in-out;
    font-size: 18px;
    border: 1px solid #250891;
}
.content.dashboard.superAdmin {
    margin-bottom: 70px;
}
hr {
    width: 95% !important;
    margin: 25px auto !important;
}
span.iconCopy {
    position: absolute;
    top: 10px;
    right: 5px;
    cursor: pointer;
    z-index: 9;
}
svg.svg-inline--fa.fa-check {
    color: green;
}
svg.svg-inline--fa.fa-xmark {
    color: white;
}
.container {
    padding: 10px;
}
</style>