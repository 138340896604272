<template>
    <Header/> 
    <section class="myAccount lightThemeMyAccount">
        <section class="section link-list primary">
            <div class="link-list-content">
              <div class="content-link create-course">
                <div class="link">
                  <a
                    class="link-content"
                    :class="[isActiveTab(1)? 'active': '']"
                    @click="setActiveTab(1)"
                  >
                    Dados Pessoais
                  </a>
                </div>
                <!-- <div class="link">
                    <a
                      class="link-content"
                      :class="[isActiveTab(2)? 'active': '']"
                      @click="setActiveTab(2)"
                    >
                      Planos e Assinaturas
                    </a>
                  </div>
                  <div class="link">
                    <a
                      class="link-content"
                      :class="[isActiveTab(3)? 'active': '']"
                      @click="setActiveTab(3)"
                    >
                      Pagamentos
                    </a>
                  </div> -->
                <div class="link">
                  <a
                    class="link-content"
                    :class="[isActiveTab(2)? 'active': '']"
                    @click="setActiveTab(2)"
                  >
                    Senha
                  </a>
                </div>
              </div>
            </div>
          </section>
      
           <section
            class="section config-form primary myAccountFormUser"
            :class="[isActiveTab(1)? 'active': '']"
          >
            <User />
          </section>
          <section
          class="section config-form primary myAccountFormUser"
          :class="[isActiveTab(2)? 'active': '']"
        >
          <Password />
        </section>
        <!-- <section
        class="section config-form primary"
        :class="[isActiveTab(3)? 'active': '']"
      >
        <Password />
      </section>
      <section
      class="section config-form primary"
      :class="[isActiveTab(4)? 'active': '']"
    >
      <Password />
    </section> -->
    </section>
    
    <Footer/>

    
</template>

<script>
import Header from '@/components/Weflix/LigthTheme/Header.vue';
import Footer from '@/components/Weflix/LigthTheme/Footer.vue';
import { mapState, mapActions } from 'vuex';
import User from '@/components/Weflix/LigthTheme/User/Account.vue'
import Password from '@/components/Weflix/LigthTheme/User/Password.vue'
export default {
    data(){
        return{
            activeTab: 1,
        }
    },
    computed:{
        ...mapState(['finalWebsite']),
    },
    components:{
        Header,
        Footer,
        User,
        Password
    },
    methods:{
     
        setActiveTab(tabNumber) {
        this.activeTab = tabNumber;
      },
      isActiveTab(tabNumber) {
        return this.activeTab === tabNumber;
      },
    
    }, 
    created(){
       
    }
}
</script>

<style scoped>
section.section.config-form.primary.myAccountFormUser.active {
    height: 65vh;
}
section.myAccount {
    background-color: white;
    padding-top: 100px;
    position: relative;
}
section.myAccount .config-form.primary {
    background-color: white;
    border-radius: 0px 0px 0.5em 0.5em;
    padding: 1rem !important;
    overflow: hidden;
}
section.myAccount .title ,section.myAccount section.section.link-list.primary,section.myAccount section.section.config-form.primary.active {
    max-width: 1500px;
    margin: 0 auto;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-radius: 5px;
}
section.myAccount .link-list-content .content-link .link .link-content.active {
    color: rgb(24, 24, 28);
    background-color: rgb(255, 255, 255);
    border-right: 1px solid #ddd;
}
section.myAccount  .link-list-content .content-link .link .link-content{
    color: rgb(24, 24, 28);
    background-color: rgb(232 235 247);
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
}
section.myAccount .title h3{
    color: white;
    margin: 0px;
    margin: 10px 0px;
    font-size: 20px;
}
</style>
<style>
.myAccount .myAccountFormUserLight .form-group .form-label label{
    color: #212529 !important;
}
.myAccount .double path {
    fill: none;
    stroke: #ffffff;
    stroke-width: 20px;
    transform: scale(0.7);
}
.myAccount  section.section.config-form.primary.active {
    position: relative;
}
.myAccount  button.btnSave {
    color: rgb(24, 24, 28);
    width: 150px;
    background-color: #fff;
    padding: 6px 10px;
    border-radius: 5px;
    transition: all 500ms ease-in-out;
    font-size: 18px;
    border: 2px solid #ddd;
}
@media (max-width: 600px) {
  .myAccount.content-link.create-course {
    display: flex;
    justify-content: space-around;
  }
  .myAccount a.link-content {
    width: 50vw;
  }
  .myAccount.lightThemeMyAccount .link-list-content .content-link .link .link-content.active {
    background-color: rgb(255, 255, 255) !important;
    border-right: 1px solid #ddd !important;
  }
  section.myAccount {
    height: auto;
  }
  section.section.config-form.primary.myAccountFormUser.active {
    height: 100% !important;
  }
  .myAccount .actionsSave {
    width: 100% !important;
    text-align: center !important;
    padding: 20px 30px 20px 10px!important;
    bottom: 0 !important;
    right: -16px !important;
  }
  .myAccount .modal-body.double {
    display: block;
    width: 100%;
  }
  .myAccount .form-file .file-wrapper>span.thumbnail {
    flex-direction: row;
    gap: 10px;
  }
  .myAccount.lightThemeMyAccount .form-file .file-wrapper>span.thumbnail span.text {
    max-width: 35vw;
    color: rgb(24, 24, 28);
    font-size: 12px;
  }
}
</style>