<template>
        <div class="container doubts-container adminWeeducCreateVideo">
                <div class="titleContainer myCoursesWeeduc">
                        <h3>Novo vídeo</h3>
                        <p style="font-size: 14px;margin-bottom: 0;">Crie as informações básicas do seu vídeo.</p>
                </div>

                <div class="content-container" v-if="!isSaving">
                        <div class="form-container">
                                <div class="form-row">
                                        <div class="form-group col-12">
                                                <p class="form-label-title">Origem do vídeo</p>
                                                <div class="video-source-options">
                                                        <label class="radio-option">
                                                                <input 
                                                                        type="radio" 
                                                                        v-model="videoData.source" 
                                                                        value="1"
                                                                        :selected="videoData.source === '1'"
                                                                />
                                                                Videoteca
                                                        </label>
                                                        <label class="radio-option">
                                                                <input 
                                                                        type="radio" 
                                                                        v-model="videoData.source" 
                                                                        value="2"
                                                                        :selected="videoData.source === '2'"
                                                                />
                                                                Youtube
                                                        </label>
                                                        <label class="radio-option">
                                                                <input 
                                                                        type="radio" 
                                                                        v-model="videoData.source" 
                                                                        value="3"
                                                                        :selected="videoData.source === '3'"
                                                                />
                                                                Vimeo
                                                        </label>
                                                </div>
                                        </div>

                                        <div class="form-group col-12" v-if="videoData.source == 2 || videoData.source == 3">
                                                <p class="form-label-title">Link do vídeo no {{ videoData.source == 2 ? 'Youtube' : videoData.source == 3 ? 'Vimeo' : 'Videoteca' }} *</p>
                                                <div class="form-input">
                                                        <input 
                                                                type="text"
                                                                class="form-control"
                                                                v-model="videoData.youtubeUrl"
                                                                placeholder="Cole aqui o URL do vídeo"
                                                                required
                                                                @input="updateVideoPreview"
                                                        />
                                                </div>
                                        </div>

                                        <div class="col-form-inner col-12 col-md-12 col-lg-12 input-video" v-if="videoData.source == 1">
                                                <div class="form-group">
                                                        <div class="form-file">
                                                                <div class="file-wrapper primary">
                                                                        <span class="thumbnail thumb-img">
                                                                                <span class="icon">
                                                                                        <svg class="uploadVideoIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M0 128C0 92.7 28.7 64 64 64l256 0c35.3 0 64 28.7 64 64l0 256c0 35.3-28.7 64-64 64L64 448c-35.3 0-64-28.7-64-64L0 128zM559.1 99.8c10.4 5.6 16.9 16.4 16.9 28.2l0 256c0 11.8-6.5 22.6-16.9 28.2s-23 5-32.9-1.6l-96-64L416 337.1l0-17.1 0-128 0-17.1 14.2-9.5 96-64c9.8-6.5 22.4-7.2 32.9-1.6z"/></svg>                    
                                                                                </span>
                                                                                <span class="text">
                                                                                        Clique aqui para adicionar novo vídeo
                                                                                </span>
                                                                        </span>
                                                                        <input class="form-control" multiple type="file" @change="uploadVideoAws" accept=".mp4, .avi"/>
                                                                </div>
                                                                <div class="form-caption">
                                                                        <div class="caption">
                                                                                <small>Formatos aceitos: .mp4, .avi. </small>
                                                                        </div>
                                                                </div>
                                                        </div>
                                                        <div class="upload-status" v-if="isUploading || isUploaded">
                                                                <div class="loading-spinner" v-if="isUploading && !isUploaded">
                                                                        <fa icon="fa-sync fa-spin" />
                                                                        <span>Enviando vídeo...</span>
                                                                </div>
                                                                <div class="upload-complete" v-else-if="isUploaded">
                                                                        <fa icon="fa-check-circle" />
                                                                        <span>Upload concluído com sucesso!</span>
                                                                </div>
                                                                <div class="progress-bar" v-if="isUploading || isUploaded">
                                                                        <div class="progress-fill" :style="{ width: progress + '%' }">
                                                                                <span class="progress-text">{{ Math.round(progress) }}%</span>
                                                                        </div>
                                                                </div>
                                                        </div>
                                                </div>
                                        </div>
                                        <div class="col-form-inner col-12 col-md-12 col-lg-12 input-video" v-if="videoData.source == 1">
                                            <div class="form-group">
                                                <div class="form-label">
                                                    <label>Capa do vídeo </label>
                                                </div>
                                                <div class="form-file">
                                                    <div class="file-wrapper primary">
                                                        <span class="thumbnail thumb-img">
                                                            <span class="icon" v-if="!previewCoverVideo">
                                                                <svg
                                                                    width="46"
                                                                    height="39"
                                                                    viewBox="0 0 46 39"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        d="M45 19.9308L36.7274 12.3475C36.1595 11.827 35.2948 11.8047 34.7009 12.2953L18.824 25.411C18.3196 25.8277 17.6074 25.8812 17.0464 25.5446L11.1009 21.9773C10.5893 21.6703 9.94666 21.6856 9.45024 22.0166L1 27.6501"
                                                                        stroke="#9D9FA3"
                                                                        stroke-width="1.25714"
                                                                    ></path>
                                                                    <rect
                                                                        x="1.00049"
                                                                        y="1.40454"
                                                                        width="43.9461"
                                                                        height="36.1909"
                                                                        rx="2.58507"
                                                                        stroke="#9D9FA3"
                                                                        stroke-width="1.25714"
                                                                    ></rect>
                                                                    <circle
                                                                        cx="14.8948"
                                                                        cy="10.6676"
                                                                        r="3.71629"
                                                                        stroke="#9D9FA3"
                                                                        stroke-width="1.25714"
                                                                    ></circle>
                                                                </svg>
                                                            </span>
                                                            <span class="text" v-if="!previewCoverVideo">
                                                                Clique aqui para selecionar a imagem
                                                            </span>
                                                        </span>
                                                        <input class="form-control" type="file" @change="updateCoverVideo" accept=".png, .jpg, .jpeg, .webp"/>
                                                    </div>
                                                    <div class="form-caption">
                                                        <div class="caption">
                                                            <small> Tamanho: 854 × 480 px, apenas formato PNG e JPG.</small>  
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-12">
                                                <p class="form-label-title">Título *</p>
                                                <div class="form-input">
                                                        <input 
                                                                type="text"
                                                                class="form-control"
                                                                v-model="videoData.title"
                                                                placeholder="Escolha um nome que chame a atenção"
                                                                required
                                                        />
                                                </div>
                                        </div>

                                        <div class="form-group col-12">
                                                <p class="form-label-title">Descrição</p>
                                                <div class="form-input">
                                                        <textarea
                                                                class="form-control"
                                                                v-model="videoData.description"
                                                                placeholder="Descreva o vídeo"
                                                                rows="4"
                                                        ></textarea>
                                                </div>
                                        </div>

                                        <div class="form-group col-12">
                                            <p class="form-label-title">Descrição</p>
                                            <div class="form-input">
                                                    <textarea
                                                            class="form-control"
                                                            v-model="videoData.description"
                                                            placeholder="Descreva o vídeo"
                                                            rows="4"
                                                    ></textarea>
                                            </div>
                                    </div>

                                        <div class="form-group col-12" v-show="showHelp">   
                                                <div v-if="videoData.source == 1">
                                                        <p style="margin-bottom: 10px"><strong><small>Como adicionar um vídeo da Videoteca:</small></strong></p>
                                                        <ol style="margin-left: 20px; line-height: 1.5">
                                                                <li><small>Acesse sua biblioteca de vídeos na Videoteca e escolha o vídeo desejado</small></li>
                                                                <li><small>Ou fazer o upload de um novo vídeo</small></li>
                                                                <li><small>Selecione o vídeo desejado</small></li>
                                                                <li><small>Clique em "Incluir no curso"</small></li>
                                                        </ol>
                                                </div>                     
                                                <div v-if="videoData.source == 2">
                                                        <p style="margin-bottom: 10px"><strong><small>Como adicionar um vídeo do YouTube:</small></strong></p>
                                                        <ol style="margin-left: 20px; line-height: 1.5">
                                                                <li><small>Acesse o vídeo desejado no YouTube</small></li>
                                                                <li><small>Clique no botão "Compartilhar" abaixo do vídeo</small></li>
                                                                <li><small>Copie o link que aparece na janela de compartilhamento</small></li>
                                                                <li><small>Cole o link no campo acima</small></li>
                                                        </ol>
                                                        <p style="margin-top: 10px; color: #666"><small>Exemplo de link válido: https://www.youtube.com/watch?v=XXXXXXXXXX</small></p>
                                                </div>

                                                <div v-if="videoData.source == 3">
                                                        <p style="margin-bottom: 10px"><strong><small>Como adicionar um vídeo do Vimeo:</small></strong></p>
                                                        <ol style="margin-left: 20px; line-height: 1.5">
                                                                <li><small>Acesse o vídeo desejado no Vimeo</small></li>
                                                                <li><small>Clique no botão "Compartilhar" no canto superior direito</small></li>
                                                                <li><small>Copie o link que aparece na primeira opção</small></li>
                                                                <li><small>Cole o link no campo acima</small></li>
                                                        </ol>
                                                        <p style="margin-top: 10px; color: #666"><small>Exemplo de link válido: https://vimeo.com/XXXXXXXXX</small></p>
                                                </div>
                                        </div>
                                </div>

               
                        </div>

                        <div class="preview-container">
                                <div class="video-preview" v-if="videoData.youtubeUrl || videoData.path || previewCoverVideo">
                                        <iframe 
                                                v-if="videoData.source == 2"
                                                :src="getYoutubeEmbedUrl(videoData.youtubeUrl)"
                                                frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowfullscreen
                                        ></iframe>
                                        <iframe 
                                                v-if="videoData.source == 3"
                                                :src="getVimeoEmbedUrl(videoData.youtubeUrl)"
                                                frameborder="0"
                                                allow="autoplay; fullscreen; picture-in-picture"
                                                allowfullscreen
                                        ></iframe>
                                        <img v-if="previewCoverVideo" :src="previewCoverVideo" style="width: 100%; height: 100%; object-fit: cover; border-radius: 8px;" />

                                </div>
                                <div class="video-placeholder" v-else>
                                        <p>Cole o link do vídeo para visualizar</p>
                                </div>
                        </div>
                </div>
                <Loader v-if="isSaving" />
                <div class="button-container">
                        <button class="btn-weflix" @click="$router.go(-1)">Voltar</button>
                        <button class="btn-weflix primary" @click="saveVideo">Salvar</button>
                </div>

                <button class="help-button" @click="toggleHelp">
                        <span v-if="!showHelp"><fa icon="fa-solid fa-question-circle"></fa> Precisa de ajuda?</span>
                        <span v-else><fa icon="fa-solid fa-times-circle"></fa> Ocultar ajuda</span>
                </button>
        </div>
</template>

<script>
import { mapState } from 'vuex';
import apiLesson from '@/utils/lesson';
import Loader from '@/components/Loader/loader.vue';
import Videos from '@/utils/Weflix/videos';
import apiAWS from '@/utils/storage/aws';

export default {
        components: {
                Loader,
                
        },
        data() {
                return {
                        videoData: {
                                source: 1,
                                path: '',
                                title: '',
                                description: '',
                                youtubeUrl: ''
                        },
                        showHelp: false,
                        isSaving: false,
                        isUploading: false,
                        isUploaded: false,
                        progress: 0,
                        tokenVideo: null,
                        previewCoverVideo: null,
                        coverVideo: null,
                        loaderImage: false
                }
        },
        computed: {
                ...mapState(['token','currentCollections', 'currentModule'])
        },
        methods: {
                toggleHelp() {
                        this.showHelp = !this.showHelp;
                },
                getYoutubeEmbedUrl(url) {
                        if (!url) return '';
                        try {
                        const urlObj = new URL(url);
                        const videoId = urlObj.searchParams.get('v');

                        // Tratamento para links curtos do YouTube (youtu.be)
                        if (!videoId && urlObj.hostname.includes('youtu.be')) {
                                return `https://www.youtube.com/embed/${urlObj.pathname.slice(1)}`;
                        }

                        return videoId ? `https://www.youtube.com/embed/${videoId}` : '';
                        } catch (error) {
                        console.error('URL inválida:', error);
                        return '';
                        }
                },
                getVimeoEmbedUrl(url) {
                        const videoId = url.split('/').pop();
                        return videoId ? `https://player.vimeo.com/video/${videoId}` : '';
                },
                updateVideoPreview() {
                        this.videoData.path = this.videoData.youtubeUrl;
                },
                // async saveVideo() {
                //         if (!this.videoData.title) {
                //                 alert('Por favor, preencha o título do vídeo');
                //                 return;
                //         }

                //         if (this.videoData.source == 1 && !this.videoData.path) {
                //                 alert('Por favor, faça o upload de um vídeo');
                //                 return;
                //         }

                //         if ((this.videoData.source === 2 || this.videoData.source === 3) && !this.videoData.youtubeUrl) {
                //                 alert('Por favor, insira o link do vídeo');
                //                 return;
                //         }

                //         this.isSaving = true;

                //         const payload = {
                //                 "type": this.videoData.source,
                //                 "title": this.videoData.title,
                //                 "path": this.videoData.path,
                //                 "description": this.videoData.description,
                //                 "idQuiz": null,
                //                 "idModule": this.currentModule.id,
                //                 "idCourse": this.currentCollections.id,
                //                 "status": 1,
                //                 "coverVideo": this.coverVideo
                //         }
                //         try {
                //                 const response = await apiLesson.createNewLesson(payload, this.token);
                //                 this.isSaving = false;
                //                 this.$router.push('/dashboard/curso');
                //         } catch (error) {
                //                 console.error('Erro ao criar aula');
                //                 alert('Erro ao salvar o vídeo. Por favor, tente novamente.');
                //         } finally {
                //                 this.isSaving = false;
                //         }
                // },
                async saveVideo(){
                    this.hasReady = false;
                    const payload = {
                        "idModule": this.currentModule.id,
                        "title": this.videoData.title,
                        "description": this.videoData.description,
                        "videoThumbnail": this.coverVideo,
                        "sample": 0,
                        "type":2,
                        "token": this.tokenVideo.urls.map(item => item.token),
                        "status": 1,
                        "uploadId": this.tokenVideo.uploadId
                    }
                    try {
                        const response = await apiAWS.createVideoVideoteca(this.token, payload);
                        this.hasReady = false;
                        
                    } catch (error) {
                        console.error('Erro ao enviar arquivo para AWS');
                    }
                },
                async putFileAws(url, file){
                    try {
                        const response = await apiAWS.putFileAWS(url, file);
                    } catch (error) {
                        console.error('Erro ao enviar arquivo para AWS');
                    }
                },
                async updateCoverVideo(event){
                    this.loaderImage = false;
                    const file = event.target.files[0];
                    
                    const validTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/webp'];
                    if (!validTypes.includes(file.type)) {
                        alert('Formato de arquivo inválido. Use PNG, JPG ou WEBP.');
                        return;
                    }

                    this.previewCoverVideo = URL.createObjectURL(file);

                    try {
                        const response = await apiAWS.getSignatureImage(this.token, file.name);
                        await this.putFileAws(response.url, file);
                        this.coverVideo = response.token;
                        this.loaderImage = true;
                    } catch (error) {
                        console.error('Erro na geração do TOKEN AWS');
                        alert('Erro ao fazer upload da imagem. Por favor, tente novamente.');
                    }
                },
                async uploadVideoAws(event) {
                    try {
                            const file = event.target.files[0];
                            
                            if (!file) {
                                return;
                            }

                            const fileExtension = file.type.split('/').pop();
                            const fileSize = Math.round(file.size / (1024 * 1024));

                            if (fileSize > 4096) { 
                                alert('O arquivo é muito grande. O tamanho máximo permitido é 4GB.');
                                return;
                            }

                            const CHUNK_SIZE = 4 * 1024 * 1024 * 1024; 
                            const totalChunks = Math.ceil(file.size / CHUNK_SIZE);

                            const response = await apiAWS.getSignatureVideo(this.token, totalChunks, fileExtension, CHUNK_SIZE);

                            if (!response) {
                                    throw new Error('URLs de upload inválidas');
                            }

                            this.tokenVideo = response;
                            this.videoData.path = response.url;

                            await this.putVideoAws(response.urls, file, totalChunks, CHUNK_SIZE);

                    } catch (error) {
                            console.error('Erro ao enviar arquivo para AWS', error);
                            alert('Erro ao fazer upload do vídeo. Por favor, tente novamente.');
                            this.isUploading = false;
                            this.progress = 0;
                    }
                },

                async putVideoAws(urlArray, file, totalChunks, chunkSize) {
                        this.isUploading = true;
                        this.isUploaded = false;
                        let successfulUploads = 0;
                        let totalProgress = 0;
                        let progressArray = new Array(totalChunks).fill(0);

                        for (let i = 0; i < totalChunks; i++) {
                                const start = i * chunkSize;
                                const end = Math.min(start + chunkSize, file.size);
                                const chunk = file.slice(start, end);

                                try {
                                        await apiAWS.putVideoAWS(urlArray[i].url, chunk, (progress) => {
                                                progressArray[i] = progress;
                                                totalProgress = progressArray.reduce((a, b) => a + b, 0) / totalChunks;
                                                this.progress = totalProgress;
                                        });

                                        successfulUploads++;
                                        if (successfulUploads === totalChunks) {
                                                this.isUploaded = true;
                                                this.isUploading = false;
                                                this.progress = 100;
                                        }
                                } catch (error) {
                                        console.error(`Erro no upload da parte ${i + 1}:`, error);
                                        this.isUploading = false;
                                        this.progress = 0;
                                        alert('Erro durante o upload do vídeo. Por favor, tente novamente.');
                                        throw error;
                                }
                        }
                },
        }
}
</script>

<style scoped>
.container {
        max-width: 100%;
        background-color: #ffffff;
        padding: 1.5rem;
        border-radius: 12px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
        position: relative;
}

.content-container {
        display: flex;
        gap: 30px;
}

.form-container {
        padding: 0px 20px;
        flex: 1;
}

.preview-container {
        flex: 1;
        padding: 20px;
}

.video-preview {
        width: 100%;
        aspect-ratio: 16/9;
        margin-top: 20px;
}

.video-preview iframe {
        width: 100%;
        height: 100%;
        border-radius: 8px;
}

.video-placeholder {
        width: 100%;
        aspect-ratio: 16/9;
        background-color: #f8f9fa;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        border: 2px dashed #e2e8f0;
        margin-top: 20px;
}

.form-group {
        margin-bottom: 20px;
}

.form-row {
        display: flex;
        flex-direction: column;
        gap: 20px;
}

.video-source-options {
        display: flex;
        gap: 20px;
}

.radio-option {
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;
}

.form-control {
        width: 100%;
        padding: 0.75rem;
        border: 1px solid #e2e8f0;
        border-radius: 6px;
        font-size: 0.875rem;
        transition: all 0.2s ease;
}

.form-control:focus {
        outline: none;
        border-color: #2f2e8b;
        box-shadow: 0 0 0 3px rgba(47, 46, 139, 0.1);
}

textarea.form-control {
        resize: vertical;
        min-height: 120px;
}

.button-container {
        display: flex;
        justify-content: flex-end;
        gap: 15px;
        margin-top: 30px;
        padding: 0px 20px;
}

.btn-weflix {
        padding: 0.5rem 1rem;
        border: 1px solid #e2e8f0;
        border-radius: 6px;
        background: white;
        color: #4a5568;
        cursor: pointer;
        transition: all 0.2s;
}

.btn-weflix.primary {
        background: #2f2e8b;
        color: white;
        border: none;
}

.btn-weflix:hover {
        background: #f8fafc;
        border-color: #cbd5e0;
}

.btn-weflix.primary:hover {
        background: #23225f;
}

p.form-label-title {
        margin-bottom: 10px;
        font-weight: 600;
}

.help-button {
        position: absolute;
        bottom: 20px;
        left: 20px;
        padding: 10px 20px;
        background-color: #d8d8d8;
        color: #6f6f6f;
        border: none;
        border-radius: 20px;
        cursor: pointer;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        transition: all 0.3s ease;
}

.help-button:hover {
        background-color: #f6f6f6;
        transform: translateY(-2px);
}

@media (max-width: 768px) {
        .container {
                padding: 1rem;
        }
        
        .content-container {
                flex-direction: column;
        }
}
button.help-button svg.svg-inline--fa.fa-circle-question {
        color: white;
}
/* DARK */
.dashboard.dark .content.dashboard > .container.doubts-container.adminWeeducCreateVideo {
        background-color: #000 !important;
}
.dashboard.dark .container.doubts-container.adminWeeducCreateVideo h3, 
.dashboard.dark .container.doubts-container.adminWeeducCreateVideo p,
.dashboard.dark .container.doubts-container.adminWeeducCreateVideo label {
        color: white !important;
}
.dashboard.dark .container.doubts-container.adminWeeducCreateVideo .form-group .form-input .form-control,
.dashboard.dark .container.doubts-container.adminWeeducCreateVideo .video-placeholder {
        background-color: #575757 !important;
        border: 1px solid #444 !important; 
}
.dashboard.dark .container.doubts-container.adminWeeducCreateVideo .help-button {
        background-color: #575757 !important;
}
.dashboard.dark .container.doubts-container.adminWeeducCreateVideo .help-button span {
        color: white !important;
}
</style>
<style scoped>
.progress-bar {
    width: 100%;
    height: 10px;
    background-color: #e7e7e7;
    border-radius: 10px;
    overflow: hidden;
    margin: 10px 0;
}

.progress-fill {
    height: 100%;
    background-color: #150065;
    transition: width 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.progress-text {
    color: white;
    font-size: 10px;
}
.upload-complete svg {
    color: #150065!important;
}
.reuseVideo {
    position: absolute;
    top: 25px;
    right: 20px;
    background-color: #eef0fc;
    padding: 5px 10px;
    color: #250891;
    border-radius: 5px;
    cursor: pointer;

}
.reuseVideo svg.svg-inline--fa {
    color: #250891!important;
}
</style>