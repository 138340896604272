<template>
    <div class="dashboard" :class="[this.dark == true ? 'dark': '' ]">       
        <Sidebar />
        <div class="page">
            <Toolbar />
            <div class="content dashboard">
                <RouterView></RouterView>
            </div>
        </div>
        <!-- <Footer></Footer> -->
    </div>
</template>

<script>
''
import Sidebar from '../../../components/Sidebar/sidebar'
import Toolbar from '../../../components/Toolbar/toolbar'
import Footer from '../../../components/Admin/Footer/footer.vue'
import { mapState } from 'vuex';

export default {
    data() {
        return {
            breadcrumbItems: [
                { label: 'Portal', link:'#' },
                { label: 'Dashboard', link:'#' ,active: true }
            ],
            TypeId:0,
            tipo:''
        }
    },
    components: {
        Sidebar,
        Toolbar,
        Footer
    },
    methods: {
       
    },
    mounted() {
        if(!localStorage.getItem('activeIndex')){
            localStorage.setItem('activeIndex', 0);
        }
        if(this.typeId==0){
            this.tipo = "Master";
        }else  if(this.typeId==1){
            this.tipo = "Professgit or";
        }else  if(this.typeId==2){
            this.tipo = "Aluno";
        }
        this.TypeId = this.typeId
    },
    computed: {
    ...mapState(['typeId','dark']),
  },

}
</script>

<style>
.page {
    width: 85%;
    margin-left: 15%;
    overflow: hidden;
}
@media (max-width: 500px) {
    .page {
        width: 85%;
        margin-left: 14%;
        overflow-x: hidden;
    }
}
.content.dashboard {
    height: calc(100vh - 60px);
    padding: 15px;
    position: relative;
    padding: 10px 10px;
    height: 100%;
}

@media (min-width: 1025px) {
    .page {
        width: 85%;
        margin-left: 15%;
    }
    .content.dashboard {
        padding: 15px;
        position: relative;
        margin-bottom: 1.4%;
        padding: 10px 10px;
        height: 100%;
    }
}

/**DARK MODE**/
.dashboard.dark .content.dashboard > .container {
    box-shadow: none !important;
}
.dashboard.dark {
    background-color: black;
}
.dashboard.dark .sidebar.primary {
    background-color: black;
    border-right: 1px solid #232323;
}

.dashboard.dark .sidebar.primary .sidebar-content .content-link .link .link-content{
    color: #ffffff;
}
.dashboard.dark .navbar.primary {
    background-color: #000;
    border-bottom: 1px solid #000000;
}

.dashboard.dark ul.dropdown-menu.show {
    background-color: black;
    box-shadow: 0px 0px 10px 1px #000000;
}

.dashboard.dark  li.dropdown-item{
    color: white;
}
.dashboard.dark .content.dashboard {
    height: 100%;
    background-color: #000000;
    padding: 10px 10px;
    position: relative;
}
.dashboard.dark .global_panel {
    background-color: #000000;
}

.dashboard.dark .global-card .title {
    color: white!important;
}
.dashboard.dark .global-card .content{
    color: white!important;
}
.dashboard.dark .Courses.page-content {
    background-color: black;
}
.dashboard.dark .page-navigation.primary {
    background-color: #444!important;
}
.dashboard.dark section.section.page-body.primary {
    background-color: #444444;
}
.dashboard.dark .wizard .table-list.primary .table-list-content .content-table>div {
    background-color: #444;
}
.dashboard.dark th  {
    background-color: black !important;
    color: white !important;
}

.dashboard.dark td  {
    background-color: black !important;
    color: white !important;
}
.dashboard.dark .wizard .table-list.primary .table-list-content .content-table table thead tr th>span,.dashboard.dark td span {
    color: #ffffff!important;
}
.dashboard.dark .page-navigation.primary .page-navigation-content .content-wrapper.primary .content-form .form-group {
    background-color: gray;
    border: 1px solid #ddd;
}

.dashboard.dark  input.form-control::placeholder, .dashboard.dark  input.form-control {
    color: white !important;
}
.dashboard.dark span.pi{
    color: white!important;
}
.dashboard.dark select.select_status{
    color: white!important;
    background-color: gray;
}
.dashboard.dark span.cont-page{
    color: white;
}
.dashboard.dark i.pi.pi-pencil {
    color: white;
}
.dashboard.dark section.section.config-form.primary.active {
    background-color: #444444;
}
.dashboard.dark span.titelAccordion {
    color: white;
}
.dashboard.dark  .link-list-content .content-link .link .link-content {
    color: rgb(255 255 255);
    background-color: rgb(68 68 68);
    border-bottom: 1px solid rgb(68 68 68);
    border-right: 1px solid rgb(68 68 68);
}
.dashboard.dark .form-group .form-label label {
    color: #ffffff;
}
.dashboard.dark label.label-form {
    color: white;
}
.dashboard.dark .ql-editor {
    background-color:#444444;
    color: white;
}
.dashboard.dark .form-textarea {
    background-color: #444444;
    color: white;
}
.dashboard.dark .ql-snow .ql-stroke {
    stroke: #ffffff;
}
.dashboard.dark .ql-snow .ql-picker {
    color: #ffffff;
    margin: 10px 0px;
}
.dashboard.dark select.select-mode {
    color: white;
    background-color: gray;
}
.dashboard.dark tr.title-table {
    background-color: black;
    color: white;
}
.dashboard.dark tr.title-table th:nth-child(1) {
    background-color: black !important;
}
.dashboard.dark .link-list-content .content-link .link a.link-content.active {
    color: rgb(255 255 255);
    background-color: rgb(164 164 164);
    border-bottom: 1px solid rgb(0 0 0);
    border-right: 1px solid rgb(0 0 0);
}
.dashboard.dark .modal-content {
    background-color: black;
}
.dashboard.dark .modal-title {
    color: white;
}
.dashboard.dark .modal-header, .dashboard.dark .modal-footer {
    border: none;
}
.dashboard.dark select.form-select {
    background-color: #444444;
    color: white;
}
.dashboard.dark textarea {
    background-color: #444444;
    color: white;
}
.dashboard.dark .link a {
    background-color: #444444;
    border-color: #444444;
    color: white;
}
.dashboard.dark .config-form.primary {
    background-color: rgb(68 68 68);
}
.dashboard.dark .form-group .form-input textarea:focus {
    color: #ffffff;
    background-color: #444;
    border-color: #ffffff;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.dashboard.dark textarea::placeholder {
    color: white !important;
}
.dashboard.dark .multiselect__tags {
    background-color: #444444;
}
.dashboard.dark .title h2 {
    color: white !important;
}
.dashboard.dark .description span{
    color: white !important;
}
.dashboard.dark .content-link .link a {
    background-color: #000000;
    border-color: #444444;
    color: white;
}
.dashboard.dark  section.config-form.primary.view-questions {
    margin: 10px 0px;
    border-radius: 0px;
}
.dashboard.dark section.config-form.primary.view-questions .title.quiz {
    color: white;
}
.dashboard.dark section.config-form.primary.view-questions i {
    color: white;
}
.dashboard.dark section.config-form.primary.view-questions input.correct.form-control {
    color: black !important;
}
.dashboard.dark input.form-contro.question:disabled {
    background-color: black;
    width: 100%;
    border-radius: 10px;
    color: white !important;
}
.dashboard.dark .description_review{
    color: white !important;
}
/* @media (min-width: 1025px) {
    .dashboard.dark .content.dashboard {
        height: calc(100vh - 125px);
        background-color: #000000;
        padding: 15px;
        position: relative;
        margin-bottom: 1.4%;
    }
} */
/* @media (min-width: 1025px) {
    .dashboard.dark .content.dashboard {
        height: 100vh;
    }
} */
</style>