<template>
    <div class="container weflix-admin-collection">
        <div class="titleContainer">
            <h3>{{currentCollections.comercialName}}</h3>
            <p v-html="currentCollections.shortDescription"></p>
            
            <div class="status-indicator">
                <span class="status-badge" :class="currentCollections.status == 1 ? 'active' : 'inactive'">
                    {{ currentCollections.status == 1 ? 'Ativo' : 'Inativo' }}
                </span>
            </div>

            <button class="editCollections" @click="(()=>this.$router.push(`/admin/editar-colecao/${this.$route.params.slug}`))"><fa icon="fa-pencil"/></button>
        </div>

        <div class="infoCollectionVideos">
            <div class="videosInfoCollection">
                <p><fa icon="fa-film"/><span v-if="!isMobile"> Total de Videos: </span><strong>{{totalVideos}}</strong></p>
                <p><fa icon="fa-clock"/><span v-if="!isMobile"> Total de horas: </span><strong>{{totalMinutes}}</strong></p>
                <p><fa icon="fa-eye"/><span v-if="!isMobile"> Total de Visualizações: </span><strong>{{totalViews}}</strong></p>
            </div>
            <div class="col-form-inner col-6 col-md-6 col-lg-6 flex end" id="changeSeasonAddVideo">
                <div class="form-group flex" id="addNewVideo">
                    <button class="btnWeflix" @click="(()=>this.$router.push(`/admin/novo-video`))">
                        <fa :icon="isMobile ? 'fa-plus' : 'fa-video'"/>
                        <span v-if="!isMobile">Novo Video</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="orderVideos">
            <small>Segure o ícone pontilado para arrastar os videos e alterar a ordem.</small>
        </div>

        <draggable 
            class="CollectionVideos"
            v-model="videos"
            v-show="hasReady"
            @end="onDragEnd"
            :animation="200"
            item-key="id"
            handle=".drag-handle"
            :group="{ name: 'videos' }"
            :options="{
                animation: 200,
                ghostClass: 'ghost',
                dragClass: 'drag'
            }"
        >
            <template #item="{element}">
                <div class="itemCollectionVideo" @click="editVideo(element.id)">
                    <div class="itemCollectionVideo-content">
                        <div class="drag-handle">
                            <fa icon="fa-grip-vertical"/>
                        </div>
                       
                        <p>                        
                            {{ element.title }} - {{element.videoTime? converterTempo(element.videoTime) : '00:00'}}
                        </p>
                        <div class="video-options">
                            <button class="options-btn" @click.stop="showOptions(element)">
                                <fa icon="fa-ellipsis-vertical"/>
                            </button>
                            <div v-if="element.showOptionsMenu" class="options-menu">
                                <div class="option-item" @click.stop="openMoveDialog(element)">
                                    Mover
                                </div>
                            </div>
                        </div> 
                    </div>
                    
                    <div class="itemCollectionVideo-actions">
                        <img :src="element.finalVideoThumbnail" alt="">
                        <div class="video-overlay">
                            <fa icon="fa-play"/>
                        </div>
                    </div>
                    <span class="video-status" :class="getStatusClass(element.status)">
                        {{ getStatusText(element.status) }}
                    </span>
                </div>
            </template>
        </draggable>

        <div class="noVideos" v-if="videos?.length === 0 && hasReady">
            <p>Envie novos videos para esta coleção clicando aqui <fa icon="fa-plus" @click="(()=>this.$router.push(`/admin/novo-video`))"/></p>
        </div>

        <v-dialog v-model="showMoveDialog" max-width="600">
            <v-card>
                <v-card-title>Mover Vídeo</v-card-title>
                <button class="close-btn" @click="showMoveDialog = false">
                    <fa icon="fa-times"/>
                </button>
                <v-card-text>
                    <div class="video-info">
                        <div class="video-thumbnail">
                            <img :src="selectedVideo?.finalVideoThumbnail" :alt="selectedVideo?.title">
                        </div>
                        <div class="video-details">
                            <h3>{{ selectedVideo?.title }}</h3>
                            <p class="duration">Duração: {{ selectedVideo?.videoTime ? converterTempo(selectedVideo.videoTime) : '00:00' }}</p>
                        </div>
                    </div>

                    <div class="move-controls">
                        <p>Para qual coleção deseja mover este vídeo?</p>
                        <select v-model="selectedCollection" class="form-select">
                            <option value="">Selecione uma coleção</option>
                            <option v-for="collection in collections" 
                                    :key="collection.id"
                                    :value="collection.id">
                                {{ collection.name }} - {{ collection.id }}
                            </option>
                        </select>
                    </div>
                </v-card-text>
                <v-card-actions class="flex justify-end">
                    <v-spacer></v-spacer>
                  
                    <v-btn color="primary" text @click="moveVideo">Mover</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <div class="pa-4 text-center">
            <v-dialog
                v-model="deleteItem"
                max-width="320"
                persistent
            >
                <v-card>
                    <v-card-title class="headline">Atenção</v-card-title>
        
                    <v-card-text>
                        Deseja realmente excluir o vídeo {{ nameItem }}?
                    </v-card-text>
        
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="cancel" text @click="closeDialog">Não</v-btn>
                        <v-btn class="accept" text @click="closeDialog">Sim</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
        <Loader :hasReady="hasReady"/>
        <NewSeason :showModal="showModal" @closeModalEmit="closeModal"/>
    </div>
</template>

<script>
import Sesson from '@/utils/Weflix/sesson';
import Videos from '@/utils/Weflix/videos';
import Loader from '@/components/Weflix/Loader.vue'
import draggable from 'vuedraggable';

import { mapState } from 'vuex';

export default {
    data(){
        return{
            seasson: [],
            valueProgressTemporary:0, 
            deleteItem: false,
            nameItem: '',
            season:'',
            seasonSelected: null,
            videos: [],
            hasReady: false,
            descriptionCollection: '',
            nameCollection: '',
            totalVideos: 0,
            totalMinutes: 0,
            totalViews: 0,
            dataViewVideos: [],
            manySeason: false,
            drag: false,
            isMobile: false,
            showMoveDialog: false,
            selectedVideo: null,
            selectedCollection: null,
            collections: [],
        }
    },
    components: {
        Loader,
        draggable
    },
    computed: {
        ...mapState(['token', 'currentCollections']),
    },
    methods: {
        showOptions(element) {
            this.videos = this.videos.map(video => ({
                ...video,
                showOptionsMenu: video.id === element.id ? !video.showOptionsMenu : false
            }));
        },
        getStatusClass(status) {
            const statusClasses = {
                1: 'status-active',
                0: 'status-inactive', 
                3: 'status-deleted',
                4: 'status-error',
                5: 'status-processing'
            }
            return statusClasses[status] || '';
        },
        getStatusText(status) {
            const statusTexts = {
                1: 'Ativo',
                0: 'Inativo',
                3: 'Deletado',
                4: 'Erro no Upload',
                5: 'Processando'
            }
            return statusTexts[status] || 'Status Desconhecido';
        },
        deleteVideo(id, name){
            this.deleteItem = true;
            this.nameItem = name;
        },
        closeDialog(){
            this.deleteItem = false;
        },
        openMoveDialog(element) {
            this.selectedVideo = element;
            this.showMoveDialog = true;
        },
        editVideo(id){
            if (!this.drag) {
                this.$router.push('/admin/video/' + id)
            }
        },
        async getSesson(){
            const param = this.$route.params.slug;
            try {
                const response = await Sesson.getSessonByCollection(this.token, param);
                
                if(response.length > 0) {
                    this.season = response;
                    this.seasonSelected = this.season[0].id;
                    this.manySeason = this.season.length > 1;
                    this.getVideos(this.seasonSelected);
                } else {
                    await this.newSeason();
                    const newResponse = await Sesson.getSessonByCollection(this.token, param);
                    this.season = newResponse;
                    this.seasonSelected = this.season[0].id;
                    this.manySeason = this.season.length > 1;
                    this.getVideos(this.seasonSelected);
                }
            } catch (error) {
                console.error('Erro ao obter dados da coleção:', error);
            }
        },
        async getVideos(id){
            try {
                const response = await Videos.getVideosBySesson(this.token, id);
                // response.list = response.list.filter(video => video.status !== 3);
                this.videos = response.list;
                this.totalVideos = response.totalVideos;
                this.totalMinutes = this.converterTempo(response.totalVideoTime);
                this.totalViews = response.totalProgress;
                this.dataViewVideos = response.progressDataMap;
                this.hasReady = true;
            } catch (error) {
                console.error('Erro ao obter dados dos Videos:', error);
            }
        },
        async newSeason(){
            const payload = {
                "idCourse": this.currentCollections.id,
                "title": "Temporada 1",
                "sample": 0
            }
            try {
                const response = await Sesson.createSesson(this.token, payload);
                this.noSeason = false;
                this.getSesson();
            } catch (error) {
                console.error('Erro ao criar temporada:', error);
            }
        },
        converterTempo(segundos) {
            const horas = Math.floor(segundos / 3600)
            const minutos = Math.floor((segundos % 3600) / 60);

            const horasFormatadas = horas.toLocaleString('pt-BR');

            return horas > 0 ? `${horasFormatadas}h ${minutos}m` : `${minutos}m`;
        },
        changeSeason(){
            this.hasReady = false;
            this.getVideos(this.seasonSelected);
        },
        async onDragEnd(evt) {
            this.drag = false;
            const newOrder = this.videos.map((video, index) => ({
                id: video.id,
                order: index + 1
            }));
           try {
            await Videos.orderVideos(this.token, newOrder);
        } catch (error) {
            console.error('Erro ao atualizar ordem dos vídeos:', error);
        }
        },
        async moveVideo() {
           
           if (this.selectedCollection && this.selectedVideo) {
               try {
                   const response = await Videos.moveVideo(this.token, this.selectedVideo.id, this.selectedCollection);
                   alert('Vídeo movido com sucesso');
                   this.showMoveDialog = false;
                   this.getVideos(this.seasonSelected);
               } catch (error) {
                   console.error('Erro ao mover vídeo:', error);
               }
           }
       },
       async getCollections(){
            try {
                const response = await Videos.listCollections(this.token);
                console.log(response);
                this.collections = response.map(item => ({
                    id: item.id,
                    name: item.name,
                    status: item.status
                }));
            } catch (error) {
                console.error('Erro ao obter coleções:', error);
            }
        },
    },
    created(){
        this.isMobile = window.innerWidth <= 600;
        this.getSesson();
        this.getCollections();
    }
}
</script>

<style scoped>
@import url(./global.css);
div#addNewVideo button.btnWeflix {
    display: flex;
    align-items: center;
}
div#addNewVideo button.btnWeflix svg {
    color: #250891;
}
div#addNewVideo button.btnWeflix:hover svg {
    color: white;
}
.btnWeflix{
    right: 35px;
}

.status-indicator {
    position: absolute;
    top: 0px;
    right: 70px;
}

.status-badge {
    padding: 4px 12px;
    border-radius: 12px;
    font-size: 12px;
    font-weight: 500;
}

.status-badge.active {
    background-color: #e6f4ea;
    color: #1e7e34;
}

.status-badge.inactive {
    background-color: #feeced;
    color: #dc3545;
}

.video-status {
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 11px;
    margin-left: auto;
    font-weight: 500;
    position: absolute;
    bottom: 15px;
    right: 15px;
}

.status-active {
    background-color: #e6f4ea;
    color: #1e7e34;
}

.status-inactive {
    background-color: #feeced;
    color: #dc3545;
}

.status-deleted {
    background-color: #f8f9fa;
    color: #6c757d;
}

.status-error {
    background-color: #fff3cd;
    color: #856404;
    width: auto;
}

.status-processing {
    background-color: #cce5ff;
    color: #004085;
}

.drag-handle {
    cursor: move;
    margin-right: 8px;
    color: #ccc;
}

.drag-handle:hover {
    color: #250891;
}

.CollectionVideos {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
    gap: 20px;
    padding: 20px;
}

.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}

.drag {
    opacity: 0.8;
}

@media (max-width: 768px) {
    .CollectionVideos {
        grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    }
}
.itemCollectionVideo {
    height: auto;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
    overflow: hidden;
    transition: transform 0.2s ease;
    cursor: pointer;
    width: 100%;
    max-width: 100%;
}

.itemCollectionVideo:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 12px rgba(0,0,0,0.15);
}

.itemCollectionVideo-content {
    padding: 12px;
    display: flex;
    align-items: center;
}
.itemCollectionVideo p {
    color: #000000;
    margin: 0;
    font-size: 12px;
}
.itemCollectionVideo-actions {
    position: relative;
    height: 160px;
}

.itemCollectionVideo-actions img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.2s ease;
}

.video-overlay .fa-play {
    font-size: 40px;
    color: white;
}

.itemCollectionVideo:hover .video-overlay {
    opacity: 1;
}

.videosInfoCollection {
    display: flex;
}
.infoCollectionVideos p {
    margin: 0;
}
.infoCollectionVideos {
    text-align: left;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}
div#changeSeasonAddVideo {
    width: 15%;
    justify-content: end;
}
.titleContainer {
    position: relative;
}
.titleContainer p {
    font-size: 13px;
}
.orderVideos {
    text-align: left;
    padding: 0 25px;
}
@media (min-width: 600px) {
    #addNewVideo .fa-video {
        display: none;
    }
}
@media (max-width: 600px) {
    div#addNewVideo button.btnWeflix {
        padding: 2px;
        width: 35px;
        height: 30px;
    }
    .weflix-admin-collection .status-indicator {
        position: absolute;
        top: 20px;
        right: 75px;
    }
    .weflix-admin-collection .itemCollectionVideo {
        padding: 0 !important;
        box-shadow: none !important;
    }
    .weflix-admin-collection .itemCollectionVideo-content {
        padding: 10px 0px 10px 0px;
    }
}
button.editCollections {
    position: absolute;
    top: 0px;
    right: 25px;
}
.video-options {
    position: absolute;
    right: 10px;
    top: 20px;
    text-align: end;
}
.options-menu {
    width: auto;
    background-color: #f0f0f0;
    padding: 5px 20px;
    position: relative;
    z-index: 10;
    margin-top: 5px;
    transform: translateX(-14px);
    transition: all 0.3s ease;
}
.options-menu:hover {
    background-color: #e0e0e0;
}
button.close-btn {
    position: absolute;
    top: 25px;
    right: 10px;
}
button.close-btn svg.svg-inline--fa.fa-xmark {
    color: #20237a;
}
</style>
<style>
button.close-btn > .v-card-actions {
    padding: 0px 25px !important;
}
</style>