<template>
    <div class="collections siteWeflix">
        <div class="itensCollections" v-for="collection in collections.slice(0, 6)" :key="collection.id">
            <div class="titleCollections">
               <center> <h3>{{collection.comercialName}}</h3></center>
               <p>{{ collection.lessonQty || 0 }} vídeos | {{ formatarTempo(collection.lessonTime || 0) }}</p>             
            </div>
            <div class="videoCollections">
                <div class="itensVideoCollections">
                    <swiper
                        :modules="modules"
                        :slides-per-view="mobile ? 2 : 5"
                        :space-between="20"
                        navigation
                        @swiper="onSwiper"
                        @slideChange="onSlideChange"
                    >
                        <swiper-slide v-for="slide in collection.lessons" :key="slide.id">
                            
                            <div class="carousel__item" @click="toggleModalSubscribe(slide.id, slide.sample, collection.owned, collection.price, collection)">

                            <!-- <img src="@/assets/Weflix/img-long.png" alt="Imagem padrão"> -->
                            <img v-if="!slide.finalVideoThumbnail" src="@/assets/Weflix/default-video-thumbnail.jpg" alt="Imagem padrão">
                            <img v-else :src="slide.finalVideoThumbnail" :alt="slide.title"/>


                                <div class="lockIcon" v-if="userPortalWeflix && slide.sample !== 1 && userPortalWeflix.user.orderStatus != 1">
                                    <fa v-if="userPortalWeflix.status !== 1" icon="lock" class="lockIcon"/>
                                    <span v-if="userPortalWeflix.user.status !== 1" class="textPrivateVideo">Conteúdo Exclusivo</span>
                                </div>

                                <div class="lockBlock" v-if="!userPortalWeflix && slide.sample !== 1">
                                    <fa icon="lock" class="lockIcon" />
                                    <span class="textPrivateVideo">Conteúdo Exclusivo</span>
                                </div>

                                <div class="sampleFlag" v-if="slide.sample === 1">
                                    <span>DEGUSTAÇÃO</span>
                                </div>

                                <div class="degradeCarousel__item"></div>
                                <div class="descriptionVideo" v-if="finalWebsite.visibleTitleCourse == 1">
                                    <h4>{{slide.title}}</h4>
                                    <div class="videoInfo">
                                        <small>{{formatarTempo(slide.videoTime)}}</small>
                                    </div>
                                </div>
                            </div>
                        </swiper-slide>
                    </swiper>
                </div>
            </div>
             
               <div class="cta-single-collection-light" v-if="collection.price && collection.owned !== 4">
                    <span class="price-collection">{{collection.installment}}x de <b>R$ {{ (collection.price && collection.price > 0 ? collection.price/collection.installment : 25).toFixed(2) }} </b> sem juros</span>
                    <a v-if="finalWebsite.company.cartStatus && finalWebsite.company.cartStatus === 1" class="button-single-collection button-cta main-background-color" :style="{ backgroundColor: finalWebsite?.color }" href="#!" @click="toggleModalSubscribe(null, null, collection.owned, collection.price, collection)">
                        <span>COMPRE AGORA </span>
                    </a>
                </div>

                  <div v-else class="collection-buttons">
                   <button class="btn-collection" @click="$router.push('/videos-colecao/'+collection.id)">Ver coleção completa</button>
                   <button class="btn-collection btn-primary" v-if="userPortalWeflix && userPortalWeflix.user.orderStatus != 1" :style="{ backgroundColor: finalWebsite?.color }" @click="toggleModalSubscribe(null, null, collection.owned, collection.price, collection)">Assine agora</button>
               </div>
        </div>
        <div class="seeMoreCollections">
            <a href="/colecoes" :style="{ backgroundColor: finalWebsite?.color }">Ver mais</a>
        </div>

         <!-- Modal de Aviso -->
         <div v-if="showPopup" class="purchase-modal">
            <div class="modal-content">
                <h3>Conteúdo Exclusivo</h3>
                <p>Para acessar este conteúdo, você precisa adquirir esta coleção.</p>
                <small v-if="!userPortalWeflix">Realize o login ou cadastre-se para finalizar a compra.</small>
                <div class="modal-buttons">
                    <button @click="(()=>this.showPopup = false)" class="cancel-button">Fechar</button>
                    <button @click="redirectToCheckout" class="buy-button">Comprar Agora</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ApiWebsite from '@/utils/Weflix/Website/index';
import ApiCollections from '@/utils/Weflix/collections';
import { mapState, mapActions } from 'vuex';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default {
    data(){
        return{
            collections: [],
            titleCollection:['Primeire Coleção', 'Segunda Coleção', 'Terceira Coleção'],
            mobile:false,
            baseUrl: process.env.NODE_ENV === 'development' ? process.env.VUE_APP_BASE_URL_HOMOLOGATION  : window.location.origin,
            breakpoints: {  
                1024: {
                    itemsToShow: 3.6,
                    snapAlign: 'start',
                },
            },
            showPopup: false,
            selectedCollection: null,
            modules: [Navigation, Pagination, Scrollbar, A11y]
        }
    },
    computed:{
        ...mapState(['userPortalWeflix','finalWebsite']),
    },
    methods:{
        ...mapActions(['storeCollectionCheckout']),
        onSwiper(swiper) {
        },
        onSlideChange() {
        },
        toggleModalSubscribe(id = null, sample = null, owned = null, price = null, collection = null){
            if(this.finalWebsite.company.cartStatus === 0 && price > 0){
                alert('A compra de coleções está desabilitada no momento, tente novamente mais tarde');
                return;
            }

            if(this.userPortalWeflix){
                if(price > 0){
                    if(owned === 4){
                        this.$router.push('/player/'+collection.id);
                    } else {
                        this.setCollectionCheckout(collection);
                        this.showPopup = true;
                    }
                } else {   
                    if(this.userPortalWeflix.user.orderStatus === 1 || sample === 1){ 
                        this.$router.push('/player/'+id)
                    } else {
                        this.$emit('toggleOpenSubscribe');
                    }
                }
            } else {
                if(price > 0){
                    this.setCollectionCheckout(collection);
                        this.showPopup = true;
                    } else {
                        this.$emit('toggleOpenDialog');
                    }
            }
        },
        setCollectionCheckout(collection) {
            console.debug('Chamou o setCollectionCheckout');
            if(this.userPortalWeflix){
                this.showPopup = true;
                this.$store.commit('SET_COLLECTION_CHECKOUT', {
                id: collection.id,
                name: collection.comercialName,
                url: collection.url,
                price: collection.price,
                imageFinalPath: collection.imageFinalPath,
                totalVideos: collection.totalVideos,
                collection: collection
            });
            } else {
                this.showPopup = true;
            }
           
        },
        async getCollections(){
            const aux = this.baseUrl.replace(/\/$/, '');
          
            const payload = {
                "url": aux
            }
            try {
                const response = await ApiWebsite.getCollectionsLimit(payload, 0);
                this.collections = response.content.sort((a, b) => a.order - b.order);
                console.debug(response);
            } catch (error) {
                console.error(error);
            }
        },
        async getCollectionByLogin(){
            try {
                const response = await ApiCollections.getCollectionByLogin(this.userPortalWeflix.acessToken);
                this.collections = response.content.sort((a, b) => {
                    if (a.owned !== b.owned) {
                        return b.owned - a.owned;
                    }
                    return a.order - b.order;
                });
                console.debug(this.collections);
            } catch (error) {
                console.error(error);
            }
        },

        itemsToShow(itemCount) {
            if (this.mobile) return 1;
            return itemCount === 1 ? 1 : Math.min(itemCount, 3.6);
        },
        shouldWrapAround(itemCount) {
            return !this.mobile && itemCount > 1;
        },
        snapAlign(itemCount) {
            if (this.mobile || itemCount === 1) return 'center';
            return 'start';
        },
        handleResize() {
            this.mobile = window.innerWidth <= 768;
        },
        formatarTempo(tempo) {
            const horas = Math.floor(tempo / 3600);
            const minutos = Math.floor((tempo % 3600) / 60);
            return `${horas > 0 ? horas + 'h ' : ''} ${minutos > 0 ? minutos + 'm' : ''}`;
        },
       
        closePopup() {
            this.showPopup = false;
        },
        confirmPurchase() {
            if(this.userPortalWeflix){
                this.$router.push('/checkoutweflix');
            } else {
                this.$emit('toggleOpenDialog');

                this.closePopup();
            }
        },
        redirectToCheckout(){
            if(this.userPortalWeflix){
                this.$router.push('/checkout-collection-weflix');
            } else {
                this.$emit('toggleOpenDialog');
                this.closePopup();
            }
        }
    },
    created(){
        if(this.userPortalWeflix){
            this.getCollectionByLogin();
        }else{
            this.getCollections();
        }
        this.mobile = window.innerWidth <= 768;
        window.addEventListener('resize', this.handleResize);
    },
    unmounted() {
        window.removeEventListener('resize', this.handleResize);
    },
    components: {
        Swiper,
        SwiperSlide
    },
    emits: ['toggleOpenSubscribe', 'toggleOpenDialog']
}
</script>
<style>
.collection-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}

.btn-collection {
    padding: 12px 24px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    border: none;
}

.btn-collection:first-child {
    background-color: transparent;
    border: 2px solid #ffffff;
    color: #ffffff;
}

.btn-collection.btn-primary {
    color: #ffffff;
}

.btn-collection:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

@media (max-width: 768px) {
    .collection-buttons {
        flex-direction: row!important;
        align-items: center!important;
        gap: 10px!important;
    }

    .btn-collection {
        width: 100%;
        max-width: 300px;
    }
}
.titleCollections{
    display: block;
    text-align: center;
}
.cta-single-collection-light {
    text-align: center;
}
@media (max-width: 600px) {
    .cta-single-collection-light {
        text-align: center !important;
    }
}
</style>
