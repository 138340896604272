<template>
    <section class="section link-list primary admin-weflix-payment">
      <div class="link-list-content">
        <div class="content-link create-course">
          <div class="link">
            <a
              class="link-content"
              :class="[isActiveTab(1)? 'active': '']"
              @click="setActiveTab(1)"
            >
              Planos
            </a>
          </div>
          <div class="link">
            <a
              class="link-content"
              :class="[isActiveTab(2)? 'active': '']"
              @click="setActiveTab(2)"
            >
              Cupons de Desconto
            </a>
          </div>
        </div>
      </div>
    </section>

     <section
      class="section config-form primary"
      :class="[isActiveTab(1)? 'active': '']"
    >
      <Plan />
    </section>
    <section
    class="section config-form primary"
    :class="[isActiveTab(2)? 'active': '']"
  >
    <Cupom />
  </section>
  
    <!--<section
    class="section config-form primary"
    :class="[isActiveTab(2)? 'active': '']"
  >
    <Layout />
  </section>

    <section
    class="section config-form primary"
    :class="[isActiveTab(3)? 'active': '']"
  >
    <Content />
  </section>

     <section
      class="section config-form primary"
      :class="[isActiveTab(4)? 'active': '']"
    >
    <Domain/>
    </section>

    <section
    class="section config-form primary"
    :class="[isActiveTab(5)? 'active': '']"
  >
  <Social />

  </section>

  <section
  class="section config-form primary"
  :class="[isActiveTab(6)? 'active': '']"
>
<Testimonials  />

</section>


   <section
  class="section config-form primary"
  :class="[isActiveTab(7)? 'active': '']"
>
<Integration />

</section> -->






  </template>
  
  <script>
    import Plan from '@/components/Weflix/Payment/plan.vue';
    import Cupom from '@/components/Weflix/Payment/cupom.vue';
    
  export default {
    data() {
      return {
        activeTab: 1,
      }
    },
    props: {
  
    },
    components: {
      Plan,
      Cupom
    },
    methods: {
      setActiveTab(tabNumber) {
        this.activeTab = tabNumber;
      },
      isActiveTab(tabNumber) {
        return this.activeTab === tabNumber;
      },
    
    },
    created() {
  
    }
  }
  </script>
  
  <style scoped>
  @import url(./global.css);

  .link a {
    display: inline-block;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: rgb(24, 24, 28);
    background-color: rgb(255, 255, 255);
    text-decoration: none;
    padding: 0.5rem 2rem;
    border-bottom: 1px solid rgb(246, 246, 246);
    border-right: 1px solid rgb(246, 246, 246);
    cursor: pointer;
}
.link-list-content .content-link .link .link-content.active {
    color: rgb(255, 255, 255);
    background-color: #250891;
    border-right: 1px solid #250891;
}
section.section.config-form.primary.active {
    display: block;
    height: 83vh;
    overflow-y: auto;
}
section.section.config-form.primary {
  display: none;
}
.content-link.create-course {
  display: flex;
}
.content-link.create-course .link:first-child a {
  border-radius: 10px 0px 0px 0px;
}
@media (max-width: 600px) {
  .admin-weflix-payment .content-link.create-course {
    overflow: hidden;
    overflow-x: auto;
    height: 45px;
  }
  .admin-weflix-payment .content-link.create-course > .link {
      flex-grow: 1;
      width: fit-content !important;
  }
  .admin-weflix-payment .content-link.create-course .link a {
    width: -webkit-fill-available;
  }
  .admin-weflix-payment .content-link.create-course .link:last-child a {
    border-radius: 0px 10px 0px 0px;
  }
}section.section.config-form.primary{
  background-color: white;

}

  </style>
  