import api from './api';

export default {
    async getReportClient(accessToken, page) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/report/user?page=${page}&size=100`, {
                method: 'GET',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter relatório do usuários:', error);
            throw error;
        }
    },
    async getReportClientExport(accessToken) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/report/user?page=0&size=9999`, {
                method: 'GET',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter relatório do usuários:', error);
            throw error;
        }
    },
    async getReportClientBySearch(accessToken, page, payload) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.post(`/report/user/search?page=${page}&size=100`, payload, {
                method: 'Post',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter relatório do usuários:', error);
            throw error;
        }
    },
    async getReportFinance(accessToken, page) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`report/financial/export?page=${page}&size=100`, {
                method: 'GET',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter relatório do usuários:', error);
            throw error;
        }
    },
    async getReportFinanceExport(accessToken) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/report/financial/export?page=0&size=9999`, {
                method: 'GET',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter relatório do usuários:', error);
            throw error;
        }
    },
    async getReportFinanceBySearch(accessToken, page, payload) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.post(`/report/financial/search?page=${page}&size=100`, payload, {
                method: 'Post',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter relatório do usuários:', error);
            throw error;
        }
    }
}
