<template>
    <section class="header" :class="dark ? 'dark' : ''">
        <div class="content-wrapper header-wrapper">
            <div class="logo">
                <img :src="dataWebsite.logoFinal" alt="Logo">
            </div>
            <div class="login-button">
                <router-link to="/portal" class="button-text">
                    <a>Portal do Aluno</a>
                </router-link>
            </div>
        </div>
    </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
    data(){
        return{
            dataConfigSite:[]
        }
    },
    computed:{
        ...mapState(['dataWebsite','dark'])
    }
}
</script>

<style scoped>
.header {
    padding: 1em 5em;
    width: 100%;
    background-color: white;
    display: block;
}

.logo img {
    width: 200px;
}

/* Tema escuro */
.gloval_site.dark .header {
    background-color: black;
}

.gloval_site.dark .logo img {
    filter: brightness(0) invert(1);
}

section.header.dark {
    background-color: black;
}
/* Responsivo */
@media only screen and (max-width: 600px) {
    .header {
        padding: 1.5em 2em;
    }
    
    .logo img {
        width: 150px;
    }
}
.button-text a {
    display: inline-block;
    width: auto !important;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
    padding: 4px 20px;
    color: #fff!important;
    background-color: #ffa519!important;
    border-radius: 0.35em;
}
.content-wrapper.header-wrapper {
    max-width: 1500px;
    margin: 0 auto;
    display: flex
;
    justify-content: space-between;
}
</style>
