<template>
    <apexchart type="bar" :options="chartOptions" :series="series" height="300"></apexchart>
</template>

<script>
import VueApexCharts from 'vue3-apexcharts';

export default {
  components: {
    apexchart: VueApexCharts
  },
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          toolbar: {
            show: false
          },
          height: '200px',
          width: '100%',
          type: 'bar',
          zoom: {
            enabled: false
          }
        },
        colors: ['#1AC7D0'],
        dataLabels: {
          enabled: false
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '80%'
          }
        },
        xaxis: {
          categories: [],
          labels: {
            formatter: function(value, timestamp) {
              const date = new Date(timestamp);
              const months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
              return months[date.getMonth()] + ' ' + date.getFullYear();
            }
          }
        },
        yaxis: {
          opposite: false
        },
        legend: {
          horizontalAlign: 'left',
          show: true
        }
      }
    };
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  watch: {
    data: {
      immediate: true,
      handler(newData) {
        if (!newData || !Array.isArray(newData) || newData.length === 0) {
            console.warn('Data is empty or not properly formatted');
            this.series = [{ name: 'Sales', data: [] }];
            this.chartOptions.xaxis.categories = [];
            return;
        }

        // Mapeamento dos meses para números
        const monthsToNum = {
          january: '01', february: '02', march: '03', april: '04',
          may: '05', june: '06', july: '07', august: '08',
          september: '09', october: '10', november: '11', december: '12'
        };

        const currentYear = new Date().getFullYear();

        const convertedData = newData.map(({ month, value }) => {
            const monthNumber = monthsToNum[month.toLowerCase()];
            if (!monthNumber) {
                console.warn(`Invalid month found: ${month}`);
                return null;
            }

          const date = `${currentYear}-${monthNumber}`;
          return {
            x: date,
            y: value === null ? Math.floor(Math.random() * 1000) * 1000 : value
          };
        }).filter(item => item !== null);

        if (convertedData.length === 0) {
            console.warn('Nenhum dado válido após a conversão');
            this.series = [{ name: 'Students', data: [] }];
            this.chartOptions.xaxis.categories = [];
            return;
        }

        convertedData.sort((a, b) => new Date(a.x) - new Date(b.x));

        this.series = [{
            name: 'Students',
            data: newData.map(item => (item?.value ?? 0))
        }];

        this.chartOptions.xaxis.categories = newData.map(item => {
            const monthNumber = monthsToNum[item?.month?.toLowerCase()] || '01';
            return `${currentYear}-${monthNumber}`;
        });
      }
    }
  }
};
</script>

<style>
.vue-apexcharts svg {
    width: auto;
}
</style>

