import api from '../api';

export default {

    // getDataIntegrationsSection
    async getIntegrationData( accessToken) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/manager/integration`,  {
                method: 'GET',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao gerar informações de integração site:', error);
                throw error;
        }
    },
    async putIntegrationData( accessToken, payload ) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.put(`/manager/integration`, payload ,  {
                method: 'PUT',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao gerar editar de integração site:', error);
                throw error;
        }
    },
 }

