<template>
    <Loader :isActive="hasReady"></Loader>
    <div class="config-form-content">
      <div class="content-wrapper primary">
        <div class="wrapper-inner">
          <div class="content-form">
            <div class="row">
              <div :class="responsiveIntegrationsClassOne">
                <div class="row">

                  <div class="col-form-inner col-12 col-md-12 col-lg-12 select">
                    <div class="form-group">
                      <label class="label-form">Facebook Pixel: </label>
                      <div class="form-input">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="GTM-28CJ0"
                          v-model="facebook"
                        />
                        <small class="small">Insira o código do seu Facebook Pixel para rastrear e analisar o comportamento dos visitantes do seu site.</small>

                      </div>
                    </div>
                  </div>
                  <div class="col-form-inner col-12 col-md-12 col-lg-12 select">
                    <div class="form-group">
                      <label class="label-form">Google Ads Pixel: </label>
                      <div class="form-input">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="GTM-28CJ0"
                          v-model="gads"
                        />
                        <small class="small">Digite o código do seu Google Ads Pixel para acompanhar as conversões e otimizar suas campanhas de publicidade.</small>

                      </div>
                    </div>
                  </div>
                  <div class="col-form-inner col-12 col-md-12 col-lg-12 select">
                    <div class="form-group">
                      <label class="label-form">Google Analytics Code: </label>
                      <div class="form-input">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="GTM-28CJ0"
                          v-model="ganalytic"
                        />
                        <small class="small">Digite o código de acompanhamento do Google Analytics para monitorar o tráfego e o comportamento dos usuários no seu site.</small>

                      </div>
                    </div>
                  </div>
                  <div class="col-form-inner col-12 col-md-12 col-lg-12 select">
                    <div class="form-group">
                      <label class="label-form">Tag Manager Code: </label>
                      <div class="form-input">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="GTM-28CJ0"
                          v-model="tagManager"
                        />
                        <small class="small">Insira o código do Google Tag Manager para gerenciar e implantar tags no seu site de forma centralizada.</small>

                      </div>
                    </div>
                  </div>
                 
                </div>
              </div>

              <div :class="responsiveIntegrationsClassOne" >
               

                <div class="col-form-inner col-12 col-md-12 col-lg-12 select">
                  <div class="form-group">
                    <label class="label-form">RDRefresh Code: </label>
                    <div class="form-input">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="GTM-28CJ0"
                        v-model="rdrefreshToken"
                      />
                      <small class="small">Informe o código do RDStation para integrar suas estratégias de marketing digital e automação de marketing.</small>

                    </div>
                  </div>

                </div>
                
                  <div class="col-form-inner col-12 col-md-12 col-lg-12 select">
                    <div class="form-group">
                      <label class="label-form">RDStation Code: </label>
                      <div class="form-input">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="GTM-28CJ0"
                          v-model="rdcode"
                        />
                        <small class="small">Informe o código do RDStation para integrar suas estratégias de marketing digital e automação de marketing.</small>

                      </div>
                    </div>

                  </div>
                  <div class="col-form-inner col-12 col-md-12 col-lg-12 select">
                    <div class="form-group">
                      <label class="label-form">RDStation Secret Client: </label>
                      <div class="form-input">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="GTM-28CJ0"
                          v-model="rdSecret"
                        />
                        <small class="small">Informe o código secreto do cliente RDStation para integrar com serviços externos de forma segura e automatizada.</small>

                      </div>
                    </div>

                  </div>
                  <div class="col-form-inner col-12 col-md-12 col-lg-12 select">
                    <div class="form-group">
                      <label class="label-form">RDStation Client ID: </label>
                      <div class="form-input">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="GTM-28CJ0"
                          v-model="rdClient"
                        />
                        <small class="small">Digite o ID do cliente RDStation para configurar a integração e automatização de suas estratégias de marketing digital.</small>

                      </div>
                    </div>
                  </div>
                  
              </div>
            </div>
          </div>
        </div>
        <div class="content-button site">
          <div class="button" v-show="hasSite">
            <button type="button" @click="save()" class="btn btn-block btn-content">
              Salvar Edição e Publicar
            </button>
              <button type="button" class="btn btn-block btn-content">
                Limpar Cache
              </button>
          </div>
          <div class="button" v-show="!hasSite">
            <button type="button" @click="save()" class="btn btn-block btn-content">
              Criar e Publicar
            </button>
          </div>
        </div>
      </div>
    </div>
    
  </template>
  
  <script>
  import api from '@/utils/site'
  import apiSiteIntegration from '@/utils/Site/integrations';
  import apiAWS from '@/utils/storage/aws';
  import { mapState, mapActions } from 'vuex'
  import Loader from '@/components/Loader/loader.vue'
  

  export default{
      data(){
          return{
            hasSite:false,
            tagManager: '',
            ganalytic:'',
            gads:'',
            rdSecret:'',
            rdClient:'',
            rdcode:'',
            rdrefreshToken:'',
            id:null,
            hasSaved:false,
            hasReady:false,
            windowWidth: window.innerWidth,
          }
      },
      computed:{
        ...mapState(['token']),
        responsiveIntegrationsClassOne() {
          return this.windowWidth <= 500
            ? 'col-form-outer col-12 col-md-6 col-lg-6 col-1'
            : 'col-form-outer col-6 col-md-6 col-lg-6 col-1';
        },
      },
      methods:{
        async getConfigSite(){
          try {
            const response = await apiSiteIntegration.getIntegrationData(this.token);

            this.hasSite = true;
            this.tagManager = response.tagManagerCode;
            this.ganalytic = response.googleAnalyticsCode;
            this.facebook= response.facebookPixelCode;
            this.gads= response.googleAdsCode;
            this.rdSecret= response.rdsecretClient;
            this.rdClient= response.rdclienteId;
            this.rdcode = response.rdcode;
            this.rdrefreshToken = response.rdrefreshToken;
            this.hasReady = true;

          } catch (error) {
            console.error('Erro ao carregar as configurações do Site', error)
          }
        },
        async editSite(){
          this.hasReady = false;
          const obj = {
           
            "tagManagerCode": this.tagManager,
            "googleAnalyticsCode":this.ganalytic,
            "facebookPixelCode": this.facebook,
            "googleAdsCode":this.gads,
            "rdsecretClient": this.rdSecret,
            "rdclienteId": this.rdClient,
            "rdcode": this.rdcode,
            "rdrefreshToken": this.rdrefreshToken,
          }
          try {
            const response = await apiSiteIntegration.putIntegrationData( this.token, obj);
            this.getConfigSite();
          } catch (error) {
            console.error('Erro ao Editar Site', error)
          }
        },
          save(){
              this.editSite();
              this.hasSaved = true
          }
      },
      props:{
          saved: Boolean
      },
      created(){
        this.getConfigSite();
      },
      components:{
        Loader
      }
  }
  </script>
  
  <style scoped>
  .form-file .file-wrapper>span.thumbnail {
   height: 6.5rem;   
}
.form-group .form-input textarea {
    width: 100%;
    min-height: 7em;
    border-color: #dee2e6;
    border-radius: 5px;
    border: 1px solid #dee2e6;
}
.form-group {
    text-align: left;
    margin: 0.5em 1em;
}
.form-group .form-input textarea:focus {
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.col-form-inner.col-12.col-md-12.col-lg-12.select {
    margin: 1.9em 0em;
}
.content-button.site > .button {
    display: flex;
    gap: 17px;
    margin: 0em 1em;
}
textarea {
  height: auto !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  resize: none;
  outline: none;
  padding: 0.5rem 0.75rem;
  color: #5f5f5f;
  background-color: #fff;
  border: 1px solid #dbdcde;
  box-shadow: none;
  padding: 13px;
}
.col-form-outer.col-6.col-md-6.col-lg-6.col-1 .col-form-inner.col-12.col-md-12.col-lg-12.select {
  margin: 10px 0px;
}
.form-group img {
  width: auto;
  height: auto;
}
@media (max-width: 500px) {
  .content-button.site > .button {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin: 0em 1em;
  }
}
/* DARK */
.dashboard.dark .global-site-weeduc.adminWeeducSite small.small {
  color: white !important;
}
.dashboard.dark .global-site-weeduc.adminWeeducSite .form-group .form-input .form-control {
  background-color: #575757;
  border: 1px solid #444;
  color: white;
}
  </style>
  