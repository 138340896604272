<template>
    <apexchart type="area" :options="chartOptions" :series="series" height="300"></apexchart>
</template>

<script>
import VueApexCharts from 'vue3-apexcharts'

export default {
  components: {
    apexchart: VueApexCharts
  },
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          toolbar: {
            show: false
          },
          height: '200px',
          width: '100%',
          type: 'area',
          zoom: {
            enabled: false
          },
        },
        colors: ['#1b59f5'],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        labels: [],
        xaxis: {
          type: 'datetime',
          labels: {
            formatter: function(value, timestamp) {
              const date = new Date(timestamp);
              const meses = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
              return meses[date.getMonth()] + ' ' + date.getFullYear();
            }
          }
        },
        yaxis: {
          opposite: false
        },
        legend: {
          horizontalAlign: 'left',
          show: true
        }
      },
    }
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  watch: {
    data: {
      immediate: true,
      handler(newData) {
        if (!newData || Object.keys(newData).length === 0) {
          console.warn('Dados vazios ou indefinidos recebidos');
          return;
        }

        const dadosConvertidos = Object.entries(newData).map(([mesAno, valor]) => {
          const [mes, ano] = mesAno.split('/');
          
          const mesesParaNumeros = {
            'jan': '01', 'fev': '02', 'mar': '03', 'abr': '04',
            'mai': '05', 'jun': '06', 'jul': '07', 'ago': '08',
            'set': '09', 'out': '10', 'nov': '11', 'dez': '12'
          };

          const numeroMes = mesesParaNumeros[mes.toLowerCase()];
          if (!numeroMes) {
            console.warn(`Mês inválido encontrado: ${mes}`);
            return null;
          }

          const data = `20${ano}-${numeroMes}-01`;
          return {
            x: data,
            y: valor === null ? 0 : valor
          };
        }).filter(item => item !== null);

        if (dadosConvertidos.length === 0) {
          console.warn('Nenhum dado válido após a conversão');
          return;
        }

        dadosConvertidos.sort((a, b) => new Date(a.x) - new Date(b.x));

        this.series = [{
          name: 'Visualizações',
          data: dadosConvertidos.map(item => item.y)
        }];

        this.chartOptions.labels = dadosConvertidos.map(item => item.x);
      }
    }
  }
}
</script>

<style>
.vue-apexcharts svg {
    width: auto;
}
</style>
