<template>
    <div class="container warnings-container weeducAdminWarnings">
        <div class="title-container">
            <h3 class="title">Avisos</h3>
            <p class="subtitle">Gerencie os avisos e notificações do sistema.</p>
            <div class="btn-weflix absolute" @click="openNewWarningDialog">
                <fa icon="fa fa-plus"></fa>
                <span v-show="!isMobileIcon" class="flex">Novo Aviso</span>
            </div>
        </div>

        <div class="search-row">
            <div class="form-group search-form-group" style="flex: 8;">
                <div class="form-input search-form-input">
                    <input
                        type="text" 
                        class="form-control search-form-control"
                        placeholder="Pesquisar aviso..."
                        v-model="searchText"
                    />
                    <fa icon="fa-search" class="input-icon search-input-icon" @click="buscarAviso" />
                </div>
            </div>

            <div class="form-group filter-form-group" style="flex: 2;">
                <label>Filtrar por:</label>
                <select v-model="statusFilter" class="form-control filter-form-control" @change="filtrarAvisos">
                    <option value="todos">Todos</option>
                    <option value="ativo">Ativo</option>
                    <option value="inativo">Inativo</option>
                </select>
            </div>
        </div>

        <Loader v-if="!hasReady" />
        <div v-else class="table-responsive warnings-table-responsive">
            <table class="table warnings-table">
                <thead>
                    <tr>
                        <th style="width: 5%;">ID</th>
                        <th style="width: 30%; text-align: left;">Título</th>
                        <th style="width: 55%; text-align: left;">Descrição</th>
                        <th style="width: 10%;"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="warning in paginatedWarnings" :key="warning.id">
                        <td style="width: 5%;">#{{warning.id}}</td>
                        <td style="width: 30%; text-align: left;">{{warning.title}}</td>
                        <td style="width: 55%; text-align: left;">{{warning.description}}</td>
                        <td style="width: 10%;">
                            <button class="btn-edit warning-btn-edit" @click="openEditWarningDialog(warning)">
                                <fa icon="fa-edit"/>
                            </button>
                            <button class="btn-delete warning-btn-delete" @click="confirmDelete(warning)">
                                <fa icon="fa-trash"/>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="pagination warnings-pagination">
            <button 
                :disabled="currentPage === 1" 
                @click="currentPage--"
                class="page-btn warnings-page-btn"
            >
                Anterior
            </button>
            <span class="page-info warnings-page-info">Página {{currentPage}} de {{totalPages}}</span>
            <button 
                :disabled="currentPage === totalPages" 
                @click="currentPage++"
                class="page-btn warnings-page-btn"
            >
                Próxima
            </button>
        </div>

        <v-dialog v-model="newWarningDialog" max-width="800">
            <v-card class="dialog-card new-warning-dialog">
                <div class="modal-header">
                    <h5 class="modal-title">Criar Novo Aviso</h5>
                    <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
                  </div>
                <v-card-text class="dialog-content">
                    <v-text-field 
                        v-model="newWarning.title" 
                        label="Título do Aviso" 
                        required
                        class="dialog-input"
                    ></v-text-field>
                    <v-textarea 
                        v-model="newWarning.description" 
                        label="Descrição do Aviso" 
                        required
                        auto-grow
                        rows="8"
                        outlined
                        class="dialog-textarea"
                    ></v-textarea>
                </v-card-text>
                <v-card-actions class="dialog-actions">
                    <v-spacer></v-spacer>
                    <v-btn class="dialog-btn cancel" text @click="newWarningDialog = false">Cancelar</v-btn>
                    <v-btn class="dialog-btn confirm" text @click="createNewWarning" :disabled="!newWarning.title">Criar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="editWarningDialog" max-width="800">
            <v-card class="dialog-card edit-warning-dialog">
                <div class="modal-header">
                    <h5 class="modal-title">Editar Aviso</h5>
                    <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
                  </div>
                <!-- <v-card-title class="dialog-title">Editar Aviso</v-card-title> -->
                <v-card-text class="dialog-content">
                    <div class="status-switch">
                        <label>Status</label>
                        <v-switch
                            v-model="editingWarning.status"
                            :label="editingWarning.status === 1 ? 'Ativo' : 'Inativo'"
                            :true-value="1"
                            :false-value="0"
                            color="#2f2e8b"
                            class="dialog-switch"
                        ></v-switch>
                    </div>
                    <v-text-field 
                        v-model="editingWarning.title" 
                        label="Título do Aviso" 
                        required
                        class="dialog-input"
                    ></v-text-field>
                    <v-textarea 
                        v-model="editingWarning.description" 
                        label="Descrição do Aviso" 
                        required
                        auto-grow
                        rows="8"
                        outlined
                        class="dialog-textarea"
                    ></v-textarea>
                </v-card-text>
                <v-card-actions class="dialog-actions">
                    <v-spacer></v-spacer>
                    <v-btn class="dialog-btn cancel" text @click="editWarningDialog = false">Cancelar</v-btn>
                    <v-btn class="dialog-btn confirm" text @click="updateWarning" :disabled="!editingWarning.title">Salvar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="deleteDialog" max-width="400">
            <v-card class="dialog-card delete-warning-dialog">
                <v-card-text class="dialog-content">
                    Tem certeza que deseja excluir o aviso "{{warningToDelete?.title}}"?
                </v-card-text>
                <v-card-actions class="dialog-actions">
                    <v-spacer></v-spacer>
                    <v-btn class="dialog-btn cancel" text @click="deleteDialog = false">Cancelar</v-btn>
                    <v-btn class="dialog-btn delete" color="error" text @click="confirmDeleteWarning">Excluir</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <Loader :isActive="hasReady" />
    </div>
</template>

<script>

import Loader from '@/components/Loader/loader.vue';
import api from '@/utils/notification'
import { mapState } from 'vuex';

export default {
    data() {
        return {
            label: this.labels,
            userFiltered: [],
            searchTerm: '',
            currentPage: 1,
            itemsPerPage: 5,
            alertOpenCourse:false,
            removedcoursename:'',
            afterWidth:40,
            notificationsData:[],
            newWarningDialog: false,
            editWarningDialog: false,
            deleteDialog: false,
            warningToDelete: null,
            newWarning: {
                title: '',
                description: '',
                status: 1
            },
            editingWarning: {
                id: null,
                title: '',
                description: '',
                status: 1
            },
            id:'',
            mensage:'',
            title:'',
            hasReady: false,
            status: '1',
            isMobileIcon: false,
            statusFilter: 'todos',
            searchText: '',
        };
    },
    mounted() {
        this.checkScreenSize();
        window.addEventListener('resize', this.checkScreenSize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.checkScreenSize);
    },
    computed:{
        ...mapState(["token","numberIntensTable"]),

        paginatedWarnings() {
            const startIndex = (this.currentPage - 1) * this.numberIntensTable;
            const endIndex = startIndex + this.numberIntensTable;
            return this.filteredWarnings.slice(startIndex, endIndex);
        },
        totalPages() {
            return Math.ceil(this.filteredWarnings.length / this.numberIntensTable);
        },
        filteredWarnings() {
            let filtered = this.notificationsData;

            if (this.searchText && this.searchText.trim() !== '') {
                filtered = filtered.filter((warning) =>
                    warning.title.toLowerCase().includes(this.searchText.toLowerCase())
                );
            }

            if (this.statusFilter !== 'todos') {
                filtered = filtered.filter((warning) =>
                    warning.status.toLowerCase() === this.statusFilter.toLowerCase()
                );
            }

            return filtered;
        }
    },
    methods: {
        openNewWarningDialog() {
            this.newWarningDialog = true;
        },
        openEditWarningDialog(warning) {
            this.editingWarning = {
                id: warning.id,
                title: warning.title,
                description: warning.description,
                status: warning.status
            };
            this.editWarningDialog = true;
        },
        confirmDelete(warning) {
            this.warningToDelete = warning;
            this.deleteDialog = true;
        },
        async confirmDeleteWarning() {
            if (this.warningToDelete) {
                await this.deleteWarning(this.warningToDelete.id);
                this.deleteDialog = false;
                this.warningToDelete = null;
            }
        },
        async createNewWarning() {
            const payload = {
                title: this.newWarning.title,
                description: this.newWarning.description,
                status: 1
            }
            try {
               const response = await api.createNewNotification(payload, this.token);
                await this.getDataNotification();
                this.newWarningDialog = false;
                this.newWarning = {
                    title: '',
                    description: '',
                    status: 1
                };
            } catch (error) {
                console.error('Erro ao criar aviso:', error);
            }
        },
        async updateWarning() {
            const payload = {
                id: this.editingWarning.id,
                title: this.editingWarning.title,
                description: this.editingWarning.description,
                status: this.editingWarning.status
            }
            try {
                const response = await api.editNotification(this.editingWarning.id, this.token, payload);
                await this.getDataNotification();
                this.editWarningDialog = false;
            } catch (error) {
                console.error('Erro ao atualizar aviso:', error);
            }
        },
        async deleteWarning(id) {
            try {
                const payload = {
                    id: id,
                    status: 0
                }
                await api.editNotification(id, this.token, payload);
                await this.getDataNotification();
            } catch (error) {
                console.error('Erro ao deletar aviso:', error);
            }
        },
        async getDataNotification(){
            try {
                const response = await api.getDataNotification(this.token);
                this.notificationsData =  response;
                this.hasReady = true;
            } catch (error) {
                console.error('Erro ao carregar notificações', error)
            }
        },
        filtrarAvisos() {
            this.currentPage = 1;
        },
        previousPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },
        removecourse(id, name){
            this.removedcoursename = name;
            this.alertOpenCourse = !this.alertOpenCourse;
            setTimeout(() => {
                this.alertOpenCourse = !this.alertOpenCourse;
                this.removedcoursename = '';
            }, 4000);
        },
        edit(id, title, description, status){
            this.openModalEditWarning = !this.openModalEditWarning;
            this.id = id;
            this.title = title;
            this.mensage = description;
            this.status = status;
        },
        create(){
            this.openModalCreateWarning = !this.openModalCreateWarning;
        },
        updatevalueCreate(newvalue){
            this.openModalCreateWarning = newvalue;
            this.getDataNotification();
        },
        updatevalueEdit(newvalue){
            this.getDataNotification();
            this.openModalEditWarning = newvalue;
        },
        checkScreenSize() {
            this.isMobileIcon = window.innerWidth <= 600;
        },
    },
    components: {
        Loader
    },
    created(){
        this.getDataNotification();
    },
};
</script>

<style scoped>
.container {
    max-width: 100%;
    background-color: #ffffff;
    padding: 1.5rem;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}
.search-form-input{
    position: relative;
    width: 100%;
}
.title-container {
      position: relative;
    margin-bottom: 10px;
    padding-bottom: 0;
    border-bottom: 1px solid #f0f0f0;
    text-align: left;
}

.title-container .title {
    font-size: 1.5rem;
    font-weight: 700;
    color: #000000;
    margin-bottom: 0.5rem;
}

.title-container .subtitle {
    color: #6b7280;
    font-size: 0.875rem;
    line-height: 1.4;
    margin-bottom: 1rem;
}

.search-row {
    display: flex;
    gap: 0.75rem;
    margin-bottom: 1.5rem;
}

.search-row .form-group {
    flex: 1;
}

.btn-weflix.absolute {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #2f2e8b;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: 600;
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(47, 46, 139, 0.2);
}

.btn-weflix.absolute:hover {
    background-color: #23225f;
    transform: translateY(-2px);
}

.warnings-table-responsive {
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 1rem;
}

.warnings-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
}

.warnings-table th {
    background-color: #f8fafc;
    padding: 0.75rem;
    font-weight: 600;
    color: #4a5568;
    text-align: left;
    border-bottom: 2px solid #e2e8f0;
}

.warnings-table td {
    padding: 0.75rem;
    vertical-align: middle;
    border-bottom: 1px solid #f0f0f0;
    color: #4a5568;
}

.warnings-table tr:hover {
    background-color: #f8fafc;
}

.badge-active, .badge-inactive {
    padding: 0.25rem 0.75rem;
    border-radius: 5px;
    font-size: 0.75rem;
    font-weight: 500;
    display: inline-block;
    text-align: center;
    transition: all 0.2s ease;
    width: 70%;
}

.badge-active {
    background-color: #7bcc8180;
    color: #4b614e;
}

.badge-inactive {
  background-color: #d7d7ee80;
    color: #2f2e8b;
    }

.warning-btn-edit, .warning-btn-delete {
    padding: 0.4rem;
    margin: 0 0.25rem;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
}

.warning-btn-edit {
   color: #2f2e8b;
}

.warning-btn-delete {
   color: #dc2626;
}

.warning-btn-edit:hover {
    background-color: #e4e3ff;
transform: translateY(-2px);
}

.warning-btn-delete:hover {
    background-color: #fee2e2;
    transform: translateY(-2px);
}

.search-form-input {
    position: relative;
}

.search-form-control {
    width: 100%;
    padding: 0.5rem 0.75rem;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    font-size: 0.875rem;
    transition: all 0.2s ease;
}

.search-form-control:focus {
    outline: none;
    border-color: #2f2e8b;
    box-shadow: 0 0 0 3px rgba(47, 46, 139, 0.1);
}

.search-input-icon {
    position: absolute;
    right: 0.75rem;
    top: 45%;
    transform: translateY(-50%);
    color: #9ca3af;
    cursor: pointer;
}

.warnings-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
}

.warnings-page-btn {
    padding: 0.5rem 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    background: white;
    color: #4a5568;
    cursor: pointer;
    transition: all 0.2s;
}

.warnings-page-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.warnings-page-btn:not(:disabled):hover {
    background: #f8fafc;
    border-color: #cbd5e0;
}

.warnings-page-info {
    color: #4a5568;
    font-size: 0.875rem;
}

@media (max-width: 768px) {
    .container {
        padding: 1rem;
    }

    .title-container {
        margin-bottom: 1.5rem;
    }

    .search-row {
        flex-direction: column;
    }

    .warnings-table th, .warnings-table td {
        padding: 0.5rem;
    }
}

@media (max-width: 600px) {
    .btn-weflix.absolute {
        padding: 0.5rem;
    }
    
    .btn-weflix.absolute span {
        display: none;
    }

    .warnings-table-responsive {
        overflow-x: auto;
    }
}
.content.dashboard > .container {
    background-color: white;
    padding: 20px;
    padding-bottom: 35px;
    box-shadow: 0px 6px 10px 0px #dfdfdf;
    min-height: 89vh;
    padding-bottom: 0;
}
select.form-control.filter-form-control {
    width: 65%;
}
.form-group.filter-form-group {
    justify-content: space-around;
align-items: center;
    margin: 0 !important;

}
.form-group.search-form-group {
    margin: 0 !important;
}
table.table.warnings-table {
    border: none !important;
    padding: 0 !important;
}
button.btn-edit.warning-btn-edit svg {
    color: #2f2e8b;
}
button.btn-delete.warning-btn-delete svg {
    color: #dc2626;
}
.container.warnings-container.weeducAdminWarnings .btn-delete.warning-btn-delete {
    position: inherit !important;
}
.form-group.filter-form-group {
    display: flex;
}
.btn-weflix.absolute span.flex {
    font-size: 16px;
}
.btn-weflix.absolute svg {
    color: white;
    font-size: 15px;
}

/* Estilos melhorados para os diálogos */
.dialog-card {
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    background: #ffffff;
    overflow: hidden;
}

.dialog-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #2f2e8b;
    padding: 1.5rem;
    border-bottom: 1px solid #e2e8f0;
    background: #f8fafc;
}

.dialog-title.delete {
    color: #dc2626;
    background: #fee2e2;
}

.dialog-content {
    padding: 1.5rem;
}

.dialog-input,
.dialog-select,
.dialog-textarea {
    margin-bottom: 1.25rem;
}

.dialog-input :deep(input),
.dialog-select :deep(select),
.dialog-textarea :deep(textarea) {
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    padding: 0.75rem;
    font-size: 0.875rem;
    width: 100%;
    transition: all 0.2s ease;
}

.dialog-input :deep(input:focus),
.dialog-select :deep(select:focus),
.dialog-textarea :deep(textarea:focus) {
    border-color: #2f2e8b;
    box-shadow: 0 0 0 3px rgba(47, 46, 139, 0.1);
}

.dialog-textarea :deep(textarea) {
    min-height: 150px;
    resize: vertical;
}

.dialog-actions {
    padding: 1rem 1.5rem;
   
    border-top: 1px solid #e2e8f0;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
}

.dialog-btn {
    padding: 0.5rem 1.5rem;
    border-radius: 6px;
    font-weight: 500;
    text-transform: none;
    transition: all 0.2s ease;
}

.dialog-btn.cancel {
    color: #64748b;
    background: #f1f5f9;
}

.dialog-btn.confirm {
    color: #ffffff;
    background: #2f2e8b;
}

.dialog-btn.delete {
    color: #ffffff!important;
    background: #dc2626!important;
}

.dialog-btn:hover {
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dialog-btn:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

/* Animações para os diálogos */
.v-dialog-transition-enter-active,
.v-dialog-transition-leave-active {
    transition: all 0.3s ease;
}

.v-dialog-transition-enter-from,
.v-dialog-transition-leave-to {
    opacity: 0;
    transform: scale(0.95);
}

/* Estilo para o status switch */
.status-switch {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
}

.status-switch label {
    font-size: 0.875rem;
    color: #4a5568;
    font-weight: 500;
}

.dialog-switch {
    margin: 0;
}
</style>
<style>
.v-overlay.v-overlay--active.v-theme--light.v-locale--is-ltr.v-dialog.v-overlay--scroll-blocked .v-overlay__content{
    width: 600px !important;
    max-width: 600px !important;
}
textarea#input-18 {
    padding-top: 25px !important;
}
</style>
<style>
.v-input.v-input--horizontal.v-input--center-affix.v-input--density-default.v-locale--is-ltr.v-input--dirty.v-switch.dialog-switch .v-input__details {
    display: none !important;
}
textarea#input-23 {
    padding-top: 20px !important;
}
.v-textarea .v-field--no-label textarea, .v-textarea .v-field--active textarea {
    opacity: 1;
    padding-top: 25px !important;
    background-color: #f6f6f6 !important;
}
.warnings-container .v-text-field .v-field--no-label input,.warnings-container .v-text-field .v-field--active input {
    opacity: 1;
    padding-top: 24px;
    padding-left: 17px;
}
/* DARK */
.dashboard.dark .content.dashboard > .container.warnings-container.weeducAdminWarnings {
    background-color: #000;
}
.dashboard.dark .container.warnings-container.weeducAdminWarnings .title-container .title {
    color: white !important;
}
.dashboard.dark .container.warnings-container.weeducAdminWarnings .subtitle {
    color: white !important;
}
.dashboard.dark .container.warnings-container.weeducAdminWarnings .form-group label,
.dashboard.dark .container.warnings-container.weeducAdminWarnings .title,
.dashboard.dark .container.warnings-container.weeducAdminWarnings .btn-edit.warning-btn-edit svg,
.dashboard.dark .container.warnings-container.weeducAdminWarnings .warnings-page-info {
    color: white !important;
}
.dashboard.dark .container.warnings-container.weeducAdminWarnings .form-group .form-input .form-control {
    background-color: #575757 !important;
}
.dashboard.dark .container.warnings-container.weeducAdminWarnings .form-control {
    background-color: #575757;
    color: white;
    border: 1px solid #444;
}
.dashboard.dark .container.warnings-container.weeducAdminWarnings .table-responsive.warnings-table-responsive {
    background-color: #000;
}
.dashboard.dark .container.warnings-container.weeducAdminWarnings .warning-btn-edit:hover {
    background-color: #575757;
}
.dashboard.dark .container.warnings-container.weeducAdminWarnings .warning-btn-delete:hover {
    background-color: #575757;
}
.dashboard.dark .container.warnings-container.weeducAdminWarnings .warnings-table th {
    border-bottom: 2px solid #575757;
}
.dashboard.dark .container.warnings-container.weeducAdminWarnings .warnings-table td {
    border-bottom: 1px solid #575757;
}
.dashboard.dark .container.warnings-container.weeducAdminWarnings .title-container {
    border-bottom: 1px solid #575757 !important;
}
.modal-header {
    padding: 16px 24px 10px;
}
</style>