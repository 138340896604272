<template>
    <Header @toggleModal="toggleOpenDialog" @toggleModalSubscribe="toggleOpenSubscribe"/> 

    <section class="contact">
        <div class="infoContact">
            <h1>Contatos</h1>
        <p>Na Weflix, estamos sempre prontos para ajudar! Se você tiver alguma dúvida, sugestão ou precisa de suporte, não hesite em entrar em contato conosco. Nossa equipe está à disposição para garantir que sua experiência seja a melhor possível.</p>
        
       
        <div class="contacts">
            <div class="item">
                <h2><fa icon="fa-phone"/>Telefone</h2>
                <p>(00) 0000-0000</p>
                <p>(00) 0000-0000</p>
            </div>    
            <div class="item">
                <h2><fa icon="fa-envelope"/>E-mail</h2>
                <p><a href="mailto:contato@weflix.com">suporte@weflix.com</a></p>
            </div> 
            <div class="item">
                <h2><fa icon="fa-clock"/>Horário de Atendimento</h2>
                <p>Segunda a Sexta: 9h às 18h<br>
                Sábados: 9h às 14h</p>
            </div> 
        </div>
        </div>
    </section>
    <Footer/>

    <Login :show="openDialogLogin" @toggleModal="toggleOpenDialog"/>
    <Subscribe :show="openDialogSubscribe" @toggleModal="toggleOpenSubscribe"/>
</template>

<script>
import Header from "@/components/Weflix/Hedaer.vue"
import Footer from '@/components/Weflix/Footer.vue'
import Login from '@/components/Weflix/Login.vue'
import Subscribe from '@/components/Weflix/Subscribe.vue'

export default {
    data(){
        return{
            openDialogLogin:false,
            openDialogSubscribe:false,
        }
    },
    components:{
        Header,
        Footer,
        Login,
        Subscribe
    },
    methods:{
        toggleOpenDialog(){
            this.openDialogLogin = !this.openDialogLogin;
        },
        toggleOpenSubscribe(){
            this.openDialogSubscribe = !this.openDialogSubscribe;
        }
    }
}
</script>

<style scoped>
section.contact {
    padding-top: 100px;
    background-color: black;
    min-height: calc(100vh - 376px);
}
.infoContact {
    max-width: 1500px;
    margin: 0 auto;
    text-align: left;
}
.infoContact p, .infoContact h1 , .infoContact h2, .infoContact a{
    color: white;
}
.infoContact h1 {
    font-size: 30px;
    font-weight: 700;
}
.contacts {
    display: block;
    gap: 10px;
    flex-wrap: wrap;
}
.item {
    width: 32%;
    display: block;
    border: none;
    padding: 10px 0;
}
.item h2 {
    font-size: 18px;
    font-weight: 700;
}
.item svg {
    font-size: 17px;
    margin-right: 10px;
}
</style>
