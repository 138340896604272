<template>
    <section class="section link-list primary">
      <div class="link-list-content">
        <div class="content-link create-course">
          <div class="link">
            <a
              class="link-content"
              :class="[isActiveTab(1)? 'active': '']"
              @click="setActiveTab(1)"
            >
              Básico
            </a>
          </div>
          <div class="link">
            <a
              class="link-content"
              :class="[isActiveTab(3)? 'active': '']"
              @click="setActiveTab(3)"
            >
              Temas
            </a>
          </div>
          <div class="link">
            <a
              class="link-content"
              :class="[isActiveTab(2)? 'active': '']"
              @click="setActiveTab(2)"
            >
            Layout
            </a>
          </div>
         
          <div class="link redes-sociais-mobile">
            <a
              class="link-content"
              :class="[isActiveTab(4)? 'active': '']"
              @click="setActiveTab(4)"
            >
              Rede Sociais
            </a>
          </div>
          <div class="link">
            <a
              class="link-content"
              :class="[isActiveTab(6)? 'active': '']"
              @click="setActiveTab(6)"
            >
              Integrações
            </a>
          </div>
          <div class="link">
            <a
              class="link-content"
              :class="[isActiveTab(5)? 'active': '']"
              @click="setActiveTab(5)"
            >
              Avançado
            </a>
          </div>
        </div>
      </div>
    </section>

     <section
      class="section config-form primary"
      :class="[isActiveTab(1)? 'active': '']">
      <Basic />
    </section>
    <section
    class="section config-form primary"
    :class="[isActiveTab(2)? 'active': '']">
    <Layout />
    </section>
    <section
    class="section config-form primary"
    :class="[isActiveTab(3)? 'active': '']">
    <Themes />
    </section>
    <section
    class="section config-form primary"
    :class="[isActiveTab(4)? 'active': '']">
    <SocialMedia />
    </section>
    <section
    class="section config-form primary"
    :class="[isActiveTab(5)? 'active': '']">
    <Advanced />
    </section>
    <section
    class="section config-form primary"
    :class="[isActiveTab(6)? 'active': '']">
    <Integrations />
    </section>
  
  

  </template>
  
  <script>
  import Basic from '@/components/Weflix/Layout/basic.vue';
  import Advanced from '@/components/Weflix/Layout/advanced.vue';
  import Integrations from '@/components/Weflix/Layout/integrations.vue';
  import Layout from '@/components/Weflix/Layout/layout.vue';
  import Themes from '@/components/Weflix/Layout/themes.vue';
  import SocialMedia from '@/components/Weflix/Layout/socialMedia.vue';
    
  export default {
    data() {
      return {
        activeTab: 1,
        hassaved:false,
        openmodalmodule:false,
      }
    },
    props: {
  
    },
    components: {
      Basic,
      Advanced,
      Integrations,
      Layout,
      Themes,
      SocialMedia
    },
    methods: {
      setActiveTab(tabNumber) {
        this.activeTab = tabNumber;
      },
      isActiveTab(tabNumber) {
        return this.activeTab === tabNumber;
      },
    },
    created() {
  
    }
  }
  </script>
  
  <style scoped>
  @import url(./global.css);

  .container {
    max-width: 100%;
 }
  .link a {
    display: inline-block;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: rgb(24, 24, 28);
    background-color: rgb(255, 255, 255);
    text-decoration: none;
    padding: 0.5rem 2rem;
    border-bottom: 1px solid rgb(246, 246, 246);
    border-right: 1px solid rgb(246, 246, 246);
    cursor: pointer;
}
.link-list-content .content-link .link .link-content.active {
    color: rgb(255, 255, 255);
    background-color: #250891;
    border-right: 1px solid #250891;
}
section.section.config-form.primary.active {
    display: block;
    height: 83vh;
    overflow-y: auto;
}
@media (max-width: 600px) {
  .content-link.create-course {
    overflow: hidden;
    overflow-x: auto;
    border-radius: 10px 10px 0px 0px;
  }
}
@media (max-width: 500px) {
  .redes-sociais-mobile {
    min-width: fit-content !important;
  }
}
section.section.config-form.primary {
  display: none;
}
.content-link.create-course {
  display: flex;
}
.content-link.create-course .link:first-child a {
  border-radius: 10px 0px 0px 0px;
}
section.section.config-form.primary {
  background-color: white;
  overflow-x: hidden;
}
  </style>
  