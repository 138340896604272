<template>
    <div class="config-form-content adminWeeducConfig">
        <div class="content-wrapper primary">
            <div class="wrapper-inner">
                <div class="content-form">
                    <div class="row">
                        <div :class="responsiveConfigClassOne">
                            <div class="row">
                                <div class="col-form-inner col-12 col-md-12 col-lg-12">
                                    <div class="form-group">
                                        <div class="form-label">
                                            <label>Título:</label>
                                        </div>
                                        <div class="form-input">
                                            <input
                                                type="text"
                                                class="form-control"
                                                placeholder="Videoteca ALLTech"
                                                v-model="title"
                                            />
                                            <small class="small">
                                                Título do seu site utlizado para mecanismos de pesquisa (SEO).
                                            </small>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-form-inner col-12 col-md-12 col-lg-12">
                                    <div class="form-group">
                                        <div class="form-label">
                                            <label>Descrição</label>
                                        </div>
                                        <div class="form-input">
                                            <textarea v-model="description"></textarea>
                                            <small class="small">
                                                Descrição do seu site utlizada para mecanismos de pesquisa (SEO).
                                            </small>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-form-inner col-12 col-md-12 col-lg-12 select">
                                    <div class="form-group">
                                        <label class="label-form">Status</label>
                                        <select v-model="status" class="select-mode">
                                            <option value="1" selected>Ativo</option>
                                            <!-- <option value="0">
                                                Ativo e Disponível na Loja
                                            </option>
                                            <option value="0">
                                                Ativo e Indisponível na Loja
                                            </option> -->
                                            <option value="0">Inativo</option>
                                        </select>
                                        <small class="small">
                                            Selecione o status de visibilidade do curso.
                                        </small>
                                    </div>
                                </div>
                                <div class="col-form-inner col-12 col-md-12 col-lg-12">
                                    <div class="form-group">
                                        <div class="form-label">
                                            <label>Palavras-Chave</label>
                                        </div>
                                        <div class="form-input">
                                            <textarea v-model="keyWords"></textarea>
                                            <small class="small">
                                                Palavras chaves do seu site utlizada para mecanismos de pesquisa (SEO).
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div :class="responsiveConfigClassTwo">
                            <div class="col-form-inner col-12 col-md-12 col-lg-12">
                                <div class="form-group">
                                    <div class="form-label">
                                        <label>Logo ( altura máxima 80px)</label>
                                    </div>
                                    <div class="form-file">
                                        <div class="file-wrapper primary">
                                            <img
                                                :src="logoPreview? logoPreview : logo"
                                                alt="Logo"
                                                v-show="logoPreview || logo"
                                            />
                                            <span class="thumbnail">
                                                <span class="icon">
                                                    <svg
                                                        width="46"
                                                        height="39"
                                                        viewBox="0 0 46 39"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M45 19.9308L36.7274 12.3475C36.1595 11.827 35.2948 11.8047 34.7009 12.2953L18.824 25.411C18.3196 25.8277 17.6074 25.8812 17.0464 25.5446L11.1009 21.9773C10.5893 21.6703 9.94666 21.6856 9.45024 22.0166L1 27.6501"
                                                            stroke="#9D9FA3"
                                                            stroke-width="1.25714"
                                                        ></path>
                                                        <rect
                                                            x="1.00049"
                                                            y="1.40454"
                                                            width="43.9461"
                                                            height="36.1909"
                                                            rx="2.58507"
                                                            stroke="#9D9FA3"
                                                            stroke-width="1.25714"
                                                        ></rect>
                                                        <circle
                                                            cx="14.8948"
                                                            cy="10.6676"
                                                            r="3.71629"
                                                            stroke="#9D9FA3"
                                                            stroke-width="1.25714"
                                                        ></circle>
                                                    </svg>
                                                </span>
                                                <span class="text">
                                                    Clique aqui para selecionar a imagem
                                                </span>
                                            </span>
                                            <input
                                                class="form-control"
                                                type="file"
                                                @change="updateFilePathLogo"
                                                accept=".png, .jpg, .jpeg, .webp"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-form-inner col-12 col-md-12 col-lg-12">
                                <div class="form-group">
                                    <div class="form-label">
                                        <label>Favicon</label>
                                    </div>
                                    <div class="form-file">
                                        <div class="file-wrapper primary">
                                            <img
                                                :src="faviconPreview ? faviconPreview : favicon "
                                                alt="Logo"
                                                v-show="faviconPreview || favicon"
                                                style="width: 50px;"
                                            />
                                            <span class="thumbnail">
                                                <span class="icon">
                                                    <svg
                                                        width="46"
                                                        height="39"
                                                        viewBox="0 0 46 39"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M45 19.9308L36.7274 12.3475C36.1595 11.827 35.2948 11.8047 34.7009 12.2953L18.824 25.411C18.3196 25.8277 17.6074 25.8812 17.0464 25.5446L11.1009 21.9773C10.5893 21.6703 9.94666 21.6856 9.45024 22.0166L1 27.6501"
                                                            stroke="#9D9FA3"
                                                            stroke-width="1.25714"
                                                        ></path>
                                                        <rect
                                                            x="1.00049"
                                                            y="1.40454"
                                                            width="43.9461"
                                                            height="36.1909"
                                                            rx="2.58507"
                                                            stroke="#9D9FA3"
                                                            stroke-width="1.25714"
                                                        ></rect>
                                                        <circle
                                                            cx="14.8948"
                                                            cy="10.6676"
                                                            r="3.71629"
                                                            stroke="#9D9FA3"
                                                            stroke-width="1.25714"
                                                        ></circle>
                                                    </svg>
                                                </span>
                                                <span class="text">
                                                    Clique aqui para selecionar a imagem
                                                </span>
                                            </span>
                                            <input
                                                class="form-control"
                                                type="file"
                                                @change="updateFilePathIcon"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-button site">
                <div class="button" v-show="hasSite">
                    <button
                        type="button"
                        @click="save()"
                        class="btn btn-block btn-content"
                    >
                        Salvar Edição e Publicar
                    </button>
                    <button type="button" class="btn btn-block btn-content">
                        Limpar Cache
                    </button>
                </div>
                <div class="button" v-show="!hasSite">
                    <button
                        type="button"
                        @click="save()"
                        class="btn btn-block btn-content"
                    >
                        Criar e Publicar
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import api from '@/utils/site'
    import apiSiteBasic from '@/utils/Site/basic'
    import apiAWS from '@/utils/storage/aws';
    import { mapState } from 'vuex'
    import Loader from '@/components/Loader/loader.vue'

    export default{
        data(){
            return{
                hasSite:false,
                title: '',
                url: '',
                logo: null,
                logoPreview: null,
                favicon: null,
                faviconPreview: null,
                description: '',
                keyWords: '',
                status: '',
                id:null,
                hasReady:true,
                windowWidth: window.innerWidth,
            }
        },
        components:{
            Loader
        },
        computed:{
            ...mapState(['token']),
            responsiveConfigClassOne() {
                return this.windowWidth <= 500
                    ? 'col-form-outer col-12 col-md-6 col-lg-6 col-1'
                    : 'col-form-outer col-6 col-md-6 col-lg-6 col-1';
            },
            responsiveConfigClassTwo() {
                return this.windowWidth <= 500
                    ? 'col-form-outer col-12 col-md-6 col-lg-6 col-2'
                    : 'col-form-outer col-6 col-md-6 col-lg-6 col-2';
            },
        },
        methods:{
            async putFileAws(url, file){
                try {
                    const response = await apiAWS.putFileAWS(url, file);
                } catch (error) {
                    console.error('Erro ao enviar arquivo para AWS');
                }
            },
            async updateFilePathLogo(event){
                const file = event.target.files[0];
                this.logoPreview = URL.createObjectURL(file)

                const formData = new FormData();
                formData.append('file', this.logo);

                try {
                    const response =  await apiAWS.getSignatureImage(this.token, file.name);
                    this.putFileAws(response.url, file)
                    this.logo = response.token;
                    console.debug(response);
                } catch (error) {
                    console.error('Erro na geração do TOKEN AWS')
                }
            },
            async updateFilePathIcon(event){
                const file = event.target.files[0];
                this.faviconPreview = URL.createObjectURL(file)

                const formData = new FormData();
                formData.append('file', this.favicon);

                try {
                    const response =  await apiAWS.getSignatureImage(this.token, file.name);
                    this.putFileAws(response.url, file)
                    this.favicon = response.token;
                    console.debug(response);
                } catch (error) {
                    console.error('Erro na geração do TOKEN AWS')
                }
            },
            async getConfigSite(){
                try {
                    const response = await apiSiteBasic.getBasicData(this.token);

                    this.hasSite = true;
                    this.title = response.title;
                    this.url = response.url;
                    this.description   = response.description;
                    this.keyWords  = response.keywords;
                    this.status  = response.status;
                    this.logo = response.logo;
                    this.favicon = response.favicon;
                    this.logoPreview = response.logoFinal;
                    this.faviconPreview = response.faviconFinal;

                } catch (error) {
                    console.error('Erro ao carregar as configurações do Site', error)
                }
            },
            async createSite(){
                const obj = {
                    "nome": this.title,
                    "title": this.title,
                    "description": this.description,
                    "keywords": this.keyWords,
                    "logo": null,
                    "favicon": null,
                    "status": this.status
                }
                try {
                    const response = api.CreateSite(this.token, obj);
                } catch (error) {
                    console.error('Erro ao criar Site', error)
                }
            },
            async editSite(){
                const obj = {
                    "nome": this.title,
                    "title": this.title,
                    "description": this.description,
                    "keywords": this.keyWords,
                    "logo": this.logo,
                    "favicon": this.favicon,
                    "status": this.status
                }
                try {
                    const response = await apiSiteBasic.putBasicData(this.token, obj);
                    this.getConfigSite();
                } catch (error) {
                    console.error('Erro ao editar Site', error)
                }
            },
            save(){
                this.editSite();
               
            }
        },
        props:{
            saved: Boolean
        },
        created(){
            this.getConfigSite();
        },
      
    }
</script>

<style>
    .form-file .file-wrapper > span.thumbnail {
        height: 6.5rem;
    }
    .form-group .form-input textarea {
        width: 100%;
        min-height: 7em;
        border-color: #dee2e6;
        border-radius: 5px;
        border: 1px solid #dee2e6;
    }
    .form-group {
        text-align: left;
        margin: 0.5em 1em;
    }
    .form-group .form-input textarea:focus {
        color: var(--bs-body-color);
        background-color: var(--bs-body-bg);
        border-color: #86b7fe;
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    }
    .col-form-inner.col-12.col-md-12.col-lg-12.select {
        margin: 1.9em 0em;
    }
    .content-button.site > .button {
        display: flex;
        gap: 17px;
        padding: 10px;
    }
    textarea {
        height: auto !important;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 28px;
        resize: none;
        outline: none;
        padding: 0.5rem 0.75rem;
        color: #5f5f5f;
        background-color: #fff;
        border: 1px solid #dbdcde;
        box-shadow: none;
        padding: 13px;
    }
    .col-form-outer.col-6.col-md-6.col-lg-6.col-1
    .col-form-inner.col-12.col-md-12.col-lg-12.select {
        margin: 10px 0px;
    }
    .form-group img {
        width: auto;
        height: auto;
    }
    @media (max-width: 500px) {
        .row {
            display: block;
        }
        .form-group img {
            width: 220px;
            height: auto;
        }
        .content-button.site > .button {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            margin: 0em 1em;
        }
    }
    .content-link.create-course .link:first-child a {
        border-radius: 10px 0px 0px 0px;
    }
    button.btn.btn-block.btn-content {
      color: white;
      background-color: #250891;
  }
  .form-group .form-label label{
    font-weight: 400!important;
  }
  /* DARK */
  .dashboard.dark .config-form-content.adminWeeducConfig small,
  .dashboard.dark .config-form-content.adminWeeducConfig span {
    color: #b1b1b1;
  }
  .dashboard.dark .config-form-content.adminWeeducConfig select.select-mode {
    border-radius: 5px;
  }
  .dashboard.dark .config-form-content.adminWeeducConfig input.form-control {
    background-color: #575757;
    color: white;
  }
  .dashboard.dark .config-form-content.adminWeeducConfig .form-input textarea {
    background-color: #575757 !important;
    color: white !important;
    border: 1px solid #444 !important;
  }
  .dashboard.dark .config-form-content.adminWeeducConfig .form-group .form-input .form-control,
  .dashboard.dark .config-form-content.adminWeeducConfig .form-control {
    border: 1px solid #444;
  }
</style>
