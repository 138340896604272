<template>
    <div class="sidebar primary" :class="{ 'show': sideBarState }">
        <fa v-if="sideBarState" icon="fa-xmark" class="icon-close" @click="changeSideBarState"/>
        <!--Header Section-->

        <div class="sidebar-header">
            <div class="header-logo">
                <div class="logo">
                    <img class="logo_black" src="./../../assets/logo_white.png" style="width: 100%;">
                    <img class="logo_white" src="./../../assets/logo_white.png" style="width: 100%;">
                </div>
            </div>
        </div>

        <!--Content Section-->

        <div class="sidebar-content" >
            <div class="content-link">
                <div class="link" v-for="(itens, index) in dataSidebar" :key="index">
                    <router-link class="link-content primary" :to="itens.link" :class="{ active: activeIndex == index }"
                        @click="setActiveIndex(index)">
                        <span class="link-icon">
                            <!-- <i class="fa" :class="itens.icon" style="font-size: 1rem"></i> -->
                            <fa :icon="itens.icon"  style="font-size: 1rem"/>
                        </span>
                        <span class="link-text">
                            <span class="text">
                               {{ itens.name }}
                            </span>
                        </span>
                    </router-link>
                </div>
<!-- 
                <div class="link dad" >
                    <a class="link-content primary" href="#"
                    @click="setActiveIndex(8)"
                    :class="{ active: activeIndex == 8 }"
                    >
                        <span class="link-icon">
                            <i class="fa fa-chart-line" style="font-size: 1rem"></i>

                        </span>
                        <span class="link-text">
                            <span class="text">
                                Relatórios
                            </span>
                        </span>
                    </a>
                    <div class="link subitem" v-for="index in typesReports.length+1" :key="index">
                    <a class="link-content primary" :href="'/dashboard/relatorios/'+linksReports[index-1]"     
                         @click="setSecondIndex(index), setActiveIndex(8)"
                         :class="{ active: secondoactiveindex === index }"
                         >
                        
                        <span class="link-icon">
                            <i class="fa" :class="iconsReports[index-1]" style="font-size: 1rem"></i>

                        </span>
                        <span class="link-text">
                            <span class="text">
                              {{ typesReports[index-1] }}
                            </span>
                        </span>
                    </a>
                </div>
                </div>
                       -->
            </div>
        </div>
    </div>
</template>


<script>
import store from '@/store';
import  { mapState,mapActions } from 'vuex';

export default {
    data() {
        return {
            menu: [],
            activeIndex: null,
            secondoactiveindex:null,
            lenguage: "pt",
            label_lenguage: "Idioma",
            typeUser:'',
            typesReports: [ 'Dúvidas', 'Fóruns', 'Avisos', 'Usuários','Vendas', 'Cursos'],
            iconsReports: ['fa-question-circle','fa-bookmark','fa-bell','fa-user','fa-money-bill','fa-chart-line'],
            linksReports: ['duvidas','foruns','avisos','usuarios','vendas','cursos'],
            dataSidebar:[
                {
                    name:'Painel',
                    link:'/dashboard/painel',
                    icon:'fa-border-all',
                    type:'0'
                },
                {
                    name:'Cursos',
                    link:'/dashboard/cursos',
                    icon:'fa-book',
                    type:'0'
                },
                {
                    name:'Dúvidas',
                    link:'/dashboard/duvidas',
                    icon:'fa-question-circle',
                    type:'0'
                },
                {
                    name:'Fórum',
                    link:'/dashboard/forum',
                    icon:'fa-comments',
                    type:'0'
                },
                {
                    name:'Avisos',
                    link:'/dashboard/avisos',
                    icon:'fa-bookmark',
                    type:'0'
                },
                {
                    name:'Usuários',
                    link:'/dashboard/usuarios',
                    icon:'fa-user',
                    type:'1'
                },
                {
                    name:'Site',
                    link:'/dashboard/site',
                    icon:'fa-globe',
                    type:'1'
                },
                {
                    name:'Cupons',
                    link:'/dashboard/cupons',
                    icon:'fa-dollar-sign',
                    type:'1'
                },
                {
                    name:'Relatórios',
                    link:'/dashboard/relatorios',
                    icon:'fa-chart-line',
                    type:'1'
                },
            ]
        }
    },
    computed: {
        ...mapState(['sideBarState'])
    },
    methods: {
        ...mapActions(['storeTypeId','storeSideBarState']),

        changeType(){
            this.storeTypeId({typeId: this.typeUser})
        },
        setActiveIndex(index) {
            if (this.activeIndex === index) {
                // this.activeIndex = null;
            } else {
                if(this.setActiveIndex!=8){
                    this.secondoactiveindex=null;
                }
                this.activeIndex = index;
            }
            localStorage.setItem('activeIndex', this.activeIndex);
        },
        setSecondIndex(index){
            if(this.secondoactiveindex===index){

            } else {
                this.secondoactiveindex = index;
            }
            localStorage.setItem('activeSecondIndex', this.secondoactiveindex);
            localStorage.setItem('activeIndex', this.activeIndex);
        },
        changeLenguage() {

            this.menu = require('../../locale/pt/sidebar.json');

            localStorage.setItem('lenguage', this.lenguage);

            const lenguage = localStorage.getItem('lenguage');

            this.lenguage = lenguage;

        },
        tranformtext(index) {
            if (index == "pt") {
                return 'Português';
            } else if (index == "en") {
                return 'English'
            } else if (index == "es") {
                return 'Español'
            }
        },
        changeSideBarState(){
            this.storeSideBarState({sideBarState:!this.sideBarState})
        }
    },
    created() {

        this.typeUser = store.state.typeId;
        const savedActiveIndex = localStorage.getItem('activeIndex');
        const savedSecondoActiveIndex = localStorage.getItem('activeSecondIndex');
        if (savedActiveIndex !== null) {
            this.activeIndex = parseInt(savedActiveIndex, 10);
        }
        if (savedSecondoActiveIndex !== null) {
            this.secondoactiveindex = parseInt(savedSecondoActiveIndex, 10);
        }

        const lenguage = localStorage.getItem('lenguage');

        if (lenguage == null) {
            this.menu = require('../../locale/pt/sidebar.json');
            
        } else {

            this.menu = require('../../locale/pt/sidebar.json');
        }
    }
}
</script>
<style scoped>
@import './sidebar.css';
span.link-icon svg.svg-inline--fa {
    color: white !important;
}
.link.subitem {
    margin-left: 13%;
    margin-top: 2%;
    margin-bottom: 2%;
}
.link.dad {
    height: 46px;
    overflow: hidden;
    transition: all 500ms linear;
}
.link.dad:hover {
    overflow: visible;
    height: 320px;
}
.link.dad > a.link-content.primary {
    cursor: default;
}
.dashboard.dark img.logo_black {
    display: none;
}
.dashboard img.logo_white {
    display: none;
}
.dashboard.dark img.logo_white {
    display: block;
}
@media (max-width: 500px) {
    .sidebar.primary {
        width: 60px;
    }
    .sidebar.primary .sidebar-content .content-link .link .link-content .link-text {
        display: flex;
        align-items: center;
        width: 100% !important;
        justify-content: space-between;
        margin-left: 0.8em;
    }
    .sidebar.primary .sidebar-content .content-link .link .link-content:hover .link-icon {
        transform: translateX(0);
        transition: .2s ease;
        transition-delay: .15s;
    }
    .link .link-icon {
        display: inline-block;
    }
    .sidebar.primary .sidebar-content .content-link .link .link-content:hover .link-icon {
        transform: none !important;
        transition: .2s ease;
        transition-delay: .15s;
    }
    .sidebar.primary .sidebar-content .content-link .link .link-content:hover .link-text>span.text {
        background-color: none !important;
    }
    .icon-close {
        color: #fff;
        padding: 5px;
    }
    .header-logo.logo {
        align-self: flex-start;
    }
}
.sidebar.primary.show {
    width: 200px;
}
svg.svg-inline--fa.fa-xmark.icon-close {
    position: absolute;
    right: 0;
}
</style>