<template>
  <div class="container doubts-container weeducAdminDoubt">
    <div v-if="loading" class="loader-container">
      <div class="loader"></div>
    </div>
    
    <div v-else>
      <div class="titleContainer myCoursesWeeduc">
        <div class="title-wrapper">
          <h3>{{ doubt.title }}</h3>
          <p style="font-size: 14px;margin-bottom: 0;">Dúvida aberta por: {{ doubt.userName }}</p>
        </div>
        <div class="status-wrapper">
          <span class="status" :class="{ 'not-answered': doubt.status === 0, 'answered': doubt.status === 1 }">
            {{ getStatusText(doubt.status) }}
          </span>
          <span class="date">Criada em: {{ formatDate(doubt.dateInsert) }}</span>
        </div>
      </div>

      <div class="form-container">
        <div class="form-row">
          <div class="form-group col-12">
            <!-- <div class="meta-info">
              <span class="course">Curso: {{ courseName }}</span>
            </div> -->

            <div class="chat-container">
              <div class="chat-messages" ref="chatMessages">
                <div v-if="messages.length">
                  <div 
                    v-for="(message, index) in messages" 
                    :key="index"
                    :class="['message', message.idUser === doubt.idTeacher ? 'teacher' : 'student']"
                  >
                    <div class="message-header">
                      <div class="user-info">
                        <img :src="message.userFinalPhoto || '/default-avatar.png'" class="avatar" />
                        <span class="name">{{ message.userName }}</span>
                      </div>
                      <span class="time">{{ formatDate(message.dateInsert) }}</span>
                    </div>
                    <div class="message-body">
                      {{ message.message }}
                    </div>
                  </div>
                </div>
                <div v-else class="no-messages">
                  <i class="far fa-comments"></i>
                  <p>Nenhuma mensagem encontrada</p>
                </div>
              </div>

              <div class="chat-input" v-if="doubt.status !== 3">
                <textarea
                  v-model="newReply"
                  placeholder="Digite sua resposta..."
                  rows="3"
                  :disabled="loading"
                 
                ></textarea>
               
                <button 
                  class="btn-weflix primary"
                  @click="sendReply"
                  :disabled="loading || !newReply.trim()"
                >
                  <fa icon="fas fa-paper-plane"></fa>
                  {{ loading ? 'Enviando...' : 'Enviar' }}
                </button>
                
              </div>              
              <div v-else class="chat-closed">
                <i class="fas fa-lock"></i>
                <p>Esta dúvida foi deletada</p>
              </div>
             
            </div>

            <!-- <div class="button-container" v-if="isActive">
              <button 
                class="btn-weflix"
                @click="closeDoubt"
                :disabled="loading"
              >
                <i class="fas fa-check-circle"></i>
                Fechar Dúvida
              </button>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ApiInquiryMessage from '@/utils/mensage';


export default {
  data() {
    return {
      doubtId: this.$route.params.slug,
      messages: [],
      newReply: '',
      loading: true,
      isActive: true,
      doubt: {
        idInquiry: '',
        idMessage: null,
        idUser: '',
        title: '',
        description: null,
        status: 0,
        dateInsert: '',
        userName: ''
      }
    };
  },
  computed: {
    ...mapState(['token', 'doubt']),
  },
  methods: {
    getStatusText(status) {
      switch(status) {
        case 0:
          return 'Não Respondida';
        case 1:
          return 'Respondida';
        case 3:
          return 'Deletada';
        default:
          return 'Status Desconhecido';
      }
    },
    async getMessages() {
      this.loading = true;
      const id = this.$route.params.slug;
      try {
        const response = await ApiInquiryMessage.getDataMensageByInquiryId(this.token, id);
        this.messages = response.messages;
        this.doubt = response.inquiry;
      
      } catch (error) {
        console.error('Erro ao carregar mensagens:', error);
      } finally {
        this.loading = false;
      }
    },
    async sendReply() {
      if (!this.newReply.trim()) return;
      
      try {
        const payload = {
          message: this.newReply,
        };
        
        const response = await ApiInquiryMessage.createNewMessage(payload, this.doubtId, this.token);
        
        if(response) {
          // Adiciona apenas a nova mensagem ao array
          this.messages.push({
            message: this.newReply,
            dateInsert: new Date(),
            idUser: this.doubt.idTeacher,
            userName: 'Professor', // Ajuste conforme necessário
            userFinalPhoto: null // Ajuste conforme necessário
          });
          
          this.newReply = '';
          this.scrollToBottom();
        }
      } catch (error) {
        console.error('Erro ao enviar resposta:', error);
      }
    },
    async closeDoubt() {
      if (!confirm('Tem certeza que deseja fechar esta dúvida?')) return;
      
      this.loading = true;
      try {
        // Implementar chamada API para fechar dúvida
        this.isActive = false;
      } catch (error) {
        console.error('Erro ao fechar dúvida:', error);
      } finally {
        this.loading = false;
      }
    },
    formatDate(date) {
      return new Date(date).toLocaleString();
    },
    scrollToBottom() {
      setTimeout(() => {
        const container = this.$refs.chatMessages;
        container.scrollTop = container.scrollHeight;
      }, 100);
    }
  },
  created() {
    this.getMessages();
  }
}
</script>

<style scoped>
svg.svg-inline--fa.fa-paper-plane {
  color: white;
}
.form-group.col-12 {
    margin: 0 !important;
}
.content.dashboard > .container {
    padding-bottom: 0;
}
.container {
  max-width: 100%;
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  margin: 0 auto;
}

.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
}

.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #2f2e8b;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 1rem;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.titleContainer {
  position: relative;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid #f0f0f0;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title-wrapper {
  flex: 1;
}

.status-wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.titleContainer h3 {
  font-size: 1.75rem;
  font-weight: 700;
  color: #000000;
  margin-bottom: 0.75rem;
}

.form-container {
  padding: 0 1.5rem;
}

.form-group {
  margin-bottom: 1.5rem;
  width: 100%;
}

.form-row {
  display: flex;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
  flex-wrap: wrap;
  align-items: flex-start;
}

.meta-info {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
  align-items: center;
}

.status {
  padding: 0.625rem 1.25rem;
  border-radius: 50px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
}

.status.not-answered {
  background-color: #ffd700;
  color: #8b4513;
}

.status.answered {
  background-color: #7bcc8180;
  color: #4b614e;
}

.chat-container {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.08);
  overflow: hidden;
  margin-bottom: 1.5rem;
}

.chat-messages {
  height: 40vh;
  overflow-y: auto;
  padding: 2rem;
  scrollbar-width: thin;
  scrollbar-color: #cbd5e0 #f7fafc;
}

.chat-messages::-webkit-scrollbar {
  width: 6px;
}

.chat-messages::-webkit-scrollbar-track {
  background: #f7fafc;
}

.chat-messages::-webkit-scrollbar-thumb {
  background-color: #cbd5e0;
  border-radius: 3px;
}

.message {
  margin-bottom: 2rem;
  max-width: 75%;
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

.message.student {
  margin-right: auto;
}

.message.teacher {
  margin-left: auto;
}

.message-header {
  margin-bottom: 0.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-info {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.avatar {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #e5e7eb;
}

.name {
  font-weight: 600;
  color: #1f2937;
  font-size: 1rem;
}

.time {
  font-size: 0.875rem;
  color: #6b7280;
}

.message-body {
  padding: 1.25rem;
  border-radius: 12px;
  line-height: 1.6;
  font-size: 1rem;
  box-shadow: 0 1px 3px rgba(0,0,0,0.05);
}

.message.student .message-body {
  background: #f3f4f6;
  color: #1f2937;
}

.message.teacher .message-body {
  background: #eff6ff;
  color: #1e40af;
}

.chat-input {
  padding: 1.5rem;
  border-top: 2px solid #e5e7eb;
  background: #f9fafb;
  display: flex;
  justify-content: space-between;
  align-items: self-end;
  gap: 10px;
}

textarea {
  width: 100%;
  padding: 1rem;
  border: 2px solid #e2e8f0;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.3s ease;
  margin-bottom: 0;
  resize: vertical;
  min-height: 100px;
}

textarea:focus {
  outline: none;
  border-color: #2f2e8b;
  box-shadow: 0 0 0 3px rgba(47, 46, 139, 0.1);
}

.btn-weflix {
  padding: 0.75rem 1.5rem;
  border: 2px solid #e2e8f0;
  border-radius: 8px;
  background: white;
  color: #4a5568;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  gap: 0.75rem;
  font-weight: 600;
  font-size: 1rem;
}

.btn-weflix.primary {
  background: #2f2e8b;
  color: white;
  border: none;
}

.btn-weflix:hover:not(:disabled) {
  background: #23225f;
  transform: translateY(-1px);
}

.btn-weflix:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
}

.no-messages,
.chat-closed {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem 2rem;
  color: #6b7280;
  text-align: center;
}

.no-messages i,
.chat-closed i {
  font-size: 3.5rem;
  margin-bottom: 1.5rem;
  opacity: 0.6;
}

@media (max-width: 768px) {
  .container {
    padding: 1rem;
  }

  .form-container {
    padding: 0 1rem;
  }

  .meta-info {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .message {
    max-width: 90%;
  }

  .chat-messages {
    height: 50vh;
    padding: 1rem;
  }

  .button-container {
    flex-direction: column;
    gap: 0.75rem;
  }

  .btn-weflix {
    width: 100%;
    justify-content: center;
  }
}
.course {
   height: auto;
}
/* DARK */
.dashboard.dark .content.dashboard > .container.weeducAdminDoubt {
    background-color: #000;
}
.dashboard.dark .container.doubts-container.weeducAdminDoubt .title-wrapper p {
    color: white !important;
}
.dashboard.dark .container.doubts-container.weeducAdminDoubt .chat-container {
  background-color: #575757 !important;
}
.dashboard.dark .container.doubts-container.weeducAdminDoubt .chat-input {
  background-color: #575757;
}
.dashboard.dark .container.doubts-container.weeducAdminDoubt .no-messages, .chat-closed {
  color: white;
}
.dashboard.dark .container.doubts-container.weeducAdminDoubt .chat-input textarea {
  background-color: #444 !important;
  color: white !important;
}
.dashboard.dark .container.doubts-container.weeducAdminDoubt span.date {
  color: white;
}
.dashboard.dark .container.doubts-container.weeducAdminDoubt .user-info .name {
  color: white;
}
.dashboard.dark .container.doubts-container.weeducAdminDoubt .time {
  color: white;
}
</style>
