<template>
    <div class="gloval_successsPage">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="processando" v-if="typePayment==13" >
                        <h1 v-show="paidPix == false">Pagamento em Processamento...</h1>
                        <h1 v-show="paidPix == true" class="aprove">Seu pedido foi processado com sucesso!</h1>
                        
                        <p class="infoEamil" v-show="paidPix == false" >Realize seu pagamento escaneando o QR code abaixo ou usando pix Copia e Cola.</p>
                        <p class="infoEamil" v-show="paidPix == true" >Seu pedido foi pago com sucesso, acesse seu email para mais informações.</p>

                        <div class="infoInvoice" v-show="paidPix == false" >
                            <p>Pedido: #{{ dataPayment.invoiceNumber }} - {{formatarData(dataPayment.dateCreated)}} - Status: Aguardando Pagamento</p>
                        </div>

                        <div class="infoInvoice" v-show="paidPix == true" >
                            <p>Pedido: #{{ dataPayment.invoiceNumber }} - {{formatarData(dataPayment.dateCreated)}} - Status: Pagamento Aprovado</p>
                        </div>

                        <div class="paymentPix" >
                            <div class="pixCopiaCola">
                                <div class="paymentPixBox">
                                    <p class="codePix">{{dataPayment.pixQRCode.payload}}</p>
                                    <button class="btn-copy" @click="copyToClipboard"><i class="pi pi-copy"></i></button>
                                </div>
                                <p class="messagecopy" v-show="showMenssageSucessCopy">Chave pix copiada com sucesso!</p>

                            </div>
                            <div class="qrCodePix">
                                <div class="codeImage">
                                    <img :src="base64Pix" alt="Image"/>

                                </div>
                                <div class="qrCodeInfo">
                                    <ul>
                                       
                                        <li>
                                            <i class="pi pi-mobile"></i>Abrao app do seu banco ou instituição financeira e entre no ambiente Pix.
                                        </li>
                                        <li>
                                            <i class="pi pi-qrcode"></i>Escolha a opção Pagar com QR Code e escaneie o código ao lado.
                                        </li>
                                        <li>
                                            <i class="pi pi-shopping-cart"></i>Confirme as informações e finalize o pagamento.
                                        </li>
                                        <li class="notPaidPix"  v-show="paidPix == false">
                                            <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>Aguardando Pagamento
                                        </li>
                                        <li class="paidPix"  v-show="paidPix == true">
                                            <i class="pi pi-check"></i>Pagamento aprovado
                                        </li>
                                       
                                    </ul>
                                </div>
                            </div>
                            <div class="infoPixPayment">
                                <p>O pagamento podem levar até 5 minutos para validação de pagamento</p>
                                <a @click="redirect" v-show="paidPix == true" class="btn">Ir para o Login</a>
                            </div>
                        </div>

                        <button @click="(()=> this.paidPix =  !this.paidPix)">Change</button>

                       
                    </div>

                    <div class="successPage boleto" v-if="typePayment == 11">
                        <h1>Seu pedido foi realizado com sucesso!</h1>
                        <p class="infoEamil">Um email de confirmação do pedido foi enviado para o email 'seuemail.com.br'</p>
                        <div class="infoInvoice">
                            <p>Pedido: #{{ dataPayment.invoiceNumber }} - {{formatarData(dataPayment.dateCreated)}} - Status: Aguardando Pagamento</p>
                        </div>
                        
                        <div class="paymentBoleto">
                            <div class="boletoInfo">
                                <p>Seu boleto foi gerado com sucesso!</p>
                                <p>Imprima o boleto e pague em qualquer agência bancária ou lotérica até a data de vencimento.</p>
                                <a :href="dataPayment.bankSlipUrl" target="_blank" class="btn-copy"><i class="pi pi-print"></i>Imprimir Boleto</a>
                                <div class="qrCodePix">
                                    <div class="codeImage">
                                        <img src="../../../assets/boleto-logo.png" alt="Image"/>
                                    </div>
                                    <div class="qrCodeInfo">
                                        <ul>
                                            <li>
                                                <i class="pi pi-print"></i> Clique no botão abaixo para visualizar e imprimir o seu boleto bancário.
                                            </li>
                                            <li>
                                                <i class="pi pi-wallet"></i>Você pode pagar seu boleto em qualquer banco, casa lotérica ou através do seu internet banking. O prazo para pagamento é de até 2 dias úteis.
                                            </li>
                                            <li>
                                                <i class="pi pi-check"></i>O prazo de entrega do seu pedido começa a contar a partir da confirmação do pagamento do boleto.
                                            </li>
                                            <li>
                                                <i class="pi pi-times-circle"></i>Caso o boleto não seja pago até a data de vencimento, seu pedido será automaticamente cancelado.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <a @click="redirect" class="btn boleto">Ir para o Login</a>
                    </div>

                    <div class="successPage" v-if="typePayment == 12">
                        <h1>Seu pedido foi realizado com sucesso!</h1>
                        <p class="infoEamil">Um email de confirmação do pedido foi enviado para o email 'seuemail.com.br'</p>
                        <div class="infoInvoice">
                            <p>Pedido: #{{ dataPayment.invoiceNumber }} - {{formatarData(dataPayment.dateCreated)}} - Status: Pagamento Aprovado</p>
                        </div>
                        
                        <div class="paymentBoleto">
                            <div class="boletoInfo">
                               
                                <div class="qrCodePix">
                                    <div class="codeImage">
                                        <img src="../../../assets/card.png" alt="Image"/>
    
                                    </div>
                                    <div class="qrCodeInfo">
                                        <ul>
                                            <li>
                                                <i class="pi pi-check"></i> Seu pagamento foi aprovado com sucesso.
                                            </li>
                                            <li>
                                                <i class="pi pi-calendar"></i>Você receberá um e-mail com os detalhes da sua compra e o comprovante de pagamento. Mantenha esse e-mail para futuras referências.
                                            </li>
                                            <li>
                                                <i class="pi pi-phone"></i>Em caso de dúvidas ou problemas com seu pedido, entre em contato com nosso suporte pelo telefone (XX) XXXX-XXXX.
                                            </li>                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <a @click="redirect" class="btn card">Ir para o Login</a>
                    </div>

                </div>
            </div>
        </div>
        <section class="footer">
            <div class="logo-footer">
                <img src="../../../assets/logo.png"/>
            </div>
            <p class="copyright-footer">@Copyright - Weeeduc | 2024 Todos os direitos reservados</p>
        </section>
    </div>
    
</template>

<script>
import { mapState, mapActions } from 'vuex';
import api from '@/utils/website'

export default {
   data() {
      return {
         message: 'Success',
         base64Pix:null,
         pixCode:null,
         paidPix:false,
         showMenssageSucessCopy:false,
      }
   },
   computed: {
      ...mapState(['dataPayment','typePayment','dataWebsite','dataUserPayment','courseCart'])
   },
   methods: {
    ...mapActions(['logout']),
        async getStatusPaymentPix(){
            if(this.typePayment != 13){
                return;
            }
            try {
                const response = await api.checkPaymentPix(this.dataPayment.invoiceNumber);
                console.debug(response);
                if( response.status!='PENDING'){
                    this.paidPix = true;
                }
            } catch (error) {
                console.error(error);
            }
        },
     
      redirect() {
         this.$router.push({ name: 'Login' });
         this.logout();
      },
      copyToClipboard() {
        navigator.clipboard.writeText(this.pixCode).then(() => {
           this.showMenssageSucessCopy = true;
           setTimeout(() => {
              this.showMenssageSucessCopy = false;
           }, 3000);
        })
        },
        formatarData(data) {
        const dataObj = new Date(data);
        const ano = dataObj.getFullYear();
        const mes = ('0' + (dataObj.getMonth() + 1)).slice(-2);
        const dia = ('0' + dataObj.getDate()).slice(-2);
        return `${dia}/${mes}/${ano}`;
    },

        //Tag Manager
        loadDataTagManager(){
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer',this.dataWebsite.tagManagerCode);

        },

        //Tag Manager Success
        loadDataTagManagerPayment(){
            dataLayer.push({
                event: "purchase",
                ecommerce: {
                'transaction_id': this.dataPayment.id,
                'affiliation': this.dataUserPayment.name,
                'payment_type': this.dataPayment.billingType,
                'value': this.dataUserPayment.value,
                'tax': 0.0,
                'currency': 'BRL',
                'user_id': 1,
                'user_data': {
                    'email_address': this.dataUserPayment.email,
                    'phone_number': this.dataUserPayment.number,
                    'address': [{
                    'first_name':  this.dataUserPayment.name,
                    'last_name':  this.dataUserPayment.name,
                    }]
                },
                items: [{
                    'item_id': this.courseCart.id,
                    'item_name': this.courseCart.comercialName,
                    'price': this.courseCart.price,
                    'quantity': 1
                }]
                }
            });
        },

        //Google Analytics
        addGtagScript() {
            const script = document.createElement('script');
            script.async = true;
            script.src = "https://www.googletagmanager.com/gtag/js?id="+this.dataWebsite.googleAnalyticsCode;
            document.head.appendChild(script);
        },
        initGtag() {
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', this.dataWebsite.googleAnalyticsCode);
        },

        //Facebook Pixel
        loadDataFacebbokPixel(){
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window,document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', this.dataWebsite.facebookPixelCode); 
            fbq('track', 'PageView');
        },
        //Facebook Pixel Success
        loadFacebookPixelPayment(){
            fbq('track', 'Purchase', {
            value: this.dataUserPayment.value, 
            currency: 'BRL' 
        });
        },
        //Google Ads Pixel
        addGadsScript() {
            const script = document.createElement('script');
            script.async = true;
            script.src = "https://www.googletagmanager.com/gtag/js?id="+this.dataWebsite.googleAdsCode;
            document.head.appendChild(script);
        },
        initGads() {
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', this.dataWebsite.googleAdsCode);
        },

        //Google Ads Success
        loadGoogleAds() {
            const script = document.createElement('script');
            script.async = true;
            script.src = "https://www.googletagmanager.com/gtag/js?id=" + this.dataWebsite.googleAdsCode;
            document.head.appendChild(script);

            script.onload = () => {
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', this.dataWebsite.googleAdsCode);
            };
        },
        trackGoogleAdsConversion() {
            function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', this.dataWebsite.googleAdsCode);

            gtag('event', 'conversion', {
                'send_to': this.dataWebsite.googleAdsCode,
                'value': this.dataUserPayment.value,
                'currency': 'BRL'
            });
        }
   },
   mounted(){
    if(this.courseCart==null){
        this.$router.push({ name: 'Site' });
    } else {
        this.loadDataTagManager();
        this.loadDataTagManagerPayment();
        this.addGtagScript();
        this.initGtag();
        this.loadDataFacebbokPixel();
        this.loadFacebookPixelPayment();
        this.addGadsScript();
        this.initGads();
        this.loadGoogleAds();
        this.trackGoogleAdsConversion();
        if(this.paidPix == false){
            setInterval(this.getStatusPaymentPix, 10000);
        }
    }    
    },
   created() {
    if(this.courseCart==null){
        this.$router.push({ name: 'Site' });
    }
    if(this.typePayment==13){
        this.pixCode = this.dataPayment.pixQRCode.payload;
        this.base64Pix = 'data:image/png;base64,'+this.dataPayment.pixQRCode.encodedImage;
    }
      
   }
}
</script>

<style scoped>

.messagecopy
{
    color: green;
    font-size: 12px;
}
.qrCodePix {
    display: flex;
    background-color: white;
    max-width: 900px;
    margin: 0 auto;
    align-items: center;
    padding: 10px;
    box-shadow: 0px 0px 10px 1px #e1e1e1;
    border-radius: 20px;
}
.codeImage img {
    width: 300px;
}

.codeImage img {
    width: 250px;
}
.qrCodeInfo li {
    list-style: none;
    margin: 20px 0px;
    text-align: left;
}
.qrCodeInfo i {
    font-size: 25px;
    margin-right: 10px;
}
.gloval_successsPage {
    background-color: white;
    min-height: 100vh;
}
.codePix{
    background-color: orange;
    margin: 0 ;
    padding: 20px;
    border-radius: 10px;
}
.btn-copy {
    background-color: #ffa500;
    color: white;
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
    display: block;
    width: 170px;
    margin: 10px auto;
    text-decoration: none;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}
section.footer {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 0;
}
.infoInvoice p {
    margin: 0;
}
.successPage {
    padding-top:50px;
}
.successPage h1 {
    color: green;
}
.infoInvoice {
    background-color: #009c00;
    max-width: 550px;
    margin: 10px auto;
    color: white;
    padding: 10px;
    border-radius: 10px;
}
.paymentBoleto li {
    font-size: 14px;
}
.loaderPix{
    z-index: 999999;
    top: 50%;
    left: 50%;
    width: 48px;
    height: 48px;
    border: 5px solid #9f9f9f;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    z-index: 99999;
}
.loadingPix{
    text-align: center;
}
li.paidPix {
    color: green;
}
.paymentPixBox {
    display: flex;
    /* background-color: orange; */
    max-width: 850px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 10px;
    gap: 10px;
}
.processando {
    padding-top: 50px;
}
.aprove {
   color: green;
}
a.btn.boleto {
    margin-top: 35px;
}
a.btn.card {
    width: 200px;
    margin: 20px auto;
}
</style>