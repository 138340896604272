<template>
    <div class="container adminWeeducCreateFile">
        <div class="titleContainer myCoursesWeeduc">
            <h3>Novo Arquivo</h3>
            <p style="font-size: 14px;margin-bottom: 0;">Crie atividades com arquivos para os alunos</p>
        </div>

        <div class="form-container" v-if="!isSaving">
            <div class="form-row">
                <div class="form-group col-6">
                    <div class="form-group col-12">
                        <label for="title">Título*</label>
                        <input type="text" id="title" class="form-control" v-model="fileTitle" placeholder="Escreva um nome que chame a atenção" required />
                    </div>
                    <div class="form-group col-12">
                        <label for="description">Descrição</label>
                        <textarea id="description" class="form-control" v-model="fileDescription" placeholder="Cole aqui a URL do vídeo"></textarea>
                    </div>
                    <div class="form-group col-12">
                        <label for="file-upload">Arquivo</label>
                        <input type="file" id="file-upload" class="form-control" accept=".pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx" @change="handleFileUpload" />
                        <small>Formatos aceitos: PDF, Excel, Word ou PowerPoint</small>
                    </div>
                   
                </div>
                <div class="form-group col-6">
                    <div class="preview-container col-12" style="position: relative;" >
                        <Loader v-if="isLoading" />
                        <iframe v-if="filePreview && !isLoading" :src="filePreview" class="preview-pdf" frameborder="0"></iframe>
                    </div>
                </div>
            </div>
        </div>
        <Loader v-if="isSaving" />
        <div class="button-container">
            <button class="btn-weflix" @click="$router.go(-1)">Cancelar</button>
            <button class="btn-weflix primary" @click="saveFile">Salvar</button>
        </div>
    </div>
</template>

<script>
import apiLesson from '@/utils/lesson';
import api from '@/utils/lesson'
import apiQuiz from '@/utils/quiz'
import Loader from '@/components/Loader/loader.vue';
import apiAWS from '@/utils/storage/aws';
import { mapState } from 'vuex';

export default {
    components: {
        Loader
    },
    data() {
        return {
            fileTitle: '',
            fileDescription: '',
            filePreview: null,
            selectedFile: null,
            link: null,
            isLoading: false,
            isSaving: false
        };
    },
    computed: {
        ...mapState(['token','currentCollections', 'currentModule'])
    },
    methods: {
        async putFileAws(url, file){
          try {
              const response = await apiAWS.putFileAWS(url, file);
          } catch (error) {
              console.error('Erro ao enviar arquivo para AWS');
          }
        },
        async handleFileUpload(event){
            this.isLoading = true;
            try {
                const file = event.target.files[0];
                const response =  await apiAWS.getSignaturePdf(this.token, file.name);
                await this.putFileAws(response.url, file);
                this.link = response.token;
                this.selectedFile = file;
                this.filePreview = URL.createObjectURL(file);
            } catch (error) {
                console.error('Erro na geração do TOKEN AWS')
            } finally {
                this.isLoading = false;
            }
        },
        cancel() {
            this.fileTitle = '';
            this.fileDescription = '';
            this.filePreview = null;
            this.selectedFile = null;
        },
        async saveFile() {
            const payload = {
                    "type": 4,
                    "title": this.fileTitle,
                    "path": this.link,
                    "description": this.fileDescription,
                    "idQuiz": null,
                    "idModule": this.currentModule.id,
                    "idCourse": this.currentCollections.id,
                    "status": 1,
                }
                try {
                    this.isSaving = true;
                    const response = await apiLesson.createNewLesson(payload, this.token);
                } catch (error) {
                    console.error('Erro ao criar aula');
                } finally {
                    this.isSaving = false;
                    this.$router.push('/dashboard/curso');
                }
            }
            
        }
}
</script>

<style scoped>
.container {
    max-width: 100%;
    background-color: #ffffff;
    padding: 1.5rem;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.form-row {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.form-group {
    margin-bottom: 20px;
    flex: 1;
}

.button-container {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    margin-top: 30px;
}

.btn-weflix {
    padding: 0.5rem 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    background: white;
    color: #4a5568;
    cursor: pointer;
    transition: all 0.2s;
}

.btn-weflix.primary {
    background: #2f2e8b;
    color: white;
    border: none;
}

.preview-pdf {
    width: 100%;
    height: 70vh;
    border: none;
}
/* DARK */
.dashboard.dark .content.dashboard > .container.adminWeeducCreateFile {
    background-color: #000 !important;
}
.dashboard.dark .container.adminWeeducCreateFile h3, 
.dashboard.dark .container.adminWeeducCreateFile p,
.dashboard.dark .container.adminWeeducCreateFile label,
.dashboard.dark .container.adminWeeducCreateFile small {
    color: white !important;
}
.dashboard.dark .container.adminWeeducCreateFile .form-control,
.dashboard.dark .container.adminWeeducCreateFile .video-placeholder {
    background-color: #575757 !important;
    border: 1px solid #444 !important; 
}
.dashboard.dark .container.adminWeeducCreateFile .titleContainer {
    border-bottom: 1px solid #575757 !important;
}
</style>
