<template>
    <div class="resetPassword">
        <div class="headerResetPassword">
            <img :src="logoUrl" alt="Logo" class="logo"/>
        </div>
        <div class="resetPasswordSection" @keydown.enter="resetPassword">
            <h1 class="titleResetPassword">Redefinir Senha</h1>
            <div class="inputResetPassword">
                <v-text-field label="Nova Senha" variant="underlined" :type="typeInput" v-model="password">
                    <fa v-show="typeInput==='password'" icon="fa-eye" @click="changeViewPassword"/>
                    <fa v-show="typeInput!='password'" icon="fa-eye-slash" @click="changeViewPassword"/>
                </v-text-field>
                <v-text-field label="Confirme sua nova senha" :type="typeInput" variant="underlined" v-model="rePassword">
                    <fa v-show="typeInput==='password'" icon="fa-eye" @click="changeViewPassword"/>
                    <fa v-show="typeInput!='password'" icon="fa-eye-slash" @click="changeViewPassword"/>
                </v-text-field>
            </div>
            <div class="submitResetPassword">
                <button class="active" @click="resetPassword">Redefinir Senha</button>
            </div>
            <a href="#" class="backLink" @click.prevent="goToHome">Voltar ao Site</a>
        </div>
    </div>
</template>

<script>
import apiLogin from '@/utils/Weflix/Website/index';
import { mapState } from 'vuex';
import axios from 'axios';

export default {
    data(){
        return{
            typeInput:'password',
            password:'',
            rePassword:'',
            token: this.$route.query.token || '',
            logoUrl: ''
        }
    },
    created() {
        this.fetchLogo();
        this.setWebsiteDetails();
    },
    computed:{
        ...mapState(['finalWebsite'])
    },
    methods:{
        changeViewPassword(){
            this.typeInput = this.typeInput == 'password' ? 'text' : 'password';
        },
        async resetPassword(){
            if (this.password !== this.rePassword) {
                alert('As senhas não coincidem. Por favor, tente novamente.');
                return;
            }

            const payload = {
                "password": this.password,
                "token": this.$route.path.split('/redefinir-senha/')[1]

            }

            try {
                await apiLogin.resetPassword(payload);
                alert('Senha redefinida com sucesso.');
                this.goToHome();
            } catch (error) {
                console.error('Erro ao redefinir senha:', error);
                alert('Erro ao redefinir senha. Token inválido, por favor, tente novamente.');
            }
        },
        goToHome(){
            this.$router.push('/');
        },
        async fetchLogo() {
            try {
                const response = await axios.post('https://api-weeduc.sistematutor.com.br/ecommerce/logo', {
                    url: window.location.origin === 'http://localhost:8080' ? process.env.BASE_URL : window.location.origin,
                });
                this.logoUrl = 'https://image.weeduc.com/' + response.data.logo;
            } catch (error) {
                console.error('Erro ao buscar logo:', error);
            }
        },
        setWebsiteDetails() {
            if (this.finalWebsite) {
                document.title = this.finalWebsite.title || 'Redefinir Senha';
                const link = document.querySelector("link[rel~='icon']");
                if (link) {
                    link.href = this.finalWebsite.faviconFinal || '';
                } else {
                    const newLink = document.createElement('link');
                    newLink.rel = 'icon';
                    newLink.href = this.finalWebsite.faviconFinal || '';
                    document.head.appendChild(newLink);
                }
            }
        }
    }
}
</script>
<style scoped>
.v-field__input {
    position: relative;
}
</style>
<style scoped>
.resetPassword {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    width: 100%;
    min-height: 100vh;
    padding: 20px;
}
.headerResetPassword {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 10px 0;
}
.logo {
    max-width: 150px!important;
    height: auto;
    width: 150px!important;
}
.resetPasswordSection {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    background-color: white;
}
.inputResetPassword {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
}
.titleResetPassword {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
    text-align: center;
}
.submitResetPassword button {
    color: #fff;
    background-color: #000;
    padding: 15px;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 700;
    width: 100%;
    cursor: pointer;
}
.backLink {
    display: block;
    margin-top: 20px;
    text-align: center;
    color: gray;
    text-decoration: none;
    font-size: 16px;
}
.inputResetPassword svg.svg-inline--fa {
    color: gray;
    cursor: pointer;
    font-size: 20px;
    margin-left: 10px;
    position: absolute;
    right: 0;
}
@media (max-width: 600px) {
    .resetPasswordSection {
        padding: 15px;
    }
    .titleResetPassword {
        font-size: 20px;
    }
    .submitResetPassword button {
        font-size: 16px;
    }
}
</style>
