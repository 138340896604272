<template>
    <div class="dashboard">       
      <Sidebar/>
      <div class="page">
        <!-- <Toolbar /> -->
        <div class="content dashboard superAdmin">
          <div class="container">
            <div class="titleContainer">
              <div class="primaryHeader">
                <h3>Lista de Empresas</h3>
                <p>
                  Lista de todas as empresas cadastradas.
                </p>
              </div>
            </div>

            <div class="container" v-if="hasReady">
                <table class="table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Nome</th>
                            <th class="companyListSuperAdmin" style="width: 300px;">CNPJ</th>
                            <th style="width: 200px;">
                              <select v-model="selectedType" @change="filterByType" :focus-visible="false">
                                <option value="">Sistema</option>
                                <option value="1">Weeduc</option>
                                <option value="2">Weflix</option>
                              </select>
                            </th>
                            <th style="width: 200px;">Telefone</th>
                            <th style="width: 150px;">Data de Criação</th>
                            <th style="width: 50px;">Ver</th>
                        </tr>
                    </thead>
                    <tbody> 
                        <tr v-for="company in paginatedCompanies" :key="company.id">
                            <td>{{company.id}}</td>
                            <td>{{company.name}}</td>
                            <td class="companyListSuperAdmin">{{company.idFiscal ? company.idFiscal.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5") : ''}}</td>
                            <td>{{company.typeSystem == 1 ? 'Weeduc' : 'Weflix'}}</td>
                            <td>{{company.phone ? company.phone.replace(/^(\d{2})(\d{4,5})(\d{4})$/, "($1) $2-$3") : ''}}</td>
                            <td>{{company.dateInsert ? new Date(company.dateInsert).toLocaleDateString('pt-BR') : ''}}</td>
                            <td>
                              <router-link :to="`/editar-empresa/${company.id}`">
                                <fa icon="fa-solid fa-eye" />
                              </router-link>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div class="pagination">
                  <button 
                    :disabled="currentPage === 1"
                    @click="currentPage--"
                    class="pagination-btn"
                  >
                    Anterior
                  </button>
                  <span>Página {{currentPage}} de {{totalPages}}</span>
                  <button 
                    :disabled="currentPage === totalPages"
                    @click="currentPage++"
                    class="pagination-btn"
                  >
                    Próxima
                  </button>
                </div>
            </div> 
          </div>
        </div>
        <Loader :hasReady="hasReady"/>
      </div>
      <Footer/>
    </div>
  </template>
  
  <script>
    import Sidebar from '@/components/Weflix/Sidebar-SuperAdmin.vue'
    import Footer from '@/components/Admin/Footer/footerSuperAdmin.vue'
    import { mapState, mapActions } from 'vuex';
    import superAdmin from '@/utils/superAdmin';
    import Loader from '@/components/Weflix/Loader.vue';

  
    export default {
      data() {
        return {
          listCompanys: [],
          selectedType: '',
          hasReady: false,
          currentPage: 1,
          itemsPerPage: 10
        }
      },
      components: {
        Sidebar,
        Footer,
        Loader
      },
      computed: {
        ...mapState(['token']),
        filteredCompanys() {
          if (!this.selectedType) {
            return this.listCompanys;
          }
          return this.listCompanys.filter(company => company.typeSystem == this.selectedType);
        },
        paginatedCompanies() {
          const start = (this.currentPage - 1) * this.itemsPerPage;
          const end = start + this.itemsPerPage;
          return this.filteredCompanys.slice(start, end);
        },
        totalPages() {
          return Math.ceil(this.filteredCompanys.length / this.itemsPerPage);
        }
      },
      methods: {
        async listCompany(){
          try {
            const response = await superAdmin.listCompany(this.token);
            this.listCompanys = response;
            this.hasReady = true;
          } catch (error) {
            console.error(error);
          }
        },
        filterByType() {
          this.currentPage = 1;
        }
      },
      created() {
        this.listCompany();
      }
    }
  </script>
  <style scoped>
  select:focus-visible {
    border: none !important;
    outline: none;
  }
  .pagination {
    display: flex;
    justify-content: initial;
    align-items: center;
    gap: 1rem;
    margin-top: 2rem;
  }
  .pagination-btn {
    padding: 0.5rem 1rem;
    border: 1px solid #250891;
    border-radius: 5px;
    background: white;
    color: #250891;
    cursor: pointer;
  }
  .pagination-btn:disabled {
    border-color: #ccc;
    color: #ccc;
    cursor: not-allowed;
  }
  </style>
  <style>
    .flex {
      display: flex;
      align-items: flex-start;
      gap: 0px;
      flex-wrap: wrap;
    }
    .dashboard {
      display: flex;
      flex-direction: column;
      height: 100%;
      background-color: #eef0fc;
      margin: 0 auto;
      position: relative;
    }   
    .content.dashboard {
      background-color: transparent;
      margin: 0;
      width: 100%;
      padding: 10px 10px;
      height: 100%;
    }
    .col-form-inner {
      padding: 0px;
  }
  .form-group {
      margin: 5px 10px !important;
  }
  textarea {
      height: 120px !important;
      font-family: "Poppins" !important;
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 28px !important;
      resize: none !important;
      outline: none !important;
      padding: 0.5rem 0.75rem !important;
      color: #5f5f5f !important;
      background-color: #fff !important;
      border: 1px solid #dbdcde !important;
      box-shadow: none !important;
      width: 100% !important;
      border-radius: 5px !important;
  }
  button.btn_new {
      color: #250891;
      width: 20%;
      background-color: #ffffff;
      padding: 6px 10px;
      border-radius: 5px;
      transition: all 500ms ease-in-out;
      font-size: 18px;
      border: 1px solid #250891;
  }
  .content.dashboard.superAdmin {
      margin-bottom: 70px;
  }
  hr {
      width: 95% !important;
      margin: 25px auto !important;
  }
  span.iconCopy {
      position: absolute;
      top: 10px;
      right: 5px;
      cursor: pointer;
      z-index: 9;
  }
  svg.svg-inline--fa.fa-check {
      color: green;
  }
  svg.svg-inline--fa.fa-xmark {
      color: white;
  }
  .container {
      padding: 10px;
  }
  </style>
  <style scoped>
    @media (max-width: 600px) {
      .container {
        overflow-y: auto;
      }
      .companyListSuperAdmin {
        display: none;
      }
    }
  </style>