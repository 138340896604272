<template>
    <Banner :view="ViewMode" :data="dataCourses"></Banner>
    <Content :view="ViewMode" :data="dataCourses" :hasReady="hasReady"></Content>
</template>
<script>

import apiCourses from '@/utils/portal/course.js';
import { mapState, mapActions } from 'vuex';


import Banner from '@/components/Student/Dashboard_Unique/Banner/Banner'
import Content from '@/components/Student/Dashboard_Unique/Content/Content'


export default{
    data(){
        return{
            ViewMode:false,
            dataCourses:null,
            hasReady:false,
        }
    }, 
    computed:{
        ...mapState(['token','listCoursesPortal'])
    },
    methods: {
        ...mapActions(['storeListCouresesPortal']),
        alterarValor(novoValor) {
            this.ViewMode = !this.ViewMode;
        },
        async getDataCourses(){
            try {
            const response = await apiCourses.getDataCourses(this.token);
            this.storeListCouresesPortal({listCoursesPortal:response})
            this.dataCourses = response;
            this.hasReady = true;
            } catch (error) {
                console.error('Erro ao obter cursos');
            }
            
        }
    },
    watch: {
        ViewMode(novoValor) {
            this.ViewMode = novoValor;
        }
    },
    components:{
        Banner,
        Content
    },
    created(){
        this.getDataCourses();
    }
}
</script>
<style scoped>  
.studentPortal {
    min-height: 100vh !important;
}</style>