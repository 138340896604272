<template>
    <section class="section link-list primary">
      <div class="link-list-content">
        <div class="content-link create-course">
          <div class="link">
            <a
              class="link-content"
              :class="[isActiveTab(1)? 'active': '']"
              @click="setActiveTab(1)"
            >
              Perfil
            </a>
          </div>
          <div class="link">
            <a
              class="link-content last"
              :class="[isActiveTab(2)? 'active': '']"
              @click="setActiveTab(2)"
            >
              Senha
            </a>
          </div>
        </div>
      </div>
    </section>

     <section
      class="section config-form primary"
      :class="[isActiveTab(1)? 'active': '']"
    >
      <User />
    </section>
    <section
    class="section config-form primary"
    :class="[isActiveTab(2)? 'active': '']"
  >
    <Password />
  </section>
  </template>
  
  <script>
    import User from '@/components/Weflix/Perfil/User.vue';
    import Password from '@/components/Weflix/Perfil/Password.vue';
    
  export default {
    data() {
      return {
        activeTab: 1,
      }
    },
    props: {
  
    },
    components: {
        User,
        Password
    },
    methods: {
      setActiveTab(tabNumber) {
        this.activeTab = tabNumber;
      },
      isActiveTab(tabNumber) {
        return this.activeTab === tabNumber;
      },
    
    },
    created() {
  
    }
  }
  </script>
  
  <style scoped>
  @import url(./global.css);

  .content-link.create-course {
    display: flex;
}
  .link a {
    display: inline-block;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: rgb(24, 24, 28);
    background-color: rgb(255, 255, 255);
    text-decoration: none;
    padding: 0.5rem 2rem;
    border-bottom: 1px solid rgb(246, 246, 246);
    border-right: 1px solid rgb(246, 246, 246);
    cursor: pointer;
}
.link-list-content .content-link .link .link-content.active {
    color: rgb(255, 255, 255);
    background-color: #250891;
    border-right: 1px solid #250891;
}
section.section.config-form.primary.active {
    display: block;
    height: 83vh;
    overflow-y: auto;
}
section.section.config-form.primary{
  display: none;
}
@media (max-width: 600px) {
  .content-link.create-course .link {
      width: 50% !important;
  }
   .content-link.create-course .link  a{
      width: 100% !important;
  }
  
}
  </style>
  