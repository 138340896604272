<template>
    <div class="loginRegister">
        <div class="headerLoginRegister">
                <span class="close" @click="redirect"><fa icon="fa-xmark"/>Voltar</span>
        </div>
        <div class="contentCheckout" v-if="itemCheckoutWeflix">
            <div class="planSubscription" v-if="itemCheckoutWeflix.type !== 0">
                <div class="plan">
                    <div class="planName">
                        <h4>Plano</h4>
                    </div>
                    <div class="planTitle">
                        <h4>{{itemCheckoutWeflix?.name}}</h4>
                        <small>{{itemCheckoutWeflix?.type === 1 ? 'Mensal' : itemCheckoutWeflix?.type === 2 ? 'Semestral' : itemCheckoutWeflix?.type === 3 ? 'Trimestral' : itemCheckoutWeflix?.type === 4 ? 'Anual' : ''}}</small>
                    </div>
                </div>
                <div class="planPrice">
                    <p><span class="price"><span class="installment">A VISTA POR </span><span class="priceSubscription">R${{ formatPrice(price).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }}</span></span></p>
                    <p v-if="installments>1 && finalWebsite.intallmentCheckout === 1 && price > 0"><span class="installment">EM ATÉ {{ installments }}x DE</span> <span class="priceSubscription">R$ {{ formatPrice(price / installments) }}</span></p>
                </div>
            </div>

           
            <!-- Novo componente de cupom -->
            <div class="couponSection" v-if="price > 0">
                <button class="toggleCoupon" @click="showCouponInput = !showCouponInput">
                    <fa icon="fa-tag" /> {{ showCouponInput ? 'Ocultar cupom' : 'Adicionar cupom' }}
                </button>
                <div v-if="showCouponInput" class="couponInput">
                    <input 
                        type="text" 
                        v-model="couponCode"
                        placeholder="Digite seu cupom"
                        class="form-control"
                    />
                    <button @click="validateCoupon" class="validateCoupon" :disabled="!couponCode">
                        Aplicar
                    </button>
                </div>
                <p v-if="couponMessage" :class="{'success-message': couponValid, 'error-message': !couponValid}">
                    {{ couponMessage }}
                </p>
            </div>
            <div class="paymentMethods" v-if="price > 0">
               <div class="paymentMethodsOptions" :class="paymentMethod == 1 ? 'creditcard' : paymentMethod == 2 ? 'boleto' : 'pix'">
                <div class="paymentImage mobile" >
                    <div class="card-preview" v-if="paymentMethod == 1">
                        <div class="card-number">{{ cardNumber || '**** **** **** ****' }}</div>
                        <div class="card-name">{{ cardName || 'NOME DO TITULAR' }}</div>
                        <div class="card-expiry">{{ cardExpiry || 'MM/AA' }}</div>
                        <div class="card-cvv">{{ cardCVV || '***' }}</div>
                    </div>
                    <img src="@/assets/Weflix/boleto2.png" class="imageBoleto" alt="payment" v-if="paymentMethod == 2">  
                    <img src="@/assets/Weflix/pix.png" class="imagePix" alt="payment" v-if="paymentMethod == 3">  
                    <div class="paymentAction desktop">
                        <button class="buttonCheckout payment active" @click="validateAndCheckout" >Finalizar Compra</button>
                    </div>
                </div>
                    <div class="paymentMethodOptionCreditCard" v-if="paymentMethod == 1">
                        <div class="flex inputCheckout">
                            <div class="form-group">
                                <div class="form-label">
                                  <label>Número do Cartão</label>
                                </div>
                                <div class="form-input">
                                  <input
                                    type="text" 
                                    required
                                    class="form-control"
                                    v-model="cardNumber"
                                    placeholder="1234 5678 9012 3456"
                                    v-mask="'#### #### #### ####'"
                                  />
                                </div>
                              </div>
                              <div class="form-group">
                                <div class="form-label">
                                  <label>Nome do Cartão</label>
                                </div>
                                <div class="form-input">
                                  <input
                                    type="text" 
                                    required
                                    class="form-control"
                                    v-model="cardName"
                                    placeholder="Nome como está no cartão"
                                  />
                                </div>
                              </div>
                        </div>
                        <div class="flex inputCheckout">
                            <div class="form-group">
                                <div class="form-label">
                                  <label>Validade</label>
                                </div>
                                <div class="form-input">
                                  <input
                                    type="text" 
                                    required
                                    class="form-control"
                                    v-model="cardExpiry"
                                    placeholder="MM/AA"
                                    v-mask="'##/##'"
                                  />
                                </div>
                              </div>
                              <div class="form-group">
                                <div class="form-label">
                                  <label>CVV</label>
                                </div>
                                <div class="form-input">
                                  <input
                                    type="text" 
                                    required
                                    class="form-control"
                                    v-model="cardCVV"
                                    placeholder="123"
                                    v-mask="'###'"
                                  />
                                </div>
                              </div>
                        </div>
                        <div class="flex inputCheckout">
                            <div class="form-group" v-if="price > 0">
                                <div class="form-label">
                                  <label>Parcelamento</label>
                                </div>
                                <div class="form-input">
                                    <select
                                      required
                                      class="form-control"
                                      v-model="selectedPlan"
                                    >
                                      <option :value="i" v-for="i in getInstallmentOptions()" :key="i">{{i}}x R$ {{formatPrice(price / i)}}</option>
                                    </select>
                                  </div>
                              </div>
                              <div class="form-group">
                                <div class="form-label">
                                  <label>CPF</label>
                                </div>
                                <div class="form-input">
                                  <input
                                    type="text" 
                                    required
                                    class="form-control"
                                    v-model="cpf"
                                    placeholder="123.456.789-00"
                                    v-mask="'###.###.###-##'"
                                  />
                                </div>
                              </div>
                        </div>
                    </div>
                    <div class="paymentImage desktop" >
                        <div class="card-preview" v-if="paymentMethod == 1">
                            <div class="card-number">{{ cardNumber || '**** **** **** ****' }}</div>
                            <div class="card-name">{{ cardName || 'NOME DO TITULAR' }}</div>
                            <div class="card-expiry">{{ cardExpiry || 'MM/AA' }}</div>
                            <div class="card-cvv">{{ cardCVV || '***' }}</div>
                        </div>
                        <img src="@/assets/Weflix/boleto2.png" class="imageBoleto" alt="payment" v-if="paymentMethod == 2">  
                        <img src="@/assets/Weflix/pix.png" class="imagePix" alt="payment" v-if="paymentMethod == 3">  
                        <div class="paymentAction">
                            <button class="buttonCheckout payment active" @click="checkoutPayment" :disabled="isButtonDisabled">Finalizar Compra</button>
                        </div>
                    </div>
                    <div class="paymentAction mobile">
                        <button class="buttonCheckout payment active" @click="checkoutPayment" :disabled="isButtonDisabled">Finalizar Compra</button>
                    </div>
               </div>
            </div>
            <div class="trial-activation" v-if="price === 0">
                <div class="trial-message">
                    <h3>Ative seu período de teste gratuito!</h3>
                    <p>Aproveite todos os recursos da plataforma sem compromisso durante o período experimental.</p>
                </div>
                <div class="trial-action">
                    <button class="buttonCheckout trial active" @click="activeTrial">
                        Ativar
                    </button>
                </div>
            </div>
            <div class="errorPayment" v-if="errorPayment"> 
                <p style="color: red;">{{errorPaymentMessage}}</p>
            </div>

            <!-- <div class="security">
                <h3>Compra 100% segura</h3>    
            </div> -->
        </div>

        <div v-else class="empty-cart">
            <div class="empty-cart-content">
                <fa icon="fa-shopping-cart" class="cart-icon" />
                <h3>Seu carrinho está vazio</h3>
                <p>Você não possui nenhum produto no carrinho</p>
                <router-link to="/" class="back-button">
                    <button class="buttonCheckout active">
                        Voltar para a loja
                    </button>
                </router-link>
            </div>
        </div>

        <footer class="checkout-footer">
            <div class="footer-content">
                <div class="footer-section">
                    <h4>Atendimento</h4>
                    <p>Seg a Sex - 9h às 18h</p>
                    <p>suporte@weflix.com</p>
                </div>
                <div class="footer-section">
                    <h4>Pagamento Seguro</h4>
                    <div class="payment-methods">
                        <fa icon="fa-lock" /> SSL Secure Payment
                    </div>
                </div>
                <div class="footer-section">
                    <h4>Políticas</h4>
                    <a href="#">Termos de Uso</a>
                    <a href="#">Política de Privacidade</a>
                </div>
            </div>
            <div class="footer-bottom">
                <p>&copy; 2024 Weflix. Todos os direitos reservados.</p>
            </div>
        </footer>
    </div>
    <div class="backgroundLoginRegister"></div>

    <!-- Popup de sucesso do trial -->
    <div class="trial-success-overlay" v-if="showTrialSuccess">
        <div class="trial-success-popup">
            <h3>Trial Ativado com Sucesso!</h3>
            <button class="buttonCheckout active" @click="goToSite">
                Ir para o site
            </button>
        </div>
    </div>

    <!-- Loader de pagamento -->
    <div class="payment-loader-overlay" v-if="isProcessingPayment">
        <div class="payment-loader">
            <fa icon="fa-spinner" class="fa-spin" />
        </div>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import ApiPayment from '@/utils/Weflix/Website/payment';

export default {
    data(){
        return{
            openModalLogin: false,
            typeLogin: 1,
            hasReadyToLogin: false,
            typeInput: 'password',
            remainingTime: 15 * 60,
            timerInterval: null,
            paymentMethod: 1,
            cardNumber: '',
            cardName: '',
            cardExpiry: '',
            cardCVV: '',
            cpf: '',
            selectedPlan: 1,
            installments: 0,
            errorPayment: false,
            showCouponInput: false,
            couponCode: '',
            couponValid: false,
            couponMessage: '',
            price: 0,
            errorPaymentMessage: 'Um ou mais erros ocorreram, tente novamente mais tarde ou utilize outro cartão.',
            trial: {
                days: 30 // Example trial days, adjust as necessary
            },
            showTrialSuccess: false,
            isProcessingPayment: false, // Adicionado para controlar o estado do loader
            isButtonDisabled: false // Nova propriedade para controlar o estado do botão
        }
    },
    computed:{
        ...mapState(['itemCheckoutWeflix', 'userPortalWeflix','finalWebsite','dataPaymentWeflix']),
        isFormValid() {
            if (this.price === 0) return true;
            
            if (this.paymentMethod === 1) {
                return this.cardNumber.length === 19 && 
                       this.cardName.length > 3 &&
                       this.cardExpiry.length === 5 &&
                       this.cardCVV.length === 3 &&
                       this.cpf.length === 14;
            }

            
            return true;
        }
    },
    methods:{
        ...mapActions(['storeUserPortalWeflix','storeDataPaymentWeflix']),
        startTimer() {
            this.timerInterval = setInterval(() => {
                if (this.remainingTime > 0) {
                    this.remainingTime--;
                } else {
                    this.stopTimer();
                }
            }, 1000);
        },
        stopTimer() {
            clearInterval(this.timerInterval);
        },
        formatTime(seconds) {
            const hours = Math.floor(seconds / 3600);
            const minutes = Math.floor((seconds % 3600) / 60);
            const remainingSeconds = seconds % 60;
            return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
        },
        setPaymentMethod(method){
            this.paymentMethod = method;
        },
        formatPrice(price){
            return price.toFixed(2).replace('.', ',');
        },
        redirect(){
            window.location = '/';
        },
        getInstallmentOptions() {
            if (!this.itemCheckoutWeflix) return [1];
            
            switch(this.itemCheckoutWeflix.type) {
                case 1: return [1];
                case 2: return Array.from({length: 6}, (_, i) => i + 1);
                case 3: return Array.from({length: 3}, (_, i) => i + 1);
                case 4: return Array.from({length: 12}, (_, i) => i + 1);
                default: return [1];
            }
        },
        calcularNumeroParcelas(price) {
            if (!this.itemCheckoutWeflix) {
                this.installments = 1;
                return;
            }

            switch(this.itemCheckoutWeflix.type) {
                case 1:
                    this.installments = 1;
                    break;
                case 2:
                    this.installments = Math.min(6, Math.floor(price / 5));
                    break;
                case 3:
                    this.installments = Math.min(3, Math.floor(price / 5));
                    break;
                case 4:
                    this.installments = Math.min(12, Math.floor(price / 5));
                    break;
                default:
                    this.installments = 1;
            }
        },
        async checkoutPayment(){
            this.isButtonDisabled = true; // Desabilita o botão
            this.isProcessingPayment = true; // Mostrar loader
            const payload = {
                "id": this.itemCheckoutWeflix.id,
                "installments": this.installments,
                "cupomCode": this.couponCode ? this.couponCode : "",
                "card": {
                    "number":this.cardNumber.replace(/[ ]/g, ''),
                    "holder_name": this.cardName,
                    "exp_month": this.cardExpiry.split('/')[0],
                    "exp_year": this.cardExpiry.split('/')[1],
                    "cvv": this.cardCVV,     
                    "billing_address": {
                        "line_1": this.userPortalWeflix.user.address,
                        "line_2": "Apt 1502",
                        "zip_code": this.userPortalWeflix.user.postcode?.replace(/[-]/g, '') || '',
                        "city": this.userPortalWeflix.user.city,
                        "state": this.userPortalWeflix.user.county,
                        "country": "BR"
                    },
                }
            }

            try {
                try {
                    const response = await ApiPayment.checkout(payload, this.userPortalWeflix.acessToken);
                    this.storeDataPaymentWeflix({dataPaymentWeflix:response});
                    if (response && response.user) {
                        const userPortalWeflixAux = {
                            ...this.userPortalWeflix,
                            user: response.user
                        };
                        this.storeUserPortalWeflix({userPortalWeflix:userPortalWeflixAux});
                    } else {
                        throw new Error('Resposta inválida do checkout');
                    }
                } catch (error) {
                    if (error.response && error.response.data) {
                        this.errorPaymentMessage = error.response.data.message;
                    } else {
                        this.errorPaymentMessage = 'Erro ao processar pagamento';
                    }
                    console.debug('Erro ao atualizar dados do usuário:', this.errorPaymentMessage);
                    throw error;
                }
                this.$router.push('/sucessoWeflix');
            } catch (error) {
                this.errorPayment = true;
                if (error.response && error.response.data) {
                    if(error.response.data.message && error.response.data.message.length > 0) {
                        this.errorPaymentMessage = error.response.data.message;
                    } else {
                        this.errorPaymentMessage = "Um ou mais erros ocorreram, tente novamente mais tarde ou utilize outro cartão.";
                    }
                } else {
                    this.errorPaymentMessage =  error.response.data.message;
                }
                console.error('Erro ao realizar checkout:', error);
                this.isButtonDisabled = false; // Reabilita o botão em caso de erro
            } finally {
                this.isProcessingPayment = false; // Esconder loader
            }
        },
        async validateCoupon(){
            try {
                const response = await ApiPayment.validateCoupon(this.couponCode, this.finalWebsite.company.id);
                
                if(response && response.discount) {
                    this.couponValid = true;
                    this.couponMessage = 'Cupom aplicado com sucesso!';
                    this.price = this.price - (this.price * response.discount / 100);
                    this.calcularNumeroParcelas(this.price);
                } else {
                    this.couponValid = false; 
                    this.couponMessage = 'Cupom inválido';
                }
            } catch (error) {
                this.couponValid = false;
                this.couponMessage = 'Erro ao validar cupom';
                console.error('Erro ao validar cupom:', error);
            }
        },
        async activeTrial(){
            this.showTrialSuccess = true;
            try {
                const response = await ApiPayment.activeTrial(this.userPortalWeflix.acessToken);
            } catch (error) {
                console.error('Erro ao ativar trial:', error);
            } finally {
                const updatedUser = {
                    ...this.userPortalWeflix,
                    user: {
                        ...this.userPortalWeflix.user,
                        orderStatus: 1
                    }
                };
                this.storeUserPortalWeflix({userPortalWeflix:updatedUser});

            }
        },
        goToSite(){
            window.location.href = "/";
        }
    },
    mounted() {
        this.startTimer();
        if(this.itemCheckoutWeflix) {
            this.price = this.itemCheckoutWeflix.price;
        }
        
    },
    beforeUnmount() {
        this.stopTimer();
    },
    watch: {
        itemCheckoutWeflix(newValue) {
            this.calcularNumeroParcelas(this.price);
        }
    },
    created() {
        this.calcularNumeroParcelas(this.price);
    }
}
</script>

<style scoped>
.loginRegister {
    background-color: white;
    width: 100%;
    opacity: 1;
    transition: all 200ms ease-in-out;
    min-height: 100vh;
}

/* Estilos para a seção de cupom */
.couponSection {
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
    padding: 10px;
}

.toggleCoupon {
    display: flex;
    align-items: center;
    justify-self: center;
    background: none;
    border: none;
    color: #333;
    cursor: pointer;
    font-size: 14px;
    padding: 5px 10px;
}

.couponInput {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.couponInput input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.validateCoupon {
    background-color: black;
    color: white;
    border: none;
    padding: 8px 15px;
    border-radius: 4px;
    cursor: pointer;
}

.validateCoupon:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.success-message {
    color: green;
    font-size: 14px;
    margin-top: 5px;
}

.error-message {
    color: red;
    font-size: 14px;
    margin-top: 5px;
}

span.close svg.svg-inline--fa.fa-xmark {
    color: gray;
}
.headerLoginRegister {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    color: gray;
}
.headerLoginRegister > span {
   cursor: pointer;
}
span.close {
    display: flex;
    gap: 15px;
    align-items: center;
    color: gray;
}
.login {
    max-width: 800px;
    margin: 0 auto;
    margin-top: 18%;
}
.register {
    max-width: 800px;
    margin: 0 auto;
    margin-top: 15%;
}
.inputLogin {
    display: flex;
    gap: 35px;
}
.titleLogin {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 30px;
}
.submitLogin button {
    color: rgb(255, 255, 255) !important;
    background-color: rgb(218, 223, 231) !important;
    padding: 18px 120px;
    margin-top: 5%;
    border-radius: 5px;
    font-size: 20px;
    font-weight: 700;
}
.submitLogin button.active {
    color: rgb(255, 255, 255) !important;
    background-color: rgb(0, 0, 0) !important;
}
.createAccount {
    transform: translateY(200px);
}
.createAccount p {
    font-size: 13px;
    color: gray;
}
.createAccount p a {
    margin-left: 5px;
    color: rgb(44, 158, 214);
    text-decoration: none;
    font-weight: 700;
}
.inputLogin svg.svg-inline--fa {
    color: gray;
    position: absolute;
    right: 0;
    cursor: pointer;
}
.register .createAccount {
    transform: translateY(100px);
    margin: 0 auto;
}
.toolbarCheckout {
    background-color: black;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.toolbarCheckout h4 {
    color: white;
    margin: 0;
    font-size: 18px;
}
span.timeRemaining {
    font-weight: 900;
    font-size: 20px;
}
button.buttonCheckout {
    width: 200px;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #f3f3f3;
    margin: 0px 5px;
    transition: all 500ms linear;
    box-shadow: -1px 7px 10px 1px #eeeeee;
}
button.buttonCheckout:hover {
    border-radius: 5px;
    box-shadow: -1px 7px 10px 1px #c6c6c6;
}
button.buttonCheckout.active {
    background-color: #4CAF50;
    color: white;
    box-shadow: -1px 7px 10px 1px #696969;
    border-color: #4CAF50;
}
button.buttonCheckout.active:disabled {
    background-color: #ccc;
    cursor: not-allowed;
    box-shadow: none;
}
.paymentMethods {
    max-width: 1000px;
    margin: 0 auto;

}
.planSubscription {
    max-width: 400px;
    margin: 80px auto;
    justify-content: space-between;
    display: flex;
}
.plan {
    display: flex;
}
.planName {
    border-right: 1px solid gray;
    padding-right: 10px;
    display: flex;
    align-items: center;
}

.planName h4 {
    font-size: 13px;
}
.planTitle h4 {
    font-size: 16px;
    font-weight: 700;
    margin: 0;
}
.planTitle {
    padding-left: 10px;
    text-align: left;
}
span.installment {
    font-size: 10px;
}
span.priceSubscription {
    font-weight: 600;
    font-size: 18px;
}
.security {
    margin: 20px 0px;
}
.security h3 {
    font-size: 15px;
    font-weight: 700;
}
.paymentMethodsOptions {
    display: flex;
    justify-content: center;
    margin: 21px 0px;
    align-items: end;
}
.paymentMethodOptionCreditCard {
    width: 40%;
}
.paymentImage {
    width: 30%;
}

.card-preview {
    background-image: url('@/assets/card-weflix.png');
    background-size: cover;
    width: 100%;
    height: 200px;
    margin: 10px;
    position: relative;
    border-radius: 10px;
    padding: 20px;
    color: white;
    font-family: monospace;
}

.card-number {
    position: absolute;
    top: 45%;
    left: 20px;
    font-size: 18px;
    letter-spacing: 2px;
}

.card-name {
    position: absolute;
    bottom: 40px;
    left: 20px;
    font-size: 16px;
    text-transform: uppercase;
}

.card-expiry {
    position: absolute;
    bottom: 40px;
    right: 70px;
    font-size: 16px;
}

.card-cvv {
    position: absolute;
    bottom: 40px;
    right: 20px;
    font-size: 16px;
}

.flex.inputCheckout .form-group .form-label label {
    font-size: 13px !important;
}
.inputCheckout .form-group .form-input .form-control {
    border: none;
    border: 1px solid #e6e6e6;
    border-radius: 0;
    padding-left: 0;
    padding: 5px;
    border-radius: 5px;
}
.flex.inputCheckout .form-group {
    margin: 5px 4px !important;
    width: 48%;
}
.paymentMethodsOptions.boleto {
    align-items: normal;
}
.paymentMethodOptionBoleto {
    width: 30%;
}
.inputCheckout .form-group {
    padding: 0;
    margin: 0 !important;
}
.infoBoleto p {
    text-align: left;
    font-size: 13px;
    color: gray;
    margin-top: 10px;
}
img.imageBoleto {
    width: 90% !important;
    margin-bottom: 14px;
}
.paymentMethodsOptions.pix {
    align-items: start;
}
img.imagePix {
    width: 35% !important;
    margin-bottom: 15px;
}

/* Estilos do Footer */
.checkout-footer {
    background-color: #f8f9fa;
    padding: 40px 0 20px;
    margin-top: 50px;
}

.footer-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 0 20px;
}

.footer-section {
    margin-bottom: 30px;
    min-width: 200px;
}


.loginRegister {
    background-color: white;
    width: 100%;
    opacity: 1;
    transition: all 200ms ease-in-out;
    min-height: 100vh;
}

/* Estilos para a seção de cupom */
.couponSection {
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
    padding: 10px;
}

.toggleCoupon {
    display: flex;
    align-items: center;
    justify-self: center;
    background: none;
    border: none;
    color: #333;
    cursor: pointer;
    font-size: 14px;
    padding: 5px 10px;
}

.couponInput {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.couponInput input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.validateCoupon {
    background-color: black;
    color: white;
    border: none;
    padding: 8px 15px;
    border-radius: 4px;
    cursor: pointer;
}

.validateCoupon:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.success-message {
    color: green;
    font-size: 14px;
    margin-top: 5px;
}

.error-message {
    color: red;
    font-size: 14px;
    margin-top: 5px;
}

span.close svg.svg-inline--fa.fa-xmark {
    color: gray;
}
.headerLoginRegister {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    color: gray;
}
.headerLoginRegister > span {
   cursor: pointer;
}
span.close {
    display: flex;
    gap: 15px;
    align-items: center;
    color: gray;
}
.login {
    max-width: 800px;
    margin: 0 auto;
    margin-top: 18%;
}
.register {
    max-width: 800px;
    margin: 0 auto;
    margin-top: 15%;
}
.inputLogin {
    display: flex;
    gap: 35px;
}
.titleLogin {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 30px;
}
.submitLogin button {
    color: rgb(255, 255, 255) !important;
    background-color: rgb(218, 223, 231) !important;
    padding: 18px 120px;
    margin-top: 5%;
    border-radius: 5px;
    font-size: 20px;
    font-weight: 700;
}
.submitLogin button.active {
    color: rgb(255, 255, 255) !important;
    background-color: rgb(0, 0, 0) !important;
}
.createAccount {
    transform: translateY(200px);
}
.createAccount p {
    font-size: 13px;
    color: gray;
}
.createAccount p a {
    margin-left: 5px;
    color: rgb(44, 158, 214);
    text-decoration: none;
    font-weight: 700;
}
.inputLogin svg.svg-inline--fa {
    color: gray;
    position: absolute;
    right: 0;
    cursor: pointer;
}
.register .createAccount {
    transform: translateY(100px);
    margin: 0 auto;
}
.toolbarCheckout {
    background-color: black;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.toolbarCheckout h4 {
    color: white;
    margin: 0;
    font-size: 18px;
}
span.timeRemaining {
    font-weight: 900;
    font-size: 20px;
}
button.buttonCheckout {
    width: 200px;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #f3f3f3;
    margin: 0px 5px;
    transition: all 500ms linear;
    box-shadow: -1px 7px 10px 1px #eeeeee;
}
button.buttonCheckout:hover {
    border-radius: 5px;
    box-shadow: -1px 7px 10px 1px #c6c6c6;
}
button.buttonCheckout.active {
    background-color: #4CAF50;
    color: white;
    box-shadow: -1px 7px 10px 1px #696969;
    border-color: #4CAF50;
}
.paymentMethods {
    max-width: 1000px;
    margin: 0 auto;

}
.planSubscription {
    max-width: 400px;
    margin: 80px auto;
    justify-content: space-between;
    display: flex;
}
.plan {
    display: flex;
}
.planName {
    border-right: 1px solid gray;
    padding-right: 10px;
    display: flex;
    align-items: center;
}

.planName h4 {
    font-size: 13px;
}
.planTitle h4 {
    font-size: 16px;
    font-weight: 700;
    margin: 0;
}
.planTitle {
    padding-left: 10px;
    text-align: left;
}
span.installment {
    font-size: 10px;
}
span.priceSubscription {
    font-weight: 600;
    font-size: 18px;
}
.security {
    margin: 20px 0px;
}
.security h3 {
    font-size: 15px;
    font-weight: 700;
}
.paymentMethodsOptions {
    display: flex;
    justify-content: center;
    margin: 21px 0px;
    align-items: end;
}
.paymentMethodOptionCreditCard {
    width: 40%;
}
.paymentImage {
    width: 30%;
}

.card-preview {
    background-image: url('@/assets/card-weflix.png');
    background-size: cover;
    width: 100%;
    height: 200px;
    margin: 10px;
    position: relative;
    border-radius: 10px;
    padding: 20px;
    color: white;
    font-family: monospace;
}

.card-number {
    position: absolute;
    top: 45%;
    left: 20px;
    font-size: 18px;
    letter-spacing: 2px;
}

.card-name {
    position: absolute;
    bottom: 40px;
    left: 20px;
    font-size: 16px;
    text-transform: uppercase;
}

.card-expiry {
    position: absolute;
    bottom: 40px;
    right: 70px;
    font-size: 16px;
}

.card-cvv {
    position: absolute;
    bottom: 40px;
    right: 20px;
    font-size: 16px;
}

.flex.inputCheckout .form-group .form-label label {
    font-size: 13px !important;
}
.inputCheckout .form-group .form-input .form-control {
    border: none;
    border: 1px solid #e6e6e6;
    border-radius: 0;
    padding-left: 0;
    padding: 5px;
    border-radius: 5px;
}
.flex.inputCheckout .form-group {
    margin: 5px 4px !important;
    width: 48%;
}
.paymentMethodsOptions.boleto {
    align-items: normal;
}
.paymentMethodOptionBoleto {
    width: 30%;
}
.inputCheckout .form-group {
    padding: 0;
    margin: 0 !important;
}
.infoBoleto p {
    text-align: left;
    font-size: 13px;
    color: gray;
    margin-top: 10px;
}
img.imageBoleto {
    width: 90% !important;
    margin-bottom: 14px;
}
.paymentMethodsOptions.pix {
    align-items: start;
}
img.imagePix {
    width: 35% !important;
    margin-bottom: 15px;
}

/* Estilos do Footer */
.checkout-footer {
    background-color: #f8f9fa;
    padding: 40px 0 20px;
    margin-top: 50px;
}

.footer-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 0 20px;
}

.footer-section {
    margin-bottom: 30px;
    min-width: 200px;
}

.footer-section h4 {
    color: #333;
    margin-bottom: 15px;
    font-size: 18px;
}

.footer-section p {
    color: #666;
    margin: 5px 0;
    font-size: 14px;
}

.footer-section a {
    display: block;
    color: #666;
    text-decoration: none;
    margin: 5px 0;
    font-size: 14px;
}

.footer-section a:hover {
    text-decoration: underline;
}

.payment-methods {
    color: #666;
    font-size: 14px;
}

.footer-bottom {
    text-align: center;
    margin-top: 30px;
    padding-top: 20px;
    border-top: 1px solid #ddd;
}

.footer-bottom p {
    color: #666;
    font-size: 12px;
}

/* Estilos do Popup de Sucesso do Trial */
.trial-success-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.trial-success-popup {
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    text-align: center;
    max-width: 400px;
    width: 90%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.trial-success-popup h3 {
    color: #000000;
    margin-bottom: 20px;
    font-size: 24px;
}

.trial-success-popup button {
    margin-top: 20px;
    width: 100%;
    max-width: 200px;
}

/* Estilos do Loader de Pagamento */
.payment-loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.payment-loader {
    color: white;
    font-size: 24px;
}

/* Mobile */
@media (max-width: 600px) {
    .planSubscription {
        display: block;
        text-align: center;
        margin: 10px auto;
    }
    .plan {
        justify-content: center;
    }
    .paymentMethodButton {
        display: flex;
        padding: 10px;
    }
    .paymentMethodsOptions.creditcard, .paymentMethodsOptions.boleto, .paymentMethodsOptions.pix {
        display: block;
        padding: 10px;
    }
    .paymentMethodOptionCreditCard, .paymentMethodOptionBoleto, .paymentMethodOptionPix {
        width: 100%;
    }
    .paymentImage  {
        width: 100%;
        margin: 20px 0px;
    }
    .paymentImage img {
        display: none;
    }
    .toolbarCheckout.mobile {
        display: block;
    }
    .toolbarCheckout.mobile {
        display: block;
    }
    .toolbarCheckout.mobile h4 {
        font-size: 16px;
    }
    .desktop {
        display: none;
    }
    .toolbarCheckout.mobile p.timeRemaining {
        color: white;
        font-size: 17px;
        margin: 0;
    }
    .card-preview {       
        height: 255px !important;
        margin: 0px;
        margin-bottom: 20px;
        position: relative;
        border-radius: 10px;
        padding: 20px;
        color: white;
        font-family: monospace;
        display: block;
        width: 100%;
        background-size: contain;
    }
    .loginRegister{
        height: auto;      
    }
    
    .footer-content {
        flex-direction: column;
        text-align: center;
    }
    
    .footer-section {
        margin-bottom: 20px;
    }
}
@media (min-width: 600px) {
    .mobile {
        display: none;
    }
    .contentCheckout {
        height: 49vh;
    }
   
}
.planPrice p{
    margin: 0;
}
.paymentAction.mobile {
    margin-top: 20px;
}
.empty-cart {
    min-height: 42vh;
    margin-top: 145px;
}
</style>
