<template>
    <div class="player" :class="showModalVideo ? 'active' : ''">
        <div class="background">
            <div class="close" @click="closeModalVideo">
                <fa icon="fa-times"/>
            </div>
            <div class="playerFrame">
                <iframe 
                :src="videoPlayer"
                width="100%"    
                :height="400" 
                frameborder="0" 
                allow="autoplay; fullscreen" 
                allowfullscreen>
            </iframe>
            </div>  
            <Loader :hasReady="hasReady"/>
        </div>
    </div>
</template>
<script>
import PlayerVideo from '@/utils/storage/playerVideo';
import { mapState } from 'vuex';
import Loader from '@/components/Weflix/Loader';
export default {
    name: 'Player',
    data(){
        return {
            videoPlayer: '',
            hasReady: false
        }
    },
    props: {
        showModalVideo: {
            type: Boolean,
            default: false
        }
    },
    components: {
        Loader
    },
    computed: {
        ...mapState(['token'])
    },
    methods: {
        closeModalVideo(){
            this.$emit('closeModalVideo');
        },
        async getPlayerVideo(){
            const param = this.$route.params.slug;
            try {
                const response = await PlayerVideo.getPlayerVideo(this.token, param);
                this.videoPlayer = response;
                this.hasReady = true;
            } catch (error) {
                console.error('Erro ao obter o player:', error);
                throw error;
            }
        }
    },
    watch: {
        showModalVideo(newVal, oldVal){
            if(newVal){
                this.getPlayerVideo();
            } else {
                this.videoPlayer = null;
            }
        }
    }
}
</script>

<style scoped>
.playerFrame {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
}
.background {
    position: relative;
    background: #000000e0 !important;
    width: 100%;
    height: 100%;
}
.player {
    position: relative;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999999;
    height: 94vh;
    display: none;
}
.player.active {
    display: block;
}
.close {
    position: absolute;
    top: 26%;
    left: 77%;
}
@media (max-width: 600px) {
    .playerFrame {
        width: 90%;
        top: 40%;
    }
    .close {
        position: absolute;
        top: 2%;
        left: 89%;
    }
    
}
</style>