<template>
    <div class="container users-container forum-container">


        <!-- <div class="search-row">
            <div class="form-group search-form-group" style="flex: 8;">
                <div class="form-input search-form-input">
                    <input
                        type="text" 
                        class="form-control search-form-control"
                        placeholder="Pesquisar dúvida..."
                        v-model="searchTerm"
                        @keyup="filteredCourses"
                    />
                    <fa icon="fa-search" class="input-icon search-input-icon" />
                </div>
            </div>

            <div class="form-group filter-form-group" style="flex: 2; display: flex; align-items: center;">
                <label style="margin-right: 0.5rem;">Filtrar por:</label>
                <select v-model="status" class="form-control filter-form-control" @change="filteredCoursesStatus">
                    <option value="all">Todos</option>
                    <option value="1">Aberta</option>
                    <option value="0">Fechada</option>
                </select>
            </div>
        </div> -->

        <Loader v-if="!hasReady" />
        <div v-else class="table-responsive users-table-responsive">
            <table class="table users-table">
                <thead>
                    <tr>
                        <th style="width: 20%; text-align: left; border-right: none !important;">Título</th>
                        <th style="width: 5%; border-right: none !important; border-left: none !important;">Data</th>
                        <th style="width: 5%; border-left: none !important;">Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="forum in paginatedCourses" :key="forum.id">
                        <td style="width: 20%; text-align: left; border-right: none !important;">{{ forum.title }}</td>
                        <td style="width: 5%; border-right: none !important; border-left: none !important;">{{ formatDate(forum.dateInsert) }}</td>
                        <td style="width: 5%; border-left: none !important;">
                            <span :class="forum.status == 1 ? 'badge-active' : 'badge-inactive'">
                                {{ forum.status == 1 ? 'Aberta' : 'Fechada' }}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="pagination users-pagination">
            <button 
                :disabled="currentPage === 1" 
                @click="previousPage"
                class="page-btn users-page-btn"
            >
                Anterior
            </button>
            <span class="page-info users-page-info">Página {{currentPage}} de {{totalPages}}</span>
            <button 
                :disabled="currentPage === totalPages" 
                @click="nextPage"
                class="page-btn users-page-btn"
            >
                Próxima
            </button>
        </div>
    </div>
</template>

<script>
import Loader from '@/components/Loader/loader.vue';
import api from "@/utils/reports.js";
import { mapState } from "vuex";

export default {
    data() {
        return {
            label: this.labels,
            userFiltered: [],
            searchTerm: '',
            status: 'all',
            currentPage: 1,
            itemsPerPage: 5,
            alertOpenCourse: false,
            removedcoursename: '',
            afterWidth: 40,
            usersInfo: [],
            openModalCreateWarning: false,
            openModalEditWarning: false,
            hasReady: false,
            indiceItem: [false, false, false, true],
            isMobileIcon: false,
        };
    },
    props: {
        labels: Array
    },
    computed: {
        ...mapState(['token', 'numberIntensTable']),
        paginatedCourses() {
            if (!Array.isArray(this.userFiltered)) {
                return [];
            }
            const startIndex = (this.currentPage - 1) * this.numberIntensTable;
            const endIndex = startIndex + this.numberIntensTable;
            return this.userFiltered.slice(startIndex, endIndex);
        },
        totalPages() {
            return Math.ceil(this.userFiltered.length / this.numberIntensTable);
        },
    },
    methods: {
        exportToCSV() {
            const columnHeaders = ["ID", "Titulo", "Descrição", "Status", "Usuário", "Data", "Comentários"];
            let csvContent = "data:text/csv;charset=utf-8,";
            const headers = columnHeaders.join(","); 
            csvContent += headers + "\n";

            csvContent += this.userFiltered.map(user => {
                return [
                    user.id,                              // ID
                    user.title,                           // Titulo
                    user.description,                     // Descrição
                    user.status,                          // Status
                    user.userName ? user.userName : "",   // Usuário (se disponível)
                    new Date(user.dateInsert).toLocaleDateString(), // Data formatada
                    user.commentsQty                      // Quantidade de Comentários
                ].join(",");
            }).join("\n");

            const encodedUri = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "relatorio_de_forum.csv");
            document.body.appendChild(link); 
            link.click();
        },
        formatDate(dateString) {
            const date = new Date(dateString);
            const day = String(date.getUTCDate()).padStart(2, '0');
            const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // getUTCMonth() returns month from 0 to 11
            const year = date.getUTCFullYear();
            return `${day}/${month}/${year}`;
        },
        async getDataReport() {
            try {
                const response = await api.getDataForum(this.token);
                this.usersInfo = response;
                this.hasActive = true;
                this.userFiltered = response;
                this.hasReady = true;
                console.debug(response)
            } catch (error) {
                console.error('Erro Report Users', error)
            }
        },
        filteredCourses() {
            if (this.searchTerm.trim() === '') {
                this.userFiltered = this.usersInfo;
            } else {
                this.userFiltered = this.usersInfo.filter((user) =>
                    user.name.toLowerCase().includes(this.searchTerm.toLowerCase())
                );
            }
            return this.userFiltered;
        },
        filteredCoursesStatus() {
            if (this.status == 'all') {
                this.userFiltered = this.usersInfo;
            } else {
                this.userFiltered = this.usersInfo.filter((course) =>
                    course.status.toLowerCase() === this.status.toLowerCase()
                );
            }
            return this.userFiltered;
        },
        previousPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },
        removecourse(id, name) {
            this.removedcoursename = name;
            this.alertOpenCourse = !this.alertOpenCourse;
            setTimeout(() => {
                this.alertOpenCourse = !this.alertOpenCourse;
                this.removedcoursename = '';
            }, 4000);
        },
        edit(id) {
            this.openModalEditWarning = !this.openModalEditWarning;
        },
        create() {
            this.openModalCreateWarning = !this.openModalCreateWarning;
        },
        updatevalueCreate(newvalue) {
            this.openModalCreateWarning = newvalue;
        },
        updatevalueEdit(newvalue) {
            this.openModalEditWarning = newvalue;
        },
        filterByDate(period, index) {
            this.indiceItem = this.indiceItem.map((item, i) => i === index ? true : false);
            const now = new Date();
            let startDate;

            switch (period) {
                case 'today':
                    startDate = new Date(now.setHours(0, 0, 0, 0));
                    break;
                case 'last30days':
                    startDate = new Date(now.setDate(now.getDate() - 30));
                    break;
                case 'last90days':
                    startDate = new Date(now.setDate(now.getDate() - 90));
                    break;
                case 'alltime':
                    this.userFiltered = this.usersInfo;
                    return;
            }
            this.userFiltered = this.usersInfo.filter((course) => {
                const courseDate = new Date(course.dateInsert);
                return courseDate >= startDate;
            });
        },
        filterByCustomDate(event) {
            const selectedDate = new Date(event.target.value);
            if (!selectedDate) {
                this.userFiltered = this.usersInfo;
            }
            this.userFiltered = this.usersInfo.filter((course) => {
                const courseDate = new Date(course.dateInsert);
                return courseDate >= selectedDate;
            });
        },
    },
    components: {
        Loader
    },
    created() {
        this.getDataReport();
        this.userFiltered = this.usersInfo;
    },
};
</script>

<style scoped>
.container {
    max-width: 100%;
    background-color: #ffffff;
    padding: 0 !important;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}
.search-form-input {
    position: relative;
    width: 100%;
}
.title-container {
    position: relative;
    margin-bottom: 10px;
    padding-bottom: 0;
    border-bottom: 1px solid #f0f0f0;
    text-align: left;
}

.title-container .title {
    font-size: 1.5rem;
    font-weight: 700;
    color: #000000;
    margin-bottom: 0.5rem;
}

.title-container .subtitle {
    color: #6b7280;
    font-size: 0.875rem;
    line-height: 1.4;
    margin-bottom: 1rem;
}

.search-row {
    display: flex;
    gap: 0.75rem;
    margin-bottom: 1.5rem;
}

.search-row .form-group {
    flex: 1;
}

.btn-weflix.absolute {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #2f2e8b;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: 600;
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(47, 46, 139, 0.2);
}

.btn-weflix.absolute:hover {
    background-color: #23225f;
    transform: translateY(-2px);
}

.users-table-responsive {
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 1rem;
}

.users-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
}

.users-table th {
    background-color: #f8fafc;
    padding: 0.75rem;
    font-weight: 600;
    color: #4a5568;
    text-align: left;
    border-bottom: 2px solid #e2e8f0;
}

.users-table td {
    padding: 0.75rem;
    vertical-align: middle;
    border-bottom: 1px solid #f0f0f0;
    color: #4a5568;
}

.users-table tr:hover {
    background-color: #f8fafc;
}

.badge-active, .badge-inactive {
    padding: 0.25rem 0.75rem;
    border-radius: 5px;
    font-size: 0.75rem;
    font-weight: 500;
    display: inline-block;
    text-align: center;
    transition: all 0.2s ease;
    width: 70%;
}

.badge-active {
    background-color: #7bcc8180;
    color: #4b614e;
}

.badge-inactive {
    background-color: #d7d7ee80;
    color: #2f2e8b;
}

.user-btn-edit {
    padding: 0.4rem;
    margin: 0 0.25rem;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    color: #2f2e8b;
}

.search-form-input {
    position: relative;
}

.search-form-control {
    width: 100%;
    padding: 0.5rem 0.75rem;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    font-size: 0.875rem;
    transition: all 0.2s ease;
}

.search-form-control:focus {
    outline: none;
    border-color: #2f2e8b;
    box-shadow: 0 0 0 3px rgba(47, 46, 139, 0.1);
}

.search-input-icon {
    position: absolute;
    right: 0.75rem;
    top: 45%;
    transform: translateY(-50%);
    color: #9ca3af;
    cursor: pointer;
}

.users-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
    padding-bottom: 10px;
}

.users-page-btn {
    padding: 0.5rem 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    background: white;
    color: #4a5568;
    cursor: pointer;
    transition: all 0.2s;
}

.users-page-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.users-page-btn:not(:disabled):hover {
    background: #f8fafc;
    border-color: #cbd5e0;
}

.users-page-info {
    color: #4a5568;
    font-size: 0.875rem;
}

@media (max-width: 768px) {
    .container {
        padding: 1rem;
    }

    .title-container {
        margin-bottom: 1.5rem;
    }

    .search-row {
        flex-direction: column;
    }

    .users-table th, .users-table td {
        padding: 0.5rem;
    }
}

@media (max-width: 600px) {
    .btn-weflix.absolute {
        padding: 0.5rem;
    }
    
    .btn-weflix.absolute span {
        display: none;
    }

    .users-table-responsive {
        overflow-x: auto;
    }
}
.content.dashboard > .container {
    background-color: white;
    padding: 20px;
    padding-bottom: 35px;
    box-shadow: 0px 6px 10px 0px #dfdfdf;
    min-height: 89vh;
    padding-bottom: 0;
}
select.form-control.filter-form-control {
    width: 60%;
}
.form-group.filter-form-group {
    justify-content: space-around;
    align-items: center;
    margin: 0 !important;
}
.form-group.search-form-group {
    margin: 0 !important;
}
table.table.users-table {
    border: none !important;
    padding: 0 !important;
}
button.btn-edit.user-btn-edit svg {
    color: #2f2e8b;
}
.form-group.filter-form-group {
    display: flex;
}
.btn-weflix.absolute span.flex {
    font-size: 16px;
}
.btn-weflix.absolute svg {
    color: white;
    font-size: 15px;
}
/* DARK */
.dashboard.dark .users-table-responsive {
    background-color: #000 !important;
}
.dashboard.dark .weeducAdminReports .page-info.users-page-info {
    color: white;
}
.dashboard.dark .weeducAdminReports .users-table th {
    border-bottom: 2px solid #575757 !important;
}
.dashboard.dark .weeducAdminReports .users-table td {
    border-bottom: 2px solid #575757 !important;
}
</style>
