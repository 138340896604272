<template>
    <div class="sidebar primary" :class="{ 'show': sideBarState }">
        <fa v-if="sideBarState" icon="fa-xmark" class="icon-close" @click="changeSideBarState"/>
        <!--Header Section-->

        <div class="sidebar-header">
            <div class="header-logo">
                <div class="logo" id="desktopLogoWeflix">
                    <img class="logo_black" src="./../../assets/Weflix/Weflix-Logo-Total-Branco.png" >
                    <img class="logo_white" src="./../../assets/Weflix/logo_weflix.png" >
                </div>
                <div class="logo-mobile">
                    <span><img src="./../../assets/Weflix/icons/Weflix-Icone-Branco.svg" alt=""></span>
                    <span class="mobile-icon-weflix-text"><img src="./../../assets/Weflix/icons/Weflix-Escrita-Branco.svg" alt=""></span>
                </div>
            </div>
        </div>

        <!--Content Section-->

        <div class="sidebar-content">
            <div class="content-link">
                <div class="link" v-for="(itens, index) in dataSidebar" :key="index">
                    <router-link class="link-content primary" :to="itens.link" :class="{ active: activeIndex == index }"
                        @click="setActiveIndex(index)">
                        <span class="link-icon">
                            <fa :icon="itens.icon"  style="font-size: 1rem"/>

                        </span>
                        <span class="link-text">
                            <span class="text">
                               {{ itens.name }}
                            </span>
                        </span>
                    </router-link>
                </div>

                <div class="link dad" v-if="!isMobile">
                    <a class="link-content primary" href="#"
                    @click="setActiveIndex(8)"
                    :class="{ active: activeIndex == 8 }"
                    >
                        <span class="link-icon">
                            <fa icon="fa-chart-line" style="font-size: 1rem;    margin-left: -10px;"/>

                        </span>
                        <span class="link-text">
                            <span class="text">
                                Relatórios
                            </span>
                        </span>
                    </a>
                    <div class="link subitem" v-for="(report, index) in typesReports" :key="index">
                        <router-link
                            class="link-content primary"
                            :to="linksReports[index]"
                            @click="setSecondIndex(index + 1), setActiveIndex(8)"
                            :class="{ active: secondoactiveindex === index + 1 }"
                        >
                            <span class="link-icon">
                                <fa :icon="iconsReports[index]" style="font-size: 1rem" />
                            </span>
                            <span class="link-text">
                                <span class="text">
                                    {{ report }}
                                </span>
                            </span>
                        </router-link>
                    </div>
                </div>
                <div class="link mobile-report">
                    <div class="link" v-for="(report, index) in typesReports" :key="index">
                        <router-link
                            class="link-content primary"
                            :to="linksReports[index]"
                            @click="setSecondIndex(index + 1), setActiveIndex(8)"
                            :class="{ active: secondoactiveindex === index + 1 }"
                        >
                            <span class="link-icon">
                                <fa :icon="iconsReports[index]" style="font-size: 1rem" />
                            </span>
                            <span class="link-text">
                                <span class="text">
                                    {{ report }}
                                </span>
                            </span>
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="version-label">
                <small v-if="!isMobile">Versão: 1.0.35 (29/03/2025)</small>
                <center><small v-if="isMobile">Versão: 1.0.35</small></center>

            </div>
        </div>
    </div>
</template>


<script>
import store from '@/store';
import  { mapState,mapActions } from 'vuex';

export default {
    data() {
        return {
            menu: [],
            activeIndex: 0,
            isMobile: false,
            secondoactiveindex:null,
            lenguage: "pt",
            label_lenguage: "Idioma",
            typeUser:'',
            typesReports: [ 'Clientes', 'Financeiro',],
            iconsReports: ['fa-chalkboard-user','fa-sack-dollar'],
            linksReports: ['/admin/relatorios/clientes','/admin/relatorios/financas'],
            dataSidebar:[
                {
                    name:'Visão Geral',
                    link:'/admin/painel',
                    icon:'fa-border-all',
                    type:'0'
                },
                {
                    name:'Coleções',
                    link:'/admin/colecoes',
                    icon:'fa-clapperboard',
                    type:'0'
                },
                // {
                //     name:'Anexos',
                //     link:'/admin/anexos',
                //     icon:'fa-file-lines',
                //     type:'0'
                // },
                {
                    name:'Layout',
                    link:'/admin/personalizar',
                    icon:'fa-paintbrush',
                    type:'0'
                },
                {
                    name:'Pagamentos',
                    link:'/admin/pagamentos',
                    icon:'fa-money-check-dollar',
                    type:'0'
                },
                {
                    name:'Configurações',
                    link:'/admin/configuracoes',
                    icon:'fa-gears',
                    type:'1'
                },
                {
                    name:'Financeiro',
                    link:'/admin/conta',
                    icon:'fa-building-columns',
                    type:'1'
                },
                {
                    name:'Usuários',
                    link:'/admin/usuarios',
                    icon:'fa-user-plus',
                    type:'1'
                },
            ],
            isMobile: window.innerWidth <= 500
        };
    },
    computed: {
        ...mapState(['sideBarState'])
    },
    methods: {
        ...mapActions(['storeTypeId','storeSideBarState']),

        changeType(){
            this.storeTypeId({typeId: this.typeUser})
        },
        setActiveIndex(index) {
            if (this.activeIndex === index) {
                // this.activeIndex = null;
            } else {
                if(this.setActiveIndex!=8){
                    this.secondoactiveindex=null;
                }
                this.activeIndex = index;
            }
            localStorage.setItem('activeIndex', this.activeIndex);
        },
        setSecondIndex(index){
            if(this.secondoactiveindex===index){

            } else {
                this.secondoactiveindex = index;
            }
            localStorage.setItem('activeSecondIndex', this.secondoactiveindex);
            localStorage.setItem('activeIndex', this.activeIndex);
        },
        changeLenguage() {

            this.menu = require('../../locale/pt/sidebar.json');

            localStorage.setItem('lenguage', this.lenguage);

            const lenguage = localStorage.getItem('lenguage');

            this.lenguage = lenguage;

        },
        tranformtext(index) {
            if (index == "pt") {
                return 'Português';
            } else if (index == "en") {
                return 'English'
            } else if (index == "es") {
                return 'Español'
            }
        },
        changeSideBarState(){
            let aux = this.sideBarState;
            this.storeSideBarState({sideBarState:!aux})
        },
        checkScreenSize() {
            this.isMobile = window.innerWidth <= 500;
        }
    },
    mounted() {
        this.checkScreenSize();
        window.addEventListener('resize', this.checkScreenSize);
        this.isMobile = window.innerWidth <= 600;
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.checkScreenSize);
    },
    created() {

        this.typeUser = store.state.typeId;
        const savedActiveIndex = localStorage.getItem('activeIndex');
        const savedSecondoActiveIndex = localStorage.getItem('activeSecondIndex');
        if (savedActiveIndex !== null) {
            this.activeIndex = parseInt(savedActiveIndex, 10);
        }
        if (savedSecondoActiveIndex !== null) {
            this.secondoactiveindex = parseInt(savedSecondoActiveIndex, 10);
        }

        const lenguage = localStorage.getItem('lenguage');

        if (lenguage == null) {
            this.menu = require('../../locale/pt/sidebar.json');
            
        } else {

            this.menu = require('../../locale/pt/sidebar.json');
        }
    }
}
</script>
<style scoped>
.logo img {
    width: 150px;
}
.link.subitem {
    margin-left: 13%;
    margin-top: 2%;
    margin-bottom: 2%;
}
.link.dad {
    height: 46px;
    overflow: hidden;
    transition: all 500ms linear;
    margin-left: 0.5rem;
}
.link.dad:hover {
    overflow: visible;
    height: 145px;
}
.link.dad > a.link-content.primary {
    cursor: default;
}
.dashboard.dark img.logo_black {
    display: none;
}
.dashboard img.logo_white {
    display: none;
}
.dashboard.dark img.logo_white {
    display: block;
}
.version-label {
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: #b1b1b1;
    font-size: 12px;
}
</style>

<style scoped>
.link.dad .link-content.primary .link-icon {
    margin-right: 1rem !important; 
}
.sidebar.primary .sidebar-content .content-link .link .link-content {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    text-decoration: none;
    padding: 0.5rem 1rem;
    color: white;
    transition: .3s ease;
}
.sidebar.primary {
    position: fixed;
    height: 100%!important;
    z-index: 1040;
    width: 15%;
    left: 0;
    overflow: hidden;
    padding: 0.5rem;
    background-color: #260791;

}
@media only screen and (min-width:1900px){
    .sidebar.primary {
        position: fixed;
        height: 100%!important;
        z-index: 1040;
        width: 15%;
        left: 0;
        overflow: hidden;
        padding: 0.5rem;
        background-color: #260791;
        border-right: 1px solid #f6f6f6;
        transition: .5s ease;
        box-shadow: 2px -1px 22px -15px gray;
        margin-left: 0px;
    } 
}
.sidebar.primary .sidebar-header {
    padding: 1rem !important;
    /* padding-left: 2px!important; */
    padding-top: 5px!important;
}
.sidebar.primary .sidebar-content {
    overflow-y: auto;
    height: calc(100vh - 128px);
}
.sidebar.primary .sidebar-content .content-link {
    display: flex;
    height: calc(100vh - 10rem);
    flex-direction: column;
    gap: 1px;
}
.sidebar.primary .sidebar-content .content-link .link {
    display: block;
}

.sidebar.primary .sidebar-content .content-link .link .link-content .link-icon {
    display: inline-block;
    margin-right: 0.5rem;
    transition: .2s ease;
}
.sidebar.primary .sidebar-content .content-link .link .link-content.active {
    background-color: #150065;
    transition: .3s ease;
}


.sidebar.primary .sidebar-content .content-link .link .link-content:hover {
    color: #fff;
    background-color: #150065;
    transition: .2s ease;
}

.sidebar.primary .sidebar-content .content-link .link .link-content:hover {
    color: #fff;
    background-color: #150065;
    transition: .2s ease;
}
.sidebar.primary .sidebar-content .content-link .link .link-content:hover .link-text>span.text {
    transform: translateX(1rem);
    transition: transform .4s ease,color .1s ease;
}
.sidebar.primary .sidebar-content .content-link .link .link-content .link-text {
    display: flex;
    align-items: center;
    width: 100% !important;
    justify-content: space-between;
}
.sidebar.primary .sidebar-content .content-link .link .link-content:hover .link-icon {
    transform: translateX(0.75rem);
    transition: .2s ease;
    transition-delay: .15s;
}
.sidebar.primary .sidebar-content .content-link .link .link-content:hover .link-icon svg path {
    fill: #fff;
    transition: .3s ease;
}

.sidebar.primary .sidebar-content .content-link .link .link-content.active .link-icon svg path {
    fill: #fff;
    transition: .3s ease;
}
.sidebar.primary .sidebar-content .content-link .link .link-content.active span.link-icon, .sidebar.primary .sidebar-content .content-link .link .link-content.active .text {
    color: white;
}
.link-icon svg.svg-inline--fa {
    color: #ffffff;
}
.sidebar.primary.show {
    width: 200px;
}
svg.svg-inline--fa.fa-xmark.icon-close {
    position: absolute;
    transform: translate(75px, 10px);
}
.logo-mobile {
    display: none;
}
@media (min-width: 501px) {
    .mobile-report {
        display: none !important;
    }
}
@media (max-width: 500px) {
    .sidebar.primary {
        width: 60px;
        transition: .5s ease;
    }
    .sidebar.primary.show {
        width: 61%;
    }
    .sidebar.primary .sidebar-content .content-link .link .link-content .link-text {
        display: flex;
        align-items: center;
        width: 100% !important;
        justify-content: space-between;
        margin-left: 0.8em;
        max-height: 40px;
    }
    .sidebar.primary .sidebar-content .content-link .link .link-content:hover .link-icon {
        transform: translateX(0);
        transition: .2s ease;
        transition-delay: .15s;
    }
    .link .link-icon {
        display: inline-block;
        margin-left: -10px;
    }
    .link.dad .link-icon {
        margin-left: 0px;
    }
    .sidebar.primary .sidebar-content .content-link .link .link-content:hover .link-icon {
        transform: none !important;
        transition: .2s ease;
        transition-delay: .15s;
    }
    .sidebar.primary .sidebar-content .content-link .link .link-content.active {
        background-color: #150065;
        transition: .3s ease;
        border-radius: 10px;
    }
    .icon-close {
        color: #fff;
        padding: 5px;
    }
    .sidebar.primary .sidebar-header {
        padding: 1rem !important;
        padding-left: 3px!important;
        padding-top: 5px!important;
    }
    .link.subitem {
        margin-left: -10px;
        margin-top: 0;
        margin-bottom: 0;
    }
    .link.dad {
        height: 46px;
        overflow: visible;
        transition: none;
    }
    .logo img {
        width: 160px;
    }
    #desktopLogoWeflix {
        display: none;  
    }
    .logo-mobile {
        display: flex;
        align-items: center;
        gap: 50px;
    }
    .logo-mobile img {
        width: 40px;
    }
    .mobile-icon-weflix-text {
        width: 60px;
        transform: scale(2.3);
    }
}
</style>