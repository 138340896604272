<template>
    <div class="global_footer adm" :class="[this.dark == true ? 'dark': '' ]">
            <div class="logo">
                <img  v-show="this.dark != true" src="../../../assets/logo.png">
                <img  v-show="this.dark == true" src="../../../assets/logo_white.png">
            </div>
            
            <div class="options">
                <div class="menu item">
                    <ul>
                        <li>
                            Copyright @ 2024 | Todos os direitos reservados
                        </li>
                    </ul>
                </div>
            </div>
       </div>
</template>
<script>
import { mapState } from 'vuex';

export default{
    data(){
        return{

        }
    },
    props:{
        view:Boolean
    },
    computed:{
        ...mapState(['dark'])
    }

}
</script>
<style>
    @import "./footer.css";
</style>