import api from '../api';

export default {

    // getDataBasicSection
    async getBasicData( accessToken) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/manager/basic`,  {
                method: 'GET',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao gerar informações site:', error);
                throw error;
        }
    },
    async putBasicData(accessToken, payload) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.put(`/manager/basic`, payload,  {
                method: 'PUT',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao editar site:', error);
                throw error;
        }
    },
 }

