import api from './api';

export default {
    async getDataInquiry(accessToken) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get('/inquiry', {
                method: 'GET',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados da Dúvida:', error);
            throw error;
        }
    },   
    async createNewInquiry(payload, accessToken){
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.post('/inquiry', payload, {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados da criação da Dúvida:', error);
            throw error;
        }
    },
}
