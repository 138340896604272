<template>
    <Header/> 
    <section class="myAccount">
        <section class="section link-list primary">
            <div class="link-list-content">
              <div class="content-link create-course">
                <div class="link account">
                  <a
                    class="link-content"
                    :class="[isActiveTab(1)? 'active': '']"
                    @click="setActiveTab(1)"
                  >
                    <fa icon="fa-user" />
                    <span>Conta</span>
                  </a>
                </div>
                <div class="link">
                  <a
                    class="link-content"
                    :class="[isActiveTab(2)? 'active': '']"
                    @click="setActiveTab(2)"
                  >
                    <fa icon="fa-credit-card" />
                    <span>Pagamentos</span>
                  </a>
                </div>
                <div class="link">
                  <a
                    class="link-content last"
                    :class="[isActiveTab(3)? 'active': '']"
                    @click="setActiveTab(3)"
                  >
                    <fa icon="fa-lock" />
                    <span>Senha</span>
                  </a>
                </div>
              </div>
            </div>
          </section>
      
           <section
            class="section config-form primary myAccountFormUser"
            :class="[isActiveTab(1)? 'active': '']"
          >
            <User />
          </section>
          <section
          class="section config-form primary myAccountFormUser"
          :class="[isActiveTab(2)? 'active': '']"
        >
          <Payment />
        </section>

          <section
          class="section config-form primary myAccountFormUser"
          :class="[isActiveTab(3)? 'active': '']"
        >
          <Password />
        </section>

    </section>

    <Footer/>

    
</template>

<script>
import Header from "@/components/Weflix/Hedaer.vue"
import Footer from '@/components/Weflix/Footer.vue'
import { mapState, mapActions } from 'vuex';
import User from '@/components/Weflix/User/Account.vue'
import Password from '@/components/Weflix/User/Password.vue'
import Payment from '@/components/Weflix/User/Payment.vue'
export default {
    data(){
        return{
            activeTab: 1,
        }
    },
    computed:{
        ...mapState(['finalWebsite', 'userPortalWeflix']),
    },
    components:{
        Header,
        Footer,
        User,
        Password,
        Payment
    },
    methods:{
      ...mapActions(['storeUserPortalWeflix']),
      changeToken(){
        this.storeUserPortalWeflix({
          userPortalWeflix: {
            ...this.userPortalWeflix,
            acessToken: '123'
          }
        });
      },
        setActiveTab(tabNumber) {
        this.activeTab = tabNumber;
      },
      isActiveTab(tabNumber) {
        return this.activeTab === tabNumber;
      },
    
    }, 
    created(){
       
    }
}
</script>

<style scoped>
section.section.config-form.primary.myAccountFormUser.active {
    height: auto;
    min-height: 50vh;
}

section.myAccount {
    background-color: black;
    padding-top: 100px;
    position: relative;
    min-height: 100vh;
}

section.myAccount .config-form.primary {
    background-color: #343434;
    border-radius: 0px 0px 0.5em 0.5em;
    padding: 1rem;
    overflow: hidden;
}

section.myAccount .title,
section.myAccount section.section.link-list.primary,
section.myAccount section.section.config-form.primary.active {
    max-width: 1500px;
    margin: 0 auto;
    width: 100%;
}

section.myAccount .link-list-content .content-link .link .link-content.active {
    color: rgb(24, 24, 28);
    background-color: rgb(255, 255, 255);
    border-right: 1px solid #343434;
}

section.myAccount .link-list-content .content-link .link .link-content {
    color: rgb(255, 255, 255);
    background-color: rgb(52, 52, 52);
    border-bottom: 1px solid rgb(52, 52, 52);
    border-right: 1px solid rgb(52, 52, 52);
    padding: 15px 30px;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
}

section.myAccount .title h3 {
    color: white;
    margin: 10px 0;
    font-size: 20px;
}

.content-link.create-course {
    display: flex;
    justify-content: flex-start;
}

@media (max-width: 600px) {
    section.myAccount .title,
    section.myAccount section.section.link-list.primary {
        max-width: 100%;
        width: 100%;
        padding: 0 15px;
    }

    section.myAccount section.section.config-form.primary.active {
        width: 100%;
        padding: 15px;
    }

    section.myAccount .link-list-content .content-link .link .link-content {
        padding: 10px 15px;
        white-space: nowrap;
    }

    .modal-content {
        padding: 10px;
    }
    .myAccount a.link-content {
      width: auto!important;
  }
  .account {
    width: 29%!important;
  }
  section.myAccount {
    padding-right: 10px !important;
    padding-left: 10px !important;
}
section.section.link-list.primary {
  padding: 0 !important;
}

}
</style>

<style>
.myAccount .form-group .form-label label {
    color: white;
}

.myAccount .double path {
    fill: none;
    stroke: #ffffff;
    stroke-width: 20px;
    transform: scale(0.7);
}

.myAccount button.btnSave {
    color: #ffffff;
    width: 150px;
    background-color: #000000;
    padding: 6px 10px;
    border-radius: 5px;
    transition: all 0.3s ease;
    font-size: 18px;
    border: 2px solid #000000;
}

.myAccount .form-file .file-wrapper > span.thumbnail {
    display: flex;
    align-items: center;
    gap: 10px;
}

.myAccount .form-file .file-wrapper > span.thumbnail span.text {
    color: white;
    font-size: 14px;
    word-break: break-word;
}

@media (max-width: 600px) {
    .myAccount .actionsSave {
        width: 100%;
        text-align: center;
        padding: 25px 15px;
        position: sticky;
        bottom: 0;
        background: #343434;
    }

    .myAccount .actionsSave button.btnSave {
        width: 100%;
        max-width: 400px;
    }

    .myAccount .modal-body.double {
        width: 100%;
    }

    .myAccount .form-file .file-wrapper > span.thumbnail span.text {
        font-size: 10px;
    }
    .content-link.create-course .link {
      width: 40%!important;
    }
        .myAccount a.link-content {
        width: auto;
    }
    .modal-content {
      padding: 10px;
    }
    button.btnSave {
      width: 100% !important;
      padding: 7px !important;
      margin: 0 auto !important;
  }
  .info-user .actionsSave2 {
    margin: 0px 0px 0px 0px!important;
    padding: 20px!important;
}

}
section.section.config-form.primary.myAccountFormUser{
  display: none ;
}
section.section.config-form.primary.myAccountFormUser.active {
  display: block;
}
.link > a.link-content {
  text-decoration: none;
}
a.link-content.last {
  border-radius: 0px 10px 0px 0px;
}
</style>