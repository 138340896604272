<template>
    <div class="container">
        <div class="titleContainer">
            <h3>Relatórios de Clientes</h3>
            <p>Gerencie os relatórios e metricas de seus clientes.</p>
        </div>
        <div class="actionsContainer" >
            <div class="actionsHeader">
                <div class="form-group">
                    <div class="form-input client-report">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Pesquisar..."
                        v-model="searchClient"
                      />
                     <fa icon="fa-magnifying-glass" />
                    </div>
                    <button class="btnSearch" @click="searchClientByName()">
                        <span v-if="!isMobile">Buscar</span>
                        <fa  v-if="isMobile" icon="fa-magnifying-glass" />
                    </button>
                  </div>
                <button class="btnExport usersExport" @click="exportarClientesParaCSV">
                    <div>
                        <fa icon="fa-download"/>
                        <span class="mobile-text-none">Baixar relatório</span>
                    </div>
                    <LoaderImage v-show="isExporting" />
                </button>
            </div>
        </div>
        <div class="cardsContainer" v-if="hasReady">
            <div class="row ">
                <div class="card-md">
                    <div class="contentCard">
                        <h3>{{stats.totalUsers.toLocaleString('pt-BR')}}</h3>
                        <p>Cadastros</p>
                    </div>
                </div>

                <div class="card-md">
                    <div class="contentCard mult">
                        <div class="contentCardMult">
                            <div class="follower">
                                <div class="follower-content">
                                    <strong>{{this.calcularPorcentagem(stats.totalSubscribers, stats.totalUsers)}}%</strong>
                                    <small>Assinantes</small>
                                </div>
                                <div class="follower-progress">
                                    <div class="progress-bar">
                                    <div class="progress animate-progress"></div>
                                    </div>
                                    <small class="progress-value">{{stats.totalSubscribers}}</small>

                                </div>
                            </div>
                        </div>
                        <div class="contentCardMult">
                            <div class="follower">
                                <div class="follower-content">
                                    <strong>{{this.calcularPorcentagem(stats.totalNonSubscribers, stats.totalUsers)}}%</strong>
                                    <small>Não Assinantes</small>
                                </div>
                                <div class="follower-progress">
                                    <div class="progress-bar">
                                    <div class="progress animate-progress"></div>
                                    </div>
                                    <small class="progress-value">{{stats.totalNonSubscribers.toLocaleString('pt-BR')}}</small>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    <div class="card-md">
                        <div class="contentCard mult">
                            <div class="contentCardMult">
                                <div class="follower">
                                    <div class="follower-content">
                                        <strong>{{this.calcularPorcentagem(stats.totalActiveTrial, stats.totalUsers)}}%</strong>
                                        <small>Trial</small>
                                    </div>
                                    <div class="follower-progress">
                                        <div class="progress-bar">
                                        <div class="progress animate-progress"></div>
                                        </div>
                                        <small class="progress-value">{{stats.totalActiveTrial}}</small>

                                    </div>
                                </div>
                            </div>
                            <div class="contentCardMult">
                                <div class="follower">
                                    <div class="follower-content">
                                        <strong>{{this.calcularPorcentagem(stats.totalTrialCancelled, stats.totalUsers)}}%</strong>
                                        <small>Trial Cancelados</small>
                                    </div>
                                    <div class="follower-progress">
                                        <div class="progress-bar">
                                        <div class="progress animate-progress"></div>
                                        </div>
                                        <small class="progress-value">{{stats.totalTrialCancelled.toLocaleString('pt-BR')}}</small>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-md">
                        <div class="contentCard mult">
                            <div class="contentCardMult">
                                <div class="follower">
                                    <div class="follower-content">
                                        <strong>{{this.calcularPorcentagem(stats.totalCancelledSubscribers, stats.totalUsers)}}%</strong>
                                        <small>Cancelados</small>
                                    </div>
                                    <div class="follower-progress">
                                        <div class="progress-bar">
                                        <div class="progress animate-progress"></div>
                                        </div>
                                        <small class="progress-value">{{stats.totalCancelledSubscribers.toLocaleString('pt-BR')}}</small>

                                    </div>
                                </div>
                            </div>
                            <div class="contentCardMult">
                                <div class="follower">
                                    <div class="follower-content">
                                        <strong>{{this.calcularPorcentagem(stats.totalExpiredSubscribers, stats.totalUsers)}}%</strong>
                                        <small>Expirados</small>
                                    </div>
                                    <div class="follower-progress">
                                        <div class="progress-bar">
                                        <div class="progress animate-progress"></div>
                                        </div>
                                        <small class="progress-value">{{stats.totalExpiredSubscribers.toLocaleString('pt-BR')}}</small>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
         
        </div>
        <div 
            class="tableContainer" 
            ref="tableContainer"
            @scroll="handleScroll"
            style="height: 380px; overflow-y: auto;" 
            v-if="hasReady"
        >
            <table>
                <thead>
                    <tr>
                        <th @click="ordenar('name')">
                            Nome
                            <i :class="['fas', ordemAtual === 'name' ? (ordemAscendente ? 'fa-sort-up' : 'fa-sort-down') : 'fa-sort']"></i>
                        </th>
                        <th @click="ordenar('speed')" v-if="!isMobile">
                            E-mail
                            <i :class="['fas', ordemAtual === 'speed' ? (ordemAscendente ? 'fa-sort-up' : 'fa-sort-down') : 'fa-sort']"></i>
                        </th>
                        <th @click="ordenar('length')">
                            Status
                            <i :class="['fas', ordemAtual === 'length' ? (ordemAscendente ? 'fa-sort-up' : 'fa-sort-down') : 'fa-sort']"></i>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(client, index) in displayedClients" :key="index">
                        <td>{{ client.name }}</td>
                        <td v-if="!isMobile">{{ client.email }}</td>
                        <td>{{ client.statusDescription }}</td>
                    </tr>
                </tbody>
            </table>

            <div v-if="isLoading" class="loading">
                Carregando mais dados...
            </div>

            <div v-if="!hasMoreData" class="no-more-data">
                Não há mais dados para carregar
            </div>
        </div>
      </div>
      <Loader :hasReady="hasReady" />
</template>

<script>
import { mapState } from 'vuex';
import apiReports from '@/utils/Weflix/reports';
import Loader from '@/components/Weflix/Loader.vue';
import LoaderImage from '@/components/Weflix/LoaderImage.vue';

export default {
    data() {
        return {
            clients: [],  
            stats: [],
            ordemAtual: 'name',
            ordemAscendente: true,
            hasReady: false,
            isMobileIcon: false,
            currentPage: 1,
            itemsPerPage: 20,
            isLoading: false,
            hasMoreData: true,
            displayedClients: [],
            searchClient: '',
            isExporting: false,
            usersExport: [],
            isMobile: false,
        }
    },
    computed: {
        ...mapState(['token','websites'])
    },
    mounted() {
        this.checkScreenSize();
        window.addEventListener('resize', this.checkScreenSize);
        this.isMobile = window.innerWidth <= 600;
        this.getReportClient();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.checkScreenSize);
    },
    components: {
        Loader,
        LoaderImage
    },
    methods: {
        handleScroll(e) {
            const container = e.target;
            const scrollPosition = container.scrollTop + container.clientHeight;
            const totalHeight = container.scrollHeight;

            // Carrega mais quando chegar a 80% do scroll
            if (scrollPosition > totalHeight * 0.8 && !this.isLoading && this.hasMoreData) {
                this.loadMoreData();
            }
        },
        async loadMoreData() {
            if (this.isLoading || !this.hasMoreData) return;

            this.isLoading = true;
            this.currentPage++;

            try {
                const response = await apiReports.getReportClient(this.token, this.currentPage);
                
                if (response.userList && response.userList.length > 0) {
                    this.clients = [...this.clients, ...response.userList];
                    this.displayedClients = [...this.displayedClients, ...response.userList];
                } else {
                    this.hasMoreData = false;
                }
            } catch (error) {
                console.error('Erro ao carregar mais dados:', error);
                this.hasMoreData = false;
            } finally {
                this.isLoading = false;
            }
        },
        ordenar(coluna) {
            if (this.ordemAtual === coluna) {
                this.ordemAscendente = !this.ordemAscendente;
            } else {
                this.ordemAtual = coluna;
                this.ordemAscendente = true;
            }

            this.boats.sort((a, b) => {
                let comparacao = 0;
                if (a[coluna] < b[coluna]) comparacao = -1;
                if (a[coluna] > b[coluna]) comparacao = 1;
                return this.ordemAscendente ? comparacao : -comparacao;
            });
        },
        calcularPorcentagem(valor, total) {
            if (total === 0) return 0;
            return Math.round((valor / total) * 100);
        },
        async getReportClient() {
            try {
                const response = await apiReports.getReportClient(this.token, this.currentPage);
                this.clients = response.userList;
                this.displayedClients = response.userList;
                this.stats = response;
                this.hasReady = true;
            } catch (error) {
                console.error('Erro ao obter relatório do usuários:', error);
            }
        },
        async getReportClientExport() {
            try {
                const response = await apiReports.getReportClientExport(this.token);
                this.usersExport = response.userList;
            } catch (error) {
                console.error('Erro ao obter relatório do usuários:', error);
            }
        },
        async searchClientByName() {
            this.hasReady = false;
            const payload = {
                "search": this.searchClient
            }
            try {
                const response = await apiReports.getReportClientBySearch(this.token, 0, payload);
                this.clients = response.userList;
                this.displayedClients = response.userList;
                this.hasReady = true;
            } catch (error) {
                console.error('Erro ao buscar usuários:', error);
            }
        },
        checkScreenSize() {
            this.isMobileIcon = window.innerWidth <= 600;
        },
        async exportarClientesParaCSV() {
            if (this.isExporting) return;
            
            this.isExporting = true;

            try {
                await this.getReportClientExport();

                if (!this.usersExport || this.usersExport.length === 0) {
                    console.error('Nenhum cliente para exportar');
                    return;
                }

                const cabecalho = [
                    'ID', 'Nome', 'E-mail', 'Bairro', 'Telefone', 'Tipo', 'Status',
                    'CPF', 'RG', 'País', 'Estado', 'Cidade', 'Endereço',
                    'Número', 'Complemento', 'CEP', 'Data de Inserção',
                    'Foto', 'Foto Final', 'Descrição do Status'
                ];

                const linhasCSV = this.usersExport.map(client => [
                    client.id,
                    client.name,
                    client.email,
                    client.neighborhood || '',
                    client.phone || '',
                    client.type || '',
                    client.status,
                    client.socialNumber || '',
                    client.idNumber || '',
                    client.country || '',
                    client.county || '',
                    client.city || '',
                    client.address || '',
                    client.addressNumber || '',
                    client.addressComplement || '',
                    client.postcode || '',
                    client.dateInsert || '',
                    client.photo || '',
                    client.photoFinal || '',
                    client.statusDescription
                ]);

                const conteudoCSV = [
                    cabecalho.join(','),
                    ...linhasCSV.map(linha => linha.join(','))
                ].join('\n');

                const blob = new Blob([conteudoCSV], { type: 'text/csv;charset=utf-8;' });
                const link = document.createElement('a');
                const url = URL.createObjectURL(blob);
                
                link.setAttribute('href', url);
                link.setAttribute('download', 'relatorio_clientes_weflix.csv');
                link.style.visibility = 'hidden';
                
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } catch (error) {
                console.error('Erro ao exportar CSV:', error);
            } finally {
                this.isExporting = false;
            }
        },
    }
}
</script>

<style scoped>
@import url(./global.css);
.container {
    max-width: 100%;
}
.titleContainer {
    display: block;
    text-align: left;
    padding: 0px 10px;
    border-bottom: 1px solid #e8e8e8;
    margin: 10px;
}
.container {
    background-color: white;
    padding: 10px 0px;
    padding-bottom: 50px;
}
.btnExport {
    width: auto;
    padding: 6px 12px;
    background-color: white;
    border:1px solid #250891;
    color: #250891;
    border-radius: 5px;
    top: 20px;
    right: 20px;
    cursor: pointer;
    transition: all 500ms ease-in-out;
    display: flex;
    align-items: center;
    gap: 8px;
}
.btnExport:hover {
    background-color: #250891;
    color: white;
}
.row {
    margin: 0;
    padding: 10px;
    gap: 10px;
    justify-content: space-evenly;
}
.card-md {
    width: 24%;
    padding: 0;
    margin: 0;
    padding: 10px;
    box-shadow: -1px 4px 10px 1px #dfdfdf;
    border-radius: 5px;
    align-items: center;
    display: flex;
    justify-content: center;
}
.client-report svg.svg-inline--fa.fa-magnifying-glass {
    color: #bcbcbc;
    position: absolute;
    top: 9px;
    right: 9px;
}
.form-input {
    position: relative;
}
input.form-control {
    padding-right: 33px !important;
}
.actionsHeader {
    display: flex;
    align-items: center;
}
.actionsContainer {
    position: absolute;
    top: 50px;
    right: 20px;
}
svg.svg-inline--fa.fa-download {
    color: #250891;
    padding: 0px 10px;
    transition: all 500ms ease-in-out;
}
.btnExport:hover svg.svg-inline--fa.fa-download {
    color: white;
}
table {
    width: 100%;
    border-collapse: collapse;
}
th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}
th {
    background-color: #f2f2f2;
    font-weight: bold;
}
tr:nth-child(even) {
    background-color: #f9f9f9;
}
.tableContainer {
    padding: 10px;
}
.progress-bar {
    width: 100%;
    background-color: #e0e0e0;
    border-radius: 10px;
    overflow: hidden;
    height: 5px;
    position: relative;
}
.progress {
    height: 10px;
    background-color: #250891;
    position: absolute;
    height: 10px;
    width: 0;
}
.animate-progress {
    animation: progressAnimation 2s ease-out forwards;
}
@keyframes progressAnimation {
    0% {
        width: 0;
    }
    100% {
        width: 90%;
    }
}
.contentCard.mult {
    width: 70%;
}
.follower-content {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin: 3px 0px;
}
.contentCardMult {
    margin: 25px 0px;
}
.follower-progress{
    position: relative;
}
small.progress-value {
    position: absolute;
    top: 7px;
    color: gray;
    font-size: 11px;
}
small.progress-value {
    display: none;
}
.loading {
    text-align: center;
    padding: 20px;
    color: #666;
}

.no-more-data {
    text-align: center;
    padding: 20px;
    color: #999;
    font-style: italic;
}

@media (max-width: 600px) {
    .cardsContainer .row {
        display: flex;
    }
    .card-md {
        width: 45%;
    }
    .follower-content {
        flex-wrap: wrap;
    }
    .titleContainer {
        display: block;
        text-align: left;
        padding: 0px 10px;
        margin: 10px;
        border-bottom: none;
    }
    .actionsContainer {
        position: relative;
        top: -15px;
        right: 0;
        padding: 0px 0px 10px;
        border-bottom: 1px solid #e8e8e8;
    }
    .btnExport {
        padding: 7px 7px !important;
    }
    .mobile-text-none {
        display: none;
    }
}
.btnSearch {
    width: auto;
    padding: 6px 12px;
    background-color: white;
    border: 1px solid #250891;
    color: #250891;
    border-radius: 5px;
    cursor: pointer;
    transition: all 500ms ease-in-out;
    margin-left: 10px;
}
.btnSearch:hover {
    background-color: #250891;
    color: white;
}
button.btnExport.usersExport .loader2{
    width: 19px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 2px solid #250891;
    animation: l20-1-79f4614c 0.8s infinite linear alternate, l20-2-79f4614c 1.6s infinite linear;
    margin: 0px 10px;
    position: initial;
}
button.btnExport.usersExport {
    display: inline-flex;
}
.actionsHeader .form-group {
    width: 260px;
    display: flex;
}
@media (max-width: 600px) {
    .actionsHeader .form-group {
        width: 72%;
    }
    .contentCard h3 {
        font-size: 20px;
    }
    .contentCard p {
        font-size: 11px;
    }
    .contentCard {
        min-height: 50px !important;
    }
    .titleContainer p {
        font-size: 12px;
    }
    .contentCard.mult {
        width: 95%;
    }
    button.btnSearch svg.svg-inline--fa.fa-magnifying-glass {
        color: #250891;
    }
}
</style>
