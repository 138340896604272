<template>
    <section style="position:relative">
        <div class="primaryBanner" :style="{backgroundImage: `url(${banner})`}">
            <div class="contentBanner">
                <img :src="logoBanner" alt="Logo Weflix"  style="width: 200px;"/>
                <h1>{{titleBanner}}</h1>
                <p>{{descriptionBanner}}</p>
            </div>
        </div>
        <div class="degrade"></div>
    </section>
       
</template>

<script>
import { mapState } from 'vuex';

export default {
    data() {
        return {
            titleBanner: '',
            descriptionBanner: '',
            logoBanner: '',
            banner: ''
        }
    },
    computed: {
        ...mapState(['finalWebsite', 'contentSiteWeflix']),
    },
    watch: {
        finalWebsite: {
            immediate: true,
            handler(newValue) {
                if (newValue) {
                    this.titleBanner = newValue.nome || '';
                    this.descriptionBanner = newValue.description || '';
                    this.logoBanner = newValue.logoFinal || '';
                }
            }
        },
        contentSiteWeflix: {
            immediate: true,
            handler(newValue) {
                if (newValue) {
                    this.banner = newValue.pathImageFinal || '';
                }
            }
        }
    },
}
</script>

<style scoped>  
.primaryBanner{
    height: 563px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: relative;
    padding-bottom: 64px;
    padding-top: 64px;
}
.degrade{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    top: 0px;
    left: 0px;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgb(0, 0, 0));
}
.contentBanner {
    position: absolute;
    width: 100%;
    z-index: 11;
    bottom: 0;
    max-width: 1000px;
    transform: translate(-50%, 0%);
    left: 50%;

}
.contentBanner h1 {
    color: white;
    margin-top: 50px;
    font-size: 50px;
}
.contentBanner p {
    color: white;
    font-size: 18px;
}
@media (max-width: 600px) {
    .contentBanner {
        top: 40% !important;
    }
    .contentBanner h1 {
        font-size: 30px;
        margin-top: 20px;
    }
    .contentBanner p {
        font-size: 14px;
        padding: 0px 20px;
    }
}
</style>
