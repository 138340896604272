<template>
    <div class="container myWeflixEditVideo">
        <div class="titleContainer">
            <h3>Editar Video</h3>
            <small>Edite as informações do seu vídeo</small>
            <button class="btn-delete" @click="deleteVideo">
                <fa icon="fa-trash"/>
            </button>
        </div>

        <div class="contetContainerEditVideo" v-if="!isLoading && !isSaving">
            <div class="col-form-inner col-6 col-md-6 col-lg-6 freeVideo">
                <div class="col-form-inner col-12 col-md-12 col-lg-12">
                    <div class="form-group">
                        <div class="form-label">
                            <label>Status<v-tooltip text="Campo obrigatório" location="top">
                            </v-tooltip></label>
                        </div>
                        <div class="itemAtionCollections form-check form-switch">
                            <span>Ativado</span>
                            <input
                                class="form-check-input"
                                type="checkbox"
                                role="switch"
                                v-model="status"
                            />
                        </div>
                        <small>Ao ativar este vídeo, ele será disponibilizado ao público. Desative para ocultá-lo da visualização pública.</small>
                    </div>
                </div>

                <div class="col-form-inner col-12 col-md-12 col-lg-12">
                    <div class="form-group">
                        <div class="form-label">
                            <label>Título do Video<v-tooltip text="Campo obrigatório" location="top">
                                <template v-slot:activator="{ props }">
                                    <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                                </template>
                            </v-tooltip></label>
                        </div>
                        <div class="form-input">
                            <input
                                type="text" 
                                required
                                class="form-control"
                                v-model="videoData.title"
                                placeholder="Título do vídeo"
                            />
                            <small>Nome do vídeo, use um nome descritivo para facilitar a identificação.</small>
                        </div>
                    </div>
                </div>


              

                <div class="col-form-inner col-12 col-md-12 col-lg-12">
                    <div class="form-group">
                        <div class="form-label">
                            <label>Descrição<v-tooltip text="Campo obrigatório" location="top">
                                <template v-slot:activator="{ props }">
                                    <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                                </template>
                            </v-tooltip></label>
                        </div>
                        <div class="form-input">
                            <textarea
                                    class="form-control"
                                    v-model="videoData.description"
                                    placeholder="Descreva o vídeo"
                                    rows="4"
                            ></textarea>
                    </div>
                    </div>
                </div>
            </div>

            <div class="col-form-inner col-6 col-md-6 col-lg-6">
                <div class="preview-container">
                    <div class="video-preview" v-if="videoData.path">
                        <iframe 
                            v-if="videoData.type == 9"
                            :src="getYoutubeEmbedUrl(videoData.path)"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                        ></iframe>
                        <iframe 
                            v-if="videoData.type == 3"
                            :src="getVimeoEmbedUrl(videoData.path)"
                            frameborder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowfullscreen
                        ></iframe>
                        <div v-if="videoData.type == 2" class="video-wrapper">
                            <img :src="videoPlayer" alt="Video Player" />
                            <Loader v-if="!hasReady" />
                            <iframe 
                                v-show="hasReady"
                                :src="videoPlayer"
                                width="100%"
                                :height="heightPlayer"
                                frameborder="0"
                                allow="autoplay; fullscreen"
                                allowfullscreen>
                            </iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Loader v-if="isLoading || isSaving" />

        <div class="actions">
            <button class="btn" @click="$router.go(-1)">Voltar</button>
            <button class="btn" @click="updateVideo">Salvar</button>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import apiLesson from '@/utils/lesson';
import Loader from '@/components/Loader/loader.vue';
import playerVideo from '@/utils/storage/playerVideo';

export default {
    name: 'EditVideo',
    components: {
        Loader
    },
    data() {
        return {
            videoData: {
                id: '',
                type: 1,
                title: '',
                path: '',
                description: '',
                idModule: '',
                idCourse: '',
                status: 1
            },
            status: true,
            isLoading: true,
            isSaving: false,
            videoPlayer: '',
            hasReady: false,
            heightPlayer: 600
        }
    },
    computed: {
        ...mapState(['token'])
    },
    methods: {
        async loadLessonData() {
            const id = this.$route.params.slug;
            try {
                const response = await apiLesson.getDataLessonById(id, this.token);
                this.videoData = response;
                this.status = response.status === 1;
                if(response.type == 2){
                    this.getPlayerVideo();
                }
            } catch (error) {
                console.error('Erro ao carregar dados da lição:', error);
            } finally {
                this.isLoading = false;
            }
        },
        getYoutubeEmbedUrl(url) {
            if (!url) return '';
            try {
                const urlObj = new URL(url);
                const videoId = urlObj.searchParams.get('v');
                
                if (!videoId && urlObj.hostname.includes('youtu.be')) {
                    return `https://www.youtube.com/embed/${urlObj.pathname.slice(1)}`;
                }

                return videoId ? `https://www.youtube.com/embed/${videoId}` : '';
            } catch (error) {
                console.error('URL inválida:', error);
                return '';
            }
        },
        getVimeoEmbedUrl(url) {
            const videoId = url.split('/').pop();
            return videoId ? `https://player.vimeo.com/video/${videoId}` : '';
        },
        async updateVideo() {
            this.isSaving = true;
            
            const payload = {
                type: this.videoData.type,
                title: this.videoData.title,
                path: this.videoData.path,
                description: this.videoData.description,
                idQuiz: null,
                idModule: this.videoData.idModule,
                idCourse: this.videoData.idCourse,
                status: this.status ? 1 : 0
            };

            try {
                await apiLesson.editLesson(this.token, payload, this.videoData.id);
                this.$router.push('/dashboard/curso');
            } catch (error) {
                console.error('Erro ao atualizar vídeo:', error);
                alert('Ocorreu um erro ao atualizar o vídeo');
            } finally {
                this.isSaving = false;
            }
        },
        async deleteVideo() {
            if (confirm('Tem certeza que deseja excluir este vídeo?')) {
                const id = this.$route.params.slug;
                try {
                    await apiLesson.deleteLesson(id, this.token);
                    this.$router.push('/dashboard/curso');
                    alert('Vídeo excluído com sucesso!');
                } catch (error) {
                    console.error('Erro ao excluir vídeo:', error);
                    alert('Ocorreu um erro ao excluir o vídeo');
                }
            }
        },
        async getPlayerVideo(){
            const id = this.$route.params.slug;
            try {
                const response = await playerVideo.getPlayerVideo(this.token,id);
                this.videoPlayer = response;
                setTimeout(() => {
                    this.hasReady = true;
                }, 2000);
            } catch (error) {
                console.error('Erro ao obter o player:', error);
            }
        },
        verifyHeight(){
            const height = window.innerWidth;
            if(height < 450){
                this.heightPlayer = 300;
            } else {
                this.heightPlayer = 600;
            }
        }
    },
    created() {
        this.verifyHeight();
        this.loadLessonData();
    }
}
</script>

<style>
@import url(./global.css);

.container {
    max-width: 100%;
    background-color: white;
    padding: 10px 0px;
    padding-bottom: 50px;
}

.titleContainer {
    display: block;
    text-align: left;
    padding: 0px 10px;
    border-bottom: 1px solid #e8e8e8;
    margin: 10px;
    position: relative;
}

.btn-delete {
    position: absolute;
    top: 25px;
    right: 20px;
    background-color: #fee7e7;
    padding: 5px 10px;
    color: #dc3545;
    border-radius: 5px;
    cursor: pointer;
    border: none;
}

.btn-delete svg {
    color: #dc3545 !important;
}

.contetContainerEditVideo {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    max-width: 100%;
    width: 100%;
    padding: 10px;
    gap: 0px;
}

.actions {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    margin-top: 30px;
    padding: 0px 20px;
}

.btn {
    padding: 8px 20px;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid #e8e8e8;
    background: white;
    color: #666;
}

.btn:last-child {
    background: #250891;
    color: white;
    border: none;
}

.form-group {
    margin-bottom: 20px;
}

.form-label {
    margin-bottom: 8px;
}

.form-control {
    width: 100%;
    padding: 8px;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
}

.video-preview {
    width: 100%;
    aspect-ratio: 16/9;
    background: #f8f9fa;
    border-radius: 8px;
    overflow: hidden;
}

.video-preview iframe {
    width: 100%;
    height: 100%;
}

.video-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* DARK MODE */
.dashboard.dark .container.myWeflixEditVideo {
    background-color: #000 !important;
}

.dashboard.dark .container.myWeflixEditVideo h3,
.dashboard.dark .container.myWeflixEditVideo small,
.dashboard.dark .container.myWeflixEditVideo label {
    color: white !important;
}

.dashboard.dark .container.myWeflixEditVideo .form-control {
    background-color: #575757 !important;
    border: 1px solid #444 !important;
    color: white !important;
}
</style>
