<template>
    <div class="login-container">
        <div class="form-side">
            <div class="login-box">
                <div class="logo">
                    <img src="@/assets/logo.png" alt="Logo Weeduc" />
                </div>
                <form @submit.prevent="handleLogin">
                    <div class="form-group">
                        <label>Email</label>
                        <input 
                            type="email"
                            v-model="email"
                            placeholder="Digite seu email"
                            required
                        />
                    </div>
                    <div class="form-group">
                        <label>Senha</label>
                        <div class="password-input">
                            <input 
                                :type="showPassword ? 'text' : 'password'"
                                v-model="password"
                                placeholder="Digite sua senha"
                                required
                            />
                            <span class="password-toggle" @click="togglePassword">
                                <fa :icon="showPassword ? 'eye-slash' : 'eye'" />
                            </span>
                        </div>
                    </div>
                    <div class="links">
                        <a href="/resetpassworduser">Esqueci minha senha</a>
                    </div>
                    <button type="submit" class="btn-login">Entrar</button>
                </form>
                
            </div>
        </div>
        <div class="banner-side">
            <div class="banner-content">
                <!-- <h1>Bem-vindo à Plataforma</h1>
                <p>Acesse os melhores conteúdos educacionais</p> -->
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import userApi from '@/utils/user.js';
import loginApi from '@/utils/login.js';


export default {
    data() {
        return {
            email: '',
            password: '',
            token: '',
            refreshToken: '',
            typeIdLocal: null,
            idUser: null,
            websites: null,
            darkMode: false,
            typeSystemLocal: null,
            lististes: [],
            showPassword: false
        }
    },
    methods: {
        ...mapActions([
            'storeTypeSystem',
            'toggleDarkMode', 
            'storeToken',
            'storeRefreshToken',
            'storeTypeId',
            'storeWebsites',
            'storeUser'
        ]),
        togglePassword() {
            this.showPassword = !this.showPassword;
        },
        async handleLogin() {
            await this.getTokenManyCompany(0);
        },
        async getTokenManyCompany() {
            try {
                const response = await loginApi.token({
                    "email": this.email,
                    "password": this.password,
                    "domain": 'https://homolog.weeduc.com.br'
                }, {
                    withCredentials: false,
                });

                this.token = response.data.acessToken;
                this.typeIdLocal = response.data.type;
                this.refreshToken = response.data.refreshToken;
                this.idUser = response.data.user.id;
                this.websites = response.data.listWebsite[0];
                this.darkMode = this.websites.typeLayout == 2 ? true : false;
                this.typeSystemLocal = response.data.typeSystem;
                this.storeTypeSystem({typeSystem:response.data.typeSystem});
                this.toggleDarkMode({dark:this.darkMode});
                this.setItemStorage();
                this.storeToken({token: this.token})
                this.storeRefreshToken({refreshToken: this.refreshToken})
                this.storeTypeId({typeId: this.typeIdLocal})
                this.fetchUserData(this.idUser, this.token);
                this.storeWebsites({websites:this.websites});
                await this.getLogo();

            } catch (error) {
                console.error('Error fetching API:', error);
            }
        },
        setItemStorage() {
            localStorage.setItem('logado', 'true');
        },
        async fetchUserData(id, tokenAPI) {
            try {
                const userData = await userApi.getDataUser(id, tokenAPI);
                this.storeUser({user: userData});
                this.$router.push('/aluno/dashboard');
                
            } catch (error) {
                console.error('Erro ao obter dados do usuário:', error);
            }
        },
        async getLogo() {
            const aux = this.baseUrl.replace(/\/$/, '');
            const url = aux.split('/login')[0];
            const obj = { url: url };

            try {
                const response = await loginApi.logo(obj);
                this.logo = 'https://image.weeduc.com/'+response.logo;
                this.typeSystemLocal = response.typeSystem;
                this.storeTypeSystem({typeSystem:response.typeSystem});
            } catch (error) {
                console.error('Error fetching API:', error);
            }
        }
    }
}
</script>

<style scoped>
.login-container {
    display: flex;
    min-height: 100vh;
}

.banner-side {
    flex: 1;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    position: relative;
}


.banner-content {
    position: relative;
    text-align: center;
    padding: 1.25rem;
}

.banner-content h1 {
    font-size: 2.5em;
    margin-bottom: 0.9375rem;
}

.form-side {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    padding: 2.5rem;
    width: 35%;
}

.login-box {
    width: 100%;
    max-width: 25rem;
    background: white;
    padding: 2.5rem;
    border-radius: 0.5rem;
    box-shadow: 0 0.125rem 0.625rem rgba(0,0,0,0.1);
}

.logo {
    text-align: center;
    margin-bottom: 1.875rem;
}

.logo img {
    width: 12.5rem;
}

h2 {
    text-align: center;
    color: #272f3a;
    margin-bottom: 1.875rem;
}

.form-group {
    margin-bottom: 1.25rem;
}

label {
    display: block;
    margin-bottom: 0.5rem;
    color: #757e8a;
}

input {
    width: 100%;
    padding: 0.75rem;
    border: 0.0625rem solid #ddd;
    border-radius: 0.25rem;
    font-size: 0.875rem;
}

.password-input {
    position: relative;
    display: flex;
    align-items: center;
}

.password-toggle {
    position: absolute;
    right: 0.75rem;
    cursor: pointer;
    color: #757e8a;
}

.btn-login {
    width: 93%;
    padding: 0.75rem;
    background-color: #3db8bf;
    color: white;
    border: none;
    border-radius: 0.25rem;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
    margin: 10px;
}

.btn-login:hover {
    background-color: #555;
}

.links {
    margin-top: 10px;
    text-align: center;
    display: flex;
    justify-content: end;
    padding: 0px 13px;
}

.links a {
    color: #3db8bf;
    text-decoration: none;
    font-size: 12px;
}

.links a:hover {
    color: #333;
}

@media (max-width: 48rem) {
    .login-container {
        flex-direction: column;
    }
    
    .banner-side {
        display: none;
    }
    
    .form-side {
        padding: 1.25rem;
    }
    
    .login-box {
        padding: 1.25rem;
    }
}
.banner-side {
    background-image: url('@/assets/portal/Login-weeduc.png');
    background-size: cover;
    background-position: center;
    background-color: none!important;
}
</style>
