import api from '../api';

export default {
    async activeTrial(token) {
        try {
            const headers = {
                'Content-Type': 'application/json', 
                'Authorization': `Bearer ${token}`,
            };
            const response = await api.get(`/order/trial/activate`, {
                method: 'GET',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao ativar trial:', error);
                throw error;
        }
    },
    async checkout(payload, token) {
        try {
            const headers = {
                'Content-Type': 'application/json', 
                'Authorization': `Bearer ${token}`,
            };
            const response = await api.post(`/order/plan`, payload, {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao realizar checkout:', error);
            throw error;
        }
    },
    async validateCoupon(cupon, idCompany) {
        try {
            const headers = {
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/ecommerce/cupom/${cupon}/${idCompany}`, {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao validar cupom:', error);
            throw error;
        }
    },
    async checkoutCollection(payload, token) {
        try {
            const headers = {
                'Content-Type': 'application/json', 
                'Authorization': `Bearer ${token}`,
            };
            const response = await api.post(`/order/collection/individual`, payload, {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao realizar checkout:', error);
            throw error;
        }
    }
}