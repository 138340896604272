<template>
    <div class="container">
        <div class="titleContainer">
            <h3>Nova Temporada</h3>
            <small>Crie temparadas para organização dos videos em sua coleção</small>
        </div>
        <div class="contetContainerEditVideo">
            <div class="col-form-inner col-12 col-md-7 col-lg-7 freeVideo">
     
              <div class="col-form-inner col-12 col-md-12 col-lg-12">
                  <div class="form-group">
                    <div class="form-label">
                      <label>Nome da Temporada<v-tooltip text="Campo obrigatório" location="top">
                        <template v-slot:activator="{ props }">
                          <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                        </template>
                      </v-tooltip></label>
                    </div>
                    <div class="form-input">
                      <input
                        type="text" 
                        required
                        class="form-control"
                        v-model="name"
                        placeholder="video.mp4"
                      />
                      <small>Recomendamos o padrão de nomeclatura: <strong>Temporada 1</strong>, <strong>Temporada 2</strong>, ....</small>
                    </div>
                  </div>
            </div>
            </div>
        </div>
        <div class="actions">
            <button class="btn" @click="(()=>this.$router.push('/admin/colecao/'+this.currentCollections.id))">Cancelar</button>
            <button class="btn" @click="newSeason()">Salvar</button>
        </div>
      </div>

</template>

<script>
import { mapState } from 'vuex';
import ApiSesson from '@/utils/Weflix/sesson';
import Loader from '@/components/Weflix/Loader.vue';


export default {
    data(){
        return{
            name: '',
            hasReady: true,
        }
    },
    computed: {
        ...mapState(['token', 'currentCollections']),
    },
    methods: {
        async newSeason(){
            this.hasReady = false;
            const payload = {
                "course": {
                    "id": this.currentCollections.id
                },  
                "title": this.name,
                "sample": 0
            }
            try {
                const response = await ApiSesson.createSesson(this.token, payload);
                this.hasReady = true;
                this.$router.push('/admin/colecao/'+this.currentCollections.id);                
            } catch (error) {
                console.error(error);
            }
        }
    },
    components: {
        Loader
    },  
}
</script>

<style scoped>
@import url(./global.css);
.container {
    max-width: 100%;
 }
.titleContainer {
    display: block;
    text-align: left;
    padding: 0px 10px;
    border-bottom: 1px solid #e8e8e8;
    margin: 10px;
    
}
.container {
    background-color: white;
    padding: 10px 0px;
    padding-bottom: 50px;
}
.CollectionVideos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    padding: 10px;
}
.itemCollectionVideo {
    max-width: 30%;
    height: 248px;
    background-size: cover;
    min-width: 300px;
    width: 27%;
    cursor: pointer;
    position: relative;
    text-align: left;
    padding: 10px;
    border-radius: 5px;

}
.thumb-img path {
    fill: #ffffff!important;
    stroke: #aaaaaa!important;
    stroke-width: 1px!important;
}
img, svg {
    vertical-align: middle;
    width: 35px;
}
.UploadVideoContainer {
    /* max-width: 800px; */
    /* margin: 50px auto; */
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 20px;
    margin: 30px 15px;
}
.col-form-inner.col-12.col-md-12.col-lg-12.input-video {
    max-width: 800px;
    margin: 10px auto;
}
.uploadVideoIcon {
    vertical-align: middle;
    width: 46px;
    padding: 8px;
    border: 1px solid #e4e4e4;
    border-radius: 10px;
}
.infoCollectionVideos {
    text-align: left;
    padding: 0px 20px;
}
svg.svg-inline--fa {
    color: silver;
}
.infoCollectionVideos p {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 14px;
}
button.editCollections {
    position: absolute;
    top: 25px;
    right: 25px;
}
button.editCollections svg {
    transition: all 500ms ease-in-out;
}
button.editCollections:hover svg {
    color: #250891;
}
.itemCollectionVideo .actions {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    text-align: right;
    padding: 10px;
    background-color: transparent;
    transition: all 500ms ease-in-out;
}
button.hoverWhite svg{
    transition: all 500ms ease-in-out;
    font-size: 15px;
}
button.hoverWhite:hover svg{
    color: white;
}
.itemCollectionVideo p {
    color: #c8c8c8;
    margin: 0;
}
</style>
<style>
.v-card.v-theme--light.v-card--density-default.v-card--variant-elevated {
    padding: 35px 0px;
    /* text-align: left; */
}
.v-card-title.headline {
    text-align: left !important;
    color: red;
    padding: 0 40px;
}
button.v-btn.v-btn--slim.v-theme--light.text-green.darken-1.v-btn--density-default.v-btn--size-default.v-btn--variant-text {
    width: 150px;
    background-color: red;
    /* color: white; */
}
.v-card-actions button {
    width: 50%;
    background-color: gray;
    padding: 0 16px;
}
.v-overlay__content {
    width: 400px !important;
    max-width: 400px !important;
}
.v-card-actions {
    align-items: center;
    display: flex;
    flex: none;
    min-height: 52px;
    padding: 0 40px!important;
}
.cancel {
    background-color: white !important;
    border: 1px solid gray;
}
.accept {
    background-color: red !important;
    border: 1px solid red;
    color: white!important;
}
.contetContainerEditVideo {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}
.col-form-inner.col-12.col-md-7.col-lg-7.freeVideo .v-input__details {
    display: none;
}
.actions > button.btn {
    background-color: #21212100 !important;
    color: #250891 !important;
    border: 1px solid #250891 !important;
    font-size: 18px !important;
    font-weight: 100 !important;
    width: 146px;
    text-align: center;
    transition: all 500ms ease-in-out;

}
.actions {
    display: flex;
    justify-content: end;
    gap: 10px;
    padding: 0px 15px;
}
.actions > button.btn:hover {
    color: white !important;
    background-color: #250891 !important;
}
.exludeVideo {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
}
.exludeVideo svg {
    color: #250891 !important;
}
input.form-check-input {
    width: 3em !important;
}
.form-check-input:checked {
    background-color: #250891!important;
    border-color: #250891!important;
}
.col-form-inner.col-12.col-md-7.col-lg-7.freeVideo {
    display: flex;
    flex-wrap: wrap;
}
.itemAtionCollections.form-check.form-switch {
    padding: 16px 40px;
}
img.coverVideo {
    width: 300px;
    height: auto;
    margin: 0 auto;
    filter: brightness(0.5);
}
.form-group.imageCoverPreview {
    text-align: center;
    position: relative;
}
.info {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}
.info svg {
    font-size: 50px !important;
    color: white !important;
}
</style>


