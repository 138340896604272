<template>
  <div class="container panel-container weeducDashboardPanel">
    <div class="cardsContainer" >
      <div class="columns">
        <!-- Coluna 1 -->
        <div class="column" style="flex: 0.3">
          <div class="card-sm fastAccess">
            <div class="titleCard">
              <h4>Acesso Rápido</h4>
            </div>
            <button class="fastAccessBtn" @click="$router.push('/dashboard/novo-curso')">
              <fa icon="fa-plus"/>
              Novo Curso
            </button>
            <button class="fastAccessBtn" @click="$router.push('/admin/novo-aviso')">
              <fa icon="fa-bell"/>
              Novo Aviso
            </button>
            <button class="fastAccessBtn flat" @click="$router.push('/admin/relatorio-vendas')">
              Relatório de Vendas
            </button>
          </div>

          <div class="row accessInfo">
            <div class="card-sm" style="width: 100%;min-height: 100px;position: relative; cursor: pointer;" @click="$router.push('/dashboard/duvidas')">
              <div class="titleCard" v-if="hasReady">
                <h4>Dúvidas <fa icon="fa-arrow-right" style="float: right;"/></h4>
              </div>
              <div class="contentCard" v-if="hasReady">
                <h3>{{unansweredQuestions}}</h3>
                <p>Dúvidas não respondidas</p>
              </div>
              <Loader :isActive="hasReady"/>
            </div>
            <div class="card-sm" style="width: 100%;min-height: 100px;position: relative; cursor: pointer;" @click="$router.push('/dashboard/cursos')">
              <div class="titleCard" v-if="hasReady">
                <h4>Cursos <fa icon="fa-arrow-right" style="float: right;"/></h4>
              </div>
              <div class="contentCard" v-if="hasReady">
                <h3>{{availableCourses}}</h3>
                <p>Cursos disponíveis</p>
              </div>
              <Loader :isActive="hasReady"/>
            </div>

            <div class="card-sm" style="width: 100%;min-height: 100px;position: relative; cursor: pointer;" @click="$router.push('/admin/forum')">
              <div class="titleCard" v-if="hasReady">
                <h4>Fórum <fa icon="fa-arrow-right" style="float: right;"/></h4>
              </div>
              <div class="contentCard" v-if="hasReady">
                <h3>{{forumInteractions}}</h3>
                <p>Interações no fórum</p>
              </div>
              <Loader :isActive="hasReady"/>
            </div>
            <div class="card-sm" style="width: 100%;min-height: 100px;position: relative; cursor: pointer;" @click="$router.push('/admin/atividades')">
              <div class="titleCard" v-if="hasReady">
                <h4>Atividades <fa icon="fa-arrow-right" style="float: right;"/></h4>
              </div>
              <div class="contentCard" v-if="hasReady">
                <h3>{{activities}}</h3>
                <p>Atividades</p>
              </div>
              <Loader :isActive="hasReady"/>
            </div>
          </div>
        </div>

        <!-- Coluna 2 -->
        <div class="column" style="flex: 0.4">
          <div class="card-lg" style="min-height: 300px;position: relative;">
            <div class="titleCard">
              <h4 style="position: absolute; top: 20px; left: 20px">Alunos</h4>
              <h4 class="card-text-title" style="position: absolute; top: 20px; right: 20px" v-if="hasReady">{{new Intl.NumberFormat('pt-BR').format(newStudents + activeStudents)}}</h4>
            </div>
            <Chart v-if="hasReady" :title="'Quantidade de Alunos'" :nameChart="'Alunos'" :data="dataChartUser"/>
            <Loader :isActive="hasReady"/>
          </div>

          <div class="card-lg" style="min-height: 300px;position: relative;">
            <div class="titleCard">
              <h4 style="position: absolute; top: 20px; left: 20px">Vendas</h4>
              <h4 class="card-text-title" style="position: absolute; top: 20px; right: 20px" v-if="hasReady">{{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(revenue)}}</h4>    
            </div>
            <Chart v-if="hasReady" :title="'Vendas'" :nameChart="'Vendas'" :data="dataChartEnrol"/>
            <Loader :isActive="hasReady"/>
          </div>
        </div>

        <!-- Coluna 3 -->
        <div class="column" style="flex: 0.3">
          <div class="students-cards" style="min-height: 210px;position: relative;">
            <h4>Alunos</h4>
            <div class="row" v-if="hasReady">
              <div class="card-sm students" style="width: 100%">
                <div class="contentCard">
                  <p>Novos no mês</p>
                  <h3>{{newStudents}}</h3>
                </div>
              </div>

              <div class="card-sm students" style="width: 100%">
                <div class="contentCard">
                  <p>Alunos ativos</p>
                  <h3>{{activeStudents}}</h3>
                </div>
              </div>
            </div>
            <Loader :isActive="hasReady"/>
          </div>

          <div class="popular-courses" style="min-height: 300px;position: relative;">
            <div class="titleCard">
              <h4>Cursos Populares</h4>
              <small>Últimos 30 dias</small>
            </div>
            <div class="contentLastVideos" v-if="hasReady">
              <div class="subtitles">
                <small>Curso</small>
                <small>Matrículas</small>
              </div>
              <div class="contentVideo" v-for="course in popularCourses?.slice(0,5) || []" :key="course.id">
                <div class="videoContent">
                  <img src="@/assets/Weflix/img-long.png" alt="Imagem padrão">
                  <!-- <img :src="course.image" :alt="course.name"> -->
                  <h5>{{course.name}}</h5>
                </div>
                <h5>{{course.enrollments}}</h5>
              </div>
              <button class="see-all-btn" @click="$router.push('/dashboard/cursos')">Ver lista de cursos</button>
            </div>
            <Loader :isActive="hasReady"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from '@/components/Charts/chartPanel.vue'
import { mapState } from 'vuex'
import api from '@/utils/dashboard'
import Loader from '@/components/Loader/loader.vue'

export default {
  components: {
    Chart,
    Loader
  },
  
  data() {
    return {
      hasReady: false,
      dataChartUser: {},
      dataChartEnrol: {},
      newStudents: 0,
      activeStudents: 0,
      revenue: 0,
      unansweredQuestions: 0,
      forumInteractions: 0,
      popularCourses: [
        { id: 1, name: "Curso teste", enrollments: 10, }
      ],
      availableCourses: 0,
      activities: 0,
    }
  },

  computed: {
    ...mapState(['token', 'user'])
  },
  methods: {
    async fetchDashboardData() {
      try {
        const response = await api.getDataPnel(this.token)
        // i've had to mock data in order to preview the dashboard, might be needed to erase when the api is ready
        this.dataChartUser = response.students && response.students.some(item => item.value > 0) ? response.students : this.mockChartData('students');
        this.dataChartEnrol = response.sales && response.sales.some(item => item.value > 0) ? response.sales : this.mockChartData('sales');
        this.newStudents = response.studentsNew || 9;
        this.activeStudents = response.studentsActive || 8;
        this.revenue = (response.sales && response.sales.length > 0) ? response.sales.reduce((total, sale) => total + sale.value, 0) : 7;
        this.unansweredQuestions = response.inquiries || 6;
        this.forumInteractions = response.forumInteractions || 5;
        this.activities = response.activities || 4;
        this.availableCourses = response.courses
        // this.popularCourses = response.coursesPopular

        this.hasReady = true
      } catch (error) {
        console.error('Erro ao carregar dados do dashboard:', error)
        this.hasReady = true
      }
    },
    // mocked data just for testing purposes remember to delete later on
    mockChartData(type) {
        if (type === 'students') {
          return [
            { month: 'January', value: 10 },
            { month: 'February', value: 15 },
            { month: 'March', value: 20 },
            { month: 'April', value: 25 },
          ];
        } else if (type === 'sales') {
          return [
            { month: 'January', value: 800.0 },
            { month: 'February', value: 1800.0 },
            { month: 'March', value: 600.0 },
            { month: 'April', value: 400.0 },
          ];
        }
        return [];
      },
  },
  async created() {
    await this.fetchDashboardData()
  },
}
</script>

<style>
.panel-container .apexcharts-canvas.apexcharts-theme- {
    width: 100%;
    max-width: 510px;
    height: 300px;
  }
</style>

<style scoped>
.content.dashboard > .container {
    padding-bottom: 0;
       background-color: #f8fbff !important;
}

.container {
  max-width: 1440px;
  margin: 0 auto;
  background-color: #ffffff;
  padding: 2rem 1rem;
  min-height: 100vh;
  box-shadow: 0 0 10px rgba(0,0,0,0.05);
}

.columns {
  display: flex;
  gap: 1.5rem;
  padding: 20px;
}

.column {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.cardsContainer .row {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 0;
  margin-bottom: 1.5rem;
}

.card-sm {
  width: calc(50% - 0.75rem);
  padding: 10px 20px;
  border-radius: 12px;
  text-align: left;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.card-sm:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

/* Fix: dashboard/painel style */

.weeducDashboardPanel .card-sm {
  border: 1px solid #e0e0e0;
}

.weeducDashboardPanel .card-sm.students {
  border: none;
}

.weeducDashboardPanel .card-sm.fastAccess {
  border: none;
}

.weeducDashboardPanel .contentCard h3 {
  color: #160266 !important;
}

.weeducDashboardPanel .contentCard p {
  color: #666666 !important;
}

.weeducDashboardPanel .videoContent h5 {
    color: #2C3E50 !important;
}

.weeducDashboardPanel .titleCard svg {
  display: none;
}

.weeducDashboardPanel .card-lg {
  border: 1px solid #e0e0e0;
}

.weeducDashboardPanel .card-text-title {
  color: #160266 !important;
}

.weeducDashboardPanel .students-cards {
  border: 1px solid #e0e0e0;
}

.weeducDashboardPanel .popular-courses {
  border: 1px solid #e0e0e0;
}

.weeducDashboardPanel .see-all-btn {
  color: #160266 !important;
}

.weeducDashboardPanel button.see-all-btn:hover {
  background-color: #250991;
  color: #ffffff !important;
}

/* Fix: dashboard/painel style */

.card-lg {
  width: 100%;
  padding: 15px;
  border-radius: 12px;
  text-align: left;
  background-color: #ffffff;
  position: relative;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.card-lg:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.card-sm.fastAccess {
    width: 100%;
    background-color: transparent;
    padding: 0;
    box-shadow: none;
}

.contentCard {
    padding: 0;
    text-align: left;
    display: flex
;
    gap: 10px;
    align-items: center;
}

.contentCard h3 {
  font-size: 1.75rem;
  font-weight: 700;
  color: #1a1a1a;
  margin-bottom: 0rem;
}

.contentCard p {
  color: #666666;
  font-size: 0.875rem;
  margin-bottom: 0rem;
}

.titleCard {
  text-align: left;
  margin: 1rem 0;
  margin-bottom: 1.5rem;
}

.titleCard h4 {
  font-size: 1rem;
  font-weight: 600;
  color: #1a1a1a;
}

.titleCard small {
  color: #666666;
  font-size: 0.75rem;
}

button.fastAccessBtn {
    width: 100%;
    background-color: #160266;
    margin: 0.75rem 0;
    padding: 1rem;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.875rem;
    font-weight: 600;
    color: #ffffff;
    transition: all 0.2s ease;
    border: none;
    cursor: pointer;
    gap: 0.5rem;
}

button.fastAccessBtn:hover {
  background-color: #250991;
  color: #ffffff;
}

button.fastAccessBtn svg {
  color: inherit;
}

.students-cards h4 {
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 600;
  color: #1a1a1a;
}

.popular-courses {
  background-color: #ffffff;
  padding: 10px 20px;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.contentVideo {
  display: flex;
  justify-content: space-between;
  margin: 5px 0px;
  align-items: center;
  padding: 0.5rem;
  border-radius: 8px;
  transition: all 500ms ease-in-out;
}

.contentVideo:hover {
  background-color: #f3f9f9;
  cursor: pointer;
  transform: translateX(5px);
}

.videoContent {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.videoContent img {
    width: 80px;
    height: 50px;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.videoContent h5 {
      font-size: 12px;
    font-weight: 600;
    color: #929292;
}
.contentVideo h5 {
    font-size: 15px;
}

.subtitles {
  display: flex;
  justify-content: space-between;
  padding: 0 0.5rem;
  margin-bottom: 0.5rem;
}

.subtitles small {
  font-size: 0.75rem;
  color: #666666;
  font-weight: 500;
}

.see-all-btn {
    width: 100%;
    background-color: #ffffff;
    color: #9b9b9b;
    padding: 11px;
    border-radius: 8px;
    margin-top: 1rem;
    font-weight: 600;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease;
    font-size: 11px;
    margin-bottom: 8px;
}

.see-all-btn:hover {
  background-color: #250991;
  color: #ffffff;
}

@media (max-width: 768px) {
  .columns {
    flex-direction: column;
  }
  
  .column {
    flex: none !important;
  }

  .card-sm {
    width: 100%;
  }

  .container {
    padding: 1rem;
  }
}
.students-cards {
    background-color: white;
    padding: 15px;
    border-radius: 10px;
    text-align: left;
}
.card-sm.students {
    background-color: #f3f9f9;
}
.card-sm.students .contentCard {
    justify-content: space-between;
}
.popular-courses .titleCard {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
button.fastAccessBtn.flat {
    background-color: transparent;
    color: #160565;
    border: 2px solid #160565;
}

button.fastAccessBtn.flat:hover {
    background-color: #160565;
    color: #ffffff;
}
.contentCard p[data-v-0264c20a] {
    color: #160565;
    font-size: 15px;
    margin-bottom: 0rem;
    font-weight: 700;
}
.card-sm.students .contentCard h3 {
    color: #160565;
}
.row.accessInfo .contentCard h3 {
    font-size: 1.75rem;
    font-weight: 700;
    color: #1ac5cf;
    margin-bottom: 0rem;
}
/* DARK */
.dashboard.dark .fastAccessBtn.flat {
  background-color: #575757;
  color: #ffffff;
  border: 2px solid #160565;
  transition: all 0.5s ease-in-out;
}
.dashboard.dark .fastAccessBtn.flat:hover {
  background-color: #160565;
  color: #fff;
}
.dashboard.dark .content.dashboard > .container {
  background-color: #575757 !important;
  border-radius: 10px;
}
.dashboard.dark .weeducDashboardPanel .titleCard h4 {
  color: white;
}
.dashboard.dark .weeducDashboardPanel .contentCard p {
  color: white !important;
}
.dashboard.dark .weeducDashboardPanel .students-cards h4 {
  color: white;
}
.dashboard.dark .weeducDashboardPanel .titleCard small {
  color: white;
}
.dashboard.dark .weeducDashboardPanel .subtitles small {
  color: white;
}
.dashboard.dark .weeducDashboardPanel .card-text-title,
.dashboard.dark .weeducDashboardPanel .contentCard h3 {
  color: white !important;
}
.dashboard.dark .weeducDashboardPanel .videoContent h5 {
  color: white !important;
}
.dashboard.dark .weeducDashboardPanel .contentVideo h5 {
  color: white !important;
}
.dashboard.dark .weeducDashboardPanel .contentVideo:hover {
  background-color: #575757;
}
.dashboard.dark .weeducDashboardPanel .row.accessInfo .card-sm {
  border: 1px solid #444444;
  background-color: #444;
}
.dashboard.dark .weeducDashboardPanel .column .students-cards {
  border: 1px solid #444444;
  background-color: #444;
}
.dashboard.dark .weeducDashboardPanel .card-sm.students {
  background-color: #575757;
}
.dashboard.dark .weeducDashboardPanel .popular-courses {
  background-color: #444;
  border: 1px solid #444444;
}
.dashboard.dark .weeducDashboardPanel .card-lg {
  background-color: #444;
  border: 1px solid #444444;
}
.dashboard.dark .weeducDashboardPanel .see-all-btn {
  background-color: #160565 !important;
  color: white !important;
}
.dashboard.dark .weeducDashboardPanel .see-all-btn:hover {
  background-color: #250991 !important;
  color: white;
}
</style>
<style>
.panel-container button.fastAccessBtn svg path {
    fill: #ffffff!important;
}
.panel-container .loader1 {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 1px solid #250891!important;
  animation: l20-1-a9184be2 0.8s infinite linear alternate, l20-2-a9184be2 1.6s infinite linear;
  position: absolute;
  top: 27%!important;
  left: 44%!important;
  transform: translate(-50%, -50%);
}
.row.accessInfo svg.svg-inline--fa.fa-arrow-right {
  color: #160565;
}
</style>