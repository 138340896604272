<template>
  <div class="login" :class="{'weflix': typeSystemLocal==2}">
    <div class="content">
    
      <div class="info" style="cursor: default;">
        <div class="logo">
          <img alt="Weeduc" src="@/assets/Weflix/Weflix-Logo-Total-Branco.png" style="width: 200px" v-show="isWeflix" />
          <img alt="Weeduc" src="@/assets/logo_white.png" style="width: 200px" v-show="isWeeduc" />
        </div>
        <div class="company" v-show="numberSites>1">
          <h5 class="company-title">Olá, {{userName}}</h5>
          <p class="company-subtitle">O que você deseja acessar?</p>
          <v-select
            v-model="selectedCompany"
            :options="filteredCompanies"
            :filterable="false"
            @search="(val) => filterText = val"
            placeholder="Selecionar Empresa"
            class="company-select"
          >
            <template #selected-option="{ label }">
              {{ label.length > 20 ? label.substring(0,20) + '...' : label }}
            </template>
            <template #no-options>
              Nenhuma empresa encontrada
            </template>
          </v-select>
          <div class="action">
            <button class="btn-success" :class="{'weflix': typeSystemLocal==1 || typeSystemLocal==2}" @click="getTokenManyCompany(filteredCompanies.indexOf(selectedCompany))">Acessar</button>
          </div>
        </div>

        <div class="form" v-show="numberSites==1">
          <form @submit.prevent="handleSubmit" class="form">
            <div class="mb-3">
              <label for="exampleInputEmail1" class="form-label">E-mail</label>
              
              <input
                type="text"
                v-model="email"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
              />
            </div>
            <div class="mb-3 password">
              <label for="exampleInputPassword1" class="form-label"
                >Senha</label
              >
              <input
                v-model="password"
                :type="showPassword"
                class="form-control"
                id="exampleInputPassword1"
              />

              <fa icon="eye"          
                style="cursor: pointer"
                v-show="showPassword=='text'"
                @click="changeViewPassword()"
                />
                <fa icon="eye-slash"          
                style="cursor: pointer"
                v-show="showPassword=='password'"
                @click="changeViewPassword()"
                />
            </div>
            <div class="mb-3 forgot_password">
              <label for="exampleInputPassword1" class="form-label">
                <a href="/forgotpassword" :class="{'weflix': typeSystemLocal==2}">Esqueci minha senha</a></label
              >
            </div>
            <div class="erro" v-show="erroLgin">
              <small>{{mensageErro}}</small>
            </div>
            <div class="mb-5 text-center">
              <button type="submit" class="btn-success" :class="{'weflix': typeSystemLocal==1 || typeSystemLocal==2}">Entrar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import  { mapState, mapActions } from 'vuex';
import loginApi from '@/utils/login.js';
import userApi from '@/utils/user.js';
import ApiWeflix from '@/utils/Weflix/Website/index.js';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

export default {
  components: {
    vSelect
  },
  data() {
    return {
      email: '',
      password: '',
      typeIdLocal:null,
      users:null,
      credentials:null,
      token: null,
      websites:null,
      refreshToken:null,
      idUser:null,
      showPassword:'password',
      lististes:null,
      numberSites:1,
      logo:null,
      selectedCompany: null,
      darkMode:null,
      userName:null,
      mensageErro:'Erro ao realizar login, usuário ou senha incorretos.',
      erroLgin: false,
      weflixPageLogin:false,
      typeSystemLocal:1,
     baseUrl: process.env.NODE_ENV === 'development' ? process.env.VUE_APP_BASE_URL_HOMOLOGATION  : window.location.origin,

      filterText: '',
      filteredCompanies: []
    };
  },
  computed: {
    ...mapState(['typeId','numberIntensTable','typeSystem']),
  },
  methods: {
  ...mapActions(['storeTypeId','login','storeToken','storeWebsites','storeRefreshToken','storeUser','toggleDarkMode','storeNumberIntensTable','storeTypeSystem','storeCompanys']),
  changeViewPassword(){
    if(this.showPassword=='password'){
      this.showPassword='text';
    } else {
      this.showPassword='password';
    }
  },
  async fetchUsers() {
    try {
      const response = await loginApi.token({
        "email": this.email,
        "password": this.password,
      }, {
        withCredentials: false,
      });
      
      this.userName = response.data.user.name;

      console.debug(response.data);


      if(response.data.user.type == 1 || response.data.user.type == 2) {
        if(response.data.listWebsite.length>1) {
          this.storeCompanys({companys:response.data.listWebsite});
          this.listCompany = response.data.listWebsite.map(site => site.nome);
          this.numberSites =  response.data.listWebsite.length;
          this.lististes = response.data.listWebsite;
          this.filteredCompanies = response.data.listWebsite.map(site => site.nome);
        } else {
          this.lististes = response.data.listWebsite;
          this.getTokenManyCompany(0);
          await this.getLogo();
        }
      } else {
        alert('Não é possível realizar login com esse tipo de usuário, por gentileza realize o login pelo site.');
      }
  } catch (error) {
    this.erroLgin = true;
    console.error('Error fetching API:', error);
  }
},
  async getTokenManyCompany(index){
      try {
        if (!this.lististes || !this.lististes[index]) {
          console.error('Invalid index or lististes not initialized');
          return;
        }

        const response = await loginApi.token({
          "email": this.email,
          "password": this.password,
          "domain": this.lististes[index].url
        }, {
          withCredentials: false,
        });

        this.token = response.data.acessToken;
        this.typeIdLocal =  response.data.type;
        this.refreshToken =  response.data.refreshToken;
        this.idUser = response.data.user.id;
        this.websites = response.data.listWebsite[0];
        this.darkMode = this.websites.typeLayout == 0 ? true : false;
        this.typeSystemLocal = response.data.typeSystem;
        this.storeTypeSystem({typeSystem:response.data.typeSystem});
        this.toggleDarkMode({dark:this.darkMode});
        this.setItemStorage();
        this.storeToken({token: this.token})
        this.storeRefreshToken({refreshToken: this.refreshToken})
        this.storeTypeId({typeId: this.typeIdLocal})
        this.fetchUserData(this.idUser,  this.token);
        this.storeWebsites({websites:this.websites});  
        await this.getLogo();

      } catch (error) {
        console.error('Error fetching API:', error);
      }
  },
  async fetchUserData(id, tokenAPI) {
      try {
        const userData = await userApi.getDataUser(id,tokenAPI);
        this.storeUser({user: userData});
        this.handleRedirect(userData.type);
      } catch (error) {
        console.error('Erro ao obter dados do usuário:', error);
      }
  },
  handleSubmit() {
    this.fetchUsers();
  },
  setItemStorage(){
    localStorage.setItem('logado', 'true');
  },

  handleRedirect(typeId){
    // if(window.location.origin === 'http://localhost:8080'){
    //   this.$router.push({ path: '/dashboard/painel' });
    //   return;
    // }
    // if (window.location.hostname === '/admin/painel') {
    //   this.$router.push({ path: '/dashboard/painel' })
    // }

    if(this.typeSystemLocal==1){
      typeId==2 || typeId== 1 ? this.$router.push({ path: '/dashboard/painel' }) : this.$router.push({ path: '/aluno' });
    } else {
        if(typeId == 2 || typeId == 1 ){
          this.$router.push({ path: '/admin/painel' });
        } else {
          alert('Não é possível realizar login com esse tipo de usuário, por gentileza realize o login pelo site.');
        }
    }
  },

  setNumberItensPageOnLoad(){
    if(window.innerHeight>680){
      this.storeNumberIntensTable({numberIntensTable:8});
    } else if (window.innerHeight<650 && window.innerHeight>450){ 
      this.storeNumberIntensTable({numberIntensTable:5});
    } else {
      this.storeNumberIntensTable({numberIntensTable:4});

    }
  },

  async getLogo(){
    const aux = this.baseUrl.replace(/\/$/, '');
    const url = aux.split('/login')[0];
    const obj = { url: url }; 

    try {
      const response = await loginApi.logo(obj); 
      this.logo = 'https://image.weeduc.com/'+response.logo;
      this.typeSystemLocal = response.typeSystem;
      this.storeTypeSystem({typeSystem:response.typeSystem});
    } catch (error) {
      console.error('Error fetching API:', error);
    } 
  },
  filterFunction() {
    if (!this.lististes) {
      this.filteredCompanies = [];
      return;
    }
    const filter = this.filterText.toUpperCase();
    this.filteredCompanies = this.lististes
      .filter(site => site && site.nome && site.nome.toUpperCase().includes(filter))
      .map(site => site.nome);
  }
  },
  mounted(){
    this.setNumberItensPageOnLoad();
    this.getLogo();
  },
  computed: {
    isWeflix() {
      return window.location.origin === 'https://app.weflix.com.br' || window.location.origin === 'http://localhost:8080' || window.location.origin === 'https://homolog.weflix.com.br';
    },
    isWeeduc() {
      return window.location.origin === 'https://app.weeduc.com.br' || window.location.origin === 'http://localhost:8080' || window.location.origin === 'https://homolog.weeduc.com.br';
    }
  },
  watch: {
    filterText: {
      handler(val) {
        this.filterFunction();
      },
      immediate: true
    }
  }
}
</script>

<style scoped>
.item{
  border:none;
}
.action {
  display: flex;
  justify-content: end;
  padding: 15px 0 5px;
}
.form {
  max-width: 100%;
  margin: 0 30px;
  text-align: left;
  height: 241px;
  position: relative;
}
.login {
  height: 100vh;
  display: flex;
  background-image: url("../../assets/Weflix/bg-login-weflix.png");
  background-size: cover;
}
.login.weflix {
  height: 100vh;
  display: flex;
  background-image: url("../../assets/Weflix/bg-login-weflix.png");
  background-size: cover;
}
.mb-5.text-center.btn {
  width: 100%;
  position: absolute;
  bottom: 0;
}
.logo {
    display: flex;
    justify-content: center;
    margin: 20px;
    margin-top: -120px;
    margin-bottom: 50px;
    transform: translateY(-15px);
}
.content {
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 10px;
}
.info {
  width: 450px;
  padding: 55px 10px;
  background-color: white;
  border-radius: 10px;
}
button.btn.btn-success {
  width: 100%;
}
.mb-3 {
  margin: 20px 0px;
}
.mb-3.forgot_password {
  color: red;
  margin: 0 !important;
  font-size: 11px;
  text-decoration: none;
  display: flex;
  justify-content: end;
}
.mb-3.forgot_password a {
  color: #19c7d0;
}
.mb-3.password {
  position: relative;
}
.mb-3.password i {
  position: absolute;
  top: 45px;
  right: 10px;
  color: #b4b4b4;
}
.company {
  max-width: 100%;
  margin: 0px;
  text-align: left;
  position: relative;
  padding: 20px;
  border-radius: 8px;
}
.company-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333;
  font-weight: 600;
}
.company-subtitle {
  font-size: 1rem;
  color: #666;
  margin-bottom: 20px;
}
.company-select {
  margin-bottom: 15px;
}
.company button.btn-success {
  margin-top: 15px;
  padding: 12px;
  font-size: 1rem;
  font-weight: 500;
  border: none;
  transition: all 0.3s ease;
}
.company button.btn-success:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}
.option-company {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  padding: 12px 0;
}
.erro small {
  color: red;
}
.option-company:last-child {
  border: none;
}

@media (max-width:600px) {
  .info {
    width: 87%;
    padding: 30px;
  }

  .logo {
    margin-top: -90px;
    margin-bottom: 50px;
  }
  .form {
    margin: 0px 15px;
  }
  .option-company:last-child {
    border: none;
  }
  .company {
    max-width: 100%;
    margin: 0 16px;
    padding: 15px;
  }
  .company-title {
    font-size: 1.3rem;
  }
  .company-subtitle {
    font-size: 0.9rem;
  }
}
svg.svg-inline--fa.fa-eye, svg.svg-inline--fa.fa-eye-slash {
  position: absolute;
  top: 45px;
  right: 5px;
  font-size: 15px!important;
  color: #dee2e6!important;
}
button.btn-success.weflix {
  background-color: #0d1a7e !important;
  width: 100%;
  color: white;
  padding: 10px;
  border-radius: 5px;
}
a.weflix {
  color: #0d1a7e !important;
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {background-color: #f1f1f1}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}
@media (max-width:600px) {
  .info {
    width: 87%;
    padding: 10px;
  }
  
}
</style>
<style>
.vs__actions > button.vs__clear svg, .vs__actions > svg.vs__open-indicator {
  width: 17px;
  /* color: red; */
}
input.vs__search {
  position: absolute;
  width: 100%;
  height: 20px;
}
div#vs7__combobox {
  min-height: 30px;
}
.vs__selected-options {
  height: 24px;
}
</style>