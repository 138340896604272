import api from './api';

export default {
    async getCollection(accessToken) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/course`, {
                method: 'GET',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados das coleções:', error);
                throw error;
        }
    },
    async newCollection(accessToken, payload) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.post(`/course`, payload, {
                method: 'POST',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao criar Coleção:', error);
                throw error;
        }
    },
    async getCollectionById(accessToken, id) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/course/${id}`, {
                method: 'GET',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados da coleção:', error);
                throw error;
        }
    },
    async editCollection(accessToken, id, payload) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.put(`/course/${id}`, payload, {
                method: 'PUT',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao editar dados da coleção:', error);
                throw error;
        }
    },
    async updateCollectionOrder(accessToken, payload) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.post(`/course/order`, payload, {
                method: 'POST',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao editar ordem da coleção:', error);
                throw error;
        }
    },
    async getCollectionByLogin(accessToken, page = 0) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/course/owner?page=${page}&size=12`, {
                method: 'GET',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados das coleções:', error);
                throw error;
        }
    },
    async getCollectionWithOutLessons(payload, page = 0) {
        try {
            const headers = {
                'Content-Type': 'application/json', 
            };
            const response = await api.post(`/ecommerce/collection?page=${page}&size=12`, payload, {
                method: 'POST',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados das coleções:', error);
                throw error;
        }
    },
    async getCollectionTop3(payload, page = 0) {
        try {
            const headers = {
                'Content-Type': 'application/json', 
            };
            const response = await api.post(`/ecommerce/collection/top3`, payload, {
                method: 'POST',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados das coleções:', error);
                throw error;
        }
    },
    async deleteCollection(accessToken, id) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.delete(`/course/${id}`, {
                method: 'DELETE',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao excluir coleção:', error);
            throw error;
        }
    },
}