import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    isAuthenticated: false,
    typeId: null,
    token: null,
    refreshToken: null,
    user: null,
    company: [],
    websites: [],
    IdCourse: null,
    dark: false,
    courseWebsite: null,
    courseCart: null,
    typeLessonPortal: null,
    idLessonPortal: null,
    currentModuleOpen: null,
    currentLesson: null,
    currentModule: null, // Alterado de array para null
    nameCurrentLesson: null,
    dataWebsite: [],
    dataContenWebsite: [],
    dataCoursesWebsite: [],
    dataTestimoniesWebsite: [],
    dataSocialMedia: [],
    dataPayment: [],
    dataUserPayment: [],
    typePayment: null,
    numberIntensTable: 0,
    currentCoursePortal:[],
    showSidebar: true,
    listCoursesPortal:[],
    cacheDate: null,
    cleanCache: false,
    sideBarState: false,
    doubt: null,
    forum: null,
    noLogin: false,

    //Weflix
    currentCollections: [],
    userPortalWeflix: null,
    finalWebsite: null,
    typeSystem: null,
    panelData: null,
    contentSiteWeflix: null,
    plansWeflix: [],
    itemCheckoutWeflix: null,
    trialWeflix: null,
    activeTrial: false,
    dataPaymentWeflix: [],
    collectionCheckout: null,
    hasSinglePurchase: false,
    
    //General
    companys: [],


    //SuperAdmin
    userSuperAdmin: null,
    currentCompanyCreated: null,
  },
  mutations: {
    TOGGLE_DARK_MODE(state, dark) {
      state.dark = dark;
    },
    SET_IDCOURSE(state, IdCourse) {
      state.IdCourse = IdCourse;
    },
    setAuthenticated(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated;
    },
    SET_TYPE_ID(state, typeId) {
      state.typeId = typeId;
    },
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_REFRESH_TOKEN(state, refreshToken) {
      state.refreshToken = refreshToken;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    SET_COMPANY(state, company) {
      state.company = company;
    },
    SET_WEBSITES(state, websites) {
      state.websites = websites;
    },
    SET_COURSE_WEBSITE(state, courseWebsite) {
      state.courseWebsite = courseWebsite;
    },
    SET_COURSE_CART(state, courseCart) {
      state.courseCart = courseCart;
    },
    SET_TYPE_LESSON_PORTAL(state, typeLessonPortal) {
      state.typeLessonPortal = typeLessonPortal;
    },
    SET_ID_LESSON_PORTAL(state, idLessonPortal) {
      state.idLessonPortal = idLessonPortal;
    },
    SET_CURRENT_MODULE_OPEN(state, currentModuleOpen) {
      state.currentModuleOpen = currentModuleOpen;
    },
    SET_CURRENT_MODULE(state, currentModule) {
      state.currentModule = currentModule;
    },
    SET_CURRENT_LESSON(state, currentLesson) {
      state.currentLesson = currentLesson;
    },
    SET_NAME_CURRENT_LESSON(state, nameCurrentLesson) {
      state.nameCurrentLesson = nameCurrentLesson;
    },
    SET_DATA_WEBSITE(state, dataWebsite) {
      state.dataWebsite = dataWebsite;
    },
    SET_DATA_CONTENT_WEBSITE(state, dataContenWebsite) {
      state.dataContenWebsite = dataContenWebsite;
    },
    SET_DATA_COURSES_WEBSITE(state, dataCoursesWebsite) {
      state.dataCoursesWebsite = dataCoursesWebsite;
    },
    SET_DATA_TESTIMONIES_WEBSITE(state, dataTestimoniesWebsite) {
      state.dataTestimoniesWebsite = dataTestimoniesWebsite;
    },
    SET_DATA_SOCIAL_MEDIA(state, dataSocialMedia) {
      state.dataSocialMedia = dataSocialMedia;
    },
    SET_DATA_PaYMENT(state, dataPayment) {
      state.dataPayment = dataPayment;
    },
    SET_DATA_USER_PAYMENT(state, dataUserPayment) {
      state.dataUserPayment = dataUserPayment;
    },
    SET_TYPE_PAYMENT(state, typePayment) {
      state.typePayment = typePayment;
    }, 
    SET_NUMBER_INTENS_TABLE(state, numberIntensTable) {
      state.numberIntensTable = numberIntensTable;
    },
    SET_CURRENT_COURSE_PORTAL(state, currentCoursePortal) {
      state.currentCoursePortal = currentCoursePortal;
    },
    SET_SHOWSIDEBAR(state, showSidebar) {
      state.showSidebar = showSidebar;
    }, 
    SET_LIST_COURSES_PORTAL(state, listCoursesPortal) {
      state.listCoursesPortal = listCoursesPortal;
    },
    SET_CACHE_DATE(state, cacheDate) {
      state.cacheDate = cacheDate;
    },
    SET_CLEAN_CACHE(state, cleanCache) {
      state.cleanCache = cleanCache;
    },
    SET_SIDEBAR_STATE(state, sideBarState) {
      state.sideBarState = sideBarState;
    },
    RESET_STATE(state) {
      state.isAuthenticated = false;
      state.typeId = null;
      state.token = null;
      state.refreshToken = null;
      state.user = null;
      state.company = [];
      state.websites = [];
      state.IdCourse = null;
      state.courseCart = null;
      state.typeLessonPortal = null;
      state.idLessonPortal = null;
      state.currentModuleOpen = null;
      state.currentLesson = null;
      state.currentModule = null; // Adicionado reset do currentModule
      state.nameCurrentLesson = null;
      state.dataPayment = [];
      state.dataUserPayment = [];
      state.typePayment = null;
      state.companys = [];
    },
    LOGOUT_PORTAL_WEFLIX(state) {
      state.userPortalWeflix = null;
    },
    SET_NO_LOGIN(state, noLogin) {
      state.noLogin = noLogin;
    },
    //Weflix
    SET_CURRENT_COLLECTIONS(state, currentCollections) {
      state.currentCollections = currentCollections;
    },
    SET_USER_PORTAL_WEFLIX(state, userPortalWeflix) {
      state.userPortalWeflix = userPortalWeflix;
    },
    SET_FINAL_WEBSITE(state, finalWebsite) {
      state.finalWebsite = finalWebsite;
    },
    SET_TYPE_SYSTEM(state, typeSystem) {
      state.typeSystem = typeSystem;
    },
    SET_PANEL_DATA(state, panelData) {
      state.panelData = panelData;
    },
    SET_CONTENT_SITE_WEFLIX(state, contentSiteWeflix) {
      state.contentSiteWeflix = contentSiteWeflix;
    },
    SET_PLANS_WEFLIX(state, plansWeflix) {
      state.plansWeflix = plansWeflix;
    },
    SET_ITEM_CHECKOUT_WEFLIX(state, itemCheckoutWeflix) {
      state.itemCheckoutWeflix = itemCheckoutWeflix;
    },
    LOGOUT_WEFLIX(state) {
      state.panelData = null;
      state.token = null;
      state.refreshToken = null;
      state.user = null;
      state.websites = null;
      state.companys = [];
    },
    SET_TRIAL_WEFLIX(state, trialWeflix) {
      state.trialWeflix = trialWeflix;
    },
    SET_ACTIVE_TRIAL(state, activeTrial) {
      state.activeTrial = activeTrial;
    },
    SET_DATA_PAYMENT_WEFLIX(state, dataPaymentWeflix) {
      state.dataPaymentWeflix = dataPaymentWeflix;
    },
    SET_COLLECTION_CHECKOUT(state, collectionCheckout) {
      state.collectionCheckout = collectionCheckout;
    },
    SET_HAS_SINGLE_PURCHASE(state, hasSinglePurchase) {
      state.hasSinglePurchase = hasSinglePurchase;
    },

    //SuperAdmin
    SET_USER_SUPER_ADMIN(state, userSuperAdmin) {
      state.userSuperAdmin = userSuperAdmin;
    },
    LOGOUT_SUPER_ADMIN(state) {
      state.userSuperAdmin = null;
      state.token = null;
    },
    SET_CURRENT_COMPANY_CREATED(state, currentCompanyCreated) {
      state.currentCompanyCreated = currentCompanyCreated;
    },

    //General
    SET_COMPANYS(state, companys) {
      state.companys = companys;
    },
    SET_DOUBT(state, doubt) {
      state.doubt = doubt;
    },
    SET_FORUM(state, forum) {
      state.forum = forum;
    },
  },
  actions: {
    storeShowSidebar({ commit }, payload) {
      commit('SET_SHOWSIDEBAR', payload.showSidebar);
    }, // Corrigido a falta de fechamento da chave
    toggleDarkMode({ commit }, payload) {
      commit('TOGGLE_DARK_MODE', payload.dark);
    },
    storeIdCourse({ commit }, payload) {
      commit('SET_IDCOURSE', payload.IdCourse);
    },
    storeAuthenticated({ commit }, payload) {
      commit('setAuthenticated', payload.isAuthenticated);
    },
    storeTypeId({ commit }, payload) {
      commit('SET_TYPE_ID', payload.typeId);
    },
    storeToken({ commit }, payload) {
      commit('SET_TOKEN', payload.token);
    },
    storeRefreshToken({ commit }, payload) {
      commit('SET_REFRESH_TOKEN', payload.refreshToken);
    },
    storeUser({ commit }, payload) {
      commit('SET_USER', payload.user);
    },
    storeCompany({ commit }, payload) {
      commit('SET_COMPANY', payload.company);
    },
    storeWebsites({ commit }, payload) {
      commit('SET_WEBSITES', payload.websites);
    },
    storeCourseWebsite({ commit }, payload) {
      commit('SET_COURSE_WEBSITE', payload.courseWebsite);
    },
    storeCourseCart({ commit }, payload) {
      commit('SET_COURSE_CART', payload.courseCart);
    },
    storeTypeLessonPortal({ commit }, payload) {
      commit('SET_TYPE_LESSON_PORTAL', payload.typeLessonPortal);
    },
    storeIdLessonPortal({ commit }, payload) {
      commit('SET_ID_LESSON_PORTAL', payload.idLessonPortal);
    },
    storeCurrentModuleOpen({ commit }, payload) {
      commit('SET_CURRENT_MODULE_OPEN', payload.currentModuleOpen);
    },
    storeCurrentLesson({ commit }, payload) {
      commit('SET_CURRENT_LESSON', payload.currentLesson);
    },
    storeNameCurrentLesson({ commit }, payload) {
      commit('SET_NAME_CURRENT_LESSON', payload.nameCurrentLesson);
    },
    storeDataWebsite({ commit }, payload) {
      commit('SET_DATA_WEBSITE', payload.dataWebsite);
    },
    storeDataContentWebsite({ commit }, payload) {
      commit('SET_DATA_CONTENT_WEBSITE', payload.dataContenWebsite);
    },
    storeDataCoursesWebsite({ commit }, payload) {
      commit('SET_DATA_COURSES_WEBSITE', payload.dataCoursesWebsite);
    },
    storeDataTestimoniesWebsite({ commit }, payload) {
      commit('SET_DATA_TESTIMONIES_WEBSITE', payload.dataTestimoniesWebsite);
    },
    storeDataSocialMedia({ commit }, payload) {
      commit('SET_DATA_SOCIAL_MEDIA', payload.dataSocialMedia);
    },
    storeDataPayment({ commit }, payload) {
      commit('SET_DATA_PaYMENT', payload.dataPayment);
    },
    storeTypePayment({ commit }, payload) {
      commit('SET_TYPE_PAYMENT', payload.typePayment);
    },
    storeDataUserPayment({ commit }, payload) {
      commit('SET_DATA_USER_PAYMENT', payload.dataUserPayment);
    },
    storeNumberIntensTable({ commit }, payload) {
      commit('SET_NUMBER_INTENS_TABLE', payload.numberIntensTable);
    },
    storeCurrentCoursePortal({ commit }, payload) {
      commit('SET_CURRENT_COURSE_PORTAL', payload.currentCoursePortal);
    },
    storeListCouresesPortal({ commit }, payload) {
      commit('SET_LIST_COURSES_PORTAL', payload.listCoursesPortal);
    },
    storeCacheDate({ commit }, payload) {
      commit('SET_CACHE_DATE', payload.cacheDate);
    },
    storeCleanCache({ commit }, payload) {
      commit('SET_CLEAN_CACHE', payload.cleanCache);
    },
    login({ commit }) {
      commit('setAuthenticated', true);
    },
    storeSideBarState({ commit }, payload) {
      commit('SET_SIDEBAR_STATE', payload.sideBarState);
    },
    logout({ commit }) {
      commit('RESET_STATE');
    },
    storeCurrentModule({ commit }, payload) {
      commit('SET_CURRENT_MODULE', payload.currentModule);
    },
    storeDoubt({ commit }, payload) {
      commit('SET_DOUBT', payload.doubt);
    },
    storeForum({ commit }, payload) {
      commit('SET_FORUM', payload.forum);
    },

    //Weflix
    storeCurrentCollections({ commit }, payload) {
      commit('SET_CURRENT_COLLECTIONS', payload.currentCollections);
    },
    storeUserPortalWeflix({ commit }, payload) {
      commit('SET_USER_PORTAL_WEFLIX', payload.userPortalWeflix);
    },
    storeFinalWebsite({ commit }, payload) {
      commit('SET_FINAL_WEBSITE', payload.finalWebsite);
    },
    storeTypeSystem({ commit }, payload) {
      commit('SET_TYPE_SYSTEM', payload.typeSystem);
    },
    logoutPortalWeflix({ commit }) {
      commit('LOGOUT_PORTAL_WEFLIX');
    },
    storePanelData({ commit }, payload) {
      commit('SET_PANEL_DATA', payload.panelData);
    },
    storeContentSiteWeflix({ commit }, payload) {
      commit('SET_CONTENT_SITE_WEFLIX', payload.contentSiteWeflix);
    },
    storePlansWeflix({ commit }, payload) {
      commit('SET_PLANS_WEFLIX', payload.plansWeflix);
    },
    storeItemCheckoutWeflix({ commit }, payload) {
      commit('SET_ITEM_CHECKOUT_WEFLIX', payload.itemCheckoutWeflix);
    },
    logoutWeflix({ commit }) {
      commit('LOGOUT_WEFLIX');
    },
    storeTrialWeflix({ commit }, payload) {
      commit('SET_TRIAL_WEFLIX', payload.trialWeflix);
    },
    storeActiveTrial({ commit }, payload) {
      commit('SET_ACTIVE_TRIAL', payload.activeTrial);
    },
    storeDataPaymentWeflix({ commit }, payload) {
      commit('SET_DATA_PAYMENT_WEFLIX', payload.dataPaymentWeflix);
    },
    storeCollectionCheckout({ commit }, payload) {
      commit('SET_COLLECTION_CHECKOUT', payload.collectionCheckout);
    },
    storeHasSinglePurchase({ commit }, payload) {
      commit('SET_HAS_SINGLE_PURCHASE', payload.hasSinglePurchase);
    },
    storeNoLogin({ commit }, payload) {
      commit('SET_NO_LOGIN', payload.noLogin);
    },

    //SuperAdmin
    storeUserSuperAdmin({ commit }, payload) {
      commit('SET_USER_SUPER_ADMIN', payload.userSuperAdmin);
    },
    logoutSuperAdmin({ commit }) {
      commit('LOGOUT_SUPER_ADMIN');
    },
    storeCurrentCompanyCreated({ commit }, payload) {
      commit('SET_CURRENT_COMPANY_CREATED', payload.currentCompanyCreated);
    },

    //General
    storeCompanys({ commit }, payload) {
      commit('SET_COMPANYS', payload.companys);
    },
  }
});
