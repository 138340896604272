<template>
    <div class="container domainsConfig weflix-admin-domain">
        <div class="titleContainer">
            <h3>Configurações de Domínio </h3>
            <small>Gerencie os domínios do seu sistema Weflix.</small>
        </div>
        <div class="col-form-outer col-12 col-md-12 col-lg-12 col-12" v-if="hasReady">
            <div class="row">

            <div class="info_domain">
                <div class="info_domain">
                    <h5>Domínio Weflix</h5>
                    <small>O Weflix cria, automaticamente, um dominio baseado no nome da sua loja. ATENÇÃO: Ao atualizar o domínio, você será redirecionado e será necessário fazer login novamente. O tempo de propagação de suas alterações pode ser de até 20 minutos.</small>
                </div>
                <div class="form-group flex domain" >
                <div class="form-group col-md-6 col-lg-6 col-6">
                    <div class="form-label">
                      <label>Nome do Domínio Weflix</label>
                    </div>
                    <div class="form-input">
                      <div class="flex domain_input" style="position:relative;">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Meu Domínio"
                        v-model="domainWeflix"
                        @keyup="createFullDomain()"
                      />
                      <fa icon="fa-check icon_check" v-if="hasValidDomain==true"/>
                      <fa icon="fa-times icon_check" v-if="hasValidDomain==false"/>
                      
                      <button class="btn verify" @click="verifyDomain">Verificar</button>
                    </div>
                      <small class="small">Apenas texto, sem espaços ou caracteres especiais. Limite de 30 caracteres.</small>

                    </div>
                </div>
                <div class="domainPreview col-md-6 col-lg-6 col-6">
                  <div class="form-label">
                    <label>Domínio Final Weflix</label>
                  </div>
                  <div class="form-input" style="position:relative;">
                    <input
                      type="text"
                      class="form-control disabled"
                      placeholder="https://www.seudominio.weflix.com.br"
                      v-model="fullDomainWeflix"
                      disabled
                    />
                    <small class="small">Domínio que será visto pelos usuários.</small>

                    <span class="iconCopy" @click="copyToClipboard(fullDomainWeflix)"><i class="pi pi-copy"></i></span>
                  </div>
                </div>
              </div>
                 

            </div>
        <hr/>
            <div class="info_domain">
                <h5>Domínio Próprio</h5>
                <small>O Weflix permite que você use um domínio próprio para sua loja. Para isso, é necessário configurar um apontamento CNAME no seu provedor de domínio.

                  ATENÇÃO: Após atualizar o domínio, você será redirecionado e precisará fazer login novamente. O tempo de propagação das suas alterações pode levar até 24 horas.</small>
            </div>

            <div class="form-group flex domain">
              <div class="col-form-inner col-6 col-md-6 col-lg-6 ">
                <div class="form-group">
                  <div class="form-label">
                    <label>Domínio Primário</label>
                  </div>
                  <div class="form-input">
                    <input
                      type="text"
                      class="form-control"
                        placeholder="https://www.seudominio.com.br"
                      v-model="domainPrimary"
                    />
                    <small class="small">Domínio primário para acesso.</small>

                  </div>
                </div>
              </div>

              <div class="col-form-inner col-6 col-md-6 col-lg-6">
                <div class="form-group">
                  <div class="form-label">
                    <label>Domínio Adicional</label>
                  </div>
                  <div class="form-input">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="https://www.seudominio.com.br"
                      v-model="domainSecondary"
                    />
                    <small class="small">Domínio secundário para acesso.</small>

                  </div>
                </div>
              </div>
            </div>
           

            

              <div class="info_pointer">
                <div class="CNAME">
                  <small>No seu provedor, selecione o registro CNAME e aponte para o endereço:</small>
                  <div class="form-input small" style="position:relative;">
                    <input
                      type="text"
                      class="form-control disabled"
                      placeholder="app.weflix.com.br"
                      disabled
                    />
                    <span class="iconCopy"><i class="pi pi-copy"></i></span>
                    
                  </div>
                </div>
                <div class="A">
                  <small>Você pode também apontar seu domínio root, Selecione o Registro A em seu provedor e aponte para os IPs:</small>

                  <div class="form-input small" style="position:relative;">
                    <input
                      type="text"
                      class="form-control disabled"
                      placeholder="35.174.239.148"
                      v-model="title"
                      disabled
                    />
                    
                    <span class="iconCopy"><i class="pi pi-copy"></i></span>
                  </div>
                  <div class="form-input small" style="position:relative;">
                    <input
                      type="text"
                      class="form-control disabled"
                      placeholder="54.208.52.130"
                      v-model="dns2"
                      disabled
                    />
                    <span class="iconCopy"><i class="pi pi-copy"></i></span>
                  </div>
                </div>
             </div>

             <div class="info_doubt">
              <small>Caso esteja com difuculdade em realizar os apontamentos, siga nosso tutorial <a href="">clicando aqui</a>.</small>
             </div>


            </div>
          </div>
        <div class="actions configDomainWeflix">
            <!-- <button class="btn" @click="(()=>this.$router.push('/admin/colecao/1'))">Cancelar</button> -->
            <button class="btn" @click="editDomain">Salvar</button>
        </div>
      </div>
<Loader :hasReady="hasReady" />
</template>

<script>
import apiDomain from '@/utils/Weflix/domain';
import { mapState } from 'vuex';
import Loader from '@/components/Weflix/Loader';
import apiWebsite from '@/utils/Weflix/website';

export default {
    data(){
        return{
            domainWeflix: '',
            fullDomainWeflix: '',
            domainPrimary: '',
            domainSecondary: '',
            hasReady: false,
            hasData: false,
            hasValidDomain: null,
        }
    },
    computed: {
        ...mapState(['token'])
    },
    components: {
        Loader
    },
    methods: {
        async getDomain(){
            try {
                const response = await apiDomain.getDomain(this.token);
                this.hasReady = true;
                this.domainWeflix = response.url.replace('https://', '').split('.')[0];
                this.fullDomainWeflix = response.url;
                this.domainPrimary = response.url1;
                this.domainSecondary = response.url2;
                this.hasdata = response.url != null || response.url != '' ? true : false;
            } catch (error) {
                console.error('Erro ao obter dados do dominio:', error);
            }
        },
        async editDomain(){
          this.hasReady = false;
          const payload = {
            "url": this.hasData == true ? this.domainWeflix : 'https://'+this.domainWeflix+'.weflix.com.br',
            "url1": this.domainPrimary,
            "url2": this.domainSecondary
          }
          try {
            const response = await apiDomain.editDomain(this.token, payload);
            this.hasReady = true;
          } catch (error) {
            
        }
    },
    createFullDomain(){
        if (this.domainWeflix) {
            // Remove todos os caracteres não alfanuméricos e espaços
            this.domainWeflix = this.domainWeflix.replace(/[^\w\s]/gi, '').replace(/\s+/g, '').toLowerCase();
            
            // Concatena o domínio base com o nome do domínio fornecido pelo usuário
            this.fullDomainWeflix = 'https://'+this.domainWeflix+'.weflix.com.br';
        } else {
            console.error('domainWeflix está undefined');
        }
    },
    async verifyDomain(){
      const payload = {
        url: this.fullDomainWeflix
      }
      try {
        const response = await apiWebsite.verifyDomaintData(this.token, payload);
        this.hasValidDomain = response;
      } catch (error) {
        console.error('Erro ao verificar dominio:', error);
      }
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text);
    }
  },  
    created(){
        this.getDomain();
    }
  }
  
</script>

<style scoped>
button.btn.verify{
    background-color: #21212100 !important;
    color: #250891 !important;
    border: 1px solid #250891 !important;
    font-size: 18px !important;
    font-weight: 100 !important;
    width: 146px;
    text-align: center;
    transition: all 500ms ease-in-out;
}
button.btn.verify:hover {
    color: white !important;
    background-color: #250891 !important;
}
hr{
    border-color: #b1b1b1;
    height: 1px;
    width: 100%;
    margin: 10px 10px;
}
.form-input.small {
    width: 50%;
    margin: 15px auto;
}
span.iconCopy {
    position: absolute;
    top: 10px;
    right: 5px;
}
.col-form-inner.col-6.col-md-6.col-lg-6 .form-group {
    margin: 0 !important;
}
.form-group.col-md-6.col-lg-6.col-6 {
    margin: 0 !important;
}
.info_pointer {
    display: flex;
    gap: 10px;
    margin-top: 10px;
    padding: 10px;
  }
  .info_pointer > div {
    width: 50%;
  }
 .container {
    max-width: 100%;
 }
.titleContainer {
    display: block;
    text-align: left;
    padding: 0 10px 0 0;
    border-bottom: 1px solid #e8e8e8;
    margin: 10px;
    
}
.container {
    background-color: white;
    padding: 10px 0px;
    padding-bottom: 50px;
}
.CollectionVideos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    padding: 10px;
}
.itemCollectionVideo {
    max-width: 30%;
    height: 248px;
    background-size: cover;
    min-width: 300px;
    width: 27%;
    cursor: pointer;
    position: relative;
    text-align: left;
    padding: 10px;
    border-radius: 5px;

}
.thumb-img path {
    fill: #ffffff!important;
    stroke: #aaaaaa!important;
    stroke-width: 1px!important;
}
img, svg {
    vertical-align: middle;
    width: 35px;
}
.UploadVideoContainer {
    /* max-width: 800px; */
    /* margin: 50px auto; */
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 20px;
    margin: 30px 15px;
}
.col-form-inner.col-12.col-md-12.col-lg-12.input-video {
    max-width: 800px;
    margin: 10px auto;
}
.uploadVideoIcon {
    vertical-align: middle;
    width: 46px;
    padding: 8px;
    border: 1px solid #e4e4e4;
    border-radius: 10px;
}
.infoCollectionVideos {
    text-align: left;
    padding: 0px 20px;
}
svg.svg-inline--fa {
    color: silver;
}
.infoCollectionVideos p {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 14px;
}
button.editCollections {
    position: absolute;
    top: 25px;
    right: 25px;
}
button.editCollections svg {
    transition: all 500ms ease-in-out;
}
button.editCollections:hover svg {
    color: #250891;
}
.itemCollectionVideo .actions {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    text-align: right;
    padding: 10px;
    background-color: transparent;
    transition: all 500ms ease-in-out;
}
button.hoverWhite svg{
    transition: all 500ms ease-in-out;
    font-size: 15px;
}
button.hoverWhite:hover svg{
    color: white;
}
.itemCollectionVideo p {
    color: #c8c8c8;
    margin: 0;
}
.col-form-outer.col-12.col-md-12.col-lg-12.col-12 {
    padding: 20px;
}
.form-group.flex.domain {
    margin: 10px 0px !important;
    gap: 10px;
    flex-wrap: nowrap;
    padding-left: 10px;
}
.info_domain {
    text-align: left;
    padding-left: 0;
}
.flex.domain_input {
  align-items: center;
}
.flex.domain_input svg.svg-inline--fa.fa-xmark {
  color: white;
}
.flex.domain_input svg.svg-inline--fa.fa-check {
  color: green;
}
@media (max-width: 600px) {
  .form-group.flex.domain {
    display: block;
  }
  .form-group.col-md-6.col-lg-6.col-6 {
    width: 100%;
  }
  .domainPreview.col-md-6.col-lg-6.col-6 {
    width: 100%;
  }
  .info_pointer {
    display: block;
  }
  .info_pointer div {
    width: 100%;
  }
  .actionsSave.configDomainWeflix {
    position: inherit !important;
    margin: 0;
    padding-left: 0;
  }
  .actions.configDomainWeflix > button.btn {
        width: 72vw;
  }
  .weflix-admin-domain h3 {
    font-size: 20px;
    margin-bottom: 5px;
  }
  .weflix-admin-domain small {
    font-size: 12px;
    color: #666;
  }
  .weflix-admin-domain .titleContainer {
    padding: 10px 10px;
    margin: 0px;
  }
  .weflix-admin-domain .form-group.flex.domain {
    padding: 0;
  }
  .weflix-admin-domain .info_pointer {
    padding: 0px;
  }
}
.flex.domain_input {
  display: flex;
  align-items: flex-start;
  gap: 7px;
  flex-wrap: nowrap;
  align-items: center;
}
.flex.domain_input svg.svg-inline--fa.fa-xmark {
  color: red !important;
}
</style>
