<template>
    <nav class="navbar primary navbar-expand-lg weeduc-admin-toolbar">
        <div class="container" style="background-color: transparent!important;">
            <div class="navbar-wrapper primary">
                <div class="wrapper-inner">
                    <div class="navbar-breadcrumb">
                        <ul class="breadcrumb">
                            <li v-for="(item, index) in items" :key="index"
                                :class="{ 'breadcrumb-item': true, 'active': item.active }">
                                <router-link v-if="!item.active" class="link-breadcrumb" :to="item.link">{{ item.label
                                }}</router-link>
                                <span v-else>{{ item.label }}</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="wrapper-inner">
                    <ul class="navbar-nav primary">
                        <li @click="changeSideBarState()" class="mobileClickMenu"> 
                            <a href="#">
                                <fa icon="fa-bars"/>
                            </a>
                        </li>
                        <li>
                            <a href="/aluno/dashboard" target="_blank" class="link">
                                <fa icon="graduation-cap"/> <span class="link-text">Portal do aluno</span>
                            </a>
                        </li>
                        <li>
                            <a :href="websites.url" target="_blank" class="link">
                                <fa icon="globe"/><span class="link-text">Site</span>
                            </a>
                        </li>
                        <li class="nav-item icon-toolbar">
                            <a class="nav-icon" @click="toggleDarkModeFunction()"> 
                               <fa icon="sun" v-show="dark"/>
                               <fa icon="moon" v-show="!dark"/>
                            </a>
                        </li>
                        <li class="nav-item dropdown" >
                            <a class="logout" href="#" @click="(()=>{this.openmodaledituser = true})">
                                <img class="image-user"  src="/img/user.e69f11a5.png" v-show="!user.photoFinal" />
                                <img class="image-user"  :src="user.photoFinal" v-show="user.photoFinal" />
                            </a>
                        </li>
                        <li @click="logout()" class="logout"> 
                            <a href="#">
                                <fa icon="fa-power-off"/>
                            </a>
                        </li>
                       
                    </ul>
                </div>
            </div>
        </div>
    </nav>
    <EditUser
    :show="openmodaledituser"
    :id="user.id"
    @open-tab="atualizarValorNoFilhoEdit"
></EditUser>
</template>


<script>
import store from '@/store';
import { mapState, mapActions } from 'vuex';
import EditUser from '@/components/Admin/Users/Edituser/edituser.vue';

export default {
    props: {
        items: {
            required: true,
            type: Object, 
        },
    },
    data() {
        return {
            typeUser: 0, 
            openmodaledituser:false,

        };
    },
    computed:{
        ...mapState(['dark','user', 'websites'])
    },
    components: {
        EditUser,
    },
    methods: {
        ...mapActions(['storeTypeId','storeDark','toggleDarkMode','storeSideBarState']),
        logout(){
            localStorage.removeItem('vuex');
            localStorage.removeItem('activeIndex');
            localStorage.removeItem('logado');
            window.location.href = "/login";
           
        },
        toggleDarkModeFunction() {
            this.toggleDarkMode({dark:this.dark==true ? false : true});
            if(this.dark==true){
                document.body.classList.add('dark');
            }else{
                document.body.classList.remove('dark');
            }
        },
        atualizarValorNoFilhoEdit(novovalor){
            this.openmodaledituser = novovalor;
        },
        changeSideBarState(){
            this.storeSideBarState({sideBarState:!this.sideBarState})
        }
    },
    created() {
        this.typeUser = store.state.typeId;
    },
   
};
</script>

<style scoped>
@import './toolbar.css';
</style>