import api from './api';

export default {
    async getDataReportUser(accessToken) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get('/report/user', {
                method: 'GET',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter relatórios usuários:', error);
            throw error;
        }
    },
    async getDataEnrols(accessToken) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get('/report/enrol', {
                method: 'GET',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter relatórios usuários:', error);
            throw error;
        }
    },
    async getDataCourses(accessToken, searchParams = {}) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            
            const response = await api.get('/report/course', {
                headers,
                params: {
                    userName: searchParams.userName || '',
                    courseName: searchParams.courseName || '',
                    pageNumber: searchParams.pageNumber || 1,
                    pageSize: searchParams.pageSize || 10
                }
            });
            
            return response.data;
        } catch (error) {
            console.error('Erro ao obter relatórios usuários:', error);
            throw error;
        }
    },
    async getDataCoursesExport(accessToken, searchParams = {}) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };

            // Log the exact parameters being sent
            const params = {
                userName: searchParams.userName || '',
                courseName: searchParams.courseName || ''
            };
            console.log('Export endpoint params:', params);

            const response = await api.get('/report/course/export', {
                headers,
                params
            });

            // Log the raw response
            console.log('Export endpoint URL:', '/report/course/export');
            console.log('Export endpoint headers:', headers);
            console.log('Export endpoint response:', response);

            return {
                content: Array.isArray(response.data) ? response.data : [],
                totalElements: Array.isArray(response.data) ? response.data.length : 0,
                totalPages: 1
            };
        } catch (error) {
            console.error('Export endpoint error:', error);
            console.error('Export endpoint error response:', error.response);
            throw error;
        }
    },
    async getDataOrder(accessToken) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get('/report/order', {
                method: 'GET',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter relatórios order:', error);
            throw error;
        }
    },
    async getDataNotification(accessToken) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get('/report/notification', {
                method: 'GET',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter relatórios notification:', error);
            throw error;
        }
    },
    async getDataInquiry(accessToken) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get('/report/inquiry', {
                method: 'GET',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter relatórios inquiry:', error);
            throw error;
        }
    },
    async getDataForum(accessToken) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get('/report/forum', {
                method: 'GET',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter relatórios forum:', error);
            throw error;
        }
    },
    async getDataReport() {
        try {
            const params = this.searchTerm ? {
                userName: this.searchTerm,
                courseName: this.searchTerm
            } : {};

            console.log('Params being sent to API:', params);

            const response = await api.getDataCourses(this.token, params);
            console.log('API response:', response);

            this.usersInfo = response.content;
            this.hasReady = true;

            // Log the usersInfo before filtering
            console.log('Users Info:', this.usersInfo);

            // Filter the results based on the search term
            this.userFiltered = this.usersInfo.filter(course => {
                const matchesUserName = course.userName.toLowerCase().includes(this.searchTerm.toLowerCase());
                const matchesCourseName = course.courseName.toLowerCase().includes(this.searchTerm.toLowerCase());
                
                // Log the matching process
                console.log(`Checking course: ${course.courseName}, User: ${course.userName}, Matches: ${matchesUserName || matchesCourseName}`);
                
                return matchesUserName || matchesCourseName;
            });

            // Log the filtered results
            console.log('Filtered Results:', this.userFiltered);

            this.totalEnrols = this.userFiltered.length; // Update total enrols based on filtered results

            // ... rest of your statistics calculations
        } catch (error) {
            console.error('Error Report Users', error);
            this.hasReady = true;
        }
    }
}
