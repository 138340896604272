<template>
    <div class="title">
        <p>Notificações</p>
    </div>
    <div class="content notification" v-for="notification in data.data" :key="notification">
        <div class="cotent_info" @click="redirectToInquiries">
            <p class="title_notification">{{notification.title}} <span class="new" v-show="notification.isRead==0"></span></p>
            <span v-html=" textoComEllipsis(notification.description)"></span>
            <label>2 dias atrás</label>
        </div>
    </div>
    <div class="action" @click="redirectToInquiries">
        <button>Ver todas as notificações</button>
    </div>
</template>
<script>
export default{
    props:{
        title: {
            type: String,
            required: true 
        },
        data:{
            type:Array,
            required:true
        }
    },
    data(){
        return{
           nome:false,
        }
    },
    methods: {
        redirectToInquiries() {
            window.location = '/aluno/notificacoes';
        }
    },
    computed: {
        textoComEllipsis() {
            return text => {
                if (text.length > 80) {
                    return text.substring(0, 80) + "... ";
                } else {
                    return text;
                }
            }
        }
    }
}
</script>

<style scoped>

/* Notification */
.title p {
    margin: 0;
    font-size: 13px;
    color: #ababab;
}

.content_notification {
    display: flex;
    border-bottom: 1px solid #c9c9c9;
    padding-bottom: 10px;
    margin: 10px;
}
.cotent_info {
    text-align: left;
    padding-left: 10px;
    cursor: pointer;
}
.content {
    display: flex;
    padding: 10px;
    color: black;
}

.cotent_info span {
    font-size: 13px;
}
.cotent_info p {
    margin: 0;
    font-size: 15px;
    font-weight: 700;
}
.content {
    display: flex;
    padding: 10px;
    color: black;
}

.notification_bar.show .title {
    align-items: center;
    display: flex;
    gap: 10px;
}
.content i {
    padding-top: 3px;
}
.title {
    border-bottom: 1px solid #e3e3e3;
    padding: 15px 20px;
}
.cotent_info label {
    display: block;
    font-size: 11px;
    margin-top: 9px;
    color: gray;
}
.cotent_info span {
    display: block;
    margin-top: 9px;
    color: gray;
}
.notification_bar .action {
    display: flex;
    justify-content: center;
}
.notification_bar .action button {
    color: #19c7d0;
    font-weight: 700;
}
p.title_notification {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
span.new {
    background-color: #19c7d0;
    height: 8px;
    width: 8px;
    border-radius: 50%;
}
.content.notification {
    display: block;
}
</style>

<style>
.global_header.dark .notification_bar.show {
    background-color: black;
}
.global_header.dark .notification_bar.show p, .global_header.dark .notification_bar.show span {
  color: white;}
.notification_bar .title p {
    color: #242424 !important;
}
</style>