<template>
    <section class="collections courseWeflix collWeflixLightTheme">
        <Header @toggleModal="toggleOpenDialog" @toggleModalSubscribe="toggleOpenSubscribe"/> 
        <div class="titleCollectionsPage">
            <button class="btn-voltar" @click="$router.go(-1)">
                <fa icon="fa-arrow-left"/> Voltar
            </button>
            <h3>Cursos</h3>
            <p>Cursos disponíveis para você.</p>
        </div>

        <!-- Loader -->
        <div v-if="loading" class="loader-container">
            <div class="loader"></div>
        </div>

        <div v-else class="containerCollections">
            <div class="itensCollections" v-for="collection in allCollections" :key="collection.id" @click="goToCollection(collection.id)">
                <div class="conteriner-flex-videos">
                    <div class="col-1-videos">
                        <img v-if="collection.imageFinalPath" :src="collection.imageFinalPath" alt="Imagem padrão">
                        <img v-else src="@/assets/Weflix/default-video-thumbnail.jpg" alt="Imagem padrão"/>
                    </div>
                    <div class="col-2-videos">
                        <div class="row-1-videos">
                            <img v-if="collection.lessons[1] && collection.lessons[1].finalVideoThumbnail" :src="collection.lessons[1].finalVideoThumbnail" alt="Imagem padrão">
                            <img v-else src="@/assets/Weflix/default-video-thumbnail.jpg" alt="Imagem padrão"/>
                        </div>
                        <div class="row-2-videos">
                            <div class="infoVideosBackground">
                                <span>+ {{collection.totalVideos ? collection.totalVideos : 0}}</span>
                            </div>
                            <img v-if="collection.lessons[2] && collection.lessons[2].finalVideoThumbnail" :src="collection.lessons[2].finalVideoThumbnail" alt="Imagem padrão">
                            <img v-else src="@/assets/Weflix/default-video-thumbnail.jpg" alt="Imagem padrão"/>
                        </div>
                    </div>
                    <div class="row-3-videos">
                        <div class="title-row-3-videos">
                            <h3>{{collection.comercialName}}</h3>
                            <p>{{collection.totalVideos ? collection.totalVideos : 0}} vídeos | {{collection.totalTime ? Math.max(1, (collection.totalTime/3600).toFixed(0)) : 0}} horas</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Paginação -->
        <div class="pagination">
            <button 
                :disabled="currentPage === 1" 
                @click="previousPage"
                class="pagination-button"
            >
                Anterior
            </button>
            
            <span class="page-info">Página {{currentPage}} de {{totalPages}}</span>
            
            <button 
                :disabled="currentPage === totalPages" 
                @click="nextPage"
                class="pagination-button"
            >
                Próximo
            </button>
        </div>

        <Footer/>
    </section>
    <Subscribe :show="openDialogSubscribe" @toggleModal="toggleOpenSubscribe"/>
    <Login :show="openDialogLogin" @toggleModal="toggleOpenDialog"/>
</template>

<script>
import { Carousel, Navigation, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
import Header from '@/components/Weflix/LigthTheme/Header.vue';
import Footer from '@/components/Weflix/LigthTheme/Footer.vue';
import Subscribe from '@/components/Weflix/Subscribe.vue'
import Login from '@/components/Weflix/Login.vue'
import ApiWebsite from '@/utils/Weflix/Website/index';

export default {
    data(){
        return{
            titleCollection:['Primeire Coleção', 'Segunda Coleção', 'Terceira Coleção'],
            openDialogLogin:false,
            openDialogSubscribe:false,
            collections: [],
            allCollections: [], 
           baseUrl: process.env.NODE_ENV === 'development' ? process.env.VUE_APP_BASE_URL_HOMOLOGATION  : window.location.origin,

            currentPage: 1,
            totalPages: 1,
            loading: true
        }
    },
    components: {
        Carousel,
        Slide,
        Navigation,
        Header,
        Footer,
        Subscribe,
        Login
    },
    methods:{
        toggleOpenDialog(){
            this.openDialogLogin = !this.openDialogLogin;
        },
        toggleOpenSubscribe(){
            this.openDialogSubscribe = !this.openDialogSubscribe;
        },
        goToVideo(){
            this.openDialogSubscribe = !this.openDialogSubscribe;
        },
        async getCollections(page = 0){
            this.loading = true;
            const aux = this.baseUrl.split('/colecoes')[0];
          
            const payload = {
                "url": aux
            }
            try {
                const response = await ApiWebsite.getCollectionsLimit(payload, page);
                const sortedContent = response.content.sort((a, b) => {
                    // Garante que order seja um número e ordena de forma crescente
                    const orderA = Number(a.order) || 0;
                    const orderB = Number(b.order) || 0;
                    if (orderA > orderB) return 1;
                    if (orderA < orderB) return -1;
                    return 0;
                });
                
                if (page === 0) {
                    this.allCollections = sortedContent;
                } else {
                    // this.allCollections = [...this.allCollections, ...sortedContent];
                    this.allCollections = sortedContent;
                }
                
                this.totalPages = response.totalPages;
            } catch (error) {
                console.error(error);
            } finally {
                this.loading = false;
            }
        },
        goToCollection(id){
            this.$router.push(`/colecoes-videos/themeLight/${id}`);
        },
        formatarTempo(tempo) {
            const minutos = Math.floor(tempo / 60);
            const segundos = tempo % 60;
            return `${minutos}:${segundos.toString().padStart(2, '0')}s`;
        },
        async previousPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
                await this.getCollections(this.currentPage - 1);
            }
        },
        async nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
                await this.getCollections(this.currentPage - 1);
            }
        }
    },
    created(){
        this.getCollections();
    }   
}
</script>
<style scoped>

.courseWeflix section.collections .titleCollectionsPage {
    margin-bottom: 0;
}
section.collections .titleCollectionsPage{
    position: relative;
}
.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
}

.loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    gap: 20px;
    margin-bottom: 50px;
}

.pagination-button {
    padding: 8px 16px;
    background-color: #464646;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.pagination-button:hover:not(:disabled) {
    background-color: #5e5e5e;
}

.pagination-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.page-info {
    color: #808080;
    font-size: 14px;
}
.loader {
    z-index: 999999;
    position: absolute;
    top: 31%;
}
.collWeflixLightTheme button.btn-voltar {
    position: absolute;
    top: -45px;
    left: 0;
    color: rgb(125, 125, 125) !important;
}
.collWeflixLightTheme button.btn-voltar svg {
   color: rgb(125, 125, 125) !important;
}
.collWeflixLightTheme .titleCollectionsPage h3 {
    color: rgb(125, 125, 125) !important;
}
</style>
<style>
/* Restante dos estilos... */
.collWeflixLightTheme button.btn-voltar {
    position: absolute;
    top: -45px;
    left: 0;
    color: rgb(125, 125, 125) !important;
}
.collWeflixLightTheme button.btn-voltar svg {
   color: rgb(125, 125, 125) !important;
}
.collWeflixLightTheme .titleCollectionsPage h3 {
    color: rgb(125, 125, 125) !important;
}
.carousel__item {
    position: relative;
    cursor: pointer;
}
.carousel__item img {
    width: 98%;
}
.titleCollections {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    color: white;
}
.titleCollectionsPage{
    max-width: 1500px;
    margin: 0 auto;
    margin-top: 100px;
    text-align: left;
}
.courseWeflix.collections {
    background-color: white;
    padding: 20px 0px 0px 0px;
}
.titleCollections h3 {
    font-size: 20px;
    font-weight: 700;
}
.carousel__prev {
    left: -4%;
}
.carousel__next {
    right: -4%;
}
svg.carousel__icon {
    color: white;
}
svg.svg-inline--fa.fa-lock.lockIcon path {
    fill: transparent;
    stroke: white;
    stroke-width: 25px;
}
svg.svg-inline--fa.fa-lock.lockIcon {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 15px;
}
.degradeCarousel__item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #0000007d;
    height: 100%;
    opacity: 0;
    
}
.carousel__item:hover > .degradeCarousel__item {
    opacity: 1;
}
span.textPrivateVideo {
    color: white;
    font-size: 11px;
    position: absolute;
    right: 36px;
    top: 13px;
    opacity: 0;
    transition: all 500ms linear;
    z-index: 2;
}
.carousel__item:hover > span.textPrivateVideo {
    opacity: 1;
}
.collWeflixLightTheme .descriptionVideo small, .descriptionVideo h4 {
    color: rgb(125, 125, 125);
}
.descriptionVideo h4 {
    font-size: 15px;
    margin-left: 5px;
    margin-top: 10px;
    margin-bottom: 0;
}
.descriptionVideo small {
    font-size: 12px;
    margin-left: 5px;
}
footer {
    margin-top: 100px !important;
}
.videoCollections img {
    width: 100%;
    height: 300px;
    border-radius: 10px;
}
.courseWeflix .containerCollections .itensCollections {
    background-color: #eef0f4;
}
.courseWeflix .title-row-3-videos h3 {
    color: #8a8a8b;
    font-size: 15px;
    margin: 0;
}
.courseWeflix .containerCollections .itensCollections:hover {
    box-shadow: 0px 0px 10px 1px #e5e7ec;
    background-color: #e5e7ec;
}
@media (max-width: 600px) {
    .itensCollections {
        padding: 20px;
    }
    .titleCollectionsPage {
        margin-left: 20px;
    }
    .containerCollections {
        display: block!important;
        gap: 18px;
        flex-wrap: wrap;
        padding: 20px;
        justify-content: start;
    }
    .containerCollections .itensCollections {
        width: 100%;
        margin: 10px 0px!important;
    }
    .videoCollections {
        transition: all 500ms linear;
    }
   
    .itensCollections {
        padding: 20px 0px;
        cursor: pointer;
        width: 100%;
    }
}
.containerCollections {
    display: flex;
    gap: 18px;
    flex-wrap: wrap;
    max-width: 1500px;
    margin: 0 auto;
}
.videoCollections {
    transition: all 500ms linear;
}

.conteriner-flex-videos {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}
.col-1-videos {
    width: 66%;
}
.col-2-videos {
    width: 31%;
}
.col-1-videos img {
    width: 100%;
    border-radius: 10px;
    height: 200px;
}
.col-2-videos img {
    width: 100%;
    border-radius: 10px;
    height: 95px;
}
.row-1-videos {
    margin-bottom: 10px;
}
.row-3-videos {
    width: 100%;
}
.title-row-3-videos {
    text-align: left;
    padding: 0px 8px;
}
.collWeflixLightTheme .title-row-3-videos h3 {
    color: #7d7d7d !important;
    font-size: 15px;
    margin: 0;
}
.collWeflixLightTheme .title-row-3-videos p {
    color: #a4a4a4;
    font-size: 11px;
    margin: 0;
}
.row-2-videos {
    position: relative;
}
.infoVideosBackground {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #f8f8f85c;
    display: flex;
    align-items: center;
    justify-content: center;
}
.infoVideosBackground span {
    color: white;
}
</style>
