<template>
    <div class="container">
        <div class="titleContainer">
            <h3>Dados Bancários</h3>
            <p>Configure os dados jurídicos e financeiros para receber os repasses de suas vendas.</p>
        </div>
        <div class="formPerson flex" v-if="typePerson == 1">
            <div class="col-form-inner col-4 col-md-4 col-lg-4">
                <div class="form-group">
                  <div class="form-label">
                    <label>CPF<v-tooltip text="Campo obrigatório" location="top">
                      <template v-slot:activator="{ props }">
                        <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                      </template>
                    </v-tooltip></label>
                  </div>
                  <div class="form-input">
                    <input
                      type="text" 
                      required
                      class="form-control"
                      v-model="comercialName"
                      placeholder="video.mp4"
                      disabled
                    />
                    <!-- <small class="alertText">Campo obrigatório e deve ser preenchido corretamente.</small> -->
                    <small>Nome comercial de sua coleção, uso externo.</small>
                  </div>
                </div>
          </div>
          <div class="col-form-inner col-8 col-md-8 col-lg-8">
            <div class="form-group">
              <div class="form-label">
                <label>Nome Completo<v-tooltip text="Campo obrigatório" location="top">
                  <template v-slot:activator="{ props }">
                    <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                  </template>
                </v-tooltip></label>
              </div>
              <div class="form-input">
                <input
                  type="text" 
                  required
                  class="form-control"
                  v-model="comercialName"
                  placeholder="video.mp4"
                  disabled
                />
                <!-- <small class="alertText">Campo obrigatório e deve ser preenchido corretamente.</small> -->
                <small>Nome comercial de sua coleção, uso externo.</small>
              </div>
            </div>
      </div>

      <div class="col-form-inner col-6 col-md-6 col-lg-6">
        <div class="form-group">
          <div class="form-label">
            <label>Banco:</label>
          </div>
          <div class="form-input">
            <select
              required
              class="form-control"
              v-model="bank"
              disabled
            >
              <option value="" selected>001 - BANCO DO BRASIL S.A.</option>
            </select>
          </div>
        </div>
      </div>

          <div class="col-form-inner col-2 col-md-2 col-lg-2">
            <div class="form-group">
              <div class="form-label">
                <label>Agência<v-tooltip text="Campo obrigatório" location="top">
                  <template v-slot:activator="{ props }">
                    <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                  </template>
                </v-tooltip></label>
              </div>
              <div class="form-input">
                <input
                  type="text" 
                  required
                  class="form-control"
                  v-model="comercialName"
                  placeholder="00000"
                  disabled
                />
                <!-- <small class="alertText">Campo obrigatório e deve ser preenchido corretamente.</small> -->
                <!-- <small>Nome comercial de sua coleção, uso externo.</small> -->
              </div>
            </div>
      </div>
      <div class="col-form-inner col-1 col-md-1 col-lg-1">
        <div class="form-group">
          <div class="form-label">
            <label>Dígito<v-tooltip text="Campo obrigatório" location="top">
              <template v-slot:activator="{ props }">
                <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
              </template>
            </v-tooltip></label>
          </div>
          <div class="form-input">
            <input
              type="text" 
              required
              class="form-control"
              v-model="comercialName"
              placeholder="00000"
              disabled
            />
            <!-- <small class="alertText">Campo obrigatório e deve ser preenchido corretamente.</small> -->
            <!-- <small>Nome comercial de sua coleção, uso externo.</small> -->
          </div>
        </div>
  </div>
  <div class="col-form-inner col-2 col-md-2 col-lg-2">
    <div class="form-group">
      <div class="form-label">
        <label>Conta<v-tooltip text="Campo obrigatório" location="top">
          <template v-slot:activator="{ props }">
            <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
          </template>
        </v-tooltip></label>
      </div>
      <div class="form-input">
        <input
          type="text" 
          required
          class="form-control"
          v-model="comercialName"
          placeholder="00000"
          disabled
        />
        <!-- <small class="alertText">Campo obrigatório e deve ser preenchido corretamente.</small> -->
        <!-- <small>Nome comercial de sua coleção, uso externo.</small> -->
      </div>
    </div>
</div>
<div class="col-form-inner col-1 col-md-1 col-lg-1">
    <div class="form-group">
      <div class="form-label">
        <label>Dígito<v-tooltip text="Campo obrigatório" location="top">
          <template v-slot:activator="{ props }">
            <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
          </template>
        </v-tooltip></label>
      </div>
      <div class="form-input">
        <input
          type="text" 
          required
          class="form-control"
          v-model="comercialName"
          placeholder="00000"
          disabled
        />
        <!-- <small class="alertText">Campo obrigatório e deve ser preenchido corretamente.</small> -->
        <!-- <small>Nome comercial de sua coleção, uso externo.</small> -->
      </div>
    </div>
</div>
<div class="actionsSave">
    <button class="btnSave">Salvar</button>
  </div>
        </div> 
        <div class="formPerson flex" v-if="typePerson == 2 && hasReady">
            <div class="col-form-inner col-4 col-md-4 col-lg-4">
                <div class="form-group">
                  <div class="form-label">
                    <label>CNPJ<v-tooltip text="Campo obrigatório" location="top">
                      <template v-slot:activator="{ props }">
                        <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                      </template>
                    </v-tooltip></label>
                  </div>
                  <div class="form-input">
                    <input
                      type="text" 
                      required
                      class="form-control"
                      v-model="CNPJ"
                      v-mask="'##.###.###/####-##'"
                      placeholder="##.###.###/####-##"
                      disabled
                    />
                    <!-- <small class="alertText">Campo obrigatório e deve ser preenchido corretamente.</small> -->
                    <small>Nome comercial de sua coleção, uso externo.</small>
                  </div>
                </div>
          </div>
          <div class="col-form-inner col-8 col-md-8 col-lg-8">
            <div class="form-group">
              <div class="form-label">
                <label>Razão Social<v-tooltip text="Campo obrigatório" location="top">
                  <template v-slot:activator="{ props }">
                    <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                  </template>
                </v-tooltip></label>
              </div>
              <div class="form-input">
                <input
                  type="text" 
                  required
                  class="form-control"
                  v-model="companyName"
                  placeholder="video.mp4"
                  disabled
                />
                <!-- <small class="alertText">Campo obrigatório e deve ser preenchido corretamente.</small> -->
                <small>Nome comercial de sua coleção, uso externo.</small>
              </div>
            </div>
      </div>

      <div class="col-form-inner col-6 col-md-6 col-lg-6">
        <div class="form-group">
          <div class="form-label">
            <label>Banco:</label>
          </div>
          <div class="form-input">
            <select
              required
              class="form-control"
              v-model="bank"
              disabled
            >
            <option value="001">001 - BANCO DO BRASIL S.A.</option>
            <option value="033">033 - BANCO SANTANDER S.A.</option>
            <option value="104">104 - CAIXA ECONÔMICA FEDERAL</option>
            <option value="237">237 - BANCO BRADESCO S.A.</option>
            <option value="341">341 - BANCO ITAÚ S.A.</option>
            <option value="077">077 - BANCO INTER S.A.</option>
            <option value="260">260 - NUBANK</option>
            <option value="336">336 - BANCO C6 S.A.</option>
            <option value="290">290 - PAGSEGURO INTERNET S.A.</option>
            <option value="380">380 - PICPAY</option>
            </select>
          </div>
        </div>
      </div>

          <div class="col-form-inner col-2 col-md-2 col-lg-2">
            <div class="form-group">
              <div class="form-label">
                <label>Agência</label>
              </div>
              <div class="form-input">
                <input
                  type="text" 
                  required
                  class="form-control"
                  v-model="agency"
                  placeholder="00000"
                  disabled
                />
                <!-- <small class="alertText">Campo obrigatório e deve ser preenchido corretamente.</small> -->
                <!-- <small>Nome comercial de sua coleção, uso externo.</small> -->
              </div>
            </div>
      </div>
      <div class="col-form-inner col-1 col-md-1 col-lg-1">
        <div class="form-group">
          <div class="form-label">
            <label>Dígito</label>
          </div>
          <div class="form-input">
            <input
              type="text" 
              required
              class="form-control"
              v-model="agencyDigit"
              placeholder="00000"
              disabled
            />
            <!-- <small class="alertText">Campo obrigatório e deve ser preenchido corretamente.</small> -->
            <!-- <small>Nome comercial de sua coleção, uso externo.</small> -->
          </div>
        </div>
  </div>
  <div class="col-form-inner col-2 col-md-2 col-lg-2">
    <div class="form-group">
      <div class="form-label">
        <label>Conta</label>
      </div>
      <div class="form-input">
        <input
          type="text" 
          required
          class="form-control"
          v-model="account"
          placeholder="00000"
          disabled
        />
        <!-- <small class="alertText">Campo obrigatório e deve ser preenchido corretamente.</small> -->
        <!-- <small>Nome comercial de sua coleção, uso externo.</small> -->
      </div>
    </div>
</div>
<div class="col-form-inner col-1 col-md-1 col-lg-1">
    <div class="form-group">
      <div class="form-label">
        <label>Dígito</label>
      </div>
      <div class="form-input">
        <input
          type="text" 
          required
          class="form-control"
          v-model="accountDigit"
          placeholder="00000"
          disabled
        />
        <!-- <small class="alertText">Campo obrigatório e deve ser preenchido corretamente.</small> -->
        <!-- <small>Nome comercial de sua coleção, uso externo.</small> -->
      </div>
    </div>
</div>
<!-- <div class="actionsSave">
    <button class="btnSave" @click="editCompany" disabled>Salvar</button>
  </div> -->
        </div> 
      </div>
      <Loader :hasReady="hasReady"/>
</template>

<script>
import ApiCompany from '@/utils/Weflix/company';
import { mapState } from 'vuex';
import Loader from '@/components/Weflix/Loader';

export default {
    data() {
        return {
            typePerson: 2,
            CNPJ: '',
            companyName: '',
            bank: '',
            agency: '',
            agencyDigit: '',
            account: '',
            accountDigit: '',
            state: '',
            city: '',
            hasReady: false,
            idCompany: '',
            receivablesAdvance: false,
            mediaComments: false,
            cartClosure: false,
            showInstallmentValue: false,
           
        }
    },
    computed: {
        ...mapState(['token'])
    },
    components: {
        Loader
    },
    methods: {
        changeTypePerson(id) {
            this.typePerson = id;
        },
        async getCompany() {
          try {
            const response = await ApiCompany.getCompany(this.token);
            this.companyName = response.name;
            this.CNPJ = response.idFiscal;
            this.idCompany = response.id;
            this.bank = response.bank;
            this.agency = response.branch;
            this.account = response.account;
            this.accountDigit = response.digitAccount;
            this.agencyDigit = response.digitBranch;
            this.hasReady = true;

            this.receivablesAdvance = response.anticipation === 1 ? true : false;
            this.mediaComments = response.videoComments === 1 ? true : false;
            this.cartClosure = response.cartStatus === 1 ? true : false;
            this.showInstallmentValue = response.intallmentCheckout === 1 ? true : false;

          } catch (error) {
            console.error(error);
          }
        },
        async editCompany() {
          this.hasReady = false;
          const payload = {
            "idFiscal": this.CNPJ,
            "bank": this.bank,
            "branch": this.agency,
            "digitBranch": this.agencyDigit,
            "account": this.account,
            "digitAccount": this.accountDigit,
            "name": this.companyName,
             "anticipation": this.receivablesAdvance ? 1 : 0,
            "videoComments": this.mediaComments ? 1 : 0,
            "cartStatus": this.cartClosure ? 1 : 0,
            "intallmentCheckout": this.showInstallmentValue ? 1 : 0,
          }
          try {
            const response = await ApiCompany.editCompany(this.token, this.idCompany, payload);
            this.hasReady = true;
          } catch (error) {
            console.error(error);
          }
        }
    },
    created() {
        this.getCompany();
    }
}
</script>


<style scoped>
@import url(./global.css);
.container {
    max-width: 100%;
 }
.titleContainer {
    display: block;
    text-align: left;
    padding: 0px 10px;
    border-bottom: 1px solid #e8e8e8;
    margin: 10px;
}
.container {
    background-color: white;
    padding: 10px 0px;
    padding-bottom: 50px;
}
.btnNewCollection {
    position: absolute;
    width: auto;
    padding: 6px 12px;
    background-color: #250891;
    color: white;
    border-radius: 5px;
    top: 20px;
    right: 20px;
    cursor: pointer;
}
.collecttionsItens .item {
    display: block;
    text-align: left;
    background-image: url('https://assets.sambaplay.tv/collection_banner/4375631b-49f8-4b05-8b6c-31d85cf6cf6f/raw.png');
    background-size:cover ;
    background-position: center;
    border-radius: 5px;
    margin: 10px 0px;
    cursor: pointer;
    position: relative;
}
.collecttionsItens {
    padding: 10px;
}
.titleItemCollection {
    color: white;
    font-size: 25px;
}
.descriptionItemCollection  {
    margin-top: 35px;
    display: flex;
    gap: 10px;
}
.descriptionItemCollection p {
    color: white;
    margin: 0;
}
.descriptionItemCollection svg path {
    fill: #ffffff;
    stroke-width: 0;
    stroke: white;
}
.descriptionItemCollection svg {
    font-size: 15px;
}
.typePerson {
    display: flex;
    gap: 15px;
    justify-content: space-around;
    padding: 10px 15px;
    border-radius: 5px;
}
.itemtypePerson {
    width: 50%;
    background-color: #ededed;
    border-radius: 5px;
    cursor: pointer;
    transition: all 500ms ease-in-out;
}
.itemtypePerson.active {
    background-color: #260791;
}
.itemtypePerson h3 {
    font-size: 16px;
    margin: 0;
    padding: 10px;
}
.itemtypePerson.active h3 {
    color: white;
}
.formPerson.flex {
    flex-wrap: wrap;
    gap: 0px;
}
hr {
    border-color: #b1b1b1;
    height: 1px;
    width: 100%;
    margin: 10px 10px;
}
.flex.shippment {
    flex-wrap: wrap;
}
@media (max-width: 600px) {
  .formPerson.flex {
    display: block;
  }
  .col-form-inner.col-4.col-md-4.col-lg-4 {
    width: 100%;
  }
  .col-form-inner.col-8.col-md-8.col-lg-8 {
    width: 100%;
  }
  .col-form-inner.col-2.col-md-2.col-lg-2 {
    width: 100%;
  }
  .col-form-inner.col-1.col-md-1.col-lg-1 {
    width: 100%;
  }
}
.form-control:disabled {
  background-color: #f0f0f0;
}
</style>
