<template>
    <div class="loginRegister">
        <div class="headerLoginRegister">
                <span class="close" @click="redirect"><fa icon="fa-xmark"/>Sair</span>
        </div>
        <div class="contentCheckout" v-if="collectionCheckout">
            <div class="planSubscription">
                <div class="plan">
                    <div class="planName">
                        <h4>Coleção</h4>
                    </div>
                    <div class="planTitle">
                        <h4>{{collectionCheckout.name}}</h4>
                    </div>
                </div>
                <div class="planPrice">
                    <p><span class="price"><span class="installment">A VISTA POR  </span><span class="priceSubscription">R${{ formatPrice(collectionCheckout.price).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }}</span></span></p>
                    <p v-if="paymentMethod === 1"><span class="installment">EM ATÉ {{ collectionCheckout.collection.installment }}x DE</span> <span class="priceSubscription">R$ {{ formatPrice(collectionCheckout.price / collectionCheckout.collection.installment).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }}</span></p>
                </div>
            </div>

            <!-- Seleção do método de pagamento -->
            <div class="payment-method-selector">
                <div class="method-option" :class="{ active: paymentMethod === 1 }" @click="setPaymentMethod(1)">
                    <fa icon="fa-credit-card" /> Cartão de Crédito
                </div>
                <div class="method-option" :class="{ active: paymentMethod === 2 }" @click="setPaymentMethod(2)">
                    <fa icon="fa-qrcode" /> PIX
                </div>
            </div>
           
            <!-- Novo componente de cupom -->
            <div class="couponSection">
                <button class="toggleCoupon" @click="(()=>this.showCouponInput = !this.showCouponInput)">
                    <fa icon="fa-tag" /> {{ this.showCouponInput ? 'Ocultar cupom' : 'Adicionar cupom' }}
                </button>
                <div v-if="this.showCouponInput" class="couponInput">
                    <input 
                        type="text" 
                        v-model="couponCode"
                        placeholder="Digite seu cupom"
                        class="form-control"
                    />
                    <button @click="validateCoupon" class="validateCoupon" :disabled="!couponCode">
                        Aplicar
                    </button>
                </div>
                <p v-if="couponMessage" :class="{'success-message': couponValid, 'error-message': !couponValid}">
                    {{ couponMessage }}
                </p>
            </div>

            <div class="paymentMethods">
               <div class="paymentMethodsOptions" :class="paymentMethod == 1 ? 'creditcard' : 'pix'">
                    <!-- Formulário de Cartão de Crédito -->
                    <div class="paymentMethodOptionCreditCard" v-if="paymentMethod == 1">
                        <div class="payment-form-container">
                            <!-- Lado esquerdo - Formulário -->
                            <div class="payment-form">
                                <div class="flex inputCheckout">
                                    <div class="form-group">
                                        <div class="form-label">
                                          <label>Número do Cartão</label>
                                        </div>
                                        <div class="form-input">
                                          <input
                                            type="text" 
                                            required
                                            class="form-control"
                                            v-model="cardNumber"
                                            placeholder="1234 5678 9012 3456"
                                            v-mask="'#### #### #### ####'"
                                          />
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="form-label">
                                          <label>Nome do Cartão</label>
                                        </div>
                                        <div class="form-input">
                                          <input
                                            type="text" 
                                            required
                                            class="form-control"
                                            v-model="cardName"
                                            placeholder="Nome como está no cartão"
                                          />
                                        </div>
                                    </div>
                                </div>
                                <div class="flex inputCheckout">
                                    <div class="form-group">
                                        <div class="form-label">
                                          <label>Validade</label>
                                        </div>
                                        <div class="form-input">
                                          <input
                                            type="text" 
                                            required
                                            class="form-control"
                                            v-model="cardExpiry"
                                            placeholder="MM/AA"
                                            v-mask="'##/##'"
                                          />
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="form-label">
                                          <label>CVV</label>
                                        </div>
                                        <div class="form-input">
                                          <input
                                            type="text" 
                                            required
                                            class="form-control"
                                            v-model="cardCVV"
                                            placeholder="123"
                                            v-mask="'###'"
                                          />
                                        </div>
                                    </div>
                                </div>
                                <div class="flex inputCheckout">
                                    <div class="form-group">
                                        <div class="form-label">
                                          <label>Parcelamento</label>
                                        </div>
                                        <div class="form-input">
                                            <select
                                              required
                                              class="form-control selectInstallment"
                                              v-model="installments"
                                            >
                                              <option :value="i" v-for="i in collectionCheckout.collection.installment" :key="i">{{i}}x R$ {{formatPrice(price / i)}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="form-label">
                                          <label>CPF</label>
                                        </div>
                                        <div class="form-input">
                                          <input
                                            type="text" 
                                            required
                                            class="form-control"
                                            v-model="cpf"
                                            placeholder="123.456.789-00"
                                            v-mask="'###.###.###-##'"
                                          />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <!-- Lado direito - Preview do Cartão -->
                            <div class="card-preview-container">
                                <div class="card-preview">
                                    <div class="card-number">{{ cardNumber || '**** **** **** ****' }}</div>
                                    <div class="card-name">{{ cardName || 'NOME DO TITULAR' }}</div>
                                    <div class="card-expiry">{{ cardExpiry || 'MM/AA' }}</div>
                                    <div class="card-cvv">{{ cardCVV || 'CVV' }}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Opção PIX -->
                    <div class="paymentMethodOptionPix" v-if="paymentMethod == 2">
                        <div class="pix-info">
                            <h3>Pagamento via PIX</h3>
                            <p>Ao clicar em "Gerar QR Code PIX", você será redirecionado para a página de pagamento onde poderá escanear o QR Code ou copiar o código PIX.</p>
                        </div>
                    </div>

                    <div class="paymentAction">
                        <button class="buttonCheckout payment active" @click="checkoutPayment">
                            {{ paymentMethod === 1 ? 'Finalizar Compra' : 'Gerar QR Code PIX' }}
                        </button>
                    </div>
               </div>
            </div>
          
            <div class="errorPayment" v-if="errorPayment"> 
                <p style="color: red;">{{errorPaymentMessage}}</p>
            </div>
        </div>
        <div v-else class="empty-cart">
            <div class="empty-cart-content">
                <fa icon="fa-shopping-cart" class="cart-icon" />
                <h3>Seu carrinho está vazio</h3>
                <p>Você não possui nenhum produto no carrinho</p>
                <router-link to="/" class="back-button">
                    <button class="buttonCheckout active">
                        Voltar para a loja
                    </button>
                </router-link>
            </div>
        </div>

        <footer class="checkout-footer">
            <div class="footer-content">
                <div class="footer-section">
                    <h4>Atendimento</h4>
                    <p>Seg a Sex - 9h às 18h</p>
                    <p>suporte@weflix.com</p>
                </div>
                <div class="footer-section">
                    <h4>Pagamento Seguro</h4>
                    <div class="payment-methods">
                        <fa icon="fa-lock" /> SSL Secure Payment
                    </div>
                </div>
                <div class="footer-section">
                    <h4>Políticas</h4>
                    <a href="#">Termos de Uso</a>
                    <a href="#">Política de Privacidade</a>
                </div>
            </div>
            <div class="footer-bottom">
                <p>&copy; 2024 Weflix. Todos os direitos reservados.</p>
            </div>
        </footer>
    </div>
    <div class="backgroundLoginRegister"></div>

    <!-- Popup de sucesso do trial -->
    <div class="trial-success-overlay" v-if="showTrialSuccess">
        <div class="trial-success-popup">
            <h3>Trial Ativado com Sucesso!</h3>
            <button class="buttonCheckout active" @click="goToSite">
                Ir para o site
            </button>
        </div>
    </div>

    <!-- Loader de pagamento -->
    <div class="payment-loader-overlay" v-if="isProcessingPayment">
        <div class="payment-loader">
            <fa icon="fa-spinner" class="fa-spin" />
        </div>
    </div>

</template>
<script>
import { mapState, mapActions } from 'vuex';
import ApiPayment from '@/utils/Weflix/Website/payment';

export default {
    data(){
        return{
            openModalLogin: false,
            typeLogin: 1,
            hasReadyToLogin: false,
            typeInput: 'password',
            remainingTime: 15 * 60,
            timerInterval: null,
            paymentMethod: 1,
            cardNumber: '',
            cardName: '',
            cardExpiry: '',
            cardCVV: '',
            cpf: '',
            selectedPlan: 1,
            installments: 4,
            errorPayment: false,
            showCouponInput: false,
            couponCode: '',
            couponValid: false,
            couponMessage: '',
            price: 0,
            errorPaymentMessage: 'Um ou mais erros ocorreram, tente novamente mais tarde ou utilize outro cartão.',
            trial: {
                days: 30
            },
            showTrialSuccess: false,
            isProcessingPayment: false,
            pixCode: 'PIX0123456789' // Exemplo de código PIX
        }
    },
    computed:{
        ...mapState(['collectionCheckout', 'userPortalWeflix','finalWebsite','dataPaymentWeflix']),
    },
    methods:{
        ...mapActions(['storeUserPortalWeflix','storeDataPaymentWeflix']),
        startTimer() {
            this.timerInterval = setInterval(() => {
                if (this.remainingTime > 0) {
                    this.remainingTime--;
                } else {
                    this.stopTimer();
                }
            }, 1000);
        },
        stopTimer() {
            clearInterval(this.timerInterval);
        },
        formatTime(seconds) {
            const hours = Math.floor(seconds / 3600);
            const minutes = Math.floor((seconds % 3600) / 60);
            const remainingSeconds = seconds % 60;
            return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
        },
        setPaymentMethod(method){
            this.paymentMethod = method;
        },
        formatPrice(price){
            return price.toFixed(2).replace('.', ',');
        },
        copyPixCode() {
            navigator.clipboard.writeText(this.pixCode)
                .then(() => alert('Código PIX copiado!'))
                .catch(err => console.error('Erro ao copiar:', err));
        },
        redirect() {
            window.location = '/';
        },
        async checkoutPayment(){
            this.isProcessingPayment = true;
            const payload = {
                "id": this.collectionCheckout.collection.id,
                "installments":  this.collectionCheckout.collection.installment ? this.collectionCheckout.collection.installment : 1,
                "cupomCode": this.couponCode ? this.couponCode : "",
                "typePayment": this.paymentMethod
            }

            if (this.paymentMethod === 1) {
                payload.card = {
                    "number": this.cardNumber.replace(/[ ]/g, ''),
                    "holder_name": this.cardName,
                    "exp_month": this.cardExpiry.split('/')[0],
                    "exp_year": this.cardExpiry.split('/')[1],
                    "cvv": this.cardCVV,     
                }
            }

            try {
                const response = await ApiPayment.checkoutCollection(payload, this.userPortalWeflix.acessToken);
                this.storeDataPaymentWeflix({dataPaymentWeflix:response});
                this.$router.push('/sucesso-colecao');
            } catch (error) {
                this.errorPayment = true;
                if (error.response && error.response.data) {
                    if(error.response.data.message && error.response.data.message.length > 0) {
                        this.errorPaymentMessage = error.response.data.message;
                    } else {
                        this.errorPaymentMessage = "Um ou mais erros ocorreram, tente novamente mais tarde ou utilize outro cartão.";
                    }
                } else {
                    this.errorPaymentMessage = error.response.data.message;
                }
                console.error('Erro ao realizar checkout:', error);
            } finally {
                this.isProcessingPayment = false;
            }
        },
        async validateCoupon(){
            try {
                const response = await ApiPayment.validateCoupon(this.couponCode, this.finalWebsite.company.id);
                
                if(response && response.discount) {
                    this.couponValid = true;
                    this.couponMessage = 'Cupom aplicado com sucesso!';
                    this.price = this.price - (this.price * response.discount / 100);
                } else {
                    this.couponValid = false; 
                    this.couponMessage = 'Cupom inválido';
                }
            } catch (error) {
                this.couponValid = false;
                this.couponMessage = 'Erro ao validar cupom';
                console.error('Erro ao validar cupom:', error);
            }
        },
        goToSite(){
            window.location.href = "/";
        }
    },
    mounted() {
        this.startTimer();
        this.price = this.collectionCheckout.price;
        this.installments = this.collectionCheckout.collection.installment; 
    },
    beforeUnmount() {
        this.stopTimer();
    },
   
}
</script>

<style scoped>
.loginRegister {
    background-color: white;
    width: 100%;
    opacity: 1;
    transition: all 200ms ease-in-out;
    min-height: 100vh;
}

/* Estilos para seleção do método de pagamento */
.payment-method-selector {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin: 20px 0;
}

.method-option {
    padding: 15px 30px;
    border: 2px solid #ddd;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    gap: 10px;
}

.method-option.active {
    border-color: #4CAF50;
    background-color: #f0f9f0;
}

.method-option:hover {
    border-color: #4CAF50;
}

/* Estilos para a seção PIX */
.paymentMethodOptionPix {
    text-align: center;
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
}

.pix-info {
    background-color: #f8f9fa;
    padding: 30px;
    border-radius: 10px;
    margin-top: 20px;
}

.pix-info h3 {
    color: #333;
    margin-bottom: 20px;
}

.pix-info p {
    color: #666;
    line-height: 1.6;
}

/* Estilos para a seção de cupom */
.couponSection {
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
    padding: 10px;
}

.toggleCoupon {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    color: #333;
    cursor: pointer;
    font-size: 14px;
    padding: 5px 10px;
    margin: 0 auto;
}

.couponInput {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.couponInput input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.validateCoupon {
    background-color: black;
    color: white;
    border: none;
    padding: 8px 15px;
    border-radius: 4px;
    cursor: pointer;
}

.validateCoupon:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.success-message {
    color: green;
    font-size: 14px;
    margin-top: 5px;
}

.error-message {
    color: red;
    font-size: 14px;
    margin-top: 5px;
}

span.close svg.svg-inline--fa.fa-xmark {
    color: gray;
}
.headerLoginRegister {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    color: gray;
}
.headerLoginRegister > span {
   cursor: pointer;
}
span.close {
    display: flex;
    gap: 15px;
    align-items: center;
    color: gray;
}
.login {
    max-width: 800px;
    margin: 0 auto;
    margin-top: 18%;
}
.register {
    max-width: 800px;
    margin: 0 auto;
    margin-top: 15%;
}
.inputLogin {
    display: flex;
    gap: 35px;
}
.titleLogin {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 30px;
}
.submitLogin button {
    color: rgb(255, 255, 255) !important;
    background-color: rgb(218, 223, 231) !important;
    padding: 18px 120px;
    margin-top: 5%;
    border-radius: 5px;
    font-size: 20px;
    font-weight: 700;
}
.submitLogin button.active {
    color: rgb(255, 255, 255) !important;
    background-color: rgb(0, 0, 0) !important;
}
.createAccount {
    transform: translateY(200px);
}
.createAccount p {
    font-size: 13px;
    color: gray;
}
.createAccount p a {
    margin-left: 5px;
    color: rgb(44, 158, 214);
    text-decoration: none;
    font-weight: 700;
}
.inputLogin svg.svg-inline--fa {
    color: gray;
    position: absolute;
    right: 0;
    cursor: pointer;
}
.register .createAccount {
    transform: translateY(100px);
    margin: 0 auto;
}
.toolbarCheckout {
    background-color: black;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.toolbarCheckout h4 {
    color: white;
    margin: 0;
    font-size: 18px;
}
span.timeRemaining {
    font-weight: 900;
    font-size: 20px;
}
button.buttonCheckout {
    width: 200px;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #f3f3f3;
    margin: 0px 5px;
    transition: all 500ms linear;
    box-shadow: -1px 7px 10px 1px #eeeeee;
}
button.buttonCheckout:hover {
    border-radius: 5px;
    box-shadow: -1px 7px 10px 1px #c6c6c6;
}
button.buttonCheckout.active {
    background-color: #4CAF50;
    color: white;
    box-shadow: -1px 7px 10px 1px #696969;
    border-color: #4CAF50;
}
.paymentMethods {
    max-width: 1000px;
    margin: 0 auto;
}

/* Novo layout do formulário de cartão */
.payment-form-container {
    display: flex;
    gap: 40px;
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
}

.payment-form {
    flex: 1;
    max-width: 500px;
}

.card-preview-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 20px;
}

.card-preview {
    background-image: url('@/assets/card-weflix.png');
    background-size: cover;
    width: 100%;
    height: 235px;
    border-radius: 15px;
    position: relative;
    padding: 20px;
    color: white;
    font-family: monospace;
    box-shadow: 0 10px 20px rgba(0,0,0,0.2);
    transition: all 0.3s ease;
    position: relative;
}
.card-number {
   position: absolute;
    top: 57%;
    left: 10%;
    font-size: 22px;
}
.card-name {
    position: absolute;
    top: 70%;
    left: 10%;
    font-size: 16px;
}
.card-cvv {
    position: absolute;
    top: 82%;
    left: 25%;
    font-size: 16px;
}
    .card-expiry {
    position: absolute;
    top: 82%;
    left: 10%;
    font-size: 16px;
}
.card-preview:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(0,0,0,0.3);
}

span.price {
    display: flex;
    align-items: center;
    gap: 10px;
}
.planSubscription {
    max-width: 600px;
    margin: 80px auto;
    justify-content: space-between;
    display: flex;
}
.plan {
    display: flex;
    align-items: center;
}
.planName {
    border-right: 1px solid gray;
    padding-right: 10px;
    display: flex;
    align-items: center;
}

.planName h4 {
    font-size: 13px;
    margin: 0;
}
.planTitle h4 {
    font-size: 16px;
    font-weight: 700;
    margin: 0;
}
.planTitle {
    padding-left: 10px;
    text-align: left;
}
span.installment {
    font-size: 10px;
}
span.priceSubscription {
    font-weight: 600;
    font-size: 18px;
}
.security {
    margin: 20px 0px;
}
.security h3 {
    font-size: 15px;
    font-weight: 700;
}
.flex.inputCheckout{
    display: flex;
    gap: 10px;  
    flex-wrap: nowrap;
}
.flex.inputCheckout .form-group {
    margin: 10px 0;
    width: 100%;
}

.form-label label {
    font-size: 14px;
    color: #333;
    margin-bottom: 5px;
}

.form-input input, .form-input select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 14px;
    transition: border-color 0.3s ease;
}

.form-input input:focus, .form-input select:focus {
    border-color: #4CAF50;
    outline: none;
}

/* Estilos do Footer */
.checkout-footer {
    background-color: #f8f9fa;
    padding: 40px 0 20px;
    margin-top: 50px;
}

.footer-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 0 20px;
}

.footer-section {
    margin-bottom: 30px;
    min-width: 200px;
}

.footer-section h4 {
    color: #333;
    margin-bottom: 15px;
    font-size: 18px;
}

.footer-section p {
    color: #666;
    margin: 5px 0;
    font-size: 14px;
}

.footer-section a {
    display: block;
    color: #666;
    text-decoration: none;
    margin: 5px 0;
    font-size: 14px;
}

.footer-section a:hover {
    text-decoration: underline;
}

.payment-methods {
    color: #666;
    font-size: 14px;
}

.footer-bottom {
    text-align: center;
    margin-top: 30px;
    padding-top: 20px;
    border-top: 1px solid #ddd;
}

.footer-bottom p {
    color: #666;
    font-size: 12px;
}

/* Estilos do Popup de Sucesso do Trial */
.trial-success-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.trial-success-popup {
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    text-align: center;
    max-width: 400px;
    width: 90%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.trial-success-popup h3 {
    color: #000000;
    margin-bottom: 20px;
    font-size: 24px;
}

.trial-success-popup button {
    margin-top: 20px;
    width: 100%;
    max-width: 200px;
}

/* Estilos do Loader de Pagamento */
.payment-loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.payment-loader {
    color: white;
    font-size: 24px;
}

/* Mobile */
@media (max-width: 768px) {
    .payment-form-container {
        flex-direction: column;
        gap: 20px;
    }

    .card-preview-container {
        order: -1; /* Move o cartão para cima no mobile */
    }

    .card-preview {
        width: 100%;
        max-width: 350px;
        margin: 0 auto;
    }

    .payment-form {
        max-width: 100%;
    }

    .flex.inputCheckout {
        flex-direction: column;
    }

    .flex.inputCheckout .form-group {
        width: 100%;
        margin: 0 !important;
    }

    .planSubscription {
        flex-direction: column;
        text-align: center;
        margin: 20px auto;
    }

    .plan {
        justify-content: center;
        margin-bottom: 15px;
    }
}

.planPrice p{
    margin: 0;
}
.paymentAction.mobile {
    margin-top: 20px;
}
select.form-control.selectInstallment {
    border: 1px solid #dbdcde !important;
}
.empty-cart {
    min-height: 57vh;
    padding-top: 200px;
}
@media (max-width: 600px) {
    .method-option {
        padding: 10px;
    }
    span.price[data-v-7a50253d] {
        justify-content: center;
    }
}
</style>
