<template>
    <div class="collections siteWeflix colecao">
        <div class="itensCollections colecao">
            <div class="titleCollections">
                <h3>Coleções Recomendadas</h3>
            </div>
            <div class="videoCollections">
                <div class="itensVideoCollections">
                    <swiper
                        :modules="modules"
                        :slides-per-view="isMobile ? 2 : 4" 
                        :space-between="50"
                        navigation
                        @swiper="onSwiper"
                        @slideChange="onSlideChange"
                    >
                        <swiper-slide v-for="slide in collections.slice(0, 6).filter(s => s.imageFinalPath != null && s.imageFinalPath != '')" :key="slide.id">
                            
                            <div class="carousel__item" @click="toggleModalSubscribe(slide.id, slide.sample, slide.owned, slide.price, slide)">
                     
                            <spna id="line1"></spna>
                            <spna id="line2"></spna>
                            <!-- <img src="@/assets/Weflix/img-long.png" alt="Imagem padrão"> -->
                            <img v-if="!slide?.imageFinalPath || !slide?.imageFinalPath || slide.imageFinalPath == null" src="@/assets/Weflix/img-long.png" alt="Imagem padrão">
                            <img v-else :src="slide.imageFinalPath2? slide.imageFinalPath2 : slide.imageFinalPath" :alt="slide?.title || 'Imagem da coleção'"/>

                                <div class="lockIcon" v-if="userPortalWeflix && slide.sample !== 1">
                                    <fa v-if="userPortalWeflix.status !== 1" icon="lock" class="lockIcon"/>
                                    <span v-if="userPortalWeflix.user.status !== 1" class="textPrivateVideo">Conteúdo Exclusivo</span>
                                </div>

                                <div class="lockBlock" v-if="!userPortalWeflix && slide.sample !== 1">
                                    <fa icon="lock" class="lockIcon" />
                                    <span class="textPrivateVideo">Conteúdo Exclusivo</span>
                                </div>

                                <div class="sampleFlag" v-if="slide.sample === 1">
                                    <span>DEGUSTAÇÃO</span>
                                </div>

                                <!-- <div class="degradeCarousel__item"></div> -->
                                <div class="descriptionVideo" v-if="finalWebsite.visibleTitleCourse == 1">
                                    <h4>{{slide.name}}</h4>
                                    <div class="videoInfo">
                                        <small style="display: flex;align-items: center;gap: 5px;"><fa icon="film" style="width: 15px;color: gray;" /> {{slide.lessonQty}}</small>
                                    </div>
                                </div>
                            </div>
                        </swiper-slide>
                    </swiper>
                </div>
            </div>
            <div class="seeMoreCollections">
            <router-link to="/colecoes" class="ver-todas">
                Ver todas as coleções
            </router-link>
            </div>
        </div>
        <!-- <div class="seeMoreCollections">
            <a href="/colecoes">Ver mais</a>
        </div> -->

         <!-- Modal de Aviso -->
         <!-- <div v-if="showPopup" class="purchase-modal">
            <div class="modal-content">
                <h3>Conteúdo Exclusivo</h3>
                <p>Para acessar este conteúdo, você precisa adquirir esta coleção.</p>
                <small v-if="!userPortalWeflix">Realize o login ou cadastre-se para finalizar a compra.</small>
                <div class="modal-buttons">
                    <button @click="(()=>this.showPopup = false)" class="cancel-button">Fechar</button>
                    <button @click="redirectToCheckout" class="buy-button">Comprar Agora</button>
                </div>
            </div>
        </div> -->
    </div>
</template>
<script>
import ApiWebsite from '@/utils/Weflix/Website/index';
import ApiCollections from '@/utils/Weflix/collections';
import { mapState, mapActions } from 'vuex';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default {
    data(){
        return{
            collections: [],
            titleCollection:['Primeire Coleção', 'Segunda Coleção', 'Terceira Coleção'],
            mobile:false,
            baseUrl: process.env.NODE_ENV === 'development' ? process.env.VUE_APP_BASE_URL_HOMOLOGATION  : window.location.origin,
            breakpoints: {  
                1024: {
                    itemsToShow: 3.6,
                    snapAlign: 'start',
                },
            },
            showPopup: false,
            selectedCollection: null,
            modules: [Navigation, Pagination, Scrollbar, A11y]
        }
    },
    computed:{
        ...mapState(['userPortalWeflix','finalWebsite']),
        isMobile() {
            return window.innerWidth <= 768;
        }
    },
    methods:{
        ...mapActions(['storeCollectionCheckout']),
        onSwiper(swiper) {
        },
        onSlideChange() {
        },
        toggleModalSubscribe( collection = null){
            this.$router.push('/videos-colecao/'+collection.id);
        },
        setCollectionCheckout(collection) {
            console.debug('Chamou o setCollectionCheckout');
            if(this.userPortalWeflix){
                this.showPopup = true;
                this.$store.commit('SET_COLLECTION_CHECKOUT', {
                id: collection.id,
                name: collection.comercialName,
                url: collection.url,
                price: collection.price,
                imageFinalPath: collection.imageFinalPath,
                totalVideos: collection.totalVideos,
                collection: collection
            });
            } else {
                this.showPopup = true;
            }
           
        },
        async getCollections(){
            const aux = this.baseUrl.replace(/\/$/, '');
          
            const payload = {
                "url": aux
            }
            try {
                const response = await ApiCollections.getCollectionWithOutLessons(payload, 0);
                this.collections = response.content.sort((a, b) => a.order - b.order);
                console.debug(response);
            } catch (error) {
                console.error(error);
            }
        },

        itemsToShow(itemCount) {
            if (this.mobile) return 1;
            return itemCount === 1 ? 1 : Math.min(itemCount, 3.6);
        },
        shouldWrapAround(itemCount) {
            return !this.mobile && itemCount > 1;
        },
        snapAlign(itemCount) {
            if (this.mobile || itemCount === 1) return 'center';
            return 'start';
        },
        handleResize() {
            this.mobile = window.innerWidth <= 768;
        },
        formatarTempo(tempo) {
            const horas = Math.floor(tempo / 3600);
            return `${horas}h`;
        },
       
        closePopup() {
            this.showPopup = false;
        },
        confirmPurchase() {
            if(this.userPortalWeflix){
                this.$router.push('/checkoutweflix');
            } else {
                this.$emit('toggleOpenDialog');

                this.closePopup();
            }
        },
        redirectToCheckout(){
            if(this.userPortalWeflix){
                this.$router.push('/checkout-collection-weflix');
            } else {
                this.$emit('toggleOpenDialog');
                this.closePopup();
            }
        }
    },
    created(){
        this.getCollections();
        this.mobile = window.innerWidth <= 768;
        window.addEventListener('resize', this.handleResize);
    },
    unmounted() {
        window.removeEventListener('resize', this.handleResize);
    },
    components: {
        Swiper,
        SwiperSlide
    },
    emits: ['toggleOpenSubscribe', 'toggleOpenDialog']
}
</script>
<style>
.itensCollections.colecao:hover .videoCollections{
    filter: brightness(1);
}
.carousel__item {
    position: relative;
    cursor: pointer;
    overflow: hidden;
}
.carousel__item img {
    width: 98%;
}
.itensCollections {
    max-width: 1500px;
    margin: 0 auto;
    padding: 20px 0px;
}
.titleCollections {
    display: block!important;
    justify-content: center!important;
    align-items: baseline;
    color: white;
}
.collections {
    background-color: black;
    padding: 20px 0px 50px 0px;
}
.titleCollections h3 {
    font-size: 20px;
    font-weight: 700;
}
.carousel__prev {
    left: -4%;
}
.carousel__next {
    right: -4%;
}
svg.carousel__icon {
    color: white;
}
svg.svg-inline--fa.fa-lock.lockIcon path {
    fill: transparent;
    stroke: white;
    stroke-width: 25px;
}
svg.svg-inline--fa.fa-lock.lockIcon {
    position: absolute;
    top: 30px!important;
    right: 5px!important;
    font-size: 15px;
    z-index: 7;
}
.degradeCarousel__item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #0000007d;
    height: 100%;
    opacity: 0;
    transition: all 0.5s;
}
.carousel__item:hover > .degradeCarousel__item {
    opacity: 1;
}
span.textPrivateVideo {
    color: white;
    font-size: 11px;
    position: absolute;
    right: 45px;
    top: 13px;
    opacity: 0;
    transition: all 500ms linear;
    z-index: 2;
}
.carousel__item:hover  span.textPrivateVideo {
    opacity: 1;
}
.descriptionVideo small, .descriptionVideo h4 {
    color: white;
}
.descriptionVideo h4 {
    font-size: 12px;
    margin-left: 5px;
    margin-top: 10px;
    margin-bottom: 0;
}
.descriptionVideo small {
    font-size: 12px;
    margin-left: 5px;
    font-size: 12px;
    margin-left: 5px;
    margin-top: 10px;
}
.videoInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 5px;
}
.button-single-collection {
    background-color: v-bind('finalWebsite?.color');
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    font-size: 14px;
    cursor: pointer;
    text-decoration: none;
    padding: 12px 20px;
    margin: 10px;
}
.price-collection {
    color: #e0e0e0;
    font-size: 14px;
}
@media screen and (max-width: 600px) {
    .carousel__slide {
         display: block; 
         width: 101% !important; 
    }
    .siteWeflix.collections {
        background-color: black;
        padding: 20px !important;
    }
    .hide-mobile {
        display: none;
    }
}
.collections.siteWeflix .itensCollections {
    max-width: 1300px;
}
.swiper-slide .carousel__item img {
    height: 100%;
    margin-top: 20px;
    border-radius: 10px !important;
    transition: all 500ms linear;
}
ol.carousel__track {
    gap: 0px 10px;
}
.descriptionVideo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 5px;
}
/*.sampleFlag {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: v-bind('finalWebsite?.color');
    padding: 3px 8px;
    border-radius: 3px;
    z-index: 7;
}*/
.sampleFlag {
    position: absolute;
    bottom: 55px;
    right: 10px;
    background-color: var(--9132494e-finalWebsite\?\.color);
    padding: 3px 8px;
    border-radius: 3px;
    z-index: 7;
}
.sampleFlag span {
    color: white;
    font-size: 11px;
    font-weight: bold;
}
.seeMoreCollections {
    display: flex;
    justify-content: center;
}
.seeMoreCollections a {
    transition: all 500ms ease-in-out;
    color: #e0e0e0;
    text-decoration: none;
    font-size: 15px;
    margin: 20px;
    display: block;
    padding: 10px 20px;
    background-color: #1a1a1a;
    width: auto;
    border-radius: 6px!important;
    border-color: #9f9f9f;
}
.seeMoreCollections a:hover {
    background-color: #525252;
    color: #ffffff;
}
.seeMoreCollections a:hover {
    background-color: v-bind('finalWebsite?.color');
    color: #ffffff;
}
.cta-single-collection-light {
    text-align: center;
    display: block;
    margin: 20px;
}
.videoCollections {
    transition: all 500ms linear;
    margin-top: 25px;
}

.purchase-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.85);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(5px);
}

.popup-content {
    background-color: #ffffff;
    border-radius: 12px;
    position: relative;
    width: 90%;
    max-width: 450px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    overflow: hidden;
}

.popup-header {
    padding: 25px;
    border-bottom: 1px solid #eee;
}

.popup-header h4 {
    color: #333;
    font-size: 24px;
    margin: 0 0 20px 0;
    font-weight: 600;
}

.collection-info {
    display: flex;
    gap: 15px;
    align-items: center;
}

.collection-thumbnail {
    width: 80px;
    height: 80px;
    border-radius: 8px;
    object-fit: cover;
}

.collection-details h5 {
    font-size: 18px;
    color: #333;
    margin: 0 0 8px 0;
}

.collection-stats {
    display: flex;
    gap: 15px;
    color: #666;
    font-size: 14px;
}

.popup-body {
    padding: 25px;
}

.price-info {
    margin-bottom: 20px;
    text-align: center;
}

.total-price {
    font-size: 24px;
    color: #333;
    margin: 0 0 5px 0;
}

.installments {
    color: #666;
    font-size: 14px;
}

.purchase-button {
    background-color: v-bind('finalWebsite?.color');
    color: white;
    border: none;
    padding: 15px 25px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    transition: all 0.3s ease;
}

.purchase-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.arrow-icon {
    font-size: 14px;
}

.close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    font-size: 20px;
    color: #666;
    cursor: pointer;
    padding: 8px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
}

.close-button:hover {
    background-color: #f5f5f5;
    color: #333;
}

@media screen and (max-width: 600px) {
    .popup-content {
        width: 95%;
        margin: 15px;
        max-height: 90vh;
        overflow-y: auto;
    }
    
    .popup-header,
    .popup-body {
        padding: 20px;
    }
    
    .collection-thumbnail {
        width: 60px;
        height: 60px;
    }
    
    .collection-details h5 {
        font-size: 16px;
    }
}
.button-group {
    display: flex;
    gap: 10px;
}
button.back-button {
    width: 45%;
    background-color: #cccccc;
    border-radius: 10px;
    color: #787878;
}
.purchase-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: #282828;
    padding: 30px;
    border-radius: 10px;
    width: 90%;
    max-width: 400px;
    text-align: center;
}
.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    color: var(--bs-modal-color);
    pointer-events: auto;
    outline: 0;
    box-shadow: 0px 1px 20px 20px #1b1b1b;
    border-radius: 10px;
    background-color: black !important;
}

.modal-content h3 {
    color: white;
    margin-bottom: 15px;
    font-size: 24px;
}

.modal-content p {
    color: #a4a4a4;
    margin-bottom: 25px;
    font-size: 16px;
}

.modal-buttons {
    display: flex;
    gap: 15px;
    justify-content: center;
}

.modal-buttons button {
    padding: 12px 25px;
    border: none;
    border-radius: 6px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
}

.cancel-button {
    background-color: #464646;
    color: white;
}

.buy-button {
    background-color: v-bind('finalWebsite?.color');
    color: white;
}

.cancel-button:hover,
.buy-button:hover {
    filter: brightness(1.1);
    transform: translateY(-1px);
}
.modal-content small {
    color: #a0a0a0;
    margin-bottom: 10px;
}
@media (max-width: 600px) {
    .titleCollections {
        display: block!important;
        text-align: center!important;
    }
    .cta-single-collection-light {
        text-align: left!important;
    }
}
.colecao .swiper-slide:hover .carousel__item img, .swiper-slide.swiper-slide-next:hover .carousel__item img{
    transform: scale(1.2);
    filter: brightness(1.2);
}

.colecao .swiper-slide:hover #line1, .swiper-slide.swiper-slide-next:hover #line1{
    display: none;
}
.colecao.swiper-slide:hover #line2, .swiper-slide.swiper-slide-next:hover #line2{
    display: none;
}
.colecaov.swiper-slide:hover  .carousel__item{
    border: 3px solid white;
    border-radius: 10px;
}
.colecao .carousel__item {
    transition: all 500ms linear;
}
.colecao span.textPrivateVideo {
    position: absolute;
    top: 30px;
    right: 40px;
    /* font-size: 15px; */
}

.ver-todas {
    display: inline-block;
    padding: 12px 24px;
    border: 1px solid #fff;
    border-radius: 4px;
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    transition: all 0.3s ease;
}

.ver-todas:hover {
    background: rgba(255,255,255,0.1);
}
.titleCollections p {
    color: #a3a3a3;
}
.carousel__item spna#line1 {
    position: absolute;
    top: 14px;
    height: 5px;
    width: 90%;
    background-color: #ffffff;
    left: 0;
    z-index: 99999;
    margin: 0 auto;
    left: 50%;
    transform: scale(1.5);
    position: absolute;
    top: 14px!important;
    height: 5px;
    width: 90%;
    background-color: #ffffffa6;
    z-index: 99999;
    margin: 0 auto;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px 10px 0px 0px;
}


.carousel__item spna#line2 {
    position: absolute;
    top: 14px;
    height: 5px;
    width: 90%;
    background-color: #ffffff;
    left: 0;
    z-index: 99999;
    margin: 0 auto;
    left: 50%;
    transform: scale(1.5);
    position: absolute;
    top: 7px!important;
    height: 5px;
    width: 84%;
    background-color: #ffffff69;
    z-index: 99999;
    margin: 0 auto;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px 10px 0px 0px;
}
</style>
