<template>
    <div class="global_banner" :class="[dark ? 'dark' : '']">
        <div class="degrade" :class="[dark ? 'dark' : '']"></div>
        <div class="banner" :style="{ backgroundImage: `url(${banner})` }" ></div>
        <div class="content">
            <div class="content_layout">
                <label>Continue assistindo</label>
                <p>{{ title }}</p>
                <button class="btn-color" @click="goCourse(data[0]?.id)">
                    <i class="pi pi-play"></i> Ir para Curso
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    props: {
        view: Boolean,
        data: Array
    },
    data() {
        return {
            userImage: require("@/assets/bg-course.png"),
            banner: null,
            title: null
        };
    },
    computed: {
        ...mapState(['dark','token','listCoursesPortal'])
    },
    methods: {
        goCourse(id) {
            window.location.href = `/aluno/curso/${id}`;
        },
        async getDataCourses(){
            if(this.listCoursesPortal){
                    this.banner = await this.listCoursesPortal ? this.listCoursesPortal[0].course.imageFinalPath : null;
                    this.title = await this.listCoursesPortal ? this.listCoursesPortal[0].course.name : null;
            } 
        }
    },
    created() {
        this.getDataCourses();   
    }
  
};
</script>


<style scoped>
.global_banner{
    position: relative;
}
.banner {
    background: no-repeat;
    background-position: right;
    height: 450px;
    background-size: cover;
}
.degrade {
    transition: all 500ms linear;
    z-index: 0;
    height: 450px;
    position: absolute;
    z-index: 0;
    width: 100%;
    background: rgb(255 255 255);
    background: linear-gradient(95deg, rgb(255 255 255) 0%, rgb(255 255 255 / 62%) 56%, rgb(0 0 0 / 0%) 83%);
}
.degrade.dark {
    background: rgb(0,0,0);
    background: linear-gradient(95deg, rgba(0, 0, 0, 1) 0%, rgb(0 0 0 / 40%) 55%, rgba(0, 212, 255, 0) 83%);
    transition: all 500ms linear;
}
.dark .degrade_back {
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 100px;
    background-color:rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.5858718487394958) 39%, rgba(0,212,255,0) 83%);
}
.degrade_back {
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 100px;
    background-color:rgb(255, 255, 255);
    background: linear-gradient(0deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0.586) 39%, rgba(0,212,255,0) 83%);
}
.content {
    position: absolute;
    bottom: 0;
    width: 50%;
    z-index: 9;
    background-color: rgba(255, 255, 255, 0);
    top: 0%;
    display: flex;
    align-items: center;
    padding-left: 100px;
}
.content_layout {
    text-align: left;
}
.dark .content_layout p {
    color: #F3F3F3;
    font-family: Inter;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    letter-spacing: 0.96px;
}
.content_layout p {
    color: #000000;
    font-family: Inter;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    letter-spacing: 0.96px;
}
.content_layout label{
    color: rgba(0, 0, 0, 0.59);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 103.6%; /* 16.576px */
    letter-spacing: 0.16px;
    margin-bottom: 10px;
}
.dark .content_layout label{
    color: rgba(243, 243, 243, 0.59);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 103.6%; /* 16.576px */
    letter-spacing: 0.16px;
    margin-bottom: 10px;
}
button.btn-color {
    border-radius: 4px;
    background: var(--Turquesa, #1AC7D0);
    display: flex;
    padding: 12px 14px 12px 12px;
    align-items: center;
    gap: 8px;
    border: none;
    color: #ffffff;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 103.6%;
    letter-spacing: 0.16px;
}
.dark button.btn-color {
    color: #000;
}
i.pi.pi-play {
    border: none;
}
@media only screen and (max-width:600px){
    .content {
        
        width: 100%;
        z-index: 9;
        background-color: rgba(255, 255, 255, 0);
        top: 0%;
        display: flex;
        align-items: center;
        padding-left: 20px;
    }
}
</style>