<template>
    <div class="global_footer weeducStudentFooter" :class="{ 'dark': this.dark }">
            <div class="logo">
                <img  v-show="!this.dark" src="../../../../../src/assets/logo.png">
                <img  v-show="this.dark" src="../../../../../src/assets/logo_white.png">
            </div>
            
            <div class="options">
                <div class="menu item">
                    <ul>
                        <li>
                            Copyright @ 2024 | Todos os direitos reservados
                        </li>
                    </ul>
                </div>
            </div>
       </div>
</template>
<script>
import { mapState } from 'vuex';

export default{
    data(){
        return{

        }
    },
    computed:{
        ...mapState(['dark'])
    }

}
</script>
<style>
@import url('./index.css');
@media only screen and (max-width:600px){

    .global_footer {
        display: block;
        padding: 10px 10px;
    }

}
.global_footer.weeducStudentFooter .item {
    background-color: white !important;
}
/* DARK */
.global_footer.weeducStudentFooter.dark .item {
    background-color: #575757 !important;
    color: white !important;
}
</style>