<template>
    <div class="container doubts-container weeducAdminEditCourse">
        <div class="titleContainer myCoursesWeeduc">
            <h3>Editar Curso</h3>
            <p style="font-size: 14px;margin-bottom: 0;">Edite as informações básicas do seu curso.</p>
        </div>

        <div class="form-container">
            <div class="form-row new-course" v-if="hasReady">
                <div class="form-group col-6">
                    <div class="form-group col-12">
                        <p class="form-label-title">Nome do curso*</p>
                        <div class="form-input">
                            <input 
                                type="text"
                                class="form-control"
                                v-model="courseData.courseName"
                                placeholder="Digite o nome do curso"
                                required
                                @input="updateCourseUrl"
                            />
                            <small>Nome que aparecerá no administrativo, não será exibido no site</small>
                        </div>
                    </div>

                    <div class="form-group col-12">
                        <p class="form-label-title">Nome do comercial*</p>
                        <div class="form-input">
                            <input 
                                type="text"
                                class="form-control"
                                v-model="courseData.commercialName"
                                placeholder="Digite o nome do comercial"
                                required
                            />
                            <small>Nome que aparecerá no site, não será exibido no administrativo</small>
                        </div>
                    </div>

                    <div class="form-group col-12">
                        <label class="form-label-title">Descrição Curta</label>
                        <div class="form-input">
                            <textarea
                                class="form-control"
                                v-model="courseData.courseDescription" 
                                placeholder="Adicione uma breve descrição do curso"
                                rows="4"
                            ></textarea>
                            <small>Descrição curta que aparecerá na página do curso</small>
                        </div>
                    </div>

                    <div class="form-group col-12">
                        <label class="form-label-title">Descrição Completa</label>
                        <div class="form-input">
                            <textarea
                                class="form-control"
                                v-model="courseData.courseLongDescription" 
                                placeholder="Adicione informações detalhadas sobre o curso"
                                rows="6"
                            ></textarea>
                            <small>Descrição completa que aparecerá na página do curso</small>
                        </div>
                    </div>

                    <div class="form-group col-12">
                        <p class="form-label-title">Url do curso*</p>
                        <div class="form-input">
                            <input 
                                type="text"
                                class="form-control"
                                v-model="courseData.courseUrl"
                                placeholder="Digite a URL do curso"
                                required
                            />
                            <small>Url do curso, link para a página do curso</small>
                        </div>
                    </div>
                </div>

                <div class="form-group col-6">
                    <div class="form-group col-12">
                        <p class="form-label-title">Status*</p>
                        <div class="form-input">
                            <div class="radio-group">
                                <div class="radio-option">
                                    <input type="radio" id="ativo" value="1" v-model="courseData.courseStatus" name="status" checked>
                                    <label for="ativo" class="radio-label">
                                        <p class="radio-text">Ativo</p>
                                        <small>Produto é publicado e disponível no site, apto para matrícula</small>
                                    </label>
                                </div>
                                <div class="radio-option">
                                    <input type="radio" id="matriculas" value="2" v-model="courseData.courseStatus" name="status">
                                    <label for="matriculas" class="radio-label">
                                        <p class="radio-text">Apenas matrículas internas</p>
                                        <small>Não fica disponível no site, somente os administradores podem cadastrar alunos</small>
                                    </label>
                                </div>
                                <div class="radio-option">
                                    <input type="radio" id="espera" value="3" v-model="courseData.courseStatus" name="status">
                                    <label for="espera" class="radio-label">
                                        <p class="radio-text">Em Espera</p>
                                        <small>Mostra no site que o produto será lançado em breve, porém ainda não aceita matrículas</small>
                                    </label>
                                </div>
                                <div class="radio-option">
                                    <input type="radio" id="inativo" value="4" v-model="courseData.courseStatus" name="status">
                                    <label for="inativo" class="radio-label">
                                        <p class="radio-text">Inativo</p>
                                        <small>Não aparece na loja virtual, não permite novas matrículas</small>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group col-12">
                        <p class="form-label-title">Capa do Produto - Principal</p>
                        <div class="form-file">
                            <div class="file-wrapper primary">
                                <span class="thumbnail thumb-img">
                                    <img v-if="imagePreviewCover" :src="imagePreviewCover" class="preview-image" />
                                    <span class="icon" v-if="loaderImage && !imagePreviewCover">
                                        <svg width="46" height="39" viewBox="0 0 46 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M45 19.9308L36.7274 12.3475C36.1595 11.827 35.2948 11.8047 34.7009 12.2953L18.824 25.411C18.3196 25.8277 17.6074 25.8812 17.0464 25.5446L11.1009 21.9773C10.5893 21.6703 9.94666 21.6856 9.45024 22.0166L1 27.6501" stroke="#9D9FA3" stroke-width="1.25714"></path>
                                            <rect x="1.00049" y="1.40454" width="43.9461" height="36.1909" rx="2.58507" stroke="#9D9FA3" stroke-width="1.25714"></rect>
                                            <circle cx="14.8948" cy="10.6676" r="3.71629" stroke="#9D9FA3" stroke-width="1.25714"></circle>
                                        </svg>
                                    </span>
                                    <span class="text" v-if="loaderImage && !imagePreviewCover">
                                        Clique aqui para selecionar a imagem
                                    </span>
                                    <LoaderImage :hasReady="loaderImage"/>
                                </span>
                                <input class="form-control" type="file" @change="updateFileCover" accept=".png, .jpg, .jpeg, .webp"/>
                            </div>
                            <div class="form-caption">
                                <div class="caption">
                                    <small>Tamanho: 1200x400px, apenas formato PNG e JPG.</small>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group col-12">
                        <p class="form-label-title">Capa do Produto - Thumbnail</p>
                        <div class="form-file">
                            <div class="file-wrapper primary">
                                <span class="thumbnail thumb-img">
                                    <img v-if="imagePreviewCover2" :src="imagePreviewCover2" class="preview-image" />
                                    <span class="icon" v-if="loaderImage2 && !imagePreviewCover2">
                                        <svg width="46" height="39" viewBox="0 0 46 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M45 19.9308L36.7274 12.3475C36.1595 11.827 35.2948 11.8047 34.7009 12.2953L18.824 25.411C18.3196 25.8277 17.6074 25.8812 17.0464 25.5446L11.1009 21.9773C10.5893 21.6703 9.94666 21.6856 9.45024 22.0166L1 27.6501" stroke="#9D9FA3" stroke-width="1.25714"></path>
                                            <rect x="1.00049" y="1.40454" width="43.9461" height="36.1909" rx="2.58507" stroke="#9D9FA3" stroke-width="1.25714"></rect>
                                            <circle cx="14.8948" cy="10.6676" r="3.71629" stroke="#9D9FA3" stroke-width="1.25714"></circle>
                                        </svg>
                                    </span>
                                    <span class="text" v-if="loaderImage2 && !imagePreviewCover2">
                                        Clique aqui para selecionar a imagem
                                    </span>
                                    <LoaderImage :hasReady="loaderImage2"/>
                                </span>
                                <input class="form-control" type="file" @change="updateFileCover2" accept=".png, .jpg, .jpeg, .webp"/>
                            </div>
                            <div class="form-caption">
                                <div class="caption">
                                    <small>Tamanho: 300x300px, apenas formato PNG e JPG.</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-row col-12">
                  <div class="form-group col-1 price">
                    <p class="form-label-title">Valor* <small class="small">(De)</small></p>
                    <div class="form-input">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">R$</span>
                            </div>
                            <input 
                                type="text"
                                class="form-control price-input"
                                v-model="courseData.coursePrice"
                                placeholder="0,00"
                                required
                                pattern="[0-9]*"
                                inputmode="decimal"
                                @input="formatPrice"
                            />
                        </div>
                    </div>
                </div>

                <div class="form-group col-1 discount">
                    <p class="form-label-title">Desconto* <small class="small">(Por)</small></p>
                    <div class="form-input">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">R$</span>
                            </div>
                            <input 
                                type="text"
                                class="form-control price-input"
                                v-model="courseData.discountAmount"
                                placeholder="0,00"
                                required
                                pattern="[0-9]*"
                                inputmode="decimal"
                                @input="formatDiscount"
                            />
                        </div>
                    </div>
                </div>

                <div class="form-group col-1">
                    <p class="form-label-title">Valor Final*</p>
                    <div class="form-input">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">R$</span>
                            </div>
                            <input 
                                type="text"
                                class="form-control price-input"
                                :value="(courseData.coursePrice - courseData.discountAmount).toFixed(2).replace('.', ',')"
                                placeholder="0,00"
                                readonly
                            />
                        </div>
                    </div>
                </div>

                    <div class="form-group col-1">
                        <p class="form-label-title">Aprovação*</p>
                        <div class="form-input">
                            <label class="switch">
                                <input type="checkbox" v-model="courseData.approvalEnabled" :disabled="showImportedQuestions">
                                <span class="slider round"></span>
                            </label>
                        </div>
                    </div>

                    <div class="form-group col-2" v-if="courseData.approvalEnabled">
                        <p class="form-label-title">Critério de Aprovação*</p>
                        <div class="form-input">
                            <input 
                                type="text" 
                                class="form-control" 
                                v-model="courseData.approvalCriteria" 
                                placeholder="9" 
                                required 
                            />
                            <small>Nota mínima para aprovação</small>
                        </div>
                    </div>

                    <div class="form-group col-1">
                        <p class="form-label-title">Certificado*</p>
                        <div class="form-input">
                            <label class="switch">
                                <input type="checkbox" v-model="courseData.certificateEnabled">
                                <span class="slider round"></span>
                            </label>
                        </div>
                    </div>

                    <div class="form-group col-2" v-if="courseData.certificateEnabled">
                        <p class="form-label-title">Selecione o certificado*</p>
                        <div class="form-input">
                            <select class="form-control" v-model="courseData.certificateId" required>
                                <option value="1">Certificado 1</option>
                                <option value="2">Certificado 2</option>
                                <option value="3">Certificado 3</option>
                            </select>
                            <small>Selecione o certificado para o curso</small>
                        </div>
                    </div>

                
                </div>
            </div>         
             <Loader :hasReady="hasReady" /> 

            <div class="button-container">
                <button class="btn-weflix" @click="$router.go(-1)">Cancelar</button>
                <button class="btn-weflix primary" @click="updateCourse">Atualizar</button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import LoaderImage from '@/components/Weflix/LoaderImage';
import Loader from '@/components/Weflix/Loader';
import apiAWS from '@/utils/storage/aws';
import apiCourses from '@/utils/courses';

export default {
    data() {
        return {
            loading: false,
            courseData: {
                courseName: '',
                coursePrice: null,
                courseStatus: '1',
                courseDescription: '',
                courseLongDescription: '',
                coverImage: null,
                thumbnailImage: null,
                availabilityDeadline: 'vitalicio',
                commercialName: '',
                courseUrl: '',
                approvalEnabled: false,
                approvalCriteria: '',
                discountAmount: 0,
                certificateEnabled: false,
                certificateId: 1
            },
            loaderImage: true,
            loaderImage2: true,
            imagePreviewCover: null,
            imagePreviewCover2: null,
            coverVideo: null,
            coverVideo2: null,
            courseId: null,
            hasReady: false
        }
    },
    computed: {
        ...mapState(['token'])
    },
  
    methods: {
        ...mapActions(['storeCurrentCollections']),

        async getCourseData() {
            try {
                this.loading = true;
                const response = await apiCourses.getCourse(this.courseId, this.token);
                this.courseData = {
                    courseName: response.name,
                    coursePrice: response.price,
                    courseStatus: response.status.toString(),
                    courseDescription: response.shortDescription,
                    courseLongDescription: response.longDescription,
                    coverImage: response.imagePath,
                    thumbnailImage: response.imagePath2,
                    availabilityDeadline: response.dayExpired,
                    commercialName: response.comercialName,
                    courseUrl: response.url,
                    approvalEnabled: response.approved === 1,
                    approvalCriteria: response.approvalCriteria,
                    discountAmount: response.discount,
                    certificateEnabled: response.certificate === 1,
                    certificateId: response.certificateId
                };
                
                this.imagePreviewCover = response.imageFinalPath;
                this.imagePreviewCover2 = response.imageFinalPath2;
                this.coverVideo = response.imagePath;
                this.coverVideo2 = response.imagePath2;
                this.hasReady = true;
            } catch (error) {
                console.error('Erro ao carregar dados do curso:', error);
            } finally {
                this.loading = false;
            }
        },

        formatPrice(event) {
            let value = event.target.value.replace(/\D/g, '')
            value = (Number(value) / 100).toFixed(2)
            this.courseData.coursePrice = value
        },

        formatDiscount(event) {
            let value = event.target.value.replace(/\D/g, '')
            value = (Number(value) / 100).toFixed(2)
            this.courseData.discountAmount = value
        },

        updateCourseUrl() {
            const formattedUrl = this.courseData.courseName
                .toLowerCase()
                .replace(/ç/g, 'c')
                .replace(/[^a-z0-9\s]/g, '')
                .replace(/\s+/g, '-')
                .trim();

            this.courseData.courseUrl = formattedUrl;
        },

        async updateCourse() {
            const payload = {
                id: this.courseId,
                name: this.courseData.courseName,
                price: this.courseData.coursePrice,
                status: this.courseData.courseStatus,
                coverImage: this.coverVideo,
                thumbnailImage: this.coverVideo2,
                certificate: this.courseData.certificateEnabled ? 1 : 0,
                comercialName: this.courseData.commercialName,
                url: this.courseData.courseUrl,
                shortDescription: this.courseData.courseDescription,
                longDescription: this.courseData.courseLongDescription,
                imagePath: this.coverVideo,
                imagePath2: this.coverVideo2,
                dayExpired: 0,
                approved: this.courseData.approvalEnabled ? 1 : 0,
                installment: 0,
                order: 0,
                discount: this.courseData.discountAmount,
                certificateId: this.courseData.certificateId,
                approvalCriteria: this.courseData.approvalCriteria
            }
            
            try {
                
                this.hasReady = false;
                const response = await apiCourses.editCourse( this.courseId, payload, this.token);
                this.getCourseData();
             
            } catch (error) {
                console.error('Erro ao atualizar curso:', error)
            } 
        },

        async putFileAws(url, file) {
            try {
                const response = await apiAWS.putFileAWS(url, file);
                return response;
            } catch (error) {
                console.error('Erro ao enviar arquivo para AWS');
            }
        },

        async updateFileCover(event) {
            this.loaderImage = false;
            const file = event.target.files[0];
            this.imagePreviewCover = URL.createObjectURL(file);
            try {
                const response = await apiAWS.getSignatureImage(this.token, file.name);
                await this.putFileAws(response.url, file);
                this.coverVideo = response.token;
                this.loaderImage = true;
            } catch (error) {
                console.error('Erro na geração do TOKEN AWS')
            }
        },

        async updateFileCover2(event) {
            this.loaderImage2 = false;
            const file = event.target.files[0];
            this.imagePreviewCover2 = URL.createObjectURL(file);
            try {
                const response = await apiAWS.getSignatureImage(this.token, file.name);
                await this.putFileAws(response.url, file);
                this.coverVideo2 = response.token;
                this.loaderImage2 = true;
            } catch (error) {
                console.error('Erro na geração do TOKEN AWS')
            }
        },
    },
    created() {
        this.courseId = this.$route.params.slug;
        this.getCourseData();
    },
    components: {
        LoaderImage,
        Loader
    }
}
</script>

<style scoped>
.col-1 {
  flex: 0 0 auto;
  width: 9.333333%;
}
.container {
    max-width: 100%;
    background-color: #ffffff;
    padding: 1.5rem;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.title-container {
    position: relative;
    margin-bottom: 10px;
    padding-bottom: 0;
    border-bottom: 1px solid #f0f0f0;
    text-align: left;
}

.title-container .title {
    font-size: 1.5rem;
    font-weight: 700;
    color: #000000;
    margin-bottom: 0.5rem;
}

.title-container .subtitle {
    color: #6b7280;
    font-size: 0.875rem;
    line-height: 1.4;
    margin-bottom: 1rem;
}

.form-container {
    padding: 0px 20px;
}

.form-group {
    margin-bottom: 20px;
}
.small {
    font-size: 11px;
    color: gray;
}
.form-row {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    flex-wrap: wrap;
}

.col-6 {
    flex: 0 0 calc(50% - 10px);
}

.form-group label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
    color: #4a5568;
}

.form-control {
    width: 100%;
    padding: 0.5rem 0.75rem;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    font-size: 0.875rem;
    transition: all 0.2s ease;
}

.form-control:focus {
    outline: none;
    border-color: #2f2e8b;
    box-shadow: 0 0 0 3px rgba(47, 46, 139, 0.1);
}

textarea.form-control {
    resize: vertical;
    min-height: 120px;
}

.file-wrapper {
    border: 2px dashed #e2e8f0;
    border-radius: 6px;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s;
    position: relative;
   
}

.file-wrapper:hover {
    border-color: #2f2e8b;
    background: #f8fafc;
}

.thumbnail {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #6b7280;
    width: 100%;
    height: 100%;
    position: relative;
}

.preview-image {
    max-width: 100%;
    max-height: 180px;
    object-fit: contain;
}

.button-container {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    margin-top: 30px;
}

.btn-weflix {
    padding: 0.5rem 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    background: white;
    color: #4a5568;
    cursor: pointer;
    transition: all 0.2s;
}

.btn-weflix.primary {
    background: #2f2e8b;
    color: white;
    border: none;
}

.btn-weflix:hover {
    background: #f8fafc;
    border-color: #cbd5e0;
}

.btn-weflix.primary:hover {
    background: #23225f;
}

.thumb-img {
    position: relative;
    width: 100%;
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.thumb-img .icon,
.thumb-img .text {
    position: absolute;
    z-index: 1;
}

.thumb-img .icon {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.thumb-img .text {
    bottom: 20px;
    width: 100%;
    text-align: center;
}

@media (max-width: 768px) {
    .container {
        padding: 1rem;
    }

    .form-row {
        flex-direction: row;
        gap: 0;
    }

    .col-6 {
        flex: 0 0 100%;
    }
}

.radio-option {
    display: flex;
    gap: 10px;
    align-items: baseline;
}

.form-row.new-course .form-group.col-6 {
    flex: auto !important;
    margin: 0 !important;
    padding: 0 !important;
    width: 33% !important;
}

.form-row.new-course .form-group.col-6 .form-group.col-12 {
    flex: auto !important;
    margin: 10px 0px !important;
    padding: 0 !important;
    width: 100% !important;
}

.input-group-text {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    text-align: center;
    white-space: nowrap;
    background-color: var(--bs-tertiary-bg);
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    width: 100%;
    padding: 7px;
    border: none !important;
}

.input-group {
    border: none !important;
}

p.form-label-title {
    margin-bottom: 10px;
    font-weight: 500;
}

label.radio-label p.radio-text {
    font-weight: 700;
    margin-bottom: 0px !important;
}

input.form-control.price-input {
    width: 83px !important;
    flex: none !important;
}

.radio-group {
    display: flex;
    flex-direction: column;
}

.form-row.new-course > .form-group.col-12 {
    display: flex;
    width: 100%;
    margin: 0 !important;
}

/* Switch toggle style */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 25px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 17px;
    width: 17px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
}

input:checked + .slider {
    background-color: #2f2e8b;
}

input:checked + .slider:before {
    transform: translateX(36px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

@media (max-width: 768px) {
    .form-row.new-course .form-group.col-6 {
        width: 100% !important;
    }
    .radio-group {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .form-row.new-course .form-group.col-1,
    .form-row.new-course .form-group.col-2 {
        width: 100% !important;
    }
    .form-row.new-course .form-group.col-1.price,
    .form-row.new-course .form-group.col-1.discount {
        width: 43% !important;
    }
}
/* DARK */
.dashboard.dark .content.dashboard > .container.weeducAdminEditCourse {
    background-color: #000;
}
.dashboard.dark .container.doubts-container.weeducAdminEditCourse h3 {
    color: white !important;
}
.dashboard.dark .container.doubts-container.weeducAdminEditCourse p {
    color: white !important;
}
.dashboard.dark .container.doubts-container.weeducAdminEditCourse small {
    color: white !important;
}
.dashboard.dark .container.doubts-container.weeducAdminEditCourse label {
    color: white !important;
}
.dashboard.dark .container.doubts-container.weeducAdminEditCourse input.form-control {
    color: white !important;
}
.dashboard.dark .container.doubts-container.weeducAdminEditCourse .form-group .form-input .form-control {
    background-color: #575757 !important;
    border: 1px solid #444 !important;
    color: white !important;
}
.dashboard.dark .container.doubts-container.weeducAdminEditCourse .file-wrapper {
    border: 2px dashed #444 !important;
}
.dashboard.dark .container.doubts-container.weeducAdminEditCourse .file-wrapper:hover {
    background-color: #575757;
    border: 2px dashed #575757 !important;
}
.form-file .file-wrapper>span.thumbnail {
    height: auto;
    padding: 10px;
}

.dashboard.dark .container.doubts-container.weeducAdminEditCourse .titleContainer {
    border-bottom: 1px solid #575757 !important;
}
</style>