<template>
  <section class="layoutThemes weflix-admin-layoutThemes">
    <!-- Bloco 1: Escolha do Tema -->
    <div class="themeSelection" v-if="hasReady">
      <div class="titlelayoutThemes">
          <h3>Galeria de Tema</h3>
          <small>Escolha algum dos temas abaixo para personalizar seu Weflix.</small>
      </div>
      <div class="themesContainer">
        <div class="col-form-inner col-6 col-md-6 col-lg-6" :class="[activeTheme === 0 ? 'active' : '']" @click="alterarActiveTheme(0)">
          <div class="col-form-inner col-12 col-md-12 col-lg-12">
              <div class="itemTheme">
                  <img src="../../../assets/Weflix/theme2.png" alt="Tema 1">
                  <div class="itemTheme-content">
                    <div class="itemTheme-content-header">
                      <h4>One</h4>
                    </div>
                    <!-- <div class="itemTheme-content-body">
                      <p>O tema One possui um visual moderno e escuro com tons de preto e cinza, proporcionando menos fadiga visual e destacando o conteúdo de forma elegante.</p>
                    </div> -->
                  </div>
              </div>
          </div>
        </div>
        <div class="col-form-inner col-6 col-md-6 col-lg-6" :class="[activeTheme === 1 ? 'active' : '']" @click="alterarActiveTheme(1)">
          <div class="col-form-inner col-12 col-md-12 col-lg-12">
            <div class="itemTheme">
              <img src="../../../assets/Weflix/theme1.png" alt="Tema 1">
              <div class="itemTheme-content">
                  <div class="itemTheme-content-header">
                    <h4>Tom</h4>
                  </div>
                  <!-- <div class="itemTheme-content-body">
                    <p>O Tom possui um design clean e minimalista com tons claros, proporcionando uma experiência visual leve e intuitiva.</p>
                  </div> -->
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Bloco 2: Configurações Adicionais -->
    <div class="additionalSettings" v-if="hasReady">
      <div class="titlelayoutThemes">
        <h3>Configurações Adicionais</h3>
        <small>Personalize suas preferências para otimizar a experiência do seu Weflix.</small>
      </div>
      
      <div class="settingsContainer">
        <div class="settingItem">
          <div class="settingHeader">
            <h4>Formato da Capa</h4>
            <small>Escolha como as capas dos vídeos serão exibidas</small>
          </div>
          <div class="coverOptions">
            <label class="optionCard">
              <input type="radio" v-model="typeImageCoverCourse" value="1" name="coverFormat">
              <div class="optionContent">
                <span class="checkmark"></span>
                <span class="optionLabel">Apenas capa horizontal                                
                  <small>1920 x 200px</small>
                </span>
              </div>
            </label>
            <label class="optionCard">
              <input type="radio" v-model="typeImageCoverCourse" value="2" name="coverFormat">
              <div class="optionContent">
                <span class="checkmark"></span>
                <span class="optionLabel">Capa dos vídeos
                   <small>240 x 440px</small>
                </span>
              </div>
            </label>
          </div>
        </div>

        <div class="settingItem">
          <div class="settingHeader">
            <h4>Títulos Visíveis</h4>
           
          </div>
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              v-model="visibleTitleCourse"
            />
            <small>Mostrar títulos dos vídeos abaixo das miniaturas</small>
          </div>
        </div>
      </div>
    </div>

    <div class="actionsSave layoutThemesWeflix">
      <button class="btnSave" @click="updateWebsite">Salvar</button>
    </div>
  </section>
  <Loader :hasReady="hasReady"/>
</template>

<script>
import websiteApi from '@/utils/Weflix/website';
import { mapState } from 'vuex';
import Loader from '@/components/Weflix/Loader.vue';

export default {
  data(){
      return{
          activeTheme: null,
          coverFormat: '1',
          showTitles: false,
          name: '',
          color: '',
          description: '',
          logo: '',
          favicon: '',
          logoPreview: '',
          faviconPreview: '',
          id: '',
          hasReady: false,
          facebook: '',
          gads: '',
          ganalytic: '',
          tagManager: '',
          rdrefreshToken: '',
          rdcode: '',
          rdSecret: '',
          rdClient: '',
          descriptionAbout: '',
          typeImageCoverCourse: null,
          visibleTitleCourse: null,
        }
  },
  computed: {
    ...mapState(['token'])
  },
  components: {
      Loader
  },
  methods: {
    alterarActiveTheme(novoTema) {
      this.activeTheme = novoTema;
    },
    toggleTitles() {
      this.showTitles = !this.showTitles;
    },
    async getWebsite() {
      const response = await websiteApi.getWebsite(this.token);
      this.id = response.id;
      this.name = response.nome;
      this.description = response.description;
      this.logo = response.logo;
      this.favicon = response.favicon;
      this.logoPreview = response.logoFinal;
      this.faviconPreview = response.faviconFinal;
      this.url = response.url;
      this.status = response.status;
      this.hasReady = true;
      this.activeTheme = response.typeLayout;
      this.color = response.color;
      this.facebook = response.facebookPixelCode;
      this.gads = response.googleAdsCode;
      this.ganalytic = response.googleAnalyticsCode;
      this.tagManager = response.tagManagerCode;
      this.rdrefreshToken = response.rdrefreshToken;
      this.rdcode = response.rdcode;
      this.rdSecret = response.rdsecretClient;
      this.rdClient = response.rdclienteId;
      this.descriptionAbout = response.about;
      this.typeImageCoverCourse = response.typeImageCoverCourse;
      this.visibleTitleCourse = response.visibleTitleCourse == 1? true : false;

    },
    async updateWebsite() {
      this.hasReady = false;
      const payload = {
          "description": this.description,
          "logo": this.logo,
          "favicon": this.favicon,
          "logoFinal": this.logoPreview,
          "faviconFinal": this.faviconPreview,
          "nome": this.name,
          "status": this.status,
          "url": this.url,
          "typeLayout": this.activeTheme,
          "color": this.color,
          "facebookPixelCode": this.facebook,
          "googleAdsCode": this.gads,
          "googleAnalyticsCode": this.ganalytic,
          "tagManagerCode": this.tagManager,
          "rdrefreshToken": this.rdrefreshToken,
          "rdcode": this.rdcode,
          "rdsecretClient": this.rdSecret,
          "rdclienteId": this.rdClient,
          "about" : this.descriptionAbout,
          "typeImageCoverCourse": this.typeImageCoverCourse,
          "visibleTitleCourse": this.visibleTitleCourse ? 1 : 0
      }
        try {
          const response = await websiteApi.editWebsite(this.token, this.id, payload);
          this.getWebsite();
          this.hasReady = true;
        } catch (error) {
          console.error('Erro ao atualizar dados do website:', error);
      }
    },
  },  
  created() {
      this.getWebsite()
    }
}
</script>

<style scoped>
.layoutThemes {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.themeSelection, .additionalSettings {
  background: #fff;
}
.themesContainer {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
}
.settingItem {
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
}
.settingHeader {
 text-align: left;
}
.form-switch {
  padding: 10px 55px;
  display: flex;
  gap: 10px;
  align-items: center;
}
input.form-check-input {
  height: 20px;
}
.settingHeader h4 {
  margin: 0;
  color: #333;
  font-size: 16px;
}
.settingHeader small {
  color: #666;
  font-size: 13px;
}
.coverOptions {
  display: flex;
  gap: 15px;
}
.optionCard {
  display: flex;
  align-items: center;
  padding: 12px 20px;
  border-radius: 6px;
  cursor: pointer;
  gap: 10px;
  transition: all 0.3s ease;
}
.optionCard:hover {
  background: #e9ecef;
}
.optionContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}
.optionLabel {
  font-weight: 500;
  color: #333;
}
.optionContent small {
  color: #666;
  font-size: 12px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}
.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}
input:checked + .slider {
  background-color: #250891;
}
input:checked + .slider:before {
  transform: translateX(26px);
}
.actionsSave {
  position: absolute;
  bottom: 30px;
  right: 35px;
  width: 20%;
}
button.btnSave {
  color: #250891;
  width: 150px;
  background-color: #ffffff;
  padding: 6px 10px;
  border-radius: 5px;
  transition: all 500ms ease-in-out;
  font-size: 18px;
  border: 2px solid #250891;
}
button.btnSave:hover {
  background-color: #160266;
  color: white;
}
.titlelayoutThemes {
  text-align: left;
  padding: 10px;
  border-bottom: 1px solid #e3e3e3;
  width: 100%;
}
.col-form-inner.col-6.col-md-6.col-lg-6 {
  max-width: 400px;
  border: 2px solid #f1f1f1;
  padding: 10px 50px;
  padding-bottom: 20px;
  border-radius: 10px;
  position: relative;
  margin-top: 100px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}
.itemTheme img {
  transform: translateY(-55px);
  width: 100%;
}
.itemTheme-content {
  margin-top: -158px;
}
.col-form-inner.col-6.col-md-6.col-lg-6.active {
  background-color: #250891 !important;
  color: white;
}
@media (max-width: 600px) {
  .weflix-admin-layoutThemes h3 {
    font-size: 20px;
  } 
  .weflix-admin-layoutThemes small {
    font-size: 12px;
    color: #666;
  }
  .weflix-admin-layoutThemes .themesContainer {
    padding: 0px 10px 0px 10px;
    margin: 10px 0px 10px 0px;
  }
  .weflix-admin-layoutThemes .actionsSave {
    width: 100%;
    padding: 10px 10px 10px 10px;
  }
  .weflix-admin-layoutThemes .themesContainer .itemTheme-content {
    margin-top: -100px;
  }
  .weflix-admin-layoutThemes .itemTheme img {
    transform: translateY(-40px);
  }
}
@media (max-width: 500px) {
  .actionsSave.layoutThemesWeflix {
    position: inherit !important;
    margin: 0;
  }
}
input[type="radio"]:checked {
  background-color: #260791 !important;
  /* border-color: red !important; */
  appearance: none;
  -webkit-appearance: none;
  /* width: 19px; */
  /* height: 20px; */
  border: 2px solid #c9c9c9;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  padding: 5px;
}
input[type="radio"] {
  background-color: #dddddd !important;
  /* border-color: red !important; */
  appearance: none;
  -webkit-appearance: none;
  /* width: 19px; */
  /* height: 20px; */
  border: 2px solid #a8a8a8;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  padding: 5px;
}
</style>