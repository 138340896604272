<template>
    <div class="bg-dark-create" :class="[showmodal ? 'open' : '']"></div>
    <div class="edit_user" :class="[this.dark? 'dark':'']">
          <div class="modal-content">
            <Loader :isActive="!hasLoaded"></Loader>
            <div class="info-user" v-show="!updatePassword">
              <div class="modal-header">
                <h5 class="modal-title">Perfil</h5>
              </div>
              <div class="modal-body info-perfil-image">
                <div class="form-group">
                  <div class="form-label">
                    <img class="temporary" src="../../../assets/user.png" v-show="!userimagePreview && !userimage"/>
                    <img :src="userimagePreview? userimagePreview : userimage" alt="Logo" v-show="userimagePreview || userimage" />
                  </div>
                  <div class="form-label">
                    <div class="form-label">
                      <label>Imagem de Perfil</label>
                    </div>
                    <div class="form-file">
                      <div class="file-wrapper primary">
                        <span class="thumbnail">
                          <span class="icon">
                            <svg
                              width="46"
                              height="39"
                              viewBox="0 0 46 39"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M45 19.9308L36.7274 12.3475C36.1595 11.827 35.2948 11.8047 34.7009 12.2953L18.824 25.411C18.3196 25.8277 17.6074 25.8812 17.0464 25.5446L11.1009 21.9773C10.5893 21.6703 9.94666 21.6856 9.45024 22.0166L1 27.6501"
                                stroke="#9D9FA3"
                                stroke-width="1.25714"
                              ></path>
                              <rect
                                x="1.00049"
                                y="1.40454"
                                width="43.9461"
                                height="36.1909"
                                rx="2.58507"
                                stroke="#9D9FA3"
                                stroke-width="1.25714"
                              ></rect>
                              <circle
                                cx="14.8948"
                                cy="10.6676"
                                r="3.71629"
                                stroke="#9D9FA3"
                                stroke-width="1.25714"
                              ></circle>
                            </svg>
                          </span>
                          <span class="text">
                            Clique aqui para selecionar a imagem
                          </span>
                        </span>
                        <input class="form-control" type="file"  @change="updateFilePath" accept=".png, .jpg, .jpeg, .webp"/>
                      </div>
                    </div>
                  </div>
                
                </div>
              </div>
              <div class="modal-body double edit_user">
                <div class="form-group">
                  <div class="form-label">
                    <label>
                      Nome  <v-tooltip text="Campo obrigatório" location="top">
                        <template v-slot:activator="{ props }">
                          <i v-bind="props" class="pi pi-exclamation-circle"></i>
                        </template>
                      </v-tooltip>
                    </label>
                  </div>
                  <div class="form-input">
                    <input v-model="name" type="text" class="form-control" placeholder="Nome">
                  </div>
                </div>
  
                <div class="form-group">
                  <div class="form-label">
                    <label>
                      Eamil  <v-tooltip text="Campo obrigatório" location="top">
                        <template v-slot:activator="{ props }">
                          <i v-bind="props" class="pi pi-exclamation-circle"></i>
                        </template>
                      </v-tooltip>
                    </label>
                  </div>
                  <div class="form-input">
                    <input v-model="email" type="text" class="form-control" placeholder="E-mail">
                  </div>
                </div>
              </div>
              <div class="modal-body double">
                <div class="form-group">
                  <div class="form-label">
                    <label>
                      CPF  <v-tooltip text="Campo obrigatório" location="top">
                        <template v-slot:activator="{ props }">
                          <i v-bind="props" class="pi pi-exclamation-circle"></i>
                        </template>
                      </v-tooltip>
                    </label>
                  </div>
                  <div class="form-input">
                    <input v-model="cpf" type="text" class="form-control" placeholder="CPF"  v-mask="'###.###.###-##'">
                  </div>
                </div>
  
  
  
                <div class="form-group">
                  <div class="form-label">
                    <label>
                      Telefone  <v-tooltip text="Campo obrigatório" location="top">
                        <template v-slot:activator="{ props }">
                          <i v-bind="props" class="pi pi-exclamation-circle"></i>
                        </template>
                      </v-tooltip>
                    </label>
                  </div>
                  <div class="form-input">
                    <input v-model="phone" type="text" class="form-control" placeholder="Telefone" v-mask="'(##) #.####-####'">
                  </div>
                </div>
  
              </div>
              <div class="modal-body double">
  
                <div class="form-group">
                  <div class="form-label">
                    <label>Identidade<v-tooltip text="Campo obrigatório" location="top">
                      <template v-slot:activator="{ props }">
                        <i v-bind="props" class="pi pi-exclamation-circle"></i>
                      </template>
                    </v-tooltip></label>
                  </div>
                  <div class="form-input cep">
                    <input
                      v-model="idNumber"
                      type="text"
                      class="form-control"
                      placeholder="x.xxx-xxx"
                    />
                  </div>
                </div>             
                <div class="form-group">
                  <div class="form-label">
                    <label> CEP <v-tooltip text="Campo obrigatório" location="top">
                      <template v-slot:activator="{ props }">
                        <i v-bind="props" class="pi pi-exclamation-circle"></i>
                      </template>
                    </v-tooltip></label>
                  </div>
                  <div class="form-input cep">
                    <input
                      v-model="cep"
                      type="text"
                      class="form-control"
                      placeholder="xxxxx-xxx"
                      @change="getCEP()"
                      v-mask="'#####-###'"
  
                    />
                  </div>
                </div>
  
              </div>
              <div class="modal-body double less">
                <div class="form-group">
                  <div class="form-label">
                    <label>
                      Endereço  <v-tooltip text="Campo obrigatório" location="top">
                        <template v-slot:activator="{ props }">
                          <i v-bind="props" class="pi pi-exclamation-circle"></i>
                        </template>
                      </v-tooltip>
                    </label>
                  </div>
                  <div class="form-input">
                    <input v-model="address" type="text" class="form-control" placeholder="Endereço">
                  </div>
                </div>
                <div class="form-group">
                  <div class="form-label">
                    <label>
                      Complemento  <v-tooltip text="Campo obrigatório" location="top">
                        <template v-slot:activator="{ props }">
                          <i v-bind="props" class="pi pi-exclamation-circle"></i>
                        </template>
                      </v-tooltip>
                    </label>
                  </div>
                  <div class="form-input">
                    <input v-model="addressComplement" type="text" class="form-control" placeholder="Endereço">
                  </div>
                </div>
                
                <div class="form-group">
                  <div class="form-label">
                    <label>
                      Número  <v-tooltip text="Campo obrigatório" location="top">
                        <template v-slot:activator="{ props }">
                          <i v-bind="props" class="pi pi-exclamation-circle"></i>
                        </template>
                      </v-tooltip>
                    </label>
                  </div>
                  <div class="form-input">
                    <input v-model="number" type="text" class="form-control" placeholder="Número">
                  </div>
                </div>
  
              </div>
  
              <div class="modal-body double">
                
                <div class="form-group">
                  <div class="form-label">
                    <label>
                      Cidade  <v-tooltip text="Campo obrigatório" location="top">
                        <template v-slot:activator="{ props }">
                          <i v-bind="props" class="pi pi-exclamation-circle"></i>
                        </template>
                      </v-tooltip>
                    </label>
                  </div>
                  <div class="form-input">
                    <input v-model="city" type="text" class="form-control" placeholder="Cidade">
                  </div>
                </div>
  
                <div class="form-group">
                  <div class="form-label">
                    <label>
                      Estado  <v-tooltip text="Campo obrigatório" location="top">
                        <template v-slot:activator="{ props }">
                          <i v-bind="props" class="pi pi-exclamation-circle"></i>
                        </template>
                      </v-tooltip>
                    </label>
                  </div>
                  <select class="form-select" aria-label="Default select example" v-model="state">
                    <option value="state" selected>Selecione o estado</option>
                    <option :value="item" v-for="item, index in states" :key="index">
                      {{item}} 
                    </option>
                  </select>
                  
                </div>
  
                <div class="form-group">
                  <div class="form-label">
                    <label>
                      Pais  <v-tooltip text="Campo obrigatório" location="top">
                        <template v-slot:activator="{ props }">
                          <i v-bind="props" class="pi pi-exclamation-circle"></i>
                        </template>
                      </v-tooltip>
                    </label>
                  </div>
                  <select class="form-select" aria-label="Default select example" v-model="country">
                    <option value="br" selected>Brasil</option>
                    <option value="outro">Outro</option>
                  </select>
                </div>
              </div>
          </div>
          <div class="info-user-paswword" v-show="updatePassword">
            <div class="modal-header">
              <h5 class="modal-title">Alterar Senha</h5>
            </div>
            <div class="modal-body double">
              <div class="form-group">
                <div class="form-label">
                  <label>
                    Senha Atual
                    <v-tooltip text="Campo obrigatório" location="top">
                      <template v-slot:activator="{ props }">
                        <i v-bind="props" class="pi pi-exclamation-circle"></i>
                      </template>
                    </v-tooltip>
                  </label>
                </div>
                <div class="form-input">
                  <input v-model="oldPassword" type="password" class="form-control" placeholder="Senha Atual">
                </div>
              </div>
            </div>
            <div class="modal-body double">
              <div class="form-group">
                <div class="form-label">
                  <label>
                    Nova senha  <v-tooltip text="Campo obrigatório" location="top">
                      <template v-slot:activator="{ props }">
                        <i v-bind="props" class="pi pi-exclamation-circle"></i>
                      </template>
                    </v-tooltip>
                  </label>
                </div>
                <div class="form-input">
                  <input v-model="password" type="password" class="form-control" placeholder="Nova senha">
                </div>
              </div>
              <div class="form-group">
                <div class="form-label">
                  <label>
                    Confirme a nova senha  <v-tooltip text="Campo obrigatório" location="top">
                      <template v-slot:activator="{ props }">
                        <i v-bind="props" class="pi pi-exclamation-circle"></i>
                      </template>
                    </v-tooltip>
                  </label>
                </div>
                <div class="form-input">
                  <input v-model="rePassword" type="password" class="form-control" placeholder="Confirmar nova senha">
                </div>
              </div>
            </div>
          </div>
            <div class="modal-footer">
              <button type="button" @click="changePassword()" class="btn btn-primary" v-show="updatePassword">Atualizar Senha</button>
              <button type="button" @click="saveModule()" class="btn btn-primary" v-show="!updatePassword">Salvar Dados</button>

              <button type="button" @click="(()=>this.updatePassword=!this.updatePassword)" class="btn btn-secondary">{{updatePassword==true? 'Alterar Dados':'Alterar Senha'}}</button>              
            </div>
          </div>
      <v-dialog
      v-model="dialog"
      width="auto"
    >
      <v-card
        max-width="400"
        prepend-icon="mdi-update"
        text="Um ou mais campo não foram preenchidos corretamenta, por favore revise-os."
        title="Ops, há algum erro!"
      >
        <template v-slot:actions>
          <v-btn
            class="ms-auto"
            text="Ok"
            @click="dialog = false"
          ></v-btn>
        </template>
      </v-card>
    </v-dialog>
    </div>
  </template>
  <script>
  import { mapState } from 'vuex';
  import api from "@/utils/portal/user.js"
  import ApiCep from '@/utils/cep'
  import Loader from '@/components/Loader/loader.vue';
  import apiAWS from '@/utils/storage/aws';
  
  
  export default {
    data() {
      return {
        hasLoaded: false,
        showmodal: '',
        name: '',
        email: '',
        cpf: '',
        phone: '',
        cep: '',
        address: '',
        number: '',
        logo:'',
        city: '',
        state: '',
        country: '',
        county: '',
        password: '',
        rePassword: '',
        oldPassword: '',
        idUser: null,
        idNumber: null,
        userImgage:null,
        userimagePreview:null,
        addressComplement: '',
        hasLoad:false,
        dialog:false,
        updatePassword:false,
        states: [
          "Acre",
          "Alagoas",
          "Amapá",
          "Amazonas",
          "Bahia",
          "Ceará",
          "Distrito Federal",
          "Espírito Santo",
          "Goiás",
          "Maranhão",
          "Mato Grosso",
          "Mato Grosso do Sul",
          "Minas Gerais",
          "Pará",
          "Paraíba",
          "Paraná",
          "Pernambuco",
          "Piauí",
          "Rio de Janeiro",
          "Rio Grande do Norte",
          "Rio Grande do Sul",
          "Rondônia",
          "Roraima",
          "Santa Catarina",
          "São Paulo",
          "Sergipe",
          "Tocantins",
          "Outros"
        ],
      }
    },
    props: {
      show: Boolean,
      id: Number
    },
    components:{
      Loader
    },
    computed: {
      ...mapState(['token','dark'])
    },
    methods: {
      async getDataUser() {
        this.hasLoaded=false;
        try {
          const response = await api.getDataUser(this.token);
          this.name = response.name;
          this.email = response.email;
          this.cpf = response.socialNumber;
          this.phone = response.phone;
          this.cep = response.postcode;
          this.type = response.type;
          this.address = response.address;
          this.number = response.addressNumber;
          this.city = response.city;
          this.state = response.county;
          this.country = response.country;
          this.county = response.county;
          this.idNumber = response.idNumber;
          this.addressComplement = response.addressComplement;
          this.hasLoaded=true;
        } catch (error) {
          console.error("Erro ao caregar dados de usuário", error)
        }
      },
      async validateFields() {
        if (!this.name || !this.email || !this.cpf || !this.phone || !this.cep || !this.address || !this.number || !this.city || !this.state || !this.country || !this.password || !this.repassword) {
          this.dialog = true;
          return false; 
        }
        return true; 
      },
      async editUser(){
          const obj = {
          "name": this.name,
          "email": this.email,
          "password": this.password,
          "phone": this.phone,
          "socialNumber": this.cpf,
          "idNumber": this.idNumber,
          "county": this.state,
          "country": this.country,
          "city": this.city,
          "address": this.address,
          "addressNumber": this.number,
          "addressComplement": this.addressComplement,
          "postcode": this.cep,
          "username": this.email,
          "type": this.type,
          "status": 1,
          "logo": this.logo
        }
        try {
          const response = await api.EditUser(this.token, obj);
          window.location.reload();
  
        } catch (error) {
          console.error('Erro ao eidtar o usuário', error)
        }
      },
      closetab() {
        this.hasLoaded = false;
  
        this.showmodal = false;
        this.updatePassword = false;
        this.$emit('open-tab', this.showmodal)
      },
      opentab() {
        this.show = true,
          this.$emit('open-tab', this.showmodal)
      },
      async saveModule() {
        this.updatePassword = false;
          this.editUser();
          this.showmodal = false;
          this.$emit('open-tab', this.showmodal);
          this.nameModule = null;
          this.demo = null;
      },
      async putFileAws(url, file){
        try {
            const response = await apiAWS.putFileAWS(url, file);
        } catch (error) {
            console.error('Erro ao enviar arquivo para AWS');
        }
      },
      async updateFilePath(event){
          const file = event.target.files[0];
          this.userimagePreview = URL.createObjectURL(file)
  
          const formData = new FormData();
          formData.append('file', this.userimage);

          try {
            const response =  await apiAWS.getSignatureImage(this.token, file.name);
            this.putFileAws(response.url, file)
            this.logo = response.token;

        } catch (error) {
            console.error('Erro na geração do TOKEN AWS')
        }
      },
      async changePassword(){
        if(this.password!=this.rePassword){
          alert('As senhas devem ser iguais');
          return false;
        }
        const obj = {
          "oldPassword": this.oldPassword,
          "newPassword": this.password
        }
        try {
          const response = api.changePassword(this.token, obj);
          this.showmodal = false;
          this.$emit('open-tab', this.showmodal);
        } catch (error) {
          console.error(error)
        }
      },
      async getCEP(){
        try {
          const response = await ApiCep.getDataCep(this.cep);
          console.debug(response);
          this.city = response.city;  
          this.address = response.street;
          const state = response.state;
          if (this.states.includes(state)) {
            this.state = state;
            this.country = 'br';
          } else {
            this.state = "Outros";
            this.country = 'outro';
          }
  
        } catch (error) {
          console.error('Erro ao gerar dados do cep');
        }
      }
    },
    created() {
     
        this.getDataUser();
    
      
    },
  
    watch: {
      show(novoValor) {
        this.showmodal = this.show;
        this.idUser = this.id
        this.getDataUser();
      },
    }
  }
  </script>
  <style scoped>
  @import '../../../components/Admin/Users/Edituser/edituser.css';
  .modal-content {
    padding: 20px;
}
.modal-body.info-perfil-image .form-group {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
}
.modal-body.info-perfil-image .form-group > .form-label:nth-child(2) {
  width: 70%;
}
span.text {
  font-size: 11px;
}
.form-group {
  margin: 10px 5px !important;
}
.modal-body.double {
  display: block;
}
.modal-body.double.less .form-group:first-child {
  width: 100%;
}
.modal-footer > button {
  width: 48%;
}
.modal-footer {
  gap:10px;
}
.edit_user.dark {
  background: black;;
}
.dark .form-group .form-input .form-control {
  background-color: grey;
  color: white;
}
.dark .form-group .form-label label{
  color: white;
}
.dark select.form-select{
  color: white;
  background-color: grey;
}
  </style>
  