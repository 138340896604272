<template>
    <div class="studentPortal">
        <Header :view="ViewMode" @alterar-valor="alterarValor"></Header>
        <RouterView></RouterView>
        <Footer :view="ViewMode"></Footer>
    </div>
</template>
<script>

import { mapState, mapActions } from 'vuex';


import Header from '@/components/Student/Dashboard_Unique/Header/Header'
import Footer from '@/components/Student/Dashboard_Unique/Footer/Footer'


export default{
    data(){
        return{
            ViewMode:false,
            dataCourses:null,
        }
    }, 
    computed:{
        ...mapState(['token']),
        ...mapState(['dark'])
    },
    methods: {
        ...mapActions(['toggleDarkMode']),
        alterarValor(novoValor) {
            this.toggleDarkMode({dark: !this.dark})
        },
    },
    watch: {
        ViewMode(novoValor) {
            this.ViewMode = novoValor;
        }
    },
    components:{
        Header,
        Footer
    },
    created(){
        // this.getDataCourses(this.token);
    }
}
</script>
