import api from '../api';

export default {
    async getDataListInquiry(accessToken) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/portal/inquiry`, {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao carregar listagem de dúvidas:', error);
                throw error;
        }
    },
    async getDataInquiryById(accessToken, id) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/portal/message/${id}`,  {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao carregar os comentários:', error);
                throw error;
        }
    },
    async newComment(accessToken, payload, id) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.post(`/portal/inquiry/message/${id}`, payload, {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao carregar os comentários:', error);
                throw error;
        }
    },
    async newInquiry(accessToken, payload) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.post(`/portal/inquiry`, payload, {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao criar dúvida:', error);
                throw error;
        }
    },

}

