<template>
  <div class="login" :class="{ 'weflix': typeSystem == 2, 'weeduc': typeSystem == 1 }">
    <div class="content">
    
      <div class="info"> 
        <div class="logo">
          <img alt="Vue logo" src="@/assets/logo.png" style="width: 200px" v-show="typeSystem==1" />
          <img alt="Weeduc" src="@/assets/Weflix/Weflix-Logo-Total-Branco.png" style="width: 200px" v-show="typeSystem==2" />
        </div>    
        <div class="form">
          <form @submit.prevent="handleSubmitChangePassword" class="form">
            <div class="mb-3">
              <label for="exampleInputEmail1" class="form-label">Nova Senha</label>
              <input
                type="text"
                v-model="email"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
              />
            </div>
            <div class="mb-3 password">
              <label for="exampleInputPassword1" class="form-label"
                >Confirmar Senha</label
              >
              <input
                v-model="password"
                :type="showPassword"
                class="form-control"
                id="exampleInputPassword1"
              />
              <i
                @click="changeViewPassword()"
                class="pi pi-eye"
                style="cursor: pointer"
                v-show="showPassword=='text'"
              ></i>
              <i
                @click="changeViewPassword()"
                class="pi pi-eye-slash"
                style="cursor: pointer"
                v-show="showPassword=='password'"
              ></i>
            </div>
            <div class="mb-5 text-center">
              <button type="submit" class="btn btn-success" :class="{'weflix': typeSystem==2, 'weeduc': typeSystem==1}">Atualizar Senha</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import  { mapState, mapActions } from 'vuex';
import apiPassword from '@/utils/password.js';
import loginApi from '@/utils/login.js';


export default {
  data() {
    return {
      email: '',
      password: '',
      token: '',
      changePassword:false,
      typeSystemLocal:1,
     baseUrl: process.env.NODE_ENV === 'development' ? process.env.VUE_APP_BASE_URL_HOMOLOGATION  : window.location.origin,



    };
  },
  components: {
  },
  computed: {
    ...mapState(['typeSystem'])
  },
  methods: {
    ...mapActions(['storeTypeId','login','storeToken','storeWebsites','storeRefreshToken','storeUser','toggleDarkMode','storeNumberIntensTable','storeTypeSystem']),

  changeViewPassword(){
    if(this.showPassword=='password'){
      this.showPassword='text';
    } else {
      this.showPassword='password';
    }
  },
    async handleSubmitChangePassword() {
    const obj = {
      'password': this.email,
      'token': this.token,
    }
      try {
        const response = await apiPassword.resetPassowrd(obj);
        this.$router.push('/login');
      } catch (error) {
        console.error(error);
      }
    },
    async getLogo(){
    const aux = this.baseUrl.replace(/\/$/, '');
    const url = aux.split('/novasenha')[0];
    const obj = { url: url }; 

    try {
      const response = await loginApi.logo(obj); 
      this.logo = 'https://image.weeduc.com/'+response.logo;
      this.storeTypeSystem({typeSystem:response.typeSystem});
      this.typeSystemLocal = response.typeSystem; 
    } catch (error) {
      console.error('Error fetching API:', error);
    } 
  },
  },
  created() {
    this.token = this.$route.params.slug;
  },
  mounted(){
    this.getLogo();
    this.typeSystemLocal = this.typeSystem;
  }
}
</script>

<style scoped>
.action {
  display: flex;
  justify-content: end;
  padding: 5px;
}
.mb-3 small {
  margin-top: 10px;
  display: block;
  color: gray;
}
.form {
  max-width: 100%;
  margin: 0 30px;
  text-align: left;
  height: 250px;
  position: relative;
}
.login.weeduc {
  height: 100vh;
  display: flex;
  background-image: url("../../assets/bg-login.png");
  background-size: cover;
}
.login.weflix {
  height: 100vh;
  display: flex;
  background-image: url("../../assets/Weflix/bg-login-weflix.png");
  background-size: cover;
}
.mb-5.text-center.btn {
  width: 100%;
  position: absolute;
  bottom: 0;
}
button.btn.btn-success.weflix {
  background-color: #0d1575 !important;
}
.logo {
  display: flex;
  justify-content: center;
  margin: 20px;
  margin-top: -110px;
  margin-bottom: 50px;

}
@media (max-width:600px) {
  .info {
    width: 87%;
    padding: 0;
    
}
.logo {
  margin-top: -90px;
  margin-bottom: 50px;
}
.form {
  margin: 0px 15px;
}
}
.content {
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 10px;
}
.info {
  width: 450px;
  padding: 30px 10px;
  background-color: white;
  border-radius: 10px;
}
button.btn.btn-success {
  width: 100%;
}
.mb-3 {
  margin: 20px 0px;
}
.mb-3.forgot_password {
  color: red;
  margin: 0 !important;
  font-size: 11px;
  text-decoration: none;
  display: flex;
  justify-content: end;
}
.mb-3.forgot_password a {
  color: #19c7d0;
}
.mb-3.password {
  position: relative;
}
.mb-3.password i {
  position: absolute;
  top: 45px;
  right: 10px;
  color: #b4b4b4;
}
.company {
  max-width: 100%;
  margin: 0 30px;
  text-align: left;
  position: relative;
}
.company button.btn.btn-success {
  margin-top: 10px;
}
.option-company {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
}
@media (max-width:600px) {
  .info {
    width: 87%;
    padding: 0;
    
}
}
</style>
