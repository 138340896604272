<template>
  <section class="layoutLayout weflix-admin-socialMediaLayout">
      <div class="titlelayoutLayout">
          <h3>Redes Sociais</h3>
          <small>Adicione as redes sociais do seu site.
          </small>
      </div>
      <div class="content-form" v-if="hasReady">
        <div class="row">
          <div class="col-form-outer col-12 col-md-12 col-lg-12">
            <div class="list">
              <div class="col-form-inner col-12 col-md-6 col-lg-6 social" v-for="(item, index) in names" :key="index">
                <div class="form-group">
                  <div class="description">
                    <span>{{ item }}</span>
                  </div>
                  <div class="form-check form-switch">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      role="switch"
                      v-model="activeSocial[index]"
                      :id="'flexSwitchCheck' + index"
                    />
                  </div>
                </div>
                <div class="form-group full">
                  <div class="form-input">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="www.facebook.com.br"
                      v-model="linkSocial[index]"
                      :disabled="!activeSocial[index]"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="actionsSave layoutSocialMediaWeflix">
        <button class="btnSave" @click="editSocialMedia">Salvar</button>
      </div>
     
  </section>
 
<Loader :hasReady="hasReady" />
</template>

<script>
import apiSiteSocialMidia from '@/utils/Weflix/socialMedia';
import { mapState } from 'vuex';
import Loader from '@/components/Weflix/Loader.vue';

export default {
  data() {
    return {
      hasSaved: false,
      data:[],
      activeSocial: Array(6).fill(false),
      linkSocial: Array(6).fill(''),
      names: ['Facebook', 'Instagram', 'Youtube', 'Linkedin', 'Whatsapp', 'TikTok'],
      hasReady: false
    };
  },
  computed: {
    ...mapState(['token']),
  },
  components: {
    Loader
  },
  methods: {
    async editSocialMedia() {
      this.hasReady = false;
      const socialData = [];
      const newItems = [];


      this.linkSocial.forEach((link, index) => {

        if(link!==''){
          const id = this.data[index] ? this.data[index].id : null;
          const status = this.activeSocial[index] ? 1 : 0;
          const url = link ? link : null;
          const type = index;
          const name = this.names[index];

          if (id) {
            // Itens existentes para atualização
            socialData.push({ id, name, type, url, status });
          } else if (this.activeSocial[index] == 1) {
            // Novos itens para criação
            newItems.push({ name, type, url, status });
          }
      }
      });
      try {
        if (socialData.length > 0) {
          // Atualiza os itens existentes
          await apiSiteSocialMidia.editSocialMidias(this.token, socialData);
          this.hasReady = true;
        }

        if (newItems.length > 0) {
          // Cria novos itens
          await apiSiteSocialMidia.postSocialMidiastData(this.token, newItems);
          this.hasReady = true;
        }

        // Recarrega os dados após a atualização/criação
        this.getDataSocialMedia();
      } catch (error) {
        console.error(error);
      }
},
  
    async getDataSocialMedia(){

      try {
        const response = await apiSiteSocialMidia.getSocialMidia(this.token);
        this.data = response;
        this.data.forEach((item) => {
          this.activeSocial[item.type] = item.status === 1;
          this.linkSocial[item.type] = item.url;
        });
        this.hasReady = true;
      } catch (error) {
        console.error(error);
      }
    },
  },
  props: {
    saved: Boolean,
  },
  created() {
    this.getDataSocialMedia();
  },
};
</script>

<style scoped>
.actionsSave {
  position: absolute;
  bottom: 30px;
  right: 35px;
}
button.btnSave {
  color: #250891;
  width: 150px;
  background-color: #ffffff;
  padding: 6px 10px;
  border-radius: 5px;
  transition: all 500ms ease-in-out;
  font-size: 18px;
  border: 2px solid #250891;
}
button.btnSave:hover {
  background-color: #160266;
  color: white;
}
.social > .form-group {
  width: 120px;
}
.col-form-inner.col-12.col-md-6.col-lg-6.social {
  display: flex;
  align-items: center;
}
.form-group.full {
  width: 100%;
}
input[id^="flexSwitchCheck"] {
  width: 3em;
  height: 1.5em;
}
.content-button.site > .button {
  display: flex;
  gap: 17px;
  margin: 0em 1em;
}
.form-check-input:checked {
  background-color: #19c7d0;
  border-color: #19c7d0;
}
.titlelayoutLayout {
  text-align: left;
  padding: 10px;
  border-bottom: 1px solid #e3e3e3;
}
@media (max-width: 600px) {
  .weflix-admin-socialMediaLayout h3 {
    font-size: 20px;
    margin-bottom: 5px;
  }
  .weflix-admin-socialMediaLayout small {
    font-size: 12px;
    color: #666;
  }
  .weflix-admin-socialMediaLayout .list {
    padding: 0px 10px 0px 10px;
  }
  .weflix-admin-socialMediaLayout .actionsSave {
    position: relative;
    top: 0;
    right: 0;
    padding: 10px 10px 0px 10px;
    margin: 10px 0px 10px 0px;
  }
}
.content-form {
  width: 100%;
}
.list {
  display: flex;
  flex-wrap: wrap;
}
</style>

