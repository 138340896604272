<template>
    <div class="container cupons-container adminWeeducCupons">
        <div class="title-container">
            <h3 class="title">Cupons</h3>
            <p class="subtitle">Gerencie os cupons do sistema.</p>
            <div class="btn-weflix absolute" @click="newCupomDialog = true">
                <fa icon="fa fa-plus"></fa>
                <span v-show="!isMobileIcon" class="flex">Novo Cupom</span>
            </div>
        </div>

        <div class="search-row">
            <div class="form-group search-form-group" style="flex: 8;">
                <div class="form-input search-form-input">
                    <input
                        type="text" 
                        class="form-control search-form-control"
                        placeholder="Pesquisar cupom..."
                        v-model="searchText"
                    />
                    <fa icon="fa-search" class="input-icon search-input-icon" @click="buscarCupom" />
                </div>
            </div>

            <div class="form-group filter-form-group" style="flex: 2; display: flex; align-items: center;">
                <label style="margin-right: 0.5rem;">Filtrar por:</label>
                <select v-model="statusFilter" class="form-control filter-form-control" @change="filtrarCupons">
                    <option value="todos">Todos</option>
                    <option value="ativo">Ativo</option>
                    <option value="inativo">Inativo</option>
                </select>
            </div>
        </div>

        <div  class="table-responsive cupons-table-responsive">
            <table class="table cupons-table">
                <thead>
                    <tr>
                        <th style="width: 5%;">ID</th>
                        <th style="width: 25%; text-align: left;">Título</th>
                        <th style="width: 20%; text-align: left;">Código</th>
                        <th style="width: 15%;">Status</th>
                        <th style="width: 15%;">Vencimento</th>
                        <th style="width: 10%;">Desconto</th>
                        <th style="width: 10%;">Ações</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="cupom in paginatedCupons" :key="cupom.id">
                        <td style="width: 5%;">#{{cupom.id}}</td>
                        <td style="width: 25%; text-align: left;">{{cupom.title}}</td>
                        <td style="width: 20%; text-align: left;">{{cupom.code}}</td>
                        <td style="width: 15%;">
                            <span :class="cupom.status && cupom.status == 1 ? 'badge-active' : 'badge-inactive'">
                                {{cupom.status && cupom.status == 1 ? 'Ativo' : 'Inativo'}}
                            </span>
                        </td>
                        <td style="width: 15%;">{{ new Date(cupom.expired).toLocaleDateString() }}</td>
                        <td style="width: 10%;">{{cupom.discount}}%</td>
                        <td style="width: 10%;">
                            <button class="btn-edit cupom-btn-edit" @click="editCupom(cupom)">
                                <fa icon="fa-file-alt"/>
                            </button>
                            <button class="btn-delete cupom-btn-delete" @click="deleteCupomFunction(cupom)">
                                <fa icon="fa-trash"/>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="pagination cupons-pagination">
            <button 
                :disabled="currentPage === 1" 
                @click="currentPage--"
                class="page-btn cupons-page-btn"
            >
                Anterior
            </button>
            <span class="page-info cupons-page-info">Página {{currentPage}} de {{totalPages}}</span>
            <button 
                :disabled="currentPage === totalPages" 
                @click="currentPage++"
                class="page-btn cupons-page-btn"
            >
                Próxima
            </button>
        </div>

        <!-- Dialog Novo Cupom -->
        <v-dialog v-model="newCupomDialog" max-width="800">
            <v-card class="dialog-card new-cupom-dialog">
                <v-card-title class="dialog-title">Criar Novo Cupom</v-card-title>
                <v-card-text class="dialog-content">
                    <v-text-field 
                        v-model="newCupom.title" 
                        label="Título do Cupom" 
                        required
                        class="dialog-input"
                    ></v-text-field>
                    <v-text-field 
                        v-model="newCupom.code"
                        label="Código do Cupom"
                        required
                        class="dialog-input"
                    ></v-text-field>
                    <v-text-field 
                        v-model="newCupom.discount"
                        label="Desconto (%)"
                        type="number"
                        required
                        class="dialog-input"
                    ></v-text-field>
                    <v-text-field
                        v-model="newCupom.expired"
                        label="Data de Vencimento"
                        type="date"
                        required
                        class="dialog-input"
                    ></v-text-field>
                </v-card-text>
                <v-card-actions class="dialog-actions">
                    <v-spacer></v-spacer>
                    <v-btn class="dialog-btn cancel" text @click="newCupomDialog = false">Cancelar</v-btn>
                    <v-btn class="dialog-btn confirm" text @click="createNewCupom" :disabled="!newCupom.title">Criar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Dialog Editar Cupom -->
        <v-dialog v-model="editCupomDialog" max-width="800">
            <v-card class="dialog-card edit-cupom-dialog">
                <v-card-title class="dialog-title">Editar Cupom</v-card-title>
                <v-card-text class="dialog-content">
                    <div class="status-switch">
                        <label>Status</label>
                        <v-switch
                            v-model="editingCupom.status"
                            :label="editingCupom.status === 1 ? 'Ativo' : 'Inativo'"
                            :true-value="1"
                            :false-value="0"
                            color="#2f2e8b"
                            class="dialog-switch"
                        ></v-switch>
                    </div>
                    <v-text-field 
                        v-model="editingCupom.title" 
                        label="Título do Cupom" 
                        required
                        class="dialog-input"
                    ></v-text-field>
                    <v-text-field 
                        v-model="editingCupom.code"
                        label="Código do Cupom"
                        required
                        class="dialog-input"
                    ></v-text-field>
                    <v-text-field 
                        v-model="editingCupom.discount"
                        label="Desconto (%)"
                        type="number"
                        required
                        class="dialog-input"
                    ></v-text-field>
                    <v-text-field
                        v-model="editingCupom.expired"
                        label="Data de Vencimento"
                        type="date"
                        required
                        class="dialog-input"
                    ></v-text-field>
                </v-card-text>
                <v-card-actions class="dialog-actions">
                    <v-spacer></v-spacer>
                    <v-btn class="dialog-btn cancel" text @click="editCupomDialog = false">Cancelar</v-btn>
                    <v-btn class="dialog-btn confirm" text @click="updateCupom(editingCupom.id)" :disabled="!editingCupom.title">Salvar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Dialog Deletar Cupom -->
        <v-dialog v-model="deleteDialog" max-width="400">
            <v-card class="dialog-card delete-cupom-dialog">
                <v-card-title class="dialog-title delete">Confirmar Exclusão</v-card-title>
                <v-card-text class="dialog-content">
                    Tem certeza que deseja excluir o cupom "{{cupomToDelete?.title}}"?
                </v-card-text>
                <v-card-actions class="dialog-actions">
                    <v-spacer></v-spacer>
                    <v-btn class="dialog-btn cancel" text @click="deleteDialog = false">Cancelar</v-btn>
                    <v-btn class="dialog-btn delete" color="error" text @click="confirmDeleteCupom()">Excluir</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <Loader :isActive="hasReady" />
    </div>
</template>

<script>

import Loader from '@/components/Loader/loader.vue';
import ApiCupom from '@/utils/cupom';

import { mapState } from 'vuex';

export default {
    data() {
        return {
            label: this.labels,
            cuponsFiltered: [],
            searchTerm: '',
            currentPage: 1,
            itemsPerPage: 5,
            alertOpenCupom:false,
            removedCupomName:'',
            afterWidth:40,
            cuponsData:[],
            openModalCreateCupom:false,
            openModalEditCupom:false,
            id:'',
            message:'',
            title:'',
            hasReady: false,
            status: '1',
            isMobileIcon: false,
            statusFilter: 'todos',
            searchText: '',
            newCupomDialog: false,
            editCupomDialog: false,
            deleteDialog: false,
            currentIdCupom: '',
            newCupom: {
                title: '',
                code: '',
                discount: '',
                expired: ''
            },
            editingCupom: {
                id: '',
                title: '',
                code: '',
                discount: '',
                expired: '',
                status: 1
            },
            deleteCupom: {
                id: '',
                title: '',
                code: '',
                discount: '',
                expired: '',
                status: 0
            },
            cupomToDelete: null
        };
    },
    mounted() {
        this.checkScreenSize();
        window.addEventListener('resize', this.checkScreenSize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.checkScreenSize);
    },
    computed:{
        ...mapState(["token","numberIntensTable"]),

        paginatedCupons() {
            const startIndex = (this.currentPage - 1) * this.numberIntensTable;
            const endIndex = startIndex + this.numberIntensTable;
            return this.filteredCupons.slice(startIndex, endIndex);
        },
        totalPages() {
            return Math.ceil(this.filteredCupons.length / this.numberIntensTable);
        },
        filteredCupons() {
            let filtered = this.cuponsData;

            if (this.searchText && this.searchText.trim() !== '') {
                filtered = filtered.filter((cupom) =>
                    cupom.title.toLowerCase().includes(this.searchText.toLowerCase()) ||
                    cupom.code.toLowerCase().includes(this.searchText.toLowerCase())
                );
            }
            if (this.statusFilter !== 'todos') {
                filtered = filtered.filter((cupom) =>
                    cupom.status && (cupom.status === (this.statusFilter === 'ativo' ? 1 : 0))
                );
            }

            return filtered;
        }
    },
    methods: {
        filtrarCupons() {
            this.currentPage = 1;
        },
        previousPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },
        editCupom(cupom) {
            this.currentIdCupom = cupom.id;
            this.editingCupom = {...cupom};
            this.editCupomDialog = true;
        },
        deleteCupomFunction(cupom) {
            this.deleteCupom = {...cupom};
            this.deleteDialog = true;
        },
        async createNewCupom() {
            const payload = {
                title: this.newCupom.title,
                code: this.newCupom.code,
                discount: this.newCupom.discount,
                expired: this.newCupom.expired,
                status: 1
            }
            try {
                const response = await ApiCupom.createNewCupom(this.token, payload);
                this.newCupomDialog = false;
                this.getCupomData();
            } catch(err) {
                console.error('Erro ao criar cupom:', err);
            }
        },
        async updateCupom(id) {
            const payload = {
                title: this.editingCupom.title,
                code: this.editingCupom.code,
                discount: this.editingCupom.discount,
                expired: this.editingCupom.expired,
                status: this.editingCupom.status
            }
            try {
                const response = await ApiCupom.editCupom(this.token, id, payload);
                this.editCupomDialog = false;
                this.getCupomData();
            } catch(err) {
                console.error('Erro ao atualizar cupom:', err);
            }
        },
        async confirmDeleteCupom() {
            const payload = {
                title: this.deleteCupom.title,
                code: this.deleteCupom.code,
                discount: this.deleteCupom.discount,
                expired: this.deleteCupom.expired,
                status: 0
            }
            try {
                const response = await ApiCupom.editCupom(this.token, this.deleteCupom.id, payload);
                this.deleteDialog = false;
                this.getCupomData();
            } catch(err) {
                console.error('Erro ao deletar cupom:', err);
            }
        },
        checkScreenSize() {
            this.isMobileIcon = window.innerWidth <= 600;
        },
        async getCupomData(){
            try{
                const response = await ApiCupom.getDataCupons(this.token);
                this.cuponsData = response.content;
                this.hasReady = true;
            }
            catch(err){
                this.hasReady = false;
                console.error('Erro ao carregar dados do Cupom')
            }
        },
    },
    components: {
        Loader
    },
    created(){
        this.getCupomData();
    },
    
};
</script>

<style scoped>
button.btn-edit.cupom-btn-edit:hover {
    background-color: #e4e3ff;
    transform: translateY(-2px);
}
button.btn-delete.cupom-btn-delete svg.svg-inline--fa.fa-trash {
    color: red;
}
button.btn-delete.cupom-btn-delete:hover {
    background-color: #fee2e2;
    transform: translateY(-2px);
}
.container {
    max-width: 100%;
    background-color: #ffffff;
    padding: 1.5rem;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}
.search-form-input{
    position: relative;
    width: 100%;
}
.title-container {
      position: relative;
    margin-bottom: 10px;
    padding-bottom: 0;
    border-bottom: 1px solid #f0f0f0;
    text-align: left;
}

.title-container .title {
    font-size: 1.5rem;
    font-weight: 700;
    color: #000000;
    margin-bottom: 0.5rem;
}

.title-container .subtitle {
    color: #6b7280;
    font-size: 0.875rem;
    line-height: 1.4;
    margin-bottom: 1rem;
}

.search-row {
    display: flex;
    gap: 0.75rem;
    margin-bottom: 1.5rem;
}

.search-row .form-group {
    flex: 1;
}

.btn-weflix.absolute {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #2f2e8b;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: 600;
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(47, 46, 139, 0.2);
}

.btn-weflix.absolute:hover {
    background-color: #23225f;
    transform: translateY(-2px);
}

.cupons-table-responsive {
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 1rem;
}

.cupons-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
}

.cupons-table th {
    background-color: #f8fafc;
    padding: 0.75rem;
    font-weight: 600;
    color: #4a5568;
    text-align: left;
    border-bottom: 2px solid #e2e8f0;
}

.cupons-table td {
    padding: 0.75rem;
    vertical-align: middle;
    border-bottom: 1px solid #f0f0f0;
    color: #4a5568;
}

.cupons-table tr:hover {
    background-color: #f8fafc;
}

.badge-active, .badge-inactive {
    padding: 0.25rem 0.75rem;
    border-radius: 5px;
    font-size: 0.75rem;
    font-weight: 500;
    display: inline-block;
    text-align: center;
    transition: all 0.2s ease;
    width: 70%;
}

.badge-active {
    background-color: #7bcc8180;
    color: #4b614e;
}

.badge-inactive {
  background-color: #d7d7ee80;
    color: #2f2e8b;
    }

.cupom-btn-edit, .cupom-btn-delete {
    padding: 0.4rem;
    margin: 0 0.25rem;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
}

.cupom-btn-edit {
    color: #2f2e8b;
}

.cupom-btn-delete {
    color: #dc3545;
}

.search-form-input {
    position: relative;
}

.search-form-control {
    width: 100%;
    padding: 0.5rem 0.75rem;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    font-size: 0.875rem;
    transition: all 0.2s ease;
}

.search-form-control:focus {
    outline: none;
    border-color: #2f2e8b;
    box-shadow: 0 0 0 3px rgba(47, 46, 139, 0.1);
}

.search-input-icon {
    position: absolute;
    right: 0.75rem;
    top: 45%;
    transform: translateY(-50%);
    color: #9ca3af;
    cursor: pointer;
}

.cupons-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
}

.cupons-page-btn {
    padding: 0.5rem 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    background: white;
    color: #4a5568;
    cursor: pointer;
    transition: all 0.2s;
}

.cupons-page-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.cupons-page-btn:not(:disabled):hover {
    background: #f8fafc;
    border-color: #cbd5e0;
}

.cupons-page-info {
    color: #4a5568;
    font-size: 0.875rem;
}

@media (max-width: 768px) {
    .container {
        padding: 1rem;
    }

    .title-container {
        margin-bottom: 1.5rem;
    }

    .search-row {
        flex-direction: column;
    }

    .cupons-table th, .cupons-table td {
        padding: 0.5rem;
    }
}

@media (max-width: 600px) {
    .btn-weflix.absolute {
        padding: 0.5rem;
    }
    
    .btn-weflix.absolute span {
        display: none;
    }

    .cupons-table-responsive {
        overflow-x: auto;
    }
}
.content.dashboard > .container {
    background-color: white;
    padding: 20px;
    padding-bottom: 35px;
    box-shadow: 0px 6px 10px 0px #dfdfdf;
    min-height: 89vh;
    padding-bottom: 0;
}
select.form-control.filter-form-control {
    width: 60%;
}
.form-group.filter-form-group {
    justify-content: space-around;
    align-items: center;
    margin: 0 !important;
}
.form-group.search-form-group {
    margin: 0 !important;
}
table.table.cupons-table {
    border: none !important;
    padding: 0 !important;
}
button.btn-edit.cupom-btn-edit svg {
    color: #2f2e8b;
}
.form-group.filter-form-group {
    display: flex;
}
.btn-weflix.absolute span.flex {
    font-size: 16px;
}
.btn-weflix.absolute svg {
    color: white;
    font-size: 15px;
}
</style>
<style scoped>
button.v-btn.v-btn--slim.v-theme--light.v-btn--density-default.v-btn--size-default.v-btn--variant-text.dialog-btn.confirm {
    background-color: #2f2e8b;
    color: white;
}
button.v-btn.v-btn--slim.v-theme--light.v-btn--density-default.v-btn--size-default.v-btn--variant-text.dialog-btn.confirm:hover {
    background-color: #23225f;
}
/* DARK */
.dashboard.dark .content.dashboard > .container.cupons-container.adminWeeducCupons {
    background-color: #000;
}
.dashboard.dark .container.cupons-container.adminWeeducCupons label,
.dashboard.dark .container.cupons-container.adminWeeducCupons .title-container .title,
.dashboard.dark .container.cupons-container.adminWeeducCupons .title-container .subtitle,
.dashboard.dark .container.cupons-container.adminWeeducCupons .btn-edit.cupom-btn-edit svg,
.dashboard.dark .container.cupons-container.adminWeeducCupons .cupons-page-info {
    color: white !important;
}
.dashboard.dark .container.cupons-container.adminWeeducCupons .form-group .form-input .form-control {
    background-color: #575757 !important;
    border: 1px solid #444;
}
.dashboard.dark .container.cupons-container.adminWeeducCupons .form-control {
    background-color: #575757;
    color: white;
    border: 1px solid #444;
}
.dashboard.dark .container.cupons-container.adminWeeducCupons .cupons-table th {
    border-bottom: 2px solid #575757;
}
.dashboard.dark .container.cupons-container.adminWeeducCupons .cupons-table td {
    border-bottom: 1px solid #575757;
}
.dashboard.dark .container.cupons-container.adminWeeducCupons .title-container {
    border-bottom: 1px solid #575757 !important;
}
.dashboard.dark .container.cupons-container.adminWeeducCupons .cupons-table-responsive {
    background-color: #000;
}
</style>