<template>
    <div class="login-container">
        <div class="form-side">
            <div class="login-box">
                <div class="logo">
                    <img src="@/assets/logo.png" alt="Logo Weeduc" />
                </div>
                <form @submit.prevent="handleSubmit">
                    <div class="form-group">
                        <label>Email</label>
                        <input 
                            type="email"
                            v-model="email"
                            placeholder="Digite seu email"
                            required
                        />
                    </div>
                    <!-- <div class="form-group">
                        <label>Nova Senha</label>
                        <div class="password-input">
                            <input 
                                :type="showPassword ? 'text' : 'password'"
                                v-model="password"
                                placeholder="Digite sua nova senha"
                                required
                            />
                            <span class="password-toggle" @click="togglePassword">
                                <fa :icon="showPassword ? 'eye-slash' : 'eye'" />
                            </span>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Confirmar Nova Senha</label>
                        <div class="password-input">
                            <input 
                                :type="showConfirmPassword ? 'text' : 'password'"
                                v-model="confirmPassword"
                                placeholder="Confirme sua nova senha"
                                required
                            />
                            <span class="password-toggle" @click="toggleConfirmPassword">
                                <fa :icon="showConfirmPassword ? 'eye-slash' : 'eye'" />
                            </span>
                        </div>
                    </div> -->
                    <div class="alert alert-success" v-if="successMessage">
                        {{ successMessage }}
                    </div>
                    <div class="links">
                        <a href="/portal">Voltar para o login</a>
                    </div>
                    <button type="submit" class="btn-login">Redefinir Senha</button>
                </form>
            </div>
        </div>
        <div class="banner-side">
            <div class="banner-content">
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loginApi from '@/utils/login.js';
import apiPassword from '@/utils/password.js';


export default {
    data() {
        return {
            email: '',
            password: '',
            confirmPassword: '',
            showPassword: false,
            showConfirmPassword: false,
            baseUrl: process.env.NODE_ENV === 'development' ? process.env.VUE_APP_BASE_URL_HOMOLOGATION : window.location.origin,
            successMessage: ''
        }
    },
    computed: {
        ...mapState(['typeSystem'])
    },
    methods: {
        togglePassword() {
            this.showPassword = !this.showPassword;
        },
        toggleConfirmPassword() {
            this.showConfirmPassword = !this.showConfirmPassword;
        },
        handleSubmit() {
            const aux = this.baseUrl.replace(/\/$/, '');
            const obj = {
                "url": aux.split('/resetpassworduser')[0],
                'email': this.email,
                'typeSystem': this.typeSystem
            }
            try {
                const response = apiPassword.resetPassowrdEmail(obj);
                this.successMessage = "Sucesso, se houver um email atrelado a solicitação de redefinição, confira seu email para mais informações.";

            } catch (error) {
                console.error('Erro ao redefinir senha:', error);
                this.successMessage = "Erro ao redefinir senha. Tente novamente.";
            }
        },
        async getLogo() {
            const aux = this.baseUrl.replace(/\/$/, '');
            const url = aux.split('/reset-password')[0];
            const obj = { url: url };

            try {
                const response = await loginApi.logo(obj);
                this.logo = 'https://image.weeduc.com/'+response.logo;
            } catch (error) {
                console.error('Error fetching API:', error);
            }
        }
    },
    mounted() {
        this.getLogo();
    }
}
</script>

<style scoped>
.login-container {
    display: flex;
    min-height: 100vh;
}

.banner-side {
    flex: 1;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    position: relative;
    background-image: url('@/assets/portal/Login-weeduc.png');
    background-color: none!important;
}

.banner-content {
    position: relative;
    text-align: center;
    padding: 1.25rem;
}

.form-side {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    padding: 2.5rem;
    width: 35%;
}

.login-box {
    width: 100%;
    max-width: 25rem;
    background: white;
    padding: 2.5rem;
    border-radius: 0.5rem;
    box-shadow: 0 0.125rem 0.625rem rgba(0,0,0,0.1);
}

.logo {
    text-align: center;
    margin-bottom: 1.875rem;
}

.logo img {
    width: 12.5rem;
}

.form-group {
    margin-bottom: 1.25rem;
}

label {
    display: block;
    margin-bottom: 0.5rem;
    color: #757e8a;
}

input {
    width: 100%;
    padding: 0.75rem;
    border: 0.0625rem solid #ddd;
    border-radius: 0.25rem;
    font-size: 0.875rem;
}

.password-input {
    position: relative;
    display: flex;
    align-items: center;
}

.password-toggle {
    position: absolute;
    right: 0.75rem;
    cursor: pointer;
    color: #757e8a;
}

.btn-login {
    width: 93%;
    padding: 0.75rem;
    background-color: #3db8bf;
    color: white;
    border: none;
    border-radius: 0.25rem;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
    margin: 10px;
}

.btn-login:hover {
    background-color: #555;
}

.links {
    margin-top: 10px;
    text-align: center;
    display: flex;
    justify-content: end;
    padding: 0px 13px;
}

.links a {
    color: #3db8bf;
    text-decoration: none;
    font-size: 12px;
}

.links a:hover {
    color: #333;
}

.alert {
    padding: 1rem;
    margin: 1rem 0;
    border-radius: 0.25rem;
    text-align: center;
}

.alert-success {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
    font-size: 12px;
}

@media (max-width: 48rem) {
    .login-container {
        flex-direction: column;
    }
    
    .banner-side {
        display: none;
    }
    
    .form-side {
        padding: 1.25rem;
        width: 100%;
    }
    
    .login-box {
        padding: 1.25rem;
    }
}
</style>
