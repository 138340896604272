<template>
  <div class="container forum-container adminWeeducForumView">
    <div v-if="loading" class="loader-container">
      <div class="loader"></div>
    </div>

    <v-dialog v-model="showConfirmModal" max-width="800">
      <v-card class="dialog-card new-cupom-dialog">
          <v-card-text class="dialog-content">
            <h4>Confirmar Fechamento</h4>
            <p>Tem certeza que deseja fechar este fórum?</p>
          </v-card-text>
          <v-card-actions class="dialog-actions">
              <v-spacer></v-spacer>
              <button class="btn-cancel" @click="showConfirmModal = false">Cancelar</button>
              <button class="btn-confirm" @click="confirmCloseForumStatus">Confirmar</button>
          </v-card-actions>
      </v-card>
    </v-dialog>

    <div v-if="!loading" class="forum-content">
      <div class="titleContainer myCoursesWeeduc">
        <div class="title-wrapper">
          <h3>{{ forum.title }}</h3>
          <p style="font-size: 14px;margin-bottom: 0;">{{ forum.description }}</p>
          <small class="comments-count">Comentários: {{ forum.commentsQty || 0 }} | </small> 
          <small class="author">Por: {{ forum.userName }} | </small> 
          <small class="date">Data: {{ new Date(forum.dateInsert).toLocaleDateString('pt-BR') }}</small>
        </div>
        <div class="status-wrapper">
          <span class="status" :class="{ 'not-answered': !forum.status, 'answered': forum.status }">
            {{ forum.status ? 'Aberto' : 'Fechado' }}
          </span>
          <button v-if="isAdmin" class="btn-lock" @click="showConfirmModal = true">
            <fa :icon="['fas', 'lock']" style="color: #260791" />
          </button>
        </div>
      </div>

      <!-- Filtro de usuários -->
      <div class="user-filter">
        <label 
          v-for="user in uniqueUsers" 
          :key="user"
          class="user-filter-label"
          :class="{ active: selectedUser === user }"
        >
          <input 
            type="radio" 
            :value="user" 
            v-model="selectedUser"
            @change="filterMessages"
            class="user-filter-input"
          >
          {{ user }}
        </label>
        <label class="user-filter-label" :class="{ active: selectedUser === '' }">
          <input 
            type="radio" 
            value="" 
            v-model="selectedUser"
            @change="filterMessages"
            class="user-filter-input"
          >
          Todos
        </label>
      </div>

      <div class="form-container">
        <div class="form-row">
          <div class="form-group col-12">
            <div class="chat-container">
              <div class="chat-messages" ref="chatMessages">
                <div v-if="filteredMessages.length">
                  <div 
                    v-for="message in filteredMessages" 
                    :key="message.id"
                    class="message"
                  >
                    <div class="message-header">
                      <div class="user-info">
                        <img :src="message.userFinalPhoto || '/default-avatar.png'" class="avatar" :alt="message.userName" />
                        <div class="user-details">
                          <span class="name">{{ message.userName }}</span>
                          <span class="date">{{ new Date(message.dateInsert).toLocaleDateString('pt-BR') }}</span>
                        </div>
                      </div>
                      <div class="message-actions">
                        <button v-if="isAdmin" class="btn-action delete" @click="deleteMessage(message.id)">
                          <i class="fas fa-trash"></i>
                        </button>
                      </div>
                    </div>
                    <div class="message-body">
                      <p v-html="message.comment"></p>
                    </div>
                  </div>
                </div>
                <div v-else class="no-messages">
                  <i class="far fa-comments"></i>
                  <p>Nenhuma mensagem encontrada</p>
                </div>
              </div>

              <div class="chat-input" v-if="forum.status">
                <textarea
                  v-model="newMessage"
                  placeholder="Digite sua mensagem..."
                  rows="3"
                  :disabled="loading"
                ></textarea>
                <button 
                  class="btn-weflix primary"
                  @click="sendMessage"
                  :disabled="loading || !newMessage.trim()"
                >
                  <fa icon="fas fa-paper-plane" />
                  {{ loading ? 'Enviando...' : 'Enviar' }}
                </button>
              </div>
              <div v-else class="chat-closed">
                <i class="fas fa-lock"></i>
                <p>Este fórum está fechado</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiForum from '@/utils/forum'
import { mapState } from 'vuex';

export default {
  data() {
    return {
      messages: [],
      filteredMessages: [],
      newMessage: '',
      loading: true,
      isAdmin: true,
      selectedUser: '',
      showConfirmModal: false
    }
  },
  computed: {
    ...mapState(['token','forum']),
    uniqueUsers() {
      return [...new Set(this.messages.map(message => message.userName))];
    }
  },
  methods: {
    async getMessages() {
      const id = this.$route.params.slug;
      try {
        const response = await ApiForum.getMessageById(this.token, id);
        this.messages = response.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        this.filterMessages();
      } catch (error) {
        console.error('Erro ao carregar mensagens:', error);
      } finally {
        this.loading = false;
      }
    },
    filterMessages() {
      if (this.selectedUser) {
        this.filteredMessages = this.messages.filter(message => message.userName === this.selectedUser);
      } else {
        this.filteredMessages = this.messages;
      }
    },
    async deleteMessage(id) {
      try {
        await ApiForum.deleteMessageById(this.token, id);
        await this.getMessages();
      } catch (error) {
        console.error('Erro ao deletar mensagem:', error);
      }
    },
    async sendMessage() {
      if (!this.newMessage.trim()) return;
      
      try {
        const payload = {
          comment: this.newMessage,
          idForum: this.$route.params.slug
        };
        
        await ApiForum.creteNewMessage(this.token, payload);
        this.newMessage = '';
        await this.getMessages();
        this.scrollToBottom();
      } catch (error) {
        console.error('Erro ao enviar mensagem:', error);
      }
    },
    scrollToBottom() {
      setTimeout(() => {
        const container = this.$refs.chatMessages;
        container.scrollTop = container.scrollHeight;
      }, 100);
    },
    async confirmCloseForumStatus() {
      await this.toggleForumStatus();
      this.showConfirmModal = false;
    },
    async toggleForumStatus() {
      const id = this.$route.params.slug;
      const payload = {
        "status": 0,
        "idUser": this.forum.idUser,
        "userName": this.forum.userName,
        "dateInsert": this.forum.dateInsert,
        "description": this.forum.description,
        "title": this.forum.title,
        "plainDescription": this.forum.plainDescription,
        "commentsQty": this.forum.commentsQty
      }
      try {
        await ApiForum.editForumById(this.token, id, payload);
        this.$router.push('/dashboard/forum');
      } catch (error) {
        console.error('Erro ao atualizar status do fórum:', error);
      }
    }
  },
  created() {
    this.getMessages();
  }
}
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  text-align: center;
}

.modal-content h4 {
  margin-bottom: 1rem;
  color: #2f2e8b;
}

.modal-actions {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
}

.btn-cancel, .btn-confirm {
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.2s;
}

.btn-cancel {
  background: #e5e7eb;
  color: #4b5563;
}

.btn-confirm {
  background: #dc2626;
  color: white;
}

.btn-cancel:hover {
  background: #d1d5db;
}

.btn-confirm:hover {
  background: #b91c1c;
}

.user-filter {
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  align-items: flex-start;
  padding: 0.5rem;
}

.user-filter-label {
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  background-color: #f3f4f6;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 2px solid transparent;
  font-size: 0.875rem;
}

.user-filter-label:hover {
  background-color: #e5e7eb;
}

.user-filter-label.active {
  background-color: #2f2e8b;
  color: white;
  border-color: #2f2e8b;
}

.user-filter-input {
  display: none;
}

svg.svg-inline--fa.fa-paper-plane {
  color: white;
}

.form-group.col-12 {
  margin: 0 !important;
}

.container {
  max-width: 100%;
  height: calc(100vh - 80px);
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.forum-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.form-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.form-row {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.chat-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
}

.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #2f2e8b;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.titleContainer {
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid #f0f0f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.status-wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.status {
  padding: 0.625rem 1.25rem;
  border-radius: 50px;
  font-weight: 600;
}

.status.answered {
  background-color: #7bcc8180;
  color: #4b614e;
}

.status.not-answered {
  background-color: #ffd700;
  color: #8b4513;
}

.chat-container {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.08);
  overflow: hidden;
}

.chat-messages {
  overflow-y: auto;
  padding: 2rem;
  min-height: 0;
  max-height: 560px;
}

.message {
  margin-bottom: 2rem;
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

.message-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem;
}

.user-info {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.user-details {
  display: flex;
  flex-direction: column;
}

.date {
  font-size: 0.875rem;
  color: #6b7280;
}

.avatar {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #e5e7eb;
}

.name {
  font-weight: 600;
  color: #1f2937;
}

.message-body {
  padding: 1.25rem;
  background: #f3f4f6;
  border-radius: 12px;
  color: #1f2937;
}

.chat-input {
  padding: 1.5rem;
  border-top: 2px solid #e5e7eb;
  background: #f9fafb;
  display: flex;
  gap: 1rem;
  align-items: flex-end;
}

textarea {
  width: 100%;
  padding: 1rem;
  border: 2px solid #e2e8f0;
  border-radius: 8px;
  resize: vertical;
  min-height: 100px;
}

.btn-weflix.primary {
  background: #2f2e8b;
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.btn-action {
  background: none;
  border: none;
  cursor: pointer;
  color: #dc2626;
}

.no-messages,
.chat-closed {
  text-align: center;
  padding: 4rem 2rem;
  color: #6b7280;
}

.no-messages i,
.chat-closed i {
  font-size: 3.5rem;
  margin-bottom: 1.5rem;
  opacity: 0.6;
}

@media (max-width: 768px) {
  .container {
    padding: 1rem;
  }
  
  .chat-messages {
    padding: 1rem;
  }
  
  .chat-input {
    flex-direction: column;
  }
  
  .btn-weflix.primary {
    width: 100%;
    justify-content: center;
  }
}
/* DARK */
.dashboard.dark .content.dashboard > .container.forum-container.adminWeeducForumView {
    background-color: #000;
}
.dashboard.dark .container.forum-container.adminWeeducForumView .title-wrapper h3, 
.dashboard.dark .container.forum-container.adminWeeducForumView .title-wrapper p, 
.dashboard.dark .container.forum-container.adminWeeducForumView .title-wrapper small,
.dashboard.dark .container.forum-container.adminWeeducForumView p {
    color: white !important;
}
.dashboard.dark .container.forum-container.adminWeeducForumView .chat-messages {
    background-color: #575757;
}
.dashboard.dark .container.forum-container.adminWeeducForumView .user-info .name, 
.dashboard.dark .container.forum-container.adminWeeducForumView .user-info .date,
.dashboard.dark .container.forum-container.adminWeeducForumView .btn-lock svg {
    color: white !important;
}
.dashboard.dark .container.forum-container.adminWeeducForumView .message-body {
  background-color: #444;
  color: white;
}
.dashboard.dark .container.forum-container.adminWeeducForumView .status.answered {
    color: white;
}
.dashboard.dark .container.forum-container.adminWeeducForumView .chat-input {
  background-color: #575757;
}
.dashboard.dark .container.forum-container.adminWeeducForumView .chat-input textarea {
  background-color: #444 !important;
  color: white !important;
}

/* Dark mode para o modal */
.dashboard.dark .modal-content {
  background-color: #333;
  color: white;
}

.dashboard.dark .modal-content h4 {
  color: #fff;
}

.dashboard.dark .btn-cancel {
  background: #4a4a4a;
  color: #fff;
}

.dashboard.dark .btn-cancel:hover {
  background: #5a5a5a;
}
</style>
