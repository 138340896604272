<template>
    <div class="bg" v-show="showEditInfo==true"></div>
    <div class="form-create-module newInquiry" :class="[showEditInfo ? 'open' : '', this.dark ? 'dark' : '']">
      <div class="modal" tabindex="-1">
        <div class="modal-dialog">
            <div class="title">
                Novo Fórum
                <i class="pi pi-times" @click="closetab()"></i>
            </div>
            <div class="form-group">
                <div class="form-label">
                  <label class="title_label"> Título <v-tooltip text="Campo obrigatório" location="top">
                    <template v-slot:activator="{ props }">
                      <i v-bind="props" class="pi pi-exclamation-circle"></i>
                    </template>
                  </v-tooltip></label>
                </div>
                <div class="form-input">
                  <input
                    v-model="title"
                    type="text"
                    class="form-control"
                    placeholder="Seu nome"
                  />
                </div>
              </div>

              <div class="form-group">
                <div class="form-label">
                  <label class="title_label">Descreva os detalhes da sua dúvida<v-tooltip text="Campo obrigatório" location="top">
                    <template v-slot:activator="{ props }">
                      <i v-bind="props" class="pi pi-exclamation-circle"></i>
                    </template>
                  </v-tooltip></label>
                </div>
                <div class="form-input">
                    <QuillEditor theme="snow" toolbar="essential" 
                       
                        v-model:content="description"
                        contentType="html"/>
                </div>
                <div class="action">
                    <button @click="newForum">Enviar</button>
                </div>
              </div>
        </div>
      </div>
    </div>
    <v-dialog
    v-model="dialog"
    width="auto"
  >
    <v-card
      max-width="400"
      prepend-icon="mdi-update"
      text="Um ou mais campo não foram preenchidos corretamenta, por favore revise-os."
      title="Ops, há algum erro!"
    >
      <template v-slot:actions>
        <v-btn
          class="ms-auto"
          text="Ok"
          @click="dialog = false"
        ></v-btn>
      </template>
    </v-card>
  </v-dialog>
  
  </template>
  <script>
  import { mapState } from "vuex";
  import Loader from "@/components/Loader/loader.vue"
  import api from "@/utils/portal/forum"
  
  export default {
  data(){
    return{
        showEditInfo: false,
        title:null,
        description:null,
    }
  },
  props: {
    showNewForum: Boolean,
  },
  computed:{
        ...mapState(['token']),
        ...mapState(['dark']),
    },
  methods: {
    newForum(){
      const obj = {
        "title": this.title,
        "description": this.description,
        "status": 1
      }
      try {
        const response =  api.newForum(this.token, obj);
        this.alterarValorEmit();
      } catch (error) {
          console.error(error)
      }
    },
    closetab() {
      this.alterarValorEmit();
    },
    alterarValorEmit() {
      this.$emit("handleOpenModalForum", false);
    },
  },
  components:{
    Loader
  },
  created(){
},
  watch: {
    showNewForum(novoValor) {
      this.showEditInfo = this.showNewForum;
    },
    }
  };
  </script>
  <style scoped>
  .title i{
    color: gray;
    cursor: pointer;
}
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
    .modal-footer {
         border: none;
    }
    .edit_user label {
        justify-content: flex-start !important;
        gap: 5px;
    }
    .modal-body.double {
        display: flex;
    }
    .modal-body.double .form-group {
        width: 100%;
    }
    .modal-body.double.less .form-group:first-child {
        width: 300%;
    }
    .form-group.nao_sei_cep {
        align-items: end;
        display: flex;
    }
    .form-input.cep input {
        padding: 4px 10px !important;
    }
    .form-create-module.edit_user.open {
        box-shadow: -1px -8px 13px #e5e5e5;
        height: 100%;
    }
    .form-create-module.newInquiry.open {
        height: 450px;
        right: 50%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 1px solid #d4d4d4;
        border-radius: 10px;
        background-color: white;
        padding: 20px;
    }
    .form-create-module.newInquiry.open .form-group {
        margin: 10px 0px !important;
        padding: 0;
    }
    .bg {
        background-color: #d0d0d0a6;
        width: 100%;
        height: 86%;
        position: absolute;
        top: 7%;
    }
    .modal-dialog {
        pointer-events:all;
    }
    .action {
        justify-content: center;
    }
    .action button {
        background-color: #19c7d0;
        padding: 5px 30px;
        border-radius: 5px;
        color: white;
        margin-top: 10px;
    }
    .form-create-module.newInquiry.open label.title_label {
        justify-content: left;
        gap: 10px;
    }
    .form-create-module.newInquiry.open.dark {
      background-color: #272727;
      border: 1px solid #000000;
  }
  .form-create-module.newInquiry.open.dark .modal-dialog  div {
    color: white;
}
.form-create-module.newInquiry.open.dark .modal-dialog i {
  color: white;
}
.form-create-module.newInquiry.open.dark label.title_label{
  color: white;
}
.form-create-module.newInquiry.open.dark input.form-control {
  background-color: #272727 !important;
  color: white !important;
  border-color: white !important;
}
.form-create-module.newInquiry.open.dark input.form-control::placeholder {
  color: white !important;
}
  </style>

  <style>
  .form-create-module.newInquiry.open.dark .ql-editor.ql-blank {
    background-color: #272727 !important;
}
  .form-create-module.newInquiry.open.dark .ql-snow .ql-stroke{
    stroke: #ffffff;
  }
  .form-create-module.newInquiry.open.dark span.ql-picker-label {
    color: #cecece;
}
.form-create-module.newInquiry.open.dark .ql-editor {
  color: #ffffff;
}
  </style>
  