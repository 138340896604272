import api from '../api';

export default {
    async getDataForum(accessToken) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/portal/forum`, {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao carregar os foruns:', error);
                throw error;
        }
    },
    async getDataForumById(accessToken, id) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/portal/comment/${id}`,  {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao carregar os comentários:', error);
                throw error;
        }
    },
    async newComment(accessToken, payload) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.post(`/portal/comment`, payload, {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao carregar os comentários:', error);
                throw error;
        }
    },
    async newForum(accessToken, payload) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.post(`/portal/forum`, payload, {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao criar forum:', error);
                throw error;
        }
    },

}

