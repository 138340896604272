import api from './api';

export default {
    async getDataForum(accessToken) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get('/forum', {
                method: 'GET',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados do forum:', error);
            throw error;
        }
    },
    
    async createNewForum(forumrData, accessToken){
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.post('/forum', forumrData, {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados do forum:', error);
            throw error;
        }
    },

    async creteNewMessage(accessToken, payload){
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.post('/comment', payload, {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao criar mensagem:', error);
            throw error;
        }
    },

    async getMessageById(accessToken, id){
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/comment/forum/${id}`, {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao carreagr mensagem:', error);
            throw error;
        }
    },
    async deleteMessageById(accessToken, id){
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.delete(`/comment/${id}`, {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao deletar mensagem:', error);
            throw error;
        }
    },
    async editForumById(accessToken, id, payload){
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.put(`/forum/${id}`, payload, {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao editar fórum:', error);
            throw error;
        }
    }
}
