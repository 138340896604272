<template>
    <section class="globalPlayer themeLight">
        <Header @toggleModal="toggleOpenDialog" @toggleModalSubscribe="toggleOpenSubscribe"/>
        
        <div class="content-wrapper">
            <!-- Botão toggle sidebar mobile -->
            <button v-if="!sidebarOpen" class="toggle-sidebar open" @click="toggleSidebar">
                <fa icon="bars"/>
            </button>
            <button v-else class="toggle-sidebar close" @click="toggleSidebar">
                <fa icon="times"/>
            </button>

            <!-- Sidebar com lista de vídeos -->
            <aside class="sidebar" :class="{ 'sidebar-open': sidebarOpen }">
                <div class="sidebar-header">
                    <h3>Conteúdo do Curso</h3>
                </div>
                <div class="video-list">
                    <div class="itensColletions">
                        <div v-for="video in relatedVideos" :key="video.id" class="item" @click="goToPlayer(video.id)">
                            <div v-if="video.id === $route.params.slug" class="play-icon">
                                <fa icon="play" fill="color: #4CAF50"/>
                            </div>
                            <img v-else :src="video.finalVideoThumbnail" alt="Thumbnail do vídeo">
                            <div class="video-info">
                                <h4>{{ video.title }}</h4>
                                <span class="descriptionVideoSidebar">{{ truncateDescription(video.description) }}</span>
                                <span class="duration">{{ Math.floor(video.videoTime / 60) }}:{{ (video.videoTime % 60).toString().padStart(2, '0') }}min</span>
                            </div>
                            <div class="descriptionVideoContentRight" v-if="!userPortalWeflix">
                                <fa icon="lock"/>
                            </div>
                        </div>
                    </div>
                </div>
            </aside>

            <!-- Área principal com player e detalhes -->
            <main class="main-content" :class="{ 'content-shifted': sidebarOpen }">
                <button class="btn-voltar" @click="$router.push(`/colecoes-videos/themeLight/${idCollection}`)">
                    <fa icon="fa-arrow-left"/> Voltar
                </button>
                
                <section class="player">                    
                    <iframe 
                        v-if="hasReady"
                        :src="videoPlayer"
                        width="100%"    
                        :style="{ height: heightPlayer + 'px' }"
                        frameborder="0" 
                        allow="autoplay; fullscreen" 
                        allowfullscreen
                        style="overflow: hidden">
                    </iframe>
                    <Loader :hasReady="hasReady"/>
                </section>

                <section class="video-info">
                    <div class="title">
                        <h1>{{infoVideo.title}}</h1>
                    </div>
                </section>

                <button class="btn-weflix-theme2" v-if="userPortalWeflix && userPortalWeflix.user && userPortalWeflix.user.status != 1" @click="$router.push('/')">Assine a partir de R$ {{ this.plansWeflix[0]? this.plansWeflix[0].planValue.toLocaleString('pt-BR', {minimumFractionDigits: 2}) : '0,00' }} / mês</button>
                
            </main>
        </div>

        <Footer/>
    </section>
</template>

<script>
import Header from '@/components/Weflix/LigthTheme/Header.vue';
import Footer from '@/components/Weflix/LigthTheme/Footer.vue';
import VideoCollections from '@/components/Weflix/CollectionsPlayer.vue'
import FileAttchments from '@/components/Weflix/AttachmentFiles.vue'
import playerVideo from '@/utils/storage/playerVideo'
import { mapState } from 'vuex'
import Loader from '@/components/Weflix/Loader'
import ApiWebsite from '@/utils/Weflix/Website/index'

export default {
    data(){
        return{
            openDialogLogin:false,
            openDialogSubscribe:true,
            showMoreVIdeo:true,
            videoPlayer:`https: //weeduc.videotecaead.com.br/Embed/iframe/?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZGVudGlmaWVyIjoiYzZjMGVkMzE1MzdiYzNlYTM2ODc1Y2RjZWNhYjcxOTciLCJzYWZldHlwbGF5ZXIiOiIiLCJzYWZldHkiOiIiLCJtYXRyaWN1bGEiOiJudWxsIiwiYWx1bm9faWQiOiJudWxsIiwibm9tZSI6Im51bGwiLCJhbHVub19ub21lIjoibnVsbCIsImVtYWlsIjoibnVsbCIsImFsdW5vX2VtYWlsIjoibnVsbCIsImlhdCI6MTczMzMxNTUyNSwibmJmIjoxNzMzMzE1NTI1LCJleHAiOjE3MzMzMTU4MjV9.CX6qG5rWkz79O779ZKHNuZvXh5VQVVJIXJwTaiO8E6Q`,
            heightPlayer:400,
            hasReady: true,
            infoVideo:{},
            relatedVideos:[],
            sidebarOpen: false,
            idCollection:null,
           baseUrl: process.env.NODE_ENV === 'development' ? process.env.VUE_APP_BASE_URL_HOMOLOGATION  : window.location.origin,

        }
    },
    components:{
        Header,
        Footer,
        VideoCollections,
        FileAttchments,
        Loader
    },
    computed:{
        ...mapState(['userPortalWeflix', 'finalWebsite', 'plansWeflix'])
    },
    watch: {
        '$route.params.slug': {
            handler(newSlug) {
                this.hasReady = false;
                this.getPlayerVideo();
                this.getInfoVideo();
                if(window.innerWidth <= 768) {
                    this.sidebarOpen = false;
                }
            }
        }
    },
    methods:{
        toggleSidebar() {
            this.sidebarOpen = !this.sidebarOpen;
        },
        truncateDescription(text) {
            if (!text) return '';
            return text.length > 50 ? text.substring(0, 50) + '...' : text;
        },
        toggleVideosAnexos(){
            this.showMoreVIdeo = !this.showMoreVIdeo;
        },
        verifyHeight(){
            const width = window.innerWidth;
            if(width < 450){
                this.heightPlayer = 200;
            } else {
                this.heightPlayer = 400;
            }
        },
        async getPlayerVideo(){
            const id = this.$route.params.slug;
            try {
                const response = await playerVideo.getPlayerVideo(this.userPortalWeflix.acessToken,id);
                this.videoPlayer = response;
                this.hasReady = true;
            } catch (error) {
                console.error('Erro ao cobter o player:', error);
            }
        },
        async getInfoVideo(){
            const id = this.$route.params.slug;
            const aux = window.location.href ? window.location.href.replace(/\/$/, '') : this.baseUrl;
          
            const payload = {
                "url": aux
            }
            try {
                const response = await ApiWebsite.getInfoVideo(payload,id);
                this.getVideosByCollections(response.idCourse);
                this.infoVideo = response;
            } catch (error) {
                console.error('Erro ao obter dados do video:', error);
            }
        },
        async getVideosByCollections(id){
            const aux = this.baseUrl.split('/player/')[0].replace(/\/$/, '');
            
            const payload = {
                "url": aux
            }
            try {
                const response = await ApiWebsite.getVideosByCollection(id, payload, 0);
                this.idCollection = id;
                this.relatedVideos = response.lessons.sort((a, b) => a.order - b.order);
                console.debug(this.relatedVideos);
            } catch (error) {
                console.error('Erro ao obter dados dos videos:', error);
            }
        },
        stripTags(text) {
            if (!text) return '';
            return text.replace(/<[^>]*>?/g, '');
        },
        goToPlayer(id){
            this.$router.push('/player-curso/'+id);
        }
    },
    mounted(){
        this.verifyHeight(); 
        this.getPlayerVideo();
        this.getInfoVideo();
        window.addEventListener('resize', this.verifyHeight);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.verifyHeight);
    }
}
</script>

<style scoped>
.item:nth-child(2n) {
    flex-direction: row;
}
.globalPlayer {
    background-color: #fff;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.content-wrapper {
    display: flex;
    max-width: 100%;
    margin-top: 65px;
    flex: 1;
    position: relative;
}

.toggle-sidebar {
    display: none;
    position: fixed;
    top: 80px;
    left: 20px;
    z-index: 1000;
    background: v-bind("finalWebsiteLocal?.color");
    border: none;
    color: white;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.sidebar {
    width: 400px;
    background: rgba(223, 227, 235, 0.6);
    height: 82vh;
    position: relative;
    overflow-y: auto;
    box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    top: -60px;
    z-index: 99;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-bottom: -62px;
}

.sidebar::-webkit-scrollbar {
    width: 8px;
}

.sidebar::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.sidebar::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.sidebar::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.sidebar-header {
    padding: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    background: #fff;
    position: sticky;
    top: 0;
    z-index: 1;
}

.sidebar-header h3 {
    color: #333;
    margin: 0;
    font-size: 1.4rem;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.video-list {
    padding: 15px;
}

.main-content {
    flex: 1;
    margin-left: 0px;
    padding: 30px;
    position: relative;
    transition: all 0.3s ease;
}

.player {
    position: relative;
    margin-top: 55px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    max-height: 400px;
}

.video-info {
    padding: 25px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.title h1 {
    font-size: 32px;
    font-weight: 700;
    color: #333;
    margin-bottom: 20px;
    line-height: 1.3;
}

.title p {
    color: #666;
    font-size: 18px;
    line-height: 1.8;
    margin-bottom: 20px;
}

.btn-voltar {
    position: absolute;
    top: 20px;
    left: auto;
    right: 15px;
    background: #fff;
    border: 1px solid #ddd;
    color: #333;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 25px;
    transition: all 0.3s ease;
    z-index: 1;
}

.btn-voltar:hover {
    background: #f5f5f5;
    transform: translateX(-5px);
}

.subscription {
    margin-top: 40px;
    padding: 40px;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.subscription h1 {
    color: #333;
    font-size: 28px;
    margin-bottom: 25px;
    line-height: 1.4;
}

.btnHedaer {
    color: #fff;
    background: linear-gradient(135deg, #e31d1a 0%, #ff4b47 100%);
    border: none;
    padding: 14px 28px;
    border-radius: 6px;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.btnHedaer:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(227, 29, 26, 0.4);
}

.themeLight .main-content button.btn-voltar {
    color: rgb(125, 125, 125) !important;
}
.themeLight .main-content button.btn-voltar svg {
    color: rgb(125, 125, 125) !important;
}
@media (max-width: 1024px) {
    .sidebar {
        width: 350px;
    }
    
    .main-content {
        margin-left: 350px;
        padding: 20px;
    }
}

@media (max-width: 768px) {
    .toggle-sidebar {
        display: block;
    }

    .content-wrapper {
        flex-direction: column;
    }
    
    .sidebar {
        width: 100%;
        max-width: 80%;
        height: 100vh;
        position: fixed;
        left: -100%;
        top: 0;
        transition: 0.3s ease-in-out;
        background-color: rgb(223 227 235);
        z-index: 99;

    }

    .sidebar-open {
        left: 0;
    }
    
    .main-content {
        margin-left: 0;
        padding: 15px;
        width: 100%;
    }

    .content-shifted {
        margin-left: 0;
    }
    
    .title h1 {
        font-size: 24px;
    }
    
    .subscription {
        padding: 25px;
        margin-top: 30px;
    }
    
    .subscription h1 {
        font-size: 22px;
    }
    
    .btnHedaer {
        width: 100%;
        padding: 12px 20px;
    }
}
@media (max-width: 600px) {
    span.duration {
        bottom: 0px !important;
    }
}

@media (max-width: 480px) {
    .sidebar-header h3 {
        font-size: 1.2rem;
    }
    
    .title h1 {
        font-size: 22px;
    }
    
    .title p {
        font-size: 16px;
    }
    
    .btn-voltar {
        font-size: 14px;
        padding: 8px 16px;
    }
}

button.btn-weflix-theme2 {
    background-color: v-bind("finalWebsite?.color")!important;
    font-size: 15px;
    font-weight: bold;
    display: inline-block;
    line-height: 24px;
    color: rgb(255, 255, 255);
    border: none;
    border-radius: 4px;
    padding: 8px 7.5px;
    margin-top: 22px;
    margin: 10px 0px;
    font-size: 18px;
    font-weight: bold;
    line-height: 28px;
    padding: 10px 20px;
}

.themeLight .itensColletions {
    display: block;
    margin-bottom: -25px;
}

.themeLight .item {
    display: flex;
    position: relative;
    border: none;
    width: 100%;
    text-align: left;
    padding: 0;
    cursor: pointer;
    justify-content: unset;
    margin: 10px 0px;
    border-bottom: 1px solid #dcdcdc;
    padding-bottom: 10px;
}

.themeLight .item img {
    width: 50px;
    height: 50px;
    padding: 0px;
    border-radius: 50%;
}

.themeLight .itensColletions  .video-info {
   padding: 0;
   border: none;
   width: 80%;
}

.themeLight  .itensColletions .video-info h4 {
    font-size: 14px;
}

span.duration {
    font-size: 10px;
    position: absolute;
    right: 0;
    bottom: 5px;
}

span.descriptionVideoSidebar {
    font-size: 10px;
}

.play-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #4CAF50 !important;
}

.themeLight .item svg.svg-inline--fa.fa-play path {
    fill: #4CAF50!important;
}
button.toggle-sidebar.close svg.svg-inline--fa.fa-xmark {
    color: #dfe3eb;
}
button.toggle-sidebar.close {
    top: 13px;
    left: 66%;
}
.play-icon path {
    fill: #4CAF50 !important;
}
.item .play-icon svg path {
    fill: #4CAF50 !important;
}
</style>
