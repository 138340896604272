<template>
    <div class="container doubts-container adminWeeducCreateQuiz">
        <div class="titleContainer myCoursesWeeduc">
            <h3>Nova Atividade Questionário</h3>
            <p style="font-size: 14px;margin-bottom: 0;">Crie as informações básicas de sua atividade questionário.</p>
        </div>

        <div class="form-container" v-if="!isSaving">
            <div class="form-row">
                <div class="form-group col-5">
                    <p class="form-label-title">Título do Atividade *</p>
                    <div class="form-input">
                        <input 
                            type="text"
                            class="form-control"
                            v-model="lessonDatatitle"
                            placeholder="Digite um título para o questionário"
                            required
                        />
                    </div>
                </div>

                <div class="form-group col-2"> 
                    <p class="form-label-title">Nota máxima *</p>
                    <div class="form-input">
                        <input 
                            type="number"
                            class="form-control"
                            v-model="quizData.maxGrade"
                            placeholder="Ex: 10"
                            min="0"
                            max="100"
                            required
                        />
                    </div>
                </div>

                <div class="form-group col-2">
                    <p class="form-label-title">Exige nota mínima?</p>
                    <div class="form-input">
                        <label class="switch">
                            <input type="checkbox" v-model="quizData.requiresMinGrade" >
                            <span class="slider round"></span>
                        </label>
                    </div>
                </div>

                <div class="form-group col-2"> 
                    <p class="form-label-title">Nota mínima *</p>
                    <div class="form-input">
                        <input 
                            type="number"
                            class="form-control"
                            v-model="quizData.minGrade"
                            placeholder="Ex: 10"
                            min="0"
                            max="100"
                            required
                        />
                    </div>
                </div>
            </div>

            <div class="form-group col-12 new-quiz-options">
                <p class="form-label-title">Como deseja criar?</p>
                <div class="form-input import-create-quiz">
                    <div class="radio-options">
                        <div class="radio-option-card" v-if="quizData.source != 1">
                            <input 
                                type="radio" 
                                id="createNew" 
                                value="0" 
                                name="questionSource"
                                v-model="quizData.source"
                                :disabled="showImportedQuestions"
                            />
                            <label for="createNew">
                                <i class="pi pi-plus-circle"></i>
                                <span>Criar novo questionário</span>
                            </label>
                        </div>
                        <div class="radio-option-card" v-if="quizData.source != 0">
                            <input 
                                type="radio" 
                                id="import" 
                                value="1" 
                                name="questionSource"
                                v-model="quizData.source"
                                :disabled="showImportedQuestions"
                            />
                            <label for="import">
                                <i class="pi pi-download"></i>
                                <span>Importar questionário</span>
                            </label>
                        </div>
                    </div>
                    <div class="config-row" v-if="quizData.source == 0">
                        <div class="config-item">
                            <div class="form-group" style="text-align: center;">
                                <label class="label-form">Quantidade de Questões</label>
                                <div class="quantity-control">
                                    <button @click="decrementQuestions" class="quantity-btn" :disabled="qtdQuestions <= 1 || showImportedQuestions">-</button>
                                    <span class="quantity-display">{{ qtdQuestions }}</span>
                                    <button @click="incrementQuestions" class="quantity-btn" :disabled="qtdQuestions >= 10 || showImportedQuestions">+</button>
                                </div>
                            </div>
                        </div>
                        <div class="config-item">
                            <div class="form-group" style="text-align: center;">
                                <label class="label-form">Quantidade de Respostas</label>
                                <div class="quantity-control">
                                    <button @click="decrementAnswers" class="quantity-btn" :disabled="qtyAnswer <= 2 || showImportedQuestions">-</button>
                                    <span class="quantity-display">{{ qtyAnswer }}</span>
                                    <button @click="incrementAnswers" class="quantity-btn" :disabled="qtyAnswer >= 5 || showImportedQuestions">+</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="import-section" v-if="quizData.source == 1">
                        <div class="form-input import-create-quiz import">
                            <div class="form-group">
                                <label class="label-form">Selecione o questionário</label>
                                <select class="form-control" v-model="selectedQuiz" :disabled="showImportedQuestions" @change="getQuestions">
                                    <option value="">Selecione um questionário</option>
                                    <option v-for="quiz in allQuiz" :value="quiz.id" :key="quiz.id">{{quiz.title}}</option>
                                </select>
                            </div>
                            <div class="form-group" style="display: flex; align-items: flex-end;gap: 10px;position: relative;">
                                <Loader v-if="isReadyLoadQuiz" />
                                <button v-if="!showPreview && !showImportedQuestions && showButtonOptions" class="btn-weflix primary" @click="previewQuiz">Ver Questionário</button>
                                <button v-if="!showPreview && !showImportedQuestions && showButtonOptions" class="btn-weflix primary" @click="applySelectedQuiz">Editar Questionário</button>
                                <button v-if="showPreview" class="btn-weflix primary" @click="showPreview = false">Voltar</button>
                                <button v-if="showImportedQuestions" class="btn-weflix primary" @click="showImportedQuestions = false">Cancelar Edição</button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group col-12 new-quiz-options" v-if="quizData.source == 0">
                <p class="form-label-title">Título do questionário *</p>
                <div class="form-input">
                    <input 
                        type="text"
                        class="form-control"
                        v-model="quizData.title"
                        placeholder="Digite um título para o questionário"
                        required
                        :disabled="showImportedQuestions"
                    />
                </div>
            </div> 
        

            <div class="questions-section" v-if="quizData.source == 0 && !showImportedQuestions">
                <draggable 
                    v-model="questions"
                    class="list-group"
                    ghost-class="ghost"
                    :move="checkMove"
                    @start="dragging = true"
                    @end="onDragEnd"
                >
                    <template #item="{index}">
                        <div class="question-card">
                            <div class="question-header" @click="toggleQuestion(index+1)">
                                <div class="question-title-row">
                                    <h4 class="question-title">Questão {{index+1}}</h4>
                                    <div class="question-actions">
                                        <i class="pi pi-bars handle"></i>
                                        <span class="toggle-icon">
                                            <i v-if="isQuestionExpanded(index+1)" class="pi pi-chevron-down"></i>
                                            <i v-else class="pi pi-chevron-right"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div v-show="isQuestionExpanded(index+1)" class="question-content">
                                <div class="question-label">Enunciado da questão:</div>
                                <div class="quileditor question-editor">
                                    <QuillEditor
                                        v-model:content="contentQuestions[index]"
                                        @input="updateContent(index, $event.srcElement.innerHTML)"
                                        theme="snow"
                                        toolbar="essential"
                                        contentType="html"
                                    />
                                </div>
                                
                                <div class="answers-container">
                                    <div class="tabs-answer">
                                        <div 
                                            class="tab" 
                                            :class="{'active-tab': active[index+1][j]}" 
                                            v-for="j in qtyAnswer" 
                                            :key="j"
                                            @click="changeValueTab(index+1,j)"
                                        >
                                            <span>{{alternatives[j-1]}} )</span>
                                        </div>
                                    </div>
                                    <div class="answer-content" v-for="k in qtyAnswer" :key="k" v-show="active[index+1][k]">
                                        <div class="answer-editor-container">
                                            <div class="answer-editor">
                                                <textarea
                                                    v-model="contentAnswers[index,k]"
                                                    @input="updateContentAnswer(index, k-1, $event.target.value)"
                                                    class="form-control"
                                                    rows="4"
                                                ></textarea>
                                            </div>
                                            <div class="correct-answer-toggle">
                                                <label class="correct-answer-label" :for="'answer-'+(index+1)+'-'+k">
                                                    <input 
                                                        class="form-check-input" 
                                                        :value="(index+1)+'-'+alternatives[k-1]" 
                                                        v-model="selectedOption[index]" 
                                                        type="radio" 
                                                        :id="'answer-'+(index+1)+'-'+k"
                                                    >
                                                    <span class="checkmark"></span>
                                                    <span class="label-text">Resposta correta</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </draggable>
                <small>Arraste e solte as questões para alterar a ordem.</small>
            </div>

            <div class="questions-section" v-if="showImportedQuestions">
                <draggable 
                    v-model="questions"
                    :disabled="true"
                    class="list-group"
                    ghost-class="ghost"
                >
                    <template #item="{index}">
                        <div class="question-card">
                            <div class="question-header" @click="toggleQuestion(index+1)">
                                <div class="question-title-row">
                                    <h4 class="question-title">Questão {{index+1}}</h4>
                                    <div class="question-actions">
                                        <i class="pi pi-bars handle"></i>
                                        <span class="toggle-icon">
                                            <i v-if="isQuestionExpanded(index+1)" class="pi pi-chevron-down"></i>
                                            <i v-else class="pi pi-chevron-right"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div v-show="isQuestionExpanded(index+1)" class="question-content">
                                <div class="question-label">Enunciado da questão:</div>
                                <div class="quileditor question-editor">
                                    <QuillEditor
                                        v-model:content="contentQuestions[index]"
                                        @input="updateContent(index, $event.srcElement.innerHTML)"
                                        theme="snow"
                                        toolbar="essential"
                                        contentType="html"
                                        :disabled="true"
                                    />
                                </div>
                                
                                <div class="answers-container">
                                    <div class="tabs-answer">
                                        <div 
                                            class="tab" 
                                            :class="{'active-tab': active[index+1][j]}" 
                                            v-for="j in qtyAnswer" 
                                            :key="j"
                                            @click="changeValueTab(index+1,j)"
                                        >
                                            <span>{{alternatives[j-1]}} )</span>
                                        </div>
                                    </div>
                                    <div class="answer-content" v-for="k in qtyAnswer" :key="k" v-show="active[index+1][k]">
                                        <div class="answer-editor-container">
                                            <div class="answer-editor">
                                                <!-- <QuillEditor
                                                    v-model:content="contentAnswers[index][k-1]"
                                                    @input="updateContentAnswer(index, k-1, $event.srcElement.innerHTML)"
                                                    theme="snow"
                                                    toolbar="essential"
                                                    :disabled="true"
                                                /> -->
                                            <textarea
                                                v-model="contentAnswers[index][k-1]" @input="contentAnswers[index][k-1] = $event.target.value.replace(/<[^>]*>/g, '')"
                                                class="form-control answer-textarea"
                                                rows="3"
                                                placeholder="Digite a resposta aqui..."
                                            ></textarea>
                                            </div>
                                            <div class="correct-answer-toggle">
                                                <label class="correct-answer-label" :for="'answer-'+(index+1)+'-'+k">
                                                    <input 
                                                        class="form-check-input" 
                                                        :value="(index+1)+'-'+alternatives[k-1]" 
                                                        v-model="selectedOption[index]" 
                                                        type="radio" 
                                                        :id="'answer-'+(index+1)+'-'+k"
                                                    >
                                                    <span class="checkmark"></span>
                                                    <span class="label-text">Resposta correta</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </draggable>
                <small>Arraste e solte as questões para alterar a ordem.</small>
            </div>
            <div class="questions-preview-small-container">
                <small class="questions-preview-small">As questões importadas ou criadas não podem ser editadas após salvar o questionário.</small>
            </div>
            <div class="questions-preview" v-if="selectedQuiz && quizData.source == 1 && showPreview">
                <h2 class="preview-title">Visualização do Questionário</h2>
                <div class="preview-container">
                    <div v-for="(question, index) in selectedQuizData" :key="index" class="preview-question">
                        <div class="preview-question-header">
                            <h3 class="preview-question-title">Questão {{question.order}}</h3>
                        </div>
                        <div class="preview-question-text" v-html="question.description"></div>
                        <div class="preview-answers">
                            <div v-for="(answer, answerIndex) in question.answers" :key="answerIndex" 
                                class="preview-answer" :class="{'correct-answer': answer.correct === 1}">
                                <div class="answer-content">
                                    <span class="answer-letter">{{alternatives[answerIndex]}} ) </span>
                                    <span class="answer-text" v-html="answer.description"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="button-container">
                <button class="btn-weflix" @click="$router.go(-1)">Cancelar</button>
                <!-- <button class="btn-weflix primary" @click="saveQuestions">Salvar Questões</button> -->
                <button class="btn-weflix primary" @click="createQuiz" v-if="quizData.source == 0">Salvar</button>
                <button class="btn-weflix primary" @click="saveLessonImported" v-if="quizData.source == 1">Salvar</button>
            </div>
        </div>
        <Loader v-if="isSaving" />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import draggable from 'vuedraggable';
import apiQuiz from '@/utils/quiz';
import apiAnswer from '@/utils/answer';
import apiLesson from '@/utils/lesson';
import Loader from '@/components/Loader/loader.vue';

export default {
    components: {
        QuillEditor,
        draggable,
        Loader
    },
    data() {
        const numRows = 100;
        const numCols = 100;
        const initialMatrix = Array.from({ length: numRows }, () =>
            Array.from({ length: numCols }, () => false)
        );

        return {
            isSaving: false,
            quizData: {
                title: '',
                requiresMinGrade: false,
                minGrade: null,
                source: 'create',
                maxGrade: null
            },
            lessonDatatitle: '',
            allQuiz: [],
            isReadyLoadQuiz: false,
            showButtonOptions: false,
            responseCreateQuiz: null,
            contentQuestions: [],
            contentAnswers: [],
            qtdQuestions: 1,
            qtyAnswer: 2,
            active: initialMatrix,
            selectedOption: [],
            alternatives: ['A','B','C','D','E'],
            expandedQuestions: new Set(),
            questions: [1],
            dragging: false,
            selectedQuiz: '',
            showImportedQuestions: false,
            showPreview: false,
            selectedQuizData: null,
            answers: []
        }
    },
    computed: {
        ...mapState(['token', 'currentModule', 'currentCollections'])
    },
    mounted() {
        for (let i = 1; i <= 10; i++) {
            this.active[i][1] = true;
        }
    },
    methods: {
        previewQuiz() {
            if (!this.selectedQuiz) {
                alert('Por favor, selecione um questionário');
                return;
            }
            
            this.showPreview = true;
        },
        applySelectedQuiz() {
            if (!this.selectedQuiz) {
                alert('Por favor, selecione um questionário');
                return;
            }

            if (!this.selectedQuizData) {
                alert('Erro ao carregar dados do questionário');
                return;
            }

            this.questions = this.selectedQuizData.map((_, index) => index + 1);
            this.qtdQuestions = this.selectedQuizData.length;
            this.qtyAnswer = Math.max(...this.selectedQuizData.map(q => q.answers.length));
            this.showImportedQuestions = true;

            this.selectedQuizData.forEach((question, index) => {
                this.contentQuestions[index] = question.description;
                this.contentAnswers[index] = [];
                question.answers.forEach((answer, answerIndex) => {
                    this.contentAnswers[index][answerIndex] = answer.description;
                    if (answer.correct === 1) {
                        this.selectedOption[index] = `${index+1}-${this.alternatives[answerIndex]}`;
                    }
                });
            });

            // Expand all questions
            this.selectedQuizData.forEach((_, index) => {
                this.expandedQuestions.add(index + 1);
            });
        },
        checkMove(e) {
            return true;
        },
        onDragEnd(e) {
            this.dragging = false;
        },
        incrementQuestions() {
            if (this.qtdQuestions < 10) {
                this.qtdQuestions++;
                this.questions.push(this.questions.length + 1);
            }
        },
        decrementQuestions() {
            if (this.qtdQuestions > 1) {
                this.qtdQuestions--;
                this.questions.pop();
            }
        },
        incrementAnswers() {
            if (this.qtyAnswer < 5) {
                this.qtyAnswer++;
            }
        },
        decrementAnswers() {
            if (this.qtyAnswer > 2) {
                this.qtyAnswer--;
            }
        },
        toggleQuestion(index) {
            if (this.expandedQuestions.has(index)) {
                this.expandedQuestions.delete(index);
            } else {
                this.expandedQuestions.add(index);
            }
        },
        isQuestionExpanded(index) {
            return this.expandedQuestions.has(index);
        },
        updateContent(i, content) {
            this.contentQuestions[i] = content;
        },
        updateContentAnswer(i, j, content) {
            if (!this.contentAnswers[i]) {
                this.contentAnswers[i] = [];
            }
            this.contentAnswers[i][j] = content;
        },
        changeValueTab(value1, value2) {
            for (let i = 1; i <= this.qtyAnswer; i++) {
                this.active[value1][i] = false;
            }
            this.active[value1][value2] = true;
        },
        async saveQuestions() {
            try {
                if (!this.contentQuestions[0]) {
                    alert(`Por favor, preencha a questão`);
                    return;
                }
                if (!this.selectedOption[0]) {
                    alert(`Por favor, selecione uma resposta correta para a questão`);
                    return;
                }

                const answers = [];
                for (let j = 0; j < this.qtyAnswer; j++) {
                    if (!this.contentAnswers[0] || !this.contentAnswers[0][j]) {
                        alert(`Por favor, preencha todas as alternativas da questão`);
                        return;
                    }
                    answers.push({
                        id: Math.random().toString(36).substr(2, 11),
                        order: j + 1,
                        correct: this.selectedOption[0] === `1-${this.alternatives[j]}` ? 1 : 0,
                        description: this.contentAnswers[0][j]
                    });
                }

                const payload = {
                    question: {
                        description: this.contentQuestions[0],
                        status: 1,
                        order: 1,
                        idQuiz: this.responseCreateQuiz.id
                    }, 
                    answers: answers
                };

                console.log(payload);

                const response = await apiAnswer.createQuestionAnswer(payload, this.token);
                
            } catch (error) {
                console.error('Erro ao salvar questões:', error);
                alert('Ocorreu um erro ao salvar as questões');
            }
        },
        async createQuiz() {
            this.isSaving = true;
            const payload = {
                "type": 1,
                "title": this.quizData.title,
                "minimum": this.quizData.minGrade,
                "maximum": this.quizData.maxGrade,
                "status": 1,
               
            }
            try {
                const response = await apiQuiz.createNewQuiz(payload, this.token);
                this.responseCreateQuiz = response;
               
            } catch (error) {
                console.error('Erro ao salvar questionário:', error);
                alert('Ocorreu um erro ao salvar o questionário');
            } finally {
                this.saveQuestions();
                this.saveLesson();
            }
        },
        async getAllQuiz() {
            try {
                const response = await apiQuiz.getDataQuiz(this.token);
                this.allQuiz = response.map(quiz => {
                    const { company, ...quizWithoutCompany } = quiz;
                    return quizWithoutCompany;
                });
            } catch (error) {
                console.error('Erro ao buscar questionários:', error);
                alert('Ocorreu um erro ao buscar questionários');
            }
        },
        async getQuestions() {
            try {
                this.isReadyLoadQuiz = true;
                const response = await apiAnswer.getAnswerByQuiz(this.selectedQuiz, this.token);
                console.log(response);
                this.selectedQuizData = response.content;
                this.isReadyLoadQuiz = false;
                this.showButtonOptions = true;
            } catch (error) {
                console.error('Erro ao buscar questões:', error);
                alert('Ocorreu um erro ao buscar questões');
            }
        },
        async saveLesson() {
            const payload = {
                "type": 5,
                "title": this.lessonDatatitle,
                "path": null,
                "description": null,
                "idQuiz": this.responseCreateQuiz.id,
                "idModule": this.currentModule.id,
                "idCourse": this.currentCollections.id,
                "status": 1,
            }
            try {
                const response = await apiLesson.createNewLesson(payload, this.token);
                this.isSaving = false;
                this.$router.push('/dashboard/curso');
            } catch (error) {
                console.error('Erro ao criar aula');
            }
        },
        async saveLessonImported() {
            const payload = {
                "type": 5,
                "title": this.lessonDatatitle,
                "path": null,
                "description": null,
                "idQuiz": this.selectedQuiz,
                "idModule": this.currentModule.id,
                "idCourse": this.currentCollections.id,
                "status": 1,
            }
            console.log(payload);
            if(this.selectedQuizData.length > 0){
                await this.editQuestion(0);
            }
            try {
                const response = await apiLesson.createNewLesson(payload, this.token);
                this.isSaving = false;
                this.$router.push('/dashboard/curso');
            } catch (error) {
                console.error('Erro ao criar aula');
            }
        },
        async editQuestion(questionId) {
            try {
                const answers = [];
                for (let i = 0; i < this.qtyAnswer; i++) {
                    const isCorrect = this.selectedOption[questionId] === `${questionId+1}-${this.alternatives[i]}` ? 1 : 0;
                    
                    answers.push({
                        "id": this.selectedQuizData[questionId].answers[i].id,
                        "order": i + 1,
                        "correct": isCorrect,
                        "description": `<p>${this.contentAnswers[questionId][i]}</p>`
                    });
                }

                const payload = {
                    "id": this.selectedQuizData[questionId].id,
                    "order": 1,
                    "status": 1,
                    "description": `<p>${this.contentQuestions[questionId]}</p>`,
                    "idQuiz": this.selectedQuiz,
                    "answers": answers
                };

                console.log(payload, this.selectedQuizData[questionId].id);
                const response = await apiAnswer.editQuestionAnswer(this.selectedQuizData[questionId].id, payload, this.token);
                
                // if (response) {
                //     alert('Questão atualizada com sucesso!');
                // }

            } catch (error) {
                console.error('Erro ao editar questão:', error);
                alert('Ocorreu um erro ao editar a questão');
            }
        }
    },
    
    created() {
        this.getAllQuiz();
    }
}
</script>

<style scoped>
.container {
    max-width: 100%;
    background-color: #ffffff;
    padding: 1.5rem;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.form-container {
    padding: 0px 20px;
}

.form-group {
    margin-bottom: 20px;
}

.form-row {
    display: flex;
    gap: 10px;
}

.form-control {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    font-size: 0.875rem;
    transition: all 0.2s ease;
}

.form-control:focus {
    outline: none;
    border-color: #2f2e8b;
    box-shadow: 0 0 0 3px rgba(47, 46, 139, 0.1);
}

.button-container {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    margin-top: 30px;
    position: absolute;
    bottom: 29px;
    right: 30px;
}

.btn-weflix {
    padding: 0.5rem 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    background: white;
    color: #4a5568;
    cursor: pointer;
    transition: all 0.2s;
}

.btn-weflix.primary {
    background: #2f2e8b;
    color: white;
    border: none;
}

.btn-weflix:hover {
    background: #f8fafc;
    border-color: #cbd5e0;
}

.btn-weflix.primary:hover {
    background: #23225f;
}

p.form-label-title {
    margin-bottom: 10px;
    font-weight: 600;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 25px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 17px;
    width: 17px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
}

input:checked + .slider {
    background-color: #2f2e8b;
}

input:checked + .slider:before {
    transform: translateX(36px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.radio-options {
    display: flex;
    gap: 20px;
    justify-content: initial;
}

.radio-option-card {
    flex: 1;
    max-width: 400px;
    position: relative;
}

.radio-option-card input[type="radio"] {
    display: none;
}

.radio-option-card label {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;
    border: 2px solid #e2e8f0;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    gap: 10px;
}

.radio-option-card label i {
    font-size: 2rem;
    color: #2f2e8b;
}

.radio-option-card label span {
    text-align: center;
    font-weight: 500;
}

.radio-option-card input[type="radio"]:checked + label {
    border-color: #2f2e8b;
    background-color: rgba(47, 46, 139, 0.05);
}

.quantity-control {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
}

.quantity-btn {
    padding: 5px 10px;
    border: 1px solid #e2e8f0;
    background: white;
    border-radius: 4px;
    cursor: pointer;
}

.quantity-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.quantity-display {
    min-width: 30px;
    text-align: center;
}

.question-card {
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    margin-bottom: 20px;
    background: white;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.question-header {
    padding: 15px 20px;
    cursor: pointer;
    background-color: #f8fafc;
    border-radius: 8px 8px 0 0;
    border-bottom: 1px solid #e2e8f0;
}

.question-content {
    padding: 20px;
    text-align: left;
}

.question-title-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.question-title {
    font-size: 1.1rem;
    color: #2d3748;
    margin: 0;
}

.question-actions {
    display: flex;
    align-items: center;
    gap: 15px;
}

.handle {
    cursor: move;
    color: #718096;
}

.question-label {
    font-weight: 500;
    color: #4a5568;
    margin-bottom: 10px;
}

.tabs-answer {
    display: flex;
    gap: 10px;
    margin: 20px 0;
}

.tab {
    padding: 8px 15px;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.active-tab {
    background-color: #2f2e8b;
    color: white;
    border-color: #2f2e8b;
}

.answer-editor-container {
    background: #f8fafc;
    border-radius: 8px;
    padding: 15px;
    width: 100%;
}

.correct-answer-toggle {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #e2e8f0;
}

.correct-answer-label {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.correct-answer-label input[type="radio"] {
    width: 18px !important;
    height: 18px !important;
    margin: 0;
}

.label-text {
    color: #4a5568;
    font-weight: 500;
}

@media (max-width: 768px) {
    .container {
        padding: 1rem;
    }
    
    .form-row {
        flex-direction: column;
    }
    
    .radio-options {
        flex-direction: column;
    }
}

input.form-control:disabled {
    background-color: #f0f0f0;
    cursor: not-allowed;
}

.radio-option-card:hover label {
    border: 2px dashed #150065;
}

.form-group.col-12.new-quiz-options {
    margin: 15px 10px !important;
}

.config-row {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.config-item {
    flex: 1;
}

.form-input.import-create-quiz {
    display: flex;
    gap: 20px;
}

.form-input.import-create-quiz > div {
    width: 50%;
}

.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}

.list-group {
    min-height: 20px;
}

.questions-section {
    text-align: left;
}

.form-input.import-create-quiz.import {
    gap: 0px;
}

.questions-preview {
    margin-top: 30px;
    padding: 20px;
    background: #f8fafc;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.preview-title {
    color: #2d3748;
    font-size: 1.5rem;
}
.preview-question {
    margin-bottom: 30px;
    padding: 20px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.preview-answers {
    margin-top: 15px;
}

.preview-answer {
    padding: 10px;
    margin: 8px 0;
    border-radius: 4px;
    background: #f8f9fa;
    display: flex;
    align-items: center;
}

.preview-answer.correct-answer {
    background: #250891;
    border: 1px solid #250991;
    color: white;
}

.preview-question-header {
    text-align: left;
}
.preview-question-text {
    text-align: left;
}
.answer-content {
    display: flex;
    gap: 10px;
}
.form-group.col-12.new-quiz-options {
    max-width: 100%;
    display: inline;
    padding: 10px !important;
    margin: 10px !important;
}
button.btn-weflix.primary:disabled {
    background-color: #2f2e8b;
    opacity: 0.5;
    cursor: not-allowed;
}
.questions-preview-small {
    text-align: left;
    font-size: 12px;
    color: #666;
    margin-top: 10px;
}
.questions-preview-small-container {
    text-align: left;
}
/* DARK */
.dashboard.dark .content.dashboard > .container.doubts-container.adminWeeducCreateQuiz {
    background-color: #000 !important;
}
.dashboard.dark .container.doubts-container.adminWeeducCreateQuiz h3, 
.dashboard.dark .container.doubts-container.adminWeeducCreateQuiz p,
.dashboard.dark .container.doubts-container.adminWeeducCreateQuiz label,
.dashboard.dark .container.doubts-container.adminWeeducCreateQuiz .radio-option-card label i,
.dashboard.dark .container.doubts-container.adminWeeducCreateQuiz .questions-preview-small,
.dashboard.dark .container.doubts-container.adminWeeducCreateQuiz small {
    color: white !important;
}
.dashboard.dark .container.doubts-container.adminWeeducCreateQuiz .form-group .form-input .form-control,
.dashboard.dark .container.doubts-container.adminWeeducCreateQuiz .video-placeholder {
    background-color: #575757 !important;
    border: 1px solid #444 !important; 
}
.dashboard.dark .container.doubts-container.adminWeeducCreateQuiz .titleContainer {
    border-bottom: 1px solid #575757 !important;
}
.dashboard.dark .container.doubts-container.adminWeeducCreateQuiz .radio-option-card label {
    border: 2px solid #444;
    background-color: #575757;
}
.dashboard.dark .container.doubts-container.adminWeeducCreateQuiz .question-header {
    background-color: #575757 !important;
    border-bottom: 1px solid #444 !important;
}
.dashboard.dark .container.doubts-container.adminWeeducCreateQuiz .question-card {
    border: 1px solid #444 !important;
}
.dashboard.dark .container.doubts-container.adminWeeducCreateQuiz .question-content {
    background-color: #575757 !important;
}
.dashboard.dark .container.doubts-container.adminWeeducCreateQuiz .answer-editor-container {
    background-color: #575757 !important;
    border: 1px solid white !important;
}
.dashboard.dark .container.doubts-container.adminWeeducCreateQuiz textarea {
    background-color: #575757 !important;
    color: white !important;
    border: 1px solid white !important;
}
.dashboard.dark .container.doubts-container.adminWeeducCreateQuiz .question-title,
.dashboard.dark .container.doubts-container.adminWeeducCreateQuiz .question-actions,
.dashboard.dark .container.doubts-container.adminWeeducCreateQuiz .handle,
.dashboard.dark .container.doubts-container.adminWeeducCreateQuiz .question-label,
.dashboard.dark .container.doubts-container.adminWeeducCreateQuiz span {
    color: white !important;
}
</style>
