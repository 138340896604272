<template>
    <div class="globalInquiry" :class="{ 'dark': this.dark }">
        <Loader :isActive="hasReady"></Loader>
        <div class="title">
            <h3>Minhas dúvidas</h3>
            <button class="newInquiry" @click="(()=>this.openDialogNewInquiry=true)">Nova dúvida</button>
        </div>
        <div class="itemInquiry" v-for="(item, index) in data" :key="index">
            <div class="subtitle">
                <span>Enviado dia {{formatarData(item.dateInsert)}}</span>
            </div>
            <div class="primaryContent" @click="toggleInquiry(index)" :class="{ 'active': activeInquiries[index] }">
                <div class="title_inquiry">
                    <span>{{item.title}}</span>
                    <i class="pi" :class="[activeInquiries[index] ? 'pi-chevron-up' : 'pi-chevron-down']"></i>
                </div>
                <div class="content_inquiry" v-show="activeInquiries[index]" v-html="item.description">
                </div>
            </div>
            
            <div v-show="activeInquiries[index]">
                <div class="reply">
                    <ReplyInquiry :id="item.id" :data="item.messages"></ReplyInquiry>
                </div>

                <div class="waiting" v-show="item.status == 1">
                    Aguardando Resposta
                </div>
                <div class="form-input">
                    <QuillEditor theme="snow" toolbar="essential" 
                        v-model:content="comment[index]"
                        contentType="html"/>
                    <button class="newInquiry" @click="newComment(item.id, index)">Novo Comentário</button>
                </div>
            </div>
        </div>
    </div>
    <NewInquiry :showNewInquiry="openDialogNewInquiry" @handleOpenModalInquiry="handleOpenModalInquiry"></NewInquiry>
</template>

<script>
import NewInquiry from '@/components/Student/Dashboard_Unique/Inquiry/New_Inquiry.vue'
import ReplyInquiry from '@/components/Student/Dashboard_Unique/Inquiry/Reply_Inquity.vue'
import Loader from '@/components/Loader/loader.vue';

import api from '@/utils/portal/inquiry.js'
import { mapState } from 'vuex';

export default {
    data(){
        return{
            active: 0,
            data:[],
            openDialogNewInquiry:false,
            hasReady:false,
            comment:[],
            activeInquiries: {}
        }
    },
    props:{

    },
    computed:{
        ...mapState(['token']),
        ...mapState(['dark']),
    },
    components:{
        NewInquiry,
        ReplyInquiry,
        Loader
    },
    methods:{
        handleOpenModalInquiry(){
            this.openDialogNewInquiry = false;
        },
        toggleInquiry(index) {
            this.activeInquiries[index] = !this.activeInquiries[index];
        },
        async getDataInquirys(){
            try {
                const response = await api.getDataListInquiry(this.token);
                this.data = response;
                // Define o primeiro item como ativo por padrão
                this.activeInquiries[0] = true;
                this.hasReady = true;
            } catch (error) {
                console.error('Erro na listagem da notificações')
            }   
        },
        formatarData(data) {
            const dataObj = new Date(data);
            const dia = dataObj.getDate();
            const mes = dataObj.getMonth() + 1;
            const ano = dataObj.getFullYear() % 100;

            const diaFormatado = dia < 10 ? "0" + dia : dia;
            const mesFormatado = mes < 10 ? "0" + mes : mes;
            const anoFormatado = ano < 10 ? "0" + ano : ano;

            return `${diaFormatado}/${mesFormatado}/${anoFormatado}`;
        },
        async newComment(id, index){
            const obj = {
                "message": this.comment[index],
            };
            try {
                const response = await api.newComment(this.token, obj, id);
                location.reload();
            } catch (error) {
                console.error('Erro ao enviar comentário da dúvida')
            }
        },
    },
    created() {
        this.getDataInquirys();
    },
    watch:{
        openDialogNewInquiry(novoValor){
            if(novoValor == false){
                setTimeout(() => {
                this.getDataInquirys();
            }, 1000);
            }
            document.body.style.overflow = novoValor ? 'hidden' : '';
        },
    }

}
</script>

<style>
.itemInquiry span.ql-align.ql-picker.ql-icon-picker {
    margin: 10px 0px;
}
.itemInquiry span.ql-color.ql-picker.ql-color-picker {
    margin: 10px 0px;
}
.globalInquiry {
    background-color: #f9f9f9fc;
    height: calc(100vh - 120px);
    padding: 50px 100px;
    overflow-y: auto;
}
.itemInquiry {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    margin: 10px 0px;
    border: 1px solid #e0e0e0;
    text-align: left;
}
.subtitle {
    font-size: 10px;
    color: gray;
    margin-bottom: 10px;
}
.title_inquiry {
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.content_inquiry {
    font-size: 14px;
    margin-top: 10px;
}
.autor {
    margin-top: 020px;
    display: flex;
    align-items: baseline;
}
.waiting {
    background-color: #fff0b9;
    padding: 10px;
    border-radius: 5px;
    width: 180px;
    text-align: center;
    font-size: 12px;
    margin-top: 20px;
}
button.newInquiry {
    background-color: #19c7d0;
    padding: 8px 16px;
    border-radius: 5px;
    font-size: 14px;
    color: white;
    font-weight: 600;
}
.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.globalInquiry.dark {
    background-color: rgb(0, 0, 0);
    border-top: 1px solid #313131;
    color: white;
}
.globalInquiry.dark .title h3{
    color: white;
}
.globalInquiry.dark  .itemInquiry {
    background-color: #272727;
    border: 1px solid #272727;
}
.globalInquiry.dark .reply_item {
    background-color: #666666;
}
.globalInquiry.dark span{
    color: white!important;
}
.globalInquiry.dark  .waiting{
    color: black;
}
.globalInquiry .form-input {
    margin: 10px 0px;
    color: white;
}
.globalInquiry .form-input button {
    margin: 10px 0px;
    color: white;
}
.ql-editor {
    color: black;
}
.globalInquiry.dark  .ql-editor {
    color: white;
}
@media only screen and (max-width:600px){
    .globalInquiry {
        background-color: #f9f9f9fc;
        height: auto;
        padding: 10px;
        overflow-y: auto;
    }
    .form-create-module.newInquiry.open{
      
        top: 15%!important;
        width: 95%!important;
    }
    .bg {
        top: 0%!important;
    }
}
</style>