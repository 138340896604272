<template>
  <div class="dashboard">       
    <Sidebar/>
    <div class="page">
      <!-- <Toolbar /> -->
      <div class="content dashboard superAdmin">
        <div class="container">
          <div class="titleContainer">
            <div class="primaryHeader">
              <h3>Nova Empresa</h3>
              <p>
                Crie uma nova empresa e as informações básicas para os sistemas Weeduc e Weflix.
              </p>
            </div>
          </div>
          <div class="container" v-if="hasReady">
            <div class="row">
              <div class="col-form-inner col-6 col-md-6 col-lg-6">
                <div class="form-group">
                  <div class="form-label">
                    <label>Razão Social<v-tooltip text="Campo obrigatório" location="top">
                      <template v-slot:activator="{ props }">
                        <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                      </template>
                    </v-tooltip></label>
                  </div>
                  <div class="form-input">
                    <input
                      type="text" 
                      required
                      class="form-control"
                      v-model="tradeName"
                      placeholder="Digite a razão social"
                    />
                    <small>Digite a razão social da empresa conforme registro.</small>
                  </div>
                </div>
              </div>
              <div class="col-form-inner col-6 col-md-6 col-lg-6">
                <div class="form-group">
                  <div class="form-label">
                    <label>Nome Fantasia<v-tooltip text="Campo obrigatório" location="top">
                      <template v-slot:activator="{ props }">
                        <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                      </template>
                    </v-tooltip></label>
                  </div>
                  <div class="form-input">
                    <input
                      type="text" 
                      required
                      class="form-control"
                      v-model="name"
                      placeholder="Digite o nome fantasia"
                    />
                    <small>Nome comercial da empresa para identificação no mercado.</small>
                  </div>
                </div>
              </div>
              <div class="col-form-inner col-4 col-md-4 col-lg-4">
                <div class="form-group">
                  <div class="form-label">
                    <label>CNPJ<v-tooltip text="Campo obrigatório" location="top">
                      <template v-slot:activator="{ props }">
                        <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                      </template>
                    </v-tooltip></label>
                  </div>
                  <div class="form-input">
                    <input
                      type="text" 
                      required
                      class="form-control"
                      v-model="idFiscal"
                      placeholder="Digite o CNPJ"
                      v-mask="'##.###.###/####-##'"
                    />
                    <small>Número do CNPJ da empresa sem pontuação.</small>
                  </div>
                </div>
              </div>

              <div class="col-form-inner col-6 col-md-6 col-lg-6">
                <div class="form-group">
                  <div class="form-label">
                    <label>E-mail<v-tooltip text="Campo obrigatório" location="top">
                      <template v-slot:activator="{ props }">
                        <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                      </template>
                    </v-tooltip></label>
                  </div>
                  <div class="form-input">
                    <input
                      type="text" 
                      required
                      class="form-control"
                      v-model="email"
                      placeholder="Digite o e-mail"
                    />
                    <small>E-mail principal para contato com a empresa.</small>
                  </div>
                </div>
              </div>

              <div class="col-form-inner col-2 col-md-2 col-lg-2">
                <div class="form-group">
                  <div class="form-label">
                    <label>Telefone<v-tooltip text="Campo obrigatório" location="top">
                      <template v-slot:activator="{ props }">
                        <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                      </template>
                    </v-tooltip></label>
                  </div>
                  <div class="form-input">
                    <input
                      type="text" 
                      required
                      class="form-control"
                      v-model="phone"
                      placeholder="Digite o telefone"
                      v-mask="'(##) #####-####'"
                    />
                    <small>Telefone principal para contato com a empresa.</small>
                  </div>
                </div>
             </div>


             <div class="col-form-inner col-2 col-md-2 col-lg-2">
                <div class="form-group">
                  <div class="form-label">
                    <label>CEP<v-tooltip text="Informe o CEP" location="top">
                      <template v-slot:activator="{ props }">
                        <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                      </template>
                    </v-tooltip></label>
                  </div>
                  <div class="form-input">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="00000-000"
                      v-model="postcode"
                      v-mask="'#####-###'"
                      @input="getCep"
                    />
                    <small class="small">
                      Informe o CEP para preenchimento automático do endereço.
                    </small>
                  </div>
                </div>
              </div>

               
              <!-- <div class="col-form-inner col-2 col-md-2 col-lg-2">
                <div class="col-form-inner col-12 col-md-12 col-lg-12 cep">
                  <div class="form-group actions">
                    <button class="btn">
                      Pesquisar
                    </button>
                  </div>
                </div>
              </div>
 -->

              <div class="col-form-inner col-8 col-md-8 col-lg-8">
                <div class="form-group">
                  <div class="form-label">
                    <label>Rua/Avenida<v-tooltip text="Informe a rua/avenida" location="top">
                      <template v-slot:activator="{ props }">
                        <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                      </template>
                    </v-tooltip></label>
                  </div>
                  <div class="form-input">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Nome da rua ou avenida"
                      v-model="address"
                    />
                    <small class="small">
                      Digite o nome da rua ou avenida do seu endereço.
                    </small>
                  </div>
                </div>
              </div>



              <div class="col-form-inner col-2 col-md-2 col-lg-2">
                <div class="form-group">
                  <div class="form-label">
                    <label>Número<v-tooltip text="Informe o número" location="top">
                      <template v-slot:activator="{ props }">
                        <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                      </template>
                    </v-tooltip></label>
                  </div>
                  <div class="form-input">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Número"
                      v-model="addressNumber"
                    />
                    <small class="small">
                      Informe o número do seu endereço.
                    </small>
                  </div>
                </div>
              </div>

              <div class="col-form-inner col-3 col-md-3 col-lg-3">
                <div class="form-group">
                  <div class="form-label">
                    <label>Complemento<v-tooltip text="Informe o complemento" location="top">
                      <template v-slot:activator="{ props }">
                        <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                      </template>
                    </v-tooltip></label>
                  </div>
                  <div class="form-input">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Complemento"
                      v-model="addressComplement"
                    />
                    <small class="small">
                      Adicione informações complementares, se necessário.
                    </small>
                  </div>
                </div>
              </div>

              <div class="col-form-inner col-3 col-md-3 col-lg-3">
                <div class="form-group">
                  <div class="form-label">
                    <label>Bairro<v-tooltip text="Informe o bairro" location="top">
                      <template v-slot:activator="{ props }">
                        <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                      </template>
                    </v-tooltip></label>
                  </div>
                  <div class="form-input">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Nome do bairro"
                      v-model="neighborhood"
                    />
                    <small class="small">
                      Digite o nome do bairro do seu endereço.
                    </small>
                  </div>
                </div>
              </div>

              <div class="col-form-inner col-4 col-md-4 col-lg-4">
                <div class="form-group">
                  <div class="form-label">
                    <label>Cidade<v-tooltip text="Informe a cidade" location="top">
                      <template v-slot:activator="{ props }">
                        <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                      </template>
                    </v-tooltip></label>
                  </div>
                  <div class="form-input">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Nome da cidade"
                      v-model="city"
                    />
                    <small class="small">
                      Informe o nome da cidade do seu endereço.
                    </small>
                  </div>
                </div>
              </div>

              <div class="col-form-inner col-2 col-md-2 col-lg-2">
                <div class="form-group">
                  <div class="form-label">
                    <label>Estado<v-tooltip text="Selecione o estado" location="top">
                      <template v-slot:activator="{ props }">
                        <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                      </template>
                    </v-tooltip></label>
                  </div>
                  <div class="form-input">
                    <select
                      class="form-control"
                      v-model="county"
                    >
                      <option value="" disabled selected>Selecione o estado</option>
                      <option value="AC">Acre</option>
                      <option value="AL">Alagoas</option>
                      <option value="AP">Amapá</option>
                      <option value="AM">Amazonas</option>
                      <option value="BA">Bahia</option>
                      <option value="CE">Ceará</option>
                      <option value="DF">Distrito Federal</option>
                      <option value="ES">Espírito Santo</option>
                      <option value="GO">Goiás</option>
                      <option value="MA">Maranhão</option>
                      <option value="MT">Mato Grosso</option>
                      <option value="MS">Mato Grosso do Sul</option>
                      <option value="MG">Minas Gerais</option>
                      <option value="PA">Pará</option>
                      <option value="PB">Paraíba</option>
                      <option value="PR">Paraná</option>
                      <option value="PE">Pernambuco</option>
                      <option value="PI">Piauí</option>
                      <option value="RJ">Rio de Janeiro</option>
                      <option value="RN">Rio Grande do Norte</option>
                      <option value="RS">Rio Grande do Sul</option>
                      <option value="RO">Rondônia</option>
                      <option value="RR">Roraima</option>
                      <option value="SC">Santa Catarina</option>
                      <option value="SP">São Paulo</option>
                      <option value="SE">Sergipe</option>
                      <option value="TO">Tocantins</option>
                    </select>
                    <small class="small">
                      Digite a sigla do estado (UF) do seu endereço.
                    </small>
                  </div>
                </div>
              </div>

              <hr>
              <div class="col-form-inner col-4 col-md-4 col-lg-4">
                <div class="form-group">
                  <div class="form-label">
                    <label>Tipo de Sistema<v-tooltip text="Selecione o estado" location="top">
                      <template v-slot:activator="{ props }">
                        <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                      </template>
                    </v-tooltip></label>
                  </div>
                  <div class="form-input">
                    <select
                      class="form-control"
                      v-model="typeSystem"
                    >
                      <option value="" disabled selected>Selecione o tipo</option>
                      <option value="1">Weeduc</option>
                      <option value="2">Weflix</option>
                    </select>
                    <small class="small">
                     Selecione o tipo de sistema Weeduc ou  Weflix.
                    </small>
                  </div>
                </div>
              </div>
<!-- 
            <div class="col-form-inner col-6 col-md-6 col-lg-6">
                <div class="form-group">
                  <div class="form-label">
                    <label>Logo</label>
                    <small>Insira uma imagem maior que 250x250 pixels</small>
                  </div>
                  <div class="form-file">
                    <div class="file-wrapper primary">
                      <img
                      :src="logoPreview? logoPreview : logo"
                      alt="Logo"
                      v-show="logoPreview || logo"
                      style="width: 250px;"
                    />
                      <span class="thumbnail">
                        <span class="icon">
                          <svg
                            width="46"
                            height="39"
                            viewBox="0 0 46 39"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M45 19.9308L36.7274 12.3475C36.1595 11.827 35.2948 11.8047 34.7009 12.2953L18.824 25.411C18.3196 25.8277 17.6074 25.8812 17.0464 25.5446L11.1009 21.9773C10.5893 21.6703 9.94666 21.6856 9.45024 22.0166L1 27.6501"
                              stroke="#9D9FA3"
                              stroke-width="1.25714"
                            ></path>
                            <rect
                              x="1.00049"
                              y="1.40454"
                              width="43.9461"
                              height="36.1909"
                              rx="2.58507"
                              stroke="#9D9FA3"
                              stroke-width="1.25714"
                            ></rect>
                            <circle
                              cx="14.8948"
                              cy="10.6676"
                              r="3.71629"
                              stroke="#9D9FA3"
                              stroke-width="1.25714"
                            ></circle>
                          </svg>
                        </span>
                        <span class="text">
                          Clique aqui para selecionar a imagem
                        </span>
                        <LoaderImage :hasReady="loaderImage"/>
                      </span>
                      <input
                        class="form-control"
                        type="file"
                        @change="updateFilePathLogo"
                        accept=".png, .jpg, .jpeg, .webp"
                      />
                    </div>
                  </div>
                </div>
            </div> -->

            <!-- <div class="col-form-inner col-6 col-md-6 col-lg-6">
                <div class="form-group">
                    <div class="form-label">
                    <label>Favicon</label>
                    <small>Insira uma imagem maior que 250x250 pixels</small>
                    </div>
                    <div class="form-file">
                    <div class="file-wrapper primary">
                        <img
                        :src="faviconPreview? faviconPreview : favicon"
                        alt="Favicon"
                        v-show="faviconPreview || favicon"
                        style="width: 250px;"
                    />
                        <span class="thumbnail">
                        <span class="icon">
                            <svg
                            width="46"
                            height="39"
                            viewBox="0 0 46 39"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            >
                            <path
                                d="M45 19.9308L36.7274 12.3475C36.1595 11.827 35.2948 11.8047 34.7009 12.2953L18.824 25.411C18.3196 25.8277 17.6074 25.8812 17.0464 25.5446L11.1009 21.9773C10.5893 21.6703 9.94666 21.6856 9.45024 22.0166L1 27.6501"
                                stroke="#9D9FA3"
                                stroke-width="1.25714"
                            ></path>
                            <rect
                                x="1.00049"
                                y="1.40454"
                                width="43.9461"
                                height="36.1909"
                                rx="2.58507"
                                stroke="#9D9FA3"
                                stroke-width="1.25714"
                            ></rect>
                            <circle
                                cx="14.8948"
                                cy="10.6676"
                                r="3.71629"
                                stroke="#9D9FA3"
                                stroke-width="1.25714"
                            ></circle>
                            </svg>
                        </span>
                        <span class="text">
                            Clique aqui para selecionar a imagem
                        </span>
                        <LoaderImage :hasReady="loaderImage"/>
                        </span>
                        <input
                        class="form-control"
                        type="file"
                        @change="updateFilePathFavicon"
                        accept=".png, .jpg, .jpeg, .webp"
                        />
                    </div>
                    </div>
                </div>
                </div> -->


                <!-- <div class="col-form-inner col-4 col-md-4 col-lg-4">
                  <div class="form-group">
                    <div class="form-label">
                      <label>Tema<v-tooltip text="Selecione o tema" location="top">
                        <template v-slot:activator="{ props }">
                          <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                        </template>
                      </v-tooltip></label>
                    </div>
                    <div class="form-input">
                      <select
                        class="form-control"
                        v-model="theme"
                      >
                        <option value="dark">Dark</option>
                        <option value="light">Light</option>
                      </select>
                      <small class="small">
                          Selecione o tema da interface do sistema.
                      </small>
                    </div>
                  </div>
                </div> -->

                <div class="col-form-inner col-4 col-md-4 col-lg-4">
                    <div class="form-group">
                      <div class="form-label">
                        <label>Tempo de Vídeo<v-tooltip text="Selecione o estado" location="top">
                          <template v-slot:activator="{ props }">
                            <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                          </template>
                        </v-tooltip></label>
                      </div>
                      <div class="form-input">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Nome do bairro"
                          v-model="videoTimeTotal"
                        />
                        <small class="small">
                            Número de horas contabilizadas para o vídeo.
                        </small>
                      </div>
                    </div>
                  </div>


                  <hr>

                  <div class="col-form-inner col-3 col-md-3 col-lg-3">
                    <div class="form-group">
                      <div class="form-label">
                        <label>Habilitar Split
                            <v-tooltip text="Campo obrigatório" location="top">
                        </v-tooltip></label>
                      </div>
                      <div class="itemAtionCollections form-check form-switch">
                        <span>Ativado</span>
                        <input
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                        v-model="split"
                      />
                    </div>
                    <small>Ative para habilitar o split e pagamento.</small>
                    </div>
              </div>


              <div class="col-form-inner col-9 col-md-9 col-lg-9">
                <div class="form-group">
                  <div class="form-label">
                    <label>Banco</label>
                  </div>
                  <div class="form-input">
                    <select
                      required
                      class="form-control"
                      v-model="bank"
                    >
                      <option value="" disabled selected>Selecione o banco</option>
                      <option value="001">001 - BANCO DO BRASIL S.A.</option>
                      <option value="033">033 - BANCO SANTANDER S.A.</option>
                      <option value="104">104 - CAIXA ECONÔMICA FEDERAL</option>
                      <option value="237">237 - BANCO BRADESCO S.A.</option>
                      <option value="341">341 - BANCO ITAÚ S.A.</option>
                      <option value="077">077 - BANCO INTER S.A.</option>
                      <option value="260">260 - NUBANK</option>
                      <option value="336">336 - BANCO C6 S.A.</option>
                      <option value="290">290 - PAGSEGURO INTERNET S.A.</option>
                      <option value="380">380 - PICPAY</option>
                    </select>
                  </div>
                  <small>Selecione o banco para recebimento dos pagamentos.</small>
                </div>
              </div>


              <div class="col-form-inner col-4 col-md-4 col-lg-4">
                <div class="form-group">
                  <div class="form-label">
                    <label>Agência<v-tooltip text="Campo obrigatório" location="top">
                      <template v-slot:activator="{ props }">
                        <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                      </template>
                    </v-tooltip></label>
                  </div>
                  <div class="form-input">
                    <input
                      type="text" 
                      required
                      class="form-control"
                      v-model="branch"
                      placeholder="0000"
                      v-mask="'####'"
                    />
                    <small>Digite o número da agência bancária.</small>
                  </div>
                </div>
          </div>


          <div class="col-form-inner col-2 col-md-2 col-lg-2">
            <div class="form-group">
              <div class="form-label">
                <label>Dígito<v-tooltip text="Campo obrigatório" location="top">
                  <template v-slot:activator="{ props }">
                    <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                  </template>
                </v-tooltip></label>
              </div>
              <div class="form-input">
                <input
                  type="text" 
                  required
                  class="form-control"
                  v-model="digitBranch"
                  placeholder="00000"
                  v-mask="'#'"
                />
                <small>Digite o dígito da agência.</small>
              </div>
            </div>
      </div>


      <div class="col-form-inner col-4 col-md-4 col-lg-4">
        <div class="form-group">
          <div class="form-label">
            <label>Conta<v-tooltip text="Campo obrigatório" location="top">
              <template v-slot:activator="{ props }">
                <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
              </template>
            </v-tooltip></label>
          </div>
          <div class="form-input">
            <input
              type="text" 
              required
              class="form-control"
              v-model="account"
              placeholder="00000"
              v-mask="'#######'"
            />
            <small>Digite o número da conta bancária.</small>
          </div>
        </div>
    </div>


    <div class="col-form-inner col-2 col-md-2 col-lg-2">
        <div class="form-group">
          <div class="form-label">
            <label>Dígito<v-tooltip text="Campo obrigatório" location="top">
              <template v-slot:activator="{ props }">
                <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
              </template>
            </v-tooltip></label>
          </div>
          <div class="form-input">
            <input
              type="text" 
              required
              class="form-control"
              v-model="digitAccount"
              placeholder="00000"
              v-mask="'#'"
            />
            <small>Digite o dígito da conta.</small>
          </div>
        </div>
    </div>

    <div class="col-form-inner col-4 col-md-4 col-lg-4">
        <div class="form-group">
          <div class="form-label">
            <label>Valor por transação (R$)<v-tooltip text="Campo obrigatório" location="top">
              <template v-slot:activator="{ props }">
                <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
              </template>
            </v-tooltip></label>
          </div>
          <div class="form-input">
            <input
              type="text" 
              required
              class="form-control"
              v-model="transactionValue"
              placeholder="1,00"
            />
            <small>Informe o valor fixo por transação.</small>
          </div>
        </div>
    </div>




    <div class="col-form-inner col-4 col-md-4 col-lg-4">
        <div class="form-group">
          <div class="form-label">
            <label>Percentual do Cliente (%)<v-tooltip text="Campo obrigatório" location="top">
              <template v-slot:activator="{ props }">
                <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
              </template>
            </v-tooltip></label>
          </div>
          <div class="form-input">
            <input
              type="text" 
              required
              class="form-control"
              v-model="percentualClient"
              placeholder="10"
            />
            <small>Informe o percentual que será destinado ao cliente.</small>
          </div>
        </div>
    </div>

    <hr>

    <div class="col-form-inner col-6 col-md-6 col-lg-6">
        <div class="form-group">
          <div class="form-label">
            <label>Nome do Responsável<v-tooltip text="Campo obrigatório" location="top">
              <template v-slot:activator="{ props }">
                <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
              </template>
            </v-tooltip></label>
          </div>
          <div class="form-input">
            <input
              type="text" 
              required
              class="form-control"
              v-model="nameRepresentative"
              placeholder="João da Silva"
            />
            <small>Digite o nome completo do responsável.</small>
          </div>
        </div>
    </div>


    <div class="col-form-inner col-6 col-md-6 col-lg-6">
        <div class="form-group">
          <div class="form-label">
            <label>E-mail do Responsável<v-tooltip text="Campo obrigatório" location="top">
              <template v-slot:activator="{ props }">
                <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
              </template>
            </v-tooltip></label>
          </div>
          <div class="form-input">
            <input
              type="text" 
              required
              class="form-control"
              v-model="emailRepresentative"
              placeholder="email@email.com"
            />
            <small>Digite o e-mail do responsável.</small>
          </div>
        </div>
    </div>

      <div class="col-form-inner col-4 col-md-4 col-lg-4">
        <div class="form-group">
          <div class="form-label">
            <label>CPF do Responsável<v-tooltip text="Campo obrigatório" location="top">
              <template v-slot:activator="{ props }">
                <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
              </template>
            </v-tooltip></label>
          </div>
          <div class="form-input">
            <input
              type="text" 
              required
              class="form-control"
              v-model="idRepresentative"
              v-mask="'###.###.###-##'"
              placeholder="000.000.000-00"
            />
            <small>Digite o CPF do responsável.</small>
          </div>
        </div>
    </div>



      <div class="col-form-inner col-4 col-md-4 col-lg-4">
        <div class="form-group">
          <div class="form-label">
            <label>Data de Nascimento<v-tooltip text="Campo obrigatório" location="top">
              <template v-slot:activator="{ props }">
                <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
              </template>
            </v-tooltip></label>
          </div>
          <div class="form-input">
            <input
              type="date" 
              required
              class="form-control"
              v-model="birthdate"
              placeholder="00/00/0000"
            />
            <small>Informe a data de nascimento do responsável.</small>
          </div>
        </div>
    </div>



      <div class="col-form-inner col-4 col-md-4 col-lg-4">
        <div class="form-group">
          <div class="form-label">
            <label>Profissão do Responsável<v-tooltip text="Campo obrigatório" location="top">
              <template v-slot:activator="{ props }">
                <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
              </template>
            </v-tooltip></label>
          </div>
          <div class="form-input">
            <input
              type="text" 
              required
              class="form-control"
              v-model="professional_occupation"
              placeholder="Engenheiro"
            />
            <small>Digite a profissão do responsável.</small>
          </div>
        </div>
    </div>

    <!-- <hr> -->

    <!-- <div class="col-form-inner col-6 col-md-6 col-lg-6">
        <div class="col-form-inner col-12 col-md-12 col-lg-12">
            <div class="form-group">
                <div class="form-label">
                <label>Título<v-tooltip text="Campo obrigatório" location="top">
                    <template v-slot:activator="{ props }">
                    <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                    </template>
                </v-tooltip></label>
                </div>
                <div class="form-input">
                <input
                    type="text" 
                    required
                    class="form-control"
                    v-model="title"
                    placeholder="Meu Site"
                />
                <small>Digite o título do seu site.</small>
                </div>
            </div>
        </div>
        <div class="col-form-inner col-12 col-md-12 col-lg-12">
            <div class="form-group">
            <div class="form-label">
                <label>Tipo do Layout<v-tooltip text="Selecione o estado" location="top">
                <template v-slot:activator="{ props }">
                    <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                </template>
                </v-tooltip></label>
            </div>
            <div class="form-input">
                <select
                class="form-control"
                v-model="typeLayout"
                >
                <option value="" disabled selected>Selecione o tipo</option>
                <option value="1">Clean</option>
                <option value="2">Dark</option>
                </select>
                <small class="small">
                Selecione o tipo de sistema 1 - Weeduc e 2 - Weflix.
                </small>
            </div>
            </div>
        </div>
        <div class="col-form-inner col-12 col-md-12 col-lg-12">
            <div class="form-group">
            <div class="form-label">
                <label>Tipo do Layout Portal<v-tooltip text="Selecione o estado" location="top">
                <template v-slot:activator="{ props }">
                    <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                </template>
                </v-tooltip></label>
            </div>
            <div class="form-input">
                <select
                class="form-control"
                v-model="typeLayoutPortal"
                >
                <option value="" disabled selected>Selecione o tipo</option>
                <option value="1">Clean</option>
                <option value="2">Dark</option>
                </select>
                <small class="small">
                Selecione o tipo de sistema 1 - Weeduc e 2 - Weflix.
                </small>
            </div>
            </div>
        </div>
        <div class="col-form-inner col-12 col-md-12 col-lg-12">
            <div class="form-group">
                <div class="form-label">
                <label>E-mail<v-tooltip text="Campo obrigatório" location="top">
                    <template v-slot:activator="{ props }">
                    <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                    </template>
                </v-tooltip></label>
                </div>
                <div class="form-input">
                <input
                    type="text" 
                    required
                    class="form-control"
                    v-model="emailSite"
                    placeholder="exemplo@dominio.com"
                />
                <small>Digite o e-mail do seu site.</small>
                </div>
            </div>
        </div>
        <div class="col-form-inner col-12 col-md-12 col-lg-12">
            <div class="form-group">
                <div class="form-label">
                <label>Telefone<v-tooltip text="Campo obrigatório" location="top">
                    <template v-slot:activator="{ props }">
                    <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                    </template>
                </v-tooltip></label>
                </div>
                <div class="form-input">
                <input
                    type="text" 
                    required
                    class="form-control"
                    v-model="phoneSite"
                    placeholder="Digite o telefone"
                    v-mask="'(##) #####-####'"
                />
                <small>Digite o telefone do seu site.</small>
                </div>
            </div>
        </div>
    </div>
    <div class="col-form-inner col-6 col-md-6 col-lg-6">
        <div class="col-form-inner col-12 col-md-12 col-lg-12 textarea">
            <div class="form-group">
            <div class="form-label">
                <label>Descrição<v-tooltip text="Campo obrigatório" location="top">
                <template v-slot:activator="{ props }">
                    <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                </template>
                </v-tooltip></label>
            </div>
            <div class="form-input"> 
                <textarea v-model="description"></textarea>
                <small>Nome comercial do seu curso, uso externo.</small>
            </div>
            </div>
        </div>
        <div class="col-form-inner col-12 col-md-12 col-lg-12 textarea">
            <div class="form-group">
            <div class="form-label">
                <label>Palavras Chaves<v-tooltip text="Campo obrigatório" location="top">
                <template v-slot:activator="{ props }">
                    <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                </template>
                </v-tooltip></label>
            </div>
            <div class="form-input"> 
                <textarea v-model="keywords"></textarea>
                <small>Nome comercial do seu curso, uso externo.</small>
            </div>
            </div>
        </div>
        <div class="col-form-inner col-12 col-md-12 col-lg-12">
            <div class="form-group">
            <div class="form-label">
                <label>Banner Principal</label>
                <small>Insira uma imagem maior que 250x250 pixels</small>
            </div>
            <div class="form-file">
                <div class="file-wrapper primary">
                <img
                :src="pathImagePreview? pathImage : logo"
                alt="Logo"
                v-show="pathImage || logo"
                style="width: 250px;"
                />
                <span class="thumbnail">
                    <span class="icon">
                    <svg
                        width="46"
                        height="39"
                        viewBox="0 0 46 39"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        d="M45 19.9308L36.7274 12.3475C36.1595 11.827 35.2948 11.8047 34.7009 12.2953L18.824 25.411C18.3196 25.8277 17.6074 25.8812 17.0464 25.5446L11.1009 21.9773C10.5893 21.6703 9.94666 21.6856 9.45024 22.0166L1 27.6501"
                        stroke="#9D9FA3"
                        stroke-width="1.25714"
                        ></path>
                        <rect
                        x="1.00049"
                        y="1.40454"
                        width="43.9461"
                        height="36.1909"
                        rx="2.58507"
                        stroke="#9D9FA3"
                        stroke-width="1.25714"
                        ></rect>
                        <circle
                        cx="14.8948"
                        cy="10.6676"
                        r="3.71629"
                        stroke="#9D9FA3"
                        stroke-width="1.25714"
                        ></circle>
                    </svg>
                    </span>
                    <span class="text">
                    Clique aqui para selecionar a imagem
                    </span>
                    <LoaderImage :hasReady="loaderImage"/>
                </span>
                <input
                    class="form-control"
                    type="file"
                    @change="updateFilePathLogo"
                    accept=".png, .jpg, .jpeg, .webp"
                />
                </div>
            </div>
            </div>
        </div>
    </div> -->


            </div>
            <div class="actionsSave">
              <button class="btnSave" @click="createNewCompany">
                Salvar
              </button>
            </div>
          </div> 
        </div>
      </div>
    </div>
    <Loader :hasReady="hasReady"/>  
    <Footer/>
  </div>
</template>

<script>
  import Sidebar from '@/components/Weflix/Sidebar-SuperAdmin.vue'
  import Footer from '@/components/Admin/Footer/footerSuperAdmin.vue'
  import { mapState, mapActions } from 'vuex';
  import ApiCep from '@/utils/cep.js';
  import superAdmin from '@/utils/superAdmin';
  import Loader from '@/components/Weflix/Loader.vue';


  export default {
    data() {
      return {
        tradeName: '',
        bank: '',
        branch: '',
        digitBranch: '',
        account: '',
        split: 0,
        loaderImage: false,
        idFiscal: '',
        county: '',
        address: '',
        addressNumber: '',
        addressComplement: '',
        city: '',
        neighborhood: '',
        state: '',
        name: '',
        postcode: '',
        imagePath: '',
        branch: '',
        digitBranch: '',
        account: '',
        videoTimeTotal: '',
        email: '',
        phone: '',
        percentualClient: '',
        transactionValue: '',
        url: '',
        url1: '',
        url2: '',
        typeSystem: 1,
        emailRepresentative: '',
        nameRepresentative: '',
        professional_occupation: '',
        birthdate: '',
        idRepresentative: '',
        title: '',
        emailSite: '',
        phoneSite: '',
        typeLayout: 0,
        urlTemporary: '',
        hasValidDomain: null,
        hasReady: true
      }
    },
    components: {
      Sidebar,
      Footer,
      Loader
    },
    computed: {
      ...mapState(['token']),
    },
    methods: {
        ...mapActions(['storeCurrentCompanyCreated']),
      async createNewCompany() {
        this.hasReady = false;
        const payload ={
        "company": {
            "idFiscal": this.idFiscal.replace(/[^\d]/g, ''),
            "name": this.name,
            "country": "Brasil", 
            "county": this.county,
            "city": this.city,
            "address": this.address,
            "addressNumber": this.addressNumber,
            "addressComplement": this.addressComplement,
            "postcode": this.postcode.replace(/[^\d]/g, ''),
            "testPlan": 1,
            "typeSystem": this.typeSystem,
            "anticipation": 1,
            "videoComments": 1,
            "cartStatus": 1,
            "intallmentCheckout": 1,
            "color": "#00FF00",
            "imagePath": this.imagePath,
            "imageFinalPath": this.imagePath,
            "bank": "033",
            "branch": this.branch,
            "digitBranch": this.digitBranch,
            "account": this.account,
            "digitAccount": "8",
            "videoTimeTotal": this.videoTimeTotal,
            "videoTimeUsed": 0,
            "idFolderVideoteca": 1001,
            "tradeName": this.tradeName,
            "email": this.email,
            "phone": this.phone.replace(/[^\d]/g, ''),
            "percentualClient": this.percentualClient,
            "transactionValue": this.transactionValue.replace(',','.'),
            "neighborhood": this.neighborhood,
            "split": this.split ? 1 : 0,
        },
        "representative": {
            "name": this.nameRepresentative,
            "email": this.emailRepresentative,
            "document": this.idRepresentative.replace(/[^\d]/g, ''),
            "birthdate": this.birthdate,
            "professional_occupation": this.professional_occupation
        }
        }
        console.debug(payload);
        try {
          const response = await superAdmin.createCompany(this.token, payload);
          this.hasReady = true;
          this.storeCurrentCompanyCreated({currentCompanyCreated: response});
          this.$router.push('/personalizar-empresa/'+response.company.id);
        } catch (error) {
          console.error(error);
          this.hasReady = true;
        }
      },
    setPageTitle() {
      document.title ='Tutor -Super Admin';
    },
    setFavicon() {
      const favicon = document.querySelector('link[rel="icon"]');
      if (!favicon) {
        const newFavicon = document.createElement('link'); 
        newFavicon.rel = 'icon';
        newFavicon.href = this.imagePath || '/favicon-tutor.ico';
        
        document.head.appendChild(newFavicon);
      } else {
        favicon.href = this.imagePath || '/favicon-tutor.ico';
      }
    },
    async getCep(){
        if(this.postcode.length >= 7){
            try {
                const response = await ApiCep.getDataCep(this.postcode);
                this.county = response.uf;
                this.address = response.logradouro;
                this.addressNumber = response.numero;
                this.addressComplement = response.complemento;
                this.city = response.localidade;
                this.neighborhood = response.bairro;
            } catch (error) {
              
            }
        }
    },

    },
    mounted() {
      this.setPageTitle();
      this.setFavicon();
    }
  }
</script>

<style>
  .flex {
    display: flex;
    align-items: flex-start;
    gap: 0px;
    flex-wrap: wrap;
  }
  .dashboard {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #eef0fc;
    margin: 0 auto;
    position: relative;
  }   
  .content.dashboard {
    background-color: transparent;
    margin: 0;
    width: 100%;
    padding: 10px 10px;
    height: 100%;
  }
  .col-form-inner {
    padding: 0px;
}
.form-group {
    margin: 5px 10px !important;
}
textarea {
    height: 120px !important;
    font-family: "Poppins" !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 28px !important;
    resize: none !important;
    outline: none !important;
    padding: 0.5rem 0.75rem !important;
    color: #5f5f5f !important;
    background-color: #fff !important;
    border: 1px solid #dbdcde !important;
    box-shadow: none !important;
    width: 100% !important;
    border-radius: 5px !important;
}
button.btn_new {
    color: #250891;
    width: 20%;
    background-color: #ffffff;
    padding: 6px 10px;
    border-radius: 5px;
    transition: all 500ms ease-in-out;
    font-size: 18px;
    border: 1px solid #250891;
}
.content.dashboard.superAdmin {
    margin-bottom: 70px;
}
hr {
    width: 95% !important;
    margin: 25px auto !important;
}
span.iconCopy {
    position: absolute;
    top: 10px;
    right: 5px;
    cursor: pointer;
    z-index: 9;
}
svg.svg-inline--fa.fa-check {
    color: green;
}
svg.svg-inline--fa.fa-xmark {
    color: white;
}
.container {
    padding: 10px;
}
</style>
<style scoped>
@media (max-width: 600px) {
  .col-2 {
    width: 100%;
  }
  .col-3 {
    width: 100%;
  }
  .col-4 {
    width: 100%;
  }
  .col-8 {
    width: 100%;
  }
}
</style>