import api from './api';

export default {
    async getDataUser(userId, accessToken) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await api.get(`/user/${userId}`, { headers });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados do usuário:', error);
            throw error;
        }
    },

    async getAllUser(accessToken, page){
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/user?page=${page}&size=100`, {
                method: 'GET',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados do usuário:', error);
            // throw error;
        }
    },
    async getAllUserWithPurchase(accessToken, page, payload){
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.post(`/user/order?page=${page}&size=100`, payload, {
                method: 'POST',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados do usuário:', error);
            // throw error;
        }
    },

    async getAllUserExport(accessToken){
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/user?page=1&size=99999`, {
                method: 'GET',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados do usuário:', error);
            // throw error;
        }
    },

    async CreateUser(accessToken, userData){
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.post('/user', userData, {
                headers,
            });
            return response;
        } catch (error) {
            console.error('Erro ao obter dados da criação do usuário:', error);
            throw error;
        }
    },
    async EditUser(payload , accessToken, id){
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.put(`/user/${id}`, payload, {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao editar usuário:', error);
            throw error;
        }
    },
    async changePassword(payload , accessToken){
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.post(`/user/change-password`, payload, {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao alterar senha:', error);
            throw error;
        }
    },
    async getUserStats(accessToken){
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/report/userstats`, { 
                headers ,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados das estatísticas do usuário:', error);
            throw error;
        }
    },
    async getUserByName(accessToken, payload){
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.post(`/user/name?size=100`, payload, { 
                headers ,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter usuário pelo nome:', error);
            throw error;
        }
    },
    async getUserId(id, accessToken){
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
                const response = await api.get(`/user/${id}`, {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter usuário:', error);
            throw error;
        }
    }
}