import api from './api';

export default {
    async getDataWebsite( payload) {
        try {
            const headers = {
            };
            const response = await api.post(`/ecommerce/website`, payload, { headers });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados do site:', error);
            throw error;
        }
    },
    async getDataContent( payload) {
        try {
            const headers = {
            };
            const response = await api.post(`/ecommerce/content`, payload, { headers });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados do Conteúdo do Site:', error);
            throw error;
        }
    },
    async getDataCourse( payload) {
        try {
            const headers = {
            };
            const response = await api.post(`/ecommerce/courses`, payload, { headers });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados dos Cursos:', error);
            // throw error;
        }
    },
    async getDataTestimonies(payload) {
        try {
            const headers = {
            };
            const response = await api.post(`/ecommerce/testimony`, payload, { headers });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados dos Depoimentos:', error);
            throw error;
        }
    },
    async getDataSocialMedia(payload) {
        try {
            const headers = {
            };
            const response = await api.post(`/ecommerce/social-media`, payload, { headers });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados das Redes Sociais:', error);
            throw error;
        }
    },
    async getDataCourseWebsite(payload) {
        try {
            const headers = {
            };
            const response = await api.post(`/ecommerce/course`, payload, { headers });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados do Curso:', error);
            throw error;
        }
    },
    async getDataUserEamil(payload, url) {
        try {
            const headers = {
            };
            const response = await api.post(`/ecommerce/finduser/${payload}`, url, { headers });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados do Usuário via Email:', error);
            // throw error;
        }
    },
    async getDataCupom(payload, idCompany) {
        try {
            const headers = {
            };
            const response = await api.get(`/ecommerce/cupom/${payload}/${idCompany}`, { headers });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados do cupom:', error);
            throw error;
        }
    },
    async purchase(payload) {
        try {
            const headers = {
                'Content-Type': 'application/json', 
            };
            const response = await api.post(`/ecommerce/payment`, payload,{ headers });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados da compra:', error);
            throw error;
        }
    },
    async checkPaymentPix(id){
        try {
            const headers = {
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/ecommerce/asaas/checkpayment/${id}`,{ headers });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados da compra:', error);
            throw error;
        }
    },
    async getLastupdate( payload) {
        try {
            const headers = {
            };
            const response = await api.post(`/ecommerce/lastupdate`, payload, { headers });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados do site:', error);
            throw error;
        }
    },
    
}
