<template>
    <section class="plans">
    <div class="titlelayoutLayout">
        <h3>Cupons de desconto
        </h3>
        <small>Crie cupons de desconto para atrair novos clientes, criar campanhas de marketing mais efetivas e estabelecer parcerias.</small>
        <button class="btnNew" @click="(()=>this.$router.push('/admin/novo-cupom'))">
            <fa v-if="isMobileIcon" icon="fa fa-plus"></fa>
            <span v-else>Novo Cupom</span>
        </button>
    </div>
    <div class="plan">
        <div class="itens">
                <div class="item couponItem" v-for="cupom,index in cupons" :key="index">
                    <div class="itemContainerRow couponItem">
                        <div class="infoItemPlan couponItem">
                            <div class="title">
                                <h3 :class="{ expired: isExpired(cupom.expired, cupom.status) }">{{cupom.code}}</h3>
                            </div>
                            <div class="description">
                                <p><strong>{{cupom.discount}}%</strong> em <strong>todos</strong> os planos</p>
                            </div>
                        </div>
                        <!-- <div class="sales">
                            <span><span style="font-weight: 900;">1</span> venda(s)</span>
                        </div> -->
                        <div class="expiration couponItem" :class="{ expired: isExpired(cupom.expired, cupom.status) }">
                            <span v-if="isExpired(cupom.expired, cupom.status)" >Vencido em</span>
                            <span v-else>Válido até</span>
                            <span :class="{ expired: isExpired(cupom.expired, cupom.status) }" style="font-weight: 900;">{{formatDate(cupom.expired)}}</span>
                        </div>
                        <div class="status couponItem" :class="{ expired: isExpired(cupom.expired, cupom.status) }">
                            <span v-if="cupom.status == 1">Status: <strong style="color: #160266;"> Ativo</strong></span>
                            <span v-else>Status: <strong style="color: #adadad;"> Inativo</strong></span>
                        </div>
                    </div>
                    <div class="actionsItem">
                        <button class="btnIcon" @click="(()=>this.$router.push(`/admin/editar-cupom/${cupom.id}`))"><fa icon="fa-pencil" /></button>
                        <!-- <button class="btnIcon"><fa icon="fa-trash" /></button> -->
                    </div>
                </div>
        </div>
    </div>
</section>

</template>
<script>
import Cupom from '@/utils/Weflix/cupom';
import { mapState } from 'vuex';

export default {
    data() {
        return {
            statusTrial: 'Desativado',
            isTrialActive: false,
            trialRange: [3, 3],
            modelValue:0,
            cupons: [],
            isMobileIcon: false,
            
        }
    },
    mounted() {
        this.checkScreenSize();
        window.addEventListener('resize', this.checkScreenSize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.checkScreenSize);
    },
    computed: {
        ...mapState(['token'])
    },
    methods: {
        changeStatusTrial() {
            this.statusTrial = this.isTrialActive ? 'Ativado' : 'Desativado'
        },
        async getCupons(){
            try {
                const response = await Cupom.getCupons(this.token);
                this.cupons = response.content;
            } catch (error) {
                console.error(error);
            }
        },
        formatDate(isoDate) {
            const date = new Date(isoDate);
            const day = String(date.getUTCDate()).padStart(2, '0');
            const month = String(date.getUTCMonth() + 1).padStart(2, '0');
            const year = date.getUTCFullYear();
            
            return `${day}/${month}/${year}`;
        },
        isExpired(expirationDate, status) {
            const currentDate = new Date();
            const expDate = new Date(expirationDate);
            return status == 0 || currentDate > expDate;
        },
        checkScreenSize() {
            this.isMobileIcon = window.innerWidth <= 600;
        },
    },
    created(){
        this.getCupons();
    }
}
</script>

<style scoped>
.plans{
    position: relative;
}
button.btnNew {
    color: #250891;
    width: 150px;
    background-color: #ffffff;
    padding: 6px 10px;
    border-radius: 5px;
    transition: all 500ms ease-in-out;
    font-size: 18px;
    border: 1px solid #250891;
    position: absolute;
    top:10px;
    right: 10px;
}
button.btnNew:hover {
    background-color: #160266;
    color: white;
}
.titlelayoutLayout {
    text-align: left;
    padding: 10px;
    border-bottom: 1px solid #e3e3e3;
    width: 100%;
    margin-bottom: 10px;
}
.item {
    background-color: #fbfbfb;
    padding: 9px 20px;
    border-radius: 5px;
    flex-direction: row;
    width: 100%;
    border: 1px dashed gray;
    align-items: center;
}
.title {
    display: flex;
    justify-content: left;
    gap: 10px;
    align-items: center;
}
.description {
    display: flex;
    gap: 5px;
    align-items: center;
}

.description p{
   margin: 0;
}
.itens {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}
.title h3 {
    margin: 0;
    font-weight: 600;
}
.actionsItem {
    display: flex;
    align-items: center;
    gap: 35px;
}
.title span {
    font-size: 13px;
    font-weight: 200;
    color: gray;
    line-height: 15px;
}
.description p {
    margin: 0;
    font-size: 15px;
    color: #707173;
}
.description span svg {
    font-size: 15px;
    color: black !important;
}
.description span svg path {
    fill: black !important;
}
.titlelayoutLayout.seondary {
    margin-top: 20px;
    position: relative;
}
.itemAtionCollections.form-check.form-switch {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    gap: 50px;
    align-items: center;
}
.expiration {
    display: grid;
}
@media (min-width: 601px) {
    .expiration {
        position: absolute;
        right: 25vw;
    }
}
</style>
<style >
.description span svg path {
    fill: #535353 !important;
}
.itemAtionCollections .form-check-input {
    margin-right: 1rem !important;
    cursor: pointer;
    flex-shrink: 0;
    width: 3rem;
    height: 1.rem;
    background-color: #d7d7d7;
    border: 1px solid #8d8d8d;
    box-shadow: none;
}
.v-slider-track__fill {
    background-color: rgb(37 8 145)!important;
}
.v-slider-thumb__surface.elevation-2 {
    background-color: #160266!important;
}
.v-slider-thumb__label {
    background-color: #250891 !important;
}
.v-card.v-theme--light.v-card--density-default.v-card--variant-elevated {
    padding: 15px 30px!important;
    box-shadow: none !important;
}
button.btnIcon.active svg path {
    fill: #e5c519 !important;
}
.trial p {
    color: gray;
    margin: 0;
}
.warning {
    text-align: left;
    padding: 0px 15px;
    background-color: #f6f6f6;
    width: 555px;
    padding: 6px;
    border-radius: 5px;
    border: 1px solid #e7e7e7;
}
.sales span, .expiration.expired span {
    color: #adadad;
}
.actionsItem svg path {
    transition: all 500ms ease-in-out;
}
.actionsItem svg:hover path {
    fill: #250891;
    transition: all 500ms ease-in-out;
}
.item.couponItem {
    display: flex;
    justify-content: space-between;
}
.itemContainerRow.couponItem {
    display: flex;
    gap: 2vw;
    align-items: center;
}
@media (max-width: 600px) {
    button.btnNew {
        padding: 1px 1px !important;
        max-width: 40px;
    }
    button.btnNew svg {
        color: #250891;
    }
    button.btnNew:hover svg {
        color: white;
    }
    .item.couponItem {
        gap: 0px;
    }
    .infoItemPlan.couponItem {
        display: flex;
        gap: 10px;
    }
    .expiration.couponItem {
        display: flex;
        gap: 5px;
    }
    .itemContainerRow.couponItem {
        display: flex;
        flex-direction: column;
        gap: 10px !important;
        align-items: baseline;
    }
    .infoItemPlan.couponItem {
        display: block;
        width: 55vw !important;
    }
}
h3.expired {
    color: gray;
}
.infoItemPlan.couponItem {
    display: block;
    width: 450px;
}
.status.couponItem span {
    display: block;
}
.plan {
    padding: 10px;
  }
</style>