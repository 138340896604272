<template>
    <div class="loginRegister" :class="openModalLogin? 'open':''">
        <div class="headerLoginRegister" >
            <span class="close" @click="toggleModal"><fa icon="fa-xmark"/>Fechar</span>
            <span v-show="typeLogin==2" @click="(()=>this.typeLogin=1)">Faça seu Login</span>
            <span v-show="typeLogin==1" @click="(()=>this.typeLogin=2)" v-if="mensageConfirmEmail==false">Crie uma conta</span>
        </div>
        <div class="login" v-show="typeLogin==1" @keydown.enter="login">
            <div class="titleLogin">
                Acesse sua conta
            </div>
            <div class="inputLogin">
                <v-text-field label="E-mail"  v-model="email" @input="verificarCampos"></v-text-field>
                <v-text-field label="Senha"  :type="typeInput" v-model="password" @input="verificarCampos">
                    <fa icon="fa-eye" @click="changeViewPassword" v-show="typeInput!='password'"/>
                    <fa icon="fa-eye-slash" @click="changeViewPassword" v-show="typeInput==='password'"/>
                </v-text-field>
            </div>
            <div class="erroLogin" v-if="erroLogin">
                <p style="color: red;">E-mail ou senha inválidos</p>
            </div>
            <div class="forgotPassword">
                <a href="#" @click="(()=>this.typeLogin=3)">Esqueci minha senha</a>
            </div>
            <div class="submitLogin">
                <button :class="hasReadyToLogin ? 'active': ''" @click="login">Entrar</button>
                <div class="createAccount">
                    <p>Não possui uma conta?<a href="#"  @click="(()=>this.typeLogin=2)">Criar Conta</a></p>
                </div>
            </div>
           
            
        </div>
        <div class="register" v-show="typeLogin==2" v-if="mensageConfirmEmail==false" @keydown.enter="createAccount">
            <div class="titleLogin">
               Cadastre-se
            </div>
            <div class="inputLogin">
                <v-text-field label="Nome Completo" variant="underlined" v-model="name"></v-text-field>
            </div>
            <div class="inputLogin">
                <v-text-field label="E-mail" variant="underlined" v-model="email"></v-text-field>
                <v-text-field label="CPF" variant="underlined" v-model="idNumber" v-mask="'###.###.###-##'"></v-text-field>
                <v-text-field label="Telefone" variant="underlined" v-model="phone" v-mask="'(##) #.####-####'"></v-text-field>
            </div>
            <div class="inputLogin">
                <v-text-field label="CEP" variant="underlined" v-model="cep" v-mask="'#####-###'" @input="getCep"   ></v-text-field>
                <v-text-field label="Rua" variant="underlined" v-model="street"></v-text-field>
                <v-text-field label="Número" variant="underlined" v-model="number"></v-text-field>
            </div>
            <div class="inputLogin">
                <v-text-field label="Cidade" variant="underlined" v-model="city"></v-text-field>
                <v-text-field label="Complemento" variant="underlined" v-model="addressComplement"></v-text-field>
                <v-text-field label="Bairro" variant="underlined" v-model="neighborhood"></v-text-field>
                <v-text-field label="Estado" variant="underlined" v-model="state"></v-text-field>
            </div>
            <div class="inputLogin">
                <v-text-field label="Senha" variant="underlined" :type="typeInput" v-model="password">
                    <fa v-show="typeInput==='password'" icon="fa-eye" @click="changeViewPassword"/>
                    <fa v-show="typeInput!='password'" icon="fa-eye-slash" @click="changeViewPassword"/>
                </v-text-field>
                <v-text-field label="Confirme sua senha" :type="typeInput" variant="underlined" v-model="rePassword">
                    <fa v-show="typeInput==='password'" icon="fa-eye" @click="changeViewPassword"/>
                    <fa v-show="typeInput!='password'" icon="fa-eye-slash" @click="changeViewPassword"/>

                </v-text-field>
            </div>
            <div class="erroLogin" v-if="erroEmail">
                <p style="color: red;">Erro: E-mail já cadastrado, por favor, tente outro e-mail.</p>
            </div>
            <div class="submitLogin">
                <button :class="hasReadyToLogin ? 'active': ''" v-if="typeLogin==1">Entrar</button>
                <button class="active" @click="createAccount" v-if="typeLogin==2">Criar conta</button>
            </div>
            
            <div class="createAccount">
                <p>Ao usar nossos serviços você automaticamente
                    concorda com os <a href="/termos-de-uso">Termos de uso.</a></p>
            </div>
        </div>
        <div class="forgotPasswordSection" v-show="typeLogin==3" @keydown.enter="forgotPassword">
            <div class="titleLogin">
                Recuperar Senha
            </div>
            <div class="inputLogin">
                <v-text-field label="E-mail" variant="underlined" v-model="email"></v-text-field>
            </div>
            <div class="submitLogin">
                <button class="active" @click="forgotPassword">Enviar</button>
            </div>
            <div class="createAccount">
                <p>Lembrou sua senha?<a href="#" @click="(()=>this.typeLogin=1)">Faça seu Login</a></p>
            </div>
        </div>
        <div class="confirmationEmail" v-if="mensageConfirmEmail==true">
            <h3>Você receberá um e-mail com um link de confirmação</h3>
            <p>Verifique sua caixa de entrada e sua caixa de SPAM caso não encontre o e-mail de confirmação.</p>
            <button class="backConfirmationEmail" @click="toggleModal">Voltar</button>
        </div>
    </div>
    <div class="backgroundLoginRegister"></div>
</template>

<script>
import apiLogin from '@/utils/Weflix/Website/index';
import apiWeflix from '@/utils/Weflix/Website/index';
import { mapActions, mapState } from 'vuex';
import apiCep from '@/utils/cep';

export default {
    data(){
        return{
            openModalLogin:false,
            typeLogin:1,
            hasReadyToLogin:false,
            typeInput:'password',
            email:'',
            password:'',
            city:'',
            name:'',
            cep:'',
            street:'',
            number:'',
            neighborhood:'',
            state:'',
            idNumber:'',
            email:'',
            addressComplement:'',
            reEmail:'',
            password:'',
            rePassword:'',
            mensageConfirmEmail:false,
            erroLogin:false,
            phone:'',
            baseUrl: process.env.NODE_ENV === 'development' ? process.env.VUE_APP_BASE_URL_HOMOLOGATION  : window.location.origin,
            erroEmail:false,
        }
    },
    props:{
       show:Boolean
    },
    computed:{
        ...mapState(['typeSystem'])
    },
    methods:{
        ...mapActions(['storeUserPortalWeflix']),
        toggleModal(){
           this.typeLogin = 1;
           this.mensageConfirmEmail = false;
           this.$emit('toggleModal');
        },
        changeViewPassword(){
            this.typeInput = this.typeInput == 'password' ? 'text' : 'password';
        },
        async login(){
            const aux = this.baseUrl.replace(/\/$/, '');

            const payload = {
                "email": this.email,
                "password": this.password,
                "domain": aux
            }
            try {
                const response = await apiLogin.Login(payload);
                if(response.user.type == 1 || response.user.type == 2){
                    alert('Usuário do tipo administrador não possui acesso ao portal, realize o login no portal de gestores.');
                    return;
                } else {
                    this.storeUserPortalWeflix({userPortalWeflix:response});
                    window.location.reload();
                }
            } catch (erro) {
                this.erroLogin = true;
                console.error('Erro ao fazer login:', erro);
            }
        },
        verificarCampos() {
            this.hasReadyToLogin = this.email.trim() !== '' && this.password.trim() !== '';
        },
        async createAccount(){
        
            const baseUrl = this.baseUrl.replace(/\/$/, '');
            
            if (!this.name || !this.email || !this.password || !this.rePassword || !this.phone || !this.idNumber || !this.state || !this.city || !this.street || !this.neighborhood || !this.number || !this.cep) {
                alert('Por favor, preencha todos os campos obrigatórios.');
                return;
            }

            if (this.password !== this.rePassword) {
                alert('As senhas não conferem.');
                return;
            }

            const payload = {
                name: this.name,
                email: this.email,
                password: this.password,
                phone: this.phone.replace(/[().-\s]/g,''),
                type: 0,
                status: 1,
                socialNumber: this.idNumber ? this.idNumber.replace(/[.-]/g, '') : null,
                idNumber: this.idNumber ? this.idNumber.replace(/[.-]/g, '') : null,
                country: "BR",
                county: this.state,
                city: this.city,
                address: this.street,
                neighborhood: this.neighborhood,
                addressNumber: this.number,
                addressComplement: this.addressComplement,
                postcode: this.cep.replace('-', ''),
                photo: "",
                photoFinal: "",
                url: baseUrl,
                firstLogin: 0,
                enabled: true,
                username: this.email,
            }
            
            try {
                const response = await apiWeflix.createAccount(payload);
                if(response.status == 201){
                    this.mensageConfirmEmail = true;
                } else {
                    this.erroEmail = true;
                }
            } catch (erro) {
                this.erroEmail = true;
                console.error('Erro ao criar usuário:', erro);
            }
        },
        async getCep(){
            try {
                const response = await apiCep.getDataCep(this.cep);
                this.city = response.localidade;
                this.state = response.uf;
                this.neighborhood = response.bairro;
                this.street = response.logradouro;
            } catch (error) {
                console.error('Erro ao buscar CEP:', error);
            }
        },
        async forgotPassword() {
            if (this.email.trim() === '') {
                alert('Por favor, insira seu e-mail.');
                return;
            }
            const payload = {
                "email": this.email,
                "url": this.baseUrl.replace(/\/$/, ''),
                "typeSystem": this.typeSystem
            }
            try {
                await apiLogin.forgotPassword(payload);
                alert('Um e-mail de recuperação de senha foi enviado para o seu endereço de e-mail.');
            } catch (error) {
                console.error('Erro ao solicitar recuperação de senha:', error);
                alert('Erro ao solicitar recuperação de senha. Por favor, tente novamente.');
            }
        }
    },
    watch:{
        openModalLogin(novoValor){
            document.body.style.overflow = novoValor ? 'hidden' : '';
        },
        show(novoValor){
            this.openModalLogin = novoValor;
        }
    },    
}
</script>

<style scoped>
.loginRegister {
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    opacity: 0;
    z-index: -1;
    transition: all 200ms ease-in-out;
}
.loginRegister.open {
    z-index: 103;
    opacity: 1;
}
span.close svg.svg-inline--fa.fa-xmark {
    color: gray;
}
.headerLoginRegister {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    color: gray;
}
.headerLoginRegister > span {
   cursor: pointer;
}
span.close {
    display: flex;
    gap: 15px;
    align-items: center;
    color: gray;
}
.login {
    max-width: 800px;
    margin: 0 auto;
    margin-top: 18%;
}
.register {
    max-width: 800px;
    margin: 0 auto;
    margin-top: 15%;
}
.forgotPasswordSection {
    max-width: 800px;
    margin: 0 auto;
    margin-top: 18%;
}
.inputLogin {
    display: flex;
    gap: 35px;
}
.titleLogin {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 30px;
}
.submitLogin button {
    color: rgb(255, 255, 255) !important;
    background-color: rgb(218, 223, 231) !important;
    padding: 18px 120px;
    margin-top: 5%;
    border-radius: 5px;
    font-size: 20px;
    font-weight: 700;
}
.submitLogin button.active {
    color: rgb(255, 255, 255) !important;
    background-color: rgb(0, 0, 0) !important;
}
.createAccount {
    transform: translateY(50px);
}
.createAccount p {
    font-size: 15px;
    color: gray;
}
.createAccount p a {
    margin-left: 5px;
    color: rgb(44, 158, 214);
    text-decoration: none;
    font-weight: 700;
}
.inputLogin svg.svg-inline--fa {
    color: gray;
    position: absolute;
    right: 0;
    cursor: pointer;
}
.register .createAccount {
    transform: translateY(100px);
    margin: 0 auto;
}
.confirmationEmail {
    max-width: 800px;
    margin: 0 auto;
    margin-top: 18%;
}
button.backConfirmationEmail {
    border: 1px solid black;
    background-color: white;
    padding: 5px 35px;
    border-radius: 5px;
    transition: all 500ms ease-in-out;
}
button.backConfirmationEmail:hover {
    border: 1px solid white;
    background-color: black;
    color: white;
}
.forgotPassword {
    margin-top: 10px;
    text-align: right;
}
.forgotPassword a {
    color: rgb(44, 158, 214);
    text-decoration: none;
    font-weight: 700;
}
@media (max-width: 600px) {
    .inputLogin{
        display: block;
        padding: 20px;
    }
    .register .inputLogin {
        padding: 0px 20px;
    }
    .register {
        max-width: 800px;
        margin: 0 auto;
        margin-top: 7%;
        overflow: scroll;
        height: 100vh;
    }
    .loginRegister.open {
        top: 60px;
    }
}
</style>
