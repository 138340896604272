<template>
    <section class="plans">
    <div class="password" v-if="hasReady">
        <div class="info-user-paswword myAccountFormUserLight">
            <div class="modal-body double">
              <div class="form-group">
                <div class="form-label">
                  <label>
                    Senha Atual
                    <v-tooltip text="Campo obrigatório" location="top">
                      <template v-slot:activator="{ props }">
                        <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                      </template>
                    </v-tooltip>
                  </label>
                </div>
                <div class="form-input">
                  <input v-model="oldPassword" type="password" class="form-control" placeholder="Senha Atual">
                </div>
              </div>
            </div>
            <div class="modal-body double">
              <div class="form-group">
                <div class="form-label">
                  <label>
                    Nova senha  <v-tooltip text="Campo obrigatório" location="top">
                      <template v-slot:activator="{ props }">
                        <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                      </template>
                    </v-tooltip>
                  </label>
                </div>
                <div class="form-input">
                  <input v-model="password" type="password" class="form-control" placeholder="Nova senha">
                </div>
              </div>
              <div class="form-group">
                <div class="form-label">
                  <label>
                    Confirme a nova senha  <v-tooltip text="Campo obrigatório" location="top">
                      <template v-slot:activator="{ props }">
                        <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                      </template>
                    </v-tooltip>
                  </label>
                </div>
                <div class="form-input">
                  <input v-model="rePassword" type="password" class="form-control" placeholder="Confirmar nova senha">
                </div>
              </div>
            </div>
          </div>
    </div>
  
</section>
<div data-v-41e9f2f5="" class="actionsSave"><button data-v-41e9f2f5="" class="btnSave" @click="updatePassword">Salvar</button></div>
<Loader :hasReady="hasReady" />
</template>
<script>
import { mapState } from 'vuex';
import User from '@/utils/Weflix/user';
import Loader from '@/components/Weflix/Loader';
export default {
    data() {
        return {
            oldPassword: '',
            password: '',
            rePassword: '',
            hasReady: true,
        }
    },
    components: {
        Loader
    },
    computed: {
        ...mapState(['token'])
    },
    methods: {
       async updatePassword(){
        if(this.password != this.rePassword ){
            alert('As senhas não conferem');
            return;
        }
        this.hasReady = false;
        const payload = {
            oldPassword: this.oldPassword,
            newPassword: this.password,
        }
        try {
            const response = await User.changePassword(payload, this.token);
            this.hasReady = true;
        } catch (error) {
            console.error(error);
            this.hasReady = true;
        }
    }  
    },
}
</script>

<style scoped>
.plans{
    position: relative;
}
button.btnNew {
    color: #250891;
    width: 150px;
    background-color: #ffffff;
    padding: 6px 10px;
    border-radius: 5px;
    transition: all 500ms ease-in-out;
    font-size: 18px;
    border: 1px solid #250891;
    position: absolute;
    top: 0;
    right: 0;
}
button.btnNew:hover {
    background-color: #160266;
    color: white;
}
.titlelayoutLayout {
    text-align: left;
    padding: 10px;
    border-bottom: 1px solid #e3e3e3;
    width: 100%;
    margin-bottom: 10px;
}
.item {
    background-color: #eef0f5;
    padding: 9px 20px;
    border-radius: 5px;
    flex-direction: row;
    width: 100%;
}
.title {
    display: flex;
    justify-content: left;
    gap: 10px;
    align-items: center;
}
.description {
    display: flex;
    gap: 5px;
    align-items: center;
}

.description p{
   margin: 0;
}
.itens {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}
.title h3 {
    margin: 0;
    font-weight: 600;
}
.actionsItem {
    display: flex;
    align-items: center;
    gap: 35px;
}
.title span {
    font-size: 13px;
    font-weight: 200;
    color: gray;
    line-height: 15px;
}
.description p {
    margin: 0;
    font-size: 15px;
    color: #707173;
}
.description span svg {
    font-size: 15px;
    color: black !important;
}
.description span svg path {
    fill: black !important;
}
.titlelayoutLayout.seondary {
    margin-top: 20px;
    position: relative;
}
.itemAtionCollections.form-check.form-switch {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    gap: 50px;
    align-items: center;
}
</style>
<style >
.description span svg path {
    fill: #535353 !important;
}
.itemAtionCollections .form-check-input {
    margin-right: 1rem !important;
    cursor: pointer;
    flex-shrink: 0;
    width: 3rem;
    height: 1.rem;
    background-color: #d7d7d7;
    border: 1px solid #8d8d8d;
    box-shadow: none;
}
.v-slider-track__fill {
    background-color: rgb(37 8 145)!important;
}
.v-slider-thumb__surface.elevation-2 {
    background-color: #160266!important;
}
.v-slider-thumb__label {
    background-color: #250891 !important;
}
.v-card.v-theme--light.v-card--density-default.v-card--variant-elevated {
    padding: 15px 30px!important;
    box-shadow: none !important;
}
button.btnIcon.active svg path {
    fill: #e5c519 !important;
}
.trial p {
    color: gray;
    margin: 0;
}
.warning {
    text-align: left;
    padding: 0px 15px;
    background-color: #f6f6f6;
    width: 555px;
    padding: 6px;
    border-radius: 5px;
    border: 1px solid #e7e7e7;
}
</style>