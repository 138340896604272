<template>
    <section class="attachment">
        <div class="itensAttachment">
            <div class="item" v-for="i in 6" :key="i"> 
                <span class="textDescriptionFile">Conteúdo_Video.png
                    <small>2.55 mb</small>
                </span>
               
                <button class="downloadFile"><fa icon="fa-download"/></button>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data(){
        return{
      
        }
    },
}
</script>

<style scoped>
section.attachment {
    max-width: 1500px;
    margin: 0 auto;
    margin-bottom: 100px;
}
.itensAttachment {
    padding: 15px 0px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}
.itensAttachment .item {
    width: 49%;
    border: none;
    padding: 0;
    display: flex;
    background-color: #2e2e2e;
    padding: 10px 10px;
    border-radius: 5px;
    position: relative;
    position: relative;
    justify-content: left;
}
span.textDescriptionFile {
    color: white;
    font-size: 14px;
    text-align: left;
}
button.downloadFile {
    position: absolute;
    top: 0;
    right: 0;
    width: 51px;
    background-color: #555555;
    height: 51px;
    cursor: pointer;
}
</style>
<style >
.item .downloadFile svg.svg-inline--fa.fa-download  path {
    fill: white!important;
}
</style>