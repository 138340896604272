<template>
    <footer>
        <div class="footer">
            <div class="col logo">
                <img :src="finalWebsite.logoFinal" :alt="finalWebsite.title"/>
            </div>
            <div class="col">
                <div class="titleFooter">
                    <h4>EXPLORE</h4>
                </div>
                    <ul>
                        <li>
                            <router-link to="/quem-somos">Sobre Nós</router-link>
                        </li>
                        <!-- <li>
                            <router-link to="/contato">Contatos</router-link>
                        </li> -->
                        <li>
                            <router-link to="/colecoes">Coleções</router-link>
                        </li>
                    </ul>
            </div>
            <div class="col">
                <div class="titleFooter">
                    <h4>DÚVIDAS?</h4>
                </div>
                    <ul>
                        <li>
                            <router-link to="/termos-de-uso">Termos de Uso</router-link>
                        </li>
                    </ul>
            </div>
            <div class="col socialmidia">
                <div class="titleFooter">
                    <h4>REDES SOCIAIS</h4>
                </div>
                <ul class="iconsFooter">
                    <li v-if="facebook.url">
                        <a :href="facebook.url">
                            <svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M512 256C512 114.6 397.4 0 256 0S0 114.6 0 256C0 376 82.7 476.8 194.2 504.5V334.2H141.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H287V510.1C413.8 494.8 512 386.9 512 256h0z"/></svg>
                        </a>
                    </li>
                    <li v-if="instagram.url">
                        <a :href="instagram.url">
                            <svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg>                        </a>
                    </li>
                    <li v-if="linkedin.url">
                        <a :href="linkedin.url">
                            <svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/></svg>                    
                        </a>
                    </li>
                    <li v-if="youtube.url">
                        <a :href="youtube.url">
                            <svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M549.7 124.1c-6.3-23.7-24.8-42.3-48.3-48.6C458.8 64 288 64 288 64S117.2 64 74.6 75.5c-23.5 6.3-42 24.9-48.3 48.6-11.4 42.9-11.4 132.3-11.4 132.3s0 89.4 11.4 132.3c6.3 23.7 24.8 41.5 48.3 47.8C117.2 448 288 448 288 448s170.8 0 213.4-11.5c23.5-6.3 42-24.2 48.3-47.8 11.4-42.9 11.4-132.3 11.4-132.3s0-89.4-11.4-132.3zm-317.5 213.5V175.2l142.7 81.2-142.7 81.2z"/></svg>
                        </a>
                    </li>
                    <li v-if="whatsapp.url">
                        <a :href="whatsapp.url">
                            <svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"/></svg>
                        </a>
                    </li>
                    <li v-if="tiktok.url">
                        <a :href="tiktok.url">
                            <svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z"/></svg>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="copyright">
            <div class="infoLeft">
                <p>Plataforma 2024 Weflix. Todos os direitos reservados </p>
            </div>
            <div class="infoRight">
                <p>Conteúdo 2024 Suporte Weflix. Todos os direitos reservados.</p>
            </div>
        </div>
    </footer>
</template>
<script>
import ApiWebsite from '@/utils/Weflix/Website/index';
import { mapState } from 'vuex';
export default {
    data(){
        return{
            facebook: '',
            instagram: '',
            youtube: '',
            linkedin: '',
            whatsapp: '',
            tiktok: '',
           baseUrl: process.env.NODE_ENV === 'development' ? process.env.VUE_APP_BASE_URL_HOMOLOGATION  : window.location.origin,

        }
    },
    methods:{
        async getSocialMedia(){
            const aux = this.baseUrl.replace(/\/$/, '');
                
          const payload = {
              "url": aux
          }
            const response = await ApiWebsite.getSocialMedia(payload);
            
            response.forEach(item => {
                switch(item.type) {
                    case 0:
                        this.facebook = item;
                        break;
                    case 1:
                        this.instagram = item;
                        break;
                    case 2:
                        this.youtube = item;
                        break;
                    case 3:
                        this.linkedin = item;
                        break;
                    case 4:
                        this.whatsapp = item;
                        break;
                    case 5:
                        this.tiktok = item;
                        break;
                }
            });
        }
    },
    created(){
        this.getSocialMedia();
    },
    computed: {
        ...mapState(['finalWebsite'])
    }
}
</script>
<style scoped>
.global_footer.adm {
    display: none;
}
footer {
  background-color: black;
  margin-top: 0px !important;
}
.footer {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    gap: 10px;
    padding-top: 100px;
}
.footer .col {
    width: 25%;
}
.col img {
    width: 200px;
}
.col ul {
   margin: 0;
   padding: 0;
}
.col ul li {
    list-style: none;
    text-align: left;
    margin: 31px 0px;
}
.col ul li a{
   color: #898989;
   text-decoration: none;
}
.titleFooter h4 {
    color: white;
    margin-bottom: 15px;
    font-size: 17px;
    font-weight: 700;
}
.titleFooter {
    text-align: left;
}
.copyright {
    border-top: 1px solid #3a3a3a;
    margin: 20px 0px;
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    height: 60px;
    max-width: 1200px;
    margin: 0 auto;
}
.infoLeft p, .infoRight p {
    color: #5a5a5a;
    font-size: 13px;
}
.col.logo {
    text-align: left;
}
svg.icon {
    width: 25px;
    height: 25px;
}
svg.icon path {
    fill: white;
}
ul.iconsFooter li {
    margin: 10px 0px !important;
}
ul.iconsFooter {
    display: flex;
    gap: 20px;
}
@media screen and (max-width: 600px) {
    .footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
    }
    .col.logo {
        width: 100%;
        text-align: center;
        padding: 15px 0;
    }
    .footer .col {
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
    }
    .col.socialmidia {
        margin-bottom: 15px;
    }
    .copyright {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 0;
        height: auto;
        width: 100%;
        text-align: center;
    }
    .infoLeft, .infoRight {
        margin-bottom: 10px;
    }
}
.col.logo img {
    width: 100px;
}
</style>