import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import 'bootstrap/dist/css/bootstrap.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';


import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faUserSecret, faPlus, faMinus, faVideo } from '@fortawesome/free-solid-svg-icons';

import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import '@mdi/font/css/materialdesignicons.min.css';

import VueSelect from "vue-select";

import VueApexCharts from "vue3-apexcharts";

import 'vuetify/styles';
import { createVuetify } from 'vuetify';

import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

import Multiselect from 'vue-multiselect'
import VuePlyr from 'vue-plyr';
import 'vue-plyr/dist/vue-plyr.css';

import VueTheMask from 'vue-the-mask';

import 'primeicons/primeicons.css';

library.add(faUserSecret, faPlus, faMinus, faVideo, fas, fab);

const vuetify = createVuetify({
    components,
    directives,
});

import Vuex from 'vuex';
import store from './store'; 


const app = createApp(App);
app.config.devtools = false; 

app.use(store);
app.use(VueTheMask);

// app.use(VueAnimXyz);

app.config.warnHandler = () => {};
app.component('VueApexCharts', VueApexCharts);
app.component('multiselect', Multiselect);
app.component("v-select", VueSelect)

app.use(router);
app.use(VuePlyr,{plyr:{}});
app.component('fa', FontAwesomeIcon);
app.component('QuillEditor', QuillEditor);
app.use(vuetify);
app.mount('#app');


// console.log = () => {};
// console.debug = () => {};
// console.error = () => {};
// console.warn = () => {};
// console.info = () => {};
  

// app.config.warnHandler = () => {};

// app.config.devtools = false;

// Sobrescrevendo console.warn para filtrar mensagens específicas
const originalConsoleWarn = console.warn;

console.warn = (message, ...args) => {
  if (message.includes('[DOM] Password field is not contained in a form') ||
      message.includes('[Violation]')) {
    return; // Ignora essas mensagens específicas
  }
  originalConsoleWarn(message, ...args);
};