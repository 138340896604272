<template>
    <div class="container admin-weflix-newPlan">
        <div class="titleContainer">
            <h3>Novo Plano</h3>
            <small>Escolha um nome e uma periodicidade de renovação de assinatura para este plano.
            </small>
        </div>
        <div class="contetContainerNewPlan">
            <div class="col-form-inner col-6 col-md-6 col-lg-6 ">
     
              <div class="col-form-inner col-12 col-md-12 col-lg-12">
                  <div class="form-group">
                    <div class="form-label">
                      <label>Nome da Plano<v-tooltip text="Campo obrigatório" location="top">
                        <template v-slot:activator="{ props }">
                          <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                        </template>
                      </v-tooltip></label>
                    </div>
                    <div class="form-input">
                      <input
                        type="text" 
                        required
                        class="form-control"
                        v-model="nomePlano"
                        placeholder="Plano Mensal - 2º Semestre"
                      />
                      <!-- <small class="alertText">Campo obrigatório e deve ser preenchido corretamente.</small> -->
                      <small>Nome comercial de sua coleção, uso externo.</small>
                    </div>
                  </div>
            </div>

            <div class="col-form-inner col-12 col-md-12 col-lg-12">
                <div class="form-group">
                  <div class="form-label">
                    <label>Valor<v-tooltip text="Campo obrigatório" location="top">
                      <template v-slot:activator="{ props }">
                        <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                      </template>
                    </v-tooltip></label>
                  </div>
                  <div class="form-input">
                    <input
                      type="text" 
                      required
                      class="form-control"
                      v-model="valorPlano"
                      placeholder="R$ 99,99"
                      @input="formatarValor"
                    />
                    <!-- <small class="alertText">Campo obrigatório e deve ser preenchido corretamente.</small> -->
                    <small>Nome comercial de sua coleção, uso externo.</small>
                  </div>
                </div>
          </div>
         
            </div>
          
            <div class="col-form-inner col-6 col-md-6 col-lg-6">
                <div class="col-form-inner col-12 col-md-12 col-lg-12">
                    <div class="form-group">
                      <div class="form-label">
                        <label>Frequência de Renovação<v-tooltip text="Campo obrigatório" location="top">
                          <template v-slot:activator="{ props }">
                            <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                          </template>
                        </v-tooltip></label>
                      </div>
                      <div class="form-input">
                        <select
                          required
                          class="form-control"
                          v-model="frequenciaRenovacao"
                        >
                          <option value="" disabled selected>Selecione a frequência</option>
                          <option value="1">Mensal</option>
                          <option value="2">Trimestral</option>
                          <option value="3">Semestral</option>
                          <option value="4">Anual</option>
                        </select>
                        <small>Escolha a frequência de renovação do plano.</small>
                      </div>
                    </div>
              </div>
              <div class="col-form-inner col-12 col-md-12 col-lg-12">
                <div class="form-group">
                  <div class="form-label">
                    <label>Definir como recomendado<v-tooltip text="Campo obrigatório" location="top">
                      <template v-slot:activator="{ props }">
                        <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                      </template>
                    </v-tooltip></label>
                  </div>
                  <div class="form-input" style="display: flex;flex-wrap: wrap;gap: 10px;">
                    <input data-v-508f1204="" class="form-check-input" type="checkbox" role="switch" v-model="recomendado">
                    <small>Um plano recomendado é tratado como principal na tela de pagamento. Ative como recomendado e o Plano terá mais destaque.</small>
                  </div>
                </div>
          </div>

              
            </div>
            <hr/>
            <div class="col-form-inner col-12 col-md-12 col-lg-12">
                <div class="paymentMethods">
                    <div class="creditCard">
                        <img src="@/assets/Weflix/cartao.png"/>
                        <div class="infoCreditCard">
                            <h4>Cartão de Crédito</h4>
                            <p>
                              Seus clientes poderão pagar 
                              <strong style="color: #160266;">R$ {{ valorPlanoFormatado }}</strong> 
                              {{ valorPlanoFormatado >= 50 
                                  ? 'parcelados em até ' 
                                  : 'em 1x' }} 
                              <strong v-if="valorPlanoFormatado >= 50" style="color: #160266;">
                                {{ Math.floor(valorPlanoFormatado / 50) }}x
                              </strong> 
                              <span v-if="valorPlanoFormatado >= 50">de</span> 
                              <strong v-if="valorPlanoFormatado >= 50" style="color: #160266;">
                                R$ {{ (valorPlanoFormatado / Math.floor(valorPlanoFormatado / 50)).toFixed(2) }}
                              </strong>
                            </p>
                                                    </div>
                    </div>
                    <hr/>
                    <!-- <div class="boleto">
                        <img src="@/assets/Weflix/boleto.png"/>
                        <div class="infoCreditCard">
                            <h4>Boleto</h4>
                            <p>Ative o pagamento em boleto para que seus assinantes possam adquirir o plano à vista.</p>
                        </div>
                        <div class="">
                            <span>Habilitar: </span>
                            <input data-v-2b0f9452="" data-v-508f1204="" class="form-check-input" type="checkbox" role="switch" v-model="habilitarBoleto">
                        </div>
                    </div>
                    <hr/>
                    <div class="boleto">
                      <img src="@/assets/Weflix/boleto.png"/>
                      <div class="infoCreditCard">
                          <h4>Pix</h4>
                          <p>Ative o pagamento em Pix para que seus assinantes possam adquirir o plano à vista.</p>
                      </div>
                      <div class="">
                          <span>Habilitar: </span>
                          <input data-v-2b0f9452="" data-v-508f1204="" class="form-check-input" type="checkbox" role="switch" v-model="habilitarPix">
                      </div>
                  </div> -->
                </div>
            </div>
        </div>
        <div class="actions newPlanWeflix">
            <button class="btn" @click="(()=>this.$router.push('/admin/pagamentos'))">Voltar</button>
            <button class="btn" @click="createPlan">Salvar</button>
        </div>
      </div>
      <!-- PopupConfirm -->
    <div class="pa-4 text-center">
        <v-dialog
        v-model="deleteItem"
        max-width="320"
        persistent
        >
        <v-card>
            <v-card-title class="headline">Atenção</v-card-title>
    
            <v-card-text>
            Deseja realmente excluir o vídeo {{ nameItem }}?
            </v-card-text>
    
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="cancel" text @click="closeDialog">Não</v-btn>
            <v-btn class="accept" text @click="closeDialog">Sim</v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
    </div>

</template>

<script>
import Plans from '@/utils/Weflix/plan';
import { mapState } from 'vuex';

export default {
    data(){
        return{
            deleteItem: false,
            nameItem: '',
            imagePreviewCover:'',
            nomePlano: '',
            valorPlano: '',
            valorPlanoFormatado: '',
            frequenciaRenovacao: '',
            recomendado: false,
            habilitarBoleto: false,
            habilitarPix: false
        }
    },
    computed: {
        ...mapState(['token'])
    },
    components: {
    },
    methods: {
        deleteVideo(id, name){
            this.deleteItem = true;
            this.nameItem = name;
        },
        closeDialog(){
            this.deleteItem = false;
        },
        formatarValor() {
            
            // Remove qualquer caractere que não seja número
            let numeroLimpo = this.valorPlano.replace(/[^\d]/g, '');
            
            // Converte para float dividindo por 100 para considerar os centavos
            let valorFormatado = (parseFloat(numeroLimpo) / 100).toFixed(2);
            
            // Substitui ponto por vírgula
            this.valorPlanoFormatado = valorFormatado.replace('.', '.');
            
            // Atualiza os valores
            this.valorPlano = valorFormatado.replace(',', '.');
        },
        async createPlan(){
            const payload = {
              "name": this.nomePlano,
              "planValue": parseFloat(this.valorPlano),
              "planType": this.frequenciaRenovacao,
              "recommended": this.recomendado ? 1 : 0,
              "card": 1,
              "billet": this.habilitarBoleto ? 1 : 0,
              "pix": this.habilitarPix ? 1 : 0,
              "discount": '',
              "status": 1
            }
            try {
                const response = await Plans.createPlan(this.token, payload);
                this.$router.push('/admin/pagamentos')

            } catch (error) {
                console.error('Erro ao criar plano:', error);

            }
        }

    },  
}
</script>

<style scoped>
@import url(./global.css);
.container {
    max-width: 100%;
 }
.titleContainer {
    display: block;
    text-align: left;
    padding: 0px 10px;
    border-bottom: 1px solid #e8e8e8;
    margin: 10px;
    padding-bottom: 10px;
    
}
.container {
    background-color: white;
    padding: 10px 0px;
    padding-bottom: 50px;
}
 .form-check-input:checked {
    background-color: #1AC7D0;
    border: 1px solid #1AC7D0;
}
.form-check-input {
    --bs-form-check-bg: var(--bs-body-bg);
    width: 1em;
    height: 1.3em!important;
    margin-top: 0.25em;
    vertical-align: top;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: var(--bs-form-check-bg);
    background-image: var(--bs-form-check-bg-image);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: var(--bs-border-width) solid var(--bs-border-color);
    -webkit-print-color-adjust: exact;
}
    
.form-check-input {
    --bs-form-switch-bg: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e);
    width: 2em;
    background-image: var(--bs-form-switch-bg);
    background-position: left center;
    border-radius: 2em!important;
    transition: background-position 0.15s ease-in-out;
}
.form-check-input:checked {
    background-position: right center;
    --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e");
}
.contetContainerNewPlan{
    display: flex;
    flex-wrap: wrap;    
}
hr {
    border-color: #b1b1b1;
    height: 1px;
    width: 100%;
    margin: 10px 10px;
}
.creditCard {
    display: flex;
    flex-wrap: wrap;
    padding: 5px 20px;
    align-items: center;
    text-align: left;
    gap: 21px;
}
.boleto {
    display: flex;
    flex-wrap: wrap;
    padding: 5px 20px;
    align-items: center;
    text-align: left;
    gap: 21px;
}
.boleto img {
    width: 22px;
    height: 30px;
}
.infoCreditCard h4 {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
}
.infoCreditCard {
    width: 80%;
}
.paymentMethods {
    padding-bottom: 50px;
}
.infoCreditCard p {
    margin: 0;
    color: gray;
}
@media (max-width: 600px) {
  .actions.newPlanWeflix {
      display: flex;
  }
  .admin-weflix-newPlan h3 {
    font-size: 20px;
    margin-bottom: 5px;
  }
  .admin-weflix-newPlan small {
    font-size: 12px;
    color: #666;
  }
  .admin-weflix-newPlan .contetContainerNewPlan {
    padding: 0px 10px;
  }
  .admin-weflix-newPlan .actions.newPlanWeflix .btn {
    width: 100%;
  }
  .admin-weflix-newPlan .paymentMethods {
    padding: 10px 0px;
  }
}
</style>