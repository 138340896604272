import api from '../api';

export default {

    // getDataLayoutSection
    async getLayoutData( accessToken) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/manager/layout`,  {
                method: 'GET',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao gerar informações do layout do site:', error);
                throw error;
        }
    },
    async putLayoutData( accessToken, payload) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.put(`/manager/layout`, payload ,  {
                method: 'PUT',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao editar informações do layout do site:', error);
                throw error;
        }
    },
 }

