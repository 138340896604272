import api from '../api';

export default {
    async getDataQuizQuestion(accessToken, id) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/portal/question/quiz/${id}`,  {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao carregar as notificações:', error);
                throw error;
        }
    },
    async submitAnswers(accessToken, payload, id) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.post(`/portal/quiz/answer/${id}`, payload , {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao carregar as notificações:', error);
                throw error;
        }
    },
    
}

