<template>
    <div class="globalViewNotification" :class="{ 'dark': this.dark }">
        <div class="title">
            <h3>Notificações</h3>
        </div>
        <div class="intenNotification" v-if="data.length > 0">
            <v-expansion-panels variant="accordion" v-model="active">
                <v-expansion-panel v-for="item in data" :key="item">

                  <v-expansion-panel-title expand-icon="mdi-menu-down" >
                    <div class="title_accordion">
                      <span>{{item.title}}</span>
                      <span class="count">10/10/2024</span>
                    </div>
                  </v-expansion-panel-title>
            
                  <v-expansion-panel-text class="content">
                    <div class="" v-html="item.description"></div>
                    <!-- <div class="autor">
                        <span class="logo">SL</span>
                        <span class="name">Samuel Linkon</span>
                        <span class="date">10/10/2024</span>
                    </div> -->
                  </v-expansion-panel-text>
        
                </v-expansion-panel>
              </v-expansion-panels>
        </div>
        <div class="noNotification" v-else>
            <h3>Nenhuma notificação</h3>
        </div>
    </div>
    
</template>

<script>
import api from '@/utils/portal/notification.js'
import { mapState } from 'vuex';

export default {
    data(){
        return{
            active: 0,
            data:[]
        }
    },
    props:{

    },
    computed:{
        ...mapState(['token','dark'])
    },
    methods:{
        async getDataNotification(){
            try {
                const response = await api.getDataNotifications(this.token);
                this.data =  response;
            } catch (error) {
                console.error('Erro na listagem da notificações')
            }   
        }
    },
    created() {
        this.getDataNotification();
    },
}
</script>

<style >
.globalViewNotification {
    background-color: #f9f9f9fc;
    height: calc(100% - 120px);
    padding: 50px 100px;
}
.globalViewNotification .intenNotification {
    padding: 20px;
    border-radius: 5px;
}
.globalViewNotification .v-expansion-panels--variant-accordion > .v-expansion-panel {
    margin-top: 0;
}
.globalViewNotification .v-expansion-panel__shadow {
    box-shadow: none;
}
.globalViewNotification .v-expansion-panel .v-expansion-panel__shadow {
    display: none;
}
.globalViewNotification .v-expansion-panel {
    margin: 10px 0px;
}
.globalViewNotification .v-expansion-panel.v-expansion-panel--active span.count {
    display: none;
}
.globalViewNotification .title_accordion {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.globalViewNotification  span.count {
    font-size: 10px;
    color: gray;
}
.globalViewNotification  .v-expansion-panel-text__wrapper {
    color: gray;
    text-align: left;
}
.globalViewNotification  .title {
    padding: 0px 20px;
    font-weight: 700;
}
.globalViewNotification  .title_accordion span {
    font-weight: 700;
}
.autor {
    margin-top: 50px;
    display: flex;
    align-items: baseline;
}
span.logo {
    width: 30px;
    height: 30px;
    position: inherit;
    display: block;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: #3b2d70;
    margin-right: 10px;
}
span.name {
    margin-top: auto;
    margin-right: 10px;
    margin-left: inherit;
    font-weight: 600;
}
span.date {
    font-size: 10px;
    color: gray;
}
.globalViewNotification.dark {
    background-color: black;
    color: white;
}
.globalViewNotification.dark .title {
    color: white;
}
.globalViewNotification.dark  .v-expansion-panel.v-expansion-panel {
    background-color: #373737;
    color: white;
}
.globalViewNotification.dark .v-expansion-panel-text__wrapper {
    text-align: left;
    color: white;
}
.v-expansion-panel-text__wrapper {
    color: gray;
}
@media (max-width: 600px) {
    .globalViewNotification {
        padding: 10px;
    }
}
.globalViewNotification  {
  min-height: 88vh;
}
.noNotification h3 {
    color: gray;
    font-size: 16px;
}
.noNotification  {
    margin-top: 50px;
}
</style>