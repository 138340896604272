<template>
    <Header @toggleModal="toggleOpenDialog" @toggleModalSubscribe="toggleOpenSubscribe"/> 
    <section class="about">
        <div class="contentAbout">
            <div class="text">
                 <img :src="logoAbout"/>
                <h1>{{ titleAbout }}</h1>
                <p>{{ descriptionAbout }}</p>
            </div>
        </div>
    </section>
    
    <Footer/>

    <Login :show="openDialogLogin" @toggleModal="toggleOpenDialog"/>
    <Subscribe :show="openDialogSubscribe" @toggleModal="toggleOpenSubscribe"/>
</template>

<script>
import Header from "@/components/Weflix/Hedaer.vue"
import Footer from '@/components/Weflix/Footer.vue'
import Login from '@/components/Weflix/Login.vue'
import Subscribe from '@/components/Weflix/Subscribe.vue'
import { mapState, mapActions } from 'vuex';
export default {
    data(){
        return{
            openDialogLogin:false,
            openDialogSubscribe:false,
            descriptionAbout:'',
            titleAbout:'',
            logoAbout:''
        }
    },
    computed:{
        ...mapState(['finalWebsite']),
    },
    components:{
        Header,
        Footer,
        Login,
        Subscribe
    },
    methods:{
        toggleOpenDialog(){
            this.openDialogLogin = !this.openDialogLogin;
        },
        toggleOpenSubscribe(){
            this.openDialogSubscribe = !this.openDialogSubscribe;
        },
    }, 
    created(){
        this.titleAbout = this.finalWebsite.title;
        this.descriptionAbout = this.finalWebsite.about;
        this.logoAbout = this.finalWebsite.logoFinal;
    }
}
</script>

<style scoped>
section.about {
    background-color: black;
    padding-top: 60px;
}
.contentAbout {
    max-width: 1500px;
    margin: 0 auto;
    min-height: 51vh;
}
.contentAbout .text p, .contentAbout .text h1{
    margin: 0;
    color:#fff;
    margin: 10px 0px;
}
.contentAbout .text h1{
    font-size: 35px;
    font-weight: 700;
}
.text {
    text-align: left;
    padding: 20px 0px;
}
.text img {
   width: 200px;
   margin-bottom: 25px;
}
@media (max-width: 600px) {
    .text {
        padding: 20px 20px;
    }
}
</style>
