<template>
    <section class="colletions">
        <div class="itensColletions moreVideos">
            <div class="item" v-for="video in limitedVideos" :key="video.id" @click="goToVideo(video.id, video.sample)">
                <!-- <img src="../../assets//Weflix/bg-video.jpg"/> -->
                <img v-if="video.finalVideoThumbnail == null" src="@/assets/Weflix/default-video-thumbnail.jpg" alt="Imagem padrão">
                <img v-else :src="video.finalVideoThumbnail"/>
                <fa icon="lock" class="lockIcon" v-if="userPortalWeflix && userPortalWeflix.user && (userPortalWeflix.user.orderStatus != 1 && userPortalWeflix.user.orderStatus != 4)"/>
                <span class="textPrivateVideo" v-if="userPortalWeflix && userPortalWeflix.user && (userPortalWeflix.user.orderStatus != 1 && userPortalWeflix.user.orderStatus != 4)">Conteúdo Exclusivo</span>
                <div class="sampleFlag videoPlayer" v-if="video.sample === 1">
                    <span>DEGUSTAÇÃO</span>
                </div>
                <div class="degradeCarousel__item"></div>
                <div class="descriptionVideo">
                    <h4>{{video.title}}</h4>
                </div>
                <div class="degradeItem"></div>
                <small class="video-time player">{{ formatarTempo(video.videoTime) }}</small>
            </div>
        </div>
    </section>
</template>

<script>
import { mapState } from 'vuex'
export default {
    data(){
        return{
      
        }
    },
    props:{
        videos: {
            type: Array,
            required: true
        }
    },
    computed:{
        ...mapState(['userPortalWeflix', 'finalWebsite']),
        limitedVideos() {
            return this.videos.slice(0, 10);
        }
    },
    watch: {
        videos: {
            handler(newVideos) {
            },
            deep: true
        }
    },
    methods:{
        goToVideo(id, sample){
            if(this.userPortalWeflix){
                if(sample === 1){
                    window.location = `/player/${id}`
                } else if(this.userPortalWeflix.user && this.userPortalWeflix.user.orderStatus === 1){
                    window.location = `/player/${id}`
                } else {
                    this.$emit('toggleModalSubscribe')
                }
            } else {
                this.$emit('toggleModal')
            }
        },
        formatarTempo(tempo) {
            console.debug(tempo);
            const horas = Math.floor(tempo / 3600);
            const minutos = Math.floor((tempo % 3600) / 60);
            return `${horas}:${minutos.toString().padStart(2, '0')}`;
        }
    },
}
</script>

<style scoped>
.itensColletions {
    display: flex;
    flex-wrap: wrap;
    gap:20px 10px;
    margin-bottom: 100px;
    justify-content: space-between;
    padding: 0 15px;
}
.item {
    display: block;
    position: relative;
    border: none;
    width: 32.5%;
    text-align: left;
    padding: 0;
    cursor: pointer;
}
section.colletions {
    max-width: 1500px;
    margin: 0 auto;
}
.item img {
    width: 100%;
    border-radius: 10px;
}
.video-time.player {
    position: absolute;
    bottom: 25px;
    right: 5px;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 2px 4px;
    border-radius: 3px;
    font-size: 12px;
}
@media (max-width: 600px) {
    .video-time.player {
        bottom: 27px;
        right: 5px;
    }
}
.degradeItem {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #0000002b;
    height: 100%;
    opacity: 0;
    transition: all 0.5s;
}
.item:hover .degradeItem, .item:hover span.textPrivateVideo {
    opacity: 1;
}
@media (max-width: 600px) {
    .itensColletions {
        gap:20px 5px;
        padding: 0 10px;
        margin: 0;
    }
    .item {
        width: 44vw;
    }
}
.itensColletions.moreVideos > .item {
    background-color: #000000;
}
.itensColletions.moreVideos .descriptionVideo h4 {
    color: white;
}
.descriptionVideo h4{
    width: 100%;
    font-size: 11px;
}
.sampleFlag.videoPlayer {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: v-bind('finalWebsite?.color');
    padding: 3px 8px;
    border-radius: 3px;
    z-index: 7;
    bottom: auto;
    right: auto;
}
.sampleFlag span {
    color: white;
    font-size: 11px;
    font-weight: bold;
}
.itensColletions.moreVideos .item {
    display: block;
    position: relative;
    border: none;
    width: 48%;
    text-align: left;
    padding: 0;
    cursor: pointer;
}
.itensColletions.moreVideos .item img{
    border-radius: 5px;
}
</style>
