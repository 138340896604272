<template>
    <div class="collections siteWeflix">
        <div class="titleCollections">
            <h3 :style="{color: finalWebsite?.color}">Cursos</h3>
        </div>
        <!-- Desktop View -->
        <div class="itensVideoCollections desktop-view" v-if="!mobile">
            <Carousel
                :items-to-show="2.6"
                :wrap-around="true"
                :snap-align="'start'"
                :transition="1000"
                :items-to-scroll="1"
                :autoplay="2000"
            >
                <Slide v-for="slide in collections || []" :key="slide">
                    <div class="carousel__item" @click="toggleModalSubscribe(slide.id)">
                        <img v-if="slide.imageFinalPath == null" src="@/assets/Weflix/default-video-thumbnail.jpg" alt="Imagem padrão">
                        <img v-else :src="slide.imageFinalPath"/>                             
                        <div class="descriptionVideo">
                            <div class="descriptionVideoContentLeft">
                                <h4 style="margin: 0;">{{slide.comercialName}}</h4>
                                <small>{{formatarDescricao(slide.shortDescription)}}</small>
                            </div>
                            <div class="descriptionVideoContentRight" v-if="!userPortalWeflix">
                                <fa icon="lock"/>
                            </div>
                        </div>
                    </div>
                </Slide>
                <template #addons>
                    <Navigation />
                </template>
            </Carousel>
        </div>

        <!-- Mobile View -->
        <div class="itensVideoCollections mobile-view" v-else>
            <div v-for="(slide) in collections.slice(0,4) || []" :key="slide" class="mobile-course-item" @click="toggleModalSubscribe(slide.id)">
                <div class="carousel__item">
                    <img v-if="slide.imageFinalPath == null" src="@/assets/Weflix/default-video-thumbnail.jpg" alt="Imagem padrão">
                    <img v-else :src="slide.imageFinalPath"/>                             
                    <div class="descriptionVideo">
                        <div class="descriptionVideoContentLeft">
                            <h4 style="margin: 0;">{{slide.comercialName}}</h4>
                            <small>{{formatarDescricao(slide.shortDescription)}}</small>
                        </div>
                        <div class="descriptionVideoContentRight">
                            <fa icon="lock"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Carousel, Navigation, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
import ApiWebsite from '@/utils/Weflix/Website/index';
import { mapState } from 'vuex';

export default {
    data(){
        return{
            collections: [],
            mobile:false,
           baseUrl: process.env.NODE_ENV === 'development' ? process.env.VUE_APP_BASE_URL_HOMOLOGATION  : window.location.origin,

            breakpoints: {  
                1024: {
                    itemsToShow: 3.6,
                    snapAlign: 'start',
                },
    },
        }
    },
    computed:{
        ...mapState(['userPortalWeflix', 'finalWebsite']),
    },
    methods:{
        formatarDescricao(descricao) {
            if (!descricao) return '';
            return descricao.length > 60 ? descricao.substring(0, 60) + '...' : descricao;
        },
        toggleModalSubscribe(id){
            this.$router.push(`/colecoes-videos/themeLight/${id}`)
        },
        async getCollections(){
            const aux = this.baseUrl.replace(/\/$/, '');
          
          const payload = {
              "url": aux
          }
            try {
                const response = await ApiWebsite.getCollections(payload, 0);
                this.collections = response.content.sort((a, b) => a.order - b.order);
                console.debug(response);
            } catch (error) {
                console.error(error);
            }
        },
        itemsToShow(itemCount) {
            if (this.mobile) return 1;
            return itemCount === 1 ? 1 : Math.min(itemCount, 3.6);
        },
        shouldWrapAround(itemCount) {
            return !this.mobile && itemCount > 1;
        },
        snapAlign(itemCount) {
            if (this.mobile || itemCount === 1) return 'center';
            return 'start';
        },
        handleResize() {
            this.mobile = window.innerWidth <= 768;
        },
        formatarTempo(tempo) {
            const horas = Math.floor(tempo / 3600);
            return `${horas}h`;
        }
    },
    created(){
        this.getCollections();
        this.mobile = window.innerWidth <= 768;
        window.addEventListener('resize', this.handleResize);
    },
    unmounted() {
        window.removeEventListener('resize', this.handleResize);
    },
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    emits: ['toggleOpenSubscribe', 'toggleOpenDialog'] // Declaração explícita dos eventos emitidos
}
</script>
<style scoped>
.carousel__item {
    position: relative;
    cursor: pointer;
    border-radius: 5px;
    overflow: hidden;
}
.carousel__item img {
    width: 100%;
}
.itensCollections {
    max-width: 1500px;
    margin: 0 auto;
    padding: 20px 0px;
}
.titleCollections {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    color: white;
}
.siteWeflix.collections {
    background-color: white;
    padding: 20px 0px 50px 0px;
}
.titleCollections h3 {
    font-size: 20px;
    font-weight: 700;
}
.carousel__prev {
    left: -4%;
}
.carousel__next {
    right: -4%;
}
svg.carousel__icon {
    color: white;
}
svg.svg-inline--fa.fa-lock.lockIcon path {
    fill: transparent;
    stroke: white;
    stroke-width: 25px;
}
svg.svg-inline--fa.fa-lock.lockIcon {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 15px;
    z-index: 7;
}
.degradeCarousel__item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #0000007d;
    height: 100%;
    opacity: 0;
    transition: all 0.5s;
}
.carousel__item:hover > .degradeCarousel__item {
    opacity: 1;
}
span.textPrivateVideo {
    color: white;
    font-size: 11px;
    position: absolute;
    right: 45px;
    top: 13px;
    opacity: 0;
    transition: all 500ms linear;
    z-index: 2;
}
.carousel__item:hover  span.textPrivateVideo {
    opacity: 1;
}
.descriptionVideo small, .descriptionVideo h4 {
    color: white;
}
.descriptionVideo h4 {
    font-size: 12px;
    margin-left: 5px;
    margin-top: 10px;
    margin-bottom: 0;
}
.descriptionVideo small {
    font-size: 12px;
    margin-left: 5px;
    font-size: 12px;
    margin-left: 5px;
    margin-top: 10px;
}
@media screen and (max-width: 600px) {
    .carousel__slide {
         display: block; 
         width: 101% !important; 
    }
    .siteWeflix.collections {
        background-color: white;
        padding: 5px !important;
    }
    .mobile-course-item {
        margin-bottom: 20px;
    }
    .mobile-course-item .carousel__item img {
        height: 200px;
        object-fit: cover;
    }
}
.collections.siteWeflix .itensCollections {
    max-width: 1300px;
}
.carousel__item img {
    height: 200px!important ;
}
ol.carousel__track {
    gap: 0px 10px;
}
.descriptionVideo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 5px;
    background-color: rgb(232 235 247);
    height: 85px;
    margin-top: -65px;
    z-index: 999;
    position: relative;
}
.contentRight {
    width: 50%;
}
.descriptionVideoContentLeft small {
    margin: 0 !important;
    margin-top: 8px !important;
    font-size: 10px !important;
    color: rgb(125, 125, 125);
}
.descriptionVideoContentLeft h4 {
    color: rgb(125, 125, 125);
    margin: 0 !important;
    margin-top: 8px !important;
    font-size: 13px !important;
    font-weight: 700;
}
.descriptionVideoContentRight svg.svg-inline--fa.fa-lock {
    padding: 6px;
    border: 1px dashed;
    border-radius: 50%;
    width: 17px;
    height: 17px;
    color: v-bind("finalWebsite?.color");
    background-color: #b7b9c1;
}
.descriptionVideoContentLeft {
    width: 80%;
}
.descriptionVideoContentRight {
    width: 15%;
}
.showMore a {
    color: v-bind("finalWebsite?.color");
}
</style>
