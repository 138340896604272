<template>
  <div class="dashboard">       
    <Sidebar/>
    <div class="page">
      <div class="content dashboard superAdmin">
        <div class="container">
          <div class="titleContainer">
            <div class="primaryHeader">
              <h3>Informações da Empresa</h3>
              <p>
                Informações detalhadas da empresa
              </p>
            </div>
          </div>

          <div class="container" v-if="hasReady">
            <div class="row">
              <!-- Dados da Empresa -->
              <div class="col-form-inner col-6 col-md-6 col-lg-6">
                <div class="form-group">
                  <div class="form-label">
                    <label>Razão Social<v-tooltip text="Campo obrigatório" location="top">
                      <template v-slot:activator="{ props }">
                        <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                      </template>
                    </v-tooltip></label>
                  </div>
                  <div class="form-input">
                    <input
                      type="text" 
                      required
                      class="form-control"
                      v-model="tradeName"
                      placeholder="Digite a razão social"
                    />
                    <small>Digite a razão social da empresa conforme registro.</small>
                  </div>
                </div>
              </div>

              <div class="col-form-inner col-6 col-md-6 col-lg-6">
                <div class="form-group">
                  <div class="form-label">
                    <label>Nome Fantasia<v-tooltip text="Campo obrigatório" location="top">
                      <template v-slot:activator="{ props }">
                        <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                      </template>
                    </v-tooltip></label>
                  </div>
                  <div class="form-input">
                    <input
                      type="text" 
                      required
                      class="form-control"
                      v-model="name"
                      placeholder="Digite o nome fantasia"
                    />
                    <small>Nome comercial da empresa para identificação no mercado.</small>
                  </div>
                </div>
              </div>

              <div class="col-form-inner col-4 col-md-4 col-lg-4">
                <div class="form-group">
                  <div class="form-label">
                    <label>CNPJ<v-tooltip text="Campo obrigatório" location="top">
                      <template v-slot:activator="{ props }">
                        <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                      </template>
                    </v-tooltip></label>
                  </div>
                  <div class="form-input">
                    <input
                      type="text" 
                      required
                      class="form-control"
                      v-model="idFiscal"
                      placeholder="Digite o CNPJ"
                      v-mask="'##.###.###/####-##'"
                    />
                    <small>Número do CNPJ da empresa sem pontuação.</small>
                  </div>
                </div>
              </div>

              <div class="col-form-inner col-6 col-md-6 col-lg-6">
                <div class="form-group">
                  <div class="form-label">
                    <label>E-mail<v-tooltip text="Campo obrigatório" location="top">
                      <template v-slot:activator="{ props }">
                        <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                      </template>
                    </v-tooltip></label>
                  </div>
                  <div class="form-input">
                    <input
                      type="text" 
                      required
                      class="form-control"
                      v-model="email"
                      placeholder="Digite o e-mail"
                    />
                    <small>E-mail principal para contato com a empresa.</small>
                  </div>
                </div>
              </div>

              <div class="col-form-inner col-2 col-md-2 col-lg-2">
                <div class="form-group">
                  <div class="form-label">
                    <label>Telefone<v-tooltip text="Campo obrigatório" location="top">
                      <template v-slot:activator="{ props }">
                        <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                      </template>
                    </v-tooltip></label>
                  </div>
                  <div class="form-input">
                    <input
                      type="text" 
                      required
                      class="form-control"
                      v-model="phone"
                      placeholder="Digite o telefone"
                      v-mask="'(##) #####-####'"
                    />
                    <small>Telefone principal para contato com a empresa.</small>
                  </div>
                </div>
              </div>

              <!-- Endereço -->
              <div class="col-form-inner col-2 col-md-2 col-lg-2">
                <div class="form-group">
                  <div class="form-label">
                    <label>CEP<v-tooltip text="Informe o CEP" location="top">
                      <template v-slot:activator="{ props }">
                        <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                      </template>
                    </v-tooltip></label>
                  </div>
                  <div class="form-input">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="00000-000"
                      v-model="postcode"
                      v-mask="'#####-###'"
                      @input="getCep"
                    />
                    <small>Informe o CEP para preenchimento automático do endereço.</small>
                  </div>
                </div>
              </div>

              <div class="col-form-inner col-4 col-md-4 col-lg-4">
                <div class="form-group">
                  <div class="form-label">
                    <label>Endereço</label>
                  </div>
                  <div class="form-input">
                    <input
                      type="text"
                      class="form-control"
                      v-model="address"
                      placeholder="Digite o endereço"
                    />
                  </div>
                </div>
              </div>

              <div class="col-form-inner col-2 col-md-2 col-lg-2">
                <div class="form-group">
                  <div class="form-label">
                    <label>Número</label>
                  </div>
                  <div class="form-input">
                    <input
                      type="text"
                      class="form-control"
                      v-model="addressNumber"
                      placeholder="Nº"
                    />
                  </div>
                </div>
              </div>

              <div class="col-form-inner col-4 col-md-4 col-lg-4">
                <div class="form-group">
                  <div class="form-label">
                    <label>Complemento</label>
                  </div>
                  <div class="form-input">
                    <input
                      type="text"
                      class="form-control"
                      v-model="addressComplement"
                      placeholder="Digite o complemento"
                    />
                  </div>
                </div>
              </div>

              <div class="col-form-inner col-4 col-md-4 col-lg-4">
                <div class="form-group">
                  <div class="form-label">
                    <label>Bairro</label>
                  </div>
                  <div class="form-input">
                    <input
                      type="text"
                      class="form-control"
                      v-model="neighborhood"
                      placeholder="Digite o bairro"
                    />
                  </div>
                </div>
              </div>

              <div class="col-form-inner col-4 col-md-4 col-lg-4">
                <div class="form-group">
                  <div class="form-label">
                    <label>Cidade</label>
                  </div>
                  <div class="form-input">
                    <input
                      type="text"
                      class="form-control"
                      v-model="city"
                      placeholder="Digite a cidade"
                    />
                  </div>
                </div>
              </div>

              <div class="col-form-inner col-4 col-md-4 col-lg-4">
                <div class="form-group">
                  <div class="form-label">
                    <label>Estado</label>
                  </div>
                  <div class="form-input">
                    <input
                      type="text"
                      class="form-control"
                      v-model="county"
                      placeholder="Digite o estado"
                    />
                  </div>
                </div>
              </div>

              <div class="col-form-inner col-4 col-md-4 col-lg-4">
                <div class="form-group">
                  <div class="form-label">
                    <label>País</label>
                  </div>
                  <div class="form-input">
                    <input
                      type="text"
                      class="form-control"
                      v-model="country"
                      placeholder="Digite o país"
                    />
                  </div>
                </div>
              </div>

              <hr>

              <!-- Dados do Sistema -->
              <div class="col-form-inner col-4 col-md-4 col-lg-4">
                <div class="form-group">
                  <div class="form-label">
                    <label>Tipo de Sistema<v-tooltip text="Selecione o sistema" location="top">
                      <template v-slot:activator="{ props }">
                        <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                      </template>
                    </v-tooltip></label>
                  </div>
                  <div class="form-input">
                    <select
                      class="form-control"
                      v-model="typeSystem"
                    >
                      <option value="" disabled selected>Selecione o tipo</option>
                      <option value="1">Weeduc</option>
                      <option value="2">Weflix</option>
                    </select>
                    <small>Selecione o tipo de sistema Weeduc ou Weflix.</small>
                  </div>
                </div>
              </div>

              <div class="col-form-inner col-4 col-md-4 col-lg-4">
                <div class="form-group">
                  <div class="form-label">
                    <label>Plano de Teste</label>
                  </div>
                  <div class="form-input">
                    <input
                      type="number"
                      class="form-control"
                      v-model="testPlan"
                      placeholder="Digite o plano de teste"
                    />
                  </div>
                </div>
              </div>

              <div class="col-form-inner col-4 col-md-4 col-lg-4">
                <div class="form-group">
                  <div class="form-label">
                    <label>Status do Carrinho</label>
                  </div>
                  <div class="form-input">
                    <input
                      type="number"
                      class="form-control"
                      v-model="cartStatus"
                      placeholder="Digite o status"
                    />
                  </div>
                </div>
              </div>

              <div class="col-form-inner col-12 col-md-12 col-lg-12">
                <div class="form-group">
                  <div class="form-label">
                    <label>Cor da Empresa<v-tooltip text="Campo obrigatório" location="top">
                      <template v-slot:activator="{ props }">
                        <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                      </template>
                    </v-tooltip></label>
                  </div>
                  <div class="inputsColors">
                    <input type="color" id="body" name="body" v-model="color" />
                    <input
                      type="text" 
                      required
                      class="form-control"
                      v-model="color"
                      placeholder="#ffffff"
                    />
                  </div>
                  <small>Cores básica para botões e outros espaços do site.</small>
                </div>
              </div>

              <!-- Dados Bancários -->
              <div class="col-form-inner col-3 col-md-3 col-lg-3">
                <div class="form-group">
                  <div class="form-label">
                    <label>Banco</label>
                  </div>
                  <div class="form-input">
                    <input
                      type="text"
                      class="form-control"
                      v-model="bank"
                      placeholder="Código do banco"
                    />
                  </div>
                </div>
              </div>

              <div class="col-form-inner col-3 col-md-3 col-lg-3">
                <div class="form-group">
                  <div class="form-label">
                    <label>Agência</label>
                  </div>
                  <div class="form-input">
                    <input
                      type="text"
                      class="form-control"
                      v-model="branch"
                      placeholder="Número da agência"
                    />
                  </div>
                </div>
              </div>

              <div class="col-form-inner col-3 col-md-3 col-lg-3">
                <div class="form-group">
                  <div class="form-label">
                    <label>Dígito da Agência</label>
                  </div>
                  <div class="form-input">
                    <input
                      type="text"
                      class="form-control"
                      v-model="digitBranch"
                      placeholder="Dígito"
                    />
                  </div>
                </div>
              </div>

              <div class="col-form-inner col-3 col-md-3 col-lg-3">
                <div class="form-group">
                  <div class="form-label">
                    <label>Conta</label>
                  </div>
                  <div class="form-input">
                    <input
                      type="text"
                      class="form-control"
                      v-model="account"
                      placeholder="Número da conta"
                    />
                  </div>
                </div>
              </div>

              <div class="col-form-inner col-3 col-md-3 col-lg-3">
                <div class="form-group">
                  <div class="form-label">
                    <label>Dígito da Conta</label>
                  </div>
                  <div class="form-input">
                    <input
                      type="text"
                      class="form-control"
                      v-model="digitAccount"
                      placeholder="Dígito"
                    />
                  </div>
                </div>
              </div>

              <!-- Outros Dados -->
              <div class="col-form-inner col-3 col-md-3 col-lg-3">
                <div class="form-group">
                  <div class="form-label">
                    <label>Tempo Total de Vídeo</label>
                  </div>
                  <div class="form-input">
                    <input
                      type="number"
                      class="form-control"
                      v-model="videoTimeTotal"
                      placeholder="Tempo total"
                    />
                  </div>
                </div>
              </div>

              <div class="col-form-inner col-3 col-md-3 col-lg-3">
                <div class="form-group">
                  <div class="form-label">
                    <label>Tempo Usado de Vídeo</label>
                  </div>
                  <div class="form-input">
                    <input
                      type="number"
                      class="form-control"
                      v-model="videoTimeUsed"
                      placeholder="Tempo usado"
                    />
                  </div>
                </div>
              </div>

              <div class="col-form-inner col-3 col-md-3 col-lg-3">
                <div class="form-group">
                  <div class="form-label">
                    <label>Percentual do Cliente</label>
                  </div>
                  <div class="form-input">
                    <input
                      type="number"
                      step="0.01"
                      class="form-control"
                      v-model="percentualClient"
                      placeholder="Percentual"
                    />
                  </div>
                </div>
              </div>

              <div class="col-form-inner col-3 col-md-3 col-lg-3">
                <div class="form-group">
                  <div class="form-label">
                    <label>Valor da Transação</label>
                  </div>
                  <div class="form-input">
                    <input
                      type="number"
                      step="0.01"
                      class="form-control"
                      v-model="transactionValue"
                      placeholder="Valor"
                    />
                  </div>
                </div>
              </div>

              <div class="actionsSave">
                <button class="btnSave" @click="$router.push('/admin/empresas')" :disabled="!hasReady">
                  <span v-if="hasReady">Voltar</span>
                  <span v-else>Salvando...</span>
                </button>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div v-if="!hasReady" class="loader-overlay">
        <div class="loader"></div>
      </div>
      <Loader :hasReady="hasReady"/>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Sidebar from '@/components/Weflix/Sidebar-SuperAdmin.vue'
import Footer from '@/components/Admin/Footer/footerSuperAdmin.vue'
import { mapState } from 'vuex'
import superAdmin from '@/utils/superAdmin';
import Loader from '@/components/Weflix/Loader.vue'

export default {
  data() {
    return {
      hasReady: false,
      id: null,
      tradeName: null,
      name: null,
      idFiscal: null,
      email: null,
      phone: null,
      postcode: null,
      address: null,
      addressNumber: null,
      addressComplement: null,
      neighborhood: null,
      city: null,
      county: null,
      country: null,
      typeSystem: null,
      testPlan: null,
      cartStatus: null,
      color: null,
      bank: null,
      branch: null,
      digitBranch: null,
      account: null,
      digitAccount: null,
      videoTimeTotal: null,
      videoTimeUsed: null,
      percentualClient: null,
      transactionValue: null,
      anticipation: 0,
      videoComments: 0,
      intallmentCheckout: 0,
      imagePath: null,
      imageFinalPath: null,
      idFolderVideoteca: null,
      recipientCode: null,
      split: null
    }
  },

  components: {
    Sidebar,
    Footer,
    Loader
  },

  computed: {
    ...mapState(['token','currentCompanyCreated']),
  },

  methods: {
    async getCep() {
      if (this.postcode && this.postcode.length === 8) {
        try {
          const response = await fetch(`https://viacep.com.br/ws/${this.postcode}/json/`)
          const data = await response.json()
          if (!data.erro) {
            this.address = data.logradouro
            this.neighborhood = data.bairro
            this.city = data.localidade
            this.county = data.uf
            this.country = 'Brasil'
          }
        } catch (error) {
          console.error('Erro ao buscar CEP:', error)
        }
      }
    },

    async editLayoutCompany() {
      this.hasReady = false
      const payload = {
        id: this.id,
        idFiscal: this.idFiscal?.replace(/\D/g, ''),
        name: this.name,
        country: this.country,
        county: this.county,
        city: this.city,
        address: this.address,
        addressNumber: this.addressNumber,
        addressComplement: this.addressComplement,
        postcode: this.postcode?.replace(/\D/g, ''),
        testPlan: this.testPlan,
        typeSystem: this.typeSystem,
        anticipation: this.anticipation,
        videoComments: this.videoComments,
        cartStatus: this.cartStatus,
        intallmentCheckout: this.intallmentCheckout,
        color: this.color,
        bank: this.bank,
        branch: this.branch,
        digitBranch: this.digitBranch,
        account: this.account,
        digitAccount: this.digitAccount,
        videoTimeTotal: this.videoTimeTotal,
        videoTimeUsed: this.videoTimeUsed,
        idFolderVideoteca: this.idFolderVideoteca,
        tradeName: this.tradeName,
        email: this.email,
        phone: this.phone?.replace(/\D/g, ''),
        percentualClient: this.percentualClient,
        transactionValue: this.transactionValue,
        recipientCode: this.recipientCode,
        neighborhood: this.neighborhood,
        split: this.split
      }
      try {
        const response = await superAdmin.editLayoutCompany(this.token, payload)
        this.hasReady = true
        this.$router.push('/admin/empresas/')
      } catch (error) {
        console.error(error)
        this.hasReady = true
      }
    },
    async getCompany() {
    const id = this.$route.params.slug
        
    try {
      const response = await superAdmin.createCompanyById(this.token, id)
      this.id = response.id
      this.idFiscal = response.idFiscal
      this.name = response.name
      this.country = response.country
      this.county = response.county
      this.city = response.city
      this.address = response.address
      this.addressNumber = response.addressNumber
      this.addressComplement = response.addressComplement
      this.postcode = response.postcode
      this.testPlan = response.testPlan
      this.typeSystem = response.typeSystem
      this.anticipation = response.anticipation
      this.videoComments = response.videoComments
      this.cartStatus = response.cartStatus
      this.intallmentCheckout = response.intallmentCheckout
      this.color = response.color
      this.bank = response.bank
      this.branch = response.branch
      this.digitBranch = response.digitBranch
      this.account = response.account
      this.digitAccount = response.digitAccount
      this.videoTimeTotal = response.videoTimeTotal
      this.videoTimeUsed = response.videoTimeUsed
      this.idFolderVideoteca = response.idFolderVideoteca
      this.tradeName = response.tradeName
      this.email = response.email
      this.phone = response.phone
      this.percentualClient = response.percentualClient
      this.transactionValue = response.transactionValue
      this.recipientCode = response.recipientCode
      this.neighborhood = response.neighborhood
      this.split = response.split
      this.hasReady = true
    } catch (error) {
      console.error('Erro ao carregar dados da empresa:', error)
      }
    }
  },
  created() {
    const id = this.$route.params.slug
    this.getCompany()
  }
}
</script>

<style>
.content.dashboard > .container {
  padding-bottom: 70px;
}

.dashboard {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #eef0fc;
  margin: 0 auto;
  position: relative;
}

.content.dashboard {
  background-color: transparent;
  margin: 0;
  width: 100%;
  padding: 10px 10px;
  height: 100%;
}

.col-form-inner {
  padding: 0px;
}

.form-group {
  margin: 5px 10px !important;
}

textarea.textarea_input {
  height: 150px !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 28px !important;
  resize: none !important;
  outline: none !important;
  padding: 0.5rem 0.75rem !important;
  color: #5f5f5f !important;
  background-color: #fff !important;
  border: 1px solid #dbdcde !important;
  box-shadow: none !important;
  width: 100% !important;
  border-radius: 5px !important;
}

hr {
  width: 95% !important;
  margin: 25px auto !important;
}

.actionsSave {
  width: 100%;
  text-align: right;
  padding: 20px;
}

.btnSave {
  background: #250891;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btnSave:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.inputsColors {
  display: flex;
  gap: 10px;
  align-items: center;
}

input[type="color"] {
  width: 50px;
  height: 38px;
  padding: 0;
  border: 1px solid #dbdcde;
  border-radius: 5px;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #250891;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>