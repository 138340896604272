<template>
    <div class="container">
        <div class="titleContainer myCoursesWeeduc">
            <h3>Editar Arquivo</h3>
            <p style="font-size: 14px;margin-bottom: 0;">Edite as informações do arquivo</p>
            <button class="btn-delete" @click="deleteFile">
                <fa icon="fa-trash"/>
            </button>
        </div>

        <div class="form-container" v-if="!isSaving">
            <div class="form-row">
                <div class="form-group col-6">
                    <div class="form-group col-12">
                        <label for="title">Título*</label>
                        <input type="text" id="title" class="form-control" v-model="fileData.title" placeholder="Escreva um nome que chame a atenção" required />
                    </div>
                    <div class="form-group col-12">
                        <label for="description">Descrição</label>
                        <textarea id="description" class="form-control" v-model="fileData.description" placeholder="Descreva o conteúdo do arquivo"></textarea>
                    </div>
                    <div class="form-group col-12">
                        <label for="file-upload">Arquivo</label>
                        <input type="file" id="file-upload" class="form-control" accept=".pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx" @change="handleFileUpload" />
                        <small>Formatos aceitos: PDF, Excel, Word ou PowerPoint</small>
                    </div>
                </div>
                <div class="form-group col-6">
                    <div class="preview-container col-12" style="position: relative;">
                        <Loader v-if="isLoading" />
                        <iframe v-if="filePreview && !isLoading" :src="filePreview" class="preview-pdf" frameborder="0"></iframe>
                    </div>
                </div>
            </div>
        </div>
        <Loader v-if="isSaving" />
        <div class="button-container">
            <button class="btn-weflix" @click="$router.go(-1)">Voltar</button>
            <button class="btn-weflix primary" @click="save">Salvar</button>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import apiLesson from '@/utils/lesson';
import Loader from '@/components/Loader/loader.vue';
import apiAWS from '@/utils/storage/aws';

export default {
    name: 'EditFile',
    components: {
        Loader
    },
    data() {
        return {
            fileData: {
                id: '',
                title: '',
                description: '',
                path: '',
                type: 4,
                idModule: null,
                idCourse: null,
                status: 1
            },
            filePreview: null,
            selectedFile: null,
            link: null,
            isLoading: false,
            isSaving: false
        };
    },
    computed: {
        ...mapState(['token'])
    },
    methods: {
        async loadFileData() {
            const id = this.$route.params.slug;
            try {
                const response = await apiLesson.getDataLessonById(id, this.token);
                this.fileData = response;
                this.filePreview = this.fileData.finalPath;
            } catch (error) {
                console.error('Erro ao carregar dados do arquivo:', error);
            }
        },
        async putFileAws(url, file) {
            try {
                await apiAWS.putFileAWS(url, file);
            } catch (error) {
                console.error('Erro ao enviar arquivo para AWS');
            }
        },
        async handleFileUpload(event) {
            this.isLoading = true;
            try {
                const file = event.target.files[0];
                const response = await apiAWS.getSignaturePdf(this.token, file.name);
                await this.putFileAws(response.url, file);
                this.link = response.token;
                this.selectedFile = file;
                this.filePreview = URL.createObjectURL(file);
                this.fileData.path = this.link;
            } catch (error) {
                console.error('Erro na geração do TOKEN AWS')
            } finally {
                this.isLoading = false;
            }
        },
        async save() {
            const payload = {
                    "type": 4,
                    "title": this.fileData.title,
                    "path": this.fileData.path,
                    "description": this.fileData.description,
                    "idQuiz": null,
                    "idModule": this.fileData.idModule,
                    "idCourse": this.fileData.idCourse,
                        "status": 1,
                }
            try {
                this.isSaving = true;
                await apiLesson.editLesson(this.token, payload, this.fileData.id);
                this.$router.push('/dashboard/curso');
            } catch (error) {
                console.error('Erro ao atualizar arquivo:', error);
            } finally {
                this.isSaving = false;
            }
        },
        async deleteFile() {
            if (confirm('Tem certeza que deseja excluir este arquivo?')) {
                try {
                    await apiLesson.deleteLesson(this.fileData.id, this.token);
                    this.$router.push('/dashboard/curso');
                } catch (error) {
                    console.error('Erro ao excluir arquivo:', error);
                }
            }
        }
    },
    created() {
        this.loadFileData();
    }
}
</script>

<style scoped>
.container {
    max-width: 100%;
    background-color: #ffffff;
    padding: 1.5rem;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.form-row {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.form-group {
    margin-bottom: 20px;
    flex: 1;
}

.button-container {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    margin: 20px;
}

.btn-weflix {
    padding: 0.5rem 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    background: white;
    color: #4a5568;
    cursor: pointer;
    transition: all 0.2s;
}

.btn-weflix.primary {
    background: #2f2e8b;
    color: white;
    border: none;
}

.preview-pdf {
    width: 100%;
    height: 70vh;
    border: none;
}

.btn-delete {
    position: absolute;
    right: 30px;
    top: 30px;
    border: none;
    padding: 8px 15px;
    border-radius: 4px;
    cursor: pointer;
}

.btn-delete:hover {
    background: #c82333;
}
</style>
