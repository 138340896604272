<template>
    <div class="container">
        <div class="titleContainer">
            <h3>Relatório Financeiro</h3>
            <p>Gerencie os relatórios e metricas financeiras.</p>
        </div>
        <div class="actionsContainer">
            <div class="actionsHeader">
                <div class="form-group finance">
                    <div class="form-input search">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Pesquisar..."
                        v-model="searchText"
                        @input="filterReports"
                      />
                     <fa icon="fa-magnifying-glass" />
                    </div>
                    <button class="btnSearch" @click="searchClient"><span v-if="!isMobile">Buscar</span> <fa  v-if="isMobile" icon="fa-magnifying-glass" /></button>
                  </div>
                <button class="btnExport usersExport" @click="exportarRelatorioParaCSV">
                    <div>
                        <fa icon="fa-download"/>
                        <span class="mobile-text-none" v-if="!isMobile">Baixar relatório</span>
                    </div>
                    <LoaderImage v-show="isExporting" />
                </button>
            </div>
        </div>
        <div class="cardsContainer" v-if="hasReady">
            <div class="row ">
                <div class="card-md">
                    <div class="contentCard" >
                        <h3 v-if="hasReady">R$ <strong>{{ (total).toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</strong></h3>
                        <p v-if="hasReady">Vendas</p>
                        <LoaderImage :hasReady="hasReady" />
                    </div>
                </div>
                <div class="card-md">
                    <div class="contentCard" >
                        <h3 v-if="hasReady">R$ <strong>{{ (available_amount/100).toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</strong></h3>
                        <p v-if="hasReady">Saldo Atual</p>
                        <LoaderImage :hasReady="hasReady" />
                    </div>
                </div>

                <div class="card-md">
                    <div class="contentCard" >
                        <h3 v-if="hasReady">R$ <strong>{{ (waiting_funds_amount/100).toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</strong></h3>
                        <p v-if="hasReady">Próximo Repasse</p>
                        <LoaderImage :hasReady="hasReady" />
                    </div>
                </div>

                <div class="card-md">
                    <div class="contentCard" >
                        <h3 v-if="hasReady">R$ <strong>{{ (transferred_amount/100).toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</strong></h3>
                        <p v-if="hasReady">Total de Saídas</p>
                        <LoaderImage :hasReady="hasReady" />
                    </div>
                </div>
            </div>
         
        </div>
        <div class="tableContainer" ref="tableContainer" @scroll="handleScroll" style="height: 380px; overflow-y: auto;">
            <table>
                <thead>
                    <tr>
                        <th @click="ordenar('name')">
                            Nome
                            <i :class="['fas', ordemAtual === 'name' ? (ordemAscendente ? 'fa-sort-up' : 'fa-sort-down') : 'fa-sort']"></i>
                        </th>
                        <th @click="ordenar('speed')">
                            Produto
                            <i :class="['fas', ordemAtual === 'speed' ? (ordemAscendente ? 'fa-sort-up' : 'fa-sort-down') : 'fa-sort']"></i>
                        </th>
                        <th @click="ordenar('length')" v-if="!isMobile">
                            Data
                            <i :class="['fas', ordemAtual === 'length' ? (ordemAscendente ? 'fa-sort-up' : 'fa-sort-down') : 'fa-sort']"></i>
                        </th>
                        <th @click="ordenar('length')" style="width: 100px;">
                            Valor
                            <i :class="['fas', ordemAtual === 'length' ? (ordemAscendente ? 'fa-sort-up' : 'fa-sort-down') : 'fa-sort']"></i>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(report, index) in filteredReports" :key="index">
                        <td>{{ report.nameBuyer }}</td>
                        <td>{{ report.planType ? report.planType : 'Assinatura' }}</td>
                        <td v-if="!isMobile">{{ new Date(report.dateApproved).toLocaleDateString('pt-BR') }}</td>
                        <td>R$ {{ (Number(report.total)/100).toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</td>
                        
                    </tr>
                </tbody>
            </table>
            <div v-if="loading" class="loading">
                Carregando mais dados...
            </div>
        </div>
      
      </div>
      <Loader :hasReady="hasReady && hasReadySearch" />
</template>

<script>
import { mapState } from 'vuex';
import reports from '@/utils/Weflix/reports';
import Loader from '@/components/Weflix/Loader.vue';
import LoaderImage from '@/components/Weflix/LoaderImage.vue';

export default {
    data() {
        return {
            headers: [
                { title: 'Nome', align: 'start', key: 'name' },
                { title: 'Speed (knots)', align: 'end', key: 'speed' },
                { title: 'Length (m)', align: 'end', key: 'length' },
                ],
            reports: [],
            filteredReports: [],
            searchText: '',
            ordemAtual: 'name',
            ordemAscendente: true,
            isMobileIcon: false,
            currentPage: 0,
            loading: false,
            hasMoreData: true,
            available_amount: 0,
            waiting_funds_amount: 0,
            transferred_amount: 0,
            total: 0,
            hasReady: false,
            hasReadySearch: true,
            isExporting: false,
            financeExport: [],
            isMobile: false,
        }
    },
    computed: {
        ...mapState(['token','websites'])
    },
    components: {
        Loader,
        LoaderImage
    },
    mounted() {
        this.checkScreenSize();
        window.addEventListener('resize', this.checkScreenSize);
        this.isMobile = window.innerWidth <= 600;
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.checkScreenSize);
    },
    methods: {
        async exportarRelatorioParaCSV() {
            if (this.isExporting) return;
            
            this.isExporting = true;

            try {
                await this.getReportFinanceExport();

                if (!this.financeExport || this.financeExport.length === 0) {
                    console.error('Nenhum cliente para exportar');
                    return;
                }
                const cabecalho = [
                    'ID', 'Nome do Comprador', 'Website', 'Código do Pagamento', 
                    'Tipo de Plano', 'Tipo de Pagamento', 'Status',
                    'Data de Aprovação', 'Data de Expiração', 'Data de Cancelamento',
                    'Data de Inserção', 'Cupom', 'Valor Total'
                ];

                const linhasCSV = this.financeExport.map(finance => [
                    finance.id,
                    finance.nameBuyer,
                    finance.nameWebsite,
                    finance.codePayment,
                    finance.planType,
                    finance.typePayment,
                    finance.status,
                    finance.dateApproved,
                    finance.dateExpired,
                    finance.dateCancelled || '',
                    finance.dateInsert,
                    finance.titleCupom || '',
                    (Number(finance.total)/100).toFixed(2)
                ]);

                const conteudoCSV = [
                    cabecalho.join(','),
                    ...linhasCSV.map(linha => linha.join(','))
                ].join('\n');

                const blob = new Blob([conteudoCSV], { type: 'text/csv;charset=utf-8;' });
                const link = document.createElement('a');
                const url = URL.createObjectURL(blob);
                
                link.setAttribute('href', url);
                link.setAttribute('download', 'relatorio_clientes_weflix.csv');
                link.style.visibility = 'hidden';
                
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } catch (error) {
                console.error('Erro ao exportar CSV:', error);
            } finally {
                this.isExporting = false;
            }
        },
        filterReports() {
            if (!this.searchText) {
                this.getReportFinance();
                return;
            }

            // const searchLower = this.searchText.toLowerCase();
            // this.filteredReports = this.reports.filter(report => {
            //     return report.nameBuyer.toLowerCase().includes(searchLower) ||
            //            (report.planType && report.planType.toString().toLowerCase().includes(searchLower)) ||
            //            new Date(report.dateApproved).toLocaleDateString('pt-BR').includes(searchLower) ||
            //            report.total.toString().includes(searchLower);
            // });
        },
        ordenar(coluna) {
            if (this.ordemAtual === coluna) {
                this.ordemAscendente = !this.ordemAscendente;
            } else {
                this.ordemAtual = coluna;
                this.ordemAscendente = true;
            }

            this.boats.sort((a, b) => {
                let comparacao = 0;
                if (a[coluna] < b[coluna]) comparacao = -1;
                if (a[coluna] > b[coluna]) comparacao = 1;
                return this.ordemAscendente ? comparacao : -comparacao;
            });
        },
        checkScreenSize() {
            this.isMobileIcon = window.innerWidth <= 600;
        },
        async getReportFinance() {
            if (this.loading || !this.hasMoreData) return;
            
            try {
                this.loading = true;
                const response = await reports.getReportFinance(this.token, this.currentPage);
                this.available_amount = response.recipientReturn?.available_amount || 0;
                this.waiting_funds_amount = response.recipientReturn?.waiting_funds_amount || 0;
                this.transferred_amount = response.recipientReturn?.transferred_amount || 0;
                this.total = response.recipientReturn?.total || 0;
                
                if (response.orders.content.length === 0) {
                    this.hasMoreData = false;
                    return;
                }
                
                this.reports = [...this.reports, ...response.orders.content];
                this.filteredReports = this.reports; 
                this.currentPage++;
                
                if (!this.hasReady) {
                    this.hasReady = true;
                }
            } catch (error) {
                console.error('Erro ao obter relatório financeiro:', error);
            } finally {
                this.loading = false;
            }
        },
        async getReportFinanceExport() {
          
            try {
                const response = await reports.getReportFinanceExport(this.token);
                this.financeExport = response.orders.content;
                this.isExporting = false;
            } catch (error) {
                console.error('Erro ao obter relatório financeiro:', error);
            }
        },

        handleScroll(e) {
            const container = e.target;
            const scrollPosition = container.scrollTop + container.clientHeight;
            const totalHeight = container.scrollHeight;
            
            if (scrollPosition > totalHeight * 0.8 && !this.loading) {
                this.getReportFinance();
            }
        },
        async searchClient() {
            this.hasReadySearch = false;
            const payload = {
                "search": this.searchText
            }
            try {
                const response = await reports.getReportFinanceBySearch(this.token, 0, payload);
                this.reports = response.content;
                this.filteredReports = this.reports; 
                this.hasReadySearch = true;
            } catch (error) {
                console.error('Erro ao buscar usuários:', error);
            }
        },
    },
    created() {
        this.getReportFinance();
    }
}
</script>


<style scoped>
@import url(./global.css);
.container {
    max-width: 100%;
 }
.titleContainer {
    display: block;
    text-align: left;
    padding: 0px 10px;
    border-bottom: 1px solid #e8e8e8;
    margin: 10px;
}
.container {
    background-color: white;
    padding: 10px 0px;
    padding-bottom: 50px;
}
.btnExport {
    width: auto;
    padding: 6px 12px;
    background-color: white;
    border:1px solid #250891;
    color: #250891;
    border-radius: 5px;
    top: 20px;
    right: 20px;
    cursor: pointer;
    transition: all 500ms ease-in-out;
}
.btnExport:hover {
    background-color: #250891;
    color: white;
}
.row {
    margin: 0;
    padding: 10px;
    gap: 10px;
    justify-content: space-evenly;

}
.card-md {
    width: 24%;
    padding: 0;
    margin: 0;
    padding: 10px;
    box-shadow: -1px 4px 10px 1px #dfdfdf;
    border-radius: 5px;
    align-items: center;
    display: flex;
    justify-content: center;
}
.form-input.search svg.svg-inline--fa.fa-magnifying-glass {
    color: #bcbcbc;
    position: absolute;
    top: 9px;
    right: 9px;
}
.form-input {
    position: relative;
}
input.form-control {
    padding-right: 33px !important;
}
.actionsHeader {
    display: flex;
    align-items: center;
}
.actionsContainer {
    position: absolute;
    top: 50px;
    right: 20px;
}
svg.svg-inline--fa.fa-download {
    color: #250891;
    padding: 0px 10px;
    transition: all 500ms ease-in-out;
}
.btnExport:hover svg.svg-inline--fa.fa-download {
    color: white;
}
table {
    width: 100%;
    border-collapse: collapse;
}
th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}
th {
    background-color: #f2f2f2;
    font-weight: bold;
}
tr:nth-child(even) {
    background-color: #f9f9f9;
}
.tableContainer {
    padding: 10px;
}
.loading {
    text-align: center;
    padding: 10px;
    color: #666;
}
@media (max-width: 600px) {
    .cardsContainer .row {
        display: flex;
    }
    .card-md {
        width: 45%;
    }
    .btnExport {
        padding: 7px 7px !important;
    }
    .btnExport svg {
        color: #250891;
    }
    .btnExport:hover svg {
        color: white;
    }
    .titleContainer {
        display: block;
        text-align: left;
        padding: 0px 10px;
        margin: 10px;
        border-bottom: none;
    }
    .actionsContainer {
        position: relative;
        top: -15px;
        right: 0;
        padding: 0px 0px 10px;
        border-bottom: 1px solid #e8e8e8;
    }
}
.btnSearch {
    width: auto;
    padding: 6px 12px;
    background-color: white;
    border: 1px solid #250891;
    color: #250891;
    border-radius: 5px;
    cursor: pointer;
    transition: all 500ms ease-in-out;
    margin-left: 10px;
}
.btnSearch:hover {
    background-color: #250891;
    color: white;
}
button.btnExport.usersExport .loader2{
    width: 19px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 2px solid #250891;
    animation: l20-1-79f4614c 0.8s infinite linear alternate, l20-2-79f4614c 1.6s infinite linear;
    margin: 0px 10px;
    position: initial;
}
button.btnExport.usersExport {
    display: inline-flex;
}
.form-group.finance {
    width: 260px;
    display: flex;
}
.contentCard {
    position: relative;
    min-height: 70px;
}
@media (max-width: 600px) {
    .form-group.finance {
        width: 72%;
    }
    .contentCard h3 {
        font-size: 20px;
    }
    .contentCard p {
        font-size: 11px;
    }
    .contentCard {
        min-height: 50px !important;
    }
    .titleContainer p {
        font-size: 14px;
    }
    
}
button.btnSearch svg.svg-inline--fa.fa-magnifying-glass {
    color: #250891;
}
</style>
