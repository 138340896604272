<template>
    <UserEdit 
    :showEditInfo="showEditInfo" 
    @showModalEditDataUser="handleShowModalEditDataUser"
  ></UserEdit>
    <div class="global_header weeducStudentHeader" :class="{ 'dark': this.dark }">
        <div class="logo">
        <router-link to="/aluno">
            <img  v-show="!this.dark" src="../../../../../src/assets/logo.png">
            <img  v-show="this.dark" src="../../../../../src/assets/logo_white.png">
        </router-link>
    </div>
    <div class="menu_mobile">
        <span v-if="this.dark" @click="changeViewMode()"><fa icon="sun"/></span>
        <span v-if="!this.dark" @click="changeViewMode()"><fa icon="moon"/></span>
        <fa icon="bars"  @click="(()=>this.showMenuMobile = !this.showMenuMobile)"/>
    </div>

    <div class="options">
        <div class="menu item">
            <ul>
                <li v-if="typeId == 2">
                    <router-link to="/dashboard/painel">Painel Administrativo</router-link>
                </li>
                <li>
                    <router-link to="/aluno">Início</router-link>
                   
                </li>
                <li>
                    <router-link to="/aluno/duvidas">Minhas dúvidas</router-link>
                </li>
                <li>
                    <router-link to="/aluno/forum">Fóruns</router-link>                   
                </li>
            </ul>
        </div>
        <div class="menu icone_actions">
            <ul>
                <li style="position:relative;">
                    <fa icon="fa-bell" @click="showNotification"></fa>
                    <span class="qtd_notification">{{qtdnotification}}</span>
                    <div class="notification_bar" v-bind:class="hasNotification? 'show':''">
                        <Notification :data={data} title="Notificações"></Notification>
                    </div>
                </li>
                <li @click="changeViewMode()">
                    <span v-if="this.dark"><fa icon="sun"/></span>
                    <span v-if="!this.dark"><fa icon="moon"/></span>
                </li>
                <li @click="(()=>this.showEditInfo=true)">
                    <span class="logo_name">{{name}}</span>
                </li>
                <li @click="logout">
                    <fa icon="fa-power-off"/>
                </li>
                
            </ul>
        </div>
    </div>
    <div class="menuMobile" :class="showMenuMobile?'show':''">
        <div class="logoMobile">
            <router-link to="/aluno">
                <img  v-show="!this.dark" src="../../../../../src/assets/logo.png">
                <img  v-show="this.dark" src="../../../../../src/assets/logo_white.png">
            </router-link>
            <span  @click="(()=>this.showMenuMobile = !this.showMenuMobile)"><fa icon="fa-xmark"/></span>

        </div>
      
        <ul>
            <li @click="closeMenu()">
                <router-link to="/aluno/perfil">
                    <span class="logo_name">{{name}}</span> - <span class="fullName">{{ fullName }}</span>
                </router-link>
            </li>
            <li @click="closeMenu()">
                <router-link to="/aluno"><i class="pi pi-home"></i>Início</router-link>
               
            </li>
            <li @click="closeMenu()">
                <router-link to="/aluno/duvidas"><i class="pi pi-exclamation-circle"></i>Minhas dúvidas</router-link>
            </li>
            <li @click="closeMenu()">
                <router-link to="/aluno/forum"><i class="pi pi-comments"></i>Fóruns</router-link>                   
            </li>
            <li style="position:relative;" @click="closeMenu()">
                <router-link to="/aluno/notificacoes"><i class="pi pi-bell"></i>Notificações</router-link>     
               
            </li>
            <li @click="changeViewMode()" class="viewmode">
                <span v-show="!darkMode">
                    <fa icon="moon"/>
                    <span>Dark</span>
                </span>
                <span v-show="darkMode">
                    <fa icon="sun"/>
                    <span>Ligth</span>
                </span>
            </li>
            <li v-if="typeId == 2" @click="closeMenu()">
                <router-link to="/dashboard/painel">Painel Administrativo</router-link>
            </li>
            <li @click="logout" >
              <i class="pi pi-sign-out"></i> Sair
            </li>
        </ul>
    </div>
   </div>
</template>
<script>
import UserEdit from '@/components/Student/Dashboard_Unique/Info_User/user.vue';
import Notification from './Notifications.vue';
import { mapState } from 'vuex';
import api from '@/utils/portal/notification.js'
import apiUser from "@/utils/portal/user.js"


export default{
    data(){
        return{
            showEditInfo:false,
            hasNotification:false,
            darkMode:true,
            qtdnotification:0,
            data: [],
            showNavItem:false,
            name:null,
            fullName:null,
            showMenuMobile:false,
        }
    },
    props:{
        view:Boolean
    },
    components:{
        Notification,
        UserEdit
    },
    computed:{
        ...mapState(['token', 'typeId', 'user', 'dark']),
    },
    methods: {
        async loadDataUser(){
            this.fullName = this.user.name;
            this.name = this.user.name.substring(0, 2);    
    },
        async getDataNotification(){
            try {
                const response = await api.getDataNotifications(this.token);
                this.data =  response;
            } catch (error) {
                console.error('Erro na listagem da notificações')
            }   
        },
        async getNumberNitification(){
            try {
                const response =  await api.getNumberNotifications(this.token);
                console.debug(response)
                this.qtdnotification = response;
            } catch (error) {
                
            }
        },
        logout(){
            localStorage.removeItem('vuex');
            localStorage.removeItem('activeIndex');
            localStorage.removeItem('logado');
            window.location.href = "/login";
           
        },
        handleShowModalEditDataUser(showEditInfo) {
            this.showEditInfo = showEditInfo;
        },
        changeViewMode(){
            this.darkMode = !this.darkMode;
            this.alterarValorNovo();
            this.closeMenu();
        },
        showNotification(){
            this.hasNotification=!this.hasNotification;
        },
        alterarValorNovo() {
            this.$emit("alterar-valor", this.darkMode);
        },
        closeMenu(){
            this.showMenuMobile = false;
        }
    },
    created(){
        this.darkMode=this.view
        this.getNumberNitification();
        this.getDataNotification();
        this.loadDataUser();
        
    },
    watch: {
        showMenuMobile(val) {
            document.body.style.overflow = val ? 'hidden' : '';
        }
    },
}
</script>
<style scoped>
@import url(./index.css);
.menu_mobile, .menuMobile{
    display: none;
}
@media only screen and (max-width:600px){
    .menu_mobile{
        display: flex;
        gap: 10px;
    }
    .menuMobile{
        display: block;
    }
    .options{
        display: none;
    }
    .global_header {
        padding: 5px 10px;
        align-items: center;
    }
    i.pi.pi-bars {
        font-size: 25px;
    }
    .menuMobile {
        position: fixed;
        width: 50%;
        background-color: white;
        top: 0;
        right: 0;
        height: 100%;
        z-index: 10;
        right: -500px;
        overflow-y: auto;
        transition: right 0.5s ease;
        box-shadow: 0px 0px 20px 1px #d0d0d0; 
    }
    
    .menuMobile.show {      
        right: 0;
    }
    .logoMobile {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
    }
    .logoMobile img {
        width: 150px;
    }
    .menuMobile ul  {
        padding: 10px;
    }
    .menuMobile ul li {
        list-style: none;
        text-align: left;
        padding: 10px 0px;
    }
    .menuMobile ul li a {
        font-size: 18px;
        text-decoration: none;
        color: #666666;
        font-weight: 400;
        display: flex;
        gap: 10px;
        align-items: center;
    }
    .dark  .menuMobile ul li a {
        color: white;
    }
    li.viewmode span {
        display: flex;
        gap: 5px;
        align-items: center;
    }
    span.fullName {
        text-transform: capitalize;
    }
    .dark .menuMobile.show {
        background-color: black;
        box-shadow: 0px 0px 20px 1px #000000;
    }
}
.global_header.weeducStudentHeader .item {
    background-color: white !important;
}
/* DARK */
.global_header.weeducStudentHeader.dark .item {
    background-color: #575757 !important;
    color: white !important;
}
</style>