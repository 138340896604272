<template>
    <div class="container weflix-admin-collections">
        <div class="titleContainer">
            <h3>Minhas Coleções</h3>
            <p style="margin-bottom: 0px;">Gerencie suas coleções ou crie novas. Arraste-as para mudar a ordem de visualizacao no portal.
            </p>
            <small v-if="!isMobile">Para alterar as coleções recomendadas, coloque as coleções na seis primeiras posições, arrastando e soltando-as.</small>
            <div class="searchContainer searchCollectionsMobile">
                <div class="form-group">
                    <div class="form-input">
                        <input
                            type="text" 
                            class="form-control"
                            placeholder="Pesquisar coleção..."
                            v-model="searchText"
                        />
                    </div>
                </div>
                <button class="btnSearchCollections btnSearchCollectionMobile" @click="buscarColecao">
                    <fa icon="fa-search"/>
                </button>
            </div>
            <div class="btnWeflix absolute" @click="(()=>this.$router.push('/admin/nova-colecao'))">
                <fa v-if="isMobileIcon" icon="fa-plus"></fa>
                <span v-else class="flex">Nova Coleção</span>
            </div>
        </div>
        <div class="tolbarCollections">
            <div class="card-sm">
                <div class="titleCardCollections">
                    <fa icon="fa-film"/>
                    <h4>Coleções</h4>
                </div>
                <div class="contentCardCollections">
                    <h4>{{panelData.collections}}</h4>
                </div>
            </div>
            <div class="card-sm">
                <div class="titleCardCollections">
                    <fa icon="fa-circle-play"/>
                    <h4>Vídeos</h4>
                </div>
                <div class="contentCardCollections">
                    <h4>{{panelData.videos}}</h4>
                </div>
            </div>
            <div class="card-sm">
                <div class="titleCardCollections">
                    <fa icon="fa-clock-rotate-left"/>
                    <h4>Tempo total</h4>
                </div>
                <div class="contentCardCollections">
                    <h4>{{panelData.videoTimeUsed}}h</h4>
                </div>
            </div>
        </div>
        <div class="choose-view-mode">
            <span>Modo de visualização: </span>
            <div class="viewOptionsContainer">
                <button class="btnViewOption" :class="{'active': view === 3}" @click="changeView(3)" title="Visualização em cards">
                    <fa icon="fa-th"/>
                </button>
                <button class="btnViewOption" :class="{'active': view === 1}" @click="changeView(1)" title="Visualização em blocos">
                    <fa icon="fa-th-large"/>
                </button>
                <button class="btnViewOption" :class="{'active': view === 2}" @click="changeView(2)" title="Visualização em lista">
                    <fa icon="fa-list"/>
                </button>
            </div>
        </div>
        <draggable
        :list="collections"
        :disabled="false"
        class="list-group"
        :class="{'grid': view === 1, 'list': view === 2, 'cards': view === 3}"
        ghost-class="ghost"
        :move="checkMove"
        @start="dragging = true"
        @end="onDragEnd"
      >
        <template #item="{ element, index }">
          <div class="list-group-item" :id="`collection-${element.id}`">
            <div class="item" :class="{
              'featured': index < 6,
              'wide': !element.imageFinalPath2 || element.imageFinalPath2 === 'https://image.weeduc.com/'
            }" @click="goToCollection(element.id, element)">
                <div class="drag-handle" v-if="dragging">
                    <fa icon="fa-grip-vertical"/>
                </div>
                <div v-if="view == 3" class="backgroundItemCollection" :class="{'wide': !element.imageFinalPath2 || element.imageFinalPath2 === 'https://image.weeduc.com/'}" :style="{ backgroundImage: (element.imageFinalPath2 && element.imageFinalPath2 !== 'https://image.weeduc.com/') ? `url(${element.imageFinalPath2})` : `url(${element.imageFinalPath})` }">
                    <span class="status-badge" :class="element.status == '1' ? 'enabled' : 'disabled'">
                        {{element.status == '1' ? 'Habilitado' : 'Desabilitado'}}
                    </span>
                </div>
                <div v-else class="backgroundItemCollection" :class="{'noImage': !element.imageFinalPath}" :style="{ backgroundImage: element.imageFinalPath ? `url(${element.imageFinalPath})` : `url(${require('@/assets/Weflix/default-video-thumbnail.jpg')})` }">
                    <span class="status-badge" :class="element.status == '1' ? 'enabled' : 'disabled'">
                        {{element.status == '1' ? 'Habilitado' : 'Desabilitado'}}
                    </span>
                </div>
                <div class="itemContent" :class="{'noImage': !element.imageFinalPath}">
                    <div class="titleItemCollection">
                        {{element.comercialName}}
                        <fa v-if="index < 6" icon="fa-star" style="color: gold; margin-left: 5px;"/>
                       
                     </div>
                     <div class="descriptionItemCollection">
                         <p><fa icon="fa-tv"/> {{element.totalVideos}} vídeos</p>
                         <p><fa icon="fa-clock"/> {{converterTempo(element.totalTime)}}</p>
                     </div>
                     <div class="descriptionItemCollectionVideo" v-if="view != 3">
                         {{element?.shortDescription?.replace(/<[^>]*>/g, '')}}
                     </div>
                </div>               
            </div>
          </div>
        </template>
      </draggable>
      </div>
      <Loader :hasReady="hasReady" />
</template>

<script>
import { mapState, mapActions } from 'vuex';
import apiCollections from '@/utils/Weflix/collections';
import Loader from '@/components/Weflix/Loader';
import draggable from 'vuedraggable';

let id = 1;

export default {    
    data() {
        return {
            collections: [],
            hasReady: false,
            dragging:false,
            showCollections:false,
            isMobileIcon: false,
            isMobile: false,
            searchText: '',
            view: 3,
        }
    },
    mounted() {
        this.checkScreenSize();
        window.addEventListener('resize', this.checkScreenSize);
        this.isMobile = window.innerWidth <= 600;
        if (this.isMobile) {
            this.view = 2;
        }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.checkScreenSize);
    },
    computed: {
        ...mapState(['token', 'panelData'])
    },
    methods: {
        ...mapActions(['storeCurrentCollections','storeToken']),

        goToCollection(id, collections) {
            this.storeCurrentCollections({currentCollections: collections});
            this.$router.push('/admin/colecao/' + id)
        },
        async getCollections() {
            try {
                const response = await apiCollections.getCollection(this.token);
                this.collections = response.sort((a, b) => {
                    // Garante que order seja um número
                    const orderA = Number(a.order) || 0;
                    const orderB = Number(b.order) || 0;
                    return orderA - orderB;
                });
                this.hasReady = true;
            } catch (error) {
                console.error('Erro ao obter dados das coleções:', error);
            }
        },
        resetToken() {
            this.storeToken({token: '12345'});
        },
        checkMove: function(e) {
        },
        onDragEnd: function(e) {
            const movedElement = this.collections[e.newIndex];
              
            // Filtrar collections para obter apenas order e id
            const collectionOrderAndId = this.collections.map(item => ({
                id: item.id,
                nome: item.comercialName,
                order: this.collections.indexOf(item) + 1
            }));
            this.updateCollectionOrder(collectionOrderAndId);
            this.dragging = false;
        },
        async updateCollectionOrder(collectionOrderAndId) {
            try {
                const response = await apiCollections.updateCollectionOrder(this.token, collectionOrderAndId);
            } catch (error) {
                console.error('Erro ao atualizar ordem da coleção:', error);
            }
        },
        checkScreenSize() {
            this.isMobileIcon = window.innerWidth <= 600;
            if (window.innerWidth <= 600 && !this.dragging) {
                this.view = 2;
            }
        },
        converterTempo(segundos) {
            const horas = Math.floor(segundos / 3600).toLocaleString('pt-BR');
            const minutos = Math.floor((segundos % 3600) / 60);
            const segundosRestantes = segundos % 60;

            let resultado = '';
            if (horas > 0) {
                resultado += `${horas}h `;
            }
            if (minutos > 0 || horas > 0) {
                resultado += `${minutos}m `;
            } else {
                resultado += '0m ';
            }

            return resultado.trim();
        },
        buscarColecao() {
            if (!this.searchText) return;
            
            const searchLower = this.searchText.toLowerCase();
            const foundItem = this.collections.find(collection => 
                collection.comercialName.toLowerCase().includes(searchLower)
            );

            if (foundItem) {
                const element = document.getElementById(`collection-${foundItem.id}`);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
            }
        },
        changeView(view) {
            this.view = view;
        },
       
    },
    components: {
        Loader,
        draggable
    },
    created() {
        this.getCollections();
    }
}
</script>


<style scoped>
@import url(./global.css);
.container {
    max-width: 100%;
 }
 .btnWeflix.absolute {
    position: absolute;
    top: 25px;
    right: 25px;
}
.titleContainer {
    display: block;
    text-align: left;
    padding: 0px 10px;
    border-bottom: 1px solid #e8e8e8;
    margin: 10px;
}
.container {
    background-color: white;
    padding: 10px 0px;
    padding-bottom: 50px;
}
.btnNewCollection {
    position: absolute;
    width: auto;
    padding: 6px 12px;
    background-color: #250891;
    color: white;
    border-radius: 5px;
    top: 20px;
    right: 20px;
    cursor: pointer;
}
 .item {
    display: flex;
    cursor: pointer;
    border: 1px solid #25099130;
    border-radius: 5px;
    padding: 0;
    height: 170px;
    overflow: hidden;
    gap: 20px;
    justify-content: flex-start;
    position: relative;
}

.status-badge {
    font-size: 12px;
    padding: 2px 8px;
    border-radius: 12px;
    margin-left: 10px;
    position: absolute;
    top: 10px;
    right: 10px;
}

.status-badge.enabled {
    background-color: #4CAF50;
    color: white;
}

.status-badge.disabled {
    background-color: #f44336;
    color: white;
}

/* Card View */
.list-group.cards {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
}

.list-group.cards .item {
    flex-direction: column;
    height: 300px;
    gap: 0;
}

.list-group.cards .backgroundItemCollection {
    width: 100%;
    height: 440px;
}

.list-group.cards .itemContent {
    width: 100%;
    padding: 10px;
}

.list-group.cards .titleItemCollection {
    margin-top: 0;
    font-size: 14px;
}

.list-group.cards .descriptionItemCollectionVideo {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.item.featured {
    border: 2px dashed #250891;
}
.drag-handle {
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
    color: #250891;
    font-size: 20px;
    z-index: 10;
    background: rgba(255, 255, 255, 0.9);
    padding: 5px;
    border-radius: 4px;
    cursor: move;
}
.collecttionsItens {
    padding: 10px;
}
.titleItemCollection {
    text-align: left;
    font-size: 20px;
    margin-top: 30px;
    font-weight: 700;
    display: flex;
    align-items: center;
}
.descriptionItemCollection  {
    margin-top: 10px;
    display: flex;
    gap: 10px;
}
.descriptionItemCollection p {
    color: gray;
    margin: 0;
}
.descriptionItemCollection svg path {
    fill: #ffffff;
    stroke-width: 0;
    stroke: white;
}
.descriptionItemCollection svg {
    font-size: 15px;
}
</style>
<style>
.itemAtionCollection .form-check-input:checked {
    background-color: #1AC7D0;
    border: 1px solid #1AC7D0;
}
.itemAtionCollections .form-check-input {
    margin-right: 1rem !important;
    cursor: pointer;
    flex-shrink: 0;
    width: 3rem;
    height: 1.375rem;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='white'/%3E%3C/svg%3E");
    background-color: #8d8d8d;
    border: 1px solid #8d8d8d;
    box-shadow: none;
}
.itemAtionCollections .form-check-input {
    margin-right: 1rem !important;
    cursor: pointer;
    flex-shrink: 0;
    width: 3rem;
    height: 1.375rem;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='white'/%3E%3C/svg%3E");
    background-color: #8d8d8d;
    border: 1px solid #8d8d8d;
    box-shadow: none;
}
.collecttionsItens .itemAtionCollections.form-check.form-switch {
    position: absolute;
    top: 10px;
    right: 0;
}
.itemContent {
    width: 70%;
}
.backgroundItemCollection {
    width: 60%;
    background-size: cover;
}
.itemContent.noImage {
    padding: 0px 10px;
}
</style>

<style scoped>
.buttons {
  margin-top: 35px;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group-item {
    border: none;
}
.backgroundItemCollection.noImage {
    background-size: contain;
}
@media (max-width: 600px) {
    .weflix-admin-collections .btnWeflix {
        padding: 2px;
        margin: 2px;
        height: 35px;
        align-content: center;
    }
    .btnWeflix.absolute svg {
        color: #250891;
    }
    .btnWeflix:hover svg {
        color: white;
    }
    .backgroundItemCollection {
        width: 100%;
        background-size: 100%;
    }
    .item {
        gap: 0px;
        flex-wrap: wrap;
    }
    .itemContent {
        width: 100%;
        height: 1%;
        margin-left: 10px;
    }
    .list-group.grid .titleItemCollection {
        margin-top: 10px;
        font-size: 16px !important;
    }
    .tolbarCollections .card-sm {
        padding: 13px !important;
    }
    .contentCardCollections h4{
        margin-bottom: .1rem !important;
        font-size: 14px !important;
    }
    .titleCardCollections h4 {
        display: none;
    }
    .descriptionItemCollectionVideo {
        display: none;
    }
    .list-group.list .titleItemCollection {
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .list-group.list .descriptionItemCollection {
        display: none;
    }
    .list-group.cards {
        grid-template-columns: 1fr;
    }
    .weflix-admin-collections .titleContainer {
        padding: 0px 0px 10px 10px !important;
    }
    .titleContainer p {
        font-size: 12px;
        margin: 15px 0px;
    }
    .searchContainer.searchCollectionsMobile .form-group {
        margin: 0 !important;
    }
    .weflix-admin-collections .btnSearchCollections.btnSearchCollectionMobile {
        padding: 2px !important;
    }
    .btnSearch {
        justify-content: center;
    }
    .searchContainer.searchCollectionsMobile {
        display: flex;
        position: relative;
        align-items: normal !important;
        gap: 10px;
        top: 0;
        right: 0;
        width: 100% !important;
        margin: 10px 0px 10px -10px;
        flex-direction: row;
    }
    .weflix-admin-collections .choose-view-mode {
        display: flex;
        padding: 10px;
    }
    .weflix-admin-collections .choose-view-mode .viewOptionsContainer {
        position: relative;
        top: 0;
        right: 0;
    }
    .weflix-admin-collections .form-input {
        padding: 0px 0px 0px 10px;
    }
    .weflix-admin-collections .list-group.list .item {
        height: 100%;
    }
    .weflix-admin-collections .list .item.featured {
        border: 1px dashed #250891;
    }
    .weflix-admin-collections h3 {
        font-size: 20px;
        color: #333;
    }
    .weflix-admin-collections .list .itemContent {
        width: 100%;
    }
    .weflix-admin-collections .choose-view-mode span {
        font-size: 16px;
        color: #333;
        font-weight: 400;
        font-family: "Poppins";
        font-style: normal;
    }
    .weflix-admin-collections .list-group.list .titleItemCollection {
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 14px;
        font-weight: 600;
    }
}
@media (min-width: 601px) {
    .weflix-admin-collections .choose-view-mode span {
        display: none;
    }
}
.tolbarCollections {
    display: flex;
    gap: 10px;
    padding: 10px;
}
.tolbarCollections .card-sm {
    width: 33%;
    background-color: #eef0fc;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    align-items: center;
    border-radius: 5px;
}
.titleCardCollections {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
}
.titleCardCollections h4{
    margin: 0;
    font-size: 16px;
    font-weight: 700;
}
.titleCardCollections svg {
    color: #250991;
}
.searchContainer {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 200px;
    width: 307px;
    top: 19px;
}

.form-group {
    width: 100%;
    max-width: 400px;
}

.form-input {
    position: relative;
    display: flex;
    align-items: center;
}

.form-input input {
    width: 100%;
    padding: 8px 35px 8px 15px;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    font-size: 14px;
}

.form-input svg {
    position: absolute;
    right: 10px;
    color: #250991;
}

.btnSearchCollections {
    background-color: #21212100;
    color: #250891;
    border: 1px solid #250891;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    padding: 7px;
    transition: all 500ms ease-in-out;
}
.btnSearchCollections svg{
    color: #250991;
}

.btnSearchCollections:hover {
    color: white;
    background-color: #1b0668;
}

.btnSearchCollections:hover svg {
    color: white;
}
.viewOptionsContainer {
    position: absolute;
    top: 85px;
    right: 20px;
}
button.btnViewOption.active svg {
    color: #250891;
}
/* List view */
.list-group.list .backgroundItemCollection {
    display: none;
}
.list .list-group-item {
    padding: 5px 10px;
}
.list-group.list .item {
    height: 40px;
}
.list-group.list .titleItemCollection{
    margin:10px;
}
.list-group.list .itemContent {
    display: flex;
}
.list-group.list .titleItemCollection {
    font-size: 14px;
}
.list-group.list .descriptionItemCollection p {
    font-size: 13px;
}
.list-group.list .descriptionItemCollection p svg {
    font-size: 13px;
}
.descriptionItemCollection p {
    color: gray;
    text-align: left;
    font-size: 11px;
}
.list-group.list .descriptionItemCollectionVideo {
    display: none;
}
.descriptionItemCollectionVideo p {
    text-align: left;
    font-size: 13px;
    margin-top: 10px;
    color: gray;
}
.descriptionItemCollectionVideo {
    text-align: left;
    font-size: 13px;
    padding-top: 5px;
}
.list-group.cards .list-group-item {
    padding: 10px 5px 10px 10px;
}
.list-group.cards .item.featured {
    height: 555px;
}
.list-group.cards .backgroundItemCollection.wide {
    height: 100px;
}
.list-group.cards .item.wide {
    height: 180px !important;
}
</style>