<template>
  <div class="config-form-content adminWeeducAdvanced">
    <div class="content-wrapper primary">
      <div class="wrapper-inner">
        <div class="content-text">
        </div>
        <div class="content-form" >
          <div class="row" v-if="hasReady">
            <div class="col-form-outer col-12 col-md-6 col-lg-6">
              <div class="form-group">
                <label class="label-form">Tema Loja Virtual</label>
                <select class="form-control" v-model="typeLayoutPortalEcommerce">
                  <option value="">Selecione uma opção</option>
                  <option value="0">Dark</option>
                  <option value="1">Clean</option>
                </select>
                <small class="text-muted">Tema padrão para a loja virtual</small>
                <div class="theme-description mt-2">
                  <p class="mb-1">
                    <strong>Clean:</strong> Tema com design minimalista e cores claras, proporcionando uma experiência visual leve e moderna. Ideal para uma aparência profissional e clean.
                  </p>
                  <p class="mb-0">
                    <strong>Dark:</strong> Tema com fundo escuro e elementos contrastantes, oferecendo uma experiência visual moderna e sofisticada. Reduz o cansaço visual em ambientes com pouca luz.
                  </p>
                </div>
              </div>
              
              <div class="form-group mt-4">
                <label class="label-form">CSS</label>
                <textarea 
                  class="form-control textarea" 
                  v-model="css" 
                  rows="6"
                  placeholder="<style>...</style>"
                ></textarea>
                <small class="text-muted">CSS personalizado para a loja virtual</small>
                <small class="small alert">Atenção: O CSS personalizado pode causar problemas com o funcionamento do site, use com cuidado.</small>
                </div>  
            </div>

            <div class="col-form-outer col-12 col-md-6 col-lg-6">
              <div class="form-group">
                <label class="label-form">Tema Portal do Aluno</label>
                <select class="form-control" v-model="typeLayoutPortalStudent">
                  <option value="">Selecione uma opção</option>
                  <option value="0">Dark</option>
                  <option value="1">Clean</option>
                </select>
                <small class="text-muted">Tema padrão para o portal do aluno</small>
                <div class="theme-description mt-2">
                    <p class="mb-1">
                      <strong>Clean:</strong> Tema com design minimalista e cores claras, proporcionando uma experiência visual leve e moderna. Ideal para uma aparência profissional e clean.
                    </p>
                    <p class="mb-0">
                      <strong>Dark:</strong> Tema com fundo escuro e elementos contrastantes, oferecendo uma experiência visual moderna e sofisticada. Reduz o cansaço visual em ambientes com pouca luz.
                    </p>
                  </div>
              </div>

              <div class="form-group mt-4">
                <label class="label-form">JS</label>
                <textarea 
                  class="form-control textarea" 
                  v-model="js" 
                  rows="6"
                  placeholder="<script>...</script>"
                ></textarea>
                <small class="text-muted">JS personalizado para o portal do aluno</small>
                <small class="small alert">Atenção: O JS personalizado pode causar problemas com o funcionamento do site, use com cuidado.</small>
              </div>
            </div>
          </div>
        </div>

        <div class="content-button site">
          <div class="button">
            <button type="button" class="btn btn-block btn-content" @click="save()" :disabled="loading">
              <span v-if="!loading">Salvar e Publicar</span>
              <div v-else class="spinner-border spinner-border-sm" role="status">
                <span class="visually-hidden">Carregando...</span>
              </div>
            </button>
            <button type="button" class="btn btn-block btn-content" @click="clearCache()" :disabled="loading">
              <span v-if="!loading">Limpar Cache</span>
              <div v-else class="spinner-border spinner-border-sm" role="status">
                <span class="visually-hidden">Carregando...</span>
              </div>
            </button>
          </div>
        </div>
        <Loader :isActive="hasReady" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import apiSiteAdvanced from '@/utils/Site/advanced';
import Loader from '@/components/Loader/loader.vue';
import websiteApi from '@/utils/Weflix/website';


export default {
  data() {
    return {
      typeLayoutPortalEcommerce: '',
      typeLayoutPortalStudent: '',
      css: '',
      js: '',
      loading: false,
      hasReady: true,
    };
  },
  computed: {
    ...mapState(['token']),
  },
  components: {
    Loader
  },
  methods: {
    async getAdvancedData() {
      try {
        const response = await apiSiteAdvanced.getAdvancedData(this.token);
        this.typeLayoutPortalEcommerce = response.typeLayout;
        this.typeLayoutPortalStudent = response.typeLayoutPortal;
        this.css = response.css;
        this.js = response.javascript;
        this.hasReady = true;
      } catch (error) {
        console.error('Erro ao obter dados avançados:', error);
      }
    },
    async save() {
      this.hasReady = false;
      const payload = {
        typeLayout: this.typeLayoutPortalEcommerce,
        typeLayoutPortal: this.typeLayoutPortalStudent,
        css: this.css,
        javascript: this.js,
      }
      try {
        const response = await apiSiteAdvanced.putAdvancedData(this.token, payload);
        await this.clearCache();
        await this.getAdvancedData();
        this.hasReady = true;
      } catch (error) {
        console.error('Erro ao salvar dados avançados:', error);
      }
    },
    async clearCache(){
      try {
        const response = await websiteApi.clearCache(this.token);
        this.messageStatusCache = true;
      } catch (error) {
        console.error('Erro ao limpar cache', error);
      }
    },
  }, 
  created() {
    this.getAdvancedData();
  }
}
</script>

<style scoped>
.form-group {
  text-align: left;
}

.label-form {
  font-weight: 500;
  margin-bottom: 0.5rem;
  display: block;
}

.text-muted {
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

.content-button.site {
  margin-top: 2rem;
}

.btn-content {
  background-color: #160266;
  color: white;
}

.btn-content:hover {
  background-color: #0d0149;
}
textarea.form-control.textarea {
    height: 300px !important;
}
.theme-description.mt-2 p {
    font-size: 11px;
}
small.small.alert {
    color: red;
    display: block;
    margin: 0 !important;
    padding: 0 !important;
    font-size: 10px;
}
/* DARK */
.dashboard.dark .config-form-content.adminWeeducAdvanced .text-muted,
.dashboard.dark .config-form-content.adminWeeducAdvanced .theme-description.mt-2 p{
  color: white !important;
}
.dashboard.dark .config-form-content.adminWeeducAdvanced .form-control {
  background-color: #575757;
  color: white;
  border: 1px solid #444;
}
.dashboard.dark .config-form-content.adminWeeducAdvanced textarea.form-control.textarea {
  background-color: #575757 !important;
  color: white !important;
  border: 1px solid #444 !important;
}
</style>
