<template>
    <div class="reply_item" v-for="item in data" :key="item.id">
        <div class="content_reply" v-html="item.message">
        </div>
        <div class="autor">
            <span class="logo">{{obterIniciais(item.userName)}}</span>
            <span class="name">{{item.userName}}</span>
            <span class="date">{{ formatarData(item.dateInsert) }}</span>
        </div>
    </div>
</template>

<script>
export default{
    data(){
        return{
            data:[],
        }
    },
    props:{
        id:String,
        data:Array,
    },
    methods:{
        formatarData(data) {
            const dataObj = new Date(data);
            const dia = dataObj.getDate();
            const mes = dataObj.getMonth() + 1;
            const ano = dataObj.getFullYear() % 100; 
            const diaFormatado = dia < 10 ? "0" + dia : dia;
            const mesFormatado = mes < 10 ? "0" + mes : mes;
            const anoFormatado = ano < 10 ? "0" + ano : ano;

            return `${diaFormatado}/${mesFormatado}/${anoFormatado}`;
        },
        obterIniciais(nome) {
            const palavras = nome.split(" "); 
            let iniciais = "";
            let contador = 0;
            for (let i = 0; i < palavras.length && contador < 2; i++) {
                const palavra = palavras[i];
                if (palavra.length > 0) {
                iniciais += palavra[0].toUpperCase();
                contador++;
                }
            }

            return iniciais;
            }
    },
    // watch:{
    //     id(newValue){
    //         this.getReplyInquiry();
    //     }
    // }
}
</script>
<style scoped>
.reply_item {
    background-color: #f3f3f3;
    margin: 10px 0px;
    margin: 10px 0px;
    padding: 20px;
    border-radius: 5px;
}
</style>