<template>
    <div class="container users-container">

        <Loader v-if="!hasReady" />
        <div v-else class="table-responsive users-table-responsive">
            <table class="table users-table">
                <thead>
                    <tr>
                        <th style="width: 20%; text-align: left; border-right: none !important;">Nome</th>
                        <th style="width: 20%; border-right: none !important; border-left: none !important;">E-mail</th>
                        <th style="width: 15%; border-right: none !important; border-left: none !important;">Telefone</th>
                        <th style="width: 15%; border-right: none !important; border-left: none !important;">CPF</th>
                        <th style="width: 20%; border-right: none !important; border-left: none !important;">Endereço</th>
                        <th style="width: 10%; border-right: none !important; border-left: none !important;">Estado</th>
                        <th style="width: 10%; border-left: none !important;">Cidade</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="user in paginatedUsers" :key="user.id">
                        <td style="width: 20%; text-align: left; border-right: none !important;">{{ user.name }}</td>
                        <td style="width: 20%; border-right: none !important; border-left: none !important;">{{ user.email }}</td>
                        <td style="width: 10%; border-right: none !important; border-left: none !important;">{{ user.phone }}</td>
                        <td style="width: 10%; border-right: none !important; border-left: none !important;">{{ user.socialNumber }}</td>
                        <td style="width: 20%; border-right: none !important; border-left: none !important;">{{ user.address }}</td>
                        <td style="width: 10%; border-right: none !important; border-left: none !important;">{{ user.county }}</td>
                        <td style="width: 10%; border-left: none !important;">{{ user.city }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="pagination users-pagination">
            <button 
                :disabled="currentPage === 1" 
                @click="previousPage"
                class="page-btn users-page-btn"
            >
                Anterior
            </button>
            <span class="page-info users-page-info">Página {{currentPage}} de {{totalPages}}</span>
            <button 
                :disabled="currentPage === totalPages" 
                @click="nextPage"
                class="page-btn users-page-btn"
            >
                Próxima
            </button>
        </div>
    </div>
</template>

<script>
import Loader from '@/components/Loader/loader.vue';
import api from "@/utils/reports.js";
import { mapState } from "vuex";

export default {
    data() {
        return {
            label: this.labels,
            userFiltered: [],
            searchTerm: '',
            status: 'all',
            currentPage: 1,
            itemsPerPage: 5,
            alertOpenCourse: false,
            removedcoursename: '',
            afterWidth: 40,
            usersInfo: [],
            openModalCreateWarning: false,
            openModalEditWarning: false,
            hasReady: false,
            indiceItem: [false, false, false, true],
            isMobileIcon: false,
        };
    },
    props: {
        labels: Array
    },
    computed: {
        ...mapState(['token', 'numberIntensTable']),
        paginatedCourses() {
            if (!Array.isArray(this.userFiltered)) {
                return [];
            }
            const startIndex = (this.currentPage - 1) * this.numberIntensTable;
            const endIndex = startIndex + this.numberIntensTable;
            return this.userFiltered.slice(startIndex, endIndex);
        },
        totalPages() {
            return Math.ceil(this.userFiltered.length / this.numberIntensTable);
        },
    },
    methods: {
        exportToCSV() {
            const columnHeaders = ["ID", "Nome", "Email", "Telefone", "CPF", "CEP", "Endereço", "Número", "Complemento", "Estado", "Cidade", "País"];
            let csvContent = "data:text/csv;charset=utf-8,";
            const headers = columnHeaders.join(","); 
            csvContent += headers + "\n";

            csvContent += this.userFiltered.map(user => {
                return [
                    user.id,                                  // ID
                    user.name,                                // Nome
                    user.email,                               // Email
                    user.phone,                               // Telefone
                    user.socialNumber,                        // CPF
                    user.postcode,                            // CEP
                    user.address,                             // Endereço
                    user.addressNumber,                       // Número
                    user.addressComplement || "",             // Complemento (vazio se não disponível)
                    user.county,                              // Estado
                    user.city,                                // Cidade
                    user.country
                ].join(",");
            }).join("\n");

            const encodedUri = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "relatorio_usuarios.csv");
            document.body.appendChild(link); 
            link.click();
        },
        async getDataReport() {
            try {
                const response = await api.getDataReportUser(this.token);
                this.usersInfo = response.userList;
                this.hasReady = true;
                this.userFiltered = response.userList;
            } catch (error) {
                console.error('Erro Report Users', error)
            }
        },
        filteredUsers() {
            if (this.searchTerm.trim() === '') {
                this.userFiltered = this.usersInfo;
            } else {
                this.userFiltered = this.usersInfo.filter((user) =>
                    user.name.toLowerCase().includes(this.searchTerm.toLowerCase())
                );
            }
            return this.userFiltered;
        },
        previousPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },
    },
    components: {
        Loader
    },
    created() {
        this.getDataReport();
    },
};
</script>

<style scoped>
.container {
    max-width: 100%;
    background-color: #ffffff;
    padding: 0 !important;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}
.search-form-input {
    position: relative;
    width: 100%;
}
.title-container {
    position: relative;
    margin-bottom: 10px;
    padding-bottom: 0;
    border-bottom: 1px solid #f0f0f0;
    text-align: left;
}

.title-container .title {
    font-size: 1.5rem;
    font-weight: 700;
    color: #000000;
    margin-bottom: 0.5rem;
}

.title-container .subtitle {
    color: #6b7280;
    font-size: 0.875rem;
    line-height: 1.4;
    margin-bottom: 1rem;
}

.search-row {
    display: flex;
    gap: 0.75rem;
    margin-bottom: 1.5rem;
}

.search-row .form-group {
    flex: 1;
}

.btn-weflix.absolute {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #2f2e8b;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: 600;
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(47, 46, 139, 0.2);
}

.btn-weflix.absolute:hover {
    background-color: #23225f;
    transform: translateY(-2px);
}

.users-table-responsive {
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 1rem;
}

.users-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
}

.users-table th {
    background-color: #f8fafc;
    padding: 0.75rem;
    font-weight: 600;
    color: #4a5568;
    text-align: left;
    border-bottom: 2px solid #e2e8f0;
}

.users-table td {
    padding: 0.75rem;
    vertical-align: middle;
    border-bottom: 1px solid #f0f0f0;
    color: #4a5568;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.users-table tr:hover {
    background-color: #f8fafc;
}

.badge-active, .badge-inactive {
    padding: 0.25rem 0.75rem;
    border-radius: 5px;
    font-size: 0.75rem;
    font-weight: 500;
    display: inline-block;
    text-align: center;
    transition: all 0.2s ease;
    width: 70%;
}

.badge-active {
    background-color: #7bcc8180;
    color: #4b614e;
}

.badge-inactive {
    background-color: #d7d7ee80;
    color: #2f2e8b;
}

.user-btn-edit {
    padding: 0.4rem;
    margin: 0 0.25rem;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    color: #2f2e8b;
}

.search-form-input {
    position: relative;
}

.search-form-control {
    width: 100%;
    padding: 0.5rem 0.75rem;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    font-size: 0.875rem;
    transition: all 0.2s ease;
}

.search-form-control:focus {
    outline: none;
    border-color: #2f2e8b;
    box-shadow: 0 0 0 3px rgba(47, 46, 139, 0.1);
}

.search-input-icon {
    position: absolute;
    right: 0.75rem;
    top: 45%;
    transform: translateY(-50%);
    color: #9ca3af;
    cursor: pointer;
}

.users-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
    padding-bottom: 10px;
}

.users-page-btn {
    padding: 0.5rem 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    background: white;
    color: #4a5568;
    cursor: pointer;
    transition: all 0.2s;
}

.users-page-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.users-page-btn:not(:disabled):hover {
    background: #f8fafc;
    border-color: #cbd5e0;
}

.users-page-info {
    color: #4a5568;
    font-size: 0.875rem;
}

@media (max-width: 768px) {
    .container {
        padding: 1rem;
    }

    .title-container {
        margin-bottom: 1.5rem;
    }

    .search-row {
        flex-direction: column;
    }

    .users-table th, .users-table td {
        padding: 0.5rem;
    }
}

@media (max-width: 600px) {
    .btn-weflix.absolute {
        padding: 0.5rem;
    }
    
    .btn-weflix.absolute span {
        display: none;
    }

    .users-table-responsive {
        overflow-x: auto;
    }
}
.content.dashboard > .container {
    background-color: white;
    padding: 20px;
    padding-bottom: 35px;
    box-shadow: 0px 6px 10px 0px #dfdfdf;
    min-height: 89vh;
    padding-bottom: 0;
}
select.form-control.filter-form-control {
    width: 60%;
}
.form-group.filter-form-group {
    justify-content: space-around;
    align-items: center;
    margin: 0 !important;
}
.form-group.search-form-group {
    margin: 0 !important;
}
table.table.users-table {
    border: none !important;
    padding: 0 !important;
}
button.btn-edit.user-btn-edit svg {
    color: #2f2e8b;
}
.form-group.filter-form-group {
    display: flex;
}
.btn-weflix.absolute span.flex {
    font-size: 16px;
}
.btn-weflix.absolute svg {
    color: white;
    font-size: 15px;
}
/* DARK */
.dashboard.dark .users-table-responsive {
    background-color: #000 !important;
}
.dashboard.dark .weeducAdminReports .page-info.users-page-info {
    color: white;
}
.dashboard.dark .weeducAdminReports .users-table th {
    border-bottom: 2px solid #575757 !important;
}
.dashboard.dark .weeducAdminReports .users-table td {
    border-bottom: 2px solid #575757 !important;
}
</style>
