<template>
    <div class="siteTheme2">
    <Header @toggleModal="toggleOpenDialog" @toggleModalSubscribe="toggleOpenSubscribe"/>
    <div class="content">
        <div class="contentLeft" :style="{backgroundImage: `url(${banner})`}" style="width: 50%;background-size: cover;background-position: center;margin: 10px;">
        </div>
        <div class="contentRight">
            <div class="contentRightTop">
                <h2 class="title" :style="{color: finalWebsiteLocal?.color}">{{ finalWebsiteLocal?.title }}</h2>
                <p class="description">{{ finalWebsiteLocal?.description }}</p>
                <button class="btn-weflix-theme2" @click="toggleOpenDialog" :style="{background: finalWebsiteLocal?.color}">Assine a partir de R$ {{ this.plans[0]? this.plans[0].planValue.toLocaleString('pt-BR', {minimumFractionDigits: 2}) : '0,00' }} / mês</button>
            </div>
            <div class="showMore">
                <router-link to="/colecoes-cursos">Veja todos</router-link>
            </div>
            <CoursesSlide/>
        </div>
    </div>
    <Footer/>
</div>
<Login :show="openDialogLogin" @toggleModal="toggleOpenDialog"/>
<Subscribe :show="openDialogSubscribe" @toggleModal="toggleOpenSubscribe"/>
</template>

<script>
import Header from '@/components/Weflix/LigthTheme/Header.vue';
import Footer from '@/components/Weflix/LigthTheme/Footer.vue';
import Login from '@/components/Weflix/Login.vue'
import Subscribe from '@/components/Weflix/Subscribe.vue'
import CoursesSlide from '@/components/Weflix/LigthTheme/CoursesSlide.vue';
import ApiWebsite from '@/utils/Weflix/Website/index';
import ApiPlans from '@/utils/Weflix/Website/plans';
import { mapState, mapActions } from 'vuex';


export default {
    data() {
        return {
            show: false,
            finalWebsiteLocal: null,
            openDialogLogin:false,
            openDialogSubscribe:false,
            plans:[],
            banner: null,
           baseUrl: process.env.NODE_ENV === 'development' ? process.env.VUE_APP_BASE_URL_HOMOLOGATION  : window.location.origin,


        }
    },
    computed: {
        ...mapState(['token'])
    },
    methods: {
        ...mapActions(['storeFinalWebsite', 'storePlansWeflix']),
        async getWebsite() {
            const payload = {
                url:this.baseUrl
            }
            try {
                const response = await ApiWebsite.getWebsite(payload);
                this.finalWebsiteLocal = response;
                this.storeFinalWebsite({finalWebsite: this.finalWebsiteLocal});
            } catch (error) {
                console.error('Erro ao obter dados do website:', error);
            }
           
        },
        async getPayments(){
            const payload = {   
                url:this.baseUrl
            }
            try {
                const response = await ApiPlans.getPlans(payload);
                this.plans = response.sort((a, b) => a.planValue - b.planValue);
                this.storePlansWeflix({plansWeflix: this.plans});
            } catch (error) {
                console.error('Erro ao obter dados dos pagamentos:', error);
            }
        },
        async getBanner(){
            const payload = {
                url:this.baseUrl
            }
            try {
                const response = await ApiWebsite.getBanner(payload);
                this.banner = response[0].pathImageFinal;
            } catch (error) {
                console.error('Erro ao obter dados do banner:', error);
            }
        },
        toggleOpenDialog(){
            this.openDialogLogin = !this.openDialogLogin;
        },
        toggleOpenSubscribe(){
            if(this.finalWebsiteLocal?.company && this.finalWebsiteLocal.company.cartStatus === 1){
                this.openDialogSubscribe = !this.openDialogSubscribe;
            }
        },
    },
    components: {
        Header,
        Footer,
        CoursesSlide,
        Login,
        Subscribe
    },
    mounted() {
        this.getWebsite();
        this.getPayments();
        this.getBanner();
    },
    created(){
    }
}
</script>

<style scoped>
.siteTheme2 {
    background-color: white;
}
.content {
    display: flex;
    min-height: 78vh;
    max-width: 1500px;
    margin: 0 auto;
    padding-top: 100px;
    padding-bottom: 50px;
}
.contentRightTop {
    margin-top: 110px;
    text-align: left;
}
h2.title {
    font-size: 80px;
    letter-spacing: 1.6px;
    line-height: 1;
    text-align: left;
    font-weight: 700;
}
p.description {
    color: rgb(39, 47, 58);
    font-size: 16px;
    line-height: 24px;
    margin: 21px 2px 0px 0px;
    display: block;
}
button.btn-weflix-theme2 {
    background-color: v-bind("finalWebsiteLocal?.color");
    font-size: 15px;
    font-weight: bold;
    display: inline-block;
    line-height: 24px;
    color: rgb(255, 255, 255);
    border: none;
    border-radius: 4px;
    padding: 8px 7.5px;
    margin-top: 22px;
    margin: 10px 0px;
    font-size: 18px;
    font-weight: bold;
    line-height: 28px;
    padding: 10px 20px;
}
.contentRight {
    width: 50%;
}
.showMore {
    text-align: right;
}
.showMore a {
    color: v-bind("finalWebsiteLocal?.color");
}
@media (max-width: 600px) {
    .showMore {
        margin-top: 20px;
        margin-bottom: -30px;
    }
    .content {
        flex-direction: column;
    }
    .contentLeft {
        width: 95%!important;
        height: 350px;
        margin-top: 0;
    }
    .contentRight {
        width: 100%!important;
        padding: 10px;
    }
    .contentRightTop {
        margin-top: 0;
        text-align: center;
    }
    h2.title {
        font-size: 40px;     
        display: ruby;
    }
}
</style>
