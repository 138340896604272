<template>
    <div class="infoCollections">
        <div class="itensInfoCollections">
            <div class="item" v-for="i in 4" :key="i">
                <img :src="require(`@/assets/Weflix/icons/${images[i-1]}.svg`)" alt="Icon"/>
                <span>{{values[i-1]}}</span>
                <p>{{titles[i-1]}}</p>
            </div>
        </div>
    </div>
</template>
<script>
import ApiSite from '@/utils/Weflix/Website/index';
export default {
    data(){
        return{
            images:['icon-copy', 'icon-video', 'icon-time', 'icon-assinatura'],
            values:[4,50,100,60],
            titles:['Coleções criadas', 'Vídeos publicados', 'Horas de vídeo', 'Assinaturas'],
           baseUrl: process.env.NODE_ENV === 'development' ? process.env.VUE_APP_BASE_URL_HOMOLOGATION  : window.location.origin,

        }
    },
    methods:{
        async getStats(){
            const aux = this.baseUrl.replace(/\/$/, '');
          
          const payload = {
              "url": aux
          }
           try{
            const response = await ApiSite.getStats(payload);
            this.values[0] = response.totalCollections;
            this.values[1] = response.totalVideos;
            this.values[2] = new Intl.NumberFormat('pt-BR').format(Math.round(response.totalVideoTime / 3600));
            this.values[3] = response.totalSubscribers;
           }catch(error){
            console.error(error);
           }
        },

        formatTime(minutes) {
            const totalSeconds = Math.round(minutes * 60);
            const hours = Math.floor(totalSeconds / 3600);
            const mins = Math.floor((totalSeconds % 3600) / 60);
            const secs = totalSeconds % 60;
            return `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
        }
    },
    created(){
        this.getStats();
    }

}
</script>
<style scoped>
.itensInfoCollections {
    display: flex;
    max-width: 900px;
    margin: 0 auto;
}
.itensInfoCollections .item {
    display: block;
    width: 25%;
    border: none;
    background-color: black;
}
.itensInfoCollections .item svg {
  font-size: 20px;
}
.itensInfoCollections .item path {
    fill: white;
}
.item img {
    height: 32px;
}
.item span {
    display: block;
    color: white;
    margin: 10px;
    font-weight: bolder;
    font-size: 30px;
}
.item p {
    color: white;
    font-size: 20px;
}
@media (max-width: 768px) {
    .item p {
        color: white;
        font-size: 14px;
    }
    .item span {
        font-size: 18px;
    }
}
</style>
