<template>
    <section class="globalPlayer">
        <Header @toggleModal="toggleOpenDialog" @toggleModalSubscribe="toggleOpenSubscribe"/>
        
        <div class="main-content">
            <div class="video-side">
                <button class="btn-voltar" @click="$router.push(`/videos-colecao/${backToCollectionId}`)">
                    <fa icon="fa-arrow-left"/> Voltar
                </button>
                <div class="video-container">
                    <iframe 
                        v-show="hasReady"
                        :src="videoPlayer"
                        width="100%"
                        :height="heightPlayer"
                        frameborder="0"
                        allow="autoplay; fullscreen"
                        allowfullscreen>
                    </iframe>
                    <Loader :hasReady="hasReady"/>
                </div>

                <div class="video-info">
                    <h1 class="video-title">{{infoVideo.title}}</h1>
                    <!-- <div class="meta-info">
                        <div class="views">1.2M visualizações</div>
                        <div class="actions">
                            <button class="action-btn">
                                <fa icon="thumbs-up"/> Gostei
                            </button>
                            <button class="action-btn">
                                <fa icon="thumbs-down"/> Não gostei
                            </button>
                            <button class="action-btn">
                                <fa icon="share"/> Compartilhar
                            </button>
                        </div>
                    </div> -->
                    <div class="description">
                        <p>{{infoVideo ? stripTags(infoVideo.description) : ''}}</p>
                    </div>
                </div>
            </div>

            <div class="related-side">
                <VideoCollections v-show="showMoreVIdeo" :videos="relatedVideos"/>
                <FileAttchments v-show="!showMoreVIdeo"/>
            </div>
        </div>

        <section v-if="userPortalWeflix.type !=1 || userPortalWeflix.user.orderStatus !=4">
            <div class="subscription secundary">
                <div class="text">
                    <h1>Assine e tenha acesso ilimitado a partir de R$ 5,99 /mês</h1>
                    <button class="btnHedaer">ASSINE AGORA</button>    
                </div>
            </div>
        </section>
        <Footer/>
    </section>
</template>

<script>
import Header from "@/components/Weflix/Hedaer.vue"
import Footer from '@/components/Weflix/Footer.vue'
import VideoCollections from '@/components/Weflix/CollectionsPlayer.vue'
import FileAttchments from '@/components/Weflix/AttachmentFiles.vue'
import playerVideo from '@/utils/storage/playerVideo'
import { mapState } from 'vuex'
import Loader from '@/components/Weflix/Loader'
import ApiWebsite from '@/utils/Weflix/Website/index'

export default {
    data(){
        return{
            openDialogLogin:false,
            openDialogSubscribe:true,
            showMoreVIdeo:true,
            videoPlayer:`https://weeduc.videotecaead.com.br/Embed/iframe/?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZGVudGlmaWVyIjoiVEVTVEUzIiwic2FmZXR5cGxheWVyIjoiIiwic2FmZXR5IjoiIiwibWF0cmljdWxhIjoiIiwiYWx1bm9faWQiOiIiLCJub21lIjoiIiwiYWx1bm9fbm9tZSI6IiIsImVtYWlsIjoiIiwiYWx1bm9fZW1haWwiOiIiLCJpYXQiOjE3MjgzMzEzMDAsIm5iZiI6MTcyODMzMTMwMCwiZXhwIjoxNzI4MzMxNjAwfQ.747VBcqa2Nkh6nWZHsZY4GTQivgpQk2YE1gmMdUIFZg`,
            heightPlayer:400,
            hasReady: false,
            infoVideo:{},
            relatedVideos:[],
           baseUrl: process.env.NODE_ENV === 'development' ? process.env.VUE_APP_BASE_URL_HOMOLOGATION  : window.location.origin,

            backToCollectionId:null
        }
    },
    components:{
        Header,
        Footer,
        VideoCollections,
        FileAttchments,
        Loader
    },
    computed:{
        ...mapState(['userPortalWeflix'])
    },
    methods:{
        toggleVideosAnexos(){
            this.showMoreVIdeo = !this.showMoreVIdeo;
        },
        verifyHeight(){
            const height = window.innerWidth;
            if(height < 450){
                this.heightPlayer = 300;
            } else {
                this.heightPlayer = 600;
            }
        },
        async getPlayerVideo(){
            const id = this.$route.params.slug;
            try {
                const response = await playerVideo.getPlayerVideo(this.userPortalWeflix.acessToken,id);
                this.videoPlayer = response;
                setTimeout(() => {
                    this.hasReady = true;
                }, 2000);
            } catch (error) {
                console.error('Erro ao cobter o player:', error);
            }
        },
        async getInfoVideo(){
            const id = this.$route.params.slug;
            const aux = window.location.href ? window.location.href.replace(/\/$/, '') : this.baseUrl;
          
            const payload = {
                "url": aux
            }
            try {
                const response = await ApiWebsite.getInfoVideo(payload,id);
                this.getVideosByCollections(response.idCourse);
                this.infoVideo = response;
            } catch (error) {
                console.error('Erro ao obter dados do video:', error);
            }
        },
        async getVideosByCollections(id){
            const aux = this.baseUrl.split('/player/')[0].replace(/\/$/, '');
          
            const payload = {
                "url": aux
            }
            try {
                const response = await ApiWebsite.getVideosByCollection(id, payload, 0);
                this.relatedVideos = response.lessons.sort((a, b) => a.order - b.order);
                this.backToCollectionId = id;
            } catch (error) {
                console.error('Erro ao obter dados dos videos:', error);
            }
        },
        stripTags(text) {
            if (!text) return '';
            return text.replace(/<[^>]*>?/g, '');
        }
    },
    created(){
        this.verifyHeight();
        this.getPlayerVideo();
        this.getInfoVideo();
    }
}
</script>

<style scoped>
.globalPlayer {
    background-color: #0f0f0f;
    min-height: 100vh;
}

.main-content {
    display: flex;
    max-width: 1500px;
    margin: 0 auto;
    padding: 20px;
    gap: 24px;
    padding-top: 110px;
}

.video-side {
    flex: 0 0 70%;
    position: relative;
}

.related-side {
    flex: 0 0 28%;
}

.related-side h3 {
    color: #fff;
    font-size: 16px;
    margin-bottom: 20px;
    padding-left: 10px;
}

.video-container {
    position: relative;
    padding-top: 56.25%;
    background: #000;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.video-info {
    padding: 20px 0;
}

.video-title {
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 10px;
    text-align: left;
}

.meta-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #aaa;
    font-size: 14px;
    padding: 10px 0;
    border-bottom: 1px solid #272727;
}

.actions {
    display: flex;
    gap: 20px;
}

.action-btn {
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    padding: 8px 16px;
    border-radius: 18px;
    background: #272727;
}

.action-btn:hover {
    background: #3f3f3f;
}

.description {
    padding: 20px 0;
    color: #fff;
    font-size: 14px;
    line-height: 1.5;
    text-align: left;
}

.btn-voltar {
    position: absolute;
    top: -48px;
    left: 0;
    background: rgb(50 50 50);
    border: none;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    padding: 8px 16px;
    border-radius: 18px;
    z-index: 1000;
}

.subscription {
    background: #272727;
    padding: 40px 20px;
    text-align: center;
    margin-top: 40px;
}

.subscription h1 {
    color: #fff;
    font-size: 24px;
    margin-bottom: 20px;
}

.btnHedaer {
    background: #c00;
    color: #fff;
    border: none;
    padding: 12px 24px;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
}

@media (max-width: 1024px) {
    .main-content {
        flex-direction: column;
    }
    
    .video-side,
    .related-side {
        flex: 0 0 100%;
    }
    
    .meta-info {
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
    }
    
    .actions {
        width: 100%;
        justify-content: space-between;
    }
}

@media (max-width: 600px) {
    .main-content {
        padding: 100px 10px;
    }
    
    .video-title {
        font-size: 18px;
    }
    
    .action-btn {
        padding: 6px 12px;
        font-size: 12px;
    }
}
</style>
