<template>
    <div class="success-page" v-if="dataPaymentWeflix && itemCheckoutWeflix">
        <div class="container">
            <div class="success-content" ref="content">
                <div class="logo">
                    <img :src="websites.logoFinal"/>
                </div>

                <div class="success-icon">
                    <fa icon="fa-circle-check" class="check-icon"/>
                </div>
                
                <div class="success-message">
                    <h1>Parabéns! Seu pedido foi confirmado!</h1>
                    <p class="info-email">Em breve você receberá um email com os detalhes do seu pedido.</p>
                </div>

                <div class="order-details">
                    <div class="order-number">
                        <span>Pedido: #{{ dataPaymentWeflix?.idOrder }}</span>
                    </div>
                    <div class="order-date">
                        <span>Data: {{ formatarData(dataPaymentWeflix?.user?.dateInsert) }}</span>
                    </div>
                    <div class="order-status">
                        <span class="status approved">Pagamento Aprovado</span>
                    </div>
                </div>

                <div class="plan-details">
                    <h3>Detalhes do Plano</h3>
                    <div class="plan-info">
                        <p><strong>Nome do Plano:</strong> {{ itemCheckoutWeflix?.name }}</p>
                        <p><strong>Preço:</strong> R$ {{ itemCheckoutWeflix?.price?.toFixed(2) }}</p>
                        <p><strong>Tipo:</strong> {{ itemCheckoutWeflix?.type === 1 ? 'Mensal' : 'Anual' }}</p>
                    </div>
                </div>

                <div class="customer-details">
                    <h3>Dados do Cliente</h3>
                    <div class="customer-info">
                        <p><strong>Nome:</strong> {{ dataPaymentWeflix?.user?.name }}</p>
                        <p><strong>Email:</strong> {{ dataPaymentWeflix?.user?.email }}</p>
                        <p><strong>Telefone:</strong> {{ dataPaymentWeflix?.user?.phone?.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3') }}</p>
                        <p><strong>Endereço:</strong> {{ dataPaymentWeflix?.user?.address }}, {{ dataPaymentWeflix?.user?.addressNumber }}</p>
                        <p><strong>Complemento:</strong> {{ dataPaymentWeflix?.user?.addressComplement }}</p>
                        <p><strong>Bairro:</strong> {{ dataPaymentWeflix?.user?.neighborhood }}</p>
                        <p><strong>Cidade:</strong> {{ dataPaymentWeflix?.user?.city }} - {{ dataPaymentWeflix?.user?.county }}</p>
                        <p><strong>CEP:</strong> {{ dataPaymentWeflix?.user?.postcode?.replace(/(\d{5})(\d{3})/, '$1-$2') }}</p>
                    </div>
                </div>

                <div class="action-buttons">
                    <button @click="redirect" class="btn-primary">
                        <fa style="color: white;" icon="fa-right-to-bracket" /> ir para o site
                    </button>
                    <button @click="exportarPDF" class="btn-export">
                        <fa style="color: white;" icon="fa-file-pdf" /> Exportar PDF
                    </button>
                </div>
            </div>
        </div>

        <footer class="footer">
            <div class="footer-content">
                <div class="logo-footer">
                    <img src="../../assets/Weflix/logo_weflix-black.png" alt="Weflix Logo"/>
                </div>
                <p class="copyright">© 2024 Weflix - Todos os direitos reservados</p>
            </div>
        </footer>
    </div>
    <div v-else>
        <div class="container">
            <div class="error-content">
                <h1>Nenhum pedido encontrado</h1>
                <button @click="redirect" class="btn-primary">
                    <fa style="color: white;" icon="fa-right-to-bracket" /> Voltar para o site
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    name: 'SuccessPage',
    data() {
        return {
        }
    },
    computed: {
        ...mapState(['websites','dataPaymentWeflix', 'itemCheckoutWeflix'])
    },
    methods: {
        ...mapActions(['logout','storeDataPaymentWeflix','storeItemCheckoutWeflix']),
        formatarData(data) {
            if (!data) return '';
            const dataObj = new Date(data);
            const ano = dataObj.getFullYear();
            const mes = ('0' + (dataObj.getMonth() + 1)).slice(-2);
            const dia = ('0' + dataObj.getDate()).slice(-2);
            return `${dia}/${mes}/${ano}`;
        },
        redirect() {
            this.storeDataPaymentWeflix({ dataPaymentWeflix: null });
            this.storeItemCheckoutWeflix({ itemCheckoutWeflix: null });
            this.$router.push('/');
        },
        exportarPDF() {
            if (!this.dataPaymentWeflix?.idOrder) return;
            
            const html2pdf = require('html2pdf.js');
            const element = this.$refs.content;
            const opt = {
                margin: [10, 10],
                filename: `pedido-${this.dataPaymentWeflix.idOrder}.pdf`,
                image: { type: 'jpeg', quality: 1 },
                html2canvas: { 
                    scale: 2,
                    useCORS: true,
                    logging: true
                },
                jsPDF: { 
                    unit: 'mm',
                    format: 'a4',
                    orientation: 'portrait',
                    putOnlyUsedFonts: true
                }
            };
            
            html2pdf().set(opt).from(element).save();
        }
    },
    mounted() {
        const link = document.querySelector("link[rel~='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'icon';
        link.href = this.websites.faviconFinal;
        document.getElementsByTagName('head')[0].appendChild(link);
    }
}
</script>

<style scoped>
.success-page {
    min-height: 100vh;
    background: linear-gradient(135deg, #f5f7fa 0%, #ffffff 100%);
    display: flex;
    flex-direction: column;
    position: relative;
}

.success-content {
    max-width: 800px;
    margin: 60px auto;
    text-align: center;
    padding: 40px 20px;
    background: white;
    border-radius: 20px;
    box-shadow: 0 10px 30px rgba(0,0,0,0.05);
}

.logo img {
    max-width: 200px;
    height: auto;
    margin-bottom: 20px;
}

.success-icon {
    margin-bottom: 30px;
}

.success-icon .check-icon {
    font-size: 80px;
    color: #4CAF50;
}

.success-message h1 {
    color: #2C3E50;
    font-size: 32px;
    margin-bottom: 15px;
}

.info-email {
    color: #7f8c8d;
    font-size: 18px;
    margin-bottom: 30px;
}

.order-details {
    background: #f8f9fa;
    padding: 20px;
    border-radius: 12px;
    margin: 30px 0;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 15px;
}

.order-details > div {
    padding: 10px 20px;
}

.customer-details {
    background: #fff;
    padding: 20px;
    border-radius: 12px;
    margin: 30px 0;
    text-align: left;
    page-break-inside: avoid;
}

.customer-details h3 {
    color: #2C3E50;
    margin-bottom: 20px;
    text-align: center;
}

.customer-info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
}

.customer-info p {
    margin: 5px 0;
    color: #666;
    page-break-inside: avoid;
}

.plan-details {
    background: #fff;
    padding: 20px;
    border-radius: 12px;
    margin: 30px 0;
    text-align: left;
    page-break-inside: avoid;
}

.plan-details h3 {
    color: #2C3E50;
    margin-bottom: 20px;
    text-align: center;
}

.plan-info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
}

.plan-info p {
    margin: 5px 0;
    color: #666;
    page-break-inside: avoid;
}

.status.approved {
    background: #4CAF50;
    color: white;
    padding: 8px 16px;
    border-radius: 20px;
    font-size: 14px;
}

.action-buttons {
    display: flex;
    gap: 15px;
    justify-content: center;
    page-break-inside: avoid;
}

.btn-primary, .btn-export {
    background: #2196F3;
    color: white;
    border: none;
    padding: 15px 40px;
    border-radius: 30px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
    display: inline-flex;
    align-items: center;
    gap: 10px;
}

.btn-export {
    background: #FF5722;
}

.btn-primary:hover {
    background: #1976D2;
    transform: translateY(-2px);
}

.btn-export:hover {
    background: #F4511E;
    transform: translateY(-2px);
}

.footer {
    padding: 20px;
    margin-top: auto;
    box-shadow: 0 -5px 20px rgba(0,0,0,0.05);
    background: white;
    page-break-inside: avoid;
}

.footer-content {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
}

.logo-footer img {
    width: 120px;
    margin-bottom: 15px;
}

.copyright {
    color: #95a5a6;
    font-size: 14px;
}

.error-content {
    max-width: 800px;
    margin: 60px auto;
    text-align: center;
    padding: 40px 20px;
    background: white;
    border-radius: 20px;
    box-shadow: 0 10px 30px rgba(0,0,0,0.05);
}

@media print {
    .success-content {
        margin: 0;
        padding: 20px;
        box-shadow: none;
    }

    .action-buttons {
        display: none;
    }

    .customer-details,
    .order-details,
    .customer-info p {
        break-inside: avoid;
    }
}

@media (max-width: 768px) {
    .success-content {
        margin: 20px;
    }
    
    .order-details {
        flex-direction: column;
    }
    
    .success-message h1 {
        font-size: 24px;
    }

    .customer-info {
        grid-template-columns: 1fr;
    }

    .action-buttons {
        flex-direction: column;
    }
}
</style>