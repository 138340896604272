import api from '../api';

export default {

    // getDataContentSection
    async getDomainData( accessToken) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/manager/domain`,  {
                method: 'GET',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao gerar informações do domínio do site:', error);
                throw error;
        }
    },
    async putDomaintData( accessToken, payload) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.put(`/manager/domain`, payload , {
                method: 'PUT',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao editar informações de domínio do site:', error);
                throw error;
        }
    },
    async postVerifyDomaintData( accessToken, payload) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.post(`/manager/checkdomain`, payload , {
                method: 'POST',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao verificar url de dominio', error);
                throw error;
        }
    },
 }

