import api from './api'

export default{
    async getDataPnel(accessToken){
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/company/weeduc/dashboard`, {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados da dashboard:', error);
            throw error;
        }
    },
    async getDataUserDashboard(accessToken){
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/user/dashboard`, {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados da dashboard:', error);
            throw error;
        }
    },
    async getDataUserEnrolDashboard(accessToken){
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/enrol/dashboard`, {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados da dashboard:', error);
            throw error;
        }
    }
}