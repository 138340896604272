import api from '../api';

export default {
    async getDataCourses(accessToken) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/portal/course`,  {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao carregar cursos do aluno:', error);
                throw error;
        }
    },
    

    async getDataCourse(accessToken, id) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/portal/course/${id}`,  {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao carregar dados do Curso', error);
                throw error;
        }
    },

}

