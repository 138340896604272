<template>
    <div class="container users-container reportCoursesWeeduc">
        
        <!-- Cards -->
        <div class="cards-container">
            <div class="card">
                <h4>Total de Matrículas</h4>
                <p>{{ totalEnrols }}</p>
            </div>
            <div class="card">
                <h4>{{ isMobile ? 'Finalizados' : 'Cursos Finalizados' }}</h4>
                <p>{{ totalEndedCourses }}</p>
            </div>
            <div class="card">
                <h4>{{ isMobile ? 'Certificados' : 'Total de Certificados' }}</h4>
                <p>{{ totalCertificated }}</p>
            </div>
            <div class="card">
                <h4>{{ isMobile ? 'Mais Vendido' : 'Curso Mais Vendido' }}</h4>
                <p>{{ mostSellCourse.courseName }} ({{ mostSellCourse.quantity }})</p>
            </div>
        </div>
        
        <Loader v-if="!hasReady" />
        <div v-else class="table-responsive users-table-responsive">
            <table class="table users-table">
                <thead>
                    <tr>    
                        <th style="width: 20%; text-align: left; border-right: none !important;">Nome do Aluno</th>
                        <th style="width: 20%; text-align: left; border-right: none !important; border-left: none !important;">Nome do Curso</th>
                        <th style="width: 15%; text-align: left; border-right: none !important; border-left: none !important;">Atividades</th>
                        <th style="width: 15%; text-align: left; border-right: none !important; border-left: none !important;">Progresso por Aula</th>
                        <th style="width: 15%; text-align: left; border-right: none !important; border-left: none !important;">Progresso no Curso</th>
                        <th style="width: 10%; text-align: left; border-left: none !important;">Certificado</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="course in paginatedCourses" :key="course.id">
                        <td style="width: 20%; text-align: left; border-right: none !important;">{{ course.userName }}</td>
                        <td style="width: 20%; text-align: left; border-left: none !important; border-right: none !important">{{ course.courseName }}</td>
                        <td style="width: 15%; text-align: left; padding-left: 50px; border-left: none !important; border-right: none !important">{{ course.lessonQty }}</td>
                        <td style="width: 15%; text-align: left; padding-left: 50px; border-left: none !important; border-right: none !important">{{ course.progressCount }}</td>
                        <td style="width: 15%; text-align: left; padding-left: 50px; border-left: none !important; border-right: none !important">{{ course.progressPercentage }}%</td>
                        <td style="width: 10%; text-align: left; border-left: none !important">
                            <div :class="['certificado-box', course.certificate == 1 ? 'possui' : 'nao-possui']">
                                {{ course.certificate == 1 ? 'Possui' : 'Não Possui' }}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="pagination users-pagination">
            <button 
                :disabled="currentPage === 1" 
                @click="previousPage"
                class="page-btn users-page-btn"
            >
                Anterior
            </button>
            <span class="page-info users-page-info">Página {{currentPage}} de {{totalPages}}</span>
            <button 
                :disabled="currentPage === totalPages" 
                @click="nextPage"
                class="page-btn users-page-btn"
            >
                Próxima
            </button>
        </div>
    </div>
</template>

<script>
import Loader from '@/components/Loader/loader.vue';
import api from "@/utils/reports.js";
import { mapState } from "vuex";

export default {
    data() {
        return {
            label: this.labels,
            userFiltered: [],
            searchTerm: '',
            status: 'all',
            currentPage: 1,
            itemsPerPage: 5,
            alertOpenCourse: false,
            removedcoursename: '',
            afterWidth: 40,
            usersInfo: [],
            openModalCreateWarning: false,
            openModalEditWarning: false,
            hasReady: false,
            indiceItem: [false, false, false, true],
            isMobileIcon: false,
            isMobile: false,
            totalEnrols: 0,
            mostSellCourse: [],
            totalEndedCourses: 0,
            totalCertificated: 0,
            totalPages: 0,
        };
    },
    props: {
        searchTerm: {
            type: String,
            default: ''
        },
        labels: Array
    },
    computed: {
        ...mapState(['token', 'numberIntensTable']),
        paginatedCourses() {
            if (!this.userFiltered || !this.userFiltered.length) {
                return [];
            }
            const startIndex = (this.currentPage - 1) * 10;
            const endIndex = startIndex + 10;
            return this.userFiltered.slice(startIndex, endIndex);
        },
        totalPages() {
            if (!this.userFiltered || !this.userFiltered.length) {
                return 1;
            }
            return Math.ceil(this.userFiltered.length / 10);
        },
    },
    methods: {
        checkIfMobile() {
            this.isMobile = window.matchMedia('(max-width: 600px)').matches;
        },
        exportToCSV() {
            const columnHeaders = ["ID", "Nome do Usuário", "Nome do Curso", "Quantidade de Aulas", "Progresso", "Porcentagem de Progresso", "Certificado Emitido"];
            let csvContent = "data:text/csv;charset=utf-8,";
            const headers = columnHeaders.join(","); 
            csvContent += headers + "\n";

            csvContent += this.userFiltered.map(user => {
                return [
                    user.id,
                    user.userName,
                    user.courseName,
                    user.lessonQty,
                    user.progressCount,
                    user.progressPercentage ? parseFloat(user.progressPercentage).toFixed(2) : user.progressPercentage,
                    user.certificate === "1" ? "Sim" : "Não"
                ].join(",");
            }).join("\n");

            const encodedUri = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "relatorio_progresso_cursos.csv");
            document.body.appendChild(link); 
            link.click();
        },
        async getDataReport(searchTerm = '') {
            try {
                console.log('Search term received:', searchTerm);
                this.hasReady = false;
                
                if (searchTerm) {
                    // Log the exact search parameters
                    const searchParams = {
                        userName: searchTerm,
                        courseName: searchTerm
                    };
                    console.log('Sending search params to export endpoint:', searchParams);

                    const response = await api.getDataCoursesExport(this.token, searchParams);
                    console.log('Export endpoint response in component:', response);

                    this.usersInfo = response?.content || [];
                    this.userFiltered = response?.content || [];
                    this.totalEnrols = response?.totalElements || 0;
                } else {
                    // Regular paginated load
                    const response = await api.getDataCourses(this.token, {
                        pageNumber: this.currentPage,
                        pageSize: 10
                    });
                    
                    this.usersInfo = response?.content || [];
                    this.userFiltered = response?.content || [];
                    this.totalPages = response?.totalPages || 1;
                    this.totalEnrols = response?.totalElements || 0;
                }
                
                if (this.usersInfo.length > 0) {
                    this.calculateStatistics();
                }
                
                this.hasReady = true;
            } catch (error) {
                console.error('Error in getDataReport:', error);
                this.hasReady = true;
                this.usersInfo = [];
                this.userFiltered = [];
                this.totalPages = 1;
                this.totalEnrols = 0;
            }
        },
        calculateStatistics() {
            // Calculate course statistics
            const courseCount = {};
            this.usersInfo.forEach(item => {
                courseCount[item.courseName] = (courseCount[item.courseName] || 0) + 1;
            });

            this.mostSellCourse = Object.entries(courseCount)
                .reduce((max, [courseName, count]) => 
                    count > max.quantity ? {courseName, quantity: count} : max,
                    {courseName: '', quantity: 0}
                );

            this.totalEndedCourses = this.usersInfo.filter(
                course => parseFloat(course.progressPercentage) > 80
            ).length;

            this.totalCertificated = this.usersInfo.filter(
                course => course.certificate === "1"
            ).length;
        },
        async filteredUsers(searchTerm) {
            try {
                this.hasReady = false;
                const response = await api.getDataCourses(this.token, {
                    pageNumber: this.pageNumber,
                    pageSize: this.pageSize,
                    search: searchTerm || '',
                    sort: {
                        sorted: false,
                        unsorted: true
                    }
                });

                this.userFiltered = response.content;
                this.totalElements = response.totalElements;
                this.totalPages = response.totalPages;
                this.hasReady = true;

            } catch (error) {
                console.error('Error fetching filtered data:', error);
                this.hasReady = true;
            }
        },
        handleSearch(event) {
            if (event && event.type === 'keypress' && event.key !== 'Enter') {
                return;
            }
            
            this.filteredUsers(this.searchTerm);
        },
        previousPage() {
            if (this.currentPage > 1) {
                this.currentPage = Number(this.currentPage) - 1;
            }
        },
        nextPage() {
            const total = Math.ceil(this.userFiltered.length / 10);
            if (this.currentPage < total) {
                this.currentPage = Number(this.currentPage) + 1;
            }
        },
        formatarData(date) {
            return new Date(date).toLocaleDateString();
        }
    },
    components: {
        Loader
    },
    async mounted() {
        await this.getDataReport();
        this.checkIfMobile();
        window.addEventListener('resize', this.checkIfMobile);
    },
    beforeDestroy() {
        if (this.searchDebounceTimeout) {
            clearTimeout(this.searchDebounceTimeout);
        }
    }
};
</script>

<style scoped>
/* Base container */
.container.users-container {
    padding: 1.5rem;
    width: 100%;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

/* Cards container */
.reportCoursesWeeduc .cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1.5rem;
    width: 100%;
}

.reportCoursesWeeduc .card {
    background-color: #e4eef5;
    border-radius: 8px;
    padding: 1.25rem;
    flex: 1;
    min-width: 0;
    margin-top: 0px !important;
    border: none;
}

.reportCoursesWeeduc .card h4 {
    margin: 0 0 0.5rem 0;
    text-align: left;
    font-size: 16px;
    color: #333;
    overflow: hidden;
    text-overflow: ellipsis;
}

.reportCoursesWeeduc .card p {
    margin: 0;
    text-align: left;
    font-size: 17px;
    font-weight: 600;
    color: #23225f;
    word-break: break-word;
}

/* Table styles */
.table-responsive {
    overflow-x: auto;
    width: 100%;
    margin-bottom: 1.5rem;
    -webkit-overflow-scrolling: touch;
}

.table {
    min-width: 800px;
    width: 100%;
}

/* Mobile styles */
@media (max-width: 600px) {
    .container.users-container {
        padding: 1rem;
    }

    .reportCoursesWeeduc .cards-container {
        flex-wrap: wrap;
        gap: 0.5rem;
    }

    .reportCoursesWeeduc .card {
        padding: 5px;
        height: 200px;
        display: flex;
        flex-direction: column;
        min-width: 0;
    }

    /* First three cards take 1/3 width each */
    .reportCoursesWeeduc .card:nth-child(-n+3) {
        flex: 1 1 calc(33.333% - 0.5rem);
    }

    /* Last card takes full width */
    .reportCoursesWeeduc .card:last-child {
        flex: 1 1 100%;
    }

    .reportCoursesWeeduc .card h4 {
        font-size: 10px;
        margin: 0 0 5px 0;
        min-height: 26px;
        display: flex;
        align-items: flex-start;
    }

    .reportCoursesWeeduc .card p {
        font-size: 12px;
        word-break: break-word;
        margin: 0;
        flex-grow: 1;
        display: flex;
        align-items: center;
    }
}
.container.users-container {
    padding: 0;
    width: 100%;
}
.reportCoursesWeeduc .cards-container {
    display: flex;
    gap: 8px;
}
@media (max-width: 600px) {
    .reportCoursesWeeduc .card {
        padding: 5px;
        height: 80px;
        display: flex;
        flex-direction: column;
        min-width: 0;
        flex: 1;
    }
    .reportCoursesWeeduc .card h4 {
        font-size: 12px;
        margin: 0 0 5px 0;
        min-height: 26px;
        display: flex;
        align-items: flex-start;
    }
    .reportCoursesWeeduc .card p {
        font-size: 14px;
        word-break: break-word;
        margin: 0;
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.container {
    max-width: 100%;
    background-color: #ffffff;
    padding: 1.5rem;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}
.search-form-input {
    position: relative;
    width: 100%;
}
.title-container .title {
    font-size: 1.5rem;
    font-weight: 700;
    color: #000000;
    margin-bottom: 0.5rem;
}
.title-container .subtitle {
    color: #6b7280;
    font-size: 0.875rem;
    margin-bottom: 1rem;
}
.search-row {
    display: flex;
    gap: 0.75rem;
    margin-bottom: 1.5rem;
}
.search-row .form-group {
    flex: 1;
}
.users-container .btn-weflix.absolute {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #2f2e8b;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: 600;
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(47, 46, 139, 0.2);
}
.users-container .btn-weflix.absolute:hover {
    background-color: #23225f;
    transform: translateY(-2px);
}
.users-table-responsive {
    background-color: #ffffff;
    border-radius: 8px;
    margin-bottom: 1rem;
}
.users-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
}
.users-table th {
    background-color: #f8fafc;
    padding: 0.75rem;
    font-weight: 600;
    color: #4a5568;
    text-align: left;
    border-bottom: 2px solid #e2e8f0;
}
.users-table td {
    padding: 0.75rem;
    vertical-align: middle;
    border-bottom: 1px solid #f0f0f0;
    color: #4a5568;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}
.users-table tr:hover {
    background-color: #f8fafc;
}
.badge-active, .badge-inactive {
    padding: 0.25rem 0.75rem;
    border-radius: 5px;
    font-size: 0.75rem;
    font-weight: 500;
    display: inline-block;
    text-align: center;
    transition: all 0.2s ease;
    width: 70%;
}
.badge-active {
    background-color: #7bcc8180;
    color: #4b614e;
}
.badge-inactive {
    background-color: #d7d7ee80;
    color: #2f2e8b;
}
.user-btn-edit {
    padding: 0.4rem;
    margin: 0 0.25rem;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    color: #2f2e8b;
}
.search-form-input {
    position: relative;
}
.search-form-control {
    width: 100%;
    padding: 0.5rem 0.75rem;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    font-size: 0.875rem;
    transition: all 0.2s ease;
}
.search-form-control:focus {
    outline: none;
    border-color: #2f2e8b;
    box-shadow: 0 0 0 3px rgba(47, 46, 139, 0.1);
}
.search-input-icon {
    position: absolute;
    right: 0.75rem;
    top: 45%;
    transform: translateY(-50%);
    color: #9ca3af;
    cursor: pointer;
}
.users-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
    padding-bottom: 10px;
}
.users-page-btn {
    padding: 0.5rem 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    background: white;
    color: #4a5568;
    cursor: pointer;
    transition: all 0.2s;
}
.users-page-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
.users-page-btn:not(:disabled):hover {
    background: #f8fafc;
    border-color: #cbd5e0;
}
.users-page-info {
    color: #4a5568;
    font-size: 0.875rem;
}
.reportCoursesWeeduc .certificado-box {
    display: inline-block;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: white;
}
.reportCoursesWeeduc .certificado-box.possui {
    background-color: #7bcc81;
    color: #4b614e;
}
.reportCoursesWeeduc .certificado-box.nao-possui {
    background-color: #dfdff6;
    color: #555;
}
@media (max-width: 768px) {
    .container {
        padding: 1rem;
    }

    .title-container {
        margin-bottom: 1.5rem;
    }

    .search-row {
        flex-direction: column;
    }

    .users-table th, .users-table td {
        padding: 0.5rem;
    }
}
@media (max-width: 360px) {
    .reportCoursesWeeduc .card {
        padding: 5px;
    }
    .reportCoursesWeeduc .card h4 {
        font-size: 10px;
        min-height: 24px;
    }
    .reportCoursesWeeduc .card p {
        font-size: 11px;
    }
}
.content.dashboard > .container {
    background-color: white;
    padding: 20px;
    padding-bottom: 35px;
    box-shadow: 0px 6px 10px 0px #dfdfdf;
    min-height: 89vh;
    padding-bottom: 0;
}
select.form-control.filter-form-control {
    width: 60%;
}
.form-group.filter-form-group {
    justify-content: space-around;
    align-items: center;
    margin: 0 !important;
}
.form-group.search-form-group {
    margin: 0 !important;
}
table.table.users-table {
    border: none !important;
    padding: 0 !important;
}
button.btn-edit.user-btn-edit svg {
    color: #2f2e8b;
}
.form-group.filter-form-group {
    display: flex;
}
.btn-weflix.absolute span.flex {
    font-size: 16px;
}
.btn-weflix.absolute svg {
    color: white;
    font-size: 15px;
}
/* DARK */
.dashboard.dark .reportCoursesWeeduc .card {
    background-color: #575757 !important;
}
.dashboard.dark .reportCoursesWeeduc .card h4,
.dashboard.dark .reportCoursesWeeduc .card p,
.dashboard.dark .reportCoursesWeeduc .page-info.users-page-info {
    color: white !important;
}
.dashboard.dark .users-table-responsive {
    background-color: #000 !important;
}
.dashboard.dark .reportCoursesWeeduc .users-table th {
    border-bottom: 2px solid #575757 !important;
}
.dashboard.dark .reportCoursesWeeduc .users-table td {
    border-bottom: 2px solid #575757 !important;
}
</style>
