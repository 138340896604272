<template>
    <div class="course" :class="[this.status?'enable':'disable']" :style="{ backgroundImage: `url(${image})` }" @click="goCourse()">
        <div class="degrade"></div>
        <div class="infos">
            <p>{{title}}</p>
            <div class="progress_course">
                <div class="progress-container">
                    <div class="progress-bar" :style="{ width: this.data.progressPercentage+ '%' }">
                    </div>
                </div>
                {{formatNumber(this.data.course ? this.data.progressPercentage : 0)}}%
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions} from 'vuex';

export default{
    data(){
        return{

        }
    }, 
    methods:{
        ...mapActions(['storeCurrentCoursePortal']),
        goCourse(){
            this.storeCurrentCoursePortal({currentCoursePortal: this.data});
            window.location.href = `/aluno/curso/${this.id}`; 
        },
        formatNumber(value) {
            if (typeof value === 'number') {
                return value.toFixed(0);
            } else {
                let parsed = parseFloat(value);
                return isNaN(parsed) ? 'Invalid Number' : parsed.toFixed(0);
            }
        }   
    },
    props:{
        image:{
            type:String
        },
        title:{
            type:String
        },
        progress:{
            type: Number
        },
        id:{
            Number
        },
        status:{
            Boolean
        },
        data:{
            Array
        }
    }
}
</script>
<style scoped>
.degrade {
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgb(16 16 16) 0%, rgba(0,0,0,0.5858718487394958) 15%, rgba(0,212,255,0) 83%);
    width: 100%;
    height: 265px;
    position: absolute;
    border-radius: 10px;
}
.progress-container {
    width: 85%;
    height: 8px;
    background-color: #f0f0f08f;
    margin-bottom: 20px;
    position: relative;
    border-radius: 10px;
    margin: 0 auto;
}
.progress-bar {
    background-color: #19c7d0;
    height: 8px;
    border-radius: 10px;
}
</style>