<template>
  <!--Content Section-->
  <div class="config-form-content">
    <div class="content-wrapper primary">
      <div class="wrapper-inner">
        <div class="content-text">
          <div class="title">
            <h2>Redes Sociais</h2>
          </div>
        </div>
        <div class="content-form">
          <div class="row">
            <div class="col-form-outer col-12 col-md-12 col-lg-12">
              <div class="list">
                <div class="col-form-inner col-12 col-md-6 col-lg-6 social" v-for="(item, index) in names" :key="index">
                  <div class="form-group">
                    <div class="description">
                      <span>{{ item }}</span>
                    </div>
                    <div class="form-check form-switch">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                        v-model="activeSocial[index]"
                        :id="'flexSwitchCheck' + index"
                      />
                    </div>
                  </div>
                  <div class="form-group full">
                    <div class="form-input">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="www.facebook.com.br"
                        v-model="linkSocial[index]"
                        :disabled="!activeSocial[index]"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-button site">
        <div class="button">
          <button type="button" class="btn btn-block btn-content" @click="save()">
            Salvar e Publicar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/utils/site/';
import apiSiteSocialMidia from '@/utils/Site/socialMidia';
import { mapState } from 'vuex';

export default {
  data() {
    return {
      hasSaved: false,
      data:[],
      activeSocial: Array(6).fill(false),
      linkSocial: Array(6).fill(''),
      names: ['Facebook', 'Instagram', 'Youtube', 'Linkedin', 'Whatsapp', 'TikTok'],
    };
  },
  computed: {
    ...mapState(['token']),
    ...mapState(['websites'])
  },
  methods: {
    save(){
        this.editSocialMedia();
    },

    async editSocialMedia() {
      const socialData = [];
      const newItems = [];

      this.linkSocial.forEach((link, index) => {

        if(link!==''){
          const id = this.data[index] ? this.data[index].id : null;
          const status = this.activeSocial[index] ? 1 : 0;
          const url = link ? link : null;
          const type = index;
          const name = this.names[index];

          if (id) {
            // Itens existentes para atualização
            socialData.push({ id, name, type, url, status });
          } else if (this.activeSocial[index] == 1) {
            // Novos itens para criação
            newItems.push({ name, type, url, status });
          }
      }
      });
      try {
        if (socialData.length > 0) {
          // Atualiza os itens existentes
          await apiSiteSocialMidia.putSocialMidiastData(this.token, socialData);
        }

        if (newItems.length > 0) {
          // Cria novos itens
          await apiSiteSocialMidia.postSocialMidiastData(this.token, newItems);
        }

        // Recarrega os dados após a atualização/criação
        this.getDataSocialMedia();
      } catch (error) {
        console.error(error);
      }
},
   async createSocialMedia() {
      const socialData = [];  
      this.linkSocial.forEach((link, index) => {
        if(this.activeSocial[index]){
          const status = this.activeSocial[index] ? 1 : 0;
          const url = link ? link : null;
          const type =  index+1;
          const name = this.names[index];
          socialData.push({ name, type , url, status});
        }
      });
      try {
          const response = await api.addSocialMedia(this.token, socialData);
      } catch (error) {
          console.error(error);
      }
    },
    async getDataSocialMedia(){

      try {
        const response = await apiSiteSocialMidia.getSocialMidiastData(this.token);
        this.data = response;
        this.data.forEach((item) => {
          this.activeSocial[item.type] = item.status === 1;
          this.linkSocial[item.type] = item.url;
        });
      } catch (error) {
        console.error(error);
      }
    },
  },
  props: {
    saved: Boolean,
  },
  created() {
    this.getDataSocialMedia();
  },
};
</script>

<style scoped>
.social > .form-group {
  width: 120px;
}
.col-form-inner.col-12.col-md-6.col-lg-6.social {
  display: flex;
  align-items: center;
}
.form-group.full {
  width: 100%;
}
input[id^="flexSwitchCheck"] {
  width: 3em;
  height: 1.5em;
}
.content-button.site > .button {
  display: flex;
  gap: 17px;
  margin: 0em 1em;
}
.form-check-input:checked {
  background-color: #19c7d0;
  border-color: #19c7d0;
}
</style>
