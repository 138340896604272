import api from './api';

export default {
    async getPlans(accessToken) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get('/plan', {
                method: 'GET',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados dos planos:', error);
            throw error;
        }
    },
    async getPlanById(accessToken, id) {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/plan/${id}`, {
                method: 'GET',
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados dos planos:', error);
            throw error;
        }
    },
    async createPlan(accessToken, payload){
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.post('/plan', payload, {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao criar plano:', error);
            throw error;
        }
    },
    async editPlan(payload, accessToken, id){
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.put(`/plan/${id}`, payload, {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao atualizar o plano:', error);
            throw error;
        }
    },
    async editTrial(payload, accessToken){
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.put(`/company/trial`, payload, {
                headers,
            });
            return response;
        } catch (error) {
            console.error('Erro ao atualizar dados do trial:', error);
            throw error;
        }
    },
    async getTrial(accessToken){
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json', 
            };
            const response = await api.get(`/company/trial`, {
                headers,
            });
            return response.data;
        } catch (error) {
            console.error('Erro ao obter dados do trial:', error);
            throw error;
        }
    },
}
