<template>
    <div class="global_footer adm">
            <div class="logo">
                <img src="@/assets/logo-tutor-azul.png">
                
            </div>
            
            <div class="options">
                <div class="menu item">
                    <ul>
                        <li>
                            Copyright @ 2024 | Todos os direitos reservados
                        </li>
                    </ul>
                </div>
            </div>
       </div>
</template>
<script>
import { mapState } from 'vuex';

export default{
    data(){
        return{

        }
    },
    props:{
        view:Boolean
    },
    computed:{
        ...mapState(['dark'])
    }

}
</script>
<style>
    @import "./footer.css";
</style>
<style scoped>
    @media (max-width: 500px) {
        .global_footer.adm {
            position: absolute;
            width: 100%;
            bottom: 0;
            z-index: 1;
            left: 0;
        }
    }
</style>