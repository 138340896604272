<template>
  <section class="layoutLayout weflix-admin-layoutLayout">
      <div class="titlelayoutLayout">
          <h3>Personalização de Layout</h3>
          <small>Personalize cores e banners para deixar seu weflix a sua cara.
          </small>
      </div>
      <div class="col-form-inner col-6 col-md-6 col-lg-6" v-if="hasReady">
        <!-- Cores -->
        <div class="col-form-inner col-12 col-md-12 col-lg-12">
            <div class="form-group">
              <div class="form-input">
                <div class="form-label">
                  <label>Cor do Template<v-tooltip text="Campo obrigatório" location="top">
                    <template v-slot:activator="{ props }">
                      <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                    </template>
                  </v-tooltip></label>
                </div>
                <div class="inputsColors">
                  <input type="color" id="body" name="body"  v-model="color" />
                  <input
                  type="text" 
                  required
                  class="form-control"
                  v-model="color"
                  placeholder="Sua empresa"
                />
                </div>              
                <!-- <small class="alertText">Campo obrigatório e deve ser preenchido corretamente.</small> -->
                <small>Cores básica para botões e outros espaços do site.</small>
              </div>   
              <div class="form-label">
                <label>Sugestões de Cores<v-tooltip text="Campo obrigatório" location="top">
                  <template v-slot:activator="{ props }">
                    <fa v-bind="props" icon="fa-solid fa-circle-exclamation"></fa>
                  </template>
                </v-tooltip></label>
              </div>         
              <div class="wrap-suggested-colors" style="display: flex; gap: 10px;">
                <span @click="setColor('#1AB490')" style="background-color:#1AB490;"></span>
                <span @click="setColor('#28C565')" style="background-color:#28C565;"></span>
                <span @click="setColor('#2E8DD6')" style="background-color:#2E8DD6;"></span>
                <span @click="setColor('#904EAC')" style="background-color:#904EAC;"></span>
                <span @click="setColor('#B93126')" style="background-color:#B93126;"></span>
                <span @click="setColor('#E54236')" style="background-color:#E54236;"></span>
                <span @click="setColor('#E47220')" style="background-color:#E47220;"></span>
                <span @click="setColor('#EFBC11')" style="background-color:#EFBC11;"></span>
              </div>
            </div>
        </div>
        <div class="col-form-inner col-12 col-md-12 col-lg-12" style="margin-top: 20px;">
          <div class="form-group">
            <div class="form-label">
              <label>Limpar Cache</label>
              <small>Limpe o cache do site para forçar o carregamento dos arquivos mais recentes.</small>
            </div>
            <div class="form-file">
              <button class="btn_cache" @click="clearCache">
                Limpar Cache do Site
              </button>
            </div>
            <small v-if="messageStatusCache" style="color:green;">Cache limpo com sucesso, seu site está atualizado!</small>
          </div>
        </div>
        <!-- Certificados -->
        <!-- <div class="col-form-inner col-12 col-md-12 col-lg-12">
          <div class="form-group">
            <div class="form-label">
              <label>Certificado</label>
              <small>Envie a assinatura de prenchimento do certificado.
              </small>
            </div>
            <div class="form-file">
              <div class="file-wrapper primary">
                <img
                :src="logoPreview? logoPreview : logo"
                alt="Logo"
                v-show="logoPreview || logo"
              />
                <span class="thumbnail">
                  <span class="icon">
                    <svg
                      width="46"
                      height="39"
                      viewBox="0 0 46 39"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M45 19.9308L36.7274 12.3475C36.1595 11.827 35.2948 11.8047 34.7009 12.2953L18.824 25.411C18.3196 25.8277 17.6074 25.8812 17.0464 25.5446L11.1009 21.9773C10.5893 21.6703 9.94666 21.6856 9.45024 22.0166L1 27.6501"
                        stroke="#9D9FA3"
                        stroke-width="1.25714"
                      ></path>
                      <rect
                        x="1.00049"
                        y="1.40454"
                        width="43.9461"
                        height="36.1909"
                        rx="2.58507"
                        stroke="#9D9FA3"
                        stroke-width="1.25714"
                      ></rect>
                      <circle
                        cx="14.8948"
                        cy="10.6676"
                        r="3.71629"
                        stroke="#9D9FA3"
                        stroke-width="1.25714"
                      ></circle>
                    </svg>
                  </span>
                  <span class="text">
                    Clique aqui para selecionar a imagem
                  </span>
                  <small>Insira uma imagem maior que 127x127 pixels</small>

                </span>
                <input
                  class="form-control"
                  type="file"
                  @change="updateFilePathLogo"
                  accept=".png, .jpg, .jpeg, .webp"
                />
              </div>
            </div>
          </div>
          <div class="showCertificated">
            <button class="btn">
              <fa icon="fa-file-lines"/> Visualizar Certificado
            </button>
          </div>

      </div> -->
    </div>

    <div class="col-form-inner col-6 col-md-6 col-lg-6" v-if="hasReady">
        <div class="col-form-inner col-12 col-md-12 col-lg-12">
            <div class="form-group">
              <div class="form-label">
                <label>Banner Principal</label>
                <small>Para uma maior qualidade insira uma imagem maior que 1980x768 pixels</small>
              </div>
              <div class="form-file">
                <img
                  :src="bannerPreview? bannerPreview : banner"
                  alt="Logo"
                  v-show="imagePreviewBanner || banner"
                  class="bannerPreviewPrimaryBannerSite"
                />
                <div :class="bannerWrapperClasses">
                  <span class="thumbnail">
                    <span class="icon" v-if="loaderImage">
                      <svg
                        width="46"
                        height="39"
                        viewBox="0 0 46 39"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M45 19.9308L36.7274 12.3475C36.1595 11.827 35.2948 11.8047 34.7009 12.2953L18.824 25.411C18.3196 25.8277 17.6074 25.8812 17.0464 25.5446L11.1009 21.9773C10.5893 21.6703 9.94666 21.6856 9.45024 22.0166L1 27.6501"
                          stroke="#9D9FA3"
                          stroke-width="1.25714"
                        ></path>
                        <rect
                          x="1.00049"
                          y="1.40454"
                          width="43.9461"
                          height="36.1909"
                          rx="2.58507"
                          stroke="#9D9FA3"
                          stroke-width="1.25714"
                        ></rect>
                        <circle
                          cx="14.8948"
                          cy="10.6676"
                          r="3.71629"
                          stroke="#9D9FA3"
                          stroke-width="1.25714"
                        ></circle>
                      </svg>
                    </span>
                    <span class="text" v-if="loaderImage">
                      Clique aqui para selecionar a imagem
                    </span>
                    <LoaderImage :hasReady="loaderImage"/>
                  </span>
                  <input
                    class="form-control"
                    type="file"
                    @change="updateFilePathBanner"
                    accept=".png, .jpg, .jpeg, .webp"
                  />
                </div>
              </div>
            </div>
          </div>
         
          <!-- <div class="col-form-inner col-12 col-md-12 col-lg-12">
            <div class="form-group">
              <div class="form-label">
                <label>Banner Sobre</label>
                <small>Banner principal da página sobre, Tamanho mínimo de 1920x480 pixels.</small>

              </div>
              <div class="form-file">
                <div class="file-wrapper primary">
                  <img
                    :src="faviconPreview ? faviconPreview : favicon "
                    alt="Logo"
                    v-show="faviconPreview || favicon"
                    style="width: 50px;"
                  />
                  <span
                    class="thumbnail"
                  >
                    <span class="icon">
                      <svg
                        width="46"
                        height="39"
                        viewBox="0 0 46 39"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M45 19.9308L36.7274 12.3475C36.1595 11.827 35.2948 11.8047 34.7009 12.2953L18.824 25.411C18.3196 25.8277 17.6074 25.8812 17.0464 25.5446L11.1009 21.9773C10.5893 21.6703 9.94666 21.6856 9.45024 22.0166L1 27.6501"
                          stroke="#9D9FA3"
                          stroke-width="1.25714"
                        ></path>
                        <rect
                          x="1.00049"
                          y="1.40454"
                          width="43.9461"
                          height="36.1909"
                          rx="2.58507"
                          stroke="#9D9FA3"
                          stroke-width="1.25714"
                        ></rect>
                        <circle
                          cx="14.8948"
                          cy="10.6676"
                          r="3.71629"
                          stroke="#9D9FA3"
                          stroke-width="1.25714"
                        ></circle>
                      </svg>
                    </span>
                    <span class="text">
                      Clique aqui para selecionar a imagem
                    </span>
                  </span>
                 
                  <input
                    class="form-control"
                    type="file"
                    @change="updateFilePathIcon"
                   
                  />
                </div>
              </div>
            </div>
          </div> -->
    </div>
    <div class="actionsSave layoutLayoutWeflix">
      <button class="btnSave" @click="update" :disabled="!loaderImage" :class="{'disabled': !loaderImage}">Salvar</button>
    </div>
  
  </section>
 <Loader :hasReady="hasReady"/>

</template>
<script>
import websiteApi from '@/utils/Weflix/website';
import contentApi from '@/utils/Weflix/content';
import { mapState } from 'vuex';
import Loader from '@/components/Weflix/Loader.vue';
import apiAWS from '@/utils/storage/aws';
import LoaderImage from '@/components/Weflix/LoaderImage';

export default {
  data(){
      return{
          color:'#ffffff',
          name: '',
          color: '',
          description: '',
          logo: '',
          favicon: '',
          logoPreview: '',
          faviconPreview: '',
          banner: '',
          bannerPreview: '',
          id: '',
          contents: [],
          hasReady: false,
          activeTheme: '',      
          descriptionAbout:'',
          facebook: '',
          gads: '',
          ganalytic: '',
          tagManager: '',
          rdrefreshToken: '',
          rdcode: '',
          rdSecret: '',
          rdClient: '',
          loaderImage: true,
          messageStatusCache: false
      }
  },
  components: {
      Loader,
      LoaderImage
  },
  computed: {
    ...mapState(['token']),
    bannerWrapperClasses() {
      return {
        'file-wrapper': true,
        'primary': true,
        'form-group': window.innerWidth > 600,
        'content-banner': true,
        'banner-image-layout': true
      }
    }
  },
  methods:{
    update(){
      this.hasReady = false;
      this.updateContent();
      this.updateWebsite();
    },
     setColor(color){
        this.color = color;
     },
     async getWebsite() {
      const response = await websiteApi.getWebsite(this.token);
      this.id = response.id;
      this.name = response.nome;
      this.description = response.description;
      this.logo = response.logo;
      this.logoPreview = response.logoFinal;
      this.favicon = response.favicon;
      this.faviconPreview = response.faviconFinal;
      this.url = response.url;
      this.status = response.status;
      this.hasReady = true;
      this.color = response.color;
      this.activeTheme = response.typeLayout;
      this.descriptionAbout = response.about;

      this.facebook = response.facebookPixelCode;
      this.gads = response.googleAdsCode;
      this.ganalytic = response.googleAnalyticsCode;
      this.tagManager = response.tagManagerCode;
      this.rdrefreshToken = response.rdrefreshToken;
      this.rdcode = response.rdcode;
      this.rdSecret = response.rdsecretClient;
      this.rdClient = response.rdclienteId;
      this.descriptionAbout = response.about;
    },
    async updateWebsite() {
      const payload = {
          "description": this.description,
          "logo": this.logo,
          "favicon": this.favicon,
          "nome": this.name,
          "status": this.status,
          "url": this.url,
          "color": this.color,
          "logoFinal": this.logoPreview,
          "faviconFinal": this.faviconPreview,
          "typeLayout": this.activeTheme,
          "about" : this.descriptionAbout,
          "facebookPixelCode": this.facebook,
          "googleAdsCode": this.gads,
          "googleAnalyticsCode": this.ganalytic,
          "tagManagerCode": this.tagManager,
          "rdrefreshToken": this.rdrefreshToken,
          "rdcode": this.rdcode,
          "rdsecretClient": this.rdSecret,
          "rdclienteId": this.rdClient,
          "about":this.descriptionAbout

      }
        try {
          const response = await websiteApi.editWebsite(this.token, this.id, payload);
          this.getWebsite();
          this.hasReady = true;
        } catch (error) {
          console.error('Erro ao atualizar dados do website:', error);
      }
    },
    async getContents(){
      try {
        const response = await contentApi.getContents(this.token);
        this.contents = response;
        if(this.contents.length > 0){
          this.banner = this.contents[0].pathImage;
          this.bannerPreview = this.contents[0].pathImageFinal;
        }
       
      } catch (error) {
        console.error('Erro ao obter dados do content:', error);
      }
    },
    async updateContent(){
      const payloadPost = [
        {
          "type": 1,
          "pathImage": this.banner,
          "order": 1,
          "status": 1
        }
      ];
      const payloadEdit = [{
        "id": this.contents && this.contents.length > 0 ? this.contents[0].id : null,
        "type": 1,
        "pathImage": this.banner,
        "order": 1,
        "status": 1
      }];

      if(!this.contents || this.contents.length == 0){
        const response = await contentApi.createContent(this.token, payloadPost);
        this.hasReady = true;
      }else{
        const response = await contentApi.editContent(this.token, payloadEdit);
        this.hasReady = true;
      }
    },
    async putFileAws(url, file){
      try {
          const response = await apiAWS.putFileAWS(url, file);
      } catch (error) {
          console.error('Erro ao enviar arquivo para AWS');
      }
    },
    async updateFilePathBanner(event){
      this.loaderImage = false;
        const file = event.target.files[0];
        this.bannerPreview = URL.createObjectURL(file);
        try {
            const response =  await apiAWS.getSignatureImage(this.token, file.name);
            this.putFileAws(response.url, file);
            this.banner = response.token;
            console.debug(response);
            this.loaderImage = true;
        } catch (error) {
            console.error('Erro na geração do TOKEN AWS')
        }
    },
    async clearCache(){
      try {
        const response = await websiteApi.clearCache(this.token);
        this.messageStatusCache = true;
      } catch (error) {
        console.error('Erro ao limpar cache', error);
      }
    }
  },
  created() {
    this.getWebsite();
    this.getContents();
  }
}
</script>
<style scoped>
@media (max-width: 600px) {
  .weflix-admin-layoutLayout h3 {
    font-size: 20px;
  }
  .weflix-admin-layoutLayout small {
    font-size: 12px;
    color: #666;
  }
  .weflix-admin-layoutLayout .bannerPreviewPrimaryBannerSite {
    padding: 10px 0px 10px 0px;
  }
  .weflix-admin-layoutLayout .actionsSave {
    padding: 10px;
  }
}
</style>
<style>
.actionsSave {
  position: absolute;
  bottom: 30px;
  right: 35px;
}
button.btnSave {
  color: #250891;
  width: 150px;
  background-color: #ffffff;
  padding: 6px 10px;
  border-radius: 5px;
  transition: all 500ms ease-in-out;
  font-size: 18px;
  border: 2px solid #250891;
}
button.btnSave:hover {
  background-color: #160266;
  color: white;
}
.titlelayoutLayout {
  text-align: left;
  padding: 10px;
  border-bottom: 1px solid #e3e3e3;
  width: 100%;
}
section.layoutLayout {
  display: flex;
  flex-wrap: wrap;
}
.inputsColors {
  display: flex;
  gap: 10px;
  align-items: center;
}
.wrap-suggested-colors span {
  width: 30px;
  height: 30px;
  display: block;
  cursor: pointer;
}
.form-file .file-wrapper>span.thumbnail {
  height: 6.5rem;
}
.showCertificated {
  text-align: left;
  padding: 15px;
}
.showCertificated > .btn {
  background-color: #250891 !important;
  gap: 10px;
  display: flex;
  align-items: center;
}
.form-group.content-banner img {
  width: 100%;
  max-height: 400px;
}
@media (max-width: 600px) {
  .col-form-inner.col-6.col-md-6.col-lg-6 {
    width: 100%;
  }
  .actionsSave.layoutLayoutWeflix {
    position: inherit !important;
    margin: 0;
  }
}
.btnSave.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.bannerPreviewPrimaryBannerSite{
  width: 100% !important;
  height: auto !important;
  padding: 15px !important;
}
button.btn_cache {
  color: #ffffff;
  /* width: 150px; */
  background-color: #ffffff;
  padding: 6px 10px;
  border-radius: 5px;
  transition: all 500ms ease-in-out;
  font-size: 15px;
  border: 2px solid #250891 !important;
  background-color: #250891 !important;
  margin: 10px 0px;
}
button.btn_cache:hover {
  background-color: #14054d !important;
}
</style>