<template>
    <div class="payment-page">
        <div v-if="loading" class="loader-container">
            <div class="loader"></div>
        </div>
        
        <div v-else class="payment-section">
            <div class="modal-content portal_student">
                <div class="info-payment">
                    <div class="modal-body double">
                        <div class="form-group">
                            <div class="form-label">
                                <label>Próxima Renovação</label>
                            </div>
                            <div class="form-input">
                                <input type="text" class="form-control" disabled :value="nextPayment ? formatDate(nextPayment) : ''">
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="form-label">
                                <label>Valor Mensal</label>
                            </div>
                            <div class="form-input">
                                <input type="text" class="form-control" disabled :value="total ? formatCurrency(total) : ''">
                            </div>
                        </div>
                    </div>

                    <div class="modal-body">
                        <div class="form-group">
                            <div class="form-label">
                                <label>Cartão Atual</label>
                            </div>
                            <div class="card-info" v-if="card">
                                <div class="card-number">{{card ? `${card} (Validade: ${cardValidity})` : ''}}</div>
                                <div class="button-group">
                                    <button @click="showChangeCardModal = true" class="btn-change-card">Trocar Cartão</button>
                                    <button @click="showCancelModal = true" class="btn-cancel-subscription">Cancelar Assinatura</button>
                                </div>
                            </div>
                            <div class="card-info" v-else>
                                <div class="card-number">Nenhum cartão cadastrado</div>
                                <div class="button-group">
                                    <button @click="showChangeCardModal = true" class="btn-change-card">Cadastrar Cartão</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal-body payment-history">
                        <div class="form-group">
                            <div class="form-label">
                                <label>Histórico de Pagamentos</label>
                            </div>
                            <div class="payment-history">
                                <div class="history-item" v-for="order in orders" :key="order.id">
                                    <div class="payment-date">{{order.dateApproved ? formatDate(order.dateApproved) : ''}}</div>
                                    <div class="payment-value">{{order.total ? formatCurrency(order.total) : ''}}</div>
                                    <div class="payment-status" :class="getStatusClass(order.status)">{{getStatusText(order.status)}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal de Confirmação -->
        <div class="modal-overlay" v-if="showCancelModal">
            <div class="modal-confirm">
                <h3>Deseja cancelar sua assinatura?</h3>
                <div class="modal-buttons">
                    <button @click="confirmCancel" class="btn-confirm">Sim</button>
                    <button @click="showCancelModal = false" class="btn-cancel">Não</button>
                </div>
            </div>
        </div>

        <!-- Modal Trocar Cartão -->
        <div class="modal-overlay" v-if="showChangeCardModal">
            <div class="modal-card">
                <h3>Alterar Cartão de Crédito</h3>
                <div class="card-form">
                    <div class="form-group">
                        <label class="white-label">Nome no Cartão</label>
                        <input type="text" v-model="cardForm.name" class="form-control white-input" placeholder="Nome como está no cartão">
                    </div>
                    <div class="form-group">
                        <label class="white-label">Número do Cartão</label>
                        <input type="text" v-model="cardForm.number" class="form-control white-input" placeholder="0000 0000 0000 0000">
                    </div>
                    <div class="form-row">
                        <div class="form-group half">
                            <label class="white-label">Data de Validade</label>
                            <input type="text" v-model="cardForm.expiry" class="form-control white-input" placeholder="MM/AA">
                        </div>
                        <div class="form-group half">
                            <label class="white-label">CVV</label>
                            <input type="text" v-model="cardForm.cvv" class="form-control white-input" placeholder="123">
                        </div>
                    </div>
                </div>
                <div class="modal-buttons">
                    <button @click="saveNewCard" class="btn-confirm">Salvar</button>
                    <button @click="showChangeCardModal = false" class="btn-cancel">Cancelar</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Subscriptions from '../../../utils/Weflix/subscriptions';
import { mapState } from 'vuex';

export default {
    data() {
        return {
            loading: true,
            showCancelModal: false,
            showChangeCardModal: false,
            cardForm: {
                name: '',
                number: '',
                expiry: '',
                cvv: ''
            },
            orders: [],
            card: '',
            cardValidity: '',
            total: 0,
            nextPayment: '',
            nome: '',
            email: '',
            cpf: ''
        }
    },
    computed: {
        ...mapState(['userPortalWeflix','token'])
    },
    methods: {
        formatDate(date) {
            return new Date(date).toLocaleDateString('pt-BR')
        },
        formatCurrency(value) {
            return value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})
        },
        getStatusClass(status) {
            return status === 1 ? 'Pago' : status === 2 ? 'Pendente' : 'Falhou'
        },
        getStatusText(status) {
            return status === 1 ? 'Pago' : status === 2 ? 'Pendente' : 'Falhou'
        },
        async getSubscription() {
            try {
                const response = await Subscriptions.getSubscription(this.userPortalWeflix.acessToken ? this.userPortalWeflix.acessToken : this.token);
                this.orders = response.orders || [];
                this.card = response.card || '';
                this.cardValidity = response.cardValidity || '';
                this.total = response.totalpayed || 0;
                this.nextPayment = response.nextPayment || '';
                this.nome = response.nome || '';
                this.email = response.email || '';
                this.cpf = response.cpf || '';
            } catch (error) {
                console.error('Erro ao obter dados da assinatura:', error);
            } finally {
                this.loading = false;
            }
        },
        async confirmCancel() {
            try {
                await Subscriptions.cancelSubscription(this.userPortalWeflix.acessToken ? this.userPortalWeflix.acessToken : this.token, this.orders[0].idBuyer);
                this.showCancelModal = false;
                await this.getSubscription();
            } catch (error) {
                console.error('Erro ao cancelar assinatura:', error);
            }
        },
        async saveNewCard() {
            const payload = {
                cardNumber: this.cardForm.number,
                cardName: this.cardForm.name,
                cardExpiry: this.cardForm.expiry,
                cardCvv: this.cardForm.cvv
            }
            try {
                const response = await Subscriptions.changeCard(this.userPortalWeflix.acessToken ? this.userPortalWeflix.acessToken : this.token, payload);
                this.showChangeCardModal = false;
                await this.getSubscription();
            } catch (error) {
                console.error('Erro ao alterar cartão:', error);
            }
        }
    },
    created() {
        this.getSubscription();
    }
}
</script>

<style scoped>
.payment-page {
    width: 100%;
    color: #ffffff;
}

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
}

.loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.card-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background: #2a2a2a;
    border-radius: 5px;
    color: #ffffff;
}

.button-group {
    display: flex;
    gap: 10px;
}

.btn-change-card {
    background: #000000;
    color: white;
    border: none;
    padding: 8px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
}

.btn-change-card:hover {
    background: #333333;
}

.btn-cancel-subscription {
    background: #ff4444;
    color: white;
    border: none;
    padding: 8px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
}

.btn-cancel-subscription:hover {
    background: #cc0000;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-confirm, .modal-card {
    background: #1a1a1a;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    color: #ffffff;
}

.modal-card {
    width: 90%;
    max-width: 500px;
}

.card-form {
    text-align: left;
    margin: 20px 0;
}

.form-row {
    display: flex;
    gap: 20px;
}

.form-group.half {
    width: 50%;
}

.modal-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
}

.btn-confirm {
    background: #ff4444;
    color: white;
    border: none;
    padding: 8px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.btn-cancel {
    background: #666;
    color: white;
    border: none;
    padding: 8px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.payment-history {
    margin-top: 15px;
}

.history-item {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    border-bottom: 1px solid #444;
    color: #ffffff;
}

.payment-status {
    color: #2ecc71;
    background: #2ecc7133;
    padding: 4px 8px;
    border-radius: 4px;
}

.payment-status.Pendente {
    color: #fdcb6e;
    background: #fdcb6e33;
}

.payment-status.Falhou {
    color: #ff7675;
    background: #ff767533;
}

.form-group {
    margin-bottom: 20px;
}

.form-label label {
    font-weight: 600;
    margin-bottom: 10px;
    display: block;
    color: #ffffff;
}

.form-control {
    width: 100%;
    padding: 8px;
    border: 1px solid #444;
    border-radius: 5px;
    background: #2a2a2a;
    color: #ffffff;
}

.white-label {
    color: #ffffff;
    font-weight: 600;
    margin-bottom: 10px;
    display: block;
}

.white-input {
    background: #ffffff;
    color: #000000;
}

.white-input::placeholder {
    color: #000000;
}

.form-control:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.modal-body.double {
    display: flex;
    gap: 20px;
}

@media (max-width: 600px) {
    .modal-body.double {
        flex-direction: column;
    }

    .history-item {
        flex-direction: row;
        gap: 8px;
        align-items: flex-start;
    }

    .card-info {
        flex-direction: column;
        gap: 10px;
    }

    .button-group {
        width: 100%;
        flex-direction: column;
    }

    .btn-change-card, 
    .btn-cancel-subscription {
        width: 100%;
    }

    .payment-status {
        align-self: flex-start;
    }

    .form-row {
        flex-direction: column;
        gap: 0;
    }

    .form-group.half {
        width: 100%;
    }
}

.myAccount section.section.config-form.primary.myAccountFormUser {
    height: auto !important;
}
.payment-history {
    margin-top: 30px;
}
</style>
